.welcome-video-background{
    background-color: #fdfdfd !important;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
body {
    color: rgb(245, 245, 245);
    background: #fff !important;
    /* font-family: 'Roboto', sans-serif; */
}
.welcome-login-video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}
.login-form-control{
    height: 40px;
    box-shadow: none;
    color: #969fa4;
}
.mail-spinner-size{
    width: 10% !important;
}
.mail-spinner-layout{
    margin-top: -20px !important;
}
.login-form-control:focus{
    border-color: #5cb85c;
}
.login-form-control, .btn-login{        
    border-radius: 4px !important;
}
.login-signup-form {
    width: 550px;
    margin: 0 auto;
    padding: 30px 0;
}
.login-signup-form h2 {
    color: #3F1E75;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
.login-button-position{
    margin-top: -40px;
    /* margin-bottom: 30px; */
}
.link2{
    margin-left: 15px;
}
.login-legal-position{
    margin-left: 0px;
    position: absolute;
    bottom: 5px;
    left: 0%;
    width: 100%;
}
/* .login-signup-form h2:before, .login-signup-form h2:after{
    content: "";
    height: 2px;
    width: 30%;
    background: #d4d4d4;
    position: absolute;
    top: 50%;
    z-index: 2;
}	 */
.login-signup-form h2:before{
    left: 0;
}
.login-signup-form h2:after{
    right: 0;
}
.login-signup-form .hint-text {
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px !important;
}
.login-form {
    color: #999;
    border-radius: 14px;
    margin-bottom: 15px;
    margin-top: 30px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    padding: 15px 55px;
    border: solid 0.5px rgb(204, 204, 204) !important;
}
.login-form-input-layout {
}
.login-signup-form .form-group{
    margin-bottom: 20px;
}
.login-signup-form input[type="checkbox"]{
    margin-top: 3px;
    background: #000 !important;
}
.login-signup-form .btn-login{        
    font-size: 16px;
    font-weight: bold;		
    min-width: 140px;
    outline: none !important;
    /* border: solid 0.5px rgb(204, 204, 204); */

}
.login-signup-form .row div:first-child{
    padding-right: 10px;
}
.login-signup-form .row div:last-child{
    padding-left: 10px;
}    	
.login-signup-form a{
    color: #fff;
    text-decoration: underline;
}
.login-signup-form a:hover{
    text-decoration: none;
}
.login-form a{
    color: #5cb85c;
    text-decoration: none;
}	
.login-form a:hover{
    text-decoration: underline;
}
.login-top-text{
    margin-top: 5%!important;
}
.login-form-control {
    display: block;
    width: 80%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto;
    margin-top: 10%;
    text-align:center;
    border: solid 0.5px rgb(204, 204, 204) !important;
    /* background-color: green !important; */
}
.login-form-logo img{
    margin-top: 15px;
    margin-bottom: 25px;
}
.login-checkbox-inline {
    color: #575578;
    font-size: 13px;
}
.login-signup-form .btn-login {
    font-size: 16px;
    font-weight: bold;
    width: 60%;
    text-align: center !important;
    padding: 10px 20px;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px !important;
    margin: auto;
    margin-top: 20%!important;
}

.login-signup-form .btn-login:active {
    transform: scale(0.88);
}

.login-signup-form .btn-login:focus {
    transform: scale(0.88);
}

.login-signup-form .btn-login:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.login-account-have{
    color: #707070;
    margin-top: 10px;
}
.login-form a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.login-form-header{
    display: none;
}
.hubster-login-logo-size{
    width: 30%;
}
.hubst3r-login-word-size{
    width: 30%;
}


/*password modal */
.authentication-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
    width: 600% !important;
    margin-top: 0%;
    margin-left: 180%;
}
.login-modal-top-text{
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.modal-password-field{
    display: block;
    width: 50%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto!important;
    margin-top: 50px!important;
    text-align:center !important;
}
.send-again-a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.send-again-a:hover{
    color: #4e2491;
    text-decoration: underline;
}
.continue-button-center{
    margin: auto!important;
    margin-top: -3% !important;
    margin-bottom: 8% !important;
}
.login-modal-btn-success{
    font-size: 16px;
    text-align: center !important;
    font-weight: bold;
    width: 100%;
    padding: 10px 20px;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px!important;
    color: white!important;
    /* margin-left: 57%!important;    */
}
/* end password modal */


@media only screen and (max-width: 1200px) {
    .login-whole-page-layout{
        position: absolute!important;
        top: 0;
        left: 0;
        width: 99.9%;
        height: -100px;
    }
    .welcome-login-video{
        width: 100%;
        height: 1400px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{
        width: 110% !important;
        margin-left: -5%;
    }

    .form-color{
        background: rgb(144,36,103) !important;
        background: linear-gradient(342deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 100%) !important;
    }
    .login-form-header{
        display: block;
        padding: 40px 20px 0px 0px;
    }
    .login-form {
        color: #999;
        border-radius: 12px;
        margin-bottom: 0px !important;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        padding: 25px 55px;
        height: 610px !important;
    }
    .login-signup-form {
        width: 100.4%;
        margin: -0.2%;
        padding: 0px 0;
        height: 600px;
    }
    .login-form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
    .login-form-header {
        display: block;
        padding: 50px 0px 30px 0px;
    }
    .login-form-control {
        display: block;
        width: 100%;
        height: 55px !important;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-image: none;
        /* border: none !important; */
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        border-radius: 8px !important;
        border: solid 0.5px rgb(204, 204, 204) !important;
        background-color: rgb(243, 243, 243) !important;
    }
    .login-form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
}

@media only screen and (max-width: 780px) {
    .welcome-login-video{
        width: 780px;
        height: 1024px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{
        width: 115% !important;
        margin-left: -7.5%;
    }
}


@media only screen and (max-width: 610px) {
    .welcome-login-video{
        width: 780px;
        height: 1024px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{
        width: 120% !important;
        margin-left: -10%;
    }
}

@media only screen and (max-width: 540px) {
    .welcome-login-video{
        width: 780px;
        height: 1024px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
}

@media only screen and (max-width: 500px) {
    .hubst3r-login-word-size{
        width: 30%!important;
        margin: auto!important;
    }
    .login-form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .login-form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .login-form-header img {
        width: 16rem;
    }
    /*password modal */
    .authentication-box-6 {
        width: 400% !important;
        margin-left: 280%;
    }
    /* end password modal */
}


@media only screen and (max-width: 420px) {
    .welcome-login-video{
        width: 820px;
        height: 800px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{
        width: 130% !important;
        margin-left: -15%;
    }
}

@media only screen and (max-width: 380px) {
    .welcome-login-video{
        width: 820px;
        height: 800px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{
        width: 135% !important;
        margin-left: -17.5%;
    }
}

@media only screen and (max-width: 380px) {
    .welcome-login-video{
        width: 820px;
        height: 800px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{

    }
}

@media only screen and (max-width: 340px) {
    /*password modal */
    .authentication-box-6 {
        width: 350% !important;
        margin-left: 310%;
    }
    .login-modal-top-text{
        font-size: 12px !important;
    } 
    /* end password modal */
    .welcome-login-video{
        width: 820px;
        height: 800px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .welcome-video-background{

    }
}
@media only screen and (max-width: 300px) {
    .login-form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 15px;
    }
}