.add-place-modal-place-fila-name{
    z-index: 1 !important;
    position: absolute !important;
    top: 30%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 18px !important;
    z-index: 1 !important; 
    font-weight: bold;
}

.add-place-modal-finaml-map_iconframe{
    width: 80px;
    height: 80px;
    padding: 10px;
    /* background-color: blue; */
    margin: auto;
    margin-top: 33%;  
}

.add-place-modal-map_iconname{
    width: 100%;
    height: 60px;
    text-align: center;
    font-weight: bold;
    /* background-color: crimson; */
    color: #232231;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5px;
    font-size: 14px;
}

.add-place-modal-map_iconslider{
    width: 100%;
    position: absolute;
    top: 37%;
    /* background-color: red; */

}

.add-place-modal-map_iconframe{
    width: 80px;
    height: 80px;
    padding: 10px;
    /* background-color: blue; */
    margin: auto;
}

.add-place-modal-place-name2-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 20%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 18px !important;
    z-index: 1 !important;
}

.add-place-modal-place-name3-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 18%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 18px !important;
    z-index: 1 !important;
}

.add-place-modal-place-name-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 24%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 18px !important;
    z-index: 1 !important;
}

.add-place-modal-place-name-input{
    position: absolute;
    left: 15%;
    width: 70%;
    top: 43%;
    height: 100%;
}

.add-place-modal-nextt-btn-blue{
    width: 30%;
    z-index: 9999999999 !important;
    height: 50px;
    /* background: #1183ca !important; */
    line-height: 50px;
    text-align: center;
    /* border-radius: 4px; */
    font-size:15px;
    font-weight: bold;
    color:#1183ca;
    position: absolute;
    right: 5%;
    bottom: 3%;
}

.add-place-modal-nextt-btn-grey{
    width: 30%;
    z-index: 9999999999 !important;
    height: 50px;
    /* background: #1183ca !important; */
    line-height: 50px;
    text-align: center;
    /* border-radius: 4px; */
    font-size:15px;
    font-weight: bold;
    color:grey;
    position: absolute;
    right: 5%;
    bottom: 3%;
}

.add-place-modal-backk-btn-blue{
    width: 30%;
    z-index: 9999999999 !important;
    height: 50px;
    /* background: #1183ca !important; */
    line-height: 50px;
    text-align: center;
    /* border-radius: 4px; */
    font-size:15px;
    font-weight: bold;
    color:#4a4b4b;
    position: absolute;
    left: 5%;
    bottom: 3%;
}


.place-marker-modal-back-button-box{
    width:20px;
    height: 20px;
    position: absolute;
    top: 2.5%;
    left: 5%;

}

.place-marker-modal-appointment-request-btn{
    width: 60%;
    z-index: 9999999999 !important;
    height: 50px;
    background: #1183ca !important;
    line-height: 50px;
    text-align: center;
    border-radius: 4px;
    font-size:15px;
    font-weight: bold;
    color:#fff;
    position: absolute;
    left: 20%;
    bottom: 7%;
}

.place-marker-modal-appointment-textrarea{
    position: absolute;
    left: 15%;
    width: 70%;
    top: 52%;
    height: 50%;
}

.place-marker-modal-appointment-title{
    z-index: 1 !important;
    position: absolute !important;
    top: 2.5%;
    width: 80%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 10%;
    color: #272635;
    font-size: 18px !important;
    font-weight: bold;
    z-index: 1 !important;
}

.place-marker-modal-appointment-small-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 40%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 14px !important;
    z-index: 1 !important;
}

.place-marker-modal-appointment-phone-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 20%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 14px !important;
    z-index: 1 !important;
}

.place-marker-modal-appointment-phone-input{
    position: absolute;
    left: 15%;
    width: 70%;
    top: 27%;
    height: 50%;
}

.place-marker-modal-closer-is-open{
    color: #1cc016;
}

.place-marker-modal-closer-is-closed{
    color: #c01616;
}

.place-marker-modal-closer-has-label{
    color: #232231;
}

.place-marker-modal-closer-red-bubble{
    width: 25px;
    height: 25px;
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);

    border-radius: 50%;
    position: absolute;
    left: 3.5%;
    top: 0.5%;
    z-index: 9999;
}

.place-marker-modal-closer-green-bubble{
    width: 25px;
    height: 25px;
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
    border-radius: 50%;
    position: absolute;
    left: 3.5%;
    top: 0.5%;
    z-index: 99999999999;
}

.place-marker-modal-closer-yellow-bubble{
    width: 25px;
    height: 25px;
    background-color: #f5d020;
    background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
    border-radius: 50%;
    position: absolute;
    left: 3.5%;
    top: 0.5%;
    z-index: 9999;
}

.place-marker-modal-close-btn{
    position: absolute;
    bottom: 2%;
    left: 35%;
    width: 30%;
    height: 30px;
    background: #d60c27;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    z-index: 999999999999;
}

.place-marker-modal-no-memories{
    z-index: 1 !important;
    width:100%;
    height: 50%;
    position:absolute;
    top: 55%;
    /* background-color: rgb(150, 20, 20); */
}

.place-marker-modal-no-memories-image{
    z-index: 1 !important;
    width: 80px;
    height: 80px;
    margin: auto;
}

.place-marker-modal-no-memories-img{
    z-index: 1 !important;
    width: 100%;
    height: 100%;
}

.place-marker-modal-no-memories-text{
    z-index: 1 !important;
    color: #575578;
    font-size:14px;
    width:70%;
    margin: auto;
    text-align: center;
    margin-top: 20px;
}

.place-marker-modal-all-info{
    z-index: 1 !important;
    width: 90%;
    height: max-content;
    /* background-color: blue; */
    margin-left: 4%;
    margin-top: 35px;
    text-align: center;
    font-size: 12px;
    line-height: 13px;
    color: #575578;
}

.place-marker-modal-all-btns{
    z-index: 1 !important;
    width: 90%;
    height: 30px;
    /* background-color: brown; */
    margin-left: 4%;
    margin-top: 15px;
    border-radius: 2px;
}
.place-marker-modal-all-btns-2{
    z-index: 1 !important;
    width: 90%;
    height: 50px;
    /* background-color: brown; */
    margin-left: 4%;
    margin-top: 15px;
    border-radius: 2px;
}

.place-marker-modal-no-reservations{
    width: 60%;
    z-index: 9999999999 !important;
    height: 100%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size:18px;
    font-weight: bold;
    color: #232231;
}

.place-marker-modal-btn-reservation{
    width: 60%;
    z-index: 9999999999 !important;
    height: max-content;
    background: #1183ca !important;
    line-height: 25px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size:11px;
    font-weight: bold;
    color:#fff
}

.place-marker-modal-btn-shop{
    z-index: 9999999999 !important;
    width: 50%;
    height: max-content;
    background-color: green;
    line-height: 25px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size:18px;
    font-weight: bold;
    padding: 10px;
    color:#fff !important;
}

.place-marker-modal-btn-post-memory{
    z-index: 9999999999 !important;
    width: 30%;
    height: 100%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    background: #fff !important;
    border: none !important;
    color: rgb(59, 59, 59) !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    font-size:13px;
    font-weight: bold;
}

.place-marker-modal-btn-menu{
    z-index: 9999999999 !important;
    width: 30%;
    height: 100%;
    line-height: 30px;
    text-align: center;
    margin: auto;
    /* background: #fff !important; */
    border: none !important;
    color: #fff !important;
    border-radius: 4px;
    /* -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3); */
    font-size:13px;
    font-weight: bold;
    background: rgb(189,195,199);
    background: radial-gradient(circle, rgb(144, 148, 151) 0%, rgb(52, 66, 80) 100%);
}

.place-marker-modal-btn-visit{
    z-index: 9999999999 !important;
    width: 30%;
    height: 100%;
    background-color: #045de9;
    background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
    line-height: 30px;
    text-align: center;
    margin: auto;
    color: #fff;
    border-radius: 4px;
    font-size:13px;
    font-weight: bold;
}

.place-marker-modal-btn-order{
    z-index: 9999999999 !important;
    width: 30%;
    height: 100%;
    background-color: #ffd600;
    line-height: 30px;
    text-align: center;
    margin: auto;
    color:black;
    border-radius: 4px;
    font-size:13px;
    font-weight: bold;
}


.place-marker-modal-all-social{
    z-index: 9999999999 !important;
    position: relative;
    width: 100%;
    max-width: 140px;
    height: 30px;
    /* background-color: blue; */
    margin: auto;
    margin-top: 5px;
}



.place-marker-modal-social-box{
    width: 30px;
    height: 30px;
    margin: auto;
    /* background-color: brown; */
    z-index: 9;
}

.place-marker-modal-social-img{
    z-index: 1 !important;
    width:90%;
    height: 90%;
    margin-top: 5%;
    margin-left: 5%;
}

  .place-marker-modal-search-icon-box{
    z-index: 1 !important;
    position: absolute;
    left: 3.5%;
    width: 16px;
    height: 16px;
    z-index: 10;
    top: 10px;
}

.place-marker-modal-search-icon-img{
    z-index: 1 !important;
    width: 100%;
    height: 100%;
}

.place-marker-modal-search-box{
    z-index: 1 !important;
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    height: 50px;
}

.place-marker-modal-search-field{
    position: absolute;
    height: 35px !important;
    width: 94%;
    left: 2%;
    top: 12%;
    border: none;
    border-radius: 12px;
    background-color: #e4e5e7 !important;
    padding-left: 30px;
    z-index: 9;
}

.place-marker-modal-slider-layout{
    z-index: 1 !important;
    position: absolute;
    bottom: 52.6%;
    left: 0%;
    width: 100%;
    height: 20%;
    padding-left:2.5%;
    /* padding-right:2%; */
    /* padding-top: 115px; */
}

.place-marker-modal-slider-frame{
    z-index: 1 !important;
    width: 100%;
    height: 100%;
}
.place-marker-modal-sent-box{
    z-index: 1 !important;
    margin-top: -6px;
}

.place-marker-modal-profile-pic-box{
    z-index: 1 !important;
    width: 60px;
    height: 60px;
    margin: auto;
}
.place-marker-modal-sent-profile-pic-box{
    z-index: 1 !important;
    width: 30px;
    height: 30px;
    margin: auto; 
    margin-top: 5px;
    margin-bottom: 5px;
}

.place-marker-modal-profile-pic-img{
    z-index: 1 !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.place-marker-modal-buddy-username{
    z-index: 1 !important;
    color: #3F1E75;
    text-align: center;
}


.place-marker-modal-social-finish{
    z-index: 1 !important;
    position: absolute;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bolder;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 8px 16px -20px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 40px -20px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 50%;
    text-align: center;
    /* height: 20%; */
    left: 25%;
    font-size: 12px;
    top: 67%;
    font-size: 25px;
    /* border-radius: 20px 20px 0px 0px !important; */
    box-shadow: 0px 0px 12px 1px rgba(45, 6, 104, 0.3);
}

.place-marker-modal-username{
    z-index: 1 !important;
    position: absolute !important;
    top: 2.5%;
    width: 90%;
    height: 30px;
    text-align: left;
    /* top: 0%; */
    left: 5%;
    color: #272635;
    font-size: 16px !important;
    font-weight: bold;
    /* background-color: blue; */
}

.place-marker-modal-url{
    z-index: 1 !important;
    position: absolute !important;
    top:30.5%;
    width: 90%;
    height: 30px;
    text-align: left;
    /* top: 0%; */
    left: 5%;
    color: #272635;
    font-size: 16px !important;
    font-weight: bold;
    /* background-color: blue; */
}

.place-marker-modal-username-input{
    z-index: 1 !important;
    position: absolute !important;
    top: 11.5%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none; 
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.place-marker-modal-url-input{
    z-index: 1 !important;
    position: absolute !important;
    top: 39.5%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.place-marker-modal-times-box{
    position: absolute;
    width: 50px;
    height: 50px;
    right: 5%;
    bottom: 0%;
    z-index: 999 !important;
    /* background-color: chartreuse; */
}

.place-marker-modal-times{
    z-index: 9999 !important;
    width: 50%;
    height: 50%;
    position: absolute;
    top:10%;
    left: 25%;
}

.place-marker-modal-buddy-name{
    z-index: 1 !important;
    position: absolute !important;
    top: 0.5%;
    width: 100%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 0%;
    color: #272635;
    font-size: 18px !important;
    font-weight: bold;
    z-index: 1 !important;
}

.place-marker-modal-title-hr{
    z-index: 1 !important;
    position: absolute !important;
    width: 100%;
    height: 10px;
    top:110px;
}

.place-marker-modal-all-choises{
    z-index: 1 !important;
    position: absolute;
    top: 130px;
    width: 100%;
    height: 400px;
}

.place-marker-modal-choise{
    z-index: 1 !important;
    width: 90%;
    margin-left: 5%;
    height: 24px;

}

.place-marker-modal-choise-text{
    z-index: 1 !important;
    width: 100%;
    text-align: left;
    color: #272635;
    font-size: 16px !important;
    font-weight: normal;
}


.place-marker-modal-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 0px !important;
    background: #fff; 

    margin-top: -10;
    /* margin-left: -5%; */
    height: 120%!important;
    width: 110%;
    z-index: 9 !important;
} 



.place-marker-modal-select-budies-frame{
    position: relative;
    z-index: 1 !important;
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 235px !important;
    /* border-radius: 20px 20px 0px 0px; */
}

.place-marker-modal-buddies-card{
    z-index: 1 !important;
    position: absolute;
    width: 100%;
    height: 88%;
    background-color: rgb(27, 19, 19);
    top: 1.5%;
    left: 1%;
    border-radius: 0px;
    box-shadow: rgb(27, 19, 19, 0.1) 0px 10px 50px;    /* rgba(22, 93, 137, 0.4)  */   
}


.place-marker-modal-buddies-card-square{
    z-index: 1 !important;
    /* background-color: #000; */
    width: 100% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    /* border-radius: 20px 20px 0px 0px; */
    box-shadow: 0px -30px 30px 10px rgb(27, 19, 19) inset !important;
}
  
.place-marker-modal-buddies-card-square:after {
    z-index: 1 !important;
    content: "";
    display: block;
    padding-bottom: 120%;
  }
.place-marker-modal-buddies-card-square-small{
    z-index: 1 !important;
    width: 50% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}
  
.place-marker-modal-buddies-card-square-small:after {
    
    content: "";
    z-index: 1 !important;
    display: block;
    padding-bottom: 100%;
  }

.place-marker-modal-buddies-username{
    z-index: 1 !important;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    top: 95%;
    left: 5%;
    width: 90%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}




@media only screen and (max-width: 992px){
    .place-marker-modal-social-finish{
        top: 77%;
        font-size: 25px;
        z-index: 1 !important;
    }
    
}


@media only screen and (max-width: 480px){

    .place-marker-modal-social-finish{
        top: 65%;
        font-size: 25px;
        z-index: 1 !important;
    }
}

@media only screen and (max-width: 410px){
    .place-marker-modal-social-finish{
        top: 38%;
        font-size: 20px;
        z-index: 1 !important;
    }
}

@media only screen and (max-width: 400px){
    .place-marker-modal-select-budies-frame{
        min-width: 160px;
        min-height: 245px;
        z-index: 1 !important;
    }
}

@media only screen and (max-width: 370px){
    .place-marker-modal-social-finish{
        top: 40%;
        z-index: 1 !important;
    }
    .place-marker-modal-buddy-name{
        top: 1.5%;
        z-index: 1 !important;
    }
}
@media only screen and (max-width: 340px){ 
    .place-marker-modal-select-budies-frame{
        min-width: 140px !important;
        min-height: 170px !important;
        z-index: 1 !important;
    }

    .place-marker-modal-close-btn{
        bottom: 1%;
        border-radius: 4px;
    }

    .place-marker-modal-buddy-name{
        top: 1.5%;
        font-size: 16px !important;
        z-index: 1 !important;
    }
    .place-marker-modal-social-finish{
        top: 38%;
        z-index: 1 !important;
    }
}