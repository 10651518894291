.bottom-navbar-all-choices{
    width: 100% !important;
    height: 8%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 999999999;
    opacity:0.95;
    background-color: black;
    /* background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%); */
    border-top: 1px solid rgb(241, 241, 241);
    margin-left: 0%;
    position: fixed;
}

.bottom-navbar-choice-box{
  width: 20%;
  height: 100%;
}
.bottom-navbar-choice-img-box{
  position: relative;
  width: 22px;
  height: 22px;
  margin: auto;
  line-height: 40px;
  margin-top: 11%;
}

.bottom-navbar-choice-img{
  width: 100%;
  height: 100%;
  margin-top: 0% !important;
  position: absolute;
  top: 0%;
}

.bottom-navbar-choice-title{
  width: 100%;
  /* position: absolute;
  bottom: 0%; */
  font-size: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  /* background-color: tomato; */
  margin-top: 7% !important;
}
.bottom-navbar-choice-title-selected{
  width: 100%;
  /* position: absolute;
  bottom: 0%; */
  font-size: 10px;
  text-align: center;
  color: rgba(255, 255, 255);
  /* background-color: tomato; */
  margin-top: 7% !important;
  font-weight: bold;
}



.bottom-navbar-notification-tremple{
  width: 100%;
  height: 100%;
  margin-top: 0% !important;
  position: absolute;
  top: 0%;
  -webkit-animation: ring 5s 1.5s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 5s 1.5s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 5s 1.5s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.bottom-nav-profile-pic{
  width: 100%;
  margin: auto;
}
.nav-bottom-9{
  background: #fff;
  background-size: cover;
  border-radius: 20px 20px 0px 0px !important;
  box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}
.footer-icon-9 img {
  width: 18%;
}
.middle-icon-footer-9 img {
  position: absolute;
  top: -45px;
  width: 92px !important;
  left: 50%;
  transform: translateX(-50%);
}
.footer-width-9{
  width: 20% !important;
}
.nav-bottom-9{
  display: none !important;
}
.nav-bottom-9{
  z-index: 99999999999 !important;
}
.bottom-navbar-notification-mika{
  position: absolute;
  top: -80%;
  left:20%;
  width: 70px;
  height: 70px;
}
.bottom-navbar-mika-img{
  width:100%;
}
@media only screen and (max-width: 1200px){
  .nav-bottom-9{
    display: block !important;
    z-index: 99999999999;
  }
}


@media only screen and (max-width: 504px){
  .footer-icon-9 img {
      width: 30%;
  }
  .middle-icon-footer-9 img {
      position: absolute;
      top: -34px;
      width: 75px !important;
      left: 50%;
      transform: translateX(-50%);
  }
  .middle-icon-footer-9-search img {
    position: absolute;
    top: -13px;
    width: 75px !important;
    left: 50%;
    transform: translateX(-50%);
}
}

@media only screen and (max-width: 422px){
  .footer-icon-9 img {
      width: 33%;
  }
}

@media only screen and (max-width: 396px){
  .middle-icon-footer-9 img {
      position: absolute;
      top: -30px;
      width: 65px !important;
      left: 50%;
      transform: translateX(-50%);
  }
  .footer-icon-9 img {
      width: 36%;
  }
}

@media only screen and (max-width: 341px){
  .footer-icon-9 img {
      width: 43%;
  }
}

@media only screen and (max-width: 337px){
  .middle-icon-footer-9 img {
      position: absolute;
      top: -27px;
      width: 57px !important;
      left: 50%;
      transform: translateX(-50%);
  }
}