.show-message-modal-audio-controls{
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 999999999999;
    width: 100%;
    height: 5%;
}

.show-message-modal-timestamp{
    position: absolute;
    bottom: 6%;
    right: 5%;
    width: 90%;
    height: 7%;
    text-align: right;
    font-weight: bold;
    z-index: 999999999999;
    /* background-color: blue; */
}

.show-message-modal-username{
    position: absolute;
    top: 0%;
    right: 15%;
    width: 70%;
    height: 7%;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    z-index: 999999999999;
}

.show-message-modal-wave-gif-img{
    /* background-color: blue; */
    width: 100%;
    height: 100%;
    opacity: 0.04;
}

.show-message-modal-wave-gif{
    position: absolute;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    /* background-color: aquamarine; */
}

.show-message-modal-box{
    position: absolute;
    padding: 0px 0px !important;
    /* border-radius: 50px; */
    background: #fff; 
    margin-top: 0%;
    margin-left: 3.7%;
    height: 100%!important;
    width: 100%!important;
    /* object-fit: cover; */
} 
.show-message-modal-photo-layout{
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    object-fit: scale-down!important;
    z-index: 999;
    position: absolute;
    /* top: -100%; */
    top: 0%;
}
.show-message-modal-photo-layout-cover{
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
    /* opacity: 0.8; */
    position: absolute;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    top: 0%;
    z-index: 1;
}

.show-message-modal-times-box{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 1%;
    top: 0%;
    z-index: 999999999999 !important;
}
.show-message-modal-times{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 999999999999 !important;
}


