.place-claim-correct-box{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-left: 20px;
}

.place-claim-correct-img{
    width: 100%;
    height: 100%;
}

.place-claim-offer-box-big{
    width: 94%;
    /* height: 100%; */
    position: relative;
    /* margin-left: 20%; */
    margin: auto;
    margin-top: 10%;
}

.place-claim-price-box-big {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.10);
    padding: 15px;
    background: #fff;
    border-radius: 4px;
}
.place-claim-price-box-big ul {
    padding: 10px 0px 10px;
    margin: 1px 0 0 0;
    list-style: none;
    border-top: solid 1px #e9e9e9;
}
.place-claim-price-box-big ul li {
    padding: 2px 0;
    font-size: 12px;
    color: #808080;
}
.place-claim-price-box-big ul li .fas {
    color: #68AE4A;
    margin-right: 7px; 
    font-size: 12px;
}





.place-claim-row-position{
    position: relative;
    margin-left: 0%;
    width: 100%;
}

.place-claim-offer-box{
    width: 48%;
    /* height: 100%; */
    position: relative;
    margin-left: 1%;
    margin-top: 10%;
}

.place-claim-price-box {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.10);
    padding: 15px;
    background: #fff;
    border-radius: 4px;
}
.place-claim-price-box ul {
    padding: 10px 0px 10px;
    margin: 1px 0 0 0;
    list-style: none;
    border-top: solid 1px #e9e9e9;
}
.place-claim-price-box ul li {
    padding: 2px 0;
    font-size: 12px;
    color: #808080;
}
.place-claim-price-box ul li .fas {
    color: #68AE4A;
    margin-right: 7px; 
    font-size: 12px;
}

.place-claim-price-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.34;
    margin-bottom: 0%;
    padding: 6px 15px;
    display: inline-block;
    border-radius: 3px; 
}
.place-claim-price-label.basic {
    background: #E8EAF6;
    color: #3F51B5;
}
.place-claim-price-label.value {
    background: #E8F5E9;
    color: #4CAF50;
}
.place-claim-price-label.premium {
    background: #FBE9E7;
    color: #FF5722;
}


.place-claim-price {
    font-size: 22px;
    line-height: 22px;
    margin: 5px 0 6px;
    font-weight: bold;
    color: #000;
}
.place-claim-price-info {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.67;
    color: inherit;
    width: 100%;
    margin: 0;
    color: #989898;
}

.place-claim-plan-btn {
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    padding: 11px 2px;
    border: 2px solid #b3b3b3;
    color: #000;
    margin-top: 5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: 0;
    border-radius: 5px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
  .place-claim-plan-btn::after {
    position: absolute;
    left: -100%;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #000;
    z-index: -1;
    transition: all 0.35s ease-in-out;
  }
  .place-claim-plan-btn:hover::after {
    left: 0;
  }
  .place-claim-plan-btn:hover, 
  .place-claim-plan-btn:focus {
      text-decoration: none;
      color: #fff;
    border: 2px solid #000;
  }