.request-reservation-no-reservation-hours{
    width: 80%;
    position: absolute;
    top: 250px;
    left: 10%;
    color: #575578;
    font-weight: bold;
}

.request-reservation-timewheel-text{
    width: 100%;
    height: 30%;
    font-size: 18px;
    color: #232231;
    text-align: center;
}

.request-reservation-timewheel-arrow-left-box{
    width: 50px;
    height: 50px;
    position: absolute;
    left: 15%;
    margin-top: 130px;
}

.request-reservation-timewheel-arrow-right-box{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 15%;
    margin-top: 130px;
}

.request-reservation-timewheel-arrow-img{
    width: 100%;
    height: 100%;
}

.request-reservation-timewheel-box{
    width: 100%;
    height: 100%;
    /* position: absolute;
    top: 50% !important;
    left: 0%; */
    /* background-color: lightcoral; */
    font-size: 50px !important;
    text-align: center !important;
    margin-top: 30px;
}

.request-reservation-timewheel-item{
    width: 50%;
    height: 30%;
    /* position: absolute;
    top: 30%;
    left: 25%; */
    text-align: center !important;
    font-size: 50px !important;

}

.back-button-request-reservation-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.request-reservation-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.request-reservation-explaining-text{
    margin: auto;
    color: #575578;
    /* font-weight: lighter; */
    text-align: left;    
    font-size: 14px;
    width: 85%;
    height: 100%;
    margin-top: -3px;
}

.request-reservation-explaining-img-box{
    width: 7%;
    height: 100%;
    margin-left: 10px;
    margin-top: 5px;
}

.request-reservation-explaining-img{
    width: 100%;
}


.request-reservation-persons-next{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 8px 15px;
    border-radius: 30px;
    font-weight: bold;
    width: 10%;
    text-align: center;
    position: absolute;
    bottom: 12%;
    right: 10%;
}

.request-reservation-write-persons{
    border: none;
    width: 50%;
    text-align: center;
    margin: auto;
}
.request-reservation-write-persons input{
    margin-top: 15%;
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}

.request-reservation-search{
    border: none;
    width: 100%;
    text-align: center;
    margin: auto;
}
.request-reservation-search input{
    margin-top: 5%;
    border-radius: 4px !important;
    border: solid 0.5px rgb(204, 204, 204);
    /* border-radius: 20px 20px 20px 20px !important; */
    /* box-shadow: 0px 0px 4px 1px rgba(45, 6, 104, 0.3); */
}

.request-reservation-top-text{
    margin: auto;
    color: #575578;
    font-weight: bold;
    margin-top: 10px;  
    font-size: 12px;
    width: 50px;
    text-align: center !important;
}

.request-reservation-btn-hub-logo{
    width: 80% !important;
    border-radius: 50%!important;
    margin-top: -10% !important;
}

.request-reservation-top-title{
    color: #575578;
    font-weight: bold;
    margin-bottom: 10px;
}

.request-reservation-top-step p {
    margin-top: 10px;    
}

.request-reservation-top-row {
    display: table-row;
}

.request-reservation-top {
    display: table;     
    width: 100%;
    position: relative;
    margin-top: 18%;
}

.request-reservation-top-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.request-reservation-top-row:before {
    top: 132px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-order: 0;
}

.request-reservation-top-step {    
    display: table-cell;
    text-align: center;
    position: relative;
}

.request-reservation-btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  display: block;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e7e7e7));
  background-image: -webkit-linear-gradient(top, #fff, #e7e7e7); 
  background-image: -moz-linear-gradient(top, #fff, #e7e7e7); 
  background-image: -ms-linear-gradient(top, #fff, #e7e7e7); 
  background-image: -o-linear-gradient(top, #fff, #e7e7e7); 
  color: #a7a7a7;
  margin: auto;
}

.request-reservation-btn-circle-sellected {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
    background-color: rgb(65, 88, 161);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(65, 88, 161)), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, rgb(65, 88, 161), #e7e7e7); 
    background-image: -moz-linear-gradient(top, rgb(65, 88, 161), #e7e7e7); 
    background-image: -ms-linear-gradient(top, rgb(65, 88, 161), #e7e7e7); 
    background-image: -o-linear-gradient(top, rgb(65, 88, 161), #e7e7e7); 
    color: #ffff;
    margin: auto;
  }

.request-reservation-section-title{
    margin-top: 10px;
    color: #575578;
    font-size: 15px !important;
    font-weight: bold;
}

.request-reservation-hub-name{
    margin-top: 10%;
    color: #575578;
    font-size: 18px !important;
    font-weight: bold; 
    text-align: center;
}

.request-reservation-hub-logo-selected{
    width: 100px!important;
    height: 100px!important;
    border-radius: 50%!important;
    object-fit: cover!important;
    margin: auto!important;
    border:double 5px rgb(77,51,168)!important;
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%)!important;
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3)!important;
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3)!important;
}

.request-reservation-hub-logo{
    width: 100px!important;
    height: 100px!important;
    border-radius: 50%!important;
    object-fit: cover!important;
    margin: auto!important;
}

.request-reservation-top-hub-logo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 0%;
}
.request-reservation-hub-carousel{
    width: 98%;
    margin:auto;
    margin-top: 10%;
}

.request-reservation-hub-carouse-item{
    width: 80% !important;
    /* background-color: #ffffff; */
    /* margin-left: -10%; */
    margin-right: 10%!important;
}
.request-reservation-time-box{
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.request-reservation-notes{
    margin-top: 10%;
    color: #575578;
    font-size: 16px !important; 
}

.request-reservation-request-button{
    background: #1183ca !important;
    /* background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%); */
    border: none;
    padding: 8px 45px; 
    border-radius: 30px;
    font-weight: bold;
    width: 60%;
    margin: auto;
    margin-top: 40px;
}

.request-reservation-request-button-user{
    /* background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%); */
    background: #1183ca !important;
    border: none;
    padding: 8px 45px; 
    border-radius: 30px;
    font-weight: bold;
    width: 60%;
    margin: auto;
    margin-top: 40px;
}

.request-reservation-request-button:active {
    transform: scale(0.88);
}

.request-reservation-request-button:focus {
    transform: scale(0.88);
}

.request-reservation-request-button:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.request-reservation-top-box{
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    border-radius: 50px;
}

.arrange-no-buddies-mika-layout{
    width: 100%;
    height: 50%;
    margin-bottom: 20%;
}
.arrange-no-buddies-image{
    width: 100% !important;
    height: 100px;
}
.arrange-no-buddies-created{
    margin-top: 20px;
    width: 30%;
    border-radius: 20px;
}
.arrange-no-buddies-text{
    color: #575578;
    font-size: 14px !important;
    margin-top: 195px;
    margin-bottom: -170px;
}



/* loading */
.arrange-invite-your-buddies-text{
    margin-top: -20px !important;
}
.arrange-page-section-disappear{
    display: none;
}
.arrange-page-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.arrange-page-lottie-dissappear{
    display: none;  
}
/* end loading */

.arrange-forever-alone-img{
    width: 80% !important;
    margin: auto;
}  

/* timepicker modal */
.arrange-page-hubs-rules{
    position: absolute;
    /* margin: auto; */
    font-size: 13px;
    width: 85%;
    margin-top: 20px !important;
    color: #3F1E75; 
    text-align: justify;   
}
.arrange-page-time-box{
    position: absolute;
    background-color: #fff;
    width: 30%;
    height: 10% !important;
    margin-left:30%;
    margin-top: 4%;
    /* padding: 20px; */
    box-sizing: content-box;
    border-radius: 12px;
    -webkit-box-shadow: 20px 24px 28px -25px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px 5px rgba(28, 2, 68, 0.3);
}
.arrange-page-time-number {
    margin: 10px auto;
    width: 80%;
    height: 38px;
    color: rgb(114, 111, 111);
    background: #fff;
    line-height: 38px;
    border-radius: 6px;
}
.arrange-page-timepicker-box{
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -255%;
    margin-left: -250%;
    height: 600px!important;
    width: 550%;
}
.timepicker-title{
    position: relative;
    font-size: 30px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 35%;
    margin: auto;
}
.arrange-timepicker-times-modal-size{
    /* position: absolute; */
    width: 5%;
    margin-bottom: -60px!important;
    float: right;
    z-index: 1 !important;
    margin-top: -7%;
    cursor: pointer;
}
.arrange-timepicker-clock-layout{
    width: 400px !important;
    margin-top:20% ;
    /* margin-left: -20%; */
}
.request-reservation-timepicker-layout{
    /* background-color: crimson; */
    margin-top: -20px !important;
    margin-left: -10%;
    /* background-color: firebrick; */
}
.arrange-timepicker-component-size{
    /* width: 300px; */
    margin: auto;
}
.timepicker-subtitle-hour{
    margin: auto;
    font-size: 16px;
    width: 85%;
    margin-top: 0% !important;
    color: #3F1E75;
    z-index: 3 !important;
}
.timepicker-subtitle-minutes{
    margin: auto;
    font-size: 16px;
    width: 85%;
    margin-top: -6% !important;
    color: #3F1E75;
    z-index: 3 !important;
}
.timepicker-back-button-size{
    max-width:18%;
    /* margin-top: 90%; */
}
.timepicker-back-button{
    /* width: 20%; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 5px 5px !important;
    border-radius: 30px!important;
    color: white !important;
    font-size: 12px;
}
/* end timepicker modal */


/* Body left css start */
.body-left-4{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.logo-4{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic-4{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text-4 strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.left-text-4{
    font-size: 15px;
}
.left-icon-4 img{
    width: 45%;
}
.left-home-4{
    cursor: pointer;
}
.left-home-4:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.body-right-4{
    margin-left: 16.65%;
    background: #fff;
}
.blue{
    color: #575578;
}
.item-div-4 {
    /* background: #fff; */
    padding: 20px 0px !important;
    max-width: 50% !important;
    border-radius: 13px;
    margin-left: 14.66% !important;
    cursor: pointer;
}
.item-img-4 {
    width: 100px !important;
    height: 100px !important;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 50% !important;
    border: 0.5px solid #efeeff !important;
    -webkit-box-shadow: 10px 10px 20px 5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
}
.arrange-buddy-img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%;
}
.item-img-4p{
    /* width: 135px;
    height: 130px; */
    margin: auto;
}
.item-img-4 img{
    width: 45px;
}
.item-text-4{
    font-weight: bold;
}
.item-text-4-username{
    font-weight: bold;

}
.btn.btn-info.item-btn-4 {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 8px 45px;
    border-radius: 30px;
    font-weight: bold;
}
.btn-danger-arrange-page {
    background: #d9534f !important;
    border: none;
    color: white !important;
    padding: 4px 15px !important;
    border-radius: 30px !important;
    font-weight: bold;
    font-size: 14px;
}
.btn-success-arrange-page {
    background: #5cb85c !important;
    border: none;
    color: white !important;
    padding:4px 15px !important;
    border-radius: 30px !important;
    font-weight: bold;
    font-size: 14px;
}
.arrow {
    position: absolute;
    width: 35px;
    height: 35px;
    margin: auto;
    background-color: #fff;
    margin-left: 0%;
    margin-top: 0%;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    cursor: pointer;
}

.arrow-left-position {   
    top:110px;
    left:-15px
}
.arrow-right-position {
    top:-142px;
    left:93%
}
.arrow-left img{
    transform: rotate(180deg);
}
.arrow img{
    margin-top: -50%;
    width: 11px;
}
.arrow-right{
    margin-left: 0%;
}
.plus-icon-4{
    display: inline-block;
    width: 25px;
    /* height: 50px; */
}
.arrange-create-gang-icon{
    width: 20px;
    margin-top: -5px;
}
.plus-text-4{
    background-color: #fff;
    max-width: 25.333333% !important;
    margin: auto;
    border-radius: 30px;
    padding: 5px 10px !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.3);
}
.plus-text-4 span{
    font-weight: bold;
}
.btn-primary-arrange-page{
    background: linear-gradient(353deg, #6232B2 11%, rgb(36, 67, 170) 91%);
    border: none !important;
    color: white !important;
    padding: 5px 18px !important;
    border-radius: 30px !important;
    width: 30% !important;
    font-weight: bold !important;
    margin-top: -2rem !important;
}
.time-button-customize {
    background-color: #6232B2 !important;
    color: white !important;
    font-size: 5px!important;
} 
textarea.form-control {
    height: auto;
    border-radius: 12px;
}
label {
    display: block !important;
    margin-bottom: .5rem;
    text-align: left;
    margin-top: -1rem;
    color:  #575578;
}
.big-box-4{
    background-color: #fff;
    padding-top: 0rem;
    border-radius: 15px;
}
.starbucks-4 img {
    width: 80px;
    height: 80px;
    position: relative;
    top: -38px;
    border-radius: 50%;
    border: 5px solid #FFFAFA;
    object-fit: cover;
}
.small-header{
    display: none;
}
.nav-bottom-4{
    display: none !important;
}
/* Body Right css end */


@media only screen and (max-width: 1200px){

    .request-reservation-request-button{
        width: 100%;
        height: 80px;
        line-height: 80px;
        left: 0%;
        top: initial;
        bottom: 0% !important;
        font-size: 30px;
        font-weight: bold;
        border-radius: 0px !important;
        border: none;
        position: fixed !important;
    } 

    .request-reservation-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
        }
    .request-reservation-persons-next{
        bottom: 20%;
        right: 10%;
    }
    .request-reservation-top-row:before {
        top: 48px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-order: 0;
    }
    
    .arrange-page-whole-page-layout{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }
    .body-left-4{
        display: none;
    }
    .body-right-4{
        margin-left: initial;
        padding-top: 3rem;
    }
    .big-box-4{
        max-width: 80% !important;
        margin: auto;
    }
    .text-top{
        display: none;
    }
    .top-back-4 img{
        width: 1.5rem;
        margin-left: 3rem;
    }
    .small-header{
        display: block !important;
    }
    .body-right-4 {
        border-radius: 0px 0px 0px 0px;
    }
    .color-4{
        background: linear-gradient(258deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    }
    .upper-text-4 h2{
        color: #fff;
        font-weight: bold;
    }

    .btn-primary-arrange-page {
        margin-top: -2rem !important;
    }
    .arrange-btn-4 {
        padding: 15px 55px !important;
        font-size: 20px !important;
        color: white !important;
    }
    .arrow {
        /* background-color: initial; */
        -webkit-box-shadow: initial;
        box-shadow: initial;
    }
    .nav-bottom-4{
        display: block !important;
    }
    .body-right-4{
        padding-bottom: 5rem;
    }
}

@media only screen and (max-width: 992px){
    .item-img-4 {
        /* width: 110px;
        height: 120px; */
        background: #fff;
        margin: auto;
        line-height: 120px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 117px;
        height: 120px;
        margin: auto;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 8px 37px;
        border-radius: 30px;
        font-weight: bold;
    }
    .arrow-left-position {   
        left:-25px
    }
    /* timepicker modal */
    .arrange-page-timepicker-box{
        margin-top: -255%;
        margin-left: -250%;
        height: 600px!important;
        width: 550%;
    }
    /* end timepicker modal */
}
@media only screen and (max-width: 857px){
    .plus-text-4 {
        background-color: #fff;
        max-width: 31.333333% !important;
        /* margin: auto; */
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.3);
    }
    .request-reservation-persons-next{
        bottom: 22%;
        right: 10%;
    }
}
@media only screen and (max-width: 768px){
    .item-div-4 {
        padding: 20px 0px !important;
        max-width: 80% !important;
        border-radius: 13px;
        margin-left: 10%!important;
        cursor: pointer;
    }
    .arrow {
        margin-top: 5rem;
    }
    .plus-text-4 {
        max-width: 34.333333% !important;
    }
    .middle-icon-footer-4 img {
        width: 60%;
        margin-left: 50%;
        transform: translate(-136%);
        position: absolute;
        top: -46px;
    }
    .upper-text-4 h2 {
        font-size: 25px;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .arrow-left-position {   
        top:40px;
        left:-30px
    }
    .arrow-right-position {
        top:-202px;
        left:94.5%
    }
    /* timepicker modal */
    .arrange-page-timepicker-box{
        margin-top: -275%;
        margin-left: -200%;
        height: 600px!important;
        width: 550%;
    }
    /* end timepicker modal */
}
@media only screen and (max-width: 633px){
    .plus-text-4 {
        max-width: 50.333333% !important;
    }
    .col-lg-8.col-md-8.col-sm-8.col-8.upper-text-4 {
        margin-left: -3rem;
    }
    .middle-icon-footer-4 img {
        width: 60%;
        margin-left: 50%;
        transform: translate(-140%);
        position: absolute;
        top: -35px !important;
    }
}
@media only screen and (max-width: 576px){
    .request-reservation-persons-next{
        bottom: 23%;
        right: 5%;
        font-size: 16px;
        width: 15%;
    }
    .arrange-page-hubs-rules{
        font-size: 12px;
    }
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 27px;
    }
    .upper-text-4 .ml-5{
        margin-left: 2.3rem !important;
    }
    .top-back-4 img {
        width: 1.2rem;
        margin-left: 3rem;
    }
    .item-img-4 {
        /* width: 100px;
        height: 112px; */
        background: #fff;
        margin: auto;
        line-height: 112px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 35px;
    }
    .item-img-4p {
        width: 110px;
        height: 112px;
        margin: auto;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 7px 33px;
        border-radius: 30px;
        font-weight: bold;
    }
    .arrow.arrow-left {
        margin-left: -10px !important;
    }
    .plus-text-4 {
        max-width: 46.333333% !important;
    }
    .arrow-left-position {   
        top:40px;
        left:-25px
    }
    .arrow-right-position {
        top:-198px;
        left:93%
    }
    .btn-success-arrange-page {
        padding: 6px 35px;
    }
    .btn-danger-arrange-page {
        padding: 6px 35px;
    }
    /* timepicker modal */
    .arrange-page-timepicker-box{
        margin-top: -275%;
        margin-left: -190%;
        height: 610px!important;
        width: 500%;
    }
    .timepicker-subtitle-hour{
        font-size: 16px;
        width: 85%;
        /* margin-top: 95% !important; */
        color: #3F1E75;
        z-index: 3 !important;
        /* margin-left: 8%;   */
    }
    .timepicker-subtitle-minutes{
        margin: auto;
        font-size: 15px;
        width: 50%;
        margin-top: -7% !important;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .timepicker-back-button-size{
        max-width:24%;
        /* margin-top: 100%; */
    }
    .timepicker-back-button{
        /* width: 20%; */
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 5px !important;
        border-radius: 30px!important;
        color: white !important;
        font-size: 12px;
    }
    /* end timepicker modal */
}
@media only screen and (max-width: 563px){
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .arrow-left-position {   
        top:40px;
        left:-25px
    }
    .arrow-right-position {
        top:-195px;
        left:93%
    }
}
@media only screen and (max-width: 540px){
    .big-box-4{
        margin-top: -38px;
    }
    .arrange-invite-your-buddies-text{
        margin-top: -20px !important;
    }
    .item-img-4 {
        width: 70px !important;
        height: 70px !important;
        background: #fff;
        margin: auto;
        line-height: 105px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 30px;
    }
    .item-img-4p {
        width: 92px;
        height: 95px;
        margin: auto;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 7px 26px;
        border-radius: 30px;
        font-weight: bold;
    }
    .arrow-left-position {   
        top:40px;
        left:-25px
    }
    .arrow-right-position {
        top:-180px;
        left:93%
    }
}
@media only screen and (max-width: 533px){
    .btn-primary-arrange-page {
        width: 10rem !important;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .btn-success-arrange-page {
        padding: 6px 30px;
    }
    .btn-danger-arrange-page {
        padding: 6px 30px;
    }
}
@media only screen and (max-width: 490px){
    /* timepicker modal */
    .arrange-page-timepicker-box{
        margin-top: -255%;
        margin-left: -140%;
        width: 400%;
        height: 560px!important;
    }
    .timepicker-title{
        position: relative;
        font-size: 20px;
        width: 50%;
    }
    .arrange-timepicker-times-modal-size{
        width: 7%;
        margin-top: -8%;
    }
    .arrange-timepicker-clock-layout{
        width: 340px !important;
        margin-top:20% ;
        /* margin-left: -20%; */
    }
    .timepicker-subtitle-hour{
        font-size: 15px;
        width: 85%;
        /* margin-top: 118% !important; */
    }
    .timepicker-subtitle-minutes{
        margin: auto;
        font-size: 15px;
        width: 85%;
        margin-top: -16% !important;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .timepicker-back-button-size{
        max-width:35%;
        margin-top: 8%;
    }
    .timepicker-back-button{
        /* width: 20%; */
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 5px !important;
        border-radius: 30px!important;
        color: white !important;
        font-size: 12px;
    }
    .arrange-timepicker-clock-layout{
        width: 340px !important;
        margin-top:25% ;
        /* margin-left: -20%; */
    }
    /* end timepicker modal */
}


@media only screen and (max-width: 483px){
    .btn-primary-arrange-page {
        width: 9rem !important;
        margin-top: -2rem !important;
    }
    .middle-icon-footer-4 img {
        width: 70% !important;
        margin-left: 50%;
        transform: translate(-120%) !important;
        position: absolute;
        top: -25px !important;
    }
    .btn-success-arrange-page {
        padding: 6px 30px;
        font-size: 11px;
    }
    .btn-danger-arrange-page {
        font-size: 11px;
        padding: 6px 30px;
    }
}
@media only screen and (max-width: 469px){
    .arrange-no-buddies-text{
        color: #575578;
        font-size: 14px !important;
        margin-top: 35px;
        margin-bottom: 0px;

    }
    .plus-text-4 {
        max-width: 50.333333% !important;
    }
    .upper-text-4 h2 {
        font-size: 23px;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .arrow-left-position {   
        top:0px;
        left:-20px
    }
    .arrow-right-position {
        top:-195px;
        left:96%
    }
}
@media only screen and (max-width: 460px){
    .request-reservation-request-button{
        height: 50px;
        line-height: 35px;
        font-size: 25px;
    }
    .item-img-4 {
        width: 60px !important;
        height: 60px !important;
        background: #fff;
        margin: auto;
        line-height: 82px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 80px;
        height: 82px;
        margin: auto;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 22px;
        border-radius: 30px;
        font-weight: bold;
    }
    .top-back-4 img {
        width: 1.2rem;
        margin-left: 1rem;
    }
    .plus-text-4 {
        max-width: 58.333333% !important;
    }
    .btn-primary-arrange-page {
        width: 9rem !important;
    }
    .arrange-btn-4 {
        padding: 10px 45px !important;
        font-size: 18px !important;
    }
    .col-lg-8.col-md-8.col-sm-8.col-8.upper-text-4 {
        margin-left: -2rem;
    }
    .arrow.arrow-right {
        margin-left: -15px;
    }
    .col-lg-8.col-md-8.col-sm-8.col-8.upper-text-4 {
        margin-left: -3rem;
    }
    .item-div-4 {
        padding: 13px 0px !important;
        max-width: 80% !important;
        border-radius: 13px;
        margin-left: 4.66%;
        cursor: pointer;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
}
@media only screen and (max-width: 443px){
    .arrange-date-button-customize{
        font-size: 13px !important;
    }
    .item-text-4-username{
        font-size: 11px !important;
    }

    .arrange-page-buddies-slider{
        margin-top: -10px;
    }
    .btn-primary-arrange-page {
        width: 8rem !important;
    }
    .upper-text-4 h2 {
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .btn-success-arrange-page {
        padding: 6px 25px;
        font-size: 10px;
    }
    .btn-danger-arrange-page {
        font-size: 10px;
        padding: 6px 23px;
    }
    /* timepicker modal */
    .arrange-page-timepicker-box{
        margin-top: -260%;
        margin-left: -135%;
    }
    /* end timepicker modal */
    .arrange-invite-your-buddies-text{
        margin-top: -40px !important;
    }
    .request-reservation-persons-next{
        bottom: 24.5%;
        right: 3%;
        font-size: 16px;
        width: 20%;
    }
}
@media only screen and (max-width: 403px){
    .btn-primary-arrange-page {
        width: 7rem !important;
    }
    .upper-text-4 h2 {
        font-size: 19px;
    }
}
@media only screen and (max-width: 400px){
    .arrange-reservation-available-text{
        font-size: 14px !important;
    }
    .arrange-buddies-note-section{
        margin-top: -40px;
    }
    .item-img-4 {
        width: 70px;
        height: 73px;
        background: #fff;
        margin: auto;
        line-height: 73px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 28px;
    }
    .item-img-4p {
        width: 70px;
        height: 73px;
        margin: auto;
    }
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 4px 18px;
        border-radius: 30px;
        font-weight: bold;
    }
    .arrow {
        margin-top: 4rem;
    }
    .btn-primary-arrange-page {
        width: 7rem !important;
        margin-top: -70px !important;
        font-size: 13px;
    }
    .box-4-btn{
        margin-right: 10px;
    }
    .box-4-btn1{
        margin-left: -3px;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 19px;
    }
    .middle-icon-footer-4 img {
        width: 78% !important;
        margin-left: 50%;
        transform: translate(-110%) !important;
        position: absolute;
        top: -22px !important;
    }
    .footer-icon-p img {
        width: 35% !important;
    }
    .arrow-left-position {   
        top:0px;
        left:-25px
    }
    .arrow-right-position {
        top:-175px;
        left:96%
    }
    .btn-success-arrange-page {
        padding: 6px 20px;
        font-size: 10px;
    }
    .btn-danger-arrange-page {
        font-size: 10px;
        padding: 6px 18px;
    }
    /* timepicker modal */

    /* end timepicker modal */
}
@media only screen and (max-width: 376px){
    /* timepicker modal */
    .arrange-page-hubs-rules{
        font-size: 11px;
    }
    .arrange-page-time-number {
        margin: 8px auto;
    }
    .arrange-page-timepicker-box{
        margin-top: -235%;
        margin-left: -115%;
        width: 360%;
        height: 535px!important;
    }
    .timepicker-title{
        position: relative;
        font-size: 20px;
        width: 50%;
    }
    .arrange-timepicker-times-modal-size{
        width: 7% !important;
        margin-top: -8%;
    }
    .timepicker-subtitle-hour{
        font-size: 15px;
        width: 100% !important;
        margin-top: 0% !important;
    }
    .timepicker-subtitle-minutes{
        margin: auto;
        font-size: 15px;
        width: 100%;
        margin-top: -19% !important;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .timepicker-back-button-size{
        max-width:35%;
        /* margin-top: 140%; */
    }
    .timepicker-back-button{
        /* width: 20%; */
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 5px !important;
        border-radius: 30px!important;
        color: white !important;
        font-size: 12px;
    }
    .arrange-timepicker-clock-layout{
        width: 320px !important;
        margin-top:20% ;
        /* margin-left: -20%; */
    }
    /* end timepicker modal */
}

@media only screen and (max-width: 373px){
    .plus-text-4 {
        max-width: 60.333333% !important;
    }
    .col-lg-8.col-md-8.col-sm-8.col-8.upper-text-4 {
        margin-left: -2.5rem;
    }
    .btn-primary-arrange-page {
        width: 7rem !important;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
}
@media only screen and (max-width: 363px){
    .btn-primary-arrange-page {
        width: 6rem !important;
    }
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }
}

@media only screen and (max-width: 360px){
    .arrange-create-gang-icon{
        width: 20px !important;
        margin-top:15px;
    }
    .plus-text-4 {
        max-width: 63.333333% !important;
        padding: 0px 10px !important;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 4px 15px;
        border-radius: 30px;
        font-weight: bold;
    }
    /* timepicker modal */
    .arrange-page-hubs-rules{
        font-size: 10px;
    }
    .arrange-page-timepicker-box{
        margin-top: -235%;
        margin-left: -105%;
        width: 340% !important;
        height: 520px!important;
    }
    .timepicker-title{
        position: relative;
        font-size: 18px;
        width: 50%;
    }
    .arrange-timepicker-times-modal-size{
        width: 7% !important;
        margin-top: -8%;
    }
    .timepicker-subtitle-hour{
        font-size: 15px;
        /* margin-top: 143% !important; */
    }
    .timepicker-subtitle-minutes{
        margin: auto;
        font-size: 15px;
        width: 100% !important;
        margin-top: -19% !important;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .timepicker-back-button-size{
        max-width:35% !important;
        /* margin-top: 150%; */
    }
    .arrange-timepicker-clock-layout{
        width: 300px !important;
        margin-top:25% !important;
        /* margin-left: -20%; */
    }
    /* end timepicker modal */
}

@media only screen and (max-width: 349px){
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 17px;
    }
    .upper-text-4 .ml-5 {
        margin-left: 1.8rem !important;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
    .arrow-left-position {   
        top:0px;
        left:-25px
    }
    .arrow-right-position {
        top:-170px;
        left:100%
    }
}


@media only screen and (max-width: 342px){
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 4px 13px;
        border-radius: 30px;
        font-weight: bold;
    }
    .arrow.arrow-right {
        margin-left: -27px;
    }
    .plus-text-4 {
        max-width: 70.333333% !important;
    }
    .btn-primary-arrange-page {
        width: 6rem !important;
    }
    .top-back-4 img {
        width: .8rem;
        margin-left: 1rem;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
}
@media only screen and (max-width: 336px){
    .item-img-4 {
        width: 60px;
        height: 65px;
        background: #fff;
        margin: auto;
        line-height: 65px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 60px;
        height: 65px;
        margin: auto;
    }
    .item-img-4 img {
        width: 22px;
    }
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
        font-size: 12px !important;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 2px 14px;
        border-radius: 30px;
        font-weight: bold;
        font-size: 14px;
    }
    .btn-primary-arrange-page {
        width: 6rem !important;
        font-size: 13px;
        padding: 8px 0px !important;
    }
    .arrange-btn-4 {
        padding: 8px 38px !important;
        font-size: 18px !important;
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
}
@media only screen and (max-width: 323px){
    .ml-3, .mx-3 {
        margin-left: 0rem!important;
    }
    .plus-text-4 span {
        font-weight: bold;
        font-size: 14px;
    }
    .btn-primary-arrange-page {
        width: 5rem !important;
        font-size: 13px;
        padding: 8px 0px !important;
    }
    .middle-icon-footer-4 img {
        width: 88% !important;
        margin-left: 50%;
        transform: translate(-105%) !important;
        position: absolute;
        top: -20px !important;
    }
    .footer-icon-p img {
        width: 40% !important;
    }
    .upper-text-4 h2 {
        font-size: 16px;
    }
    .btn-success-arrange-page {
        padding: 6px 15px;
        font-size: 10px;
    }
    .btn-danger-arrange-page {
        font-size: 10px;
        padding: 6px 13px;
    }
}

@media only screen and (max-width: 320px){
    /* timepicker modal */
    .arrange-page-time-number {
        margin: 6px auto;
    }
    .arrange-page-timepicker-box{
        margin-top: -230%;
        margin-left: -105%;
        width: 320%;
        height: 500px!important;
    }
    .timepicker-title{
        position: relative;
        font-size: 18px;
        width: 50%;
    }
    .arrange-timepicker-times-modal-size{
        z-index: 999999 !important;
    }
    .request-reservation-timepicker-layout{
        margin-top: -16px !important;
        margin-left: -5%;
    }
    .timepicker-subtitle-hour{
        /* font-size: 12px;
        width: 45% !important; */
        /* margin-top: 151% !important; */
    }
    .timepicker-subtitle-minutes{
        margin: auto;
        font-size: 12px;
        width: 50%;
        margin-top: -12% !important;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 50%;
    }
    .timepicker-back-button-size{
        max-width:35%;
        /* margin-top: 154%; */
    }
    .timepicker-back-button{
        /* width: 20%; */
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 5px !important;
        border-radius: 30px!important;
        color: white !important;
        font-size: 10px;
    }
    /* end timepicker modal */
}

@media only screen and (max-width: 309px){
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
    }
    .plus-text-4 {
        max-width: 77.333333% !important;
    }
    .btn-primary-arrange-page {
        width: 5rem !important;
        font-size: 13px;
    }
    .plus-icon-4 {
        display: inline-block;
        width: 35px;
        /* height: 50px; */
    }
    .box-4-btn{
        width: 40% !important;
    }
    .box-4-btn1{
        width: 40% !important;
    }
}


@media only screen and (max-width: 285px){
    .item-div-4 {
        padding: 20px 0px !important;
        max-width: 80% !important;
        border-radius: 13px;
        margin-left: 4.66%;
        cursor: pointer;
    }
    .upper-text-4 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
    }
    .upper-text-4 .ml-5 {
        margin-left: 1.4rem !important;
    }
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
        font-size: 12px;
    }
    .item-img-4 {
        width: 41px;
        height: 45px;
        background: #fff;
        margin: auto;
        line-height: 45px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 41px;
        height: 45px;
        margin: auto;
    }
    .btn.btn-info.item-btn-4 {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 2px 6px;
        border-radius: 30px;
        font-weight: bold;
        font-size: 11px;
    }
    .item-img-4 img {
        width: 18px;
    }
    
    .arrow img {
        width: 11px;
    }
    .btn-primary-arrange-page {
        width: 4rem !important;
        font-size: 13px;
    }
    label {
        font-size: 14px;
    }
    .arrange-btn-4 {
        padding: 6px 30px !important;
        font-size: 18px !important;
    }
    .arrow-left-position {   
        top:0px;
        left:-25px
    }
    .arrow-right-position {
        top:-155px;
        left:100%
    }    
}



/* Footer icon css start */
.nav-bottom-4{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon-4 img {
    width: 22%;
}
/* .middle-icon-footer img {
    position: absolute;
    top: -30px;
    width: 92px !important;
    left: 30px;
} */
.middle-icon-footer-4 img {
    width: 60%;
    margin-left: 50%;
    transform: translate(-140%);
    position: absolute;
    top: -46px;
}
.footer-width-4{
    width: 20% !important;
}
/* Footer icon css end */


