/*hubs-terms modal */
.hubs-terms-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
    width: 600% !important;
    height: 350%;
    margin-top: 0%;
    margin-left: 180%;
}
.login-hubs-terms-box {
    margin-top: -115%;
    margin-left: -420%;
    /* width: 950%; */
} 
.login-hubs-terms-icon{
    z-index: 99999999999;
    width: 30%;
    margin-left: 35% !important;
}
.login-hubs-terms-top-text{
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 50px;
}
.accept-hubs-terms-btn-success{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px!important;
    color: white!important;
    /* margin: auto!important; */
    margin-left: 57%!important;  
    margin-top: 20px; 
}
/* end hubs-terms modal */

@media only screen and (max-width: 780px) {
    /*hubs-terms modal */
    .login-hubs-terms-box {
        margin-left: -410%;
    } 
    /* end hubs-terms modal */    
}

@media only screen and (max-width: 650px) {
    /*hubs-terms modal */
    .hubs-terms-box-6 {
        width: 400% !important;
        height: 350%;
    }
    .login-hubs-terms-box {
        margin-top: -115%;
        margin-left: -310%;
    } 
    .accept-hubs-terms-btn-success{
        margin-left: 64%!important;   
    }
    /* end hubs-terms modal */
}

@media only screen and (max-width: 460px) {
    /*hubs-terms modal */
    .hubs-terms-box-6 {
        width: 350% !important;
    }
    .login-hubs-terms-box {
        margin-left: -290%;
    } 
    /* end hubs-terms modal */
}

@media only screen and (max-width: 359px) {
    /*hubs-terms modal */
    .hubs-terms-box-6 {
        width: 300% !important;
        height: 400%;
    }
    .login-hubs-terms-box {
        margin-left: -265%;
    } 
    .accept-hubs-terms-btn-success{
        margin-left: 70%!important;   
    }
    /* end hubs-terms modal */
}