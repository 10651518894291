.magazine-bottom-see-more{
    position: absolute;
    width: 100%;
    height: 3%;
    background-color: #f7f7f7!important;
    bottom: 0%;
    text-align: center;
    color: #1183ca;

}

.setup-page-magazine-bottom-place-row{
    margin-left: 0%;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom:3.5%;
    z-index: 999!important;
}


.setup-page-magazine-bottom-page-description{
    width: 70%;
    height:40px;
    padding-top: 0px;
    line-height: 18px;
    padding-left: 3%;
    padding-right: 3%;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    /* background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%); */
}

.setup-page-magazine-bottom-page-price{
    width: 30%;
    height:40px;
    /* padding-top: 10px; */
    line-height: 18px;
    padding-left: 3%;
    padding-right: 3%;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    /* background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%); */
    text-align: right;
}


.magazine-qr-modal-box{
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999999999!important;
}

.magazine-qr-acces-modal-times-box{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0%;
    right: 0%;
    /* background-color: brown; */
}

.magazine-qr-acces-modal-times-img{
    width: 50%;
    height: 50%;
    margin-top: 2%;
    margin-left: 45%;
}
.magazine-qr-modal-title{
    width: 70%;
    margin: auto;
    text-align: center;
    color: #232231;
    height: 10%;
    margin-top: 3%;
}
.magazine-qr-modal-qr-box{
    /* width: 320px;
    height: 320px; */
    margin: auto;
    margin-top: 6%;
}

.magazine-qr-modal-qr-img{
    width: 100%;
    height: 100%;
}

.magazine-qr-modal-download-btn{
    width: 50%;
    height: 40px;
    background: #1183ca !important;
    color: #fff !important;
    text-align: center;
    border-radius: 4px;
    /* padding: 10px; */
    line-height: 40px;
    font-weight: bold;
    z-index: 999999999999;
    position: absolute;
    bottom: 5px;
    left: 25%;
}

.magazine-page-download-qr{
    position: absolute;
    width:40px;
    height: 40px;
    right: 2%;
    bottom: 2%;
    /* background: #fff; */
    background-size: cover;
    border-radius: 50% !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
}

.magazine-top-navbar-catalog{
    margin-left: 0%;
    display: block;
    font-size: 16px;
    /* font-family: sans-serif; */
    font-weight: bold;
    width: 100%;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 0%;
    left: 0%;
    text-align: center;
    background-size: cover;
    /* border-radius: 0px 0px 10px 10px !important; */
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    /* background-color: #ffff; */
    z-index: 999;  
    color: #232231;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    position: fixed !important;

}

.magazine-has-more-catalogs{
    width:20px;
    height: 35px;
    /* background-color: chartreuse; */
    position: absolute;
    top: 0%;
    right: 2%;
}

.magazine-has-more-catalogs-don{
    width:20px;
    height: 35px;
    /* background-color: chartreuse; */
    position: absolute;
    bottom: 0%;
    right: 2%;
    transform: rotate(180deg);
    z-index: 999;
}

.magazine-page-catalog-mini-slider{
    position: absolute;
    width: 60%;
    bottom: 2%;
    left: 20%;
    height: 12%;
    z-index: 9999;
    /* background-color: red; */
}

.magazine-page-catalog-mini-slider-up{
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0%;
    height: 32%!important;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    position: fixed !important;
    z-index: 999999;
}

.magazine-page-catalog-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.magazine-page-catalog-buddies-username{
    font-size: 18px;
    font-weight: bold;
    position: relative;
    text-align: center;
    top: 10%;
    left: 0%;
    width: 100%;
    /* background-color: blue; */
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.magazine-page-catalog-buddies-username-small{
    font-size: 11px;
    /* font-weight: bold; */
    position: relative;
    text-align: center;
    top: 10%;
    left: 0%;
    width: max-content;
    margin: auto;
    background-color: blue;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.magazine-page-catalog-buddies-username-small-up{
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    bottom: 0%;
    left: 0%;
    width: 100%;
    color: black;
}

.magazine-page-catalog-buddies-card{
    position: relative;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    border-radius: 0px;
}


.magazine-page-catalog-buddies-card-square{
    /* background-color: #000; */
    width: 100% !important;
    height: 100%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 0px 0px 0px 0px;
}

.magazine-page-catalog-select-budies-frame{
    position: relative;
}

.magazine-catalogs-box{
    width: 100%;
    height: 75%;
    position: absolute;
    top: 10%;
    left: 0%;
    /* background-color: blue; */
}

.magazine-top-navbar-logo{
    display: none;
}

.magazine-top-navbar-back{
    position: absolute;
    width: 100px;
    height: 40px;
    top: 0%;
    left: 0%;
    z-index: 999999;
    border-radius: 0px 0px 30px 0px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    background-color: #ffff;
    z-index: 999;  
    color: #575578; 
    font-weight: bold;
}

.magazine-top-navbar-back-2{
    position: absolute;
    width: 100px;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: 999;  
    color: #575578; 
    font-weight: bold;
}

.magazine-top-navbar-back2{
    position: absolute;
    /* width: 105px; */
    width: max-content;
    padding-right: 10px;
    height: 30px;
    top: 0%;
    left: 0%;
    z-index: 999999;
    border-radius: 0px 0px 10px 0px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    background-color: #ffff;
    z-index: 999;  
    color: #575578; 
    font-weight: bold;
}

.magazine-top-navbar-back-box{
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: -1px;
}

.magazine-top-navbar-back-icon{
    width: 100%;
    height: 100%;
}

.magazine-bottom-navbar{
    display: none;
}

.magazine-all-magazine-size-none{
    display: none;
}

.magazine-all-magazine-size{
    display: block;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 6%;
    top: 0%;
    /* background-color: red; */
    /* background-color: hsl(35, 55, 98);
    border: solid 1px hsl(35, 20, 70);
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4); */
}
.magazine-all-magazine-size-small{
    display: none;
}

.magazine-zoom-all-magazine-size{
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0%;
    top: 0%;
    background-color: black;
}

.magazine-zoom-page-size{
    /* width: 100%;
    height: 100%;
    background-color: rgba(36, 10, 3, 0.5);
    position: absolute;
    left: 0%;
    top: 0%; */
    position: relative;
    width: 100% !important;
    height: 100% !important;
    /* left: 0%;
    top: 0%; */
    color:  hsl(35, 35, 35);
    border: solid 1px hsl(35, 20, 50);
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    z-index: 99999999999;
}

.magazine-zoom-page-img-size{
    background-color: hsl(35, 55, 98);
    /* position: absolute; */
    width: 100%;
    height: 100%;
    /* left: 0%;
    top: 0%; */
    object-fit: contain;  
}

.magazine-page-size{
    position: relative;
    width: 50% !important;
    height: 100% !important;
    /* left: 0%;
    top: 0%; */
    /* background-color: rgba(35, 45, 80); */
    color:  hsl(35, 35, 35);
    border: solid 1px hsl(35, 20, 50);
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    /* background-color: rgba(14, 182, 8, 0.5); */
}

.magazine-page-img-size{
    background-color: hsl(35, 55, 98);
    /* position: absolute; */
    width: 50%;
    height: 10%;
    /* left: 0%;
    top: 0%; */
    object-fit: contain;
}

.magazine-cover-page-img-size{
    background-color: hsl(35, 55, 98);
    /* position: absolute; */
    width: 100%;
    height: 100%;
    /* left: 0%;
    top: 0%; */
    object-fit: contain;
}

@media only screen and (max-width: 1200px){
    .magazine-all-magazine-size{
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        left: 0% !important;
        top: 0% !important;
        /* background-color: red; */
    
        /* background-color: hsl(35, 55, 98);
        border: solid 1px hsl(35, 20, 70);
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4); */
    }
    
    .magazine-page-size{
        position: absolute;
        width: 100% !important;
        height: 81% !important;
        left: 0% !important;
        top: 0% !important;
        color:  hsl(35, 35, 35);
        border: solid 1px hsl(35, 20, 50);
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
        /* background-color: rgba(14, 182, 8, 0.5); */
    }
    
    .magazine-page-img-size{
        background-color: hsl(35, 55, 98);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
        object-fit: contain;
    }
}




@media only screen and (max-width: 660px){
    .magazine-all-magazine-size-pc{
        display: none;
    }
    
    .magazine-all-magazine-size-responsive{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
        background-color: hsl(35, 55, 98);
        border: solid 1px hsl(35, 20, 70);
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }
    
}

@media only screen and (max-width: 490px){
    .magazine-top-navbar-back{
        width: 85px;
        height: 30px;
        border-radius: 0px 0px 20px 0px;
    }

    .magazine-top-navbar-layout{
        width:100%;
        height: 8%;
        position: absolute;
        top: 0%;
        z-index: 999;  
    }

    .magazine-top-navbar-logo{
        display: block;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        width: 70%;
        height: 70%;
        position: absolute;
        top: 0%;
        left: 15%;
        text-align: center;
        background-size: cover;
        border-radius: 0px 0px 30px 30px !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
        background-color: #ffff;
        z-index: 999;  
        color: #575578; 
    }


    .magazine-bottom-navbar{
        display: block;
        width:100%;
        height: 10%;
        position: absolute;
        bottom: 0%;
        z-index: 999;
    }

    .magazine-bottom-navbar-small-1{
        width:10%;
        height: 10%;
        position: absolute;
        left: 0%;
        top: 45%;
    }

    .magazine-bottom-navbar-small-zoom-1{
        width:15%;
        height: 100%;
        position: absolute;
        left: 5%;
    }

    .magazine-bottom-navbar-small-zoom-4{
        width:15%;
        height: 100%;
        position: absolute;
        left: 85%;
    }

    .magazine-bottom-navbar-small-2{
        width:15%;
        height: 100%;
        position: absolute;
        left: 15%;
    }

    .magazine-bottom-navbar-big{
        width:40%;
        height: 100%;
        position: absolute;
        left: 30%;
        text-align: center;
    }

    .magazine-bottom-navbar-big-zoom{
        width:50px;
        height: 50px;
        position: absolute;
        right: 3%;
        top: 63px;
    }

    .magazine-bottom-navbar-small-3{
        width:15%;
        height: 100%;
        position: absolute;
        left: 70%;
    }

    .magazine-bottom-navbar-small-4{
        width:10%;
        height: 10%;
        position: absolute;
        left: 90%;
        top: 45%;
    }

    .magazine-bottom-navbar-btn{
        position: absolute;
        width:45px;
        height: 45px;
        left: -10%;
        top: 15%;
        /* background: #fff; */
        background-size: cover;
        border-radius: 50% !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
        background-color: #f7f7f7;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
        background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    }

    .magazine-bottom-navbar-btn-zoom{
        position: absolute;
        width:45px;
        height: 45px;
        left: -10%;
        top: 15%;
        /* background: #fff; */
        /* background-size: cover;
        border-radius: 50% !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
        background-color: #f7f7f7;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
        background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);  */
    }

    .magazine-bottom-navbar-big-btn{
        position: absolute;
        width:90%;
        height: 50px;
        left: 5%;
        top: 10%;
        /* background: #fff; */
        background-size: cover;
        border-radius: 30px !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
        background-color: #ffff;
        /* background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
        background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);  */
    }

    .magazine-bottom-zoom-out-btn{
        position: absolute;
        width:90%;
        height: 50px;
        background-color: red;
        line-height: 50px;
        left: 5%;
        top: 10%;
        border-radius: 4px;
    }

    .magazine-bottom-navbar-big-btn-zoom{
        position: absolute;
        width:100%;
        height: 100%;
        /* background: #fff; */
        background-size: cover;
        border-radius: 30px !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
        background-color: #ffff;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
        background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
        background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    }

    .magazine-bottom-navbar-logo-zoom{
        width: 80%;
        margin-left: 10%;
        height: 80%;
        margin-top: 10%;

    }

    .magazine-bottom-navbar-logo{
        width: 70%;
        height: 100%;
        object-fit: contain;
        margin: auto;
    }

    .magazine-bottom-navbar-icon{
        width: 60%;
        height: 60%;
        object-fit: contain;
        margin-left: 20%;
        margin-top: 20%;
        /* background-color: slateblue; */
    }

    .magazine-zoom-page-size{
        position: relative;
        width: 100% !important;
        height: 500px !important;
        left: 0%;
        margin-top: 30%;
    }

    .magazine-all-magazine-size{
        display: none;
    }

    .magazine-all-magazine-size-small{
        display: block;
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        left: 0%;
        top: 0%; 
    }

    .magazine-page-size{
        position: relative;
        width: 100% !important;
        height: 100% !important;
        /* left: 0%;
        top: 0%; */
        color:  hsl(35, 35, 35);
        border: solid 1px hsl(35, 20, 50);
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
        /* background-color: rgba(14, 182, 8, 0.5); */
    }
    
    .magazine-page-img-size{
        background-color: hsl(35, 55, 98);
        /* position: absolute; */
        width: 100%;
        height: 100%;
        /* left: 0%;
        top: 0%; */
        object-fit: cover;
    }
}



@media only screen and (max-width: 350px){
    .magazine-bottom-navbar-btn{
        position: absolute;
        width:40px;
        height: 40px;
        left: 15%;
        top: 15%;
    }
    .magazine-bottom-navbar-big-btn{
        position: absolute;
        width:90%;
        height: 45px;
        left: 5%;
        top: 8%;
    }
}