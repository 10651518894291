.show-more-box{
    position: absolute;
    padding: 5px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -180%;
    margin-left: -100%;
    height: 400px!important;
    width: 350%;
}
.show-more-text{
    color: #3F1E75 !important;
}
.show-more-field{
    margin-top: 3%;
    font-size: 15px !important;
    margin-left: 0% !important;

}
.show-more-icon{
    width: 20px;
    height: 20px;
}


@media only screen and (max-width: 540px){
    .show-more-field{
        margin-top: 3%;
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 400px){
    .show-more-box{
        margin-top: -205%;
        margin-left: -125%;
        width: 380%;
        height: 470px!important;
    }
}

@media only screen and (max-width: 376px){
    .show-more-box{
        margin-top: -205%;
        margin-left: -125%;
        width: 380%;
        height: 470px!important;
    }
}
@media only screen and (max-width: 346px){
    .show-more-box{
        margin-top: -205%;
        margin-left: -105%;
        width: 340%;
        height: 440px!important;
    }
}