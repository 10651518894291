
.hub-grp {
    width: 125px;
    margin-top: -25px;
}
.hub-grp-small {
    width: 80px;
    margin-top: -25px;
}
.hub-logo-size-big{
    border-radius: 50%;
    margin-left: 5%;
    margin-top: -20%;
    width: 120px!important;
    height: 120px!important;
    border: 10px solid #FFFAFA;
}


@media only screen and (max-width: 1200px){
    .top-logo-section-12{
        display: none;
    }
}