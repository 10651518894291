.conversation-room-conversation-text-image-box {
    margin-top: 5px;
    width: 100px;
    height: 130px;
    border-radius: 4px;
    margin-bottom: 5px;
}

.conversation-room-conversation-text-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.conversation-room-uploaded-photo-box {
    position: relative;
    background-color: black;
    width: 24vw !important;
    height: 24vh !important;
    bottom: 22.5vh;
    left: 5vw;
    border-radius: 4px;
    z-index: 999;
}

.conversation-room-uploaded-photo-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down !important;
    z-index: 999;
}

.conversation-room-uploaded-photo-x-box {
    width: 20px;
    height: 20px;
    background-color: #e4e5e7;
    position: absolute;
    bottom: 28vh;
    left: 25vw;
    z-index: 9999;
    border-radius: 50%;
}

.conversation-room-uploaded-photo-x-img {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
}

.conversation-room-senf-img-box {
    position: absolute;
    bottom: 15px;
    width: 40px;
    height: 40px;
    z-index: 9 !important;
    left: 3.5%;
}

.conversation-room-senf-img {
    width: 90%;
    height: 90%;
    margin-top: 5%;
}

.conversation-room-input-field {
    position: absolute;
    min-height: 40px !important;
    line-height: 34px !important;
    width: 94%;
    left: 3%;
    bottom: 15px;
    border: none;
    border-radius: 30px;
    background-color: #e4e5e7 !important;
    padding-left: 40px;
    z-index: 9;
    padding-right: 18%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    white-space: initial;
    word-wrap: break-word !important;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: justify;
}

.conversation-room-input-field:focus {
    outline: none;
    border: 1px solid rgba(114, 114, 114, 0.8);
    border-radius: 20px;
    background-color: #fff !important;
}

.conversation-room-input-field-with-img {
    position: absolute;
    min-height: 40px !important;
    line-height: 34px !important;
    width: 98%;
    left: 1%;
    bottom: 15px;
    border: none;
    border-radius: 30px;
    background-color: #e4e5e7 !important;
    padding-left: 120px;
    z-index: 9;
    padding-right: 18%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    white-space: initial;
    word-wrap: break-word !important;
    padding-top: 20px;
    padding-bottom: 3px;
    text-align: justify;
    word-break: normal;
}

.conversation-room-input-field-with-img:focus {
    outline: none;
    border: 1px solid rgba(114, 114, 114, 0.8);
    border-radius: 20px;
    background-color: #fff !important;
}

.conversation-room-type-message-bar {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 50px;
    position: fixed !important;
    z-index: 999;
}


.conversation-room-input-send {
    /* width: 30%; */
    height: 40px;
    position: absolute;
    z-index: 99;
    right: 8%;
    bottom: 15px;
    line-height: 40px;
    font-weight: bold;
    color: #1183ca;
}

.conversation-room-input-send-grey {
    /* width: 30%; */
    height: 40px;
    position: absolute;
    z-index: 99;
    right: 8%;
    bottom: 15px;
    line-height: 40px;
    font-weight: bold;
    color: #969797;
}

.conversation-messages-no-messages-box {
    position: absolute;
    width: 70%;
    height: 50%;
    left: 15%;
    top: 63px;
}

.conversation-messages-no-messages-profile-pic-box {
    position: relative;
    width: 90px;
    height: 90px;
    margin: auto;
    margin-top: 10%;
}

.conversation-messages-no-messages-profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.conversation-messages-no-messages-text-margin {
    margin-top: 10%;
}

.conversation-messages-no-messages-username {
    color: #575578;
    text-align: center;
    font-size: 16px;
    width: 100%;
    height: 20px;
    line-height: 20px;
    margin-top: 0px;
}

.conversation-messages-no-messages-visit {
    width: 120px;
    height: 28px;
    line-height: 25px;
    background: #fff !important;
    color: rgb(44, 43, 43) !important;
    font-size: 16px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    border: solid 1px rgb(204, 204, 204);
    border-radius: 6px;

}

.conversation-top-pinelo-box {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0%;
    right: 1%;
    /* background-color: khaki; */
}

.conversation-top-pinelo-img {
    position: absolute;
    /* width: 100%; */
    height: 50%;
    top: 25%;
    left: 25%;
}

.conversation-room-seen-box {
    width: 30%;
    height: 30px;
    /* background-color: rgb(199, 181, 17); */
    position: absolute;
    top: 100%;
    right: 0%;
    text-align: center;
    color: #232231;
}

.conversation-message-item-box {
    position: relative;
    z-index: 9 !important;
    margin-top: 5%;
    padding-right: 5%;
    width: 100%;
    margin-left: 0px;
}

.conversation-room-conversator-profile-pic-box {
    position: absolute;
    width: 40px;
    height: 40px;
    /* background-color: tomato; */
    z-index: 99999;
    bottom: 3%;
    left: 2%;
}

.conversation-room-conversator-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.conversation-room-text-braek {
    word-break: break-all;
}

.conversation-room-conversation-text-message {
    /* width: 70%; */
    margin: auto;
    padding: 7px 10px 7px 10px !important;
    border-radius: 18px;
    width: max-content;
    height: max-content;
    max-width: 80%;
    margin-left: 16%;
    /* min-width: 50px;
    min-height: 50px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 15px, rgba(0, 0, 0, 0.22) 0px 10px 7px;
}

.conversation-room-conversator-message-color-initial {
    color: #232231;
    background-color: #fff;
    border: 2px solid rgb(214, 213, 213);
}


.conversation-room-conversation-text-empty {
    color: #232231;
    background-color: #fff;
    /* width: 70%; */
    margin: auto;
    padding: 10px 20px 10px 50px !important;
    border-radius: 18px;
    border: 2px solid rgb(214, 213, 213);
    width: max-content;
    max-width: 80%;
    margin-left: 16%;
    height: 50px;
}

.conversation-room-conversation-my-text-message {
    text-align: justify;
    padding: 7px 10px 7px 10px !important;
    /* padding: 10px 50px 10px 20px !important; */
    border-radius: 18px;
    width: max-content;
    max-width: 80%;
    /* min-width: 50px;
    min-height: 50px; */
    float: right;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 15px, rgba(0, 0, 0, 0.22) 0px 10px 7px;

}

.conversation-room-message-color-initial {
    color: #232231;
    background-color: rgb(222, 222, 223);
    border: 1px solid rgb(255, 255, 255);
}


.conversation-room-message-color-blue {
    background: rgb(0, 212, 255);
    background: linear-gradient(0deg, rgba(0, 212, 255, 1) 0%, rgba(97, 97, 241, 1) 100%);
    color: #fff;
}

.conversation-room-message-color-roze {
    background: rgb(218, 170, 241);
    background: linear-gradient(0deg, rgba(218, 170, 241, 1) 0%, rgba(241, 97, 148, 1) 100%);
    color: #fff;
}

.conversation-room-message-color-hbstr {
    background: rgb(12, 53, 185);
    background: linear-gradient(180deg, rgba(12, 53, 185, 1) 0%, rgba(32, 73, 191, 1) 18%, rgba(121, 47, 145, 1) 40%, rgba(205, 62, 125, 1) 72%, rgba(250, 119, 143, 1) 100%);
    color: #fff;
}

.conversation-room-message-color-green {
    background: rgb(34, 193, 195);
    background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(106, 194, 139, 1) 100%);
    color: #fff;
}

.conversation-room-message-color-red {
    background-color: #6b0f1a;
    background-image: linear-gradient(0deg, #6b0f1a 0%, #b91372 74%);
    color: #fff;
}

.conversation-room-message-color-pale {
    background-color: #ebbe9b;
    background-image: linear-gradient(315deg, #ebbe9b 0%, #e7a977 74%);
    color: #fff;
}

.conversation-room-message-color-white {
    background-color: #b8c6db;
    background-image: linear-gradient(180deg, #b8c6db 0%, #f5f7fa 74%);
    color: #232231;
}

.conversation-room-message-color-orange {
    background-color: #f5d020;
    background-image: linear-gradient(0deg, #f5d020 0%, #e45027 74%);
    color: #fff;
}


.conversation-message-row-correction {
    width: 100%;
    height: 100%;
}

.conversation-room-conversation-my-text-empty {
    /* position: unset; */
    color: #232231;
    background-color: rgb(222, 222, 223);
    padding: 10px 20px 10px 50px !important;
    border-radius: 10px;
    position: relative;
    /* margin-left: 28%; */
    border: 1px solid rgb(255, 255, 255);
    /* min-width: 10%;
    max-width: 70%; */
    width: max-content;
    float: right;
    max-width: 80%;
    height: 50px;
}

.conversation-room-my-play-audio-box {
    position: absolute;
    top: 0%;
    right: 5%;
    width: 50px;
    height: 100%;
}

.conversation-room-other-play-audio-box {
    position: absolute;
    top: 0%;
    left: 16%;
    width: 50px;
    height: 100%;
    /* background-color: tomato; */
}

.conversation-room-my-play-audio-img {
    width: 60%;
    height: 60%;
    margin-left: 20%;
    margin-top: 20%;
    position: unset;
}

.conversation-all-message-items-box {
    height: auto;
    min-height: calc(100%);
    max-height: calc(100%);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* margin-top: 63px; */
    padding-top: 63px;
}



.audioplayer {
    position: absolute;
    top: 10%;
    z-index: 99999999;
    left: 0%;
    width: 100%;
    height: 20%;
    /* background-color: brown; */
}

.conversation-room-conversator-unseen {
    color: #2e9225;
    font-weight: bold;
}

.conversation-room-conversator-new-message {
    position: absolute;
    bottom: 0%;
    width: 100%;
    left: 0%;
    /* height: 10%; */
    background: #1183ca !important;
    padding: 5px 5px !important;
    border-radius: 2px !important;
    color: #fff !important;
    text-align: center;
    font-size: 20px;
    position: fixed !important;
    z-index: 999;
}

.conversation-room-conversator-title {
    position: absolute;
    width: 80%;
    height: 10%;
    left: 6%;
    top: 10%;
    color: #232231;
    font-weight: bold;
    /* background-color: blueviolet;  */
}

.conversation-room-conversator-message-box {
    width: 31%;
    height: 90%;
    margin: auto;
    /* margin-left: 2%; */
    margin-top: 0%;

    /* background-color: rgb(67, 50, 228) */
}

.conversation-room-conversator-all-messages {
    width: 91%;
    height: 70%;
    margin-left: 4.5%;
    margin-top: 14%;
    /* background-color: aqua */
}


.conversation-room-conversator-audio-box {
    position: relative;
    margin-top: 3px;
    margin-left: 20%;
    width: 60%;
    /* background-color: coral; */
}

.conversation-room-conversator-audio-length {
    width: 70%;
    height: 20%;
    text-align: center;
    color: #232231;
    font-size: 11px;
    margin-left: 15%;
    margin-top: 5px;
    padding-top: 5%;
}

.conversation-room-conversator-audio-img-unread {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 5px;
    background: #3ab0b4;
    background: -webkit-linear-gradient(to bottom, #97fc45, #2e9225, #3ab47b);
    background: linear-gradient(to bottom, #97fc45, #2e9225, #3ab47b);
    /* border-radius: 50%; */
}

.conversation-room-conversator-audio-img-readed {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 5px;
    background: #acadad;
    background: -webkit-linear-gradient(to bottom, #dcdddc, #757575, #c0c2c1);
    background: linear-gradient(to bottom, #dcdddc, #757575, #c0c2c1);
    /* border-radius: 50%; */
}

.conversation-room-conversator-audio-img-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 5px;
    background: #3ab0b4;
    background: -webkit-linear-gradient(to bottom, #899ee2, #201e9e, #455ecc);
    background: linear-gradient(to bottom, #5671c9, #201e9e, #455ecc);
}

.conversation-room-conversator-audio-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.conversation-room-my-profile-pic-box {
    position: absolute;
    top: 89%;
    left: 2%;
    width: 60px;
    height: 60px;
}



.conversation-room-sending-profile-pic-box {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    /* left: 38px; */
}

.conversation-room-sending-profile-pic-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 0%;
}


.conversation-room-sending-username-link {
    position: absolute;
    width: 70%;
    left: 15%;
    height: 50px;
}


.conversation-room-sending-username-align {
    text-align: left;
    margin-left: 35px;
    color: #232231;
}

.back-button-conversation-room-box {
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;

}

.conversation-room-back-button {
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.conversation-room-total-page-layout {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0px !important;
    left: 0px !important;
    background: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px -100px 120px -50px inset; */
    /* box-shadow:0px 5px 30px 10px rgba(0,0,0,0.5) inset; */
}

.conversation-room-sound-proof-box {
    display: none;
}

.conversation-room-my-message-bubble {
    position: absolute;
    top: 56%;
    left: 5%;
    width: 90%;
    height: 30%;
    border-radius: 38px;
    background-color: #ffff;
    z-index: 1;
    /* border: 2px solid rgb(241, 241, 241); */
}

.conversation-room-conversator-bubble-triangle {
    position: absolute;
    top: 82%;
    left: 9%;
    width: 40px;
    height: 40px;
}

.conversation-room-conversator-bubble-triangle-up {
    position: absolute;
    top: 20%;
    right: 9%;
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
}

.conversation-room-conversator-bubble-triangle-img {
    width: 100%;
    height: 100%;
}

.conversation-room-conversator-message-bubble {
    position: absolute;
    top: 23%;
    left: 5%;
    width: 90%;
    height: 30%;
    border-radius: 38px;
    background-color: #ffff;
    z-index: 1;
    /* border: 2px solid rgb(241, 241, 241); */

}



@media only screen and (max-width: 1200px) {
    .conversation-room-mobile-navbar {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 9999 !important;
    }

    .conversation-room-sound-proof-box {
        display: block;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .conversation-room-sound-proof-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
    }
}

@media only screen and (max-width: 340px) {
    .conversation-room-conversator-audio-length {
        width: 70%;
        height: 20%;
        text-align: center;
        color: #232231;
        font-size: 9px;

    }
}