

.all-page-touches-invisible-box{
    width: 100%;
    height: 83%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 99999999999999999;
}

.prolfie-close-street-view-btn{
    position: absolute;
    bottom: -50px;
    left: 35%;
    width: 30%;
    height: 30px;
    background: #d60c27;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    z-index: 999999999999;
}

.suggestion-item{
    z-index: 999;
}

.prolfie-show-more-modal{
    width: 100%;
    height: max-content;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 9999999999999999 !important;
}

.prolfie-show-more-single-box{
    width: 90%;
    height: 50px;
    margin-left: 5%;
    background-color: #fff;
    border-radius: 30px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
}
.prolfie-show-more-single-row{
    width:max-content;
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
    margin: auto;
    
}

.prolfie-show-more-image{
    width: 40px;
    height: 30px;
    /* margin-top:10px */
}

.prolfie-show-more-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit:cover;
    margin-top: -0px;
    margin-left: 0%;
}

.prolfie-show-more-x-box{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
}

.prolfie-story-doubleclick{
    width: 50%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 999999999999;
}

.map-sarch-top-chat-position{
    width:10%;
    height:100%;
    position: absolute;
    right: 0%;
    top: 0%;
}
.map-sarch-top-chat-img{
    width:65%;
    height: 65%;
    position: absolute;
    left: 10%;
    top: 18%;
    /* background-color: rgb(255, 0, 255); */
}

.prolfie-story-all-black{
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: black;
    z-index: 999 !important;
}

.prolfie-story-index{
    z-index: 9999999999999999999999 !important;
}

.story {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100px;
    height:100px;
    overflow:hidden;
  }
  .story img {
    width:70px;
    border-radius:50%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
  .story svg {
    fill:none;
    stroke:#8a3ab8;
    stroke-width:3px;
    stroke-dasharray:1;
    stroke-dashoffset:0;
    stroke-linecap:round;
    animation:loading 4500ms ease-in-out infinite alternate;
  }
  @keyframes loading {
    100% {
      stroke:#cd476b;
      stroke-dasharray:10;
      transform:rotate(200deg);
    }
  }
  

.prolfie-all-story-section{
    width:100%;
    height: 80px;
    position:absolute;
    top:53px;
    left:0px;
    z-index: 99!important;
    margin-left:0px;
    background: linear-gradient(0deg, transparent 30%, #fff 90%);
    padding-top: 100px;
}

.prolfie-my-story-profile-plus-img{
    position: absolute;
    bottom: 22px;
    right: 22px;
    width: 26px;
    height: 26px;
    z-index: 99999999999 !important;
}

.prolfie-story-slider{
    width:100%;
    height:100%;
    position:absolute;
    top: 0%;
    left:0%;
    /* background-color:red; */
}

.prolfie-my-story-box{
    width:80%;
    height: 80px;
    margin: auto;
}

.prolfie-my-story-profile{
    width:55px;
    height: 55px;
    /* background-color:rgb(32, 129, 129); */
    margin: auto;
}

.prolfie-my-story-profile-img{
    position: absolute;
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid  rgba(255, 255, 255, 0.8);
    z-index: 9999;
    /* animation: stroke-draw 6s ease-out infinite alternate;  */
  }

.prolfie-my-story-spinner{
    width: 55px;
    height: 55px;
    border-top: 5.5px solid #6da9f5;
    border-right: 5.5px solid transparent;
    border-radius: 50%;
    margin: auto;
    animation: spinner 0.6s linear infinite;
    font-size: 18px;
    z-index: 999999;
}

.prolfie-my-story-head{
    width: 5.5px;
    height: 5.5px;
    background-color: #6da9f5;
    border-radius: 50%;
    margin-left: 46.75px;
    margin-top: 2.75px;
  }
  @keyframes spinner {
    100% { transform: rotate(360deg) }
  }

.prolfie-story-username-text{
    font-size: 0.65rem;
    color: rgb(32, 32, 32);
    text-align: center;
    width: 100%;
    margin: auto;
}

.prolfie-story-times-box{
    width: 40px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 0px;
    z-index:99999999999999999 !important;
    /* background-color: crimson; */
}

.prolfie-story-times-img{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 12px;
}

.prolfie-story-reactions-xo{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 7px;
    bottom: 10px;
    max-width: 12%;
    position: fixed !important;
    z-index: 99999999;
}

.prolfie-story-reactions-img{
    width: 100%;
    height: 100%;
    z-index: 99999999999999999999!important;
}

.prolfie-story-swipe-up{
    position: absolute;
    width: 70%;
    height: 70px;
    left: 15%;
    bottom: 0%;
    z-index: 9999999999999999999;
    color: white;
    text-align: center;
    letter-spacing: 0.1em;
    /* margin-bottom: 1.2vh; */
    text-transform: capitalize;
    opacity: 1;
    font-size: 0.8em;
    transition: opacity 300ms ease-in-out;
}

.prolfie-story-bottom-row{
    position: absolute;
    width: 20%;
    height: 10%;
    right: 0%;
    bottom: 0%;
    z-index: 99999999999999999999 !important;
}


.prolfie-story-top-text{
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 30px;
    left: 90px;
    z-index: 99;
}

.prolfie-story-top-heading{
    font-size: 0.7rem !important;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    margin-bottom: 2;
}

.prolfie-story-top-heading-tags{
    font-size: 0.6rem !important;
    color: rgba(255, 255, 255, 0.9);
}

.prolfie-story-top-subheading1{
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 2;
}

.prolfie-story-top-subheading{
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}

.prolfie-story-profile-pic-box{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 29px;
    left: 20px;
    /* background-color: crimson; */
    z-index: 999999999999999!important;
}

.prolfie-story-profile-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid  rgba(255, 255, 255, 0.8)
    
/* background: rgb(93,93,93);
background: radial-gradient(circle, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.prolfie-story-photo{
    position: absolute;
    width: 100%;
    height:100%;   
    left: 0%;
    top:0%;
    /* background: rgb(93,93,93);
    background: linear-gradient(0deg, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.prolfie-story-photo-size{
    position: absolute;
    width: 100%;
    height:100% !important;
    object-fit: contain;
}




.prifile-all-social-media{
    position: absolute;
    margin-top: 20px;
    margin-bottom: 60px !important;
    left: 0%;
    /* background-color: blue; */
    width: 90% !important;
    left: 5%;
    height: 100% !important;
}

.prifile-social-media-explainer{
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    width: 100%;
    text-align: center !important;
    position: relative;
    color: #575578;
}

.prifile-social-media-box{
    margin: auto;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    width: 33.3333333%;
    /* background-color: red; */
    /* border: 1px solid #000 !important; */
    text-align: center !important;
    position: relative;
}

.prifile-social-media-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.prifile-social-media-btn{
    border-radius: 50%;
    width: 70%;
    height: 70%;
    margin-left: 15%;
    border-left: none!important;
    border-right: none!important;    
    border-bottom: none!important;
    border-top: solid 0.5px rgb(204, 204, 204,0.2) !important;
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.17) 0px -12px 13px 0px inset, rgba(0, 0, 0, 0.15) 0px -18px 15px 0px inset, rgba(0, 0, 0, 0.1) 0px -59px 30px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}  

.prifile-social-media-btn-pressed{
    border-radius: 50%;
    width: 70%;
    height: 70%;
    margin-left: 15%;
    border-left: none!important;
    border-right: none!important;    
    border-bottom: none!important;
    border-top: solid 0.5px rgb(204, 204, 204,0.2) !important;
    transform: scale(1);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}  

.prifile-social-media-name{
    color: #232231;
    font-size: 18px !important;
    /* font-weight: lighter;      */
    position: absolute;
    width: 100%;
    /* background-color: blueviolet; */
    left: 0%;
    bottom: 0%;
}
.prifile-social-media-img{
    position: absolute;
    width: 40%;
    left: 30%;
    height: 40%;
    top: 30%;
    opacity:0.80
}





.ambassandor-carousal-request-explaining{
    color: #575578;
    font-size: 12px;
    width: 80% !important;
    height: 100px !important;
    position: absolute;
    bottom: 0%;
    left: 10%;
    /* width: 100% !important;   */
    /* background-color: #2019ad;   */
}

.ambassandor-carousal-request-btn{
    position: absolute;
    width: 60%;
    height: 10%;
    /* margin: auto;
    margin-top: 30px; */
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    color: white !important;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    cursor: pointer; 
    bottom: 5%;   
    left: 20%;
}


.ambassandor-carousal-join-btn{
    position: absolute;
    width: 80%;
    height: 10%;
    /* margin: auto;
    margin-top: 30px; */
    font-weight: bold;
    cursor: pointer; 
    bottom: 6%;   
    left: 10%;
    background: #1183ca !important;
    border: none !important;
    padding: 3px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    font-size: 16px;
}


.ambassandor-carousal-welcome-cart{
    color: #232231;
    font-size: 18px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    top: 170px;
    width:100%;
}


.slider-single-content {
    border-radius: 50px!important;
}

.slider-left{
    opacity: 0%;
}

.slider-right{
    opacity: 0%;
}

.ambassandor-carousal-carouserl-layout{
    position: relative;
    top: 50px !important;
    left: 0%;
    width: 100%;
    margin-bottom: 200px;
}  

.ambassandor-carousal-carouserl-box{
    width: 100%;
    height: 300px;
    border-radius: 50px!important;
    border: 1px solid rgb(233, 232, 232);
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 30px -20px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 30px 170px -20px rgba(45, 6, 104, 0.2); 
} 

.ambassandor-carousal-expaling-title{
    color: #575578;
    font-size: 12px;
    width: 100px !important;
    height: 100px !important;
    position: absolute;
    top: 7px;
    width: 100% !important;
    /* background-color: red; */
}

.ambassandor-carousal-img-box{
    width: 100px !important;
    height: 100px !important;
    margin: auto;
    margin-top: 80px !important;
}

.ambassandor-carousal-img-box-hubst3r{
    width: 100px !important;
    height: 100px !important;
    margin: auto;
    margin-top: 50px !important;
}

.ambassandor-carousal-img{
    width: 100%;
    height: 100%;
}

.ambassandor-carousal-sugget-username{
        color: #232231;
        font-size: 18px !important;
        font-weight: bold;
        text-align: center;
        margin-top: 30px;
        width: 100%;
        word-break: break-word !important;
        /* background-color: red; */
}



.back-button-user-profil-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.user-profil-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.pofile-top-box-nubinfo{
    position: absolute;
    width: 60%;
    height: 120px;
    left: 32%;
}

.pofile-top-box-info-1{
    position: absolute;
    width: 30%;
    height:inherit;
    left: 10%;
    padding-top: 20px;
}

.pofile-top-box-info-2{
    position: absolute;
    width: 30%;
    height:inherit;
    left: 55%;
    padding-top: 20px;
}

.pofile-top-box-number{
    color: rgb(59, 59, 59) !important;
    font-size: 30px;
    font-weight: bold;
}
.pofile-top-box-info-name{
    color: #575578;
    font-size: 18px;
}

.memory-img-all-img{
    position: absolute;
    width: 100%;
}

.memory-img-all-img:after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.memory-img-size-box{
    position: relative;
    width: 50%;
    border: solid 1px rgb(216, 215, 215);
}

.memory-img-size-box:after{
    content: "";
    display: block;
    padding-bottom: 0%;
}

.memory-img-size-full{
    position: relative;
    top: 0%;
    height: 100%;
    width: 100%;
    /* border-radius: 4px;  */
    object-fit: cover;
}

.profile-top-all-info{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.profile-top-profile-pic-box{
    width: 30%;
    height: 40%;
}

.profile-top-profile-picture-photo{
    height: 70px;
    width: 70px; 
    border-radius: 50%!important;
    object-fit: cover;
    margin-top: 10px;
}

.profile-top-change-profilepic-icon{
    z-index: 9 !important;
    width: 25px !important;
    position: absolute;
    top: 55px;
    margin-left: 75px;
    /* margin-top: 12%;
    margin-left: -5%; */
}

.profile-top-username-and-fullname{
    width: 80%;
    left: 7%;
    height: 100%;
    position: relative;
    margin-top: 85px !important;
    color: #232231;
    text-align: left;
}

.profile-top-bio-text{
    width: 80%;
    left: 7%;
    height: 100%;
    position: relative;
    margin-top: 1px !important;
    color: #333249;
    text-align: justify;  
    word-break: break-word;
}

.profile-top-edit-profile{
    position: relative;
    width: 90%;
    height: 10%;
    background: #fff;
    margin: auto;
    margin-top: 30px;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
    border: solid 0.5px rgb(204, 204, 204);
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
}

.profile-top-edit-profile:active {
    transform: scale(0.88);
}

.profile-top-edit-profile:focus {
    transform: scale(0.88);
}

.profile-top-edit-profile:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.profile-top-buddy-profile-btn{
    position: relative;
    width:45%;
    height: 10%;
    background: #fff;
    margin: auto;
    margin-top: 30px;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
    border: solid 0.5px rgb(204, 204, 204);
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 10px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
}

.profile-top-buddy-profile-empty{
    position: relative;
    width:2%;
    height: 10%;
}

.profile-top-buddy-profile-btn:active {
    transform: scale(0.88);
}

.profile-top-buddy-profile-btn:focus {
    transform: scale(0.88);
}

.profile-top-buddy-profile-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.profile-top-request-buddy{
    position: relative;
    width: 90%;
    height: 10%;
    margin: auto;
    margin-top: 30px;
    /* line-height: 125px; */
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    color: white !important;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    /* border: none; */
    cursor: pointer;
}

.profile-top-two-btns{
    position: relative;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    height: 30px !important;

}
.profile-top-two-btns-left{
    position: absolute;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
    border-radius: 4px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 10px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 49%;
    height: 100%;
    left: 0%;
    font-size: 12px;
}

.profile-top-two-btns-full{
    position: absolute;
    background: #fff;
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
    border-radius: 4px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 10px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 100%;
    height: 100%;
    left: 0%;
    font-size: 12px;
}

.profile-top-two-btns-left:active {
    transform: scale(0.88);
}

.profile-top-two-btns-left:focus {
    transform: scale(0.88);
}

.profile-top-two-btns-left:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.profile-top-two-btns-right{
    position: absolute;
    background: #fff;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
    border-radius: 4px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 10px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 49%;
    height: 100%;
    left: 51%;
    font-size: 12px;
}

.profile-top-two-btns-right:active {
    transform: scale(0.88);
}

.profile-top-two-btns-right:focus {
    transform: scale(0.88);
}

.profile-top-two-btns-right:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.profile-top-two-btns-accept{
    position: absolute;
    background: #fff;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 35px;
    width: 49%;
    height: 100%;
    left: 0%;
    font-size: 12px;
    background-color: #06E00F !important;
    border: none;
    color: white !important;
    text-align: center;
    cursor: pointer;
}

.profile-top-two-btns-decline{
    position: absolute;
    background: #fff;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 35px;
    width: 49%;
    height: 100%;
    left: 51%;
    font-size: 12px;
    background-color: #CC0919 !important;
    border: none;
    color: white !important;
    text-align: center;
    cursor: pointer;
}

.profile-top-change-icon-size{
    width: 100%;
}

.profile-top-info-all-boxes{
    margin-top:30px
}
.profile-top-info-box{
    width: 130px;
    height: 100px;
    /* background-color: brown; */
    margin: auto;
}
.profile-top-info-img{
    width: 20px;
    height: 20px;
    margin: auto;
}
.profile-top-info-icon{
    width: 100%;
    height: 100%;
}
.profile-top-info-title{
    color: #575578;
    /* font-weight:bold; */
    margin-top: 10px;
    /* font-size: 25px; */
}
.profile-top-info-info-number{
    color: #575578;
    font-weight:bolder;
    font-size: 25px;
}

.profile-username-and-fullname{
    color: #575578;
    position: absolute;
    top: 11%;
    text-align: left;
    left: 22%;
}

.my-profile-set-splash-box{
    position:absolute;
    right: 7px;
    top: 105px;
}
.my-profile-set-splash-img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid gray;
}
.my-profile-set-splash-text{
    color: #575578;
    font-size: 10px !important;
    width: 60px;
    margin: auto;
    margin-top: 2px;
}
.user-profile-vip-badge{
    position: absolute;
    right: 3%;
    top: 1%;
}
.user-profile-vip-badge-size{
    width: 35px;
    height: 35px;
}
.my-profile-working-hubs-sc{
    margin-top: 20px;
}
.my-profile-working-hubs-title{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
}
.my-profile-working-hubs-item{
    width: 50%;
}
.my-profile-working-hubs-logo{
    margin: auto;
    width: 20%;
    margin-top: 20px;
}
.my-profile-working-hubs-img{
    width: 100%;
    border-radius: 50%;
    margin: auto;

}
.my-profile-working-hubs-name{
    color: #575578;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}




.profile-no-memories-mika-layout{
    width: 100%;
    height: 50%;
    margin-bottom: 0%;
    margin-top: 0px;
}
.profile-no-memories-image{
    width: 100% !important;
    height: 100px;
}
.profile-no-memories-created{
    margin-top: 10px;
    width: 30%;
    border-radius: 20px;
}
.profile-no-memories-text{
    color: #575578;
    font-size: 12px !important;
    margin-top: 20px !important;
    margin-bottom: 70px !important;
    text-align: center;
    width: 100%;
    position: relative;
    margin-left: -0%;
}

/* Global css start */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
/* Global css end */
.profile-show-all-buddies-section{
    width:20%;
    position:absolute;
    right:0%;
    top:-15px;
    height: 70px;
    /* background: red; */
}
.profile-show-all-buddies-button{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 44px;
    height: 44px;
    position: relative;
    text-align: center;
    line-height: 44px !important;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto;
}
.profile-show-all-buddies-icon{
    width: 70%;
}
.profile-show-all-buddies{
    color:  #575578;
    text-align: center;
    font-size:12px;
}
.profile-share-memory-text{
    color:  #575578;
    text-align: center;
    font-size:10px;
}
/* loading */
.profile-section-disappear{
    display: none;
}
.profile-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.profile-lottie-dissappear{
    display: none;
}
/* end loading */
.profile-snap-memory-layout{
    position: absolute;
    right: 10px;
    top:10px
}
.profile-memories-snap-memory-layout{
    position: relative;
    right: 10px;
    top:10px
}
.profile-memories-share-memory-text{
    color:  #575578;
    text-align: center;
    font-size:20px;
    margin-bottom: 10px;
}
.profile-memories-snap-memory-size{
    width: 15%;
    margin-left: 10px
}
.profile-snap-memory-size{
    width: 50px;
}
/* troll slider */
.arrange-forever-alone-img{
    width: 80% !important;
    margin: auto;
} 
.profile-all-input-size{
    width: 300% !important;
    height: 50px !important;
    margin-left: -80% !important;
}
.item-img-4 {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
}
.item-img-4 img{
    width: 45px;
}
.item-text-4{
    font-weight: bold;
}
/* end troll slider */
.profile-forever-monk-position{

}
.profile-forever-monk{
    width:40%;
    margin:auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
/* Body left css start */ 
.body-left-5{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.logo-5{
    padding: 30px 40px;
    margin-top: 30px;
}
.search-user-username-layout{
    font-size: 25px !important;
    color:  #575578;
}
.profile-pic-5{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text-5 strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.profile-settings-icons-layout{
    width: 4%;
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 999999999999999999999!important;
    position: fixed !important;

}
.profile-memory-settings-icons-layout{
    width: 4%;
    position: absolute;
    top: 1%;
    right: 6%;
    z-index: 999999999999999999999!important;
    position: fixed !important;
}

.profile-dashboard-z-ndex{
    z-index: 99999999999999999999 !important;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 1%;
    right: 17%;
}
.profile-dashboard-icons-layout{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5%;
    right: 1%;
}
.profile-all-memories-position{
    margin-top: 8%;
}
.memory-img-size{
    height: 280px;
    width: 280px;
    /* margin-left: 15px; */
    margin-bottom: 20px;
    border-radius: 4px;
    margin-left: -20px;
    position: relative;
    /* object-fit: cover; */
    object-fit: cover;
    z-index:1;
}

.memory-img-size-cover{    
    height: 280px;
    width: 280px;
    /* margin-left: 15px; */
    /* margin-bottom: 20px;
    border-radius: 10px;
    margin-left: -20px; */
    left: 5px;
    top:5px;
    position: absolute;
    /* object-fit: cover; */
    object-fit: fill!important;
    filter: blur(20px);
    -webkit-filter: blur(20px);
}
.top-memory img {
    border-radius: 10px!important;
    width: 50px;
    position: absolute;
    top: 1%;
    left: 75%;
    z-index: 9999999 !important;
}
.left-text-5{
    font-size: 15px;
}
.left-icon-5 img{
    width: 45%;
}
.left-home-5{
    cursor: pointer;
}
.left-home-5:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.body-right-5{
    margin-left: 16.65%;
    background: #fff;
    border-radius:0px 0px 0px 0px!important;
    padding-top: 10px;
    margin-top: 0px !important;
}
.blue{
    color: #575578;
    /* word-break: break-all; */
}
.item-5{
    background: #fff;
    margin-top: 2rem;
    padding: 25px;
    border-radius: 25px;
}
.item-text-5 h3{
    font-weight: bold;
}

.user-profile-btn-add{
    width: 30%;
    font-size: 20px !important;
    text-align: center;
    padding: 10px 5px;
    margin-top: -8%;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    color: white !important;
    border-radius: 30px!important;
    cursor: pointer;
    margin: auto;
}
.user-profile-btn-answered{
    width: 30%;
    text-align: center;
    margin-top: -8%;
    background: #fff !important;
    font-weight: bold;  
    border: none !important;
    padding: 5px 30px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    cursor: pointer;
    margin: auto;
}

.user-profile-btn-cancel{
    width: 35%;
    margin-top: -8%;
    font-size: 18px !important;
    padding: 10px 5px;
    font-weight: bold;
    text-align: center;
    border-radius: 30px!important;
    color: rgb(207, 49, 49)!important;
    border: 1px solid rgb(207, 49, 49) !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    cursor: pointer;
    margin: auto;
}
.profile-item-div-4 {
    background: #fff;
    padding: 20px 0px !important;
    max-width: 60% !important;
    border-radius: 13px;
    margin-left: 14.66% !important;
    cursor: pointer;
}
.buddies-profile-data-position{
    margin: auto;
}
.buddies-profile-username-position{
    margin-left: 0% !important;
}
.item-img-4 {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
}
.arrange-buddy-img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 25px;
}
.item-img-4p{
    width: 135px;
    height: 130px;
    margin: auto;
}
.item-img-4 img{
    width: 45px;
}
.item-text-4{
    font-weight: bold;
}
.w-box1-s{
    font-size: 18px;
}
.hub-info-input{
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4); 
        box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4);
        padding: 13px 20px;
        border-radius: 13px;
        height: 35px;
        width: 150px;
        text-align: center!important;
}
.w-box2, .w-box3{
    color: #575578;
    margin-top: 1.5rem;
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 10px;
}
.hub-info-icon {
    width: 20px;
    height: 20px!important;
    margin-right: 8px;
}
.icon-5 img{
    width: 30px;
    margin-top: .4rem;
}
.mini-box-5 {
    max-width: 21% !important;
    margin: auto;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    border-radius: 10px;
}
.text-5 {
    background: #fff;
    padding: 5px 8px;
    border-radius: 7px;
    max-width: 55px !important;
}
.text-5 span{
    font-weight: bold;
    font-size: 20px;
}
.p-p p{
    margin-top: 8px !important;
    font-weight: bold;
    font-size: 14px!important;
}
.img-5{
    max-width: 4% !important;
    margin-left: 1rem;
}
.img-5 img{
    width: 25px;
}
.img-location img{
    width: 15px;
}
.profile-hubs-picture-layout{
    border-radius: 50%!important;
    width: 100px!important;
    height: 100px!important;
    margin-left: 30px !important;
    margin-top: 12% !important;
    object-fit: cover;
}
.profile-buttons-space{
    width: 0px !important;
}
.profile-change-profilepic-icon{
    z-index: 9 !important;
    width: 30px;
    margin-top: 12%;
    margin-left: -5%;
}
.text-blue-10{
    color: #575578; 
}
.profile-change-icon-size{
    width: 30px;
}
.all-btn-5 .btn-danger-profile {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 6px 0px;
    border-radius: 30px;
    font-size: 22px;
    font-weight: bold;
    min-width: 8.5rem;
    /* width: 8rem; */
    color: white;
    cursor: pointer;
    /* margin-left: 10px; */
}
.all-btn-5 .btn-light {
    background-color: #fff !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    border: none;
    padding: 6px 0px;
    border-radius: 30px;
    font-size: 22px;
    font-weight: bold;
    color: #575578!important;
    min-width: 8.5rem;
    cursor: pointer;
    /* margin-left: 10px; */

    /* width: 8rem; */
    /* height: 100%; */
}
.profile-bio-text-layout{
    width:50% !important;
    margin:auto;
    word-break: break-all; 
}
.profile-slider-size{
    width:90%
}
.all-btn-5 .btn{
}
.col-xl-12.text-left.ml-3.item-text-5 {
    margin-bottom: 1.1rem;
}
.blue-box-5 {
    background: #6232B2;
    padding: 4px 5px;
    width: 40%;
    margin: auto;
    border-radius: 16px;
    text-align: left;
}
.blue-box-5 img{
    width: 3rem;
    margin-bottom: 3px;
}
.blue-box-5 span{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: -20px;
}
.col-xl-5.bv-img-5 {
    line-height: 3.5rem;
    text-align: right;
}
.bv-img-5 img{
    width: 40px;
}
.bv-text-5 h5{
    color: rgba(87, 85, 120, 0.6);
    font-weight: bold;
    margin-top: 2px;
}
.bv-text-5 p{
    color: #6232B2;
    margin-bottom: 0px;
}
.divided-box-5 {
    max-width: 95%;
    margin: auto;
    padding: 20px 30px;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    border-radius: 12px;
}
.col-xl-7.bv-text-5 {
    text-align: left;
}

.img-thumbnail {
    padding: 0.50rem !important;
    background-color: #fff;
    border: none !important;
    border-radius: .50rem !important;
    max-width: 100%;
    height: auto;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
}
.img-col-5{
    max-width: 23% !important;
}
.profile-item-hub-field{
    width: 60%!important;
    background: #fff;
    padding: 15px 10px;
    border-radius: 20px;
    margin: auto !important;
    margin-top: 35px !important;
    /* margin-left: -13%!important; */
}
.profile-item-hub-field{
    top: initial;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    height: 10rem;
}
.profile-hubs-hub-name-layout{
    margin-left: 5;
    margin-top: -60px;
    font-size: 30px;
}
.profile-hubs-visites-layout{
    margin-left: 80%;
    margin-top: -50px;
    font-size: 30px;
}
.profile-hubs-month-word{
    font-size: 15px; 
    color:#575578;;
}
.profile-birthday-sellect-btn{
    margin-left: 45% !important;
    background-color: rgb(224, 222, 222) !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2) !important;
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2) !important;
    padding: 6px 0px !important;
    border-radius: 10px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #575578!important;
    width: 8rem !important;
    border: 10px #2019ad!important;
}
.n-icon-5{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    border-radius: 50%;
    margin-top: 4.4rem;
}


.n-icon-5 img{
    width: 12px;
}
.small-header-5{
    display: none;
}
.nav-bottom-5{
    display: none !important;
}
.profile-buddy-img{
    width: 100%!important;
    height: 100% !important;
    border-radius: 50%!important;
}
.profile-profile-picture-photo{
    margin-top: 30%;
    height: 80px;
    width: 80px; 
    border-radius: 50%!important;
    object-fit: cover;
}
.buddies-username-possition{
    margin-left: 10%;
}
.buddies-profile-all-buttons-layout{
    margin: auto !important;
    margin-top: 5% !important;
}
.profile-logout-button{
    background-color: #ce0404 !important;
    color: white !important;
    border: none !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    padding: 10px 35px !important;
    width: 40%;
    margin: auto;
    margin-top: 80px;
    font-size: 25px !important;
    z-index: 999;
}
/* info section */
.profile-info-info{
    width: 100%;
    margin-top: -20px !important;
    /* margin-left: 75px; */
}
.user-profile-pencil-icon {
    position: relative;
    width: 20px;
    height: 20px!important;
    margin-left: 800px;
    margin-top: -70px !important;
}
.white-box-profile{
    width: 200%;
    margin: auto;
}
.profile-city-country-position{
    margin-left: 0%!important;
}
.buddies-profile-lock{
    width: 20%;
    margin-top: -6%;
}

/* gag modal */
.text-blue-19{
    color: #575578;
}
.profile-gag-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 360px !important;
    margin-top: -150%;
    margin-left: -220%;
    width: 750%;
}
.post-hub-modal-frame {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.post-hub-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}
.modal-times-close-icon{
    margin-top: 6px;
    width:2%;
}
.profile-gag-slider-layout{
    margin-top: 2%;
}
.profile-gag-btn-danger {
    background: #ce0404 !important;
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /* margin-top: 3%; */
    color: white!important;
    cursor: pointer;
    float: right;
}
/* end gag modal */

.user-profile-accept-button{
    /* width: 30%; */
    background-color: #06E00F !important;
    border: none;
    padding: 10px 45px;
    color: white !important;
    font-size: 20px !important;
    border-radius: 30px!important;
    text-align: center;
    margin-left: 18%;
    cursor: pointer;
}
.user-profile-decline-button{
    /* width: 30%; */
    background-color: #CC0919 !important;
    border: none;
    padding: 10px 45px;
    color: white !important;
    font-size: 20px !important;
    border-radius: 30px!important;
    text-align: center;
    margin-left: 10%;
    cursor: pointer;
}



@media only screen and (max-width: 1200px){
    .profiles-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 9999 !important;
    }
    .profiles-mobile-navbar-username{
        /* background-color: tomato; */
        width: 80%;
        margin-left: 5%;
        text-align: center!important;
    }
    .profiles-mobile-navbar-has-hubs{
        width: 10px;
        height: 100%;
        margin-left: 5px;
    }
    .profiles-mobile-navbar-has-hubs-icon{
        width: 100%;
        height: 100%;
    }

    .user-profil-all-body{
        position: absolute;
        width: 100%;
        top: 55px;
    }

    .user-profile-accept-button{
        padding: 10px 45px;
        font-size: 20px !important;
        margin-left: 33%;
    }
    .user-profile-decline-button{
        padding: 10px 45px;
        font-size: 20px !important;
        margin-left: 10%;
    }
    .top-memory img {
        top: 1%;
        left: 70%;
    }
    .profile-whole-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .white-box-profile{
        max-width:85%;
        /* width: 200%; */
        margin: auto;
    }
    .body-left-5{
        display: none;
    }
    .body-right-5{
        margin-left: initial;
        margin-top: 3%;
    }
    .profile-top-username-navbar{
        margin-top: 55px;
    }
    .profile-buddy-top-username-navbar{
        margin: auto;
        margin-top: 0px;
        font-size: 25px;
    }
    .small-header-5{
        display: block;
    } 
    .color-5{
        /* background: rgb(29,54,135);
        background: linear-gradient(336deg, rgba(29,54,135,1) 70%, rgba(144,36,103,1) 100%); */
        width: 100%!important;
        height: 6.8% !important;
    }
    .body-left-5 {
        border-radius: 45px 45px 0px 0px;
    }
    .col-xl-8.item-5 {
        width: 90%;
        margin: auto;
    }
    .col-xl-8.item-5.first-item-5 {
        margin-top: 1.7rem;
    }
    .upper-text-5 h2{
        color: #fff;
    }
    .user-profile-username-layout{
        margin: auto;
        font-weight: bold;
        font-size: 40px;
    }
    .all-btn-5{
        margin-bottom: 1.5rem;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 6px 0px;
        font-size: 22px;
        min-width: 10rem;
    }
    .all-btn-5 .btn-light {
        padding: 6px 0px;
        font-size: 22px;
        min-width: 10rem;
    }
    .nav-bottom-5{
        display: block !important;
    }
    .profile-bottom-empty{
        height: 50px!important;
        width: 100%;
        position: relative;
    }
    .buddies-profile-lock{
        width: 15%;
        margin-top: -6%;
    }
    .user-profile-back-button{
        position: absolute;
        width: 10px;
        top: 1.5%;
        left: 5%;
    }
    .profile-change-profilepic-icon{
        width: 50px;
        margin-top: 70px;
        margin-left: -6%;
    }
    .profile-change-icon-size{
        width: 50px;
    }
    .profile-birthday-sellect-btn{
        margin-left: 25% !important;
    }
    .profile-profile-picture-photo{
        margin-top: 0%;
        height: 120px;
        width: 120px;
        object-fit: cover !important;
    }
    /* modal */
    .profile-gag-modal-box {
        height: 360px !important;
        margin-top: -150%;
        margin-left: -300%;
        width: 750%;
    }
    /* end mofal */

}
@media only screen and (max-width: 1030px){
    .user-profile-pencil-icon {
        margin-left: 700px;
        margin-top: -70px !important;
    }
    .user-profile-accept-button{
        margin-left: 29%;
    }
}

@media only screen and (max-width: 992px){
    .user-profile-accept-button{
        margin-left: 26%;
    }
    .memory-img-size{
        height: 250px;
        width: 250px;
        margin-bottom: 20px;
        margin-left: -20px;
    }
    .memory-img-size-cover{    
        height: 250px;
        width: 250px;
        left: 30px;
        top:5px;
    }

    /* troll slider */
    .item-img-4 {
        width: 110px;
        height: 120px;
        background: #fff;
        margin: auto;
        line-height: 120px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    /* end troll slider */
    /* gag modal */
    .profile-gag-modal-box {
        height: 330px !important;
    }
    .post-hub-modal-frame {
        width: 100px;
        height: 100px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    /* end gag modal */
    .item-img-4 {
        width: 110px;
        height: 120px;
        background: #fff;
        margin: auto;
        line-height: 120px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 117px;
        height: 120px;
        margin: auto;
    }
    .top-memory img {
        left: 68%;
    }
}

@media only screen and (max-width: 920px){
    .user-profile-pencil-icon {
        margin-left: 650px;
        margin-top: -70px !important;
    }
    .top-memory img {
        left: 72%;
    }
    .profile-profile-picture-photo{
        margin-top: 10%;
        height: 100px;
        width: 100px;
        object-fit: cover !important;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -4%;
    }
    .profile-change-icon-size{
        width: 40px;
    }
}
@media only screen and (max-width: 850px){
    .user-profile-pencil-icon {
        margin-left: 600px;
        margin-top: -70px !important;
    }
}
@media only screen and (max-width: 831px){
    .memory-img-size{
        height: 210px;
        width: 210px;
        margin-bottom: 0px;
        margin-left: 0px;
    }
    .memory-img-size-cover{    
        height: 210px;
        width: 210px;
        left: 35px;
        top:5px;
    }
    .top-memory img {
        left: 69%;
    }
}
@media only screen and (max-width: 800px){
    .profile-hubs-picture-layout{
        border-radius: 50%!important;
        width: 90px!important;
        height: 90px!important;
        margin-left: 30px !important;
        margin-top: 28% !important;
        object-fit: cover;
    }
    .search-user-username-layout{
        font-size: 20px !important;
    }
    .profile-settings-icons-layout{
        width: 4%;
    }
    .profile-memory-settings-icons-layout{
        width: 4%;
    }
    .profile-buddy-top-username-navbar{
        margin: auto;
        margin-top: -12px;
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 768px){
    .user-profile-accept-button{
        margin-left: 24%;
    }
    .profile-all-input-size{
        width: 260% !important;
    }
    .memory-img-size{
        height: 210px;
        width: 210px;
        margin-bottom: 20px;
    }
    .memory-img-size-cover{    
        height: 210px;
        width: 210px;
        left: 22px;
        top:5px;
    }
    .top-memory img {
        left: 75%;
        width: 40px !important;
    }
    .user-profile-pencil-icon {
        margin-left: 550px;
        margin-top: -70px !important;
    }
    .profile-profile-picture-photo{
        width: 100px!important;
        height: 100px;
        object-fit: cover !important;
    }
    .profile-username-position{
        margin: auto;
    }
    .mini-box-5 {
        max-width: 22% !important;
    }
    .profile-buttons-space{
        width: 0px !important;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 10px 0px;
        font-size: 20px;
        min-width: 9rem;
    }
    .all-btn-5 .btn-light {
        padding: 10px 0px;
        font-size: 20px;
        min-width: 9rem;
    }
    .blue-box-5 {
        width: 40%;
    }
    .blue{
        font-size: 13px!important;
    }
    .p-p p{
        margin-top: 8px !important;
        font-weight: bold;
        font-size: 10px!important;
    }

    /* gag modal */
    .profile-gag-modal-box {
        height: 320px !important;
        margin-top: -150%;
        margin-left: -210%;
        width: 550%;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -50px;
    }
    .profile-gag-btn-danger {
        padding: 8px 40px !important;
        font-size: 20px !important;
    }
    /* end gag modal */
    .profile-logout-button{
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 740px){
    .user-profile-pencil-icon {
        margin-left: 500px;
        margin-top: -70px !important;
    }
}
@media only screen and (max-width: 700px){
    .profile-username-and-fullname{
        top: 50px;
        left: 160px;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -3%;
    }
    .memory-img-size{
        height: 200px;
        width: 200px;
        margin-bottom: 20px;
        margin-left: -5px;
    }
    .memory-img-size-cover{    
        height: 200px;
        width: 200px;
        left: 15px;
        top:5px;
    }
    .top-memory img {
        left: 73%;
    }
    .user-profile-accept-button{
        margin-left: 17%;
    }
}

@media only screen and (max-width: 689px){
    .p-p p {
        margin-top: 4px !important;
        font-weight: bold;
        font-size: 15px;
    }
    .text-5 {
        background: #fff;
        padding: 5px 3px;
        border-radius: 7px;
        max-width: 60px !important;
    }
    .text-5 span {
        font-weight: bold;
        font-size: 17px;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-5.text-left.item-text-5.mt-4.blue {
        margin-top: 1.5rem !important;
    }
    .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-4.mt-4.item-btn-5 {
        margin-top: .5rem !important;
    } 
    .user-profile-btn-cancel{
        /* width: 100%; */
        margin-top: 5% !important;
        font-size: 18px !important;
        padding: 10px 23px;
    }
    .profile-item-hub-field{
        width: 90%!important;
        background: #fff;
        padding: 15px 10px;
        border-radius: 20px;
        margin: auto !important;
        margin-top: 35px !important;
    }
    .profile-logout-button{
        padding: 10px 25px !important;
        width: 40%;
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 659px){
    .profile-all-input-size{
        width: 240% !important;
    }
    .p-p p {
        margin-top: 4px !important;
        font-weight: bold;
        font-size: 14px;
    }
    .user-profile-pencil-icon {
        margin-left: 450px;
        margin-top: -70px !important;
    }
    .user-profile-btn-add{
        width: 40%;
    }
    .user-profile-btn-answered{
        width: 40%;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 10px 0px;
        font-size: 18px;
        min-width: 8rem;
    }
    .all-btn-5 .btn-light {
        padding: 10px 0px;
        font-size: 18px;
        min-width: 8rem;
    }
    .top-memory img {
        left: 77%;
        width: 40px !important;
    }
}

@media only screen and (max-width: 645px){
    .memory-img-size{
        height: 190px;
        width: 190px;
        margin-bottom: 20px;
        margin-left: -3px;
    }
    .memory-img-size-cover{    
        height: 190px;
        width: 190px;
        left: 12px;
        top:5px;
    }
    .top-memory img {
        left: 74%;
        width: 40px !important;
    }
}

@media only screen and (max-width: 610px){
    .pofile-top-box-info-1{
        left: 20%;
        padding-top: 10px;
    }
    
    .pofile-top-box-info-2{
        left: 60%;
        padding-top: 10px;
    }
    
    .pofile-top-box-number{
        font-size: 25px;
    }
    .pofile-top-box-info-name{
        font-size: 16px;
    }


    .memory-img-size{
        height: 180px;
        width: 180px;
        margin-bottom: 5px;
        margin-left: -5px;
    }
    .memory-img-size-cover{    
        height: 180px;
        width: 180px;
        left: 10px;
        top:5px;
    }
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -180%;
        width: 500%;
    }
    .post-hub-modal-frame {
        width: 80px;
        height: 80px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    .top-memory img {
        left: 74%;
        width: 35px !important;
    }
    
}
@media only screen and (max-width: 600px){
    .user-profile-pencil-icon {
        margin-left: 400px;
        margin-top: -70px !important;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 6px 0px;
        font-size: 18px;
        min-width: 7rem;
    }
    .all-btn-5 .btn-light {
        padding: 6px 0px;
        font-size: 18px;
        min-width: 7rem;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -1%;
    }
    .top-memory img {
        left: 77%;
        width: 35px !important;
    }
}
@media only screen and (max-width: 576px){
    .profile-show-all-buddies-section{
        width:20%;
        position:absolute;
        right:2%;
        top:-20px;
        height: 60px;
    }
    .profile-show-all-buddies{
        margin-top: -0px;
    }
    .profile-all-input-size{
        width: 180% !important;
        margin-left: -0% !important;
    }
    .memory-img-size{
        height: 170px;
        width: 170px;
        margin-bottom: 5px;
        margin-left: -7px;
    }
    .memory-img-size-cover{    
        height: 170px;
        width: 170px;
        left: 10px;
        top:5px;
    }
    /* troll slider */
    .item-img-4 {
        width: 100px;
        height: 112px;
        background: #fff;
        margin: auto;
        line-height: 112px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 35px;
    }
    /* end troll slider */
    .top-memory img {
        left: 75%;
        width: 35px !important;
    }
    .profile-hubs-picture-layout{
        border-radius: 50%!important;
        width: 70px!important;
        height: 70px!important;
        margin-left: 30px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    .top-back-4 img {
        width: 1.2rem;
        margin-left: 3rem;
    }
    .item-img-4 {
        width: 100px;
        height: 112px;
        background: #fff;
        margin: auto;
        line-height: 112px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 35px;
    }
    .item-img-4p {
        width: 110px;
        height: 112px;
        margin: auto;
    }
    .text-5 {
        background: #fff;
        padding: 3px 0px !important;
        border-radius: 7px;
        max-width: 60px !important;
        font-size: 16px !important;
        text-align: center;
    }
    .mini-box-5 {
        max-width: 45% !important;
        margin-bottom: 15px;
        height: 90px;
    }
    .all-btn-5 .btn-danger-profile {
        width: 100%;
        font-size: 18px;
    }
    .all-btn-5 .btn-light {
        width: 100%;
        font-size: 18px;
    }

    .item-text-5 h3 {
        font-size: 1.5rem;
    }
    .item-text-5 p {
        font-size: 15px;
    }
    .item-btn-5 .btn-info {
        padding: 6px 28px;
        font-size: 18px;
        font-weight: bold;
        /* text-align: left; */
        border-radius: 10px;
        background-color: #2D71B4;
    }
    .blue-box-5 {
        width: 60%;
    }
    .divided-box-5 {
        max-width: 95%;
        margin: auto;
        padding: 18px 0px;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        border-radius: 12px;
    }
    .bv-img-5 img {
        width: 36px;
    }
    .col-xl-3.img-col-5 {
        padding-right: 0px !important;
        padding-left: 6px;
    }
    .img-thumbnail {
        padding: 0.30rem !important;
    }
    .img-box-5.img-thumbnail strong {
        font-size: 12px !important;
    }
    .n-icon-5 {
        width: 35px;
        height: 35px;
        line-height: 32px;
        text-align: center;
        background-color: #fff;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        border-radius: 50%;
        margin-top: 1rem;
        margin-left: .7rem;
    }
    .img-col-5 {
        max-width: 24.5% !important;
    }
    .middle-icon-footer-5 img {
        width: 60%;
        margin-left: 50%;
        transform: translate(-140%);
        position: absolute;
        top: -25px !important;
    }
    .p-p p{
        margin-top: 8px !important;
        font-weight: bold;
        font-size: 14px!important;
    }
    .user-profile-pencil-icon {
        margin-left: 350px;
        margin-top: -70px !important;
    }
    .profile-info-info{
        width: 60%;
        margin-top: -20px !important;
        margin-left: 40%;
        font-size: 8px;
    }
    .profile-info-title-position{
        margin-left: -90%;
    }
    .profile-item-hub-field{
        height: 7rem;
    }
    .profile-birthday-sellect-btn{
        margin-left: 220% !important;
        margin-top: -50px;
        padding: 6px 10px !important;
        font-size: 14px !important;
        width: 100% !important;
    }
    /* gag modal */
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
    }
    .profile-gag-btn-danger {
        padding: 8px 30px !important;
        font-size: 18px !important;
    }
    /* end gag modal */
    .profile-logout-button{
        padding: 10px 35px !important;
        width: 40%;
        font-size: 20px !important;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -6%;
    }
}
@media only screen and (max-width: 540px){
    .profile-top-info-box{
        width: 80px;
        margin: auto !important;
    }
    .profile-top-info-title{
        color: #575578;
        /* font-weight:bold; */
        margin-top: 10px;
        font-size: 12px;
    }

    .my-profile-working-hubs-logo{
        width: 40%;
    }
    .my-profile-working-hubs-name{
        font-size: 12px !important;
    }
    .item-img-4 {
        width: 90px;
        height: 95px;
        background: #fff;
        margin: auto;
        line-height: 105px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 30px;
    }
    .item-img-4p {
        width: 92px;
        height: 95px;
        margin: auto;
    }
    /* gag modal */
    .post-hub-modal-frame {
        width: 80px;
        height: 80px;
        line-height: 105px;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 30px !important;
        font-size: 18px !important;
    }
    /* end gag modal */
    .profile-logout-button{
        padding: 10px 35px !important;
        width: 40%;
        font-size: 18px !important;
    }
    .memory-img-size{
        height: 160px;
        width: 160px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 160px;
        width: 160px;
        left: 8px;
        top:5px;
    }
    .top-memory img {
        left: 75%;
        width: 30px !important;
    }
    .user-profile-accept-button{
        padding: 8px 35px;
        font-size: 20px !important;
        margin-left: 17%;
    }
    .user-profile-decline-button{
        padding: 8px 35px;
        font-size: 20px !important;
        margin-left: 10%;
    }
}
@media only screen and (max-width: 510px){
    .memory-img-size{
        height: 150px;
        width: 150px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 150px;
        width: 150px;
        left: 7px;
        top:5px;
    }
    .top-memory img {
        left: 74%;
        width: 30px !important;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 6px 0px;
        font-size: 16px;
        min-width: 6rem;
    }
    .all-btn-5 .btn-light {
        padding: 6px 0px;
        font-size: 16px;
        min-width: 6rem;
    }
}

@media only screen and (max-width: 504px){
    .profile-all-input-size{
        width: 160% !important;
    }
    .profile-hubs-picture-layout{
        margin-left: 20px !important;
    }
    .search-user-username-layout{
        font-size: 17px !important;
    }
    .profile-hubs-hub-name-layout{
        margin-left: 5;
        margin-top: -50px;
        font-size: 30px;
    }
    .profile-hubs-visites-layout{
        margin-left: 80%;
        margin-top: -45px;
        font-size: 30px;
    }
    .profile-hubs-month-word{
        font-size: 10px; 
    }
}

@media only screen and (max-width: 500px){
    .profile-no-memories-text{
        color: #575578;
        font-size: 14px !important;
        margin-top: 95px;
        margin-bottom: 00px;
    }
    .user-profile-pencil-icon {
        margin-left: 300px;
        margin-top: -70px !important;
    }
    .profile-birthday-sellect-btn{
        margin-left: 180% !important;
        margin-top: -50px;
        padding: 6px 10px !important;
        font-size: 14px !important;
        width: 100% !important;
    }
    /* modal */
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -150%;
        width: 430%;
    }
    /* end modal */
    .profile-buddy-top-username-navbar{
        margin: auto;
        font-size: 18px !important;
        margin-top: -17px;
      }

}

@media only screen and (max-width: 482px){
    .memory-img-size{
        height: 140px;
        width: 140px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 140px;
        width: 140px;
        left: 6px;
        top:5px;
    }
    .top-memory img {
        left: 72%;
        width: 30px !important;
    }
    .user-profile-accept-button{
        padding: 8px 35px;
        font-size: 18px !important;
        margin-left: 17%;
    }
    .user-profile-decline-button{
        padding: 8px 35px;
        font-size: 18px !important;
        margin-left: 8%;
    }
}

@media only screen and (max-width: 460px){
    .top-memory img {
        left: 76%;
        width: 30px !important;
        z-index: 9999999999999999999 !important;
    }
    .item-img-4 {
        width: 80px;
        height: 82px;
        background: #fff;
        margin: auto;
        line-height: 82px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 80px;
        height: 82px;
        margin: auto;
    }
    /* gag modal */
    .profile-gag-modal-box {
        height: 290px !important;
        margin-top: -150%;
    }
    .post-hub-modal-frame {
        width: 65px;
        height: 65px;
    }
    .profile-gag-btn-danger {
        padding: 8px 20px !important;
        font-size: 15px !important;
    }
    /* end gag modal */
}
@media only screen and (max-width: 457px){
    .profile-all-input-size{
        width: 140% !important;
    }
    .profile-info-title-position{
        font-size: 12px !important;
    }
    .item-text-5 p {
        font-size: 14px;
    }
    .item-text-5 h3 {
        font-size: 1.4rem;
    }
    .item-btn-5 .btn-info {
        padding: 6px 25px;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        border-radius: 10px;
        background-color: #2D71B4;
    }
    .footer-icon-5 img {
        width: 28% !important;
    }
    .middle-icon-footer-5 img {
        width: 60% !important;
        margin-left: 50%;
        transform: translate(-140%);
        position: absolute;
        top: -46px;
    }
    .profile-logout-button{
        padding: 10px 25px !important;
        width: initial !important;
        font-size: 16px !important;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -3%;
    }
    .profile-settings-icons-layout{
        width: 6%;
    }
    .profile-memory-settings-icons-layout{
        width: 6%;
        right: 9%;
        /* top:% */
    }
    .profile-sliders-names-size{
        font-size: 10px !important;
    }
}
@media only screen and (max-width: 450px){
    .user-profile-btn-add{
        font-size: 13px !important;
        padding: 5px 10px;
        width: 25%;
    }
    .user-profile-btn-cancel{
        font-size: 12px !important;
        padding: 5px 10px;
        width: 25%;
    }
    .user-profile-btn-answered{
        width: 60%;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 6px 0px;
        font-size: 12px;
        min-width: 5rem;
    }
    .all-btn-5 .btn-light {
        padding: 6px 0px;
        font-size: 12px;
        min-width: 5rem;
    }
    .memory-img-size{
        height: 130px;
        width: 130px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 130px;
        width: 130px;
        left: 6px;
        top:5px;
    }
    .top-memory img {
        left: 73%;
        width: 27px !important;
    }
    .user-profile-accept-button{
        padding: 8px 35px;
        font-size: 18px !important;
        margin-left: 13%;
    }
    .user-profile-decline-button{
        padding: 8px 35px;
        font-size: 18px !important;
        margin-left: 8%;
    }
    .profile-share-memory-text{
        font-size:9px;
    }
    .profile-snap-memory-size{
        width: 30px !important;
    }
    .profile-show-all-buddies-button{

        width: 34px;
        height: 34px;
        line-height: 34px !important;
    }
    .profile-show-all-buddies-icon{
        width: 60%;
    }
}
@media only screen and (max-width: 432px){
    .body-right-5{
        margin-top: 6%;
    }
    .profile-top-username-navbar h2{
        margin-top:0% !important; 
        font-size: 18px;
    }
    .item-text-5 p {
        font-size: 13px;
    }
    .upper-text-5 h2 {
        color: #fff;
        font-weight: bold;
        font-size: 25px;
    }
    .user-profile-pencil-icon {
        margin-left: 280px;
        margin-top: -70px !important;
    }
    .profile-birthday-sellect-btn{
        margin-left: 170% !important;
    }
    /* modal */
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -135%;
        width: 400%;
    }
    /* end modal */
}
@media only screen and (max-width: 422px){
    .profile-item-hub-field{
        height: 5.5rem;
    }
    .profile-hubs-hub-name-layout{
        margin-top: -40px;
    }
    .profile-hubs-visites-layout{
        margin-top: -45px;
    }
    .profile-hubs-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: 10px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    .search-user-username-layout{
        font-size: 15px !important;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: -1%;
    }
    .user-profile-accept-button{
        padding: 8px 35px;
        font-size: 18px !important;
        margin-left: 12%;
    }
    .user-profile-decline-button{
        padding: 8px 30px;
        font-size: 18px !important;
        margin-left: 8%;
    }
    .profile-username-and-fullname{
        top: 50px;
        left: 140px;
        font-size: 14px;
    }
}
@media only screen and (max-width: 416px){
    .profile-change-icon-size{
        width: 40px !important;
    }
    .memory-img-size{
        height: 130px;
        width: 130px;
        margin-bottom: 5px;
        margin-left: -11px;
    }
    .memory-img-size-cover{    
        height: 130px;
        width: 130px;
        left: 6px;
        top:5px;
    }
    .top-memory img {
        left: 76%;
        width: 27px !important;
    }
}
@media only screen and (max-width: 407px){
    .memory-img-size{
        height: 120px;
        width: 120px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 120px;
        width: 120px;
        left: 5px;
        top:5px;
    }
    .top-memory img {
        left: 72%;
        width: 27px !important;
    }
    .profile-all-input-size{
        width: 130% !important;
    }
}
@media only screen and (max-width: 400px){
    .pofile-top-box-info-1{
        left: 25%;
        padding-top: 10px;
    }
    
    .pofile-top-box-info-2{
        left: 65%;
        padding-top: 10px;
    }
    
    .pofile-top-box-number{
        font-size: 22px;
    }
    .pofile-top-box-info-name{
        font-size: 14px;
    }

    .item-img-4 {
        width: 70px;
        height: 73px;
        background: #fff;
        margin: auto;
        line-height: 73px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4 img {
        width: 28px;
    }
    .item-img-4p {
        width: 70px;
        height: 73px;
        margin: auto;
    }
    /* modal */
    .post-hub-modal-frame {
        line-height: 73px;
        width: 55px;
        height: 55px;
    }  
    /* end modal */
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
    }
}
@media only screen and (max-width: 396px){
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-7.bv-text-5 {
        padding-left: 0px;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 6px 0px;
        font-size: 11px;
        min-width: 4.5rem;
    }
    .all-btn-5 .btn-light {
        padding: 6px 0px;
        font-size: 11px;
        min-width: 4.5rem;
    }
    .blue-box-5 {
        width: 70%;
    }
    .bv-img-5 img {
        width: 28px;
    }
    .divided-box-5 {
        max-width: 95%;
        margin: auto;
        padding: 12px 0px;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        border-radius: 12px;
    }
    /* .profile-username-position{
        margin-left: 40%!important;
    } */
    .user-profile-pencil-icon {
        margin-left: 260px;
        margin-top: -70px !important;
    }
    /* gag modal */
    .profile-gag-modal-box {
        height: 260px !important;
        margin-top: -150%;
        margin-left: -110%;
        width: 350%;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 15px !important;
        font-size: 12px !important;
    }
    /* end gag modal */
    .buddies-username-possition{
        margin-left: 17%;
    }
}
@media only screen and (max-width: 385px){
    .profile-top-info-box{
        width: 70px;
    }
    .profile-top-info-title{
        color: #575578;
        /* font-weight:bold; */
        margin-top: 10px;
        font-size: 11px;
    }
    .memory-img-size{
        height: 110px;
        width: 110px;
        margin-bottom: 5px;
        margin-left: -8px;
    }
    .memory-img-size-cover{    
        height: 110px;
        width: 110px;
        left: 4px;
        top:5px;
    }
    .top-memory img {
        left: 72%;
        width: 25px !important;
    }
    .user-profile-accept-button{
        padding: 8px 25px;
        font-size: 18px !important;
        margin-left: 14%;
    }
    .user-profile-decline-button{
        padding: 8px 20px;
        font-size: 18px !important;
        margin-left: 8%;
    }
}
@media only screen and (max-width: 376px){
    .profile-info-title-position{
        font-size: 11px !important;
    }
    .user-profile-btn-answered{
        font-size: 17px;
    }
    
    .profile-logout-button{
        padding: 10px 25px !important;
        width: 40%;
        font-size: 18px !important;
    }
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: 2%;
    }
    .profile-buddy-top-username-navbar{
        margin: auto;
        font-size: 16px !important;
        margin-top: -16px !important;

    }
    
    .profile-snap-memory-size{
        width: 25px !important;
    }
}
@media only screen and (max-width: 366px){
    .col-xl-10.col-lg-10.col-md-10.col-sm-10.col-10.text-left.blue.ml-0.text-s-5 {
        font-size: 15px;
    }
    .white-box-profile{
        max-width:100%;
        /* width: 200%; */
        margin: auto;
    }
    .profile-hubs-hub-name-layout{
        margin-top: -40px;
    }
    .all-btn-5 .btn-danger-profile {
        padding: 5px 0px;
        font-size: 8px;
        min-width: 3rem !important;
        /* margin-left: -20%; */
    }
    .all-btn-5 .btn-light {
        padding: 5px 0px;
        font-size: 8px;
        min-width: 3rem !important;
        /* margin-left: -20%; */
    }
    /* gag modal */
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 15px !important;
        font-size: 10px !important;
    }
    /* end gag modal */
    .top-memory img {
        left: 76%;
        width: 25px !important;
    }
    .user-profile-accept-button{
        margin-left: 12%;
    }
}
@media only screen and (max-width: 360px){
    .memory-img-size{
        height: 115px;
        width: 115px;
        margin-bottom: 5px;
        margin-left: -12px;
    }
    .memory-img-size-cover{    
        height: 115px;
        width: 115px;
        left: 4px;
        top:5px;
    }
}
@media only screen and (max-width: 350px){
    .profile-top-two-btns-left{
        font-size: 11px;
    }
    
    .profile-top-two-btns-right{
        font-size: 11px;
    }

    .user-profile-btn-answered{
        width: 80%;
    }
    .item-text-5 p {
        font-size: 10px;
    }
    .dp img{
        width: 115%;
    } 
    .item-text-5 h3 {
        font-size: 1.2rem;
    }
    .item-btn-5 .btn-info {
        padding: 6px 20px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        border-radius: 10px;
        background-color: #2D71B4;
        margin-top: -.8rem;
    }
    .text-s-5{
        font-size: 10px;
    }
    .text-s-5 {
        font-size: 13px;
        margin-top: .2rem;
    }
    .blue-box-5 {
        width: 75%;
    }
    .bv-img-5 img {
        width: 25px;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-7.bv-text-5 h3 {
        font-size: 17px;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-7.bv-text-5 p {
        font-size: 15px;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-5.text-left.item-text-5.mt-4.blue {
        margin-top: 1.5rem !important;
        width: 100%;
        margin-left: 10%;
    }
    .divided-box-5 {
        max-width: 100%;
        margin: auto;
        padding: 18px 0px;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        border-radius: 12px;
    }
    .col-xl-5.col-lg-5.col-md-5.col-sm-5.col-5.bv-img-5 {
        padding-left: 0px;
        padding-right: 0px;
        text-align: right;
        width: 17px;
        max-width: 50px !important;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-7.bv-text-5 {
        width: 113px !important;
        padding-right: 0px;
        padding-left: 10px;
    }
    .p-p p {
        margin-top: 4px !important;
        font-weight: bold;
        font-size: 12px!important;
    }
    .img-5 {
        max-width: 4% !important;
        margin-left: 0rem !important;
    }
    .col-xl-10.col-lg-10.col-md-10.col-sm-10.col-10.text-left.blue.ml-0.text-s-5 {
        font-size: 14px;
    }
    .icon-5 img{
        width: 25px!important;
    }
    .icon-55 img{
        width: 20px!important;
    }
    /* .profile-username-position{
        margin-left: 40%!important;
    } */
    .top-memory-size{
        width: 20px!important;
        border-radius: 10px;
        z-index: 99999999999999999999999 !important;
    }
    .user-profile-pencil-icon {
        margin-left: 240px;
        margin-top: -70px !important;
    }
    .profile-info-title-position{
        margin-left: -60%;
    }
    .buddies-profile-lock{
        width: 25% !important;
        margin-top: -0%;
    }

    .user-profile-btn-answered{
        font-size: 13px;
    }
    
    .profile-change-profilepic-icon{
        width: 40px;
        margin-top: 70px;
        margin-left: 4%;
    }
    .memory-img-size{
        height: 105px;
        width: 105px;
        margin-bottom: 5px;
        margin-left: -12px;
    }
    .memory-img-size-cover{    
        height: 105px;
        width: 105px;
        left: 3px;
        top:5px;
    }
    .top-memory img {
        left: 72%;
        width: 23px !important;
    }
}

@media only screen and (max-width: 342px){
    .profile-show-all-buddies-button{
        line-height: 44px !important;
    }
    .profile-show-all-buddies-icon{
        width: 70%!important;
    }
    /* gag modal */
    .profile-gag-modal-box {
        height: 240px !important;
        margin-top: -120%;
        margin-left: -95%;
        width: 320%;
    }
    .profile-gag-slider-layout{
        margin-top: 2% !important;
        margin-left: -0px !important;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 2px 15px !important;
        font-size: 10px !important;
        margin-top: -7px;
    }
    /* end gag modal */
    .profile-hubs-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: -5px !important;
        margin-top: 12% !important;
    }  
    .profile-hubs-hub-name-layout{
        margin-left: 5;
        /* margin-top: -38px; */
        font-size: 30px;
    }
    .profile-hubs-visites-layout{
        margin-left: 80%;
        /* margin-top: -33px; */
        font-size: 30px;
    }
    .profile-hubs-month-word{
        font-size: 7px; 
    }
    .search-user-username-layout{
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 336px){
    .profile-show-all-buddies-icon{
        margin-top: 10px !important;
    }
    .profile-info-title-position{
        font-size: 10px !important;
    }
    .item-img-4 {
        width: 60px;
        height: 65px;
        background: #fff;
        margin: auto;
        line-height: 65px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 60px;
        height: 65px;
        margin: auto;
    }
    .item-img-4 img {
        width: 22px;
    }
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
        font-size: 14px;
    }
    .profile-buddy-top-username-navbar{
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 332px){
    .profile-top-info-box{
        width: 65px;
    }
    .profile-top-info-title{
        color: #575578;
        /* font-weight:bold; */
        margin-top: 10px;
        font-size: 10px;
    }
    .profile-birthday-sellect-btn{
        margin-left: 190% !important;
        margin-top: -70px;
        padding: 6px 10px !important;
        font-size: 12px !important;
        width: 100% !important;
    }
    .user-profile-btn-answered{
        font-size: 17px;
    }
    .profile-sliders-names-size{
        font-size: 8px !important;
    }
}
@media only screen and (max-width: 330px){
    .user-profile-accept-button{
        padding: 8px 20px;
        font-size: 18px !important;
        margin-left: 11%;
    }
    .user-profile-decline-button{
        padding: 8px 18px;
        font-size: 18px !important;
        margin-left: 8%;
    }
    .profile-all-input-size{
        width: 125% !important;
    }
    .memory-img-size{
        height: 101px;
        width: 101px;
        margin-bottom: 5px;
        margin-left: -12px;
    }
    .memory-img-size-cover{    
        height: 101px;
        width: 101px;
        left: 3px;
        top:5px;
    }
    .profile-settings-icons-layout{
        width: 7%;
    }
    .profile-memory-settings-icons-layout{
        width: 7%;
        right: 11%;
        /* top:7%; */
    }
    .top-memory img {
        left: 74%;
        width: 23px !important;
    }
}

@media only screen and (max-width: 307px){
    .memory-img-size{
        height: 90px;
        width: 90px;
        margin-bottom: 5px;
        margin-left: -13px;
    }
    .memory-img-size-cover{    
        height: 90px;
        width: 90px;
        left: 3px;
        top:5px;
    }
    .dp img {
        width: 130%;
    }
    .item-text-5 p {
        font-size: 9px;
    }
    .item-btn-5 .btn-info {
        padding: 6px 20px;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        border-radius: 10px;
        background-color: #2D71B4;
        margin-top: -.8rem;
    }
    .icon-5 img {
        width: 25px;
    }
    .text-5 span {
        font-weight: bold;
        font-size: 15px;
    }
    .text-5 {
        background: #fff;
        padding: 3px 0px !important;
        border-radius: 7px;
        max-width: 52px !important;
        font-size: 16px !important;
        text-align: center;
    }
    .p-p p {
        margin-top: 4px !important;
        font-weight: bold;
        font-size: 10px!important;
    }
    .img-box-5.img-thumbnail strong {
        font-size: 10px !important;
    }
    .blue-box-5 {
        width: 80%;
    }
    .blue-box-5 img {
        width: 2.3rem;
        margin-bottom: 3px;
    }
    .blue-box-5 span {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: -20px;
    }
    .bv-text-5 h5 {
        color: rgba(87, 85, 120, 0.6);
        font-weight: bold;
        margin-top: 2px;
        font-size: 15px;
    }
    .col-xl-7.col-lg-7.col-md-7.col-sm-7.col-7.bv-text-5 p {
        font-size: 12px;
    }
    .divided-box-5 {
        max-width: 100%;
        margin: auto;
        padding: 12px 0px;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        border-radius: 12px;
    }
    .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-4.mt-4.item-btn-5 {
        margin-top: 1.1rem !important;
    }
    /* .profile-username-position{
        margin-left: 40%!important;
    } */
    .user-profile-pencil-icon {
        margin-left: 200px;
        margin-top: -70px !important;
    }
    .user-profile-btn-cancel{
        margin-top: 0% !important;
    }
    .profile-logout-button{
        padding: 10px 25px !important;
        width: 50%;
        font-size: 18px !important;
    }
}
@media only screen and (max-width: 300px){
    .profile-hubs-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: -10px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    .search-user-username-layout{
        font-size: 9px !important;
    }
    /* gag modal */

    .profile-gag-slider-layout{
        width: 100%;
    }
    .profile-gag-btn-danger {
        margin-top: -20px !important;
        padding: 2px 10px !important;
        font-size: 10px !important;
    }
    /* end gag modal */
    .user-profile-accept-button{
        padding: 8px 20px;
        font-size: 15px !important;
        margin-left: 11%;
    }
    .user-profile-decline-button{
        padding: 8px 18px;
        font-size: 15px !important;
        margin-left: 8%;
    }
}
@media only screen and (max-width: 285px){
    p.item-text-4.blue.pt-1 {
        margin-bottom: 7px;
        font-size: 12px;
    }
    .item-div-4 {
        background: #fff;
        padding: 20px 0px !important;
        max-width: 80% !important;
        border-radius: 13px;
        margin-left: 4.66%;
        cursor: pointer;
    }
    .item-img-4 {
        width: 41px;
        height: 45px;
        background: #fff;
        margin: auto;
        line-height: 45px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }
    .item-img-4p {
        width: 41px;
        height: 45px;
        margin: auto;
    }
    .item-img-4 img {
        width: 18px;
    }   
}

/* Footer icon css start */
.nav-bottom-5{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon-5 img {
    width: 22%;
}
/* .middle-icon-footer img {
    position: absolute;
    top: -30px;
    width: 92px !important;
    left: 30px;
} */
.middle-icon-footer-5 img {
    width: 60%;
    margin-left: 50%;
    transform: translate(-140%);
    position: absolute;
    top: -46px;
}
.footer-width-5{
    width: 20% !important;
}
/* Footer icon css end */