
.text-blue{
    color: #575578;
    font-size: 70%;
}
.gdpr-links-feedback-btn {    
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    width: 40% !important;
    border: none;
    padding: 10px 45px !important;
    border-radius: 30px !important;
    bottom: 0px!important;
    color: white !important;
    margin: auto !important;
    text-align: center;
}
.footer {
    padding-bottom: 60px !important;
}
.link2{
    color: #575578;
    margin-left: 15px;
}


@media only screen and (max-width: 752px){
    .gdpr-links-feedback-btn {    
        width: 50% !important;
        padding: 10px 35px !important;
    }
}

@media only screen and (max-width: 572px){
    .gdpr-links-feedback-btn {    
        width: 60% !important;
        padding: 10px 25px !important;
    }
}

@media only screen and (max-width: 452px){
    .gdpr-links-feedback-btn {    
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 380px){
    .gdpr-links-feedback-btn {    
        width: 70% !important;
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 319px){
    .gdpr-links-feedback-btn {    
        width: 80% !important;
    }
}