.setup-page-index-modal-box{
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.setup-page-index-modal-title{
    width: 80%;
    height: 40px;
    color: #232231;
    font-size: 14px;
    padding-left: 2%;
}

.setup-page-index-modal-slider{
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 5%;
}

.setup-page-index-modal-catalog-index{
    height: 100%;
    width: 100%;
    margin-top: 20px;
}

.setup-page-index-modal-catalog-index-btn{
    background-color: #fff;
    height: 80px;
    width: 80px;
    margin: auto;
    background-size: cover;
    border-radius: 50% !important;
    /* box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3); */
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    text-align: center;
    line-height: 80px;
    color: #232231;
    font-size: 20px;
}

.setup-page-index-modal-catalog-index-btn-slctd{
    height: 80px;
    width: 80px;
    margin: auto;
    background-size: cover;
    border-radius: 50% !important;
    /* background-color: #f7f7f7; */
    background: #1183ca !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    text-align: center;
    line-height: 80px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.setup-page-index-modal-catalog-index-now{
    /* width: 100%; */
    height: 20px;
    color: #232231;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
}

.set-gas-page-row{
    width: 100%;
    margin-left: 0%;
    margin-top: 90px;
    /* height: 50px; */
}

.set-gas-page-info-input-box{
    position: relative;
    width: 50%;
    margin-left: 0%;
    height: 100px;
    padding-top: 50px;
    /* background-color: #00b712; */
}

.set-gas-page-info-input-field{
    width: 90%;
    height: 100%;
    border-radius: 4px !important; 
    border: none;
    color: #7498aa !important;
    background-color: #e4e5e7 !important;
    z-index: 99;
    font-weight: bold;
    text-align: center;
    margin-left: 5%;
}

.set-gas-page-title-text{
    position: absolute;
    color: #232231;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    /* background-color: #2ca4b4;  */
    text-align: center;   
    top: 15px;
}

.setup-page-menu-price-input-text{
    z-index: 1 !important;
    position: absolute !important;
    top: 19%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 14px !important;
    z-index: 1 !important;
}

.setup-page-menu-size-small{
    display: block;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0%;
    top: 0%;    
}

.setup-page-menu-size{
    position: relative;
    width: 100% !important;
    height: 100% !important;
    /* left: 0%;
    top: 0%; */
    color:  hsl(35, 35, 35);
    border: solid 1px hsl(35, 20, 50);
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    /* background-color: rgba(14, 182, 8, 0.5); */
}

.setup-page-menu-description-modaltimes-box{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0%;
    right: 0%;
    /* background-color: brown; */
}

.setup-page-menu-description-modaltimes-img{
    width: 50%;
    height: 50%;
    margin-top: 2%;
    margin-left: 45%;
}

.setup-page-menu-description-modal-claim-btn{
    width: 60%;
    z-index: 9999999999 !important;
    height: 40px;
    background: #1183ca !important;
    padding: 5px;
    line-height: 30px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size:14px;
    font-weight: bold;
    color:#fff;
    position: absolute;
    bottom: 5%;
    left: 20%;
}

.setup-page-menu-description-modal-name-input{
    position: absolute;
    left: 15%;
    width: 70%;
    top: 15%;
    height: 60%;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.setup-page-menu-prcie-modal-input{
    position: absolute;
    left: 25%;
    width: 50%;
    top: 42%;
    height: 60px;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
    text-align: center;
}

.setup-page-menu-description-modal-titel{
    width: 80%;
    height: max-content;
    color: #232231 !important;
    margin: auto;
    text-align: center;
}

.setup-page-menu-description-modal-box{
    width: 100%;
    height: 100%;
    background-color: #fff;
}



.setup-page-menu-show-more-down-arrow-box{
    width: 30px;
    height: 30px;
    margin: auto;
    z-index: 999999999;
}

.setup-page-menu-show-more-down-arrow{
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.whole-menu-page-background-clr{
    width: 100%;
    height: 100%;
    background-color: black;
}

.setup-page-menu-show-more-box{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: rgb(36, 14, 133); */
    line-height: 25px;
}

.setup-page-menu-show-more-description{
    background: rgb(235, 235, 235,0.2);
    padding: 10px;
    color: black;
    position: absolute;
    bottom: 12%;
    left: 5%;
    width: 90%;
    /* font-weight: bold; */
}

.setup-page-menu-show-more-description-edit{
    color: blue;
    font-size: 20px !important;
    text-align: center !important;
    margin: auto !important;
    /* background-color: #a71d31; */
    margin-left: 35%;
    width: 30%;
    margin-top: 10px !important;
}

.setup-page-menu-show-more-price-edit{
    color: blue;
    font-size: 18px !important;
    text-align: center !important;
    margin: auto !important;
    /* background-color: #a71d31; */
    margin-left: 35%;
    width: 30%;
    margin-top: 5px !important; 
}

.setup-page-menu-show-blue{
    color: blue;
}

.setup-page-menu-show-more-price{
    background: rgb(235, 235, 235,0.2);
    padding: 5px;
    color: black;
    position: absolute;
    bottom: 5%;
    /* background-color: crimson; */
    width: 40%;
    left: 30%;
    text-align: center;
    font-weight: 40px !important;
    font-weight: bold;
    /* color: #232231; */
    border-radius: 30px;
    line-height: 18px
}

.setup-page-menu-bottom-place-row{
    margin-left: 0%;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom:0%;
}

.setup-page-menu-bottom-page-add-description{
    width: 70%;
    height: 100%;
    padding-top: 10px;
    line-height: 30px;
    padding-left: 3%;
    color: blue;
    font-weight: bold;
    font-size: 12px;
    background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%);
}

.setup-page-menu-bottom-page-description{
    width: 70%;
    height:40px;
    padding-top: 0px;
    line-height: 18px;
    padding-left: 3%;
    padding-right: 3%;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    /* background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%); */
}

.setup-page-menu-bottom-page-price{
    width: 30%;
    height:40px;
    /* padding-top: 10px; */
    line-height: 18px;
    padding-left: 3%;
    padding-right: 3%;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    /* background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%); */
    text-align: right;
}

.setup-page-menu-bottom-page-add-price{
    width: 30%;
    height: 100%;
    padding-top: 10px;
    line-height: 30px;
    padding-left: 3%;
    color: blue;
    font-weight: bold;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.4);
    background: linear-gradient(180deg, transparent 0%, rgb(235, 235, 235,0.8) 65%);
}



.setup-page-acces-modal-times-box{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0%;
    right: 0%;
    /* background-color: brown; */
}

.setup-page-acces-modal-times-img{
    width: 50%;
    height: 50%;
    margin-top: 2%;
    margin-left: 45%;
}

.setup-page-acces-remove-access{
    width: 40%;
    height: 80px;
    background-color: red;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
    line-height: 35px;
    font-weight: bold;
    z-index: 999999999999;
    position: absolute;
    top: 60%;
    left: 5%;
}

.setup-page-acces-remove-admin{
    width: 40%;
    height: 80px;
    background-color: red;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
    line-height: 35px;
    font-weight: bold;
    z-index: 999999999999;
    position: absolute;
    top: 60%;
    left: 55%;
}

.setup-page-acces-give-admin{
    width: 40%;
    height: 80px;
    background: #1183ca !important;
    color: #fff !important;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
    line-height: 35px;
    font-weight: bold;
    z-index: 999999999999;
    position: absolute;
    top: 60%;
    left: 55%;
}

.setup-page-users-with-acces-change-box{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 2%;
    margin-top: 30px;
    /* background-color: brown; */
}

.setup-page-users-with-acces-change-img{
    width: 50%;
    height: 50%;
    margin-top: 25%;
    margin-left: 25%;
}

.setup-page-users-with-acces-admin{
    color: blue;
}

.setup-page-acces-add-modal-finif{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 40%;
    height: 40px;
    line-height: 40px;
    left: 30%;
    bottom: 3% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-acces-add-modal-position-input{
    position: absolute !important;
    top: 65%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.setup-page-acces-add-modal-expalining-txt2{
    position: absolute !important;
    top: 60%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border: none;
    color: #969fa4 !important;
    padding-left: 0px;
    font-size: 13px;
    line-height: 14px;
}

.setup-page-acces-add-profile-pix{
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 12%;
}

.setup-page-acces-add-profile-pix2{
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 7%;
}

.setup-page-acces-add-profile-pix-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.setup-page-acces-add-username{
    color: #232231;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
}

.setup-page-acces-add-modal-nouser{
    width: 50%;
    height: 25px;
    font-size: 18px;
    color: #232231;
    font-weight: bold;
    position: absolute;
    text-align: center;
    top: 70%;
    left: 25%;
}

.setup-page-acces-add-modal-search-btn{
    /* position: absolute;
    top: 70%; */
    margin: auto;
    margin-top: 55%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 28px;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
}

.setup-page-acces-add-modal-search-img{
    width: 50%;
    margin-left: 25%;
    height: 50%;
    margin-top: 25%;
}

.setup-page-acces-add-modal-box{
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.setup-page-acces-add-modal-titel{
    width: max-content;
    height: 10%;
    color: #232231;
    font-size: 16px;
    margin: auto;
    text-align: center;
}

.setup-page-acces-add-modal-expalining-txt{
    position: absolute !important;
    top: 30%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border: none;
    color: #969fa4 !important;
    padding-left: 0px;
    font-size: 13px;
    line-height: 14px;
}

.setup-page-acces-add-modal-input{
    position: absolute !important;
    top: 47%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.setup-page-all-users-with-acces{
    width: 100%;
    position: absolute;
    top: 22%;
    left: 0%;
    height: 70%;
    /* background-color: crimson; */
}

.setup-page-users-with-acces-box{
    width: 100%;
    margin-left: 0%;
    height: 100px;
    /* background-color: #575578; */
    border-top: 1px solid rgb(214, 214, 214);
    /* background: linear-gradient(180deg, #fff 0%, rgb(245, 245, 245) 65%); */
}

.setup-page-users-with-acces-box-add{
    width: 100%;
    margin-left: 0%;
    height: 100px;
    /* background-color: #575578; */
    border-top: 1px solid rgb(214, 214, 214);
    border-bottom: 1px solid rgb(214, 214, 214);
    color: blue;
    line-height: 100px;
    font-size: 20px;
    font-weight: bold;
    text-align: center !important;
    /* background: linear-gradient(180deg, #fff 0%, rgb(245, 245, 245) 65%); */
}

.setup-page-users-with-acces-profile-box{
    width: 60px;
    height: 60px;
    margin-left: 10px;
    margin-top: 20px;
    /* background-color: #eee; */
}

.setup-page-users-with-acces-profile-img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}


.setup-page-users-with-acces-txt{
    width: max-content;
    height: 100px;
    margin-left: 20px;
    margin-top: 25px;
}


.setup-page-users-with-acces-username{
    width: max-content;
    height: 25px;
    font-size: 18px;
    color: #232231;
    font-weight: bold;
}

.setup-page-users-with-acces-position{
    position: relative;
    width: max-content;
    height: 25px;
    font-size: 16px;
    color: #232231;
}


.setup-page-catalog-remove{
    width: 60%;
    height: 30px;
    background-color: red;
    margin: auto;
    text-align: center;
    border-radius: 30px;
    padding: 0px 10px 0px 10px;
    line-height: 30px;
    margin-top: -10px;
    font-weight: bold;
    z-index: 999999999999;
} 

.setup-page-created-hours-box{
    width:100%;
    height: 40%;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: blue; */
    margin-left: 0%;
    margin-top: 10px;
}

.setup-page-created-hour{
    width:max-content;
    /* height: 20px; */
    text-align: center;
    /* line-height: 24px; */
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    padding: 5px 15px 5px 15px;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    margin: auto;
    margin-top: 5px;
}

.setup-page-created-hour-remove{
    position: absolute;
    border-radius: 50%;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    /* position: absolute; */
    /* top: 0%;
    right: 0%; */
    z-index: 99;
    /* position: inherit; */
    width: 20px;
    height: 20px;
    line-height: 14px;
    font-size: 14px;
    text-align: center;
    margin-top: -35px;
    margin-left: 45px;
}

.setup-page-res-hours-add{
    width: 20%;
    height: 70px;
    color: blue;
    position: absolute;
    right: 0%;
    text-align: center;
    font-weight: bold;
    line-height: 70px;
}

.setup-page-res-hours-margin{
    margin: auto;
    height: 100%;
    width: max-content;
}

.setup-page-res-hours-hour{
    width: 70px;
    height: 100%;
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    border-radius: 10px;
    color: #4d4f50 !important;
    background-color: #e4e5e7 !important;
    border: none;
}
.setup-page-res-hours-middle{
    width: 20px;
    height: 100%;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    line-height: 60px;
    color: #4d4f50 !important;
}

.setup-page-res-hours-row{
    width: 100%;
    height: 70px;
    margin-left: 0%;
    margin-top: 40%;
}

.setup-page-new-hour-done{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 40%;
    height: 50px;
    line-height: 50px;
    left: 30%;
    bottom: 15% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-reservation-hour-all-choises{
    width:100%;
    position: absolute;
    top: 30%;
}

.setup-page-reservation-hour-choise{
    width:80%;
    height: 60px;
    margin: auto;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    padding-top: 3px;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    margin-top: 20px;
}

.setup-page-lottie-position{
    position: absolute;
    width: 70%;
    left: 15%;
    top: 30%;
}

.setup-page-menu-input-box{
    /* background-color: brown; */
    position: absolute;
    width: 70%;
    left: 15%;
    height: 30%;
    top: 32%;
}

.setup-page-menu-input-img{
    position: relative;
    width: 70%;
    /* height: 120px; */
    margin-left: 15% !important;
}

.setup-page-menu-input-btn{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 50%;
    height: 50px;
    line-height: 50px;
    left: 25%;
    top: 70% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;    
}

.setup-page-map_iconslider{
    width: 100%;
    position: absolute;
    top: 25%;
}

.setup-page-map_iconframe{
    width: 120px;
    height: 120px;
    padding: 10px;
    /* background-color: blue; */
    margin: auto;
}

.setup-page-map_iconname{
    width: 100%;
    height: 60px;
    text-align: center;
    font-weight: bold;
    /* background-color: crimson; */
    color: #232231;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5px;
}

.setup-page-map_icon-img{
    width: 100%;
    height: 100%;
    z-index: 1;
}

.setup-page-catalog-select-budies-frame{
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 295px;
    border-radius: 20px 20px 0px 0px;
    margin-top: 10%;
}

.setup-page-catalog-buddies-card{
    position: absolute;
    width: 96%;
    height: 88%;
    background-color: rgb(27, 19, 19);
    top: 1.5%;
    left: 2%;
    border-radius: 20px;
    box-shadow: rgb(27, 19, 19, 0.1) 0px 10px 50px;    /* rgba(22, 93, 137, 0.4)  */   
}


.setup-page-catalog-buddies-card-square{
    /* background-color: #000; */
    width: 100% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -20px 30px 10px rgb(27, 19, 19) inset !important;
}
  
.setup-page-catalog-buddies-card-square:after {
    content: "";
    display: block;
    padding-bottom: 120%;
  }



.setup-page-catalog-buddies-username{
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    bottom: 3%;
    left: 0%;
    width: 100%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.setup-page-all-catalogs{
    width: 100%;
    height: 50%;
    position: absolute;
    top: 28%;
    left: 0%;
}

.setup-page-catalog-index{
    height: 40px;
}

.setup-page-catalog-index-btn{
    background-color: #fff;
    height: 40px;
    width: 40px;
    margin: auto;
    background-size: cover;
    border-radius: 50% !important;
    /* box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3); */
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    text-align: center;
    line-height: 40px;
    color: #232231;
}

.setup-page-new-catalog-btn{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 60%;
    height: 50px;
    line-height: 50px;
    left: 20%;
    top: 15% !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-set-promo-photo{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 40%;
    height: 50px;
    line-height: 50px;
    left: 30%;
    top: 30% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-set-cover-photo{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 30%;
    height: 30px;
    line-height: 30px;
    left: 35%;
    top: 40% !important;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-set-logo-photo{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 30%;
    height: 30px;
    line-height: 30px;
    left: 35%;
    top: 26% !important;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}

.setup-page-map-layout-sml{
    position: absolute;
    bottom: 50px !important;
    left: 0%;
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.setup-page-map-layout-sml2{
    position: absolute;
    bottom: 50px !important;
    left: 0%!important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.setup-page-place-show-bubble{
    position: absolute;
    /* height: 100px;
    width: 100%; */
    top: 53px;
    left: 0%;
    z-index: 9999;
}

.setup-page-promo-photo-box{
    position: absolute;
    background-color: #fff;
    top: 0%;
    left: 0%;
    z-index: 9999;
}

.setup-page-bubble-bottom-text{
    position: absolute;
    border-bottom: 1px solid rgb(241, 241, 241);
    border-top: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, rgb(245, 245, 245) 0%, rgb(235, 235, 235) 65%);
    bottom: 0%;
    left: 0%;
    z-index: 99999; 
}


.setup-page-bubble-closer-yellow-bubble{
    width: 15px;
    height: 15px;
    background-color: #f5d020;
    background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.setup-page--promo-photo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.setup-page-bubble-closer-name{
    color: #232231;
    z-index: 999999;
    position: absolute;
    top: 0%;
    font-size: 12px;
    font-weight: bold;
    left: 0%;
    padding-left: 3px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}


.setup-page-bubble-closer-category{
    color: #232231;
    z-index: 999999;
    position: absolute;
    top: 18px;
    font-size: 12px;
    font-weight: bold;
    left: 0%;
    padding-left: 3px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}
.setup-page-bubble-closer-is-open{
    color: #1cc016;
    z-index: 999999;
    position: absolute;
    top: 36px;
    font-size: 12px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.setup-page-bubble-closer-is-closed{
    color: #c01616;
    z-index: 999999;
    position: absolute;
    top: 36px;
    font-size: 12px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.setup-page-bubble-closer-has-label{
    color: #232231;
    z-index: 999999;
    position: absolute;
    top: 36px;
    font-size: 12px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.3);
}

.setup-page-bubble-closer-red-bubble{
    width: 15px;
    height: 15px;
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.setup-page-bubble-closer-green-bubble{
    width: 15px;
    height: 15px;
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.setup-page-bubble-closer-yellow-bubble{
    width: 15px;
    height: 15px;
    background-color: #f5d020;
    background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}


.setup-page-my-categories{
    width: 90%;
    /* height: 30%; */
    height: max-content;
    position: relative;
    margin-top: 35%;
    margin-left: 5%;
    /* background-color: blue; */
}
.setup-page-my-categories-row{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    /* background-color: red; */
}

.setup-page-all-categories{
    width: 90%;
    /* height: 30%; */
    height: max-content;
    position: relative;
    margin-top: 5%;
    margin-left: 5%;
    /* background-color: blue; */
}

.setup-page-all-my-categories-category{
    width: max-content;
    height: max-content;
    background: #1183ca !important;
    color: #fff !important;
    line-height: 30px;
    border-radius: 8px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    padding: 0px 8px 0px 12px;
    margin: 10px;
}

.setup-page-all-categories-category{
    width: max-content;
    height: max-content;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    line-height: 30px;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    padding: 0px 12px 0px 12px;
    margin: 10px;
}

.setup-page-all-categories-row{
    width: 100%;
    height: 100%;
    margin-left: 0px;
}

.setup-page-day-is_close{
    position: relative;
    width: 50px;
    height: 30px;
    margin-top: 10px;
    margin-left: 16%;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
}

.setup-page-day-change_close{
    position: relative;
    width: 50px;
    height: 30px;
    margin-top: 10px;
    margin-left: 18%;
    line-height: 30px;
    text-align: center;
}

.setup-page-day-close{
    position: relative;
    width: 50px;
    height: 30px;
    margin-top: 10px;
    margin-left: 13%;
    line-height: 30px;
    text-align: center;
    color: rgb(170, 60, 60) !important;
}

.setup-page-day-row{
    width: 100%;
    margin-left: 0%;
}

.setup-page-closing-btn{
    position: relative;
    width: 70px;
    height: 30px;
    margin-top: 10px;
    margin-left: 40%;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bolder;
    line-height: 30px;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
}

.setup-page-name-input-field{
    width: 100%;
    height: 100%;
    border-radius: 8px !important; 
    border: none;
    color: #4d4f50 !important;
    background-color: #e4e5e7 !important;
    z-index: 99;
    font-weight: bold;
    text-align: center;
}

.setup-page-name-input-field-closing{
    position: relative;
    width: 70px;
    height: 30px;
    margin-top: 10px;
    margin-left: 40%;
    line-height: 30px;
    border-radius: 8px !important; 
    border: none;
    color: #4d4f50 !important;
    background-color: #e4e5e7 !important;
    z-index: 99;
    font-weight: bold;
    text-align: center;
}

.setup-page-name-input-field-opening{
    position: relative;
    width: 70px;
    height: 30px;
    margin-top: 10px;
    margin-left: 10%;
    line-height: 30px;
    border-radius: 8px !important; 
    border: none;
    color: #4d4f50 !important;
    background-color: #e4e5e7 !important;
    z-index: 99;
    font-weight: bold;
    text-align: center;
}

.setup-page-opening-btn{
    position: relative;
    width: 70px;
    height: 30px;
    margin-top: 10px;
    margin-left: 10%;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bolder;
    line-height: 30px;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
}

.setup-page-all-days-layout{
    width: 100%;
    height: 350px;
    position: absolute;
    top: 25%;
    left: 0%;
    color: #232231 !important;
}
.setup-page-day-layout{
    width: 32%;
    height: 50px;
    /* border: 1px solid #575578; */
    color: #232231;
    line-height: 50px;
    font-weight: bold;
    padding-left: 10px;
}

.setup-page-reservation-question-text{
    width:100%;
    position: absolute;
    color: #575578;
    top: 35%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.setup-page-reservation-question-box{
    width:100%;
    height: 50px;
    position: absolute;
    top: 50%;
}

.setup-page-reservation-question-yes{
    width:30%;
    height: 50px;
    position: absolute;
    background: #5cb85c !important;
    text-align: center;
    left: 55%;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
}

.setup-page-reservation-question-no{
    width:30%;
    height: 50px;
    position: absolute;
    text-align: center;
    left: 15%;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
    background: #d9534f !important;
}

.setup-page-reservation-through-other{
    width:30%;
    height: 80px;
    position: absolute;
    text-align: center;
    left: 15%;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    padding-top: 10px;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
}

.setup-page-reservation-through-hubst3r{
    width:30%;
    height: 80px;
    position: absolute;
    text-align: center;
    left: 55%;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
    padding-top: 10px;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
}

.setup-page-reservation-through-telephone{
    width:60%;
    height: 80px;
    position: absolute;
    top: 120px;
    text-align: center;
    left: 20%;
    line-height: 60px;
    font-size: 20px;
    /* font-weight: bold; */
    border-radius: 8px;
    padding-top: 10px;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
}

.whole-setup-page-page-layout{
    position: absolute;
    top: 0%;
    left: 0%;
    width:100%;
    height: 100% !important;
}

.setup-page-welcome-title{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 53px;
    z-index: 99999999;
    line-height: 53px;
    text-align: center;
    font-weight: bold;
    font-size: 15px !important;
    color: #232231;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
}


.setup-page-back-box{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 15%;
    height: 100%;
    /* background-color: red; */
}

.setup-page-back-icon-box{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 20px;
    height: 20px;
    /* background-color: blue; */
}
.setup-page-back-icon{
    width: 100%;
    height: 100%;
}

.setup-page-next-btn{
    background: #1183ca !important;
    padding: 5px 5px !important;
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 60px;
    line-height: 60px;
    left: 0%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
}

.setup-page-next-btn-half{
    background: #1183ca !important;
    padding: 5px 5px !important;
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 50%;
    height: 60px;
    line-height: 60px;
    left: 50%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
    z-index: 9999999 !important;
}

.setup-page-back-btn-big{
    background: #fff !important;
    padding: 5px 5px !important;
    color: #232231;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 60px;
    line-height: 58px;
    left: 0%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
    border: 1px solid #232231;
}

.setup-page-back-btn{
    background: #fff !important;
    padding: 5px 5px !important;
    color: #232231;
    text-align: center;
    position: absolute;
    width: 50%;
    height: 60px;
    line-height: 58px;
    left: 0%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
    border: 1px solid #232231;
    z-index: 9999999;
}

.setup-page-logo-text{
    width:100%;
    position: absolute;
    color: #575578;
    top: 15%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}


.setup-page-logo-box{
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: 40%;
}

.setup-page-logo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204);
}

.setup-page-name-text{
    width:100%;
    position: absolute;
    color: #575578;
    top: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.setup-page-name-input-box{
    position: absolute;
    width: 90%;
    top: 60%;
    left: 5%;
    height: 50px;
}


.setup-page-info-text{
    width:100%;
    position: absolute;
    color: #575578;
    top: 35%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.setup-page-info-input-box{
    position: absolute;
    width: 90%;
    top: 50%;
    left: 5%;
    height: 50px;
}

.setup-page-info-input-field{
    width: 100%;
    height: 100%;
    border-radius: 8px !important; 
    border: none;
    color: #4d4f50 !important;
    background-color: #e4e5e7 !important;
    z-index: 99;
    font-weight: bold;
    text-align: center;
}

.setup-page-info-done{
    background: #1183ca !important;
    /* padding: 5px 5px !important; */
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 40%;
    height: 50px;
    line-height: 50px;
    left: 30%;
    top: 70% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px !important;
    border: none;
}


.setup-page-pdf-input{
    width:100px;
    height: 100px;
    margin: auto;
    margin-top: 50%;
    background-color: blue;

}

.place-setup-page-terms-icon-box{
    width: 70%;
    position: absolute;
    left: 15%; 
    top: 32%;
}

.place-setup-page-terms-icon-img{
    width: 100%;
    height: 100%;
}

.place-setup-page-terms-top-text{
    color: #575578;
    text-align: center;
    position: absolute;
    width: 80%;
    left: 10%;
    font-size: 14px;
    top: 60%;
}

.place-setup-page-terms-accept{
    background: #1183ca !important;
    padding: 5px 5px !important;
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 80px;
    line-height: 80px;
    left: 0%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
}

.place-setup-page-section-disappear{
    display: none;
}
.place-setup-page-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.place-setup-page-lottie-dissappear{
    display: none;
}

.place-setup-page-welcome-title{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:10px;
}

.place-setup-page-explaing-text{
    color: #575578;
    font-size: 12px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:40px;   
    line-height: 12px;
}

.place-setup-page-logo-box{
    position: absolute;
    right: 1%;
    top: 2%;
    width: 100px;
    height: 100px;
}

.place-setup-page-logo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204);
}

.place-setup-page-create-reservation-btn{
    background: #1183ca !important;
    border: none !important;
    padding: 5px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center;
    width: 30%;
    position: absolute;
    left: 45%;
    top: 70px;
}

.place-setup-page-create-reservation-btn:active {
    transform: scale(0.88);
}

.place-setup-page-create-reservation-btn:focus {
    transform: scale(0.88);
}

.place-setup-page-create-reservation-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.place-setup-page-question-box{
    position: absolute;
    left: 30%;
    top: 20% !important;
    width: 60%;
    height: 100%;
}

.place-setup-page-question-left{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.place-setup-page-question-bottom-left{
    position: absolute;
    left: 0%;
    top: 45%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.place-setup-page-question-right{
    position: absolute;
    left: 50%;
    top: 0%;
    width: 50%;
    height: 100%;
    display: block;
}

.place-setup-page-question-bottom-right{
    position: absolute;
    left: 50%;
    top: 45%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.place-setup-page-question-btn{
    display: block;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    position: absolute;
    width: 90%;
    margin-left: 5%;
    height: 255px;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}


.place-setup-page-question-btn:active {
    transform: scale(0.94);
}

.place-setup-page-question-btn:focus {
    transform: scale(0.94);
}

.place-setup-page-question-btn::after {
    transform: scale(0.94);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}




.place-setup-page-question-icon-box{
    margin: auto;
    width: 150px;
    height: 150px;
    margin-top: 5%;
}

.place-setup-page-question-icon{
    width: 100%;
    height: 100%;
}

.place-setup-page-question-text{
    color: #575578;
    text-align: center;
    font-size: 12px;
    margin-top: 5%;
    width: 85%;
    margin-left: 7.5%;
}

.place-setup-page-question-text-small{
    color: #575578;
    text-align: center;
    font-size: 10px;
    margin-top: 5px;
    width: 100%;
    margin-left: 0%;
}

.place-setup-page-notification-number{
    font-weight: bold;
    margin: auto;
    position: absolute;
    color: #ffff;
    font-size: 12px !important;
    line-height: 32px;
    border-radius: 50%;
    background-color: #dd3535;
    width: 40px;
    height: 40px;
    top: 26%;
    right: 36%;
    text-align: center !important; 
    z-index: 99999999 !important;
    /* padding-left: 4px; */
    border: 5px solid rgb(236, 236, 236);
    letter-spacing: 0.5px;
}


@media only screen and (max-width: 1200px){
    .place-setup-page-notification-number{
        top: 29%;
        right: 39%;
    }


    .whole-place-setup-page-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100% !important;
    }
    .place-setup-page-welcome-title{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        z-index: 99999999;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 15px !important;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    }

    .place-setup-page-back-box{
        position: absolute;
        left: 0%;
        top: 0%;
        width: 15%;
        height: 100%;
        /* background-color: red; */
    }

    .place-setup-page-back-icon-box{
        position: absolute;
        left: 0%;
        top: 0%;
        width: 20px;
        height: 20px;
        /* background-color: blue; */
    }
    .place-setup-page-back-icon{
        width: 100%;
        height: 100%;
    }

    .place-setup-page-profile-pic-box{
        position: absolute;
        left: 25px;
        top: 0%;
        width: 25px;
        height: 25px;
    }

    .place-setup-page-profile-pic{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: solid 0.5px rgb(204, 204, 204);
    }

    .place-setup-page-logo-box{
        position: relative;
        margin: auto;
        margin-top: 16%;
        width: 120px;
        height: 120px;
    }

    .place-setup-page-question-box{
        left: 0%;
        top: 35% !important;
        height: 60%;
        width: 100%;
    }

    .place-setup-page-create-reservation-btn{
        width: 100%;
        height: 80px;
        line-height: 80px;
        left: 0%;
        top: initial;
        bottom: 0% !important;
        font-size: 30px;
        font-weight: bold;
        border-radius: 0px !important;
        border: none;
        position: fixed !important;

    }

    .place-setup-page-explaing-text{
        font-size: 20px !important;
        width: 100%;
        top:7%;   
        line-height: 30px;
    }
    
}

@media only screen and (max-width: 800px){
    .place-setup-page-notification-number{
        top: 27%;
        right: 36%;
    }
}


@media only screen and (max-width: 650px){
    .place-setup-page-notification-number{
        font-size: 11px !important;
        line-height: 26px;
        width: 32px;
        height: 32px;
        top: 25%;
        right: 37%;
        border: 3px solid rgb(236, 236, 236);
    }

    .place-setup-page-question-box{
        top: 30% !important;
        /* height: 70%; */
        position: absolute;
        left: 0%;
        /* top: 22%; */
        width: 100%;
        height: 470px;
    }
    
    .place-setup-page-question-icon-box{
        width: 50px;
        height: 50px;
    }
    .place-setup-page-question-btn{
        height: 190px;
    }
    .place-setup-page-explaing-text{
        font-size: 18px !important;
        top:8%;   
        line-height: 25px;
        font-weight: normal;
    }

    .place-setup-page-logo-box{
        margin: auto;
        margin-top: 18%;
        width: 90px;
        height: 90px;
    }
    
}

@media only screen and (max-width: 450px){
    .place-setup-page-notification-number{
        font-size: 10px !important;
        line-height: 23.5px;
        width: 30px;
        height: 30px;
        top: 20%;
        right: 32%;
        border: 3px solid rgb(236, 236, 236);
    }
    .place-setup-page-question-box{
        position: absolute;
        left: 0%;
        top: 30% !important;
        width: 100%;
        height: 35%;
    }

    .place-setup-page-logo-box{
        margin-top: 23%;
        width: 90px;
        height: 90px;
    }

    .place-setup-page-create-reservation-btn{
        height: 50px;
        line-height: 40px;
        font-size: 25px;
    }
    
    .place-setup-page-question-bottom-left{
        top: 210px;

    }
    
    .place-setup-page-question-bottom-right{
        top: 210px;
    }

}

@media only screen and (max-width: 400px){
    .place-setup-page-question-box{
        /* top: 22%; */
        height: 33%;
        top: 25% !important;
    }
    .place-setup-page-explaing-text{
        font-size: 14px !important;
        top:65px;   
        line-height: 18px;
        font-weight: normal;
    }

    .place-setup-page-logo-box{
        margin-top: 95px;
        width: 80px;
        height: 80px;
    }
    .place-setup-page-notification-number{
        font-size: 10px !important;
        line-height: 25px;
        width: 30px;
        height: 30px;
        top: 20%;
        right: 30%;
        border: 3px solid rgb(236, 236, 236);
    }
}

@media only screen and (max-width: 365px){
    .place-setup-page-question-text{
        font-size: 12px;
    }
}

@media only screen and (max-width: 346px){
    .setup-page-day-close{
        margin-left: 8%;
    }
}

@media only screen and (max-width: 340px){
    .place-setup-page-question-btn{
        height: 170px;
    }
    .place-setup-page-logo-box{
        margin-top: 95px;
        width: 70px;
        height: 70px;
    }
    .place-setup-page-notification-number{
        right: 27%;
    }
}



.setup-page-buddies-waiting-box{
    position: absolute;
    width: 100%;
    height: 100%;
}

.setup-page-buddies-waiting-img{
    position: relative;
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 50%;
}
.setup-page-buddies-waiting-profile{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: solid 1px rgb(204, 204, 204);
    animation: search_pulse 2s infinite;
}

@keyframes search_pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(221, 8, 18, 0.1);
        
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 250px rgba(221, 8, 18, 0.1); 
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 250px rgba(47, 8, 221, 0.1);
	}
}

.setup-page-hubs-visit-next-position{
    position: absolute;
    top: 80px;
    right: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.setup-page-hubs-visit-add-position{
    position: absolute;
    top: 80px;
    left: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.setup-page-hubs-visit-profile-position{
    position: absolute;
    bottom: 140px;
    left: 20%;
    width: 60%;
    height: 20%;  
    opacity: 0.9;
    z-index: 9999;
}
.setup-page-hubs-visit-icon-img{
    width: 100%;
    height: 100%;
}
.setup-page-hubs-all-choises-btns{
    width: 100% !important;
    height: 10%;
    /* background-color: red; */
    position: absolute;
    top: 87%;
    /* left: 0%; */
}
.setup-page-hubs-all-choises-row{
    position: relative;
    width: 100% !important;
    height: 100%;
    top: 0%;
    margin-left: 0%;
}
.setup-page-hubs-choise-box{
    position: relative;
    width:25%;
    height: 100%;
    /* background-color: blue; */
}

.setup-page-hubs-choise-icon-box{
    position: relative;
    width:70%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
}

.setup-page-hubs-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.setup-page-hubs-choise-icon-box-small{
    position: relative;
    width:50%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
    margin-top: 12%;
}

.setup-page-hubs-choise-icon-box-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }



  .setup-page-hubs-choise-icon-img{
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
}

.back-button-setup-page-hubs-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.setup-page-hubs-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.setup-page-hubs-memories-box-empty{
    width: 90%;
    height: 18%;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
    position: absolute;
    top: 68%;
    left:5%;
    border-radius: 20px;
}

.setup-page-hubs-no-memories-yet{
    color: #606062;
    font-size: 12px !important;
    font-weight: lighter;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0%;
    margin-top: 60px;
    /* background-color: darkred; */
}
.setup-page-hubs-empty-photos-icon{
    width: 100%;
    height: 100%;
}

.setup-page-hubs-empty-photos-box{
    margin: auto;
    width: 5%;
    /* height: 18px; */
    margin-top: 20px;
}

.setup-page-hubs-memory-size{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9999;
}

.setup-page-hubs-memory-empty{
    width: 100%;
    height: 100%;
    /* background-color: rgb(27, 19, 19); */
    background: linear-gradient(0deg, rgb(194, 192, 192) 0%, rgb(117, 116, 116) 50%);
}

.setup-page-hubs-username{
    font-size: 17px;
    font-weight: bold;
    position: absolute;
    top: 60%;
    left: 3%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.setup-page-hubs-memories-box{
    width: 100%;
    height: 18%;
    /* background-color: rgb(53, 42, 42);; */
    position: absolute;
    top: 68%;
    left: 0%;
}

.setup-page-hubs-memory-box-1{
    position: absolute;
    width: 30%;
    left: 3%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.setup-page-hubs-memory-box-2{
    position: absolute;
    width: 30%;
    left: 35%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.setup-page-hubs-memory-box-3{
    position: absolute;
    width: 30%;
    left: 67%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.setup-page-hubs-swipe-card{
    position: absolute;
    width: 96%;
    height: 80%;
    background-color: rgb(27, 19, 19);
    top: 73px;
    left: 2%;
    border-radius: 20px;
}

.setup-page-hubs-swipe-box{
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    /* background-color: #000; */
}

.setup-page-hubs-swipe-square{
    /* background-color: #000; */
    width: 100% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -80px 30px 10px rgb(27, 19, 19) inset !important;
    /* background-color: firebrick; */
}

.setup-page-hubs-map-frame{
    position: absolute;
    width: 100%;
    height: 60% !important;
    border-radius: 20px 20px 0px 0px;
}

.setup-page-hubs-logo-box{
    position: absolute;
    width:70px;
    height: 70px;
    left: 0.5%;
    top: 46.5%;
}

.setup-page-hubs-logo-img{
    width:100%;
    height: 100%;
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204);
}

.setup-page-hubs-black-cover-box{
    width: 100%;
    height: 5%;
    background-color: rgb(27, 19, 19);
    position: absolute;
    top: 80%;
    left:0%;
}
  
.setup-page-hubs-swipe-square:after {
    content: "";
    display: block;
    padding-bottom: 120%;
  }
.setup-page-hubs-swipe-square-small{
    width: 50% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}

.setup-page-hubs-swipe-square-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.setup-page-hubs-swipe-img{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.setup-page-hubs-right-10{
    margin-left: 16.67%;    
}

.setup-page-hubs-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1200px){
    .setup-page-hubs-right-10{
        margin-left: initial;
    }
    .setup-page-hubs-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
        }
}

@media only screen and (max-width: 400px){
    .setup-page-catalog-select-budies-frame{
        min-width: 160px;
        min-height: 265px;
    }
}

@media only screen and (max-width: 395px){
    .setup-page-hubs-swipe-card{
        height: 78%;
    }
}

@media only screen and (max-width: 355px){
    .setup-page-hubs-username{
        font-size: 14px;
        top: 62%;
    }
    .setup-page-hubs-empty-photos-box{
        margin: auto;
        width: 5%;
        /* height: 18px; */
        margin-top: 10px;
    }
    .setup-page-hubs-no-memories-yet{
        margin-top: 40px;
    }
    .setup-page-hubs-swipe-card{
        height: 75%;
    }
}

@media only screen and (max-width: 355px){
    .setup-page-catalog-select-budies-frame{
        min-width: 150px;
        min-height: 245px;
    }
    .setup-page-map_iconframe{
        width: 100px;
        height: 100px;
    }
}