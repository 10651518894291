.swipe-buddies-visit-next-position{
    position: absolute;
    top: 80px;
    right: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.swipe-buddies-visit-add-position{
    position: absolute;
    top: 80px;
    left: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.swipe-buddies-visit-profile-position{
    position: absolute;
    bottom: 140px;
    left: 20%;
    width: 60%;
    height: 20%;  
    opacity: 0.9;
    z-index: 9999;
}
.swipe-buddies-visit-icon-img{
    width: 100%;
    height: 100%;
}
.swipe-buddies-all-choises-btns{
    width: 100% !important;
    height: 10%;
    /* background-color: red; */
    position: absolute;
    top: 78%;
    /* left: 0%; */
}
.swipe-buddies-all-choises-row{
    position: relative;
    width: 100% !important;
    height: 100%;
    top: 0%;
    margin-left: 0%;
}
.swipe-buddies-choise-box{
    position: relative;
    width:25%;
    height: 100%;
    /* background-color: blue; */
}

.swipe-buddies-choise-icon-box{
    position: relative;
    width:70%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
}

.swipe-buddies-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.swipe-buddies-choise-icon-box-small{
    position: relative;
    width:50%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
    margin-top: 12%;
}

.swipe-buddies-choise-icon-box-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }



  .swipe-buddies-choise-icon-img{
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
}

.back-button-swipe-buddies-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.swipe-buddies-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.swipe-buddies-memories-box-empty{
    width: 90%;
    height: 18%;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
    position: absolute;
    top: 68%;
    left:5%;
    border-radius: 20px;
}

.swipe-buddies-no-memories-yet{
    color: #606062;
    font-size: 12px !important;
    font-weight: lighter;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0%;
    margin-top: 60px;
    /* background-color: darkred; */
}
.swipe-buddies-empty-photos-icon{
    width: 100%;
    height: 100%;
}

.swipe-buddies-empty-photos-box{
    margin: auto;
    width: 5%;
    /* height: 18px; */
    margin-top: 20px;
}

.swipe-buddies-memory-size{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9999;
}

.swipe-buddies-memory-empty{
    width: 100%;
    height: 100%;
    /* background-color: rgb(27, 19, 19); */
    background: linear-gradient(0deg, rgb(194, 192, 192) 0%, rgb(117, 116, 116) 50%);
}

.swipe-buddies-username{
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 60%;
    left: 5%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.swipe-buddies-memories-box{
    width: 100%;
    height: 18%;
    /* background-color: rgb(53, 42, 42);; */
    position: absolute;
    top: 68%;
    left: 0%;
}

.swipe-buddies-memory-box-1{
    position: absolute;
    width: 30%;
    left: 3%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.swipe-buddies-memory-box-2{
    position: absolute;
    width: 30%;
    left: 35%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.swipe-buddies-memory-box-3{
    position: absolute;
    width: 30%;
    left: 67%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.swipe-buddies-swipe-card{
    position: absolute;
    width: 96%;
    height: 88%;
    background-color: rgb(27, 19, 19);
    top: 1.5%;
    left: 2%;
    border-radius: 20px;
    box-shadow: rgb(27, 19, 19, 0.1) 0px 10px 50px;    /* rgba(22, 93, 137, 0.4)  */   
}

.swipe-buddies-swipe-box{
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    /* background-color: #000; */
}

.swipe-buddies-swipe-square{
    /* background-color: #000; */
    width: 100% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -80px 30px 10px rgb(27, 19, 19) inset !important;
}
.swipe-buddies-black-cover-box{
    width: 100%;
    height: 5%;
    background-color: rgb(27, 19, 19);
    position: absolute;
    top: 80%;
    left:0%;
}
  
.swipe-buddies-swipe-square:after {
    content: "";
    display: block;
    padding-bottom: 120%;
  }
.swipe-buddies-swipe-square-small{
    width: 50% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}
  
.swipe-buddies-swipe-square-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.swipe-buddies-swipe-img{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.swipe-buddies-right-10{
    margin-left: 16.67%;    
}

.swipe-buddies-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1200px){
    .swipe-buddies-right-10{
        margin-left: initial;
    }
    .swipe-buddies-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
        }

}

@media only screen and (max-width: 340px){


}