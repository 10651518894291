.offline-label {
    width: 100%!important;
    height: 40px!important;
    font-size: 20px;
    margin: auto!important;
    color: white;
    background-color:#d9534f
}

@media only screen and (max-width: 800px){
    .offline-label {
        font-size: 18px;
    }
}

@media only screen and (max-width: 500px){
    .offline-label {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350px){
    .offline-label {
        font-size: 15px;
    }
}

@media only screen and (max-width: 350px){
    .offline-label {
        font-size: 13px;
    }
}