.hubs-access-logo-box{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-top: -8px;
    /* background-color: red; */
}
.hubs-access-logo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204);
}

.hubs-access-times{
    position: absolute;
    width: 5%;
    right: 3%;
    top: 3%;
    z-index: 99999999 !important;
}

.hubs-access-buddy-name{
    position: absolute !important;
    top: 2.5%;
    width: 100%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 0%;
    color: #272635;
    font-size: 20px !important;
    font-weight: bold;
} 

.hubs-access-title-hr{
    position: absolute !important;
    width: 100%;
    height: 10px;
    top:35px;
}

.hubs-access-all-choises{
    position: absolute;
    top: 60px;
    width: 100%;
    height: 400px;
    /* background-color: rgb(34, 45, 202); */
}

.hubs-access-choise{
    width: 90%;
    margin-left: 5%;
    height: 24px;

}

.hubs-access-choise-text{
    /* width: 100%; */
    text-align: left;
    color: #272635;
    font-size: 18px !important;
    font-weight: normal;
    margin-left: 20px;
}

.hubs-access-remove-icon-box{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 3%;
    top: 30px;
    z-index: 99999999 !important;
}

.hubs-access-remove-icon{
    position: absolute;
    width: 100%;
    height: 100%;
}


.hubs-access-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 30px;
    background: #fff; 
    margin-top: -250px;
    margin-left: -100px;
    height: 500px!important;
    width: 500px;
    /* object-fit: cover; */
} 

@media only screen and (max-width: 1200px){
    .hubs-access-box{
        margin-top: -400px;
        margin-left: -270px;
        height: 600px!important;
        width: 600px;
    } 

}


@media only screen and (max-width: 992px){
    .hubs-access-box{
        margin-top: -200px;
        margin-left: -180px;
        height: 400px!important;
        width: 400px;
    } 
}


@media only screen and (max-width: 480px){
    .hubs-access-box{
        margin-top: 0px;
        margin-left: -190px;
        height: 450px!important;
        width: 420px;
        z-index: 9 !important;
    } 
    .hubs-access-times{
        width: 5%;
        right: 4%;
        top: 3.5%;
        z-index: 99999999 !important;
    }
}

@media only screen and (max-width: 410px){
    .hubs-access-box{
        margin-left: -180px;
        height: 750px!important;
        width: 400px;
        z-index: 9 !important;
    } 
}
@media only screen and (max-width: 370px){
    .hubs-access-box{
        margin-left: -162px;
        width: 364px;
    } 
    .hubs-access-buddy-name{
        top: 1.5%;
    }
    .hubs-access-times{
        right: 4%;
        top: 2.5%;
    }
}
@media only screen and (max-width: 340px){
    .hubs-access-box{
        margin-left: -152px;
        width: 344px;
    }
    .hubs-access-buddy-name{
        top: 1.5%;
        font-size: 18px !important;
    }
    .hubs-access-times{
        right: 5%;
        top: 2%;
    }
}