.reason-modal-box {
    position: absolute;
    padding: 10px 15px !important;
    border-radius: 50px;
    background: #fff; 
    height: 220px !important;
    margin-top: -30%;
    margin-left: -135%;
    width: 400%;
}
.reason-modal-hub-name{
    text-align: left;
    font-size: 20px;
    color: #3F1E75;
}

.reason-modal-not-going{
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 12px; 
    margin: auto;
    width: 50%;
    margin-top: 7%;
}

.reason-modal-going{
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #64ad6a;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 12px; 
    margin: auto;
    width: 50%;
    margin-top: 7%;
}

.reason-modal-hub-reason{
    font-size: 18px;
    color: #3F1E75;
}
.reason-modal-hub-reason-position{
    margin-top: 10%;
}
.reason-modal-times-frame {
    width: 30px;
    height: 30px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 6%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.reason-modal-close-icon{
    margin-top: -12px;
    width:60%;
}

@media only screen and (max-width: 422px){
    .reason-modal-box {
        height: 220px !important;
        margin-top: -100%;
        margin-left: -85%;
        width: 300%;
    }
    .reason-modal-hub-name{
        font-size: 14px;
    }
    
    .reason-modal-hub-reason{
        font-size: 12px;
    }
    .reason-modal-hub-reason-position{
        margin-top: 10%;
    }
    .reason-modal-times-frame {
        width: 25px;
        height: 25px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 6%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .reason-modal-close-icon{
        margin-top: -18px;
        width:60%;
    }
    .reason-modal-not-going{
        border-radius: 30px;
        padding: 12px 35px !important;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 12px; 
        margin: auto;
        width: 50%;
        margin-top: 12%;
    }
}