body {
    color: #fff;
    background: #fff !important;
    /* font-family: 'Roboto', sans-serif; */
}
.hub-register-form-control{
    height: 40px;
    box-shadow: none;
    color: #969fa4;
}
.hub-register-form-control:focus{
    border-color: #5cb85c;
}
.hub-register-form-control, .btn{        
    border-radius: 3px;
}
.hub-register-signup-form {
    width: 550px;
    margin: 0 auto;
    padding: 30px 0;
}
.hub-register-signup-form h2 {
    color: #3F1E75;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
/* .hub-register-signup-form h2:before, .hub-register-signup-form h2:after{
    content: "";
    height: 2px;
    width: 30%;
    background: #d4d4d4;
    position: absolute;
    top: 50%;
    z-index: 2;
}	 */
.hub-register-signup-form h2:before{
    left: 0;
}
.hub-register-signup-form h2:after{
    right: 0;
}
.hub-register-signup-form .hint-text {
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.hub-register-signup-form form {
    color: #999;
    border-radius: 12px;
    margin-bottom: 15px;
    margin-top: 30px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    padding: 15px 55px;
}
.hub-register-signup-form .form-group{
    margin-bottom: 20px;
}
.hub-register-signup-form input[type="checkbox"]{
    margin-top: 3px;
    background: #000 !important;
}
.hub-register-signup-form .btn{        
    font-size: 16px;
    font-weight: bold;		
    min-width: 140px;
    outline: none !important;
}
.hub-register-signup-form .row div:first-child{
    padding-right: 10px;
}
.hub-register-signup-form .row div:last-child{
    padding-left: 10px;
}    	
.hub-register-signup-form a{
    color: #fff;
    text-decoration: underline;
}
.hub-register-signup-form a:hover{
    text-decoration: none;
}
.hub-register-signup-form form a{
    color: #5cb85c;
    text-decoration: none;
}	
.hub-register-signup-form form a:hover{
    text-decoration: underline;
}
.hub-register-form-control {
    display: block;
    width: 100%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    text-align:center !important;
}
.hub-register-form-logo img{
    margin-top: 15px;
    margin-bottom: 25px;
}
.hub-register-checkbox-inline {
    color: #575578;
    font-size: 13px;
    margin-top: 10%;
    margin-bottom: -10%;
}
.hub-register-signup-form .btn {
    font-size: 16px;
    font-weight: bold;
    width: 60%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 14px;
    margin: auto;
    margin-top: 15%;
}

.hub-register-account-have{
    color: #707070;
}
.hub-register-signup-form form a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.hub-register-form-header{
    display: none;
}
.hubster-login-logo-size{
    width: 30%;
}
.hubst3r-login-word-size{
    width: 30%;
}



/* modal */
.authentication-password-box {
    margin-top: -115%;
    margin-left: -420%;
    width: 950%;
}
.hub-register-modal-top-text{
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.modal-password-field{
    display: block;
    width: 50%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto!important;
    margin-top: 50px!important;
    text-align:center !important;

}
.send-again-a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.send-again-a:hover{
    color: #4e2491;
    text-decoration: underline;
}
.hub-register-modal-btn-success{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px!important;
    color: white!important;
    /* margin: auto!important; */
    margin-left: 57%!important;   
}
/* end modal */


@media only screen and (max-width: 1200px) {
    .hub-register-form-color{
        /* background: rgb(144,36,103) !important;
        background: linear-gradient(342deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 100%) !important; */
        background: rgb(144,36,103) !important;
        background: linear-gradient(336deg, rgba(144,36,103,1) 85%, rgba(28,48,111,1) 99%)!important;
        position: absolute!important;
        top: 0;
        left: 0;
        width: 100%;
        height: -100px;
    }
    .hub-register-form-header{
        display: block;
        padding: 40px 20px 0px 0px;
    }
    .hub-register-signup-form form {
        color: #999;
        border-radius: 12px;
        margin-bottom: 0px !important;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        padding: 25px 55px;
        height: 610px !important;
    }
    .hub-register-signup-form {
        width: 100.4%;
        margin: -0.2%;
        padding: 0px 0;
        height: 600px;
    }
    .hub-register-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
    .hub-register-form-header {
        display: block;
        padding: 50px 0px 30px 0px;
    }
    .hub-register-form-control {
        display: block;
        width: 100%;
        height: 55px !important;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-image: none;
        border: none !important;
        border-radius: 12px !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        background-color: #fff !important;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
    }
    .hub-register-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
}

@media only screen and (max-width: 1000px) {
    .hub-register-modal-btn-success{
        width: 80%;
        margin-left: 65%!important;   
    }
}
@media only screen and (max-width: 500px) {
    .hubst3r-login-word-size{
        width: 30%!important;
        margin: auto!important;
    }
    .hub-register-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .hub-register-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .hub-register-form-header img {
        width: 16rem;
    }
}

@media only screen and (max-width: 440px) {
    .hub-register-modal-btn-success{
        font-size: 16px;
        width: 60%;
        margin-left: 75%!important;   
    }
}

@media only screen and (max-width: 385px) {
    .hub-register-modal-btn-success{
        font-size: 16px;
        width: 60%;
        margin-left: 80%!important;   
    }
    .hub-register-modal-btn-success{
        width: 60%;
        margin-left: 75%!important;   
    }
}

@media only screen and (max-width: 300px) {
    .hub-register-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 15px;
    }
    .hub-register-modal-btn-success{
        width: 60%;
        margin-left: 75%!important;   
    }
}