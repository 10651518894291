.hub-text-blue{
    color: #575578 !important;
    font-size: 70%;
    margin-left: 0.5%;
}
.hub-footer {
    position: relative;
    margin-left: -9%;
    padding-bottom: 60px !important;
    /* margin-top: -3%; */
    /* position: relative;
    bottom: 15px;
    width: 100%;
    height: 3.5rem;  */
}
.hub-link2{
    margin-left: 15px;
}


@media only screen and (max-width: 1200px){
    .hub-footer {
        /* margin-top: 100%; */
        bottom: 10%;
    }
}