.marker-map-plcae-marker-name{
    width: max-content/2;
    height: 30px;
    /* background-color: red; */
    text-align: center;
    line-height: 14px;
    margin-top: 45px;
    background-color: #f7f7f7;
    opacity: 0.8 !important;

}

.marker-map-plcae-marker-name p{
    color: #232231 !important;
    font-weight: bold;
    font-weight: bold;
    font-size: 14px !important;
}

.hyb-page-touches-invisible-box{
    width: 100%;
    height: 83%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 99999999999999999 !important;
}

.hyb-page-close-street-view-btn{
    position: absolute;
    bottom: -50px;
    left: 35%;
    width: 30%;
    height: 30px;
    background: #d60c27;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    z-index: 999999999999;
}

.suggestion-item{
    z-index: 999;
}

.hyb-page-show-more-modal{
    width: 100%;
    height: max-content;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 9999999999999999 !important;
}

.hyb-page-show-more-single-box{
    width: 90%;
    height: 50px;
    margin-left: 5%;
    background-color: #fff;
    border-radius: 30px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
}
.hyb-page-show-more-single-row{
    width:max-content;
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
    margin: auto;
    
}

.hyb-page-show-more-image{
    width: 40px;
    height: 30px;
    /* margin-top:10px */
}

.hyb-page-show-more-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit:cover;
    margin-top: -0px;
    margin-left: 0%;
}

.hyb-page-show-more-x-box{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
}

.hyb-page-story-doubleclick{
    width: 50%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 999999999999;
}

.map-sarch-top-chat-position{
    width:10%;
    height:100%;
    position: absolute;
    right: 0%;
    top: 0%;
}
.map-sarch-top-chat-img{
    width:65%;
    height: 65%;
    position: absolute;
    left: 10%;
    top: 18%;
    /* background-color: rgb(255, 0, 255); */
}

.hyb-page-story-all-black{
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: black;
    z-index: 999 !important;
}

.hyb-page-story-index{
    z-index: 9999999999999999999999 !important;
}

.story {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100px;
    height:100px;
    overflow:hidden;
  }
  .story img {
    width:70px;
    border-radius:50%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
  .story svg {
    fill:none;
    stroke:#8a3ab8;
    stroke-width:3px;
    stroke-dasharray:1;
    stroke-dashoffset:0;
    stroke-linecap:round;
    animation:loading 4500ms ease-in-out infinite alternate;
  }
  @keyframes loading {
    100% {
      stroke:#cd476b;
      stroke-dasharray:10;
      transform:rotate(200deg);
    }
  }
  

.hyb-page-all-story-section{
    width:100%;
    height: 80px;
    position:absolute;
    top:53px;
    left:0px;
    z-index: 99!important;
    margin-left:0px;
    background: linear-gradient(0deg, transparent 30%, #fff 90%);
    padding-top: 100px;
}

.hyb-page-my-story-profile-plus-img{
    position: absolute;
    bottom: 22px;
    right: 22px;
    width: 26px;
    height: 26px;
    z-index: 99999999999 !important;
}

.hyb-page-story-slider{
    width:100%;
    height:100%;
    position:absolute;
    top: 0%;
    left:0%;
    /* background-color:red; */
}

.hyb-page-my-story-box{
    width:80%;
    height: 80px;
    margin: auto;
}

.hyb-page-my-story-profile{
    width:55px;
    height: 55px;
    /* background-color:rgb(32, 129, 129); */
    margin: auto;
}

.hyb-page-my-story-profile-img{
    position: absolute;
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid  rgba(255, 255, 255, 0.8);
    z-index: 9999;
    /* animation: stroke-draw 6s ease-out infinite alternate;  */
  }

.hyb-page-my-story-spinner{
    width: 55px;
    height: 55px;
    border-top: 5.5px solid #6da9f5;
    border-right: 5.5px solid transparent;
    border-radius: 50%;
    margin: auto;
    animation: spinner 0.6s linear infinite;
    font-size: 18px;
    z-index: 999999;
}

.hyb-page-my-story-head{
    width: 5.5px;
    height: 5.5px;
    background-color: #6da9f5;
    border-radius: 50%;
    margin-left: 46.75px;
    margin-top: 2.75px;
  }
  @keyframes spinner {
    100% { transform: rotate(360deg) }
  }

.hyb-page-story-username-text{
    font-size: 0.65rem;
    color: rgb(32, 32, 32);
    text-align: center;
    width: 100%;
    margin: auto;
}

.hyb-page-story-times-box{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    right: 0px;
    z-index:99999999999999999999999999 !important;
    /* background-color: crimson; */
}

.hyb-page-story-times-img{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 12px;
    z-index:999999999999 !important;
}

.hyb-page-story-reactions-xo{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 7px;
    bottom: 10px;
    max-width: 12%;
    position: fixed !important;
    z-index: 99999999;
}

.hyb-page-story-reactions-img{
    width: 100%;
    height: 100%;
    z-index: 99999999999999999999!important;
}

.hyb-page-story-swipe-up{
    position: absolute;
    width: 70%;
    height: 70px;
    left: 15%;
    bottom: 0%;
    z-index: 9999999999999999999;
    color: white;
    text-align: center;
    letter-spacing: 0.1em;
    /* margin-bottom: 1.2vh; */
    text-transform: capitalize;
    opacity: 1;
    font-size: 0.8em;
    transition: opacity 300ms ease-in-out;
}

.hyb-page-story-bottom-row{
    position: absolute;
    width: 20%;
    height: 10%;
    right: 0%;
    bottom: 0%;
    z-index: 99999999999999999999 !important;
}

.hyb-page-story-top-text{
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 30px;
    left: 90px;
    z-index: 99;
}

.hyb-page-story-top-heading{
    font-size: 0.7rem !important;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    margin-bottom: 2;
}

.hyb-page-story-top-heading-tags{
    font-size: 0.6rem !important;
    color: rgba(255, 255, 255, 0.9);
}

.hyb-page-story-top-subheading1{
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 2;
}

.hyb-page-story-top-subheading{
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}

.hyb-page-story-profile-pic-box{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 29px;
    left: 20px;
    /* background-color: crimson; */
    z-index: 999999999999999!important;
}

.hyb-page-story-profile-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid  rgba(255, 255, 255, 0.8)
    
/* background: rgb(93,93,93);
background: radial-gradient(circle, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.hyb-page-story-photo{
    position: absolute;
    width: 100%;
    height:100%;   
    left: 0%;
    top:0%;
    /* background: rgb(93,93,93);
    background: linear-gradient(0deg, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.hyb-page-story-photo-size{
    position: absolute;
    width: 100%;
    height:100% !important;
    object-fit: contain;
}


.hub-page-appointment-modal-times{
    position: absolute;
    width: 5%;
    right: 6%;
    top: 1%;
    z-index: 99999999 !important;
}

.hub-page-all-contacts{
    width: 100%;
    height: 40px;
    /* background-color: red; */
    position: absolute;
    top: -10px;
    left: 20px;
}
.hub-page-contacts-box{
    margin: auto;
    width: 25%;
    height: 100%;
    /* background-color: blue; */
}
.hub-page-contacts-img-box{
    margin: auto;
    width: 25px !important;
    height: 25px !important;
    margin-top: 10px;
    /* background-color: coral; */
}
.hub-page-contacts-img{
    margin: auto;
    width: 100% !important;
    height: 100% !important;
}

.hub-page-hub-category-phone{
    color: #575578;
    font-size: 13px !important;
    text-align: center;   
}

.hub-page-hub-category-website{
    color: #575578;
    font-size: 13px !important;
    text-align: center;      
}

.hub-page-hub-category-info-text{
    color: #575578;
    font-size: 12px !important;
    text-align: center;    
    margin: auto;  
    font-weight:normal;
}

.hub-page-memories-title{
    color: #575578;
    font-size: 130% !important;
    text-align: center; 
    margin-bottom: 4px;    
}
.hub-page-post-memory{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 300px;
    right: 3%;
}

.hub-memory-img-all-img{
    position: absolute;
    width: 100%;
    /* top: 0-2%; */
}

.hub-memory-img-all-img:after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.hub-memory-img-size-box{
    position: relative;
    width: 50%;
    border: solid 1px rgb(216, 215, 215);
}

.hub-memory-img-size-box:after{
    content: "";
    display: block;
    padding-bottom: 0%;
}

.hub-memory-img-size-full{
    position: relative;
    top: 0%;
    height: 100%;
    width: 100%;
    /* border-radius: 2px;  */
    object-fit: cover;
}


.back-button-hub-page-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.hub-page-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}
.text-blue-empty-hub-memories{
    color: #575578;
    font-size: 130% !important;
    text-align: center; 
}

.hub-page-no-memories{
    margin: auto;
}
.hub-page-no-memories-image{
    width: 30%;
    margin: auto;
}
.hub-page-no-memories-img{
    width: 100%;
}

.hub-page-no-memories-img-ivisible{
    width: 100%;
    opacity: 0%;
}

.hub-page-memory-text h2{
    color: #575578;
    padding: 25px 0px;
    margin-left: 10%;
    text-align: center;    
}

.hub-page-unclaimed-text{
    color: #575578;
    text-align: center; 
    margin: auto;
    margin-top: 5%; 
    text-decoration: underline;
}

.hub-page-wifi-round-button-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}
.hub-page-wifi-position{
    position: absolute;
    top: 2%;
    right: 3%;
    z-index: 9999999;
}
.hub-page-wifi-round-button{
    /* margin: 36px; */
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    /* line-height: 95px; */
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    margin-bottom: 23px;
}
.hub-page-wifi-icon{
    width: 20px;
    height:20px;
    margin:auto;
    margin-top: 10px;
}
.hub-page-wifi-text{
    /* color: rgb(87, 85, 120); */
    color: #fff;
    font-weight: bolder;
    font-size: 12px;
    margin-top: -15px;
}


/* Global css start */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav-bottom{
    display: none;
}
/* Global css end */

/* loading */
.hub-page-section-disappear{
    display: none;
}
.hub-page-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.hub-page-lottie-dissappear{
    display: none;
}
/* end loading */

/* Body left css start */
.hub-page-body-left{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
 
.hub-memory-img-size{
    height: 280px;
    width: 280px;
    /* margin-left: 15px; */
    margin-bottom: 20px;
    border-radius: 10px;
    margin-left: -20px;
    position: relative;
    /* object-fit: cover; */
    object-fit: contain;
    z-index:1;
}
.hub-memory-img-size-cover{
    height: 280px;
    width: 280px; 
    /* margin-left: 15px; */
    /* margin-bottom: 20px;
    border-radius: 10px;
    margin-left: -20px; */
    left: 5px;
    top:5px;
    position: absolute;
    /* object-fit: cover; */
    object-fit: fill!important;
    filter: blur(20px);
    -webkit-filter: blur(20px);
}

.logo{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.left-text{
    font-size: 15px;
}
.left-icon img{
    width: 45%;
}
.left-home{
    cursor: pointer;
}
.left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */


/* Body Right css start */
.body-right{
    margin-left: 16.6%;
    background: #fff;
}
.starbucks-item{
    width: 100% !important;
    margin: auto;
    margin-left: 12%;
    margin-top: 2%;
}
.hub-page-all-memories{
    margin-left: 12%;
    width: 100%;
}

.all-hub-page-info {
    border-radius: 0px 0px 22px 22px !important;
    width: 96.3% !important;
    margin: auto;
    background: #fff;
    padding-bottom: 20px;
    margin: auto !important;
}
.starbucks-item-img{
    width: 100% !important;
    max-width: 1000px;
    max-height: 200px!important;
    border-radius: 0px 0px 0px 0px !important;
    margin: auto !important;
    object-fit: cover;
}



.hub-cover-photo-size{
    height: 100px;
}
.starbucks-item1{
    background-color: #fff; 
}

.item{
    cursor: pointer;
    margin-left: 10%;
}

.hub-page-round-buttons{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 44px;
    height: 44px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
}
.all-items-hub-page{
    margin-left: 0%;
}
.hub-page-information-item{
    margin-left: 15%;
    cursor: pointer;   
}
.hub-page-all-titles{
    /* margin-left: 12%; */
    margin-bottom: 15px;
    color: #575578;
}
.hub-page-information-title-1{
    margin-left: 12.5% !important;
}
.hub-page-information-title-2{
    margin-left: 10%!important;
}
.hub-page-information-title-3{
    margin-left: 10%!important;
}
.hub-page-information-title-4{
    margin-left: 13.5%!important;
}


.hub-page-information-img{
    width: 18px !important;
    height: 18px !important;
    position: absolute;
    top:13px;
    left: 13.5px;
    /* margin-top: -105px; */
}
.item img{
    width: 22px;
    height: 22px;
    margin-top: -4px;
    margin-right: 3px;
}
.item-img img{
    width: 17px;
}
.item strong{
    color: #575578;
    margin-left: 2%;
}
.row.all-item {
    border-radius: 0px 0px 22px 22px !important;
    width: 100%;
    margin: auto;
    background: #fff;
    padding-bottom: 20px;
}
.hub-page-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    border-radius: 30px !important;
    color: #ffffff !important;
    /* font-size: 10px !important; */
    margin-left: -30px;
    width: 110%;
}
.btn-success-hub-page{
    background: #fff !important;
    border: none !important;
    padding: 11px 45px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
}
.item-div.item-div2 {
    margin-left: -20px;
}
.memory-text, .friends-img{
    /* height: 80% !important; */
    /* margin-top: -300px; */
    width: 80% !important;
    margin: auto;
}
.memory-text h2{
    color: #575578;
    padding: 15px 0px;
    margin-left: 5%;
}
/* .friends-img-item img{
    border-radius: 25px;
    position: relative;
} */
/* .hub-page-top-memory img {
    width: 50px;
    position: relative;
    top: -310px;
    left: 15px;
} */
.hub-page-all-informations{
    margin: auto;
    margin-top: 20px !important;
    margin-bottom: 20px;
}
.item-div{
    margin-left: 30px;
    width: 100%;
}
.hub-page-top-memory img {
    border-radius: 10px!important;
    width: 50px;
    position: absolute;
    top: 1%;
    left: 72%;
    z-index: 999999 !important;
}
button.btn.btn-danger.hub-page-arrange-btn {
    width: 190px;
}
.hour-btn{
    background: #fff !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    color: #575578 !important;
    font-weight: bold !important;
    margin-left: 0px;
}
a.btn.btn-success-hub-page {
    width: 200px;
}
.bg-linear{
    display: none !important;
}
.starbucks img {
    position: absolute;
    top: 15%;
    left: 0;
    border-radius: 50%;
    border: 10px solid #fff;
    right: 0;
    width: 80px;
    height: 80px;
    margin-left: 61%;
    z-index: 999;
    transform: translateX(-50%);
    object-fit: cover !important;
}
.starbucks-item1 img{
    position: relative;
    padding-top: 60px;
}
button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
    width: 178px;
    margin-left: 0px !important;
    padding: 12px 19px !important;
}
.hub-page-arrange-btn{
    margin-top: 8px;
}
/* Body Right css end */

/* Footer css start */
a.btn.btn-danger.feedback-btn {
    padding: 12px 80px !important;
}
.terms{
    margin-right: 10px;
}
.links{
    margin: 10px 0px 20px 0px;
}
.footer{
    padding-bottom: 80px;
}
/* Footer css end */



/* working hours modal */
.hub-page-click-box-6 {
    margin-top: -320%;
    margin-left: -250%;
    width: 750%;
    height: 750%!important;
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
}
.working-day-door{
    margin-top: -1%;
    width: 5%;
}
.modal.fade.working-hours-mode.show {
    background: rgba(8, 8, 8, 0.9);
}
.modal-content {
    position: relative;
    /* display: -ms-flexbox; */
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    /* background-color: #fff; */
    background-color: rgba(8, 8, 8, 0.7) !important;
    background-clip: padding-box;
    /* border: 1px solid rgba(0,0,0,.2); */
    border: rgba(8, 8, 8, 0.7);
    border-radius: .3rem;
    outline: 0;
    /* margin-top: 50%; */
    transform: translateY(-50%);
}
.modal-content img{
    width: 100%;
    height: 100%;
    margin: auto;
}
.hub-page-modal-content {
    border-radius: 40px;
    width: 100%;
}
.hub-page-days-mon{
    margin-top: -72.5%;
    margin-left: 25%;
}
.hub-page-days-mon span{
    font-size: 30px;
    font-weight: bold;
}
.hub-page-days-tue {
    margin-top: 1%;
    margin-left: 25%;  
}
.hub-page-days-tue span{
    font-size: 30px;
    font-weight: bold;
}
.b-hours{
    margin-top: -74%;
}
/* end modal */
/* Responsive css start */
@media only screen and (max-width: 1200px){
    .hub-page-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }

    .hub-page-memory-text h2{
        padding: 35px 0px;
        margin-left: 5%;    
    }
    .hub-page-top-memory img {
        width: 50px;
        left: 65%;
        border-radius: 50%;
    }
    .hub-page-total-page-layout{
        position: absolute !important;
        top: 0px !important;
        left: 0px !important;
        width: 100%;
        height: 100%;
    }
    .starbucks-item{
        width: 80% !important;
        margin: auto;
        margin-top: 35px;
    }
    .hub-page-all-memories{
        margin-left: 6%;
        width: initial;
    }
    .starbucks img {
        width: 130px;
        height: 130px;
        margin-left: 51%;
    }
    .hub-page-body-left{
        display: none;
    }
    .body-right{
        margin-left: initial;
    }
    .hub-page-nav-color{
        background: rgb(144,36,103);
        background: linear-gradient(336deg, rgba(144,36,103,1) 87%, rgba(28,48,111,1) 99%);
        width: 100% !important;
        height: 100% !important;
    }
    .bg-linear{
        width: 100%;
        height: 10%;
        display: block !important;
    }
    .small-header{
        padding-top: 10px;
    }
    .small-header h1{
        margin-left:10%;
        color: #fff;
        font-weight: bold;
    }
    .col-xl-10.body-right {
        border-radius: 0px 0px 0px 0px;
        margin-top: 50px;
    }
    .hub-page-bg-blue{
        height: 200px!important;
        margin-bottom: 400px;
    }
    .nav-bottom{
        display: block;
    }

    .hub-page-click-box-6 {
        margin-top: -320%;
        margin-left: -320%;
        width: 750%;
        height: 750%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-map-box{
        position: absolute;
        width: 100%;
        height: 120px;
        top: 170px;
        left: 0%;
    }

    .hub-page-proile-all-btns{
        position: absolute;
        width: 100%;
        height: 35px;
        top: 85px;
        left: 0%;
        /* background-color:red; */
        margin-left: 0%;
    }
    .hub-page-proile-btn-resevaion{
        width: max-content;
        padding-left: 30px;
        padding-right: 30px;
        background: #1183ca !important;
        border: none !important;
        border-radius: 4px !important;
        color: #fff !important;
        text-align: center;
        height: 100%;
        margin: auto;
        line-height: 35px;
    }

    .hub-page-proile-btn-shop-online{
        width: max-content;
        padding-left: 30px;
        padding-right: 30px;
        border: none !important;
        border-radius: 4px !important;
        color: #fff !important;
        text-align: center;
        height: 100%;
        margin: auto;
        line-height: 35px;
        background-color: green;
        font-weight: bold;
    }

    .hub-page-proile-btn-no-reservations{
        width: max-content;
        text-align: center;
        height: 100%;
        margin: auto;
        line-height: 35px;
        font-size:18px;
        font-weight: bold;
        color: #232231;
    }

    .hub-page-proile-all-btns2{
        position: absolute;
        width: 100%;
        height: 35px;
        top: 130px;
        left: 0%;
        /* background-color:rgb(100, 74, 74); */
        margin-left: 0%;
    }

    .hub-page-proile-btn-catalof{
        padding: 5px 5px !important;
        border-radius: 4px !important;
        text-align: center;
        width: 40%;
        height: 100%;
        /* width: 80%; */
        /* background: #fff !important; */
        border: 0.5px solid rgb(226, 226, 226) !important;
        /* color: rgb(59, 59, 59) !important; */
        color: #fff !important;
        border-radius: 2px;
        /* -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3); */
        margin: auto;
        background: rgb(189,195,199);
        background: radial-gradient(circle, rgb(144, 148, 151) 0%, rgb(52, 66, 80) 100%);
    }

    .hub-page-proile-btn-rder-nline{
        width: 40%;
        height: 100%;
        background-color: #ffd600;
        line-height: 30px;
        text-align: center;
        margin: auto;
        color:black;
        border-radius: 4px;
        font-size:13px;
        padding: 5px 5px !important;
        width: 40%;
        height: 100%;
        line-height: 25px;
    }

    .starbucks-item-img{
        /* position: absolute;
        top: 0%;
        left: 0%; */
        width: 100% !important;
        height: 100%;
        object-fit: cover;
    }

    .hub-page-information-item{
        margin-left: 15%;
        cursor: pointer;
    }
}
@media only screen and (max-width: 1040px){
    .hub-page-top-memory img {
        width: 50px;
        left: 69%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 992px){
    a.btn.btn-success-hub-page {
        width: 160px;
    }
    .hub-memory-img-size{
        height: 250px;
        width: 250px;
        margin-bottom: 20px;
        margin-left: -20px;
    }
    .hub-memory-img-size-cover{    
        height: 250px;
        width: 250px;
        left: -2px;
        top:5px;
    }
 
    .btn-success-hub-page {
        padding: 11px 15px !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 155px;
        margin-left: -15px !important;
    }
    .hub-page-btn-danger {
        padding: 11px 18px !important;
        width: 140%;
        margin-left: -40px;
    }
    a.btn.btn-danger.btn-feedback {
        width: 320px;
    }
    strong {
        font-size: 14px;
    }
    /* .item {
        margin: 15px 0px;
        width: 220px !important;
    } */
    .middle-icon-footer img {
        position: absolute;
        top: -48px !important;
        width: 92px !important;
        left: 30px;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 160px;
        margin-left: 0px !important;
        padding: 11px 19px !important;
    }
    .hour-btn{
        margin-left: 10px;
        font-size: 14px !important;
        height: 45px;
    }
    /* .starbucks-item-img{
        width: 100% !important;
        max-width: 732px;
        max-height: 300px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
    } */
    .hub-page-all-informations{
        margin-top: 20px !important;
        margin-bottom: 20px;
    }
    .hub-page-information-title-1{
        margin-left: 13%!important;;
    }
    .hub-page-information-title-2{
        margin-left: 10%!important;
    }
    .hub-page-information-title-3{
        margin-left: 12%!important;
    }
    .hub-page-information-title-4{
        margin-left: 15%!important;
    }
    .item-div{
        margin-left: 18%;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: 5%;
    }
    .hub-page-top-memory img {
        width: 40px;
        left: 65%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 920px){
    .hub-page-top-memory img {
        width: 40px;
        left: 71%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 852px){
    .item-div{
        margin-left: 14%;
    }
    .hub-page-information-item{
        margin-left: 14%;
    }
    .hub-page-information-title-1{
        margin-left: 10%!important;;
    }
    .hub-page-information-title-2{
        margin-left: 10%!important;
    }
    .hub-page-information-title-3{
        margin-left: 10%!important;
    }
    .hub-page-information-title-4{
        margin-left: 13%!important;
    }
    .hub-page-top-memory img {
        width: 40px;
        left: 77%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 831px){
    .hub-memory-img-size{
        height: 210px;
        width: 210px;
        margin-bottom: 20px;
        margin-left: -10px;
    }
    .hub-memory-img-size-cover{    
        height: 210px;
        width: 210px;
        left: 5px;
        top:5px;
    }
    .hub-page-top-memory img {
        width: 30px;
        left: 70%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 768px){
    .small-header h1 {
        font-size: 38px;
    }
    .hub-memory-img-size{
        height: 210px;
        width: 210px;
        margin-bottom: 20px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 210px;
        width: 210px;
        left: -8px;
        top:5px;
    }
    .hub-page-btn-danger {
        padding: 9px 18px !important;
        border-radius: 30px !important;
        margin-left: -90px;
        width: 180%;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 150px;
        margin-left: -18px !important;
    }
    .btn-success-hub-page {
        padding: 9px 15px !important;
    }
    a.btn.btn-success-hub-page {
        width: 150px;
    }
    .hour-btn{
        margin-left: 10px;
        font-size: 13px !important;
        width: 200%;
        height: 40px;
    }
    .hub-page-click-box-6 {
        margin-top: -320%;
        margin-left: -275%;
        width: 650%;
        height: 650%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-days-mon{
        margin-top: -72.5%;
        margin-left: 25%;
    }
    .hub-page-days-mon span{
        font-size: 25px;
        font-weight: bold;
    }
    .hub-page-days-tue {
        margin-top: 1%;
        margin-left: 25%;  
    }
    .hub-page-days-tue span{
        font-size: 25px;
        font-weight: bold;
    }
    /* .starbucks-item-img{
        width: 100% !important;
        max-width: 566px !important;
        max-height: 283px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
    } */
    .hub-page-information-item{
        margin-left: 12.5%;
    }
    .hub-page-all-titles strong{
        font-size: 13px !important;
    }
    .hub-page-information-title-1{
        margin-left: 10%!important;
    }
    .hub-page-information-title-2{
        margin-left: 8%!important;
    }
    .hub-page-information-title-3{
        margin-left: 9%!important;
    }
    .hub-page-information-title-4{
        margin-left: 12.5%!important;
    }
    .hub-page-top-memory img {
        width: 30px;
        left: 70%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 700px){
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 150px;
        margin-left: -50px !important;
    }
    .hub-memory-img-size{
        height: 200px;
        width: 200px;
        margin-bottom: 20px;
        margin-left: -30px;
    }
    .hub-memory-img-size-cover{    
        height: 200px;
        width: 200px;
        left: -15px;
        top:5px;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 160px;
        margin-left: 0px !important;
        padding: 10px 19px !important;
    }
    /* .starbucks-item-img{
        width: 100% !important;
        max-width: 566px !important;
        max-height: 283px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
    } */
    .hub-page-information-item{
        margin-left: 12%;
    }
    .hub-page-all-titles strong{
        font-size: 12px !important;
    }
    .hub-page-top-memory img {
        width: 30px;
        left: 70%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 645px){
    .hub-memory-img-size{
        height: 190px;
        width: 190px;
        margin-bottom: 20px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 190px;
        width: 190px;
        left: -10px;
        top:0px;
    }
    .hub-page-top-memory img {
        width: 30px;
        left: 73%;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 612px){
    .hub-page-memory-text h2{
        padding: 50px 0px;
        margin-left: 2%;   
        font-size: 20px; 
    }
    .hub-page-click-box-6 {
        margin-top: -320%;
        margin-left: -215%;
        width: 550%;
        height: 550%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-days-mon{
        margin-top: -72.8%;
        margin-left: 25%;
    }
    .hub-page-days-mon span{
        font-size: 20px;
        font-weight: bold;
    }
    .hub-page-days-tue {
        margin-top: 1.2%;
        margin-left: 25%;  
    }
    .hub-page-days-tue span{
        font-size: 20px;
        font-weight: bold;
    }
    .item-div{
        margin-left: 5%;
    }
    .hub-page-information-item{
        margin-left: 10.5%;
    }
    .hub-page-all-titles strong{
        font-size: 12px !important;
    }
    .hub-page-information-title-1{
        margin-left: 7%!important;
    }
    .hub-page-information-title-2{
        margin-left: 7%!important;
    }
    .hub-page-information-title-3{
        margin-left: 7%!important;
    }
    .hub-page-information-title-4{
        margin-left: 10%!important;
    }
    .hub-memory-img-size{
        height: 180px;
        width: 180px;
        margin-bottom: 5px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 180px;
        width: 180px;
        left: -10px;
        top:0px;
    } 
}
@media only screen and (max-width: 576px){
    .hub-page-hub-category-box{
        position: absolute;
        left: 35%;
        top:10px;
        width: 60%;
        height: 20%;
    }

    .hub-page-hub-category-text{
        color: #575578;
        text-align: center;
        font-size: 13px;
        /* font-weight: bold; */
        position: relative;
        top: 30px;
        margin: auto;
    }

    .hub-page-no-reservation-text{
        color: #575578;
        text-align: center;
        font-size: 13px;
        margin-top: 0px;
    }

    .hub-page-hub-category-btns{
        width: 100%;
        height: 35px;
        position: absolute;
        top:30px;
    }
    .hub-page-hub-category-btns-row{
        width: 100%;
        margin: auto;
    }

    .hub-page-hub-category-btn{
        background: #1183ca !important;
        border: none !important;
        padding: 5px 5px !important;
        border-radius: 2px !important;
        color: #fff !important;
        text-align: center;
        height: 100%;
        margin: auto;
        width: 50%;
    }

    .hub-page-hub-category-btn:active {
        transform: scale(0.88);
    }
    
    .hub-page-hub-category-btn:focus {
        transform: scale(0.88);
    }
    
    .hub-page-hub-category-btn:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .hub-page-hub-menu-btn{
        padding: 5px 5px !important;
        border-radius: 2px !important;
        text-align: center;
        /* -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); */
        width: 50%;
        height: 100%;
        /* width: 80%; */
        background: #fff !important;
        border: none !important;
        color: #fff !important;
        border-radius: 2px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
        margin: auto;
        

        /* position: absolute; */
        /* left: 51%; */
    }

    .hub-page-hub-menu-btn:active {
        transform: scale(0.88);
    }
    
    .hub-page-hub-menu-btn:focus {
        transform: scale(0.88);
    }
    
    .hub-page-hub-menu-btn:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .hub-page-hub-menu-btn-full{
        padding: 5px 5px !important;
        border-radius: 4px !important;
        text-align: center;
        /* -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); */
        width: 70%;
        margin: auto;
        /* width: 80%; */
        background: #fff !important;
        border: none !important;
        color: rgb(59, 59, 59) !important;
        border-radius: 2px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
        /* position: absolute; */
        left: 15%; 
        margin-top:5px;
    }

    .hub-page-hub-menu-btn-full:active {
        transform: scale(0.88);
    }
    
    .hub-page-hub-menu-btn-full:focus {
        transform: scale(0.88);
    }
    
    .hub-page-hub-menu-btn-full:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .starbucks-item1 {
        border-radius: 40px;
    }
    .item {
        width: initial !important;
    }
    .hide-element{
        display: none;
    }
    .item img {
        width: 18px;
    }
    .item.item-img img {
        width: 15px !important;
    }
    .item {
        width: 170px !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 130px;
        margin-left: -18px !important;
        font-size: 14px;
    }
    a.btn.btn-success-hub-page {
        width: 140px;
        font-size: 14px;
    }
    .col-xl-4.col-lg-4.col-md-4.col-sm-4.col-4.friends-img-item {
        padding: 5px;
    }
    .col-xl-4.col-lg-4.col-md-4.col-sm-4.col-4.friends-img-item.my-4 {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    .friends-img-item img {
        border-radius: 15px;
        position: relative;
    }
    .memory-text h2 {
        font-size: 28px;
    }
    .footer-icon img {
        width: 30%;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -45px;
        max-width: 75px !important;
        left: 15px !important;
    }
    .footer-icon img {
        width: 35% !important;
    }
    .starbucks img {
        position: absolute;
        top: 0%;
        left: -40% !important;
        width: 80px;
        height: 80px;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 142px;
        margin-left: 0px !important;
        padding: 10px 19px !important;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -32px !important;
        width: 92px !important;
        left: 30px;
    }
    .hub-page-btn-danger {
        padding: 9px 18px !important;
        border-radius: 30px !important;
        margin-left: 0px;
        width: 110%;
    }
    .hub-page-click-box-6 {
        margin-top: -320%;
        margin-left: -165%;
        width: 450%;
        height: 440%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-days-mon{
        margin-top: -72.8%;
        margin-left: 25%;
    }
    .hub-page-days-mon span{
        font-size: 15px;
        font-weight: bold;
    }
    .hub-page-days-tue {
        margin-top: 1.3%;
        margin-left: 25%;  
    }
    .hub-page-days-tue span{
        font-size: 15px;
        font-weight: bold;
    }
    /* .starbucks-item-img{
        width: 103.5% !important;
        max-width: 390px !important;
        max-height: 195px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -1.5% !important;
    } */
    .item-div{
        margin-left: 0%;
        width: 100% !important;
    }
    .item{
        cursor: pointer;
        margin-left: 3%;
    }
    .hub-memory-img-size{
        height: 170px;
        width: 170px;
        margin-bottom: 5px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 170px;
        width: 170px;
        left: -10px;
        top:15px;
    }
    .hub-page-top-memory img {
        width: 30px;
        left: 71%;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 540px){
    .item-div{
        margin-left: 30%;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -30%;
    }
    .item strong{
        font-size: 10px;
    }
    .all-items-hub-page{
        margin-left: -1%;
    }
    .hub-page-information-item{
        margin-left: 10%;
    }
    .hub-page-all-titles strong{
        font-size: 11px !important;
    }
    .hub-page-information-title-1{
        margin-left: 6%!important;
    }
    .hub-page-information-title-2{
        margin-left: 7%!important;
    }
    .hub-page-information-title-3{
        margin-left: 7%!important;
    }
    .hub-page-information-title-4{
        margin-left: 10%!important;
    }
    .hub-memory-img-size{
        height: 160px;
        width: 160px;
        margin-bottom: 5px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 160px;
        width: 160px;
        left: -10px;
        top:0px;
    }
    .hub-page-top-memory img {
        width: 28px;
        left: 71%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 510px){
    .hub-memory-img-size{
        height: 150px;
        width: 150px;
        margin-bottom: 5px;
        margin-left: -25px;
    }
    .hub-memory-img-size-cover{    
        height: 150px;
        width: 150px;
        left: -10px;
        top:0px;
    }
    .hour-btn{
        margin-left: 0px;
        font-size: 11px !important;
        width: 130%;
        height: 41px;
    }
    .item-div{
        margin-left: 20%;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -34%;
    }
    .item strong{
        font-size: 9px;
    }
    .all-items-hub-page{
        margin-left: -1%;
    }
    .hub-page-information-item{
        margin-left: 9%;
    }
    .hub-page-all-titles strong{
        font-size: 11px !important;
    }
    .hub-page-information-title-1{
        margin-left: 4%!important;
    }
    .hub-page-information-title-2{
        margin-left: 7%!important;
    }
    .hub-page-information-title-3{
        margin-left: 7%!important;
    }
    .hub-page-information-title-4{
        margin-left: 8%!important;
    }
}
@media only screen and (max-width: 482px){
    .small-header h1 {
        font-size: 25px;
    }
    .hub-memory-img-size{
        height: 140px;
        width: 140px;
        margin-bottom: 5px;
        margin-left: -23px;
    }
    .hub-memory-img-size-cover{    
        height: 140px;
        width: 140px;
        left: -9px;
        top:0px;
    }
    .hub-page-top-memory img {
        width: 26px;
        left: 70%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 450px){
    .hub-page-no-memories-image{
        width: 20%;
        margin: auto;
        margin-top: 15%;
    }

    .text-blue-empty-hub-memories{
        color: #575578;
        font-size: 14px !important;
        text-align: center; 
    }
    .text-blue-share-hub-memories{
        color: #575578;
        font-size: 15px !important;
        text-align: center;   
    }
    
    .hub-memory-img-size{
        height: 130px;
        width: 130px;
        margin-bottom: 5px;
        margin-left: -23px;
    }
    .hub-memory-img-size-cover{    
        height: 130px;
        width: 130px;
        left: -8px;
        top:0px;
    }
    .hub-page-btn-danger {
        margin-left: 0px;
        width: 110%;
        font-size: 13px !important;
    }
    .hour-btn{
        margin-left: -4px;
        font-size: 10px !important;
        width: 130%;
        height: 37px;
    }
    .hub-page-click-box-6 {
        margin-top: -150%;
        margin-left: -120%;
        width: 350%;
        height: 330%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-days-mon{
        margin-top: -74.5%;
        margin-left: 25%;
    }
    .hub-page-days-mon span{
        font-size: 10px;
        font-weight: bold;
    }
    .hub-page-days-tue {
        margin-top: -0.6%;
        margin-left: 25%;  
    }
    .hub-page-days-tue span{
        font-size: 10px;
        font-weight: bold;
    }
    /* .starbucks-item-img{
        width: 105.5% !important;
        max-width: 318px !important;
        max-height: 159px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -2.5% !important;
    } */
    .item-div{
        margin-left: 25% !important;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -40%;
    }
    .item strong{
        font-size: 9px;
    }
    .all-items-hub-page{
        margin-left: 0%;
    }
    .all-items-hub-page{
        margin-left: -3%;
    }
    .hub-page-information-item{
        margin-left: 8%;
    }
    .hub-page-all-titles strong{
        font-size: 11px !important;
    }
    .hub-page-information-title-1{
        margin-left: 2%!important;
    }
    .hub-page-information-title-2{
        margin-left: 4%!important;
    }
    .hub-page-information-title-3{
        margin-left: 4%!important;
    }
    .hub-page-information-title-4{
        margin-left: 9%!important;
    }
    .hub-page-top-memory img {
        width: 24px;
        left: 68%;
        border-radius: 50%;
        z-index: 999999 !important;
    }
}
@media only screen and (max-width: 416px){
    .hub-memory-img-size{
        height: 125px;
        width: 125px;
        margin-bottom: 5px;
        margin-left: -23px;
    }
    .hub-memory-img-size-cover{    
        height: 125px;
        width: 125px;
        left: -8px;
        top:0px;
    }
    a.btn.btn-success-hub-page {
        width: 125px;
        font-size: 14px;
        margin-left: -20px !important;
    }
    strong {
        font-size: 12px;
    }
    .item-div {
        margin-left: 0px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 122px !important;
        margin-left: -35px !important;
    }
    .footer-icon img {
        width: 35% !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 142px;
        margin-left: -12px !important;
        padding: 9px 2px !important;
    }
    .hub-page-btn-danger {
        margin-left: 0px;
        width: 120%;
        margin-left: -20px;
        font-size: 12px !important;
    }
    .hour-btn{
        margin-left: -13px;
        font-size: 9px !important;
        width: 135%;
        height: 35px;
    }
    /* .starbucks-item-img{
        width: 106.5% !important;
        max-width: 294px !important;
        max-height: 147px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -3% !important;
    } */
    .hub-page-top-memory img {
        width: 24px;
        left: 69%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 407px){
    .hub-memory-img-size{
        height: 120px;
        width: 120px;
        margin-bottom: 5px;
        margin-left: -23px;
    }
    .hub-memory-img-size-cover{    
        height: 120px;
        width: 120px;
        left: -8px;
        top:0px;
    }
}
@media only screen and (max-width: 385px){
    .hub-page-post-memory{
        width: 25px;
        height: 25px;
    }
    .hour-btn{
        margin-left: -13px;
        font-size: 8px !important;
        width: 145%;
        height: 35px;
    }
    /* .starbucks-item-img{
        width: 106.5% !important;
        max-width: 262px !important;
        max-height: 131px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -3% !important;
    } */
    .item-div{
        margin-left: 33% !important;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -50%;
    }
    .item strong{
        font-size: 8px;
    }
    .all-items-hub-page{
        margin-left: 0%;
    }
    .all-items-hub-page{
        margin-left: -9%;
    }
    .hub-page-information-item{
        margin-left: 7.5%;
    }
    .hub-page-all-titles strong{
        font-size: 10px !important;
    }
    .hub-page-information-title-1{
        margin-left: -3%!important;
    }
    .hub-page-information-title-2{
        margin-left: 5%!important;
    }
    .hub-page-information-title-3{
        margin-left: 5.5%!important;
    }
    .hub-page-information-title-4{
        margin-left: 12%!important;
    }
    .hub-memory-img-size{
        height: 110px;
        width: 110px;
        margin-bottom: 5px;
        margin-left: -20px;
    }
    .hub-memory-img-size-cover{    
        height: 110px;
        width: 110px;
        left: -6px;
        top:0px;
    }
}
@media only screen and (max-width: 365px){
    .hub-page-post-memory{
        top: 290px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        margin-left: -30px !important;
    }

    a.btn.btn-success-hub-page {
        width: 125px;
        font-size: 14px;
        margin-left: -25px !important;
    }
    strong {
        font-size: 11px;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -48px !important;
        width: 92px !important;
        left: 30px;
        margin-left: -15px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 110px !important;
        margin-left: -60px !important;
    }
    .hub-page-click-box-6 {
        margin-top: -100%;
        margin-left: -100%;
        width: 300%;
        height: 280%!important;
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff;
    }
    .hub-page-days-mon{
        margin-top: -75.5%;
        margin-left: 25%;
    }
    .hub-page-days-mon span{
        font-size: 7px;
        font-weight: bold;
    }
    .hub-page-days-tue {
        margin-top: -2.4%;
        margin-left: 25%;  
    }
    .hub-page-days-tue span{
        font-size: 7px;
        font-weight: bold;
    }
    /* .starbucks-item-img{
        width: 108.3% !important;
        max-width: 254px !important;
        max-height: 127px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -4% !important;
    } */
    .item-div{
        margin-left: 33% !important;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -53%;
    }
    .item strong{
        font-size: 8px;
    }
    .all-items-hub-page{
        margin-left: -7%;
    }
    .hub-page-information-item{
        margin-left: 6%;
    }
    .hub-page-all-titles strong{
        font-size: 9px !important;
    }
    .hub-page-information-title-1{
        margin-left: -3%!important;
    }
    .hub-page-information-title-2{
        margin-left: 6%!important;
    }
    .hub-page-information-title-3{
        margin-left: 6%!important;
    }
    .hub-page-information-title-4{
        margin-left: 12%!important;
    }
    .hub-page-top-memory img {
        width: 22px;
        left: 71%;
        border-radius: 50%;
    }
}
@media only screen and (max-width: 350px){
    .hub-memory-img-size{
        height: 105px;
        width: 105px;
        margin-bottom: 5px;
        margin-left: -20px;
    }
    .hub-memory-img-size-cover{    
        height: 105px;
        width: 105px;
        left: -3px;
        top:0px;
    }
}
@media only screen and (max-width: 340px){
    .hub-page-post-memory{
        width: 25px;
        height: 25px;
    }
    .all-items-hub-page{
        margin-left: -4%;
    }
    .hub-page-information-item{
        margin-left: 4%;
    }
    .hub-page-all-titles strong{
        font-size: 9px !important;
    }
    .hub-page-information-title-1{
        margin-left: -3%!important;
    }
    .hub-page-information-title-2{
        margin-left: 4%!important;
    }
    .hub-page-information-title-3{
        margin-left: 5%!important;
    }
    .hub-page-information-title-4{
        margin-left: 9%!important;
    }
}
@media only screen and (max-width: 330px){
    .hub-memory-img-size{
        height: 98px;
        width: 98px;
        margin-bottom: 5px;
        margin-left: -20px;
    }
    .hub-memory-img-size-cover{    
        height: 98px;
        width: 98px;
        left: -3.5px;
        top:0px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 104px;
        margin-left: -30px !important;
        font-size: 14px;
    }
    .btn-success-hub-page {
        padding: 9px 1px !important;
    }
    a.btn.btn-success-hub-page {
        width: 105px;
        font-size: 14px;
        margin-left: -15px !important;
    }
    .item {
        /* width: 125px !important; */
    }
    .item img {
        width: 12px;
        margin-top: 0px;
    }
    strong {
        font-size: 10px;
    }
    .item.item-img img {
        width: 10px !important;
    }
    .small-header h1 {
        font-size: 18px;
    }
    a.btn.btn-danger.btn-feedback {
        width: 260px;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -36px !important;
        width: 92px !important;
        left: 0px;
        margin-left: -14px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 106px !important;
        margin-left: -42px !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 142px;
        margin-left: -20px !important;
        padding: 9px 2px !important;
    }
    a.btn.btn-success-hub-page {
        width: 105px;
        font-size: 14px;
        margin-left: -25px !important;
    }
    .hub-page-btn-danger {
        margin-left: 0px;
        width: 130%;
        margin-left: -20px;
        font-size: 8px !important;
    }
    .hour-btn{
        margin-left: -0px;
        font-size: 7px !important;
        width: 120%;
        height: 40px;
    }
    /* .starbucks-item-img{
        width: 110.3% !important;
        max-width: 224px !important;
        max-height: 112px!important;
        border-radius: 20px 20px 0px 0px !important;
        margin: auto !important;
        object-fit: cover;
        margin-left: -5.5% !important;
    } */
    .item-div{
        margin-left: 23% !important;
        width: 100%;
    }
    .item{
        cursor: pointer;
        margin-left: -40%;
    }
    .item strong{
        font-size: 7px;
    }
    .all-items-hub-page{
        margin-left: -9%;
    }
    .hub-page-information-item{
        margin-left: 5%;
    }
    .hub-page-all-titles strong{
        font-size: 8px !important;
    }
    .hub-page-information-title-1{
        margin-left: -3%!important;
    }
    .hub-page-information-title-2{
        margin-left: 5%!important;
    }
    .hub-page-information-title-3{
        margin-left: 8%!important;
    }
    .hub-page-information-title-4{
        margin-left: 15%!important;
    }
}
@media only screen and (max-width: 317px){
    .all-items-hub-page{
        margin-left: -8%;
    }
    .hub-page-information-item{
        margin-left: 1.5%;
    }
}
@media only screen and (max-width: 280px){
    .hub-page-all-titles strong{
        font-size: 7px !important;
    }
    .hub-page-information-title-1{
        margin-left: -7%!important;
    }
    .hub-page-information-title-2{
        margin-left: 7%!important;
    }
    .hub-page-information-title-3{
        margin-left: 8%!important;
    }
    .hub-page-information-title-4{
        margin-left: 15%!important;
    }
    strong {
        font-size: 9px;
    }
    button.btn.btn-danger.hub-page-arrange-btn {
        width: 85px;
        margin-left: -44px !important;
        font-size: 12px;
        width: 95px !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        font-size: 12px;
        font-weight: normal !important;
    }
    button.btn.btn-danger.hub-page-arrange-btn.hour-btn {
        width: 142px;
        margin-left: -20px !important;
        padding: 6px 2px !important;
    }
    .hub-page-btn-danger {
        padding: 6px 8px !important;
    }
    a.btn.btn-success-hub-page {
        width: 100px;
        font-size: 12px;
        margin-left: -30px !important;
    }
    .btn-success-hub-page {
        padding: 6px 1px !important;
    }
    a.btn.btn-danger.btn-feedback {
        width: 258px;
    }
    .hub-memory-img-size{
        height: 86px;
        width: 86px;
        margin-bottom: 5px;
        margin-left: -20px;
    }
    .hub-memory-img-size-cover{    
        height: 86px;
        width: 86px;
        left: -5px;
        top:0px;
    }
}
/* Responsive css end */


/* Footer icon css start */
.nav-bottom{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon img {
    width: 22%;
}
.middle-icon-footer img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 30px;
}
.middle-icon-footer img {
    position: absolute;
    top: -30px;
    width: 92px !important;
    left: 30px;
}
.footer-width{
    width: 20% !important;
}
/* Footer icon css end */