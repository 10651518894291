/* body{
    background-color:#ffffff;
    margin: 0px;
    height: 100%;
    overflow: hidden;
    margin-top: -10% !important;
} */
.hubster-lottie-position{
    /* position: absolute; */
    margin: auto;
    margin-top: 0px !important;
    width:30%;
    height:30%;
    margin-left: 35%;
}

.hubster-name-logo-position{
    /* position: absolute; */
    margin: auto;
    margin-top: 40px !important;
    width: 30%;
}

.hubster-name-logo-size{
    width: 100%;
    height: 100%;
}

#lottie{
    background-color:#ffffff;
    width:10%;
    height:10%;
    display:block;
    overflow: hidden;
    transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
}

@media only screen and (max-width: 1200px){
    .hubster-lottie-position{
        margin: auto;
        margin-top: 65% !important;
        width:40%;
        height:40%;
        margin-left: 30%;
    }
}


@media only screen and (max-width: 650px){
    .hubster-lottie-position{
        margin: auto;
        margin-top: 25% !important;
        width:60%;
        height:60%;
        margin-left: 20%;
    }
}

@media only screen and (max-width: 420px){
    .hubster-lottie-position{
        margin: auto;
        margin-top: 70% !important;
        width:50%;
        height:50%;
    }
    .hubster-name-logo-position{
        margin-top: 30px !important;
    }
}


