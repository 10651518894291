.set-splash-drop-minus{
    width: 13px;
    height: 13px;
    background-color: rgb(235, 235, 238);
    border: solid 0.5px #fff !important;
    position: absolute;
    top: 10px;
    left: 10px;
    animation:.8s shake infinite alternate;
    border-radius: 20px;
    /* animation-iteration-count: infinite; */
} 

.set-splash-drag-tremple{
    animation:.8s shake infinite alternate;
}

.set-splash-edit-dot{
    border-radius: 50%;
    width: 33px;
    height: 33px;
    /* margin: auto; */
    background-color: rgb(71, 70, 70);
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border-top: solid 1px rgb(204, 204, 204) !important;
    border-left: solid 1px rgb(204, 204, 204) !important;
    border-right: solid 1px rgb(204, 204, 204) !important;
}

.set-splash-drop-minus-img{
    position: absolute;
    width: 80%;
    left: 10%;
    height: 20%;
    top: 40%;
}

.set-splash-drop-nfc{
    position: absolute;
    left: 180px;
    top: 40px;
    width: 40px !important;
    height: 40px !important;
}

.set-splash-drop-space-explain{
    color: #575578;
    text-align: center;
    font-weight: lighter;
    width: 60%;
    height: 30%;
    position: absolute;
    top: 50%;
    left: 20%;
    font-size: 20px !important;
}

.set-splash-drop-expainer{
    position: absolute;
    left: 170px;
    top: 80px;
    width: 70px !important;
    height: 80px !important;
}
.set-splash-drop-explainer-img{
    width: 100% !important;
    height: 100% !important; 
}

.set-splash-nfc-img{
    width: 100% !important;
    height: 100% !important; 
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
}

.set-splash-drag-text{
    width: 120px !important;
    height: 120px !important;
    /* background-color: royalblue; */
    /* background-color: bisque !important; */
}

.set-splash-drag-name{
    /* position: absolute;
    top: 20px;
    left: 0%; */
    width: 100%;
    height: 20px;
    z-index: 9;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color: #232231; 
    /* background-color: #1183ca;      */
}

.set-splash-drop-bottom-text{
    color: #acabbd;
    text-align: center;
    font-weight: lighter;
    width: 60%;
    height: 30%;
    position: absolute;
    bottom: -50px;
    left: 20%;
    font-size: 12px;
}

.set-splash-drag-username{
    width: 100%;
    height: 20px;
    z-index: 9;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    color: #232231; 
    /* background-color: #1183ca;       */
}

.set-splash-drag-username-missing{
    width: 100%;
    height: 20px;
    z-index: 9;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    color: #c79b21; 
    /* background-color: #1183ca;       */
}

.set-splash-drag-success{
    /* background-color: green; */
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
}

.set-splash-drag-missing-text{
    width: 100%;
    height: 20px;
    z-index: 9;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color: #c79b21; 
    position: absolute;
    top: 35px;
}

.set-splash-drag-edit-btn{
    position: absolute;
    top: 35px;
    left: 35%;
    border: none;
    padding: 2px 5px; 
    border-radius: 4px;
    font-weight: normal;
    width: 30%;
    text-align: center;
    /* background-color: blue; */
}

.set-splash-done-btn{
    position: absolute;
    bottom: 2%;
    left: 41%;
    background: #1183ca !important;
    /* background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%); */
    border: none;
    padding: 8px 45px; 
    border-radius: 30px;
    font-weight: bold;
    width: 20%;
    text-align: center;
    color: #fff !important;
}

.set-splash-done-btn:active {
    transform: scale(0.88);
}

.set-splash-done-btn:focus {
    transform: scale(0.88);
}

.set-splash-done-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.set-splash--page-title{
    position: absolute;
    top: 20px;
    left: 20%;
    width: 60%;
    height: 53px;
    z-index: 9;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #232231;    
}


.set-splash-hubst3r-logo{
    /* background-color: tomato; */
    width: 70%;
    height: 70%;
    position: absolute;
    top: 15%;
    left: 15%;
    border-radius: 50%;
    opacity: 0.85;
}

.set-splash-hubst3r-logo-img{
    margin-left: 23%;
    margin-top: 5%;
    width: 53% !important;
    height: 60% !important;
    /* border-radius: 50%; */
}

.set-splash-hubst3r-name-img{
    width: 75% !important;
    height: 25% !important;
    margin-left: 15%;
    margin-top: 8%;
    /* background-color: teal; */
}

.set-splash-drop-hubst3r{
    width: 74px;
    height: 74px;
    position: absolute;
    top: 40px;
    left: 158px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-instagram{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 180px;
    left: 60px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-facebook{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 180px;
    left: 135px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-tiktok{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 180px;
    left: 210px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-twitter{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 180px;
    left: 285px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-phone{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 250px;
    left: 210px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-website{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 250px;
    left: 135px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: solid 1px rgb(204, 204, 204) !important;
    z-index: 999;
}

.set-splash-drop-logo{
    margin-left: 25%;
    margin-top: 25%;
    width: 50%;
    height: 50%;
    z-index: 999 !important;
}

.set-splash-drop-logo-blur{
    margin-left: 25%;
    margin-top: 25%;
    width: 50%;
    height: 50%;
    z-index: 999 !important;
    opacity: 10%;  
}



  
@keyframes shake {
    0% { transform: translate(0.5px, 0.5px) rotate(0deg); }
    10% { transform: translate(-0.5px, -0.5px) rotate(-1deg); }
    20% { transform: translate(-0.5px, 0px) rotate(1deg); }
    30% { transform: translate(0.5px, 0.5px) rotate(0deg); }
    40% { transform: translate(0.5px, -0.5px) rotate(1deg); }
    50% { transform: translate(-0.5px, 0.5px) rotate(-1deg); }
    60% { transform: translate(-0.5px, 0.5px) rotate(0deg); }
    70% { transform: translate(0.5px, 0.5px) rotate(-1deg); }
    80% { transform: translate(-0.5px, -0.5px) rotate(1deg); }
    90% { transform: translate(0.5px, 0.5px) rotate(0deg); }
    100% { transform: translate(0.5px, -0.5px) rotate(-1deg); }
  }



.set-splash-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
}

.back-button-set-splash-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
    
}
.set-splash-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.set-splash-drop-postition{
    position: relative;
    top: 150px;
    left: 100px;
    width: 400px;
    height: 400px;
}

.bigg-design-circle{
    width: 600px;
    height: 600px;
    margin: auto;
    border-radius: 50%;
    /* background-color: royalblue; */

    /* background-color: rgb(129, 8, 8); */
    /* border: solid 1px rgb(204, 204, 204) !important; */

}

.set-splash-drop-space-initial{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    /* margin: auto; */
    /* background-color: rgb(246, 246, 246); */
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    border-top: solid 1px rgb(204, 204, 204,0.2) !important;
    /* border-left: solid 1px rgb(204, 204, 204,0.1) !important; */
    /* border-right: solid 1px rgb(204, 204, 204,0.1) !important; */
    padding-top: 150px;
    /* margin-top: 100px;
    margin-left: 100px; */
        /* background-color: rgb(129, 8, 8); */
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
    transform: scale(1);
    /* animation: pulse 2s infinite; */
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.set-splash-drop-space-glow{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    /* margin: auto; */
    /* background-color: rgb(246, 246, 246); */
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border-top: solid 1px rgb(204, 204, 204) !important;
    border-left: solid 1px rgb(204, 204, 204) !important;
    border-right: solid 1px rgb(204, 204, 204) !important;
    padding-top: 150px;
    /* margin-top: 100px;
    margin-left: 100px; */
        /* background-color: rgb(129, 8, 8); */
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(144,36,103,0.1);
        
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 250px rgba(28,48,111,0.05); 
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 250px transparent;
	}
}

.set-splash-drop-space-text{
    color: #575578;
    text-align: center;
    font-weight: lighter;
    width: 60%;
    height: 30%;
    position: absolute;
    top: 25%;
    left: 20%;
}

.set-splash-drop-space-profile-pic-box{
    width:100px;
    height: 100px;
    margin: auto;
    object-fit: cover;
    z-index: 9999 !important;
}

.set-splash-drop-space-profile-pic-img{
    z-index: 9999 !important;
    width:100%;
    height:100%;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204) !important;
}





.set-splash-drag-instagram{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 20%;
    left: 40px;
}

.set-splash-drag-facebook{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 50%;
    left: 40px;
}

.set-splash-drag-twitter{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 20%;
    right: 40px;
}

.set-splash-drag-phone{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 50%;
    right: 40px;
}


.set-splash-drag-website{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 80%;
    right: 40px;
}

.set-splash-drag-tiktok{
    width: 100px;
    height: 100px;
    /* background-color: royalblue; */
    position: absolute;
    top: 80%;
    left: 40px;
}

.set-splash-drag-logo{
    width: 100%;
    height: 100%;
   
}

@media only screen and (max-width: 1200px){
    .set-splashs-mobile-navbar{
        position: fixed !important;
        z-index: 99999999999;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    }

    .set-splash-done-btn{
        width: 100%;
        height: 80px;
        line-height: 70px;
        left: 0%;
        top: initial;
        bottom: 0% !important;
        font-size: 30px;
        font-weight: bold;
        border-radius: 0px !important;
        border: none;
        position: fixed !important;
    }
    

    .set-splash--page-title{
        top: 80px;
    }

    .bigg-design-circle{
        position: absolute;
        left: 0%;
        top: 30%;
        width: 100% ;
        height: 400px;
        border-radius: 0%;
        /* background-color: royalblue; */
    }
    
    .set-splash-drop-postition{
        position: relative;
        top: auto;
        left:0px;
        width: 400px;
        height: 400px;
        margin: auto;
    }
}


@media only screen and (max-width: 700px){    
    .set-splash-drop-expainer{
        left: 145px;
        top: 70px;
    }


    .set-splash-drop-nfc{
        left: 158px;
        top: 30px;
        width: 40px !important;
        height: 40px !important;
    }
    .set-splash-done-btn{
        height: 70px;
        line-height: 60px;
    }
    .set-splash--page-title{
        top: 60px;
    }

    .bigg-design-circle{
        top: 31%;
        height: 350px;
    }
    
    .set-splash-drop-postition{   
        width: 350px;
        height: 350px;
    }



    
    .set-splash-drop-instagram{
        /* top: 230px; */
        left: 50px;
    }
    
    .set-splash-drop-facebook{
        /* top: 230px; */
        left: 115px;
    }
    
    .set-splash-drop-tiktok{
        /* top: 230px; */
        left: 180px;
    }
    
    .set-splash-drop-twitter{
        /* top: 230px; */
        left: 245px;
    }
    
    .set-splash-drop-phone{
        /* top: 285px; */
        left: 180px;
    }
    
    .set-splash-drop-website{
        /* top: 285px; */
        left: 115px;
    }




    .set-splash-drop-space{
        padding-top: 125px;
    }
    .set-splash-drop-space-text{
        width: 70%;
        left: 15%;
    }
    
    .set-splash-drag-instagram{
        top: 13%;
        left: 8%;
    }

    .set-splash-drag-facebook{
        top: 13%;
        left: 42%;
    }

    .set-splash-drag-tiktok{
        top: 13%;
        left: initial;
        right: 8%;
    }

    .set-splash-drag-twitter{
        top: initial;
        bottom: 13%;
        right: initial;
        left: 8%;
    }

    .set-splash-drag-phone{
        top: initial;
        bottom: 13%;
        right: 8%;
    }

    .set-splash-drag-website{
        top: initial;
        bottom: 13%;
        right: initial;
        left: 42%;
    }
}


@media only screen and (max-width: 550px){
    .set-splash-drop-space-explain{
        top: 45%;
    }
    
    .set-splash-drop-expainer{
        left: 120px;
        top: 40px;
    }

    .set-splash-drop-minus{
        top: 6px;
        left: 6px;
    }

    .set-splash-drag-text{
        width: 100px !important;
        height: 100px !important;
        /* background-color: royalblue; */
        /* background-color: bisque !important; */
    }

    .set-splash-drag-text-left{
        margin-left: -12px;
    }

    .set-splash-drop-nfc{
        left: 128px;
        top: 25px;
        width: 40px !important;
        height: 40px !important;
    }
    .set-splash-done-btn{
        height: 55px;
        line-height: 45px;
        font-size: 25px;

    }

    .set-splash--page-title{
        /* top: 10%;  */
        font-size: 14px;
    }

    .bigg-design-circle{
        top: 220px;
    }

    .set-splash-drop-space{
        padding-top: 100px;
    }
   
    .set-splash-drop-postition{   
        width: 300px;
        height: 300px;
        margin-top: 10px;
    }

     
    .set-splash-drop-instagram{
        width: 50px;
        height: 50px;
        top: 155px;
        left: 30px;
    }
    
    .set-splash-drop-facebook{
        width: 50px;
        height: 50px;
        top: 155px;
        left: 93px;
    }
    
    .set-splash-drop-tiktok{
        width: 50px;
        height: 50px;
        top: 155px;
        left: 155px;
    }
    
    .set-splash-drop-twitter{
        width: 50px;
        height: 50px;
        top: 155px;
        left: 220px;
    }
    
    .set-splash-drop-phone{
        width: 50px;
        height: 50px;
        top: 215px;
        left: 155px;
    }
    
    .set-splash-drop-website{
        width: 50px;
        height: 50px;
        top: 215px;
        left: 93px;
    }


    .set-splash-drop-space-text{
        font-size: 14px !important;
    }
    
    .set-splash-drop-space-profile-pic-box{
        width:80px !important;
        height: 80px !important;
        margin-top: 10px
    }

    
    .set-splash-drag-instagram{
        top: 130px;
        left: 8%;
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-facebook{
        top: 130px;
        left: 41%;
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-tiktok{
        top: 130px;
        left: initial;
        right: 8%;
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-twitter{
        top: 560px;
        /* bottom: 5%; */
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-phone{
        top: 560px;
        right: 8%;
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-website{
        top: 560px;
        left: 41%;
        width: 60px;
        height: 60px;
    }
    .set-splash-drop-bottom-text{
        bottom: 80px;
    }
}


@media only screen and (max-width: 380px){
    .set-splash-done-btn{
        height: 35px;
        line-height: 25px;
        font-size: 18px;
    }

    .set-splash-drag-text-left{
        margin-left: -16px;
    }
    
    /* .bigg-design-circle{
        top: 28%;
    } */
    
    .set-splash-drag-instagram{
        width: 70px;
        height: 70px;
    }

    .set-splash-drag-facebook{
        left: 41%;
        width: 70px;
        height: 70px;
    }

    .set-splash-drag-tiktok{
        width: 70px;
        height: 70px;
    }

    .set-splash-drag-twitter{
        bottom: 5%;
        width: 70px;
        height: 70px;
    }

    .set-splash-drag-phone{       
        bottom: 5%;
        width: 70px;
        height: 70px;
    }

    .set-splash-drag-website{
        bottom: 5%;
        left: 41%;
        width: 70px;
        height: 70px; 
    }
}


@media only screen and (max-width: 359px){    

    .set-splash--page-title{
        top: 60px;
    }
    .set-splash-drag-instagram{
        width: 60px;
        height: 60px;
        left: 2%;
    }

    .set-splash-drag-facebook{
        left: 41%;
        width: 60px;
        height: 60px;
    }

    .set-splash-drag-tiktok{
        width: 60px;
        height: 60px;
        right: 2%;
    }

    .set-splash-drag-twitter{
        bottom: 5%;
        width: 60px;
        height: 60px;
        left: 2%;
    }

    .set-splash-drag-phone{      
        bottom: 5%;
        width: 60px;
        height: 60px;
        right: 2%;
    }

    .set-splash-drag-website{
        bottom: 5%;
        left: 41%;
        width: 60px;
        height: 60px;  
    }
}