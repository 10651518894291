.back-button-likes-page-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.likes-page-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.impression-sellect-hub-button{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 12px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    margin-top: 43%;
}

.all-impressions-itemds{
    margin-top: 6%;
}
.single-item-impression{
    width: 50%;
    height: 100px;
    background-color: #ffff;
    border-radius: 20px;
    border:rgb(233, 233, 231) solid 2px;
    margin: auto; 
    margin-bottom: 1%;
}
.impression-profile-picture-position{
    padding-top: 2.5%;
    margin-left: 5%;
    width: 11%;
}
.impression-profile-picture{
    width:70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover!important;
}
.impression-profile-username{
    width:39%;
    height: 70px;
    text-align: left;
    margin-left: 6%;
    margin-top: 35px;
    font-size: 25px;
}
.impression-mika-img-position{
    margin-left: 20px;
    width:15%;
    height: 100%;
}
.impression-mika-img{
    margin-top: 30%;
    width: 60%;
}
.impression-non-buddy-mika-img-position{
    margin-left: 20%;
    width:15%;
    height: 100%;
}
.impression-non-buddy-mika-img{
    margin-top: 10%;
    width: 100%;
}
.impression-add-buddy-position{
    width:17%;
    height: 100%;
}
.impression-add-buddy-button{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 5px 10px;
    color: white !important;
    font-size: 15px !important;
    border-radius: 30px!important;
    text-align: center;
    margin-top: 40%;
}
@media only screen and (max-width: 1200px){
    .all-impressions-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .single-item-impression{
        width: 80%;
        height: 100px;
        background-color: #ffff;
        border-radius: 20px;
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .impression-profile-picture-position{
        padding-top: 1%;
        margin-left: 5%;
        width: 11%;
    }
    .impression-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .impression-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .impression-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .impression-mika-img{
        margin-top: 0%;
        width: 60%;
    }
    .impression-non-buddy-mika-img-position{
        margin-left: 10%;
        width:10%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-non-buddy-mika-img{
        margin-top: 10%;
        width: 80%;
    }
    .impression-add-buddy-position{
        margin-left: -30px;
        width:13%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-add-buddy-button{
        font-size: 15px !important;
        margin-top: 30%;
    }
}

@media only screen and (max-width: 780px){
    .all-impressions-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .single-item-impression{
        width: 80%;
        height: 100px;
        background-color: #ffff;
        border-radius: 20px;
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .impression-profile-picture-position{
        padding-top: 2%;
        margin-left: 5%;
        width: 11%;
    }
    .impression-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .impression-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .impression-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .impression-mika-img{
        margin-top: 20%;
        width: 60%;
    }
    .impression-non-buddy-mika-img-position{
        margin-left: 10%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-non-buddy-mika-img{
        margin-top: 10%;
        width: 80%;
    }
    .impression-add-buddy-position{
        margin-left: -20px;
        width:11%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-add-buddy-button{
        font-size: 15px !important;
        margin-top: 50%;
    }
}

@media only screen and (max-width: 550px){
    .all-impressions-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .single-item-impression{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        border-radius: 0px;
        border:none;
        margin: auto; 
        margin-bottom: 1%;
        border-bottom: rgb(233, 233, 231) solid 1px;
    }
    .impression-profile-picture-position{
        padding-top: 2%;
        margin-left: 5%;
        width: 11%;
    }
    .impression-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .impression-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .impression-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .impression-mika-img{
        margin-top: 25%;
        width: 70%;
    }
    .impression-add-buddy-position{
        margin-left: -20px;
        width:11%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-add-buddy-button{
        font-size: 15px !important;
        margin-top: 60%;
    }
}

@media only screen and (max-width: 430px){
    .all-impressions-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .single-item-impression{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        /* border-radius: 20px; */
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .impression-profile-picture-position{
        padding-top: 5%;
        margin-left: 5%;
        width: 11%;
    }
    .impression-profile-picture{
        width:60px;
        height: 60px;
        border-radius: 50%;
    }
    .impression-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 22px !important;
        margin-left: 10%;
        font-weight: bold;
    }
    .impression-mika-img-position{
        margin-left: -30px;
        width:15%;
        height: 100%;
    }
    .impression-mika-img{
        margin-top: 40%;
        width: 70%;
    }
    .impression-non-buddy-mika-img-position{
        margin-left: 5%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-non-buddy-mika-img{
        margin-top: 20%;
        width: 100%;
    }

    .likes-heart-icon{
        position:relative;
        width: 50px;
        height: 50px;
        right:-5%;
        top:25px;
    }

    .likes-heart-icon-img{
        width: 100%;
        heigth:100%;
    }

    .impression-add-buddy-position{
        margin-left: -12px;
        width:18%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-add-buddy-button{
        font-size: 15px !important;
        margin-top: 40%;
    }
}
@media only screen and (max-width: 340px){
    .all-impressions-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .single-item-impression{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        /* border-radius: 20px; */
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .impression-profile-picture-position{
        padding-top: 8%;
        margin-left: 5%;
        width: 11%;
    }
    .impression-profile-picture{
        width:50px;
        height: 50px;
        border-radius: 50%;
    }
    .impression-profile-username{
        width:50%;
        height: 70px;
        margin-top: 40px;
        font-size: 18px;
    }
    .impression-mika-img-position{
        margin-left: -40px;
        width:15%;
        height: 100%;
    }
    .impression-mika-img{
        margin-top: 70%;
        width: 70%;
    }
    .impression-non-buddy-mika-img-position{
        margin-left: 5%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-non-buddy-mika-img{
        margin-top: 50%;
        width: 100%;
    }
    .impression-add-buddy-position{
        margin-left: -0px;
        width:18%;
        height: 100%;
        /* background-color: red; */
    }
    .impression-add-buddy-button{
        font-size: 15px !important;
        margin-top: 60%;
    }
}

















*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
} 
.meeteing-wifi-round-button-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}
.meeteing-wifi-position{
    position: absolute;
    top: 2%;
    left: 3%;
}
.meeteing-wifi-round-button{
    /* margin: 36px; */
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    /* line-height: 95px; */
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    margin-bottom: 23px;
}
.meeteing-wifi-icon{
    width: 20px;
    height:20px;
    margin:auto;
    margin-top: 10px;
}
.meeteing-wifi-text{
    color: rgb(87, 85, 120);
    font-weight: bolder;
    font-size: 12px;
    margin-top: -15px;
}
/* loading */
.impressions-section-disappear{ 
    display: none;
}
.impressions-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.impressions-lottie-dissappear{
    display: none;
}
/* end loading */
.empty-impressions-image{
    margin: auto !important;
}
.empty-impressions-created{
    width: 50% !important;
    margin-left: -5%;
    margin-top:-5%;
}
.impressions-invitations-status-color{
    color: #575578;
}
/* .impressions-change-impressions-all{
    width: 30%;
    margin: auto;
}
.impressions-change-impressions-icon-position{
margin-top: -10%;
}
.impressions-change-impressions-icon-size{
    width: 30% !important;
    border-radius: 20%;
} */
.impressions-change-impressions-btn{
    margin: auto;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    color: #fff;
    margin-top: 0px;
    /* width: 40%; */
}
.invitation-message-red-bubble{
    position: relative;
    background-color: red;
    width:14px;
    height: 14px;
    border-radius: 50%;
    z-index: 1;
    margin-top: -89%;
    margin-left: 75%;
    border: solid 2px #fff;
}
.text-blue{
    color: #575578;
    font-size: 70%;
}
.text-blue-empty-impressions{
    color: #575578;
    font-size: 170% !important; 
}
/* Body left css start */
.body-left{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.feedback-btn {    
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    width: 80% !important;
    border: none;
    /* padding: 10px 45px; */
    border-radius: 30px !important;
    bottom:0px!important;
    color: white !important;
}
.logo{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.left-text{
    font-size: 15px;
}
.left-icon img{
    width: 45%;
}
.left-home{
    cursor: pointer;
}
.left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.up-toggle, .up-toggle1, .up-toggle2{
    width: 25px;
    margin-top: 20px;
    cursor: pointer;
}
.impressions-page-body-right{
    margin-left: 16.67%;
    background: #fff;
    width: 1200px !important;
}
/* .button {
    padding: 10px 25px !important;
    width: 106%;
    font-weight: bold !important;
    border-radius: 30px !important;
    cursor: pointer;
} */
.btn-font-size{
    font-size: 12px !important;
    text-align: center;
}

.btn1:hover{
    background-color: #26789F;
}
.all-btn{
    margin-left: 10% !important;
}
.all-btn img{
    cursor: pointer;
}
.text{
    background: #26789F;
    color: #fff;
    border-radius: 12px;
    height: 80px;
    transform: translate(0px, 10px);
}
.text p{
    margin-bottom: 0px;
}
.text span{
    float: right;
    margin-top: 8%;
}
.img-text{
    margin-top: -10px;
}
.item-of-table{
    background-color: #fff;
    padding: 25px 18px 0px;
    padding-right: 20px;
    border-radius: 20px;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 20px !important;
}
.top-logo {
    position: absolute;
    top: -48px;
    left: 50%;
    /* margin-left: 53%; */
    transform: translateX(-50%);
    /* margin: auto !important; */

}
.impressions-hub-logo-size {
    width: 88px !important;
    height: 88px !important;
    border-radius: 50% !important;
    border: 9px solid #fff !important;    
    object-fit: cover;
}
.second-row {
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 8px 12px;
    border-radius: 15px;
}
.second-row img{
    width: 35px;
}
.third-row img {
    width: 28px;
}
.next{
    width: 14px !important;
}
.third-row.second-row.no-answer-row.my-3 {
    padding: 13px 5px;
}
.row-4 {
    padding: 14px 8px;
}
.day-layout {
    font-size: 18px;
}
.date-layout {
    margin-top: 7% !important;
    margin-left: -0.5%;
    font-size: 15px !important;
}
.time-layout {
    margin-top: 1.5% !important;
    /* font-size: 20px !important; */
    font-weight: bold;
}
button.btn-impressions.btn-info {
    /* margin-left: -25%; */
    border-radius: 30px;
    background: #553DB1;
    border: none;
    font-weight: bold;
    font-size:17px!important;
    width: 70%!important;
    color: white !important;
    padding: 12px 20px !important;
    /* height: 80% !important; */
}
button.btn-impressions.btn-danger-not-going-impressions {
    border-radius: 30px;
    padding: 8px 30px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 20px;
    /* width: 60% !important; */
    margin: auto !important;
    margin-top: 2%!important;
    margin-bottom: 2% !important;
}
/* top buttons */
.reservations-pre-share-icon{
    margin-top: 10px;
    width: 34px;
}

.reservations-pre-share-button{
    display: block;
    background-color: #e9e9e9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    position: relative;
    text-align: center;
    border-radius: 50%;
    width: 74px;
    height: 74px;
    border: 9px solid #fff !important;    
}
.pre-share-button-layout{
    position: absolute;
    top: -25px;
    right: -1.5%;
}
.reservations-share-button{
    display: block;
    width: 74px;
    height: 74px;
    text-align: center;
    border-radius: 50%;
    border: 9px solid #fff !important;    

}
.reservations-share-icon{
    /* margin-top: 10px; */
    width: 74px;
    margin-left: -9px;
    margin-top: -10px;
    /* border: 9px solid #fff !important;  */
    /* border-radius: 50%; */
}
.share-button-layout{
    position: absolute;
    top: -25px;
    right: -1.5%;
}
.invite-more-buddies-icon{
    margin-bottom: 20px;
    width:40px !important;
}
.button-7 {
    padding: 10px 25px !important;
    width: 100%;
    font-weight: bold !important;
    border-radius: 30px !important;
    cursor: pointer;
}
.btn1-7 {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(0deg,  rgb(68, 169, 223) 51%, rgba(50,135,187,1) 89%);
    border-color: rgba(50,135,187,1) !important;
}
.btn2-7 {
    color: #fff;
    background: rgb(134,122,239);
    background: linear-gradient(0deg, rgba(134,122,239,1) 12%, rgba(92,69,185,1) 73%);
    border-color: #5c45b9 !important;
}
.btn3-7 {
    color: #fff;
    background: rgb(221,58,151);
    background: linear-gradient(0deg, rgba(221,58,151,1) 0%, rgba(205,9,92,1) 100%);
    border-color: #AA2E7F !important;
}
.btn1-7:hover{
    background-color: #26789F;
}
.all-btn-7{
    margin-left: 10% !important;
}
.all-btn-7 img{
    cursor: pointer;
}
/* end top buttons */
.activities-reservation-for-text{
    margin-top: -2%;
    margin-bottom: -2%;
}
.impressions-hub-name-position{
    margin: auto;
}
.impressions-hub-name{
    color: #575578;
    font-weight:bold;
    margin-top: 25px;
}
.reservation-shares-photo-layout{
    width: 20%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
.reservation-shares-profile-pic{
    border-radius: 50%;
    width: 60% !important;
}
.pending-impressions-btn-light{
    margin: auto;
    width: 65%;
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    color: rgb(168, 168, 168) !important;
    border: none;
    font-weight: bold;
    font-size: 10px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
}
.pending-impressions-btn-sellected{
    margin: auto;
    width: 65%;
    border-radius: 30px;
    padding: 12px 35px;
    background: #553DB1;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: white !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
}
.down-arrow img{
    width: 30px;
    cursor: pointer;
    margin: auto !important;
    /* margin-left: 9%; */
}
.drinks-box, .drinks-box1, .drinks-box2{
    display: none;
}
.pre-shared-hub-reservation-box{
    background-color: #8c8a8f;
}
.shared-hub-reservation-box{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
}
.text1{
    background-color: #553DB1;
}
.text2{
    background-color: #AA2E7F;
}
.item-all{
    padding-bottom: 320px !important;
}
.item-col{
    max-width: 80% !important;
}

.btn-success-impressions {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 12px 75px !important;
    border-radius: 30px !important;
}
.btn-impressions.btn-light {
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}

.number-img {
    margin-top: 10px;
    width: 100px;
    height: 100px;
}
.numbers {
    position: absolute;
    top: 45px;
    left: 0px;
    right: 0;
    font-weight: bold;
    color: #57558E;
    letter-spacing: -1px;
}
.attending-position {
    position: relative;
    margin-left:10px
}
.all-impressions-center-layout{
    width: 100%;
}
.numbers-0 {
    position: absolute;
    left: 50px;
    top: 25px;
    font-size: 18px;
}
.link2-impressions{
    margin-left: 15px !important;
}
.impressions-buddies-text-layout{
    color: #57558E;
    text-align: justify;
    margin: auto;
    word-break: break-all;
}
.impressions-row{
    display: none !important;
}
.all-btn img {
    width: 135% !important;
}
.footer {
    padding-bottom: 60px !important;
}

/* Footer icon css start */
.nav-bottom{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon img {
    width: 18%;
}
.middle-icon-footer img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 30px;
}
.footer-width{
    width: 20% !important;
}
.nav-bottom{
    display: none !important;
}
.m-t {
    margin-top: 10px !important;
    width: 80px;
    height: 80px;
    margin-top: 80px;
    border-radius: 50%;
}
.invitations-img {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
    margin-top: -7px;
}
.impressions-form-control-text{
    margin-top: -10px !important;
}
/* Footer icon css end */
/* Body Right css end */

/* extra click modal css start */
.click-blue-box6 {
    background: #553DB1;
    padding: 12px 15px;
    border-radius: 15px;
    width: 50%;
    margin-left: 25%;
    margin-top: 10%;
    margin-bottom: 5%;
}
.click-blue-box6 p {
    margin-bottom: 0px;
}
.impressions-modal-logo{
    border-radius: 50%;
}
.mb{
    margin-top: -.6rem;
}
.red-circle {
    margin-left: -50%;
    /* margin-top: 15px; */
}
.red-circle img{
    width: 40%;
}

.impressions-reservation-activities{
    /* margin-left: 40%; */
    font-weight: bold;
    font-size: 20px;
}
.text-red-6 p{
    color: red;
    font-size: 12px !important;
}
.btn-impressions.btn-primary.fd-btn-6.my-3 {
    background: #553DB1;
    border: none;
    width: 10rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 11px;
    font-weight: bold;
    cursor: pointer;
}
.btn-impressions.btn-light.d-btn-6{
    background: #fff;
    border: none;
    width: 10rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 11px;
    font-weight: bold;
    color: #553DB1;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    cursor: pointer;
    margin-top: 20px;
}
.cancle-btn-6{
    background-color: #c00808 !important;
    border: none !important;
    margin-left: 10%;
}
.send-btn-6{
    background-color: #553DB1 !important;
    border: none !important;
    margin-left: 30%;
}
.btn-impressions.btn-danger.cs-btn6, .btn-impressions.btn-primary.cs-btn6 {
    width: 12rem;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
}
.il-6 {
    position: absolute;
    z-index: 999;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
}
.il-6 img{
    width: 120px;
    height: 120px;
}
.row-6 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -115px!important;
    margin-left: -125px !important;
}

button.btn-impressions.btn-danger-impressions {
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 12px; 
    margin-top: 5px!important;
    /* width: 100% !important; */
}
button.btn-impressions.btn-success-impressions-green {
    border-radius: 30px;
    padding: 12px 35px;
    background: #64ad6a;
    border: none;
    color: white; 
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px!important;
    /* width: 100% !important;
    height: 95%; */
}
.btn-impressions.btn-light-accepted-declined {
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 12px !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 5px!important;
}

.creator-name-color {
    color: #57558E !important;
}

/* extra click modal css end */
.c-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -205%;
    margin-left: -50%;
    width: 500%;
}
.modal-size{
    /* width: 100px;; */
    margin-top: -200px;
    margin-bottom: -100px;
    height: 10%!important;
}
.modal-attending-icon{
    margin-left: 15%;
}
.numbers-6 {
    position: relative;
    margin-top: -28%;
    font-weight: bold;
    color: #57558E;
    margin-left: 15%;
}
.attending-6{
    margin-left: 15%;
}
.numbers-6 span{
    font-weight: bold;
    font-size: 20px;
}
.impressions-modal-logo-hub {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
    border: 5px solid #FFFAFA !important;
    object-fit: cover;
}

.modal.fade.bd-example-modal-lg {
    background: rgba(8, 8, 8, 0.9);
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgba(8, 8, 8, 0.1) !important;
    background-clip: padding-box;
    border: 1px solid  rgba(8, 8, 8, 0.1) !important;
    border-radius: .3rem;
    outline: 0;
}
/* end modal */






/* Responsive css start */
@media only screen and (max-width: 1200px){

    .likes-page-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }

    .impressions-back-icon-box{
        position: absolute;
        top: 6px;
        left: 2%;
        width: 30px;
        height: 30px;
    }

    .impressions-back-icon{
        width: 100%;
        height: 100%;
    }
    
    .impressions-hub-name{
        margin-top: 35px;
        margin-bottom: -10px;
    }
    .text{
        border-radius: 15px;
        margin-top: 10px;
        height: 90px;
    }
    .impressions-page-body-right{
        width: 100% !important;
        height: 100%!important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 7rem!important;
    }
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 23.33% !important;
    }
}
@media only screen and (max-width: 1200px){
    .body-left{
        display: none;
    }
    .impressions-page-body-right{
        margin-left: initial;
    }
    .item-col{
        max-width: 80% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .d-b {
        display: block;
    }
    .text span {
        float: right;
        margin-top: -3%;
        position: relative;
    }
    .top-logo {
        position: absolute;
        top: -34px;
        left: 0;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    button.btn-impressions.btn-info {
        padding: 12px 20px !important;
        width: 70%!important;
    }
    /* .blue-btn{
        text-align: right !important;
    } */
    /* .red-btn{
        text-align: left !important;
    } */
    .item-all{
        padding-bottom: 0px !important;
    }
    .impressions-row{
        display: block !important;
        background-color: #ffff !important;
        height: 40px;
        /* background: rgb(144,36,103);
        background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%); */
        padding: 10px 0px 6px 0px;
    }
    .impressions{
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }
    .col-xl-10.col-lg-12.impressions-page-body-right.pb-5 {
        border-radius: 0px 0px 0px 0px;
    }
    .bg-linear{
        /* background: rgb(144,36,103);
        background: linear-gradient(353deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 51%); */
    }
    .impressions-box-size {
        /* width: 115% !important; */
        }
    .impressions-hub-color{
        /* background: rgb(144,36,103);
        background: linear-gradient(336deg, rgba(144,36,103,1) 65%, rgba(28,48,111,1) 99%); */
        width: 100%;
        background: linear-gradient(258deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    }
    .nav-bottom{
        display: block !important;
    }
    .time-layout {
        margin-top: -0% !important;
        font-size: 20px !important;
    }
    .day-layout {
        font-size: 20px;
        margin-top: -1%;
    }
    .number-img {
        margin-left: 7px;
    }
    .numbers {
        /* left: 88px; */
    }
    .attending-position {

    }
    .impressions-total-page-layout{
        position: absolute !important;
        width: 100%;
        top: 0px !important;
        left: 0px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -190%;
    }

}
@media only screen and (max-width: 992px){
    .click-blue-box6 {  
        width: 40%;
        margin-left: 25%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .red-circle {
        margin-left: -80%;
        margin-top: 10px;
    }
    .red-circle img{
        width: 40%;
    }
    .all-btn {
        margin-left: 3% !important;
    }
    .all-btn img {
        width: 120% !important;
    }
    .text{
        border-radius: 15px;
        margin-top: 10px;
        height: 70px;
    }
    button.btn-impressions.btn-info {
        width: 80%!important;
        height: 100%;
        font-size: 13px!important;
    }
    .time-layout {
        margin-top: -0% !important;
        font-size: 20px !important;
    }
    .date-layout {
        margin-top: 2% !important;
        font-size: 15px !important;
    }
    .number-img {
        /* margin-left: 10%; */
    }
    .numbers {
        /* left: 38%; */
    }
    .btn1 {
        width: 110%;
        margin-left: -50px;
    }
    .btn-impressions.btn-light.bt1-position {
        padding: 12px 35px;
        width: 110%;
    }
    
    .btn2 {
        width: 110%;
    }
    .btn-impressions.btn-light.btn2-position {
        padding: 12px 35px;
        width: 110%;
    }
    
    .btn3 {
        width: 110%;
        margin-left: 25px;
    }
    .btn-impressions.btn-light.btn3-position {
        padding: 12px 35px;
        width: 110%;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 800px){
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 30.33% !important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 3rem!important;
    }
}
@media only screen and (max-width: 790px){
    .numbers {
        /* left: 45%; */
    }
}

@media only screen and (max-width: 769px){
    .btn-impressions.btn-danger.cs-btn6, .btn-impressions.btn-primary.cs-btn6 {
        width: 10rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 30px;
    }
    .impressions-reservation-activities{
        /* margin-left: 38%; */
        font-weight: bold;
        font-size: 20px;
    }
    .text{
        margin-top: 10px;
        height: 50px;
    }
    .m-t {
        margin-top: 10px !important;
        width: 60px;
        height: 60px;
    }
    .all-btn {
        margin-left: 10% !important;
    }
    .all-btn img {
        width: 96% !important;
    }
    .btn1 {
        width: 110%;
        margin-left: -10px;
    }
    .date-layout{
        margin-top: 2% !important;
    }
    .time-layout {
        margin-top: 1.5% !important;
        font-size: 14px !important;
    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 15px !important;
    }
    .number-img {
        margin-top: -1%;
        margin-left: -15%;
    }
    .numbers {
        margin-top:-10px;
        margin-left: -15px;
    }
    .attending-position{
        margin-left: -15px;
    }
    button.btn-impressions.btn-info {
        width: 100% !important;
        height: 100%;
        font-size: 10px !important;
    }
    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 18px;
        /* width: 65% !important; */
    }
    .cancle-btn-6{
        background-color: #c00808 !important;
        border: none !important;
        margin-left: 20%;
    }
}
@media only screen and (max-width: 767px){
    .meeteing-wifi-position{
        top: 3%; 
        left: 5%;
    }
    .meeteing-wifi-round-button{
        /* margin: 36px; */
        width: 30px;
        height: 30px;
        margin-bottom: 23px;
    }
    .meeteing-wifi-icon{
        width: 15px;
        height:15px;
        margin-top: 3px;
    }
    .all-btn {
        margin-left: 0% !important;
    }
    .il-6 {
        top: -60px;
        left: 51%;
    }
    .il-6 img{
        width: 100px;
        height: 100px;
    }
    .btn1 {
        width: 80%;
        margin-left: -30px;
    }
    .btn-impressions.btn-light.bt1-position {
        padding: 12px 0px;
        width: 80%;
    }
    
    .btn2 {
        width: 80%;
    }
    .btn-impressions.btn-light.btn2-position {
        padding: 12px 0px;
        width: 80%;
    }
    
    .btn3 {
        width: 80%;
        margin-left: 25px;
    }
    .btn-impressions.btn-light.btn3-position {
        padding: 12px 0px;
        width: 80%;
        margin-left: 25px;
    }
}
@media only screen and (max-width: 669px){
    .attending-position{
        margin-left: 0px;
    }
    .numbers {
        margin-top:-10px;
        margin-left: -10px;
    }
}

@media only screen and (max-width: 576px){
    /* top buttons */
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 31.33% !important;
    }
    .button-7 {
        padding: 10px 10px!important;
        width: 110%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 1rem!important;
    }
    /* end top buttons */
    button.btn-impressions.btn-info {
        border-radius: 40px;
        background: #553DB1;
        border: none;
        font-weight: bold;
        padding: 2px 15px !important;
        text-align: center !important;
    }
    
    .item-col{
        max-width: 90% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .footer-icon img {
        width: 25%;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -45px;
        width: 83px !important;
        left: 16px;
    }
    .all-btn img {
        width: 101% !important;
    }
    .attending-position {
        margin-left: 5px
    }
    .feedback-btn {    
        width: 50% !important;
        margin-left:25%;
    }
    .impressions-reservation-activities{
        /* margin-left: 36%; */
        font-weight: bold;
        font-size: 20px;
    }
    .btn1 {
        padding:0px 0px !important;
        width: 80% !important;
        margin-left: -30px;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.bt1-position {
        padding: 0px 0px !important; 
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn2 {
        padding:0px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.btn2-position {
        padding: 0px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn3 {
        padding:0px 0px !important;
        width: 80% !important;
        margin-left: 25px !important;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.btn3-position {
        padding: 0px 0px;
        width: 80%;
        margin-left: 25px;
        font-size: 12px !important
    }
    .numbers {
        margin-top:-10px;
        margin-left: 3px;
    }
}

@media only screen and (max-width: 504px){
    button.btn-impressions.btn-danger-impressions {
        padding: 12px 30px !important;
        font-size: 10px;
        margin-top: 5px!important;
    }
    button.btn-impressions.btn-success-impressions-green {
        padding: 12px 30px !important;
        font-size: 10px;
        margin-top: 5px!important;
    }
    .btn-impressions.btn-light-accepted-declined {
        padding: 12px 25px !important;
        font-size: 10px !important;
        margin-top: 5px!important;
    }
}

@media only screen and (max-width: 492px){
    .btn-impressions.btn-danger.cs-btn6, .btn-impressions.btn-primary.cs-btn6 {
        width: 8rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 30px;
    }
    .click-blue-box6 {
        background: #553DB1;
        padding: 12px 15px;
        border-radius: 15px;
        width: 50%;
        margin-left: 28%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .date-layout{
        margin-top: 2% !important;
        font-size: 10px !important;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 4% !important;

    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 10px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -130%;
        width: 400%;
        margin-top: -250%;
    }
    /* end modal */
    .pending-impressions-btn-light{
        font-size: 9px;
    }
    .pending-impressions-btn-sellected{
        font-size: 9px;
    }
}
@media only screen and (max-width: 490px){
    .date-layout{
        margin-top: 2% !important;
        font-size: 10px !important;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 5% !important;

    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 10px !important;
    }
    .number-img {
        height: 80px;
        margin-top: 10px;
        margin-left: -15px;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 15px;
    }
    /* .numbers {
        top: 36px;
        left: 45%;
    } */
    .attending-position {
        font-size: 12px;
    }
    .feedback-btn {    
        width: 60% !important;
        margin-left:20%;
        font-size: 12px;
    }
    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
        /* width: 70% !important; */
    }
}

@media only screen and (max-width: 455px){
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 33.33% !important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: -1rem!important;
    }
    .meeteing-wifi-text{
        font-size: 11px;
    }
}

@media only screen and (max-width: 435px){
    .cancle-btn-6{
        background-color: #c00808 !important;
        border: none !important;
        margin-left: 5%;
    }
    .send-btn-6{
        background-color: #553DB1 !important;
        border: none !important;
        margin-left: 50%;
    }
    .d-b {
        display: block;
        font-size: 13px;
        margin-left: -18px;
    }
    .all-btn img {
        width: 116% !important;
    }
    /* .button {
        padding: 8px 0px !important;
        width: 110%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
    } */
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 7% !important;

    }
    /* .numbers {
        top: 35px;
        left: 40px;
    } */
    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
        /* width: 80% !important; */
    }
    .btn1 {
        padding:10px 0px !important;
        width: 80% !important;
        margin-left: -30px;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.bt1-position {
        padding: 10px 0px !important; 
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn2 {
        padding:10px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.btn2-position {
        padding: 10px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn3 {
        padding:10px 0px !important;
        width: 80% !important;
        margin-left: 25px !important;
        font-size: 12px !important
    }
    .btn-impressions.btn-light.btn3-position {
        padding: 10px 0px;
        width: 80%;
        margin-left: 25px;
        font-size: 12px !important
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -125%;
        width: 380%;
    }
    .attending-6{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end modal */
    .numbers {
        margin-top:-8px;
        margin-left: 22px;
    }
    .attending-position {
        margin-top: -20% !important;
        margin-left: 10% !important;
    }
    .meeteing-wifi-position{
        top: 3%; 
        left: 8%;
    }
}


@media only screen and (max-width: 405px){
    .button-7 {
        padding: 8px 0px!important;
        width: 115%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        /* font-size: 15px !important; */
    }
}

@media only screen and (max-width: 400px){
    .btn-impressions.btn-danger.cs-btn6, .btn-impressions.btn-primary.cs-btn6 {
        width: 7rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 30px;
    }
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 60px;
        height: 60px;
    }
    .full-w{
        width: 100% !important;
        display: block;
    }
    .img-text img {
        width: 57px;
    }
    .d-b {
        display: block;
        font-size: 11px;
    }
    .text {
        /* margin-left: 8px; */
    }
    p, span{
        font-size: 14px;
    }
    .numbers.numbers-0 {
        position: absolute;
        top: 24px;
        left: 40px;
        right: 0;
        font-weight: bold;
        color: #57558E;
        letter-spacing: -2px;
        /* font-size: 14px; */
    }
    .date-layout{
        font-size: 10px;
        margin-top: 7% !important;
    }
    .number-img {
        height: 80px;
        width: 80px;
        margin-top: 20%;
        margin-left: 10%;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 20px;
    }

    .attending-position {
        font-size: 10px;
    }

    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 15px;
        /* width: 80% !important; */
    }
    .btn-impressions.btn-light.bt1-position {
        font-size: 14px !important;
        padding-top: 16%!important;
    }
    .btn-impressions.btn-light.btn2-position {
        width: 125%!important;
        font-size: 14px !important;
        padding-left: 15%!important;
        margin-left: -18px!important;
        height: 100%!important;
    }
    .btn-impressions.btn-light.btn3-position {
        width: 120%!important;
        font-size: 14px!important;
        margin-left: -13px!important;
        height:100%!important;
        padding-left: 20%!important;
    }
    .red-circle {
        margin-left: -100%;
        margin-top: 18px;
    }
    .red-circle img{
        width: 40%;
    }
    .click-blue-box6 {

        padding: 12px 15px;
        border-radius: 15px;
        width: 50%;
        margin-left: 33%;
        margin-top: 13%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .impressions-reservation-activities{
        /* margin-left: 34%; */
        font-weight: bold;
        font-size: 20px;
    }
        /* extra click modal css end */
    .c-box-6 {
        margin-left: -115%;
        width: 360%;
    }
    /* end modal */
}
@media only screen and (max-width: 385px){
    .time-layout {
        font-size: 10px !important;
        margin-top: 9% !important;
    }
    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 13px;
        /* width: 80% !important; */
    }
    button.btn-impressions.btn-danger-impressions {
        padding: 12px 30px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    button.btn-impressions.btn-success-impressions-green {
        padding: 12px 30px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    .btn-impressions.btn-light-accepted-declined {
        padding: 12px 20px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 25px;
    }
}
@media only screen and (max-width: 380px){
    .impression-sellect-hub-button{
        padding: 5px 10px !important;
        font-size: 11px !important;
        margin-top: 78%;
        width: 130%;
        margin-left: -30%;
    }
    .btn-success-impressions {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none !important;
        padding: 10px 75px !important;
        border-radius: 30px !important;
    }
    .button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 12px;
        /* width: 90% !important; */
    }
    .btn1{
        font-size: 13px !important;  
    }
    .btn2{
        font-size: 13px !important;  
    }
    .btn3{
        font-size: 13px !important;  
    }    
    .btn-impressions.btn-light.bt1-position {
        font-size: 13px !important;
    }
    .btn-impressions.btn-light.btn2-position {
        width: 125%!important;
        font-size: 13px !important;
        padding-left: 15%!important;
        margin-left: -18px!important;
        height: 100%!important;
    }
    .btn-impressions.btn-light.btn3-position {
        width: 120%!important;
        font-size: 13px!important;
    }
    .c-box-6 {
        margin-left: -115%;
        width: 360%;
        height: 500px;
        margin-top: -210%;
    }
}
@media only screen and (max-width: 365px){
    p, span {
        font-size: 11px;
        margin-top: 6px !important;
    }
    .d-b {
        margin-top: 3px;
    }
    img.number-img {
        margin-top: -10px;
    }
    .numbers {
        margin-top:-30px;
        margin-left: 25px;
    }
    /* .numbers{
        margin-top: -19px;
        margin-left: 3px;
    } */
    /* .button {
        padding: 8px 0px !important;
        width: 116%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 13px !important;
        margin-left: -15px;
    } */
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px !important;
    }
    .attending-position{
        margin-left: 10px !important;
    }
}
@media only screen and (max-width: 365px){
    .m-t {
        width: 36px;
        height: 36px;
        margin-top: 13px;
    }
    .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-3.full-w.img-text {
        margin-left: -10px;
        margin-top: 3px;
    }
    .btn-success-impressions {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none !important;
        padding: 10px 20px !important;
        border-radius: 30px !important;
        font-size: 14px !important;
    }
    .d-b {
        margin-left: -12px;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 7px !important;
    }
    .time-layout {
        font-size: 7px !important;
        margin-top: 16% !important;

    }
    .day-layout {
        font-size: 7px !important;
    }
    .attending-position {
        font-size: 9px;
    }
    button.btn-impressions.btn-info {
        width: 120% !important;
        height: 100%;
        font-size: 10px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -85%;
        width: 300%;
        height: 550px;
    }
    .pending-impressions-btn-light{
        width: 60%;
        font-size: 10px;
        margin-top: 1%;
    }
    .pending-impressions-btn-sellected{
        width: 60%;
        font-size: 10px;
        margin-top: 1%;
    }
    .impressions-reservation-activities{
        font-size: 13px;
    }
    /* end modal */
}
@media only screen and (max-width: 350px){

    .button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 12px;
        /* width: 100% !important; */
    }   
    .btn2{
        width: 135%!important;
    } 
    .btn-impressions.btn-light.bt1-position {
        font-size: 12px !important;
    }

    .btn-impressions.btn-light.btn2-position {
        width: 135%!important;
        font-size: 12px !important;
    }
    .btn-impressions.btn-light.btn3-position {
        width: 120%!important;
        font-size: 12px!important;
    }
    button.btn-impressions.btn-danger-impressions {
        padding: 8px 20px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    button.btn-impressions.btn-success-impressions-green {
        padding: 8px 20px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    .btn-impressions.btn-light-accepted-declined {
        padding: 8px 15px !important;
        font-size: 8px !important;
        margin-top: 5px!important;
    }
}
@media only screen and (max-width: 340px){
    .button-7 {
        padding: 8px 0px!important;
        width: 128%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 13px !important;
    }

    /* modal */
    .il-6 {
        top: -40px;
        left: 51%;
    }
    .modal-attending-icon{
        margin-left: 5%;
        margin-top: -3%;
    }

    .attending-6{
        margin-left: 5%;
    }

    .impressions-modal-logo-hub {
        width: 80px !important;
        height: 80px !important;
        border-radius: 50% !important;
        border: 5px solid #FFFAFA !important;
        object-fit: cover;
    }

    .modal.fade.bd-example-modal-lg {
        background: rgba(8, 8, 8, 0.9);
    }
    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: rgba(8, 8, 8, 0.1) !important;
        background-clip: padding-box;
        border: 1px solid  rgba(8, 8, 8, 0.1) !important;
        border-radius: .3rem;
        outline: 0;
    }
    .text-red-6 p{
        margin-top: -12px !important;
        margin-bottom: 5px;
    }
    .impressions-reservation-activities{
        font-size: 11px;
    }
    .c-box-6 {
        margin-left: -80%;
        height: 400px;
        width: 300%;
        margin-top: -180% !important;
    }
    .pending-impressions-btn-light{
        font-size: 9px;
        padding: 6px 10px;
    }
    .pending-impressions-btn-sellected{
        font-size: 9px;
        padding: 5px 10px;
    }
    .send-btn-6{
        margin-top: -10px;
    }
    .cancle-btn-6{
        margin-top: -10px;
    }
    /* end modal */
}
@media only screen and (max-width: 325px){
    .btn-impressions.btn-danger.cs-btn6, .btn-impressions.btn-primary.cs-btn6 {
        width: 6rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 30px;
    }
    .red-circle {
        margin-left: -100%;
        margin-top: 22px;
    }
    .red-circle img{
        width: 40%;
    }
    .numbers {
        margin-top:-30px;
        margin-left: 36px;
    }
}

@media only screen and (max-width: 316px){
    button.btn-impressions.btn-info {
        width: 140% !important;
        height: 100%;
        font-size: 9px !important;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -33px;
        width: 65px !important;
        left: 0px;
    }
    .footer-icon img {
        width: 50%;
    }
    /* .button {
        padding: 7px 0px !important;
        width: 132%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 9px !important;
        margin-left: -20px;
    } */
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px !important;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 6px !important;
    }
    .time-layout {
        font-size: 6px !important;
        margin-top: 20% !important;

    }
    .day-layout {
        font-size: 6px !important;
    }

    .attending-position {
        font-size: 8px;
    }
    button.btn-impressions.btn-danger-not-going-impressions {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 9px;
        /* width: 105% !important; */
    }
    .btn-impressions.btn-light.bt1-position {
        font-size: 10px !important;
        padding-top: 21%!important;
    }

    .btn-impressions.btn-light.btn2-position {
        width: 135%!important;
        font-size: 10px !important;
    }
    .btn-impressions.btn-light.btn3-position {
        width: 120%!important;
        font-size: 10px!important;
    } 
    button.btn-impressions.btn-danger-impressions {
        padding: 3px 15px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    button.btn-impressions.btn-success-impressions-green {
        padding: 3px 15px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    .btn-impressions.btn-light-accepted-declined {
        padding: 4px 10px !important;
        font-size: 6px !important;
        margin-top: 5px!important;
    }
}
@media only screen and (max-width: 300px){
    .button-7 {
        padding: 8px 10px!important;
        width: 135%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 290px){
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 40px !important;
        height: 40px !important;
    }
    .d-b {
        margin-left: -18px;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 6px !important;
        margin-left: -12% !important;
    }
    .time-layout {
        font-size: 6px !important;
        margin-top: 25% !important;
        margin-right: -17%;
    }
    .day-layout {
        font-size: 6px !important;
        margin-left: -12% !important;
    }

    .attending-position {
        font-size: 9px;
    }
    .feedback-btn {    
        width: 70% !important;
        margin-left:15%;
        font-size: 11px;
    }
    button.btn-impressions.btn-info {
        margin-left: -20px;
        width: 170% !important;
        height: 100%;
        font-size: 10px !important;
    }
    .btn-impressions.btn-light.bt1-position {
        font-size: 9px !important;
        padding-top: 25%!important;
    }

    .btn-impressions.btn-light.btn2-position {
        width: 135%!important;
        font-size: 9px !important;
    }
    .btn-impressions.btn-light.btn3-position {
        width: 120%!important;
        font-size: 9px!important;
    }
}



.select-hub-searvh-row{
    margin: auto;
}
.select-hub-all-items{
    width: 60%;
    margin: auto;
}
.select-hub-map-icon{
    background-color: #fff;
    width: 20px;
    height: 30px;
    border-radius: 11px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    padding: 8px 15px;
    box-sizing: content-box;
    /* width: 10%; */
    margin: auto;
    margin-top: 20px;
}
.select-hub-map-empty{
    width: 20px;
    height: 30px;
    /* border-radius: 11px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    padding: 8px 15px;
    box-sizing: content-box; */
    /* width: 10%; */
    margin: auto;
    margin-top: 20px;
}

.search-hub-search-box{
    z-index: 999999999999999999999999999999 !important;
    margin: auto !important;
    width: 100%;
    border-radius: 9px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    margin-top: 18px !important;
    margin-bottom: -100px !important;
    word-break: break-word;
    z-index: 11!important;
    margin: auto;
} 
/* Responsive css end */


@media only screen and (max-width: 550px){
    .select-hub-all-items{
        width: 100%;
        margin: auto;
        margin-bottom: 10%;
    }
    .select-hub-back-button{
        position: absolute;
        left: 2%; 
        top: 30px;   
}
}


@media only screen and (max-width: 350px){
    .select-hub-map-icon{
        width: 15px;
    }
    .search-hub-search-box{
        width: 80%;
    }   
}





/* filter modal */
.homepage-category-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 310px !important;
    margin-top: -155%;
    margin-left: -420%;
    width: 1000%;
}
.filter-category-title{
    position: relative;
    font-size: 27px;
    width: 85%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%; 
}
.homepage-category-clear-btn {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 2%;
    color: white!important;
    cursor: pointer;
    float: right;
}
.homepage-category-modal-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.homepage-category-modal-times-close-icon{
    margin-top: 6px;
    width:60%;
}
.homepage-filter-slider-layout{
    margin-top: 3%;
}
.homepage-filter-modal-frame {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.homepage-category-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

}
.homepage-category-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
/* end filter modal */

@media only screen and (max-width: 1500px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 300px !important;
        width: 800%;
        margin-left: -320%;
    }
}


@media only screen and (max-width: 992px){    
     /* filter modal */
     .homepage-category-modal-box {
        height: 250px !important;
        margin-top: -125%;
        margin-left: -220%;
        width: 600%;
    }

    .homepage-filter-modal-frame {
        width: 105px;
        height: 100px;
    }
 
    /* end filter modal */
}



@media only screen and (max-width: 767px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 220px !important;
        margin-top: -105%;
        margin-left: -180%;
        width: 500%;
    }
    .homepage-filter-modal-frame {
        width: 83px;
        height: 80px;
        margin-left: 14%;
    }
    /* end filter modal */
}

@media only screen and (max-width: 527px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 200px !important;
        margin-top: -75%;
        margin-left: -100%;
        width: 350%;
    }
    .homepage-category-clear-btn {
        background: linear-gradient(0deg, rgb(238, 234, 236) 0%, rgb(188, 188, 192) 60%);
        border: none !important;
        padding: 5px 20px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 16px !important;
        margin-top: 2%;
        color: rgb(51, 50, 50)!important;
        cursor: pointer;
        float: right;
    }
    .homepage-category-modal-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .homepage-category-modal-times-close-icon{
        margin-top: -12px;
        width:60%;
    }
    .homepage-filter-slider-layout{
        margin-top: 3%;
        width: 140%;
        margin-left: -20%;
    }
    .homepage-filter-modal-frame {
        width: 83px;
        height: 80px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .homepage-category-modal-logo{
        width: 100% !important;
        height: 100%;
        border:solid 10px ;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

    }
    .homepage-category-sellected-logo{
        width: 100% !important;
        height: 100%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .filter-category-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    /* end filter modal */
}



@media only screen and (max-width: 400px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 200px !important;
        margin-top: -75%;
        margin-left: -110%;
        width: 350%;
    }
    /* end filter modal */
}


@media only screen and (max-width: 361px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 165px !important;
        margin-top: -55%;
        margin-left: -85%;
        width: 300%;
    }
    .homepage-category-clear-btn {
        background: linear-gradient(0deg, rgb(238, 234, 236) 0%, rgb(188, 188, 192) 60%);
        border: none !important;
        padding: 5px 10px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 12px !important;
        margin-top: 2%;
        color: rgb(51, 50, 50)!important;
        cursor: pointer;
        float: right;
    }
    .homepage-category-modal-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .homepage-category-modal-times-close-icon{
        margin-top: -12px;
        width:60%;
    }
    .homepage-filter-slider-layout{
        margin-top: 3%;
        width: 140%;
        margin-left: -20%;
    }
    .homepage-filter-modal-frame {
        width: 62px;
        height: 60px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .homepage-category-modal-logo{
        width: 100% !important;
        height: 100%;
        border:solid 10px ;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

    }
    .homepage-category-sellected-logo{
        width: 100% !important;
        height: 100%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .filter-category-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    /* end filter modal */
}




















