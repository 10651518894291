.chat-room-recorder-bar{
    position: absolute;
    width: 60%;
    left:10%;
    height: 5%;
    top: 42%;
    border-radius: 30px !important;
    z-index: 9999!important;
    background-color: transparent;
    box-shadow: 5px 5px 20px rgba(0,0, 0, 0.4);
}

.chat-room-send-btn{
    position: absolute;
    width: 30%;
    right: 10%;
    /* height: 60px; */
    top: 42.25%;
    height: 4.5%;
    background-color: #1183ca;
    color: #fff;
    text-align: right;
    border-radius: 30px !important;
    font-size: 16px;
    font-weight: bold;
    z-index: 999;
    padding:5px 25px;
}

.chat-room-restart-btn{
    position: absolute;
    width: 50%;
    left: 0%;
    /* height: 60px; */
    bottom: 0%;
    background-color: #A9A9A9;
    color: #fff;
    text-align: center;
    border-radius: 0px !important;
    font-size: 20px;
    font-weight: bold;
    z-index: 9999;
    padding:10px 10px;
}


.chat-room-sound-wave{
    position: absolute;
    width: 100%;
    left: 0%;
    top: 53px;
    height: 10%;
    z-index: 9999999;
}

.chat-room-start-recording{
    z-index: 99;
    position: absolute;
    bottom: 0%;
    width: 100%;
    left: 0%;
    padding:10px 10px;
    background: #73ad64;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.chat-room-stop-recording{
    z-index: 99;
    position: absolute;
    top: 42%;
    width: 50%;
    left:25%;
    padding:5px 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    background: #d60c27;
    z-index: 99999;
}

.chat-room-recorder-display{
    width: 100%;
    height: 100%;
}

.chat-room-microphone-img{
    width: 100%;
    height: 100%;
    z-index: 999;
}
.chat-room-recorder-microphone{
    position: absolute;
    width:99%;
    left: 1%;
    height: 53%;    
    bottom: 17%;
    border-radius: 0px !important;
    /* background-color: gold; */
}

.chat-room-microphone-green-light{
    background-color: rgb(152, 231, 166); 
    border-radius: 50%; 
    width: 25px; 
    height: 25px; 
    display: block; 
    z-index: 1; 
    position: absolute; 
    top: 40%;
    left: 47.5%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    animation: glowing-animation 3s infinite;
    animation-fill-mode: forwards; 
}

.chat-room-microphone-green-light::before { 
    background-color: rgb(51, 153, 11); 
    content: ''; 
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    animation: glowing-animation 3s infinite;
    animation-fill-mode: forwards; 
    z-index: 0;
  }

  @keyframes glowing-animation {
	0% {
		transform: scale(1);        
	}

	50% {
		transform: scale(0.8);
	}

	100% {
		transform: scale(1);

	}
  }

.chat-room-microphone-red-light{
    background-color: rgb(241, 134, 134); 
    border-radius: 50%; 
    width: 25px; 
    height: 25px; 
    display: block; 
    z-index: 1; 
    position: absolute; 
    top: 40%;
    left: 47.5%;
}
.chat-room-microphone-red-light::before { 
    background-color: red; 
    content: ''; 
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    animation: burst-animation 3s infinite;
    animation-fill-mode: forwards; 
    z-index: 0;
  }
  @keyframes burst-animation {
	0% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(224, 146, 150, 0.1);
        
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 400px rgba(127, 111, 196, 0.1); 
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 400px transparent;
	}
  }

.chat-room-recorder-start{
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 49%;
    /* background-color: red; */
}
.chat-room-recorder-box{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
}