.homepage-map-link-btn{
    width:10%;
    height:100%;
    position: absolute;
    right: 16%;
    top: 0%;
}

.homepage-map-link-icon{
    width:90%;
    height: 50%;
    position: absolute;
    left: 5%;
    top: 23%;
}

.homepage-top-snap-memory-position{
    width:10%;
    height:100%;
    position: absolute;
    right: 31%;
    top: 0%;
    /* background-color: red; */
}

.homepage-new-message-bbubble{
    width:18px;
    height:18px;
    background-color: #dd3535;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    /* margin: auto; */
    margin-top: 15px;
    float: right;
    position: absolute;
    right: 20%;
    top: 18%;
}

.homepage-memory-top-backgroud-white{
    background-color: #fff;
    position: absolute;
    top: 0px !important;
    left: 0%;
    height: 40px;
    width: 100%;
}
.slider-single-content {
    border-radius: 50px!important;
}

.slider-left{
    opacity: 0%;
}

.slider-right{
    opacity: 0%;
}

.find-buddies-carouserl-layout{
    position: relative;
    top: 19%;
    left: 0%;
    width: 100%;
} 

.find-buddies-carouserl-box{
    width: 400px;
    height: 370px;
    border-radius: 50px!important;
    border: 1px solid rgb(233, 232, 232);
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 30px -20px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 30px 170px -20px rgba(45, 6, 104, 0.2); 
} 

.find-buddies-img-box{
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: 20px;
}

.find-buddies-img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%; 
    object-fit: cover;
}

.find-buddies-sugget-username{
    color: #575578;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.find-buddies-sugget-full-name{
    color: #575578;
    font-size: 15px !important;
    text-align: center;
}

.find-buddies-sugget-full-name-empty{
    color: transparent;
    font-size: 15px !important;
    text-align: center; 
}

.find-buddies-sugget-3-memories{
    width: 100%;
    height: 90px;
    /* background-color: tomato; */
    margin: auto;
    margin-top: 10px;
    z-index: 9999;
}

.find-buddies-sugget-3-memories-empty{
    width: 100%;
    height: 90px;
    /* background-color: tomato; */
    margin: auto;
    margin-top: 10px;
    z-index: 9999;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
}

.find-buddies-empty-photos-box{
    margin: auto;
    width: 18px;
    height: 18px;
    margin-top: 20px;
}

.find-buddies-no-memories-yet{
    color: #606062;
    font-size: 12px !important;
    font-weight: lighter;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 50px;
}

.find-buddies-empty-photos-icon{
    width: 100%;
    height: 100%;
}

.find-buddies-sugget-memory{
    width: 33.333%;
    height: 100%;
    z-index: 9999;
    border: 1px solid rgb(255, 255, 255);
}

.find-buddies-sugget-memory-size{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9999;
}

.find-buddies-sugget-memory-empty{
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
}

.find-buddies-sugget-add-btn{
    position: relative;
    width: 70%;
    height: 10%;
    margin: auto;
    margin-top: 20px;
    /* line-height: 125px; */
    font-weight: bold;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    color: white !important;
    text-align: center;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    cursor: pointer;
}

.find-buddies-sugget-requested-btn{
    position: relative;
    width: 70%;
    height: 10%;
    background: #fff;
    margin: auto;
    margin-top: 20px;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
}


.homepage-memory-find-buddies{
    background: #1183ca !important;
    border: none !important;
    padding: 5px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center;
    width: 70%;
    position: absolute;
    left: 15%;
    top: 300px;
}

.homepage-memory-find-buddies:active {
    transform: scale(0.88);
}

.homepage-memory-find-buddies:focus {
    transform: scale(0.88);
}

.homepage-memory-find-buddies:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.homepage-memory-request-reservation{
    background: #1183ca !important;
    border: none !important;
    padding: 5px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center;
    width: 70%;
    position: absolute;
    left: 15%;
    top: 400px;
}

.homepage-memory-request-reservation:active {
    transform: scale(0.88);
}

.homepage-memory-request-reservation:focus {
    transform: scale(0.88);
}

.homepage-memory-request-reservation:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}


.homepage-memory-new-user-icon-box{
    margin: auto;
    width: 90px;
    height: 90px;
    margin-top: 50px;
}

.homepage-memory-new-user-icon{
    width: 100%;
    height: 100%;
}

.homepage-memory-backgroud-new-user{
    position: absolute;
    width: 100%;
    height: 550%;
    background-color: rgb(255, 255, 255);
    top: -13%;
    left: 0%;
}

.homepage-memory-new-user-welcome{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top: 160px;
}

.homepage-memory-new-user-text{
    color: #575578;
    font-size: 16px !important;
    font-weight: lighter;
    text-align: center; 
    position: absolute;
    width: 83%;
    top: 200px;
    left: 10%;
}

.homepage-memory-new-user-or-text{
    color: #575578;
    font-size: 16px !important;
    font-weight: bolder;
    text-align: center; 
    position: absolute;
    width: 100%;
    top: 350px;
    left: 0%; 
}

.homepage-memory-new-user-welcome-carousel{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:10px;
}

.homepage-memory-new-user-text-carousel{
    color: #575578;
    font-size: 16px !important;
    font-weight: lighter;
    text-align: center; 
    position: absolute;
    width: 90%;
    top: 50px;
    left: 5%;
}

.homepage-suggested-memory-add-btn{
    width: 12%;
    font-size: 16px !important;
    text-align: center;
    padding: 4px 10px;
    margin-top: -8%;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: 2px solid #e7e7e7;
    color: white !important;
    border-radius: 30px!important;
    cursor: pointer;
    margin: auto; 
    position: absolute;
    top: 7%;   
    left: 44%;
    z-index: 1;
}

.homepage-suggested-memory-add{
    width: 30%;
    font-size: 12px !important;
    text-align: center;
    padding: 4px 10px;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: 2px solid #e7e7e7;
    color: white !important;
    border-radius: 2px!important;
    cursor: pointer;
    margin: auto; 
    position: absolute;
    left: 35%;
    top: 10%;
    z-index: 1;
}

.homepage-suggested-memory-requested{
    color: #575578!important;
    border: 1px solid #575578 !important;
    background-color: rgb(196, 192, 192) !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    width: 30%;
    font-size: 12px !important;
    text-align: center;
    padding: 4px 10px;
    font-weight: bold;
    border-radius: 2px!important;
    cursor: pointer;
    margin: auto; 
    position: absolute;
    left: 35%;
    top: 10%;
    z-index: 1;
}


.homepage-memory-tag-profile-pic-layout{
    position: relative;
    margin-right: 5px;
}
.homepage-memory-tag-profile-photo{
    width: 16px;
    height: 16px;
    border-radius: 50%;  
    border-bottom: 1px solid rgb(216, 215, 215);    
    object-fit: cover;
}

.homepage-suggested-memory-cancel-btn{
    width: 28%;
    font-size: 14px !important;
    text-align: center;
    padding: 4px 10px;
    margin-top: -8%;
    font-weight: bold;
    border-radius: 30px!important;
    cursor: pointer;
    margin: auto; 
    position: absolute;
    top: 9%;   
    left: 36%;
    z-index: 1;
    color: #575578!important;
    border: 1px solid #575578 !important;
    background-color: rgb(196, 192, 192) !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
}

.homepage-buddy-suggestion-visibility{
    display: none;
}

.homepage-memory-suggesting-mika{
    width: 20%;
    margin-left: 50%;
    margin-top: 15%;
}

.homepage-memory-suggesting-mika-img{
    width: 100%;
}

.homepage-memory-suggestion-text{
    color: #575578;    
    font-size: 20px;
    /* margin-top: -170px; */
    /* margin-bottom: 120px; */
    margin-left: 15%;
    width: 90%;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 5%;   
}

.homepage-memory-suggestion-title{
    color: #575578;    
    font-size: 30px;
    /* margin-top: -170px; */
    /* margin-bottom: 120px; */
    margin-left: 15%;
    width: 90%;
    text-align: center;
    margin-top: 2%;
}



.homepage-memory-maximize-btn{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 35px;
    height: 35px;
    position: absolute;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    right: 1%;
    top: 12%;
    z-index: 9;
}

.homepage-memory-tags-btn{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 35px;
    height: 35px;
    position: absolute;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    left: 1%;
    top: 12%;
    z-index: 9;
}

.homepage-memory-maximize-icon{
    width: 80%;
    margin: auto;
}
.homepage-memory-tag-icon{
    width: 80%;
    margin: auto;
}


.homepage-like-top-memory-badge{
    position: absolute;
    bottom: 5%;
    left: 80%;
    width: 18%;
    height: 84px;    
}
.homepage-reaction-heart-layout{
    position: absolute;
    left: 5%;
    bottom: 13%;
    width: 25px;
}
.homepage-reaction-heart-img{
    width: 100%;
}
.homepage-memory-like-number{
    position: absolute;
    left: 5%;
    bottom: 8%;
    color: rgb(41, 40, 40)!important;
}

.homepage-timeline-memories-layout{
    position: absolute;
    left: 0%;
}
.homepage-hub-reservations-available{
    position: relative;
    font-size: 12px;
    color: #000;
    text-align: center;
    margin: auto;
}
.homepage-top-all-tabs{
    width: 100%;
    height: 20%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 15px; 
}
.homepage-top-hub-all-tabs{
    width: 100%;
    height: 120px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 35px;
    /* background-color: brown; */
}
.homepage-top-tab-sellected{
    width: 30%;
    font-size: 20px !important;
    text-align: center;
    padding: 10px 5px;
    margin-top: -8%;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    color: white !important;
    border-radius: 30px!important;
    cursor: pointer;
    margin: auto;
}
.homepage-top-tab-not-sellected{
    width: 30%;
    font-size: 20px !important;
    text-align: center;
    padding: 10px 5px;
    margin-top: -8%;
    font-weight: bold;
    border: none;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    color: #575578!important;
    border-radius: 30px!important;
    cursor: pointer;
    margin: auto;
}


.homepage-memory-backgroud{
    position: relative;
    background-color: #fff;
    width: 80%;
    height: 570px !important;
    margin-top: 5% !important;
    left: 0%;
    margin-left: 20%;
}
.homepage-memory-item-size{
    width: 100%;
    margin: auto !important;
}
.homepage-memory-photo-item{    
    left: 0%;
    position: absolute;
    width: 100%;
    height: 100% !important;
    /* object-fit: cover!important; */
    object-fit: contain!important;
    margin-top: 75px;
    /* border-top: 1px solid rgb(194, 192, 192) !important; */
    /* border-bottom: 1px solid rgb(216, 215, 215); */
    /* box-shadow: 0 0 8px 8px white inset; */
    z-index: 1;
}
.homepage-memory-photo-item-cover{    
    left: 0%;
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover!important;
    object-fit: fill!important;
    /* object-fit: contain!important; */
    margin-top: 75px;
    border-top: 1px solid rgb(194, 192, 192) !important;
    border-bottom: 1px solid rgb(216, 215, 215);
    /* opacity: 0.8; */
    filter: blur(20px); 
    -webkit-filter: blur(20px);
}
.homepage-memory-profile-pic-layout{
    position: absolute;
    top: 1.5%;
    left: 0%;
    width: 60px;
    height: 60px;
}
.homepage-memory-profile-photo{
    width: 50px;
    height: 50px;
    border-radius: 50%;  
    margin-top: 5px;
    margin-left: 5px;
    border-bottom: 1px solid rgb(216, 215, 215);    
    object-fit: cover;
}
.homepage-memory-username-layout{
    position: absolute;
    /* background-color: brown; */
    color: #575578;    
    margin-left: 95px;
    margin-top: 13px;
    font-size: 20px;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.homepage-memory-username-layout-tag{
    position: absolute;
    /* background-color: brown; */
    color: #575578;    
    margin-left: 95px;
    margin-top: 1px;
    font-size: 20px;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.homepage-memory-username-tags-layout{
    position: absolute;
    /* background-color: brown; */
    color: #575578;    
    margin-left: 95px;
    margin-top: 25px;
    font-size: 14px;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.homepage-memory-hub_name-layout-tag{
    position: absolute;
    /* background-color: brown; */
    color: #575578;    
    margin-left: 95px;
    margin-top: 45px;
    font-size: 13px;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.homepage-memory-hub_name-layout{
    position: absolute;
    /* background-color: brown; */
    color: #575578;    
    margin-left: 95px;
    margin-top: 39px;
    font-size: 13px;
    font-family:Georgia, 'Times New Roman', Times, serif
}

.homepage-memory-comment-layout{
    position: absolute;
    /* background-color: brown; */
    color: rgb(41, 40, 40)!important;
    margin-left: 5px;
    top: 528px;
    left: 4%;
    /* font-size: 20px; */
    font-family:Georgia, 'Times New Roman', Times, serif
}
.homepage-memory-hub-logo-layout{
    position: absolute;
    top: 1.5%;
    right: 0%;
    width: 60px;
    height: 60px;
}
.homepage-memory-hub-logo-photo{
    width: 50px;
    height: 50px;
    border-radius: 50%;  
    margin-top: 5px;
    margin-left: 5px;
    border-bottom: 1px solid rgb(216, 215, 215);    
}

.homepage-top-memory-badge{
    position: absolute;
    bottom: 0%;
    left: 80%;
    width: 18%;
    height: 84px;
    /* background-color: brown; */
}
.homepage-top-memory-badge-icon{
    width: 30%;
    margin-top: 20px;
    margin-left: 20%;
}


.homepage-memory-all-impressions{
    position: absolute;
    bottom: 0%;
    left: 10%;
    width: 70%;
    height: 84px;
}
.homepage-memory-all-impressions-no-top{
    position: absolute;
    bottom: 0%;
    left: 12.5%;
    width: 90%;
    height: 84px;
    /* background-color: brown; */
}
.homepage-memory-impression-box{
    width: 25%;
    height: 84px;
}
.homepage-memory-impression-round-button{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 45px;
    height: 45px;
    position: relative;
    text-align: center;
    line-height: 37px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin-top: 17px;
    margin-left: 10%;
}
.homepage-memory-mika-impression-img{
    width: 70%;
    height: 70%;
    margin-left: 2px;
}
.homepage-mika-animation-index{
    position: absolute;
    z-index: 999999999;
    top: 10%;
}
.homepage-memory-reaction-mika-animation{
    /* position: absolute; */
    margin-left: 34%;
    margin-top: 40%;
    width:50%

}
.homepage-memory-mika-animation-size{
    width: 80%;
    z-index: 9999999999;

}
.homepage-memory-reactions-number{
    color: #575578;    
    text-align: center;    
    margin-left: 17%;
    margin-top: 30px;
}
.homepage-sellected-reaction-icon{
    width: 20%;
    margin-left: -8%;
    height: 84px;
}
.homepage-memory-sellected-impression-img{
    width: 50%;
    margin-top: 8px;
}







.homepage-hubs-loading-spinner{
    /* margin-left: 30% !important; */
    width: 100%;
    margin-bottom: 12%;
    margin-top: 1%;

}

.homepage-hubs-loading-spinner-size{
    width: 12%;
    margin-left: 44%;
}

/* GLobal css */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.blue-color{ 
    color: #575578;
}
.bg-white{
    background-color: #fff;
}
a{
    /* color: #fff; */ 
    text-decoration: none;
}

.homepage-hub-round-buttons{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 44px;
    height: 44px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
}

.homepage-hub-all-informations{
    width: 160%;
    margin-left: 0% !important;
}

.homepage-hub-information-item{
    margin-left: 6.5% !important;
    cursor: pointer;
}
.homepage-hub-information-imgs{
    width: 18px !important;
    height: 18px !important;
    margin-top: -105px;
    /* margin-top: -4px; */
    /* margin-right:15px; */
}
.traffic-badge-position{
    margin-top: 8% !important;
}
.congestion-item img{
    width: 87px; 
    /* margin-top: 40px; */
    margin-left: -20px;
}

/* loading */
.homepage-section-disappear{
    display: none;
}
.homepage-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.homepage-lottie-dissappear{
    display: none;
}
/* end loading */



#sticky-sidebar {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    padding-top: 20px;
} 
.body-right{
    margin-left: 16.7%;
}
.nav-bottom{
    display: none;
}
/* body css start */
.body-left{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
}
.logo{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic{
    padding-right: 0px;
    text-align: right;
}
.profile-picture-layout {
    border-radius: 30% !important;
    margin-top: -10px;
    width: 80px !important;
    height: 80px !important;
    margin-bottom: 30px!important;
}
.profile-text strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.left-icon img{
    width: 45%;
}
.homepage-search-top{
    border: none;
}
.arrange-btn-2 {
    border-radius: 40%;
}
.col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
    font-size: 16px;
    padding: 13px 13px;
    /* width: 100px; */
    border-radius: 30px !important;
}

/* body right css start */
.header-up{
    display: none;
} 
.body-right{
    background-color: #f0f0f0;    
}
input.homepage-search-top {
    margin-top: 6px;
    border-radius: 9px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    margin-top: 8px;
    width: 620px !important;
    word-break: break-word;
    z-index: 11!important;
}
.sizing-img{
    max-width: 48% !important;
}
.rotate-img1{
    transform: rotate(-15deg);
    z-index: 0;
}
.rotate-img2{
    transform: rotate(360deg);
    z-index: 99999;
}
.rotate-img3{
    transform: rotate(15deg);
    z-index: 111111;
}
.icon-image{
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 11px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    padding: 8px 15px;
    box-sizing: content-box;
}
.left-home{
    cursor: pointer; 
    transition: .4s;
}
.left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
.icon img{
    width: 20px;
} 
.friends-img{
    margin-left: 10px;
    margin-top: 30px !important;
}
.friend-name{
    text-align: center !important;
    background-color: #fff;
    padding: 1px 0px;
    border-radius: 0px 0px 14px 14px;
}
.col-custom{
    max-width: 10.5% !important;
    height: 150px;
}
.col-custom img{
    border-radius: 10px 10px 0px 0px;
}
.homepage-desktop-carousel-memory{
    object-fit: cover; 
    width: 100%;
}
.homepage-icon-search{
    /* position: relative; */
    width: 4%;
    margin-left: -50px !important;
    margin-top: 5px !important;
    z-index: 9999!important;
}
.homepage-map-icon-mobile{
    display: none;
}
.friends-img-item img{
    height: 80%;
    border-radius: 10px 10px 0px 0px;
    max-height: 100px;
}
.friends-img-next img{
    background-color: #fff;
    width: 15px;
    border-radius: 50%;
    float: right;
    margin-right: -15%;
    /* position: absolute; */
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    padding: 15px 22px;
    box-sizing: content-box;
    margin-top: -105px;
}
.friends-img-prev img{
    background-color: #fff;
    width: 15px;
    border-radius: 50%;
    float: left;
    margin-left: -16%;
    /* position: absolute; */
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    padding: 15px 22px;
    box-sizing: content-box;
    margin-top: 61px;
}
.location-icon{
    width: 13px;
}
.btn-danger-homepage-arrange{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 10px 35px !important;
    color: white !important;
    border: none;
    border-radius: 30px !important;
    font-size: 12px !important;
    margin-top: 10px;
}
.hubs-single-item .homepage-brand-logo img{
    border-radius: 50%;
    border: 10px solid #f0f0f0;
    object-fit: cover;
    margin: auto !important;
}

.homepage-hub-cover-photo{
    border-radius: 18px 18px 0px 0px;
    max-height: 136px !important;
    width: 100% !important;
    object-fit: cover;
}
.hubs-item-text{
    border-radius: 0px 0px 18px 18px;
}
/* .hubs-item{
    margin-left: -200px;
} */
.hubs-single-item{
    position: relative;
}
.homepage-brand-logo{
    width: 25%;
    position: absolute;
    top: -45px;
    left: 37.5%;
}
/* body right css end */

/* Footer css */
.footer a{
    color: #575578;
}
.feedback-btn a{
    color: #fff;
}
.img-bottom-border {
    border: 5px solid #FFFAFA;
    border-bottom: 40px solid #FFFAFA;
    border-radius: 5px;
    height: 180px;
}
/* body css end */

/* description Modal */
.homepage-description-box {
    /* margin-top: -155%;
    margin-left: -800%;
    width: 1800% !important; */
}
.descrition-modal-box{
    position: absolute;
    padding: 20px 40px 20px 40px!important;
    border-radius: 50px;
    background: rgb(254, 254, 255);
    background: linear-gradient(180deg, rgb(224, 234, 255) 0%, rgb(250, 234, 239) 100%); 
    height: 450px!important;
    margin-top: -200%;
    margin-left: -250%;
    width: 700% !important;
}
.description-modal-title{
    position: relative;
    font-size: 27px;
    font-weight: bold;
    width: 85%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-top: -10px; 
}
.homepage-hub-description-text{
    color:#3F1E75;
    font-size: 25px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: justify;
    margin-top: 20px;
}
/* end description Modal */
.homepage-hub-logo-size{
    width: 80px !important;
    height: 80px !important;
}
/* qr modal */
.qr-click-box {
    /* margin-top: -255%;
    margin-left: -450%;
    width: 1100% !important; */
}
.qr-box{
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -255%;
    margin-left: -250%;
    height: 600px!important;
    width: 550%;
}
.modal-back-arrow{
    position: relative;
    width: 2.5%;
    margin-bottom: -60px!important;
    float: left;
    z-index: 1 !important;
    margin-top: 12px;
    margin-right: 0px!important;
}
.qr-code-title{
    position: relative;
    font-size: 30px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 35%;
}
.camera-qr-code-title{
    position: relative;
    font-size: 27px;
    width: 85%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%;  
}
.camera-qr-code-subtitle{
    /* position: relative; */
    font-size: 11px;
    width: 85%;
    margin-top: 10px !important;
    /* margin-bottom: -8%; */
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%;  
}
.qr-image-size{
    width: 100%;
    margin-left: 0%!important;
}
.qr-code-scan-success{
    width: 400px !important;
    height: 400px !important;
    margin-top: 15%;
    margin-left: 10%; 
    margin-bottom: 7.5%;
}
.qr-scan-button{
    width: 50%;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 10px 45px;
    border-radius: 30px!important;
    color: white !important;
}

.homepage-city-country-text{
    color:#575578 !important
}
.camera-qr-code-title{
    position: relative;
    font-size: 27px;
    width: 85%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%;  
}
.qr-camera-laout{
    margin-left: 8%!important;
    margin-top: 13%;
    width: 85% !important;
    margin-bottom: 13px;
}
.qr-modal-back-icon{
    width: 3%;
    float: left;
    margin-left: 0% !important;
    margin-top: 2% !important;    
}
.times-modal-size{
    position: relative;
    width: 5%;
    margin-bottom: -60px!important;
    float: right;
    z-index: 1 !important;
    margin-top: 1%;
    cursor: pointer;
}
/* end qr modal */

/* map modal */
.map-modal-frame-radious2{
    border-radius: 50px !important;
}
.homepage-hub-name-size{
    width:150% !important;
}

.map-modal-map{
    border: none;
    border-radius: 50px !important;
    margin-left: -5%;
    margin-top:-2%;
    width: 109.7% !important;
    height: 103.4% !important;
}
/* end modal */

/* filter modal */
.homepage-category-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 310px !important;
    margin-top: -155%;
    margin-left: -420%;
    width: 1000%;
}
.filter-category-title{
    position: relative;
    font-size: 27px;
    width: 85%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%; 
}
.homepage-category-clear-btn {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 2%;
    color: white!important;
    cursor: pointer;
    float: right;
}
.homepage-category-modal-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.homepage-category-modal-times-close-icon{
    margin-top: 6px;
    width:60%;
}
.homepage-filter-slider-layout{
    margin-top: 3%;
}
.homepage-filter-modal-frame {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.homepage-category-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

}
.homepage-category-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
/* end filter modal */

@media only screen and (max-width: 1500px){
    /* filter modal */
    .homepage-category-modal-box {
        height: 300px !important;
        width: 800%;
        margin-left: -320%;
    }
}

/* media queires css start */
@media only screen and (max-width: 1390px){
    .body-left {
        height: 170vh !important;
    }
}

@media only screen and (max-width: 1200px){
    .homepage-suggested-memory-add-btn{
        top: 6%;   
    }

    .homepage-memory-suggesting-mika{
        width: 30%;
        margin:auto;
        margin-top: 3%;
    }
    
    .homepage-memory-suggesting-mika-img{
        width: 100%;
    }
    
    .homepage-memory-suggestion-text{
        color: #575578;    
        font-size: 25px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 90%;
        text-align: center;
        margin-top: 3%;
        margin-bottom: 8%;   
    }
    
    .homepage-memory-suggestion-title{
        color: #575578;    
        font-size: 50px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 90%;
        text-align: center;
        margin-top: 2%;
    }









    .homepage-buddy-suggestion-visibility{
        display: block;
        margin-top: 140px;
        margin-bottom: 40px;
    }

    .homepage-buddy-suggestion-title{
        color: #575578;    
        font-size: 16px;
        margin-top: -170px;
        margin-bottom: 120px;
        margin-left: 10px;
    }
    .homepage-buddy-suggestion-slider{
        width: 100%;
        margin-top: -100px;
    }
    .homepage-buddy-suggestion-box{
        background-color: #fff;
        width: 98%;
        height: 320px;
        padding-top: 5% !important;
        border-radius: 2px;
        border: 1px solid rgb(216, 214, 214);
        margin-left:10px;
    }
    .homepage-buddy-suggestion-profile{
        margin: auto;
        width:100%;
    }
    .homepage-buddy-suggestion-profile-pic{
        margin: auto;
        width:140px;
        height: 140px;
        border-radius: 50%;
        object-fit: cover;
        background-color: rgb(248, 252, 255);
        border: 1px solid rgb(216, 214, 214);
    }
    .homepage-buddy-suggestion-username{
        color: #575578;    
        font-size: 14px !important;
        text-align: center;
        font-weight: bold;
        margin: 10px;
    }
    .homepage-buddy-suggestion-add{
        width: 70%;
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 5px 15px !important;
        color: white !important;
        font-size: 20px !important;
        border-radius: 30px!important;
        text-align: center;
        margin: auto;
        margin-top: 30px;
        z-index:999
    }

    .homepage-buddy-suggestion-add:active {
        transform: scale(0.88);
    }
    
    .homepage-buddy-suggestion-add:focus {
        transform: scale(0.88);
    }
    
    .homepage-buddy-suggestion-add:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .homepage-buddy-suggestion-requested{
        width: 70%;
        padding: 5px 15px !important;
        font-size: 20px !important;
        border-radius: 30px!important;
        text-align: center;
        margin: auto;
        margin-top: 30px; 
        background: #fff !important;
        border: none !important;
        color: rgb(59, 59, 59) !important;
        border-radius: 2px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    }

    .homepage-buddy-suggestion-requested:active:focus:after {
        transform: scale(0.88);
    }
    
    /* .homepage-buddy-suggestion-add:focus {
        transform: scale(0.88);
    }
    
    .homepage-buddy-suggestion-add:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    } */

    .homepage-buddy-suggestion-cancel{
        color: rgb(207, 49, 49)!important;
        border: 1px solid rgb(207, 49, 49) !important;
        background-color: #fff !important;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        width: 70%;
        padding: 5px 15px !important;
        font-size: 20px !important;
        border-radius: 30px!important;
        text-align: center;
        margin: auto;
    }








    .homepage-memory-sellected-impression-img{
        margin-top: 0px;
    }
    .homepage-memory-backgroud{
        width: 100%;
        margin-left: 0%;
        height: 990px !important;
    }
    .homepage-memory-comment-layout{
        top: 923px;
    }
    .homepage-memory-photo-item{    
        height: 780px !important;
    }
    .homepage-memory-photo-item-cover{    
        height: 780px !important;

    }
    .homepage-input-icon-search{
        /* position: relative; */
        width: 4%;
        margin-left: -7%;
        margin-top: -0px;
        z-index: 1;
    }
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 161px !important;
        width: 100% !important;
        object-fit: cover;
    }
    .btn-danger-homepage-arrange{
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 10px 25px !important;
        color: white !important;
        border: none;
        border-radius: 30px !important;
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 1190px){
    .body-left {
        height: 200vh !important;
    }
}
@media only screen and (max-width: 1195px){
    .col-custom {
        max-width: 20.5% !important;
    }
    .body-left.col-lg-2 {
        font-size: 13px;
    }
    .col-lg-8.profile-text.mt-2 {
        margin-top: 0px !important;
    }
    .logo {
        padding: 30px 40px;
        margin-top: 20px;
        width: 155px;
    }

    .left-icon img {
        width: 75%;
        margin-top: 3px;
    }
    .col-lg-8.col-md-8.col-sm-8.hubs-text-left h3 {
        font-size: 25px;
    }
    span {
        font-size: 12px;
    }

    span {
        font-size: 10px;
    }
    span.blue-color.span-conges {
        font-size: 13px;
        margin-left: 6px;
    }
    .col-lg-4.col-md-4.col-sm-4.congestion-item.mt-5.hubs-text-right {
        margin-left: -20px;
    }
}
@media only screen and (max-width: 1120px){
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 60px solid #FFFAFA;
        border-radius: 5px;
        height: 300px;
    }
}
@media only screen and (max-width: 1200px){
    .hide-images{
        display: none;
    }
    .body-right{
        margin-left: 8%;
    }
    .notDisplay{
        display: none;
    }
    .friends-img{
        display: none;
    }
    .col-custom {
        max-width: 25.5% !important;
    }
    .header-up{
        display: block;
    }
    .header-up{
        padding: 45px 0px;
        background: transparent;
        /* background: linear-gradient(318deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%); */
        border-radius: 0px 0px 55px 55px;
        width: 100%;
        /* position: fixed !important; */
        /* position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important; */
    }
    .homepage-start-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }
    .homepage-logo-top-position{
        width:50%;
        height: 100%;
        /* background-color: red; */
        position: absolute;
        left: 0px;
        top: 0%;
    }
    .homepage-logo-top-img{
        /* width: 60%; */
        height: 70%;
        position: absolute;
        left: 5%;
        top: 15%;
        /* background-color: blue; */
    }
    .homepage-top-chat-position{
        width:10%;
        height:100%;
        position: absolute;
        right: 1%;
        top: 0%;
    }
    .homepage-top-chat-img{
        width:65%;
        height: 65%;
        position: absolute;
        left: 10%;
        top: 18%;
        /* background-color: rgb(255, 0, 255); */
    }

    .homepage-top-snap-memory-img{
        width:66%;
        height: 50%;
        position: absolute;
        left: 21%;
        top: 28%;
        /* background-color: blue; */
    }
    .homepage-whole-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .traffic-badge-position{
        margin-top: 10% !important;
        margin-left: 0% !important;
    }
}

@media only screen and (max-width: 1070px){
    input.homepage-search-top {
        margin-top: 6px;
        border-radius: 9px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
        box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        text-align: center;
        margin-top: 8px;
        width: 620px !important;
    }
}

@media only screen and (max-width: 1098px){
    input.homepage-search-top {
        margin-top: 6px;
        border-radius: 9px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
        box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        text-align: center;
        margin-top: 8px;
        width: 500px !important;
    }
}

@media only screen and (max-width: 1018px){
    .btn-danger-homepage-arrange {
        font-size: 12px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 50px solid #FFFAFA;
        border-radius: 5px;
        height: 240px;
    }
    /* description Modal */
    /* .homepage-description-box {
        margin-top: -105%;
        margin-left: -700%;
        width: 1500% !important;
    } */
    .descrition-modal-box{
        position: absolute;
        /* padding: 40px 80px 40px 80px!important; */
        border-radius: 50px;
        background: rgb(254, 254, 255);
        background: linear-gradient(180deg, rgb(224, 234, 255) 0%, rgb(250, 234, 239) 100%); 
        height: 300px!important;
        margin-left: -290%;
    }

    .homepage-hub-description-text{
        color: #3F1E75;
        font-size: 18px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        text-align: justify;
        margin-top: 30px;
    }
    /* end description Modal */
}
@media only screen and (max-width: 992px){
    .homepage-map-icon-big{
        display: none;
    }
    .homepage-map-icon-mobile{
        display: block;
    }
    .homepage-hub-all-informations{
        width: 160%;
        margin-left: 0% !important;
    }
    
    .homepage-hub-information-item{
        margin-left: 12% !important;
        cursor: pointer;
    }
    .traffic-badge-position{
        margin-top: 10% !important;
        margin-left: 0% !important;
    }
    .memory-images-size{
        width: 500px;
        height: 300px!important;
    }
    .homepage-input-icon-search{
        margin-left: 17%;
        margin-top: -34px;
    }

    .slider-layout{
        margin-top: -300px;
        margin-left: 40px;
        margin-bottom: -50px;
    }
    .footer{
        margin-bottom: 100px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        position: relative;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 250px;
    }
    .caption {
        position: relative !important;
        margin-top: 100px;
        margin-bottom: 5px;
        margin-left: 12px;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 30px;
        opacity:1;
      }
      .caption-position {
        position: absolute !important;
        margin-top: -55px;
        margin-left: 2px;
      }
    .nav-bottom{
         width: 93%; 
    } 
    .middle-icon-footer img {
        position: absolute;
        top: -30px;
        width: 92px !important;
        left: 30px;
    }
    .all-icon-footer{
        /* margin-bottom: 20px; */
    }
    .header-bg{
        background-color: #ffff;
    }
    .body-right{
        margin-left: 0%;
    }
    .body-left{
        display: none;
    }
    .homepage-whole-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .header-up{
        display: block;
    }
    .header-up{
        padding: 45px 0px;
        border-radius: 0px 0px 55px 55px;
    }
    .icon-small img{
        width: 32px;
    }
    .logo-small img{
        width: 155px;
    }
    .friends-img-slider{
        position: relative;
    }
    .friends-img-slider img{
        width: 50% !important;
        margin-top: 15%;
        margin-left: 5%;
        margin-bottom: -100px;
    }
    .back-img{
        position: absolute;
        top: 0;
        left: 10px;
        right: 0;
    }
    .size-image{
        max-width: 50% !important;
        position: absolute;
        top: 6px;
        left: 65px;
    }
    .third-img{
        max-width: 60% !important;
    }

    .small-screen-header{
        margin-top: 35px;
        padding-bottom: 20px;
    }
    .all-img{
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: 30px;
        right: 0;
    }

    .notDisplay{
        display: none;
    }
    .friends-img{
        display: none;
    }
    .middleItem{
        margin-bottom: 50px;        
    }
    .feedback-btn{
        margin-top: 30px !important;
    }
    .col-lg-2.footer-links.text-center span {
        font-size: 16px;
    }
    .col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
        font-size: 16px;
        padding: 13px 13px;
        width: 170px;
        border-radius: 40%;
    }
    .hubs-text {
        margin-top: -40px;
    }
    input.homepage-search-top {
        padding: 30px 25px !important;
    }
    img.icon-image.filter-img {
        /* max-width: 6%;
        position: absolute;
        top: -64px;
        right: 25px;
        height: 45px; */
    }
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -5px;
        right: 0;
        width: 122%;
    }

    img.back-img.size-image.text-center.img-thumbnail.rotate-img2 {
        width: 45% !important;
    }
    .rotate-img2 {
        width: 45% !important;
    }

    .size-image {
        max-width: 49% !important;
        position: absolute;
        top: 9px;
        left: 78px;
    }

    .footer{
        margin-bottom: 70px;
    }
    .b-n-icon-back img{
        width: 25px;
        margin-left: -55%;
        margin-top: 200px;
        padding-bottom: -80px;
        height: 50px;
        float: left;
        text-align: center !important;
        /* margin-top: 3rem; */
        cursor: pointer;
    }
    .b-n-icon-next img{
        width: 25px;
        height: 50px;
        margin-right: -25%;
        margin-top: -235px;
        float: right;
        text-align: center !important;
        /* margin-top: 3rem; */
        cursor: pointer;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -355%;
        margin-left: -400%;
        width: 900% !important; */
    }
    .qr-box{
        margin-top: -255%;
        margin-left: -250%;
        height: 600px!important;
        width: 550%;
    }
    .qr-code-title{
        margin-left: 40%;
    }
    .qr-image-size{
        width: 80%;
        margin-top: 40px;
        margin-left: 10%!important;
    }
    .qr-code-scan-success{
        margin-top: 20%;
        margin-left: 20%;
        margin-bottom: 10.4%;
    }
    /* end qr modal */
    /* description Modal */
    /* .homepage-description-box {
        margin-left: -400%;
        width: 875% !important;
    } */
    /* end description Modal */
    
     /* filter modal */
     .homepage-category-modal-box {
        height: 250px !important;
        margin-top: -125%;
        margin-left: -220%;
        width: 600%;
    }

    .homepage-filter-modal-frame {
        width: 105px;
        height: 100px;
    }
 
    /* end filter modal */
    input.homepage-search-top {
        width: 70% !important;
        position: absolute;
        top: -50px;
        left: 15%;
    }

    img.icon-image.filter-img {
        /* width: 45px; */
        position: absolute;
        top: -85px !important;
        right: 2%;
        /* height: 32px; */
    }
    .homepage-map-icon{
        position: absolute;
        top: -22px !important;
        margin-left: -1%;
        /* left: 2%; */
    }
}
@media only screen and (max-width: 827px){
 
    
    .homepage-memory-suggestion-text{
        color: #575578;    
        font-size: 25px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 90%;
        text-align: center;
        margin-top: 3%;
        margin-bottom: 8%;   
    }
    
    .homepage-memory-suggestion-title{
        color: #575578;    
        font-size: 40px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 100%;
        text-align: center;
        margin-top: 2%;
    } 
 
 
 
 
    .homepage-hub-information-item{
        margin-left: 10% !important;
    }

    .homepage-memory-backgroud{
        position: relative;
        background-color: transparent;
        width: 109% !important;
        height: 570px !important;
        margin-top: 5% !important;
        left: -4.5% !important;
    }
    .homepage-memory-comment-layout{
        top: 833px; 
    }
    .homepage-memory-item-size{
        width: 100%;
        margin: auto !important;
    }
    .homepage-memory-photo-item{    
        left: 0%;
        position: absolute;
        width: 100%;
        height: 380px !important;
        /* object-fit: cover!important; */
        object-fit: contain!important;
        margin-top: 75px;
        /* box-shadow: 0 0 8px 8px white inset; */
        /* border-top: 1px solid rgb(194, 192, 192) !important;
        border-bottom: 1px solid rgb(216, 215, 215); */
    }

    .homepage-memory-photo-item-cover{    
        left: 0%;
        position: absolute;
        width: 100%;
        height: 380px !important;
        object-fit: cover!important;
        /* object-fit: contain!important; */
        margin-top: 75px;
        border-top: 1px solid rgb(194, 192, 192) !important;
        border-bottom: 1px solid rgb(216, 215, 215);
    }

    .homepage-memory-backgroud{
        width: 100%;
        margin-left: 0%;
        height: 890px !important;
    }
    .homepage-memory-photo-item{    
        height: 680px !important;
    }

    .homepage-memory-photo-item-cover{    
        height: 680px !important;
    }

    .homepage-memory-profile-pic-layout{
        position: absolute;
        top: 1.5%;
        left: 0%;
        width: 60px;
        height: 60px;
    }
    .homepage-memory-profile-photo{
        width: 50px;
        height: 50px;
        border-radius: 50%;  
        margin-top: 5px;
        margin-left: 5px;
        border-bottom: 1px solid rgb(216, 215, 215);    
    }
    .homepage-memory-username-layout{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 13px;
        font-size: 20px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }
    .homepage-memory-hub_name-layout{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 39px;
        font-size: 13px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }


    .homepage-memory-username-layout-tag{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 1px;
        font-size: 20px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }
    .homepage-memory-username-tags-layout{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 23px;
        font-size: 18px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }
    .homepage-memory-hub_name-layout-tag{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 45px;
        font-size: 13px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }



    .homepage-memory-hub-logo-layout{
        position: absolute;
        top: 1.5%;
        right: 0%;
        width: 60px;
        height: 60px;
    }
    .homepage-memory-hub-logo-photo{
        width: 50px;
        height: 50px;
        border-radius: 50%;  
        margin-top: 5px;
        margin-left: 5px;
        border-bottom: 1px solid rgb(216, 215, 215);    
    }
    
    .homepage-top-memory-badge{
        position: absolute;
        bottom: 0%;
        left: 80%;
        width: 18%;
        height: 84px;
        /* background-color: brown; */
    }
    .homepage-top-memory-badge-icon{
        width: 30%;
        margin-top: 20px;
        margin-left: 20%;
    }
    .homepage-sellected-reaction-icon{
        width: 20%;
        margin-left: -8%;
        height: 84px;
    }
    .homepage-memory-sellected-impression-img{
        width: 50%;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 768px){
    .memory-images-size{
        height: 400px;
        width: 400px;
    }
    .slider-layout{
        margin-top: -290px;
        margin-left: 10px;
        margin-bottom: -50px;
    }
    .b-n-icon img{
        margin-top: 1.5rem;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 340px;
    }
    .caption {
        margin-bottom: 45px;
        margin-left: 55px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 30px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -55px;
        margin-left: 2px;
      }
    .size-image {
        max-width: 50% !important;
        position: absolute;
        top: 6px;
        left: 52px;
    }
    /* input.homepage-search-top {
        width: 70% !important;
        position: absolute;
        top: -55px;
        left: 50px;
    } */
    img.icon-image.filter-img {
        top: -80px !important;
    }

    span {
        font-size: 14px;
    }
    .btn-danger-homepage-arrange {
        padding: 8px 36px;
        font-size: 15px;
    }
    .span-conges {
        margin-left: 10px;
    }

    .friends-img-slider img {
        width: 67% !important;
    }
    .third-img {
        max-width: 70% !important;
    }
    .size-image {
        max-width: 54% !important;
        position: absolute;
        top: 10px;
        left: 52px;
    }
    input.homepage-search-top {
        padding: 24px !important;
    }
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -5px;
        right: 0;
        width: 122%;
    }
    .size-image {
        max-width: 54% !important;
        position: absolute;
        top: 13px;
        left: 63px;
    }

    .footer-width{
        width: 20% !important;
        margin-left: -5px;
    }

    .b-n-icon-back img{
        margin-left: -65%;
        margin-top: 245px;
        padding-bottom: -80px;
    }

    img.back-img.size-image.text-center.img-thumbnail.rotate-img2 {
        width: 48% !important;
    }
    .rotate-img2 {
        width: 48% !important;
    }

    /* qr modal */
    .qr-click-box {
        /* margin-top: -355%;
        margin-left: -350%;
        width: 800% !important; */
    }
    .qr-box{
        margin-top: -275%;
        margin-left: -200%;
        height: 700px!important;
        width: 550%;
    }
    .qr-code-title{
        margin-left: 36%;
    }
    .qr-image-size{
        width: 90%;
        margin-top: 40px;
        margin-left: 5%!important;
    }
    .qr-code-scan-success{
        margin-bottom: 6.8%;
    }
    .camera-qr-code-title{ 
        position: relative;
        font-size: 27px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    .qr-camera-laout{
        margin-left: 8%!important;
        margin-top: 13%;
        width: 85% !important;
        margin-bottom: 26px !important;
    }
    .qr-modal-back-icon{
        width: 3%;
        float: left;
        margin-left: 0% !important;
        margin-top: 1% !important;    
    }
    .times-modal-size{
        position: relative;
        width: 5%;
        margin-bottom: -60px!important;
        float: right;
        z-index: 1 !important;
        margin-top: 1%;
        cursor: pointer;
    }
    /* end qr modal */
    
    /* description Modal */
    .descrition-modal-box{
        position: absolute;
        border-radius: 50px;
        background: rgb(254, 254, 255);
        background: linear-gradient(180deg, rgb(224, 234, 255) 0%, rgb(250, 234, 239) 100%); 
        height: 250px!important;
        margin-top: -100%;
        margin-left: -250%;
        width: 630% !important;
    }
    .homepage-hub-description-text{
        color: #3F1E75;
        font-size: 15px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        text-align: justify;
    }
    .map-modal-frame-radious2{
        border-radius: 50px !important;
    }
    /* end description Modal */
    .homepage-top-all-tabs{
        margin-top: -20px;
    }
    .homepage-top-hub-all-tabs{
        height: 100px;
    }
}
@media only screen and (max-width: 767px){
    .memory-images-size{
        height: 340px;
        margin-top: 100px;
        width: 300px;
    }
    .caption-position {
        position: absolute !important;
        margin-top: -55px;
        margin-left: -40px;
      }
    .homepage-input-icon-search{
        margin-left: 17%;
        margin-top: -36px;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -275%;
        margin-left: -350%;
        width: 800% !important; */
    }
    .qr-box{
        height: 600px!important;
    }
    .camera-qr-code-title{
        width: 67%;
        font-size: 23px;
        margin-bottom: -8%;
        margin-left: 15%;  
    }
    .qr-camera-laout{
        margin-left: 10%!important;
        margin-top: 15%;
        margin-bottom: 24px;
        width: 80% !important;
    }
    .modal-back-arrow{
        width: 2.5%;
        margin-top: 9px;
        margin-right: 0px!important;
    }
    .qr-code-scan-success{
        width: 350px !important;
        height: 350px !important;
        margin-left: 12%;
        margin-bottom: 10.6%;
    }
    /* end qr modal */
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 131px !important;
        width: 100% !important;
        object-fit: cover;
    }


    /* filter modal */
    .homepage-category-modal-box {
        height: 220px !important;
        margin-top: -105%;
        margin-left: -180%;
        width: 500%;
    }
    .homepage-filter-modal-frame {
        width: 83px;
        height: 80px;
        margin-left: 14%;
    }
    /* end filter modal */
}

@media only screen and (max-width: 703px){
    .homepage-suggested-memory-add-btn{
        width: 15%;
        top: 10%;   
    }
    .homepage-hub-information-item{
        margin-left: 8% !important;
    }
    .homepage-memory-backgroud{
        height: 670px !important;
        width: 108.3% !important;
    }
    .homepage-memory-comment-layout{
        top: 633px;
        /* font-size: 12px; */
    }
    .homepage-memory-photo-item{    
        height: 470px !important;
    }
    .homepage-memory-photo-item-cover{    
        height: 470px !important;
    }
}
@media only screen and (max-width: 672px){
    .map-modal-frame-radious2{
        border-radius: 50px !important;
    }
    .memory-images-size{
        height: 300px;
        margin-top: -30px;
        width: 300px;
    }
    .slider-layout{
        margin-top: -165px;
        margin-left: 10px;
        margin-bottom: -80px;
    }
    .size-image {
        max-width: 54% !important;
        position: absolute;
        top: 13px;
        left: 58px;
    }

    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 260px;
    }
    .caption {
        margin-bottom: 75px;
        margin-left: 11px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 30px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -45px;
        margin-left: 10px;
      }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 220px;
    }
    img.icon-image.filter-img {
        top: -74px !important;
    }
    .b-n-icon-back img{
        margin-left: -65%;
        margin-top: 155px;
        padding-bottom: -80px;
    }
    /* .b-n-icon-next img{
        margin-right: -55%;
        margin-top: -195px;
    } */
    .nav-bottom{
        width: 90%; 
   } 
}
@media only screen and (max-width: 660px){
    .memory-images-size{
        height: 280px;
        width: 280px;
    }
    .slider-layout{
        margin-top: -150px;
        margin-bottom: -30px;
    }
    img.icon-image.filter-img {
        /* max-width: 6%;
        position: absolute;
        top: -64px;
        right: 36px;
        height: 32px; */
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 260px;
    }
    .caption {
        margin-bottom: 35px;
        margin-left: 11px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 30px;
        opacity:1;
      }

    span {
        font-size: 12px;
    }
    .col-lg-4.col-md-4.col-sm-4.congestion-item.mt-5 {
        margin-left: -17px;
    }
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -5px;
        right: 0;
        width: 122%;
    }
    .size-image {
        max-width: 54% !important;
        position: absolute;
        top: 12px;
        left: 58px;
    }
    .size-image {
        max-width: 54% !important;
        position: absolute;
        top: 13px;
        left: 51px;
    }
    .b-n-icon-back img{
        margin-left: -65%;
        margin-top: 155px;
        padding-bottom: -80px;
    }
    /* .b-n-icon-next img{
        margin-right: -55%;
        margin-top: -200px;
    } */
}
@media only screen and (max-width: 630px){
    .b-n-icon-back img{
        margin-left: -65%;
        margin-top: 155px;
        padding-bottom: -80px;
    }

    /* description modal */
    .descrition-modal-box{
        height: 260px!important;
        margin-top: -100%;
        margin-left: -210%;
        width: 550% !important;
    }
    .homepage-hub-description-text{
        margin-top: 20px;
    }
    /* end description modal */
}

@media only screen and (max-width: 600px){
    .homepage-memory-sellected-impression-img{
        width: 60%;
        margin-top: 15px;
    }
    .homepage-top-memory-badge-icon{
        width: 40px;
    }
    .caption {
        margin-bottom: 60px;
        margin-left: 10px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 25px;
        opacity:1;
      }
    .homepage-input-icon-search{
        width: 5%;
    }
    .caption-position {
        position: absolute !important;
        margin-top: -45px;
        margin-left: 2px;
      }
    .memory-images-size{
        height: 300px;
        width: 300px;
    }
    .slider-layout{
        margin-top: -130px;
        margin-bottom: -70px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 220px;
    }
    /* input.homepage-search-top {
        width: 71% !important;
        position: absolute;
        top: -55px;
        left: 35px;
    } */
    span {
        font-size: 11px;
    }
    .b-n-icon-back img{
        margin-left: -65%;
        margin-top: 155px;
        padding-bottom: -80px;
    }
    /* description Modal */
    /* .homepage-description-box {
        margin-top: -105%;
        margin-left: -300%;
        width: 700% !important;
    } */
    .descrition-modal-box{
        /* padding: 40px 40px 30px 40px!important; */
        height: 260px!important;
    }
    .homepage-hub-description-text{
        font-size: 15px;
    }
    /* end description modal */
    .homepage-hub-information-item{
        margin-left: 6% !important;
    }
    .homepage-top-hub-all-tabs{
        height: 150px;
        margin-top: -65px !important;
        /* background-color: brown; */
    }
}

@media only screen and (max-width: 576px){

    .homepage-hub-positions{
        margin-left: -6%;
    }
    .memory-images-size{
        height: 300px;
        width: 350px;
    }
    .slider-layout{
        margin-top: -220px;
        margin-bottom: 0px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 260px;
    }
    .caption {
        margin-bottom: -0px;
        margin-left: 40px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 22px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -40px;
        margin-left: 0px;
      }

    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 280px;
    }
    .icon-small{
        max-width: 25%;
    }
    .logo-small{
        max-width: 50%;
        z-index: 1 !important;
    }
    .b-n-icon{
        max-width: 20%;
    }
    .friends-img-slider{
        /* It fucks the bottom navbar up */
        max-width: 50%;
    }
    .b-n-icon img {
        margin-top: 1.3rem;
        width: 22px;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 46px;
    }
    span {
        font-size: 16px;
    }
    .hubs-text-left{
        max-width: 70%;
    }
    .hubs-text-right{
        max-width: 30%;
    }
    .span-conges {
        margin-left: 0px;
    }
    .hubs-item-margin{
        margin-bottom: 55px;
    }
    .col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
        max-width: 51%;
        /* margin: auto; */
        border-radius: 40%;
    }
    .size-image {
        max-width: 55% !important;
        position: absolute;
        top: 8px;
        left: 49px;
    }
    .footer-width{
        width: 20% !important;
    }
    .footer-icon img {
        width: 30%;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -45px;
        max-width: 75px !important;
        left: 15px !important;
    }
    .footer-icon img {
        width: 28%;
    }
    .b-n-icon-back img{
        margin-left: -60%;
        margin-top: 200px;
        padding-bottom: -80px;
    }
    .arrange-it-button-layout{
        /* margin: auto !important; */
        width: 100%;
        /* margin-left: 40% !important; */
    }
    .btn-danger-homepage-arrange {
        border: none;
        width: 60% !important;
        border-radius: 30px !important;
        font-size: 12px !important;
        
    }

    /* qr modal */
    .qr-click-box {
        /* margin-top: -275%;
        margin-left: -300%;
        width: 700% !important; */
    }
    .qr-box{
        margin-top: -275%;
        margin-left: -190%;
        height: 600px!important;
        width: 500%;
    }
    .qr-code-title{
        margin-left: 30%;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 40px;
        margin-left: 0%!important;
    }
    .times-modal-size{
        margin-top: 5px;
    }
    .modal-back-arrow{
        width: 3%;
        margin-top: 0px;
        margin-right: 0px!important;
    }
    .qr-code-scan-success{
        width: 340px !important;
        height: 340px !important;
        margin-top: 25%;
        margin-left: 8%;
        margin-bottom: 9.6%;
    }
    .camera-qr-code-title{
        position: relative;
        font-size: 22px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    .qr-camera-laout{
        margin-left: 8%!important;
        margin-top: 20%;
        width: 85% !important;
        margin-bottom: 29px !important;
    }
    .qr-modal-back-icon{
        width: 3%;
        float: left;
        margin-left: 0% !important;
        margin-top: 1% !important;    
    }
    .times-modal-size{
        position: relative;
        width: 5%;
        margin-bottom: -60px!important;
        float: right;
        z-index: 1 !important;
        margin-top: 1%;
        cursor: pointer;
    }
    /* end qr modal */
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 247px !important;
        width: 100% !important;
        object-fit: cover;
    }
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 5% !important;
    }

    .homepage-hub-information-item{
        margin-left: 12.5% !important;
        cursor: pointer;
    }
    .homepage-sellected-reaction-icon{
        width: 20%;
        margin-left: -8%;
        height: 84px;
    }
    .homepage-memory-sellected-impression-img{
        width: 55px;
        margin-top: 11px;
    }
}

@media only screen and (max-width: 537px){
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 5% !important;
    }

    .homepage-hub-information-item{
        margin-left: 11% !important;
        cursor: pointer;
    }
    .homepage-map-icon{
        top: -24px !important;
    }
}
@media only screen and (max-width: 527px){
    .map-modal-frame-radious2{
        border-radius: 50px !important;
    }
    .memory-images-size{
        height: 300px;
        width: 300px;
    }
    .caption {
        margin-bottom: -15px;
        margin-left: 30px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -38px;
        margin-left: -10px;
      }
    .size-image {
        max-width: 55% !important;
        position: absolute;
        top: 8px;
        left: 52px;
    }
    .slider-layout{
        margin-top: -180px;
        margin-bottom: -20px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 250px;
    }
    .b-n-icon-back img{
        margin-left: -60%;
        margin-top: 180px;
        padding-bottom: -80px;
    }
    /* map modal */
    .map-modal-map{
        border: none;
        border-radius: 50px !important;
        margin-left: -5%;
        margin-top:-2%;
        width: 110%;
        height: 103.4% !important;
        
    }
    /* end map modal*/

    /* filter modal */
    .homepage-category-modal-box {
        height: 200px !important;
        margin-top: -75%;
        margin-left: -100%;
        width: 350%;
    }
    .homepage-category-clear-btn {
        background: linear-gradient(0deg, rgb(238, 234, 236) 0%, rgb(188, 188, 192) 60%);
        border: none !important;
        padding: 5px 20px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 16px !important;
        margin-top: 2%;
        color: rgb(51, 50, 50)!important;
        cursor: pointer;
        float: right;
    }
    .homepage-category-modal-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .homepage-category-modal-times-close-icon{
        margin-top: -12px;
        width:60%;
    }
    .homepage-filter-slider-layout{
        margin-top: 3%;
        width: 140%;
        margin-left: -20%;
    }
    .homepage-filter-modal-frame {
        width: 83px;
        height: 80px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .homepage-category-modal-logo{
        width: 100% !important;
        height: 100%;
        border:solid 10px ;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

    }
    .homepage-category-sellected-logo{
        width: 100% !important;
        height: 100%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .filter-category-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    /* end filter modal */
    
    /* description modal */
    .descrition-modal-box{
        margin-top: -100%;
        margin-left: -160%;
        width: 450% !important;
        height: 320px !important;
    }

    /* end description modal */
    /* .homepage-hub-promo-impretions-postition{
        position: relative;
        margin-top: 620px;
    } */
}
@media only screen and (max-width: 490px){
    .homepage-buddy-welcome-box{
        background-color: #fff;
        width: 98%;
        height: 250px;
        padding-top: 5% !important;
        border-radius: 2px;
        border: 1px solid rgb(216, 214, 214);
        margin-left:10px;
    }




    .homepage-suggested-memory-add-btn{
        width: 30%;  
        left: 35%;
        z-index: 1;
    }
    .homepage-memory-username-tags-layout{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 28px;
        font-size: 10.5px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }

    .homepage-memory-tag-profile-pic-layout{
        position: relative;
        margin-right: 5px;
    }
    .homepage-memory-tag-profile-photo{
        width: 14px;
        height: 14px;
        border-radius: 50%;  
        border-bottom: 1px solid rgb(216, 215, 215);    
        object-fit: cover;
    }

    .homepage-memory-suggesting-mika{
        width: 35%;
        margin:auto;
        margin-top: 3%;
    }
    
    .homepage-memory-suggesting-mika-img{
        width: 100%;
    }
    
    .homepage-memory-suggestion-text{
        color: #575578;    
        font-size: 15px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 50%;
        text-align: center;
        margin-top: 3%;
        margin-bottom: 8%;   
    }
    
    .homepage-memory-suggestion-title{
        color: #575578;    
        font-size: 20px;
        font-weight: bold;
        /* margin-top: -170px; */
        /* margin-bottom: 120px; */
        margin: auto;
        width: 90%;
        text-align: center;
        margin-top: 16%;
        margin-bottom: 18%;   

    }
    
    
    
    .homepage-buddy-suggestion-title{
        color: #575578;    
        font-size: 16px;
        margin-top: -170px;
        margin-bottom: 120px;
        margin-left: 10px;
    }
    .homepage-buddy-suggestion-slider{
        position: relative;
        width: 107%;
        margin-top: -100px;
        left: 0%;
        margin-left: -4%;
    }
    .homepage-buddy-suggestion-box{
        background-color: #fff;
        width: 98%;
        height: 250px;
        padding-top: 5% !important;
        border-radius: 2px;
        border: 1px solid rgb(216, 214, 214);
        margin-left:10px;
    }
    .homepage-buddy-suggestion-profile{
        margin: auto;
        width:100%;
    }
    .homepage-buddy-suggestion-profile-pic{
        margin: auto;
        width:140px;
        height: 140px;
        border-radius: 50%;
        object-fit: cover;
        background-color: rgb(248, 252, 255);
        border: 1px solid rgb(216, 214, 214);
    }
    .homepage-buddy-suggestion-username{
        color: #575578;    
        font-size: 14px !important;
        text-align: center;
        font-weight: bold;
        margin: 10px;
    }
    .homepage-buddy-suggestion-add{
        padding: 6px 15px !important;
        font-size: 18px !important;
        border-radius: 2px!important;
        text-align: center;
        width: 94%;
        margin-top: 22px;
    }

    .homepage-buddy-suggestion-requested{
        padding: 6px 15px !important;
        font-size: 18px !important;
        border-radius: 2px!important;
        text-align: center;
        width: 94%;
        margin-top: 22px;
        z-index: 999;
    }


    .homepage-buddy-suggestion-cancel{
        color: rgb(207, 49, 49)!important;
        border: 1px solid rgb(207, 49, 49) !important;
        background-color: #fff !important;
        -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
        width: 70%;
        padding: 5px 15px !important;
        font-size: 20px !important;
        border-radius: 30px!important;
        text-align: center;
        margin: auto;
    }
    
    
    
    
    
    .homepage-below-section-memories{
        position: relative;
        background-color: rgb(251, 251, 251);
        top: 20px!important;
    }
    .memory-images-size{
        margin-top: -20px;
        height: 250px;
        width: 350px;
        margin-left: -14px;
    }
    input.homepage-search-top {
        max-width: 60% !important;
        left: 20%;
    }
    .slider-layout{
        margin-top: -170px;
        margin-bottom: -30px;
    }
    .caption {
        margin-bottom: 20px;
        margin-left: 19px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 25px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -45px;
        margin-left: 2px;
      }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 220px;
    }
    .back-img {
        position: absolute;
        top: 1px;
        left: 4px;
        right: 0;
    }
    .back-img {
        position: absolute;
        top: 1px;
        left: 4px;
        right: 0;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 37px;
    }
    .friends-img-slider img {
        width: 61% !important;
    }
    .b-n-icon img {
        margin-top: 0.3rem;
        width: 18px;
    }
    .logo-small img {
        width: 125px;
        margin-left: 20px;
    }
    .header-up {
        height: 40px;
        padding: 1px 0px 65px 0px;
        margin-bottom: 0px;
        border-radius: 0px 0px 0px 0px;
    }
    .icon-small img {
        width: 26px;
    }
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -7px;
        right: 0;
        width: 136%;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 5px;
        left: 48px;
    }
    .b-n-icon img {
        margin-top: 1.9rem;
        width: 18px;
    }
    .footer-icon img {
        width: 33% !important;
    }
    .b-n-icon-back img{
        margin-left: -55%;
        margin-top: 155px;
        padding-bottom: -80px;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -220%;
        margin-left: -250%;
        width: 600% !important; */
    }
    .qr-box{
        margin-top: -205%;
        margin-left: -140%;
        width: 400%;
        height: 470px!important;
    }
    .qr-code-title{
        margin-left: 30%;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 20px;
        margin-left: 0%!important;
    }
    .qr-code-scan-success{
        width: 250px !important;
        height: 250px !important;
        margin-top: 20%;
        margin-left: 15%;
        margin-bottom: 13.8%;
    }
    .qr-scan-button{
        width: 70%;
        padding: 10px 45px;
    }
    .camera-qr-code-title{
        position: relative;
        font-size: 18px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    .qr-camera-laout{
        margin-left: 8%!important;
        margin-top: 17%;
        width: 85% !important;
        margin-bottom: 31px !important;
    }
    .qr-modal-back-icon{
        width: 3%;
        float: left;
        margin-left: 0% !important;
        margin-top: 1% !important;    
    }
    .times-modal-size{
        position: relative;
        width: 5%;
        margin-bottom: -60px!important;
        float: right;
        z-index: 1 !important;
        margin-top: 1%;
        cursor: pointer;
    }
    /* end qr modal */
    
    /* description Modal */
    /* .homepage-description-box {
        margin-top: -205%;
        margin-left: -230%;
        width: 550% !important;
    } */
    .descrition-modal-box{
        /* padding: 40px 40px 30px 40px!important; */
        height: 320px!important;
    }
    .homepage-hub-description-text{
        font-size: 15px;
    }
    /* end description modal */
    /* map modal */
    .map-modal-map{
        border: none;
        border-radius: 50px !important;
        margin-left: -5%;
        margin-top:-2%;
        width: 111.5%;
        height: 103.4% !important;
        
    }
    /* end map modal*/
    .homepage-input-icon-search{
        margin-left: 22%;
        margin-top: -36px;
    }


    .homepage-top-tab-sellected{
        width: 40%;
        font-size: 18px !important;
        padding: 7px 5px;
    }
    .homepage-top-tab-not-sellected{
        width: 40%;
        font-size: 18px !important;
        padding: 7px 5px;
    }

}


@media only screen and (max-width: 445px){
    .homepage-hub-positions{
        margin-left: -8%;
    }
    .memory-images-size{
        height: 200px;
        margin-left: 2px;
        margin-top: -10px;
        width: 250px;
    }
    .caption {
        margin-bottom: -10px;
        margin-left: 5px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
    }
    .caption-position {
        position: absolute !important;
        margin-top: -40px;
        margin-left: -10px;
      }
    .slider-layout{
        margin-top: -150px;
        margin-bottom: -10px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 200px;
    }
    .col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
        max-width: 65%;
        /* margin: auto; */
        border-radius: 40%;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 45px;
    }

    .footer-icon img {
        width: 40%;
    }
    .b-n-icon-back img{
        margin-left: -55%;
        margin-top: 130px;
        padding-bottom: -80px;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -200%;
        margin-left: -200%;
        width: 500% !important; */
    }
    .qr-box{
        margin-top: -205%;
        margin-left: -135%;
        width: 400%;
        height: 470px!important;
    }
    .qr-code-title{
        margin-left: 30%;
        font-size: 20px;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 20px;
        margin-left: -1%!important;
    }
    .qr-scan-button{
        width: 70%;
    }
    .camera-qr-code-title{
        width: 70%;
        font-size: 16px;
        margin-bottom: -8%;
        margin-left: 10%;  
    }
    .qr-camera-laout{
        margin-top: 15%;
        margin-bottom: 26px; 
    }
    .qr-code-scan-success{
        width: 210px !important;
        height: 210px !important;
        margin-top: 25%;
        margin-left: 11%;
        margin-bottom: 15.4%;
    }
    /* end qr modal */
    .map-modal-map{
        border: none;
        border-radius: 50px !important;
        margin-left: -5%;
        margin-top:-2%;
        width: 112.5%;
        height: 103.4% !important;
        
    }
    /* end map modal*/


    /* description modal */
    .descrition-modal-box{
        margin-top: -150%;
        margin-left: -135%;
        width: 400% !important;
    }
    .homepage-hub-description-text{
        margin-top: 10px !important;
    }
    /* end description modal */
    .homepage-hub-information-item{
        margin-left: 10% !important;
    }
}
@media only screen and (max-width: 420px){
    .map-modal-frame-radious2{
        border-radius: 50px !important;
    }
    .caption {
        margin-bottom: -10px;
        margin-left: -5px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
      .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 184px !important;
        width: 100% !important;
        object-fit: cover;
    }
    /* description Modal */
    /* .homepage-description-box {
        margin-top: -205%;
        margin-left: -180%;
        width: 450% !important;
    } */
    .descrition-modal-box{
        /* padding: 40px 40px 30px 40px!important; */
        height: 320px!important;
    }
    .homepage-hub-description-text{
        font-size: 14px;
    }
    /* end description modal */
}
@media only screen and (max-width: 400px){
    .homepage-memory-new-user-text{
        font-size: 14px !important;
    }
    .homepage-below-section-memories{
        top: 0px!important;
    }
    .qr-box{
        margin-top: -205%;
        margin-left: -125%;
        width: 380%;
        height: 470px!important;
    }
    .camera-qr-code-subtitle{
        font-size: 11px;
        margin-top: 20px !important;
    }

    /* filter modal */
    .homepage-category-modal-box {
        height: 200px !important;
        margin-top: -75%;
        margin-left: -110%;
        width: 350%;
    }
    /* end filter modal */
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 5% !important;
    }

    .homepage-hub-information-item{
        margin-left: 9% !important;
    }
    .homepage-map-icon{
        top: -26px !important;
        margin-left: -1.5%;
    }
}
@media only screen and (max-width: 380px){
    .middle-icon-footer img {
        position: absolute;
        top: -45px;
        width: 92px !important;
        left: 5px !important;
    }
    /* .caption {
        margin-bottom: -0px;
        margin-left: -15px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      } */
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 180px;
    }
    .footer-icon img {
        width: 45% !important;
    }
    .b-n-icon-back img{
        margin-left: -55%;
        margin-top: 80px;
        padding-bottom: -80px;
    }
    /* .b-n-icon-next img{
        margin-right: -30%;
        margin-top: -80px;
    } */
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 165px !important;
        width: 100% !important;
        object-fit: cover;
    }
    /* description modal */
    .descrition-modal-box{
        /* margin-top: -100%; */
        margin-left: -115%;
        width: 360% !important;
        height: 330px!important;
    }
    /* end description modal */
    img.icon-image.filter-img {
        top: -70px !important;
    }
    .find-buddies-carouserl-box{
        height: 350px;
    } 
}
@media only screen and (max-width: 390px){
    .homepage-new-message-bbubble{
        width:16px;
        height:16px;
        line-height: 16px;
    }
    
    .homepage-memory-backgroud-new-user{
        top: -10%;
    }
    .memory-images-size{
        height: 170px;
        margin-left: 3px;
        width: 220px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 170px;
    }
    .slider-layout{
        margin-top: -100px;
        margin-bottom: -25px;
    }
    /* input.homepage-search-top {
        width: 71% !important;
        position: absolute;
        top: -55px;
        left: 18px;
    } */
    .caption {
        margin-bottom: -5px;
        margin-left: -15px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .b-n-icon-back img{
        margin-left: -55%;
        margin-top: 110px;
    }
    /* .b-n-icon-next img{
        margin-right: -35%;
        margin-top: -106px;
    } */
    .homepage-input-icon-search{
        width: 6%;
    }
}

@media only screen and (max-width: 376px){
    .find-buddies-carouserl-layout{
        top: 18%;
    } 
    .memory-images-size{
        height: 170px;
        margin-left: 20px;
        width: 200px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 30px solid #FFFAFA;
        border-radius: 5px;
        height: 170px;
    }
    .slider-layout{
        margin-top: -100px;
        margin-bottom: -30px;
        margin-left: -15px;
    }
    .caption {
        margin-bottom: -10px;
        margin-left: -14px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -32px;
        margin-left: -10px;
      }
    /* .friends-img-slider {
        max-width: 60%;
        margin-left: -7px !important;
    } */
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: 0px;
        right: 0;
        width: 142%;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 38px;
    }
    input.homepage-search-top {
        padding: 22px 10px !important;
    }
    .b-n-icon-back img{
        margin-left: -45%;
        margin-top: 120px;
        height: 30px;
        margin-right: -30px;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -200%;
        margin-left: -175%;
        width: 450% !important; */
    }
    .qr-box{
        margin-top: -205%;
        margin-left: -125%;
        width: 380%;
        /* height: 490px!important; */
        height: 470px!important;
    }
    .qr-code-title{
        margin-left: 30%;
        font-size: 20px;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 20px;
        margin-left: 0%!important;
    }
    .camera-qr-code-title{
        font-size: 13px;
        margin-bottom: -8%;
        margin-left: 10%;  
    }
    .qr-camera-laout{
        margin-top: 18%;
        margin-bottom: 21px;
    }
    .qr-code-scan-success{
        width: 200px !important;
        height: 200px !important;
        margin-top: 25%;
        margin-left: 9%;
        margin-bottom: 12.4%;
    }    
    /* end qr modal */
    /* map modal */
    .map-modal-map{
        border: none;
        border-radius: 50px !important;
        margin-left: -5%;
        margin-top:-2%;
        width: 114%;
        height: 103.4% !important;
    }
    /* end map modal*/
}
@media only screen and (max-width: 361px){
    .homepage-memory-new-user-text-carousel{
        font-size: 14px !important;
    }

    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 157px !important;
        width: 100% !important;
        object-fit: cover;
    }

    /* filter modal */
    .homepage-category-modal-box {
        height: 165px !important;
        margin-top: -55%;
        margin-left: -85%;
        width: 300%;
    }
    .homepage-category-clear-btn {
        background: linear-gradient(0deg, rgb(238, 234, 236) 0%, rgb(188, 188, 192) 60%);
        border: none !important;
        padding: 5px 10px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 12px !important;
        margin-top: 2%;
        color: rgb(51, 50, 50)!important;
        cursor: pointer;
        float: right;
    }
    .homepage-category-modal-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .homepage-category-modal-times-close-icon{
        margin-top: -12px;
        width:60%;
    }
    .homepage-filter-slider-layout{
        margin-top: 3%;
        width: 140%;
        margin-left: -20%;
    }
    .homepage-filter-modal-frame {
        width: 62px;
        height: 60px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .homepage-category-modal-logo{
        width: 100% !important;
        height: 100%;
        border:solid 10px ;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);

    }
    .homepage-category-sellected-logo{
        width: 100% !important;
        height: 100%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .filter-category-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    /* end filter modal */
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 6% !important;
    }

    .homepage-hub-information-item{
        margin-left: 8% !important;
    }
}

@media only screen and (max-width: 345px){
    .homepage-new-message-bbubble{
        width:15px;
        height:15px;
        line-height: 15px;
        font-size: 11px;
    }
    .homepage-memory-new-user-text-carousel{
        font-size: 13px !important;
    }
    .homepage-memory-new-user-text{
        font-size: 12px !important;
    }
    .homepage-map-icon{
        top: -26px !important;
        margin-left: -2.5%;
    }
    .memory-images-size{
        height: 170px;
        margin-left: 0px;
        width:250px;
    }
    .caption {
        margin-bottom: -5px;
        margin-left: -20px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -35px;
        margin-left: 10px;
      }
    .slider-layout{
        margin-top: -105px;
        margin-left: 5px;
        margin-bottom: -30px;
    }

    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 160px;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 26px;
    }
    .b-n-icon img {
        margin-top: -13px !important;
        width: 15px !important;
    }
    .col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
        font-size: 14px;
        padding: 12px 28px;
        border-radius: 40%;
    }
    .b-n-icon img {
        margin-top: 15px !important;
    }
    .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -5px;
        right: 0;
        width: 154%;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 6px;
        left: 35px;
    }
    .b-n-icon-back img{
        margin-left: -55%;
        margin-top: 120px;
        padding-bottom: -80px;
    }
    .btn-danger-homepage-arrange{
        padding: 10px 5px !important;
    }
    /* description Modal */
    /* .homepage-description-box {
        margin-top: -205%;
        margin-left: -150%;
        width: 400% !important;
    } */
    .descrition-modal-box{
        /* padding: 40px 40px 30px 40px!important; */
        height: 300px!important;
    }
    .homepage-hub-description-text{
        font-size: 13px;
    }
    /* end description modal */
    /* qr modal */
    .qr-box{
        margin-top: -205%;
        margin-left: -105%;
        width: 340%;
        height: 440px!important;
    }
    .qr-scan-button{
        width: 80%;
        padding: 10px 35px;
    }
    /* end qr modal */
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 5% !important;
    }

    .homepage-hub-information-item{
        margin-left: 7.5% !important;
        cursor: pointer;
    }

    .traffic-badge-position{
        /* margin-top: 110px !important; */
        margin-left: 0% !important;
    }

}

@media only screen and (max-width: 320px){
    .find-buddies-carouserl-box{
        height: 300px;
    } 

    .find-buddies-img-box{
        width: 70px;
        height: 70px;
    }
    
    .find-buddies-sugget-username{
        font-size: 14px !important;
        margin-top: 10px;
    }
    
    .find-buddies-sugget-full-name{
        font-size: 13px !important;
    }
    .find-buddies-sugget-full-name-empty{
        font-size: 13px !important;
    }
    
    .find-buddies-sugget-3-memories{
        height: 70px;
        margin-top: 15px;
    }

    .find-buddies-sugget-3-memories-empty{
        height: 70px;
        margin-top: 15px;
    }

    .find-buddies-empty-photos-box{
        margin-top: 15px;
    }
    
    .find-buddies-no-memories-yet{
        color: #606062;
        font-size: 11px !important;
        font-weight: lighter;
        text-align: center;
        position: absolute;
        width: 100%;
        margin-top: 45px;
    }


    .homepage-memory-username-tags-layout{
        position: absolute;
        /* background-color: brown; */
        color: #575578;    
        margin-left: 95px;
        margin-top: 30px;
        font-size: 9px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }

    .homepage-memory-tag-profile-pic-layout{
        position: relative;
        margin-right: 0px;
    }
    .traffic-badge-position{
        /* margin-top: 0% !important; */
        position:absolute;
        top:100px !important;
        left: 190px;
        /* margin-left: -2% !important; */
    }
    .memory-images-size{
        height: 150px;
        margin-left: 10%;
        width: 200px;
    }
    .slider-layout{
        margin-top: -70px;
        margin-left: -10px;
        margin-bottom: -30px;
    }
    .caption {
        margin-bottom: -0px;
        margin-left: -25px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .homepage-input-icon-search{
        width: 6%;
        z-index: 1 !important;
    }
    .caption-position {
        position: absolute !important;
        margin-top: -35px;
        margin-left: -5px;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 40px solid #FFFAFA;
        border-radius: 5px;
        height: 150px;
    }
    .col-lg-4.col-md-4.col-sm-4.congestion-item.mt-5 {
        margin-left: -30px;
        margin-top: 58px !important;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 4px;
        left: 25px;
    }
    .b-n-icon img {
        margin-top: -1.5rem;
        width: 12px;
    }
    .logo-small img {
        width: 105px;
        margin-left: 20px;
    }
    .icon-small img {
        width: 23px;
    }
    .all-img {
        margin-left: -16px !important;
    }
    input.homepage-search-top {
        max-width: 60% !important;
    }
    /* input.homepage-search-top {
        width: 71% !important;
        z-index: 1000 !important;
        position: absolute;
        top: -55px;
        left: 10px;
    } */
    .col-lg-4.col-md-6.col-sm-6.btn-homepage.btn-danger-homepage-arrange.feedback-btn.arrange-btn.button.mt-2 {
        max-width: 80%;
        /* margin: auto; */
        border-radius: 40%;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 4px;
        left: 37px;
    }
    /* .all-img {
        margin: auto !important;
        text-align: center !important;
        position: relative;
        top: 0;
        left: -5px;
        right: 0;
        width: 154%;
    } */

    .middle-icon-footer img {
        position: absolute;
        top: -32px !important;
        width: 50px !important;
        left: 0px !important;
    }
    .footer-icon img {
        width: 53% !important;
    }
    .b-n-icon-back img{
        margin-left: -45%;
        margin-top: 100px;
        padding-bottom: -80px;
    }

    /* qr modal */
    .qr-click-box {
        /* margin-top: -130%;
        margin-left: -150%;
        width: 400% !important; */
    }
    .qr-box{
        margin-top: -205%;
        margin-left: -105%;
        width: 340%;
        height: 440px!important;
    }
    .qr-code-title{
        margin-left: 27%;
        font-size: 20px;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 20px;
        margin-left: 0%!important;
    }
    .camera-qr-code-title{
        font-size: 12px;
        margin-bottom: -8%;
        margin-left: 10%;  
    }
    .qr-camera-laout{
        /* margin-left: 0%!important; */
        margin-top: 18%;
        /* width: 100% !important; */
    }
    .qr-code-scan-success{
        width: 170px !important;
        height: 170px !important;
        margin-top: 25%;
        margin-left: 12%;
        margin-bottom: 9.2%;
    }   
    /* end qr modal */
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 137px !important;
        width: 100% !important;
        object-fit: cover;
    }
    /* description modal */
    .descrition-modal-box{
        margin-top: -180%;
        margin-left: -95%;
        width: 320% !important;
        height: 350px !important;
    }
    /* end description modal */
}
@media only screen and (max-width: 311px){
    .caption {
        margin-bottom: 0px;
        margin-left: -30px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 20px;
        opacity:1;
      }
    .memory-images-size{
        height: 150px;
        width: 180px;
    }
    .slider-layout{
        margin-top: -70px;
        margin-bottom: -30px;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 4px;
        left: 30px;
    }
    .b-n-icon-back img{
        margin-left: -45%;
        margin-top: 99px;
        padding-bottom: -80px;
    }
    /* .b-n-icon-next img{
        margin-right: -30%;
        margin-top: -90px;
    } */
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 3% !important;
    }
    .homepage-hub-information-item{
        margin-left: 7% !important;
        cursor: pointer;
    }
}
@media only screen and (max-width: 300px){
    .memory-images-size{
        height: 150px;
        width: 150px;
    }
    .slider-layout{
        margin-top: -50px;
        margin-bottom: -30px;
    }
    .image-margin-fixing {
        margin-top: -150px;
        position:relative;
        z-index:0;
    }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 55px solid #FFFAFA;
        border-radius: 5px;
        height: 280px;
    }
    .caption {
        margin-bottom: -5px;
        margin-left: -39px;
        position: absolute;
        width: 200px;
        height: 55px;
        bottom: 0px;
        left: 0px;
        color: rgb(114, 111, 111);
        z-index: 999999999;
        text-align:center;
        font-weight:bold;
        font-size: 18px;
        opacity:1;
      }
    .caption-position {
        position: absolute !important;
        margin-top: -30px;
        margin-left: -8px;
      }
    .img-bottom-border {
        border: 5px solid #FFFAFA;
        border-bottom: 30px solid #FFFAFA;
        border-radius: 5px;
        height: 140px;
    }
    span {
        font-size: 11px;
    }
    .span-conges {
        margin-left: 10px;
        font-size: 13px;
    }
    .col-lg-4.col-md-4.col-sm-4.congestion-item.mt-5 {
        margin-left: -30px;
        margin-top: 28px !important;
    }
    .col-lg-4.col-md-4.col-sm-4.congestion-item.mt-5.hubs-text-right {
        margin-left: -32px;
    }
    .size-image {
        max-width: 51% !important;
        position: absolute;
        top: 4px;
        left: 31px;
    }
    .footer-icon img {
        width: 70% !important;
    }
    .middle-icon-footer img{
        width: 6rem !important;
    }
    .b-n-icon-back img{
        margin-left: -45%;
        margin-top: 80px;
        padding-bottom: -80px;
    }
    /* qr modal */
    .qr-click-box {
        /* margin-top: -130%;
        margin-left: -140%;
        width: 380% !important; */
    }
    .qr-box{
        margin-top: -155%;
        margin-left: -85%;
        width: 300%;
        height: 400px!important;
    }
    .qr-code-title{
        margin-left: 27%;
        font-size: 20px;
    }
    .qr-image-size{
        width: 100%;
        margin-top: 10px;
        margin-left: 0%!important;
    }
    .qr-code-scan-success{
        width: 150px !important;
        height: 150px !important;
        margin-top: 25%;
        margin-left: 15%;
        margin-bottom: 9.2%;
    } 
    /* end qr modal */
    .homepage-hub-cover-photo{
        border-radius: 18px 18px 0px 0px;
        max-height: 117px !important;
        width: 100% !important;
        object-fit: cover;
    }
    .homepage-hub-all-informations{
        width: 140%;
        margin-left: 1% !important;
    }
    .homepage-hub-information-item{
        margin-left: 5% !important;
        cursor: pointer;
    }
    /* .b-n-icon-next img{
        margin-right: -30%;
        margin-top: -105px;
    } */
    .traffic-badge-position{
        /* margin-top: 0% !important; */
        position:absolute;
        /* top:200px !important; */
        left: 150px;
        /* margin-left: -2% !important; */
    }
    .homepage-map-icon{
        top: -26px !important;
        margin-left: -3.5%;
    }
}

/* media queires css end */





/* Footer icon css start */
/* .nav-bottom{
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon img {
    width: 22%;
}
.middle-icon-footer img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 30px;
} */
/* Footer icon css end */