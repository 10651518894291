.are-you-sure-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
    width: 800% !important;
    height: 400%;
    margin-top: -190%;
    margin-left: -330%;
    z-index: 99999999 !important;
}
.are-you-sure-times-icon{
    float: right;
}
.are-you-sure-title-size{
    width: 94%;
}
.are-you-sure-title{
    position: relative;
    font-size: 25px;
    width: 80%;
    margin-bottom: -8%; 
    color: #3F1E75;
    z-index: 3 !important;
    /* margin-left: 3%; */
    text-align: center; 
}
.are-you-sure-mika-approve{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left:15%;
    border-radius: 20%;
}
.are-you-sure-sellected-yes{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 15% !important;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.are-you-sure-mika-decline{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 15%;
    border-radius: 20%;
}
.are-you-sure-sellected-no{
    width: 30%;
    height: 30%;
    margin-top: 12% !important;
    margin-left: 15%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.are-you-sure-choises-no{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 16%;
    margin-top: 6%;
}
.are-you-sure-choises-yes{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 23%;
    margin-top: 6%;
}


@media only screen and (max-width: 780px) {  
    .are-you-sure-box-6 {
        width: 600% !important;
        height: 300%;
        margin-top: -150%;
        margin-left: -230%;
    }
    .are-you-sure-title{
        position: relative;
        font-size: 18px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .are-you-sure-mika-approve{
        width: 20% !important;
        height: 20%;
        margin-top: 17% !important;
        margin-left:20%;
    }
    .are-you-sure-sellected-yes{
        width: 25%;
        height: 25%;
        margin-top: 17% !important;
        margin-left: 20% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-mika-decline{
        width: 20%;
        height: 20%;
        margin-top: 17% !important;
        margin-left: 22%;
    }
    .are-you-sure-sellected-no{
        width: 25%;
        height: 25%;
        margin-top: 17% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-choises-no{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .are-you-sure-choises-yes{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 16%;
        margin-top: 6%;
    }
}

@media only screen and (max-width: 570px) {
    .are-you-sure-box-6 {
        width: 400% !important;
        height: 240%;
        margin-top: -140%;
        margin-left: -135%;
    }
    .are-you-sure-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .are-you-sure-mika-approve{
        width: 20% !important;
        height: 20% !important;
        margin-top: 20% !important;
        margin-left:25%;
    }
    .are-you-sure-sellected-yes{
        width: 25%;
        margin-top: 20% !important;
        margin-left: 25% !important;
        border-radius: 50%;
        border:double 8px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-mika-decline{
        width: 20%;
        height: 20% !important;
        margin-top: 20% !important;
        margin-left: 22%;
    }
    .are-you-sure-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-top: 20% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 8px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-choises-no{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .are-you-sure-choises-yes{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 19.5%;
        margin-top: 6%;
    }
}

@media only screen and (max-width: 400px) {
    .are-you-sure-box-6 {
        width: 350% !important;
        height: 220%;
        margin-top: -110%;
        margin-left: -110%;
    }
    .are-you-sure-sellected-yes{
        width: 25%;
        margin-top: 20% !important;
        /* margin-left: 13% !important; */
        border-radius: 50%;
        border:double 5px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-sellected-no{
        border:double 5px rgb(77,51,168);
    }
    .are-you-sure-mika-approve{
        width: 20% !important;
        height: 20% !important;
        margin-top: 20% !important;
        /* margin-left:13%; */
    }
    .are-you-sure-choises-no{
        margin-left: 18%;
    }
    .are-you-sure-choises-yes{
        margin-left: 16%;
    }
}

@media only screen and (max-width: 350px) {
    .are-you-sure-box-6 {
        width: 300% !important;
        height: 200%;
        margin-top: -95%;
        margin-left: -85%;
    }
    .are-you-sure-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
    }
    .are-you-sure-mika-approve{
        width: 20% !important;
        height: 20% !important;
        margin-left:34%;
    }
    .are-you-sure-sellected-yes{
        width: 25%;
        height: 25% !important;
        margin-left: 34% !important;
        border-radius: 50%;
        border:double 5px rgb(77,51,168);
    }
    .are-you-sure-mika-decline{
        width: 20%;
        height: 20% !important;
        margin-left: 18%;
    }
    .are-you-sure-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-left: 16%;
        border-radius: 50%;
        border:double 5px rgb(77,51,168) !important;
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .are-you-sure-choises-no{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 14%;
        margin-top: 6%;
    }
    .are-you-sure-choises-yes{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
}