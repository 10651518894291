
.user-desktop-navbar-sellected{
    width: 50%;
    margin-left: -27px;
    margin-top: -5px;
}
/* Body left css start */
.body-left{
    position: absolute;
    top: 0%;
    left: 0%;
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.desktop-profile-sellected{
    z-index: -1;
    position: absolute;
    margin-top: -5px;
    margin-left: -10%;
    width: 100%;
}
.desktop-navbar-sellected{
    color:#9D2464;
}
.hubst3r-word-logo{
    padding: 30px 40px;
    margin-top: -10px;
}
.profile-pic{ 
    padding-right: 0px;
    text-align: right;
    margin-top: 6px; 
}
.desktop-profile-text strong, p{
    color: #fff;
    font-size: 60%!important;
    margin-left: 3%;
}
.text-white{
    color: #fff;
}
.left-text-desktop-navbar{
    font-size: 11px !important;
}
.left-icon img{
    width: 45%;
}
.left-home{
    cursor: pointer;
}
.left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
.desktop-profile-picture-layout {
    border-radius: 30% !important;
    margin-top: 0px;
    width: 60px !important;
    height: 60px !important;
    margin-bottom: 30px!important;
}

.settings-icons-layout{
    width: 15%;
    position: relative;
    margin-top: 5%;
    left: 2%;
    cursor: pointer;
    /* margin-left: 30%; */
    /* padding: 30px 30px; */
    /* float: center; */
}
/* Body left css end */

@media only screen and (max-width: 1200px){
    .body-left{
        display: none;
    }
}
