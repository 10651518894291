.create-job-box {
    position: absolute;
    padding: 10px 15px !important;
    border-radius: 50px;
    background: #fff; 
    height: 420px !important;
    margin-top: -180%;
    margin-left: -135%;
    width: 400%;
}
.create-job-hub-name{
    text-align: left;
    font-size: 20px;
    color: #3F1E75;
}
.create-job-hub-section-title{
    text-align: left;
    font-size: 12px;
    color: #3F1E75; 
    margin-top: 6%;
    margin-bottom: -10%;  
}

.create-job-not-going{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 28px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    cursor: pointer;
    color: white !important;
    margin:auto;
    width: 60%;
    margin-top: 30%;
}

.create-job-create-job{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 28px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    cursor: pointer;
    color: white !important;
    margin:auto;
    width: 60%;
    margin-top: 5%;
    text-align: center;
}

.create-job-going{
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #64ad6a;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 12px; 
    margin: auto;
    width: 50%;
    margin-top: 7%;
}

.create-job-hub-reason{
    font-size: 18px;
    color: #3F1E75;
}
.create-job-text-show{
    font-size: 12px;
    color: #3F1E75;
    word-break: break-all;
}
.create-job-hub-reason-position{
    margin-top: 10%;
}
.create-job-times-frame {
    width: 30px;
    height: 30px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 6%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.create-job-close-icon{
    margin-top: -12px;
    width:60%;
}
.create-job-delete-btn{
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px; 
    width: 50%;
    text-align: center;
    position: absolute;
    bottom: -5%;
    left: 25%;
}
@media only screen and (max-width: 1050px){
    .ambassandor-all-page-display{
        display: block;
    }
}

@media only screen and (max-width: 422px){
    .create-job-box {
        height: 420px !important;
        margin-top: -180%;
        margin-left: -85%;
        width: 300%;
    }
    .create-job-hub-name{
        font-size: 14px;
    }
    
    .create-job-hub-reason{
        font-size: 12px;
    }
    .create-job-hub-reason-position{
        margin-top: 10%;
    }
    .create-job-times-frame {
        width: 25px;
        height: 25px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 6%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .create-job-close-icon{
        margin-top: -18px;
        width:60%;
    }
    .create-job-not-going{
        border-radius: 30px;
        padding: 12px 35px !important;
        font-size: 12px; 
        width: 50%;
        margin-top: 12%;
    }
    .create-job-create-job{
        border-radius: 30px;
        padding: 12px 35px !important;
        font-size: 12px; 
        width: 50%;
        margin-top: 12%;
    }
    .create-job-delete-btn{
        margin-top: 10%;
    }
}
