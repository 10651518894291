.search-adroid-buddies-invisible-redirect{
    position: absolute;
    height: 60%;
    top: 5%;
    width: 86%;
    left: 7%;
}

.search-adroid-arrow-icon{
    width: 60%;
    height: 63%;
    position: absolute;
    top: 25%;
    left: 20%;
}

.search-adroid-arrow-btn-layout{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
}

.search-adroid-previous-btn-box{
    width: 45px;
    height: 45px;
    position: absolute;
    bottom: 3%;
    left: 10%;
    z-index: 99999;
}

.search-adroid-next-btn-box{
    width: 45px;
    height: 45px;
    position: absolute;
    bottom: 3%;
    right: 10%;
    z-index: 99999;
}

.search-adroid-buddies-center-choise-box{
    position: absolute;
    width:40%;
    height: 40px;
    left: 30%;
    bottom:2.5%;
    /* margin-top: 1%; */
    background-color: blue;
}

.search-adroid-buddies-center-choise-icon-box{
    position: relative;
    width:100%;
    /* height: 40px; */
    position: absolute;
    top:0%;
    /* left: 10%; */
    font-weight: bold;
    border-radius: 4px;
    color: white !important;
    text-align: center;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    font-size: 12px;
    line-height: 40px;
}

.search-adroid-buddies-center-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 0%;
  }

  .search-adroid-buddies-center-recuestr-choise-icon-box{
    position: relative;
    width:100%;
    /* height: 40px; */
    position: absolute;
    top: 0%;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    font-weight: lighter;
    background: #fff;
    color: rgb(59, 59, 59) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    border: solid 0.5px rgb(204, 204, 204);
  }

  .search-adroid-buddies-center-recuestr-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 0%;
  }

.search-adroid-buddies-swipe-card{
    position: relative;
}

.search-adroid-buddies-swipe-square{
    /* background-color: #000; */
    /* width: 100% !important; */
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px -80px 30px 10px rgb(27, 19, 19) inset !important;
}

.back-button-buddies-page-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.buddies-page-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.search-buddies-small-icon-box{
    width: 35px;
    height: 35px;
    margin: auto;
    margin-top: 5px;
}

.search-buddies-small-icon-box-left{
    width: 35px;
    height: 35px;
    margin: auto;
    margin-top: 8px;
}

.search-buddies-small-icon{
    width: 100%;
    height: 100%;
}

.search-buddies-small-btn{
    width: 100%;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 0%;
    z-index: 9999999999 !important;
}

.search-buddies-small-btn-left{
    position: absolute;
    width:50%;
    height: 100%;
    left: 0%;
    top: 0%;
    border-radius: 4px;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    box-shadow: 0 0 10px rgb(0, 0, 0, 1);
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}

.search-buddies-small-btn-left:active {
    transform: scale(0.88);
}

.search-buddies-small-btn-left:focus {
    transform: scale(0.88);
}

.search-buddies-small-btn-left:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-small-btn-left-sellected{
    position: absolute;
    width:50%;
    height: 100%;
    left: 0%;
    top: 0%;
    border-radius: 4px;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    /* box-shadow: 0 0 10px rgb(0, 0, 0, 1); */
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
    box-shadow: inset 0 0 10px rgb(122, 120, 120);

}

.search-buddies-small-btn-left-sellected:active {
    transform: scale(0.88);
}

.search-buddies-small-btn-left-sellected:focus {
    transform: scale(0.88);
}

.search-buddies-small-btn-left-sellected:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-small-btn-right{
    position: absolute;
    width:50%;
    height: 100%;
    left: 50%;
    top: 0%;
    border-radius: 4px;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    box-shadow: 0 0 10px rgb(0, 0, 0, 1);
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}

.search-buddies-small-btn-right:active {
    transform: scale(0.88);
}

.search-buddies-small-btn-right:focus {
    transform: scale(0.88);
}

.search-buddies-small-btn-right:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-small-btn-right-sellected{
    position: absolute;
    width:50%;
    height: 100%;
    left: 50%;
    top: 0%;
    border-radius: 4px;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    /* box-shadow: 0 0 10px rgb(0, 0, 0, 1); */
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
    box-shadow: inset 0 0 10px rgb(122, 120, 120);
}

.search-buddies-small-btn-right-sellected:active {
    transform: scale(0.88);
}

.search-buddies-small-btn-right-sellected:focus {
    transform: scale(0.88);
}

.search-buddies-small-btn-right-sellected:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-all-info{
    /* background-color: green;  */
    position: relative;
    width: 280px;
    height: 100%;
    margin-left: 20%;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px !important;
    color: #575578;
}

.profil-buddies-all-info{
    /* background-color: green;  */
    position: relative;
    width: 280px;
    height: 100%;
    margin-left: 20%;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px !important;
    color: #575578;
}

.search-buddies-info-username{
    /* background-color: rgb(210, 219, 210);  */
    position: absolute;
    width: 100%;
    height: 33%;
    /* margin-left: 20%; */
    font-weight: bold;
    font-size: 18px !important;
    color: #575578;
    top: 10%;
}

.search-buddies-info-fullname{
    /* background-color: rgb(135, 127, 173);  */
    position: absolute;
    width: 100%;
    height: 33%;
    top: 35%;
    /* margin-left: 20%; */
    font-weight:normal;
    font-size: 18px !important;
    color: #575578;
}

.search-buddies-info-extra{
    /* background-color: rgb(135, 127, 173);  */
    position: absolute;
    width: 100%;
    height: 33%;
    top: 55%;
    /* margin-left: 20%; */
    font-weight:lighter;
    font-size: 16px !important;
    color: #7673a1;
    line-height: 200%;
}

.search-buddies-btn-approve{
    position: absolute;
    background: #fff;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 35px;
    font-size: 12px;
    background-color: #06E00F !important;
    border: none;
    color: white !important;
    text-align: center;
    cursor: pointer;
    left: 65%;
}

.search-buddies-btn-approve:active {
    transform: scale(0.88);
}

.search-buddies-btn-approve:focus {
    transform: scale(0.88);
}

.search-buddies-btn-approve:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-btn-decline{
    position: absolute;
    background: #fff;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 35px;
    font-size: 12px;
    background-color: #CC0919 !important;
    border: none;
    color: white !important;
    text-align: center;
    cursor: pointer;
    left: 65%;
    margin-top: 80px;
}

.search-buddies-btn-decline:active {
    transform: scale(0.88);
}

.search-buddies-btn-approve:focus {
    transform: scale(0.88);
}

.search-buddies-btn-approve:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddy-requested-btn{
    position: absolute;
    font-weight: lighter;
    border-radius: 4px;
    padding: 15px 42px;
    text-align: center;
    font-size: 12px;
    right: 250px;
    margin-top: 35px;
    background: #fff;
    color: rgb(59, 59, 59) !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    border: solid 0.5px rgb(204, 204, 204);
}

.search-buddy-request-btn{
    position: absolute;
    font-weight: bold;
    border-radius: 4px;
    padding: 15px 42px;
    color: white !important;
    text-align: center;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    font-size: 12px;
    right: 250px;
    margin-top: 35px;
}

.search-buddies-section-top-title{
    position: absolute;
    left: 5%;
    top: 85px;
    width: 90%;
    height: 100px;
    color: #575578;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.search-buddies-question-box-small{
    position: absolute;
    left: 0%;
    top: 500px;
    width: 100%;
    height: 15%;
    margin: auto;
}

.search-buddies-question-left-small{
    position: absolute;
    left: 0%;
    width: 50%;
    height: 100%;
    margin: auto;
}

.search-buddies-question-right-small{
    position: absolute;
    left: 50%;
    width: 50%;
    height: 100%;
    margin: auto;

}

.search-buddies-question-btn-small-sellected{
    border-radius: 20px;
    position: relative;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    /* box-shadow: inset 0 0 10px rgb(48, 16, 121); */
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(221, 220, 220),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    width: 98px;
    height: 98px;
    margin: auto;
    margin-top: 3px;
    box-shadow: inset 0 0 10px rgb(122, 120, 120);
    border: solid 0.5px rgb(204, 204, 204);
}

.search-buddies-question-btn-small{
    border-radius: 20px;
    position: relative;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    box-shadow: 0 0 10px rgb(0, 0, 0, 1);
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    width: 100px;
    height: 100px;
    margin: auto;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}


.search-buddies-question-btn-small:active {
    transform: scale(0.88);
}

.search-buddies-question-btn-small:focus {
    transform: scale(0.88);
}

.search-buddies-question-btn-small:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-buddies-question-icon-box-small{
    width: 80px;
    height: 80px;
    margin: auto;
    margin-top: 10px;
}

.slider-single-content {
    border-radius: 50px!important;
}

.slider-left{
    opacity: 0%;
}

.slider-right{
    opacity: 0%;
}

.search-carousal13-carouserl-layout{
    position: relative;
    top: 120px !important;
    left: 0%;
    width: 100%;
} 

.search-swipe-Whole-box-layout{
    position: absolute;
    top: 3% !important;
    left: 0%;
    width: 100%;
    height:65%;
} 

.search-carousal13-carouserl-box{
    width: 400px;
    height: 370px;
    border-radius: 50px!important;
    border: 1px solid rgb(233, 232, 232);
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 30px -20px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 30px 170px -20px rgba(45, 6, 104, 0.2); 
} 

.search-carousal13-img-box{
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: 20px;
}

.search-carousal13-img{
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%;
    object-fit: cover;
}

.search-carousal13-sugget-username{
    color: #575578;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.search-carousal13-sugget-full-name{
    color: #575578;
    font-size: 15px !important;
    text-align: center;
}

.search-carousal13-sugget-full-name-empty{
    color: transparent;
    font-size: 15px !important;
    text-align: center; 
}

.search-carousal13-sugget-3-memories{
    width: 100%;
    height: 90px;
    /* background-color: tomato; */
    margin: auto;
    margin-top: 10px;
    z-index: 9999;
}

.search-carousal13-sugget-3-memories-empty{
    width: 100%;
    height: 90px;
    /* background-color: tomato; */
    margin: auto;
    margin-top: 10px;
    z-index: 9999;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
}

.search-carousal13-empty-photos-box{
    margin: auto;
    width: 18px;
    height: 18px;
    margin-top: 20px;
}

.search-carousal13-no-memories-yet{
    color: #606062;
    font-size: 12px !important;
    font-weight: lighter;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 50px;
}

.search-carousal13-empty-photos-icon{
    width: 100%;
    height: 100%;
}

.search-carousal13-sugget-memory{
    width: 33.333%;
    height: 100%;
    z-index: 9999;
    border: 1px solid rgb(255, 255, 255);
}

.search-carousal13-sugget-memory-size{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9999;
}

.search-carousal13-sugget-memory-empty{
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
}

.search-carousal13-sugget-add-btn{
    position: relative;
    width: 70%;
    height: 10%;
    margin: auto;
    margin-top: 20px;
    /* line-height: 125px; */
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    color: white !important;
    text-align: center;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    cursor: pointer;
}

.search-carousal13-sugget-add-btn:active {
    transform: scale(0.88);
}

.search-carousal13-sugget-add-btn:focus {
    transform: scale(0.88);
}

.search-carousal13-sugget-add-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-carousal13-sugget-requested-btn{
    position: relative;
    width: 70%;
    height: 10%;
    background: #fff;
    margin: auto;
    margin-top: 20px;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    text-align: center;
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
}

.search-carousal13-sugget-requested-btn:active {
    transform: scale(0.88);
}

.search-carousal13-sugget-requested-btn:focus {
    transform: scale(0.88);
}

.search-carousal13-sugget-requested-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.search-carousal13-vistit-btn{
    position: relative;
    margin: auto;
    width: 70%;
    height: 9%;
    margin-top: 20px;
    text-align: center;
    /* font-weight: bold; */
    border-radius: 4px;
    padding: 6px 35px;
    color: #fff !important;
    background: #1183ca !important;
    border: none !important;
    font-size: 15px;
}

.search-carousal13-vistit-btn:active {
    transform: scale(0.88);
}

.search-carousal13-vistit-btn:focus {
    transform: scale(0.88);
}

.search-carousal13-vistit-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}



.search-buddies-question-top-title{
    position: absolute;
    left: 5%;
    top: 20px;
    width: 90%;
    height: 100px;
    color: #767683;
    text-align: center;
    font-size: 14px;
}

.search-buddies-question-title-question{
    position: absolute;
    left: 5%;
    top: 100px;
    width: 90%;
    height: 30px;
    color: #575578;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.search-buddies-question-box{
    position: absolute;
    left: 0%;
    top: 12%;
    width: 100%;
    height: 50%;
}

.search-buddies-question-left{
    position: absolute;
    left: 0%;
    top: 10%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.search-buddies-question-right{
    position: absolute;
    left: 50%;
    top: 10%;
    width: 50%;
    height: 100%;
    display: block;
}

.search-buddies-question-btn{
    display: block;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    position: absolute;
    width: 90%;
    margin-left: 5%;
    height: 255px;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}


.search-buddies-question-btn:active {
    transform: scale(0.94);
}

.search-buddies-question-btn:focus {
    transform: scale(0.94);
}

.search-buddies-question-btn::after {
    transform: scale(0.94);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}




.search-buddies-question-icon-box{
    margin: auto;
    width: 150px;
    height: 150px;
    margin-top: 10%;
}

.search-buddies-question-icon{
    width: 100%;
    height: 100%;
}

.search-buddies-question-text{
    color: #575578;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    width: 85%;
    margin-left: 7.5%;
}




.search-buddies-hub-traffic-section{
    width: 10%;
    position: relative;
    right: -30%;
    top:5px;
}

.search-buddies-hub-traffic-img-box{
    width: 100%;
}
.search-buddies-hub-traffic-img-sc{
    width: 100%;
}
.search-buddies-hub-traffic-title{
    color: #575578;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.text-blue-search-users{
    color: #575578;
    font-size: 22px;
    font-weight: bold;
}
.text-blue-username-search-users{
    position: absolute;
    left: 32%;
    top: 140px;
    color: #575578;
    font-size: 18px !important;
    font-weight: bold; 
    width: 250px;
    /* background-color: green; */
    /* left: 0%; */
}
.search-user-user-username-alone{
    margin-left: 15%;
    margin-top: 55px; 
}
.search-user-user-fullname-alone{
    margin-left: 15%;
    margin-top: 40px; 
}
.full-name-search-users{
    color: #575578;
    font-size: 16px;
}
.search-buddies-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
}

/* loading */
.search-users-section-disappear{
    display: none;
}
.search-users-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.search-users-lottie-dissappear{
    display: none;
}
/* end loading */

/* Body left css start */
.body-left-10{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.search-top{
    border: none;
    z-index: 999999 !important;
}
.search-top input{
    /* margin-top: 6px; */
    border-radius: 4px !important; 
    /* -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); */
    text-align: center;
    z-index: 999999 !important;
}
.logo-10{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic-10{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text-10 strong, p{
    color: #fff;
}
.text-white-10{
    color: #fff;
}
.left-text-10{
    font-size: 15px;
}
.left-icon-10 img{
    width: 45%;
}
.left-home-10{
    cursor: pointer;
}
.left-home-10:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.body-right-10{
    margin-left: 16.67%;
    background: #ffff;
}

.btn-green-10{
    background-color: #06E00F !important;
    margin-top: -11%;
}
.btn-red-10{
    background-color: #CC0919 !important;
    margin-top: -11%;
    margin-left: 10%;
}
.btn-green-10, .btn-red-10{
    border: none !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    width: 15% !important;
}

.single-item-buddy-field{
    width: 60%!important;
    background: #fff;
    padding: 15px 10px;
    border-radius: 4px;
    margin: auto !important;
    margin-top: 35px !important;
    border: solid 0.5px rgb(204, 204, 204);
    /* margin-left: -13%!important; */
}
.single-item-buddy-field{
    top: initial;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    height: 10rem;
}
.search-buddies-search-box{
    /* position: absolute; */
    z-index: 999999999999999999999999999999 !important;
    /* margin: auto !important; */
    width: 50%;
    position: absolute;
    left: 33.5%;

} 
.search-form-text{
    text-align: center;
    background-color: #e4e5e7 !important;
}
.btn{
    cursor: pointer;
}
.search-all-buttons-layout{
    /* float: right; */
    position:absolute;
    left: 68%;
    margin-top: 40px;
}
.btn-info-search-buddies{
    /* width: 100%; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 10px 45px;
    color: white !important;
    font-size: 20px !important;
    border-radius: 30px!important;
    /* margin-left: 60%; */
}
.search-user-buddy-button{
    float: right;
    margin-top: -60px;
    margin-right: 4% !important;  
}
.btn-already-buddy{
    width: 100% !important;
    background: rgb(200, 200, 200);
    background: linear-gradient(0deg, rgb(248, 248, 248) 0%, rgb(241, 241, 241) 100%);
    border: none;
    padding: 10px 35px;
    /* color: white !important; */
    color:  #575578 !important;
    font-size: 20px !important;
    font-weight: bold;
    border-radius: 30px!important;
    text-align: center !important;
}
.search-user-cancel-button{
    float: right;
    margin-top: -60px;
    margin-right: 5% !important;
}
.btn-cancel-search-buddies{
    color: rgb(207, 49, 49)!important;
    border: 1px solid rgb(207, 49, 49) !important;
    width: 100%;
    font-size: 20px !important;  
    border-radius: 30px!important;
    padding: 5px 20px !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 3px 17px -2px rgba(45, 6, 104, 0.2);
    border: none;
    font-weight: bold;
    margin-left: 12%;
}

.search-user-buttons{
    float: right;
    margin-right: 0% !important; 
}

.user-search-btn-approve{
    width: 100%;
    background-color: #06E00F !important;
    border: none;
    padding: 10px 25px;
    color: white !important;
    font-size: 20px !important;
    border-radius: 30px!important;
    margin-top: -18%;
    margin-bottom: 5%;
    text-align: center;
    margin-left: -10%;
    cursor: pointer;
}
.user-search-btn-decline{
    width: 100%;
    background-color: #CC0919 !important;
    border: none;
    padding: 10px 45px;
    color: white !important;
    font-size: 20px !important;
    border-radius: 30px!important;
    text-align: center;
    margin-left: -10%;
    cursor: pointer;
}
.search-user-user-username{
    margin-left: 5%;
    margin-top: 30px;
    width: 40%;
}
.right-icon-10 img{
    margin-top: 10px;
}
.logo-text-10{
    display: flex;
    justify-content: space-between;
}
.w-small-10{
    max-width: 80% !important;
}
.search-buddies7-10{
    margin-top: 3.5rem;
}
.cross-text-10{
    display: flex;
    justify-content: space-between;
}
.cross-text-10 img{
    width: 35px;
}
.search-buddies-small-header-container-10{
    display: none;
}

.margin-top-10{
    padding-top: 1.8rem !important;
}
.buddies-10{
    width: 6%;
    margin-left: -70px;
    margin-top: 50px;
}
.k-logo-10{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%!important;
    margin-top: -10px;
}
.text-blue-empty{
    margin-top: 7%;
    margin-left: 30%;
}
.search-user-picture-layout{
    border-radius: 50%!important;
    width: 100px!important;
    height: 100px!important;
    margin-left: 30px !important;
    margin-top: 12% !important;
    object-fit: cover;
    border: #e6e6e6 1px solid !important;
}
.buddies-10-position{
    margin-top: 20px; 
}
.search-buddy-input-icon-search{
    position: absolute;
    width: 30px !important;
    margin-left: 10px;
    margin-top: 8px;
    z-index: 9999999;
}


/* Footer icon css start */
.nav-bottom-10{
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon-10 img {
    width: 18%;
}
.middle-icon-footer-10 img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 50%;
    transform: translateX(-50%);
}
.footer-width-10{
    width: 20% !important;
}
.nav-bottom-10{
    display: none !important;
}
.nav-bottom-10{
    z-index: 99999999999 !important;
}
/* Footer icon css end */

.search-buddies-all-buttons-layout{
    width: auto!important;
}
@media only screen and (max-width: 1450px){
    .search-user-buddy-button{
        float: right;
        margin-top: -60px;
        margin-right: 6% !important;  
    }
    .search-user-buttons{
        float: right;
        margin-right: -3% !important; 
    }

    .search-user-user-username{
        margin-left: 10%;
        margin-top: 30px;
    }
    .search-all-buttons-layout{
        /* float: right; */
        position:absolute;
        left: 66%;
        margin-top: 40px;
    }
    .show-profile-buddies-back-button{
        display:none
    }
    
}

@media only screen and (max-width: 1200px){
    
    .buddies-page-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }

    .search-buddies-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        z-index: 99999999;
    }
    .search-buddies-btn-decline{
        margin-top: 50px;
    }
    .show-profile-buddies-back-button{
        display:block;
        position: absolute;
        top: 62px;
        left:5%;
        z-index: 999;
        cursor: pointer;
    }
    body{
        background-color: #fff !important;
    }
    .body-left-10{
        display: none;
    }
    .body-right-10{
        margin-left: initial;
        border-radius: 0px 0px 0px 0px;
        padding-top: 40px !important;
        padding-bottom: 130px !important;
        margin-bottom: 40px !important;
        height: 1200px;
        margin-top: -30px;
    }
    .search-buddies-small-header-container-10{
        display: block;
    }
    .search-buddies-hide-h3{
        display: none;
    }
    .sm-header-text{
        padding: 30px 10px;
        margin-top: 20px;
 
    }
    .title-font-layout strong{
        font-size: 40px;
    }
    .search-buddies-color-10{
        background: rgb(144,36,103);
        background: linear-gradient(300deg, rgba(144,36,103,1) 35%, rgba(28,48,111,1) 100%);
    }
    .search-user-buddy-button{
        float: right;
        margin-top: -60px;
        margin-right: 4% !important;  
    }
    .search-user-buttons{
        margin-right: -5% !important; 
    }
    .btn-cancel-search-buddies{
        font-size: 20px !important;  
        padding: 5px 20px !important;
        /* margin-left: -3%; */
    }
    .search-user-user-username{
        margin-left: 12%;
        margin-top: 30px;
    }
    .text-blue-username-search-users{
        color: #575578;
        font-size: 20px !important;
        font-weight: bold;
    }
    .search-user-user-username-alone{
        margin-left: 10%;
        margin-top: 50px; 
    }
    .search-user-user-fullname-alone{
        margin-left: 15%;
        margin-top: 33px; 
    }
    .user-search-btn-approve{
        padding: 10px 25px;
        font-size: 20px !important;
        margin-top: -18%;
        margin-bottom: 5%;
        margin-left: -0%;
    }
    .user-search-btn-decline{
        padding: 10px 25px;
        font-size: 20px !important;
        margin-left: -0%;
    }
}


@media only screen and (max-width: 992px){
    .single-item-buddy-field{
        width: 80%!important;
    }
    .search-buddies-search-box{
        width: 60%;
    }
    .search-user-username-size{
        font-size: 16px !important;
    }
    .search-user-buddy-button{
        margin-right: 5% !important;  
    }
    .search-user-cancel-button{
        margin-top: -55px;
        margin-right: 5% !important;
    }
    .btn-cancel-search-buddies{
        width: 100%;
        font-size: 20px !important;  
        padding: 5px 30px !important;
        margin-left: 3%;
    }
    .search-user-buttons{
        margin-right: -3% !important; 
    }
    .search-all-buttons-layout{
        /* float: right; */
        position:absolute;
        left: 72%;
        margin-top: 40px;
    }
}


@media only screen and (max-width: 800px){
    .search-user-picture-layout{
        border-radius: 50%!important;
        width: 90px!important;
        height: 90px!important;
        margin-left: 30px !important;
        margin-top: 28% !important;
        object-fit: cover;
    }
    .search-user-user-username{
        width: 35%;        
    }
    .search-user-username-size{
        font-size: 15px !important;
    }
    .search-user-buddy-button{
        margin-right: 3.5% !important;  
    }
    .btn-already-buddy{
        margin-left: 35%;
    }
    .search-user-cancel-button{
        margin-top: -55px;
        margin-right: 4% !important;
    }
    .btn-cancel-search-buddies{
        width: 100%;
        font-size: 18px !important;  
        padding: 7px 20px !important;
        margin-left: 70%;
    }
    .search-user-buttons{
        margin-right: -7% !important; 
    }
    .body-right-10{
        height: 900px;
    }
    .search-buddy-input-icon-search{
        width: 3%;
        margin-left: 12px;
        margin-top: 8px;
    }
    .btn-info-search-buddies{
        margin-left: 40%;
    }
    .text-blue-search-users{
        color: #575578;
        font-size: 16px;
        font-weight: bold;
    }
    .search-all-buttons-layout{
        /* float: right; */
        position:absolute;
        left: 58%;
        margin-top: 40px;
    }
    .text-blue-username-search-users{
        color: #575578;
        font-size: 15px !important;
        font-weight: bold;
    }
    .search-user-user-username-alone{
        margin-left: 15%;
        margin-top: 50px; 
    }
    .search-user-user-fullname-alone{
        margin-left: 15%;
        margin-top: 45px; 
    }
    .user-search-btn-approve{
        padding: 10px 25px;
        font-size: 18px !important;
        margin-top: -18%;
        margin-bottom: 5%;
        margin-left: 50%;
    }
    .user-search-btn-decline{
        padding: 10px 25px;
        font-size: 18px !important;
        margin-left: 50%;
    }
}

@media only screen and (max-width: 576px){
    .search-buddies-all-info{
        width: 180px;
        margin-top: 0px;
        margin-left: 30px;
    }

    .profil-buddies-all-info{
        width: 180px;
        margin-top: 8px;
        margin-left: 30px;
    }
    
    .search-buddies-info-username{
        font-size: 14px !important;
        top: 0%;
    }
    
    .search-buddies-info-fullname{
        font-size: 13px !important;
        top: 32%;
    }
    
    .search-buddies-info-extra{
        font-size: 11px !important;
        top: 65%;
    }


    .title-font-layout strong{
        font-size: 25px;
    }
    .show-profile-buddies-back-button{
        left:2%;
    }
    .btn-green-10, .btn-red-10 {
        border: none !important;
        font-weight: bold !important;
        border-radius: 30px !important;
        width: 25% !important;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 65px;
    }
    .logo-text-10 img {
        width: 22px;
    }
    img.k-logo-10 {
        width: 40px;
        height: 40px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-10.text-center img {
        width: 45px;
    }
    .right-icon-top-10 img{
        width: 30px;
    }
    .body-right-10{
        margin-left: initial;
        border-radius: 0px 0px 0px 0px;
        padding-top: 30px;
    }
    .search-buddies-color-10{
        background: #fff;
    }
    .search-buddies-small-header-container-10 {
        /* background: url(./svg/bg-color.svg) no-repeat; */
        background-size: cover;
        background-position: center;
        z-index: 9999;
        border-radius: 0px 0px 0px 0px;
        padding-bottom: 53px;
        margin-bottom: 20px;
    }
    .col-xl-10.col-lg-12.body-right-10.pb-5 {
        padding-bottom: 0rem !important;
    }
    .footer-icon-10 img {
        width: 25%;
    }
    .middle-icon-footer-10 img {
        position: absolute;
        top: -38px;
        width: 78px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .buddies-10{
        width: 8%;
        margin-left: -20%;
        margin-top: 50px;
    }
    .buddies-10-position{
        margin-top: 20px;
    }
    .single-item-buddy-field{
        width: 90%!important;
        height: 15% !important;
        margin-top: 2% !important;
    }
    .search-buddies-search-box{
        width: 93%;
        /* height: 100px !important; */
        /* margin-top: -60px !important; */
        position: absolute;
        left: 3.5% !important;
        top: 9px !important;
    }

    .search-buddies-search-box input{
        height: 35px !important;
        width: 100%;
        border: none;
    }
    
    .search-user-picture-layout{
        border-radius: 50%!important;
        width: 70px!important;
        height: 70px!important;
        margin-left: 30px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    .search-user-user-username{
        margin-left: 12%;
        margin-top: 10px;
        width: 45%;
    }
    .search-user-username-size{
        font-size: 15px !important;
    }
    .btn-info-search-buddies{
        font-size: 16px !important;
        padding: 10px 30px;
        margin-left: 20%;
    }
    .search-user-buddy-button{
        margin-right: 0% !important;  
    }
    .btn-already-buddy{
        padding: 10px 25px;
        font-size: 16px !important;
        margin-left: 10%;

    }
    .search-user-cancel-button{
        margin-top: -55px;
        margin-right: 0% !important;
    }
    .btn-cancel-search-buddies{
        font-size: 16px !important;  
        padding: 7px 16px !important;
        margin-left: 20%;
    }
    .search-user-buttons{
        margin-right: -12.5% !important; 
    }
    .search-user-buttons-up{
        margin-top: -150px;
    }
    .search-user-buttons-down{
        margin-top: -100px;
    }
    .search-buddy-input-icon-search{
        width: 20px !important;
        position: absolute;
        left: 0%;
        top: 0px;
    }
    .search-all-buttons-layout{
        /* float: right; */
        position:absolute;
        left: 72%;
        margin-top: 15px;
    }
    .text-blue-username-search-users{
        color: #575578;
        font-size: 15px !important;
        font-weight: bold;
        margin-left: -5px;
    }
    .search-user-user-username-alone{
        margin-left: 15%;
        margin-top: 25px; 
    }
    .search-user-user-fullname-alone{
        margin-left: 15%;
        margin-top: 20px; 
    }
    .user-search-btn-approve{
        padding: 5px 10px;
        font-size: 18px !important;
        margin-top: -18%;
        margin-bottom: 5%;
        margin-left: 30%;
    }
    .user-search-btn-decline{
        padding: 5px 10px;
        font-size: 18px !important;
        margin-left: 30%;
    }
}

@media only screen and (max-width: 504px){
    .search-buddies-hub-traffic-section{
        width: 15%;
        position: absolute;
        right: 10%;
        top: 40px !important;;
    }
    .search-buddies-hub-traffic-title{
        font-size: 14px;
    }
    .search-buddies-hub-traffic-img-sc{
        width: 90% !important;
        margin: auto;
    }
    p.text-blue-search-users {
        font-size: 14px;
    }
    .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .195rem .75rem !important;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .footer-icon-10 img {
        width: 30%;
    }
    .middle-icon-footer-10 img {
        position: absolute;
        top: -34px;
        width: 75px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .search-user-picture-layout{
        margin-left: 20px !important;
    }
    /* .search-user-user-username{
        margin-left: 35%;
        margin-top: -40px;  
    } */
    .search-user-username-size{
        font-size: 12px !important;
    }
    .btn-info-search-buddies{
        font-size: 16px !important;
        padding: 10px 30px;
        margin-left: 20%;
    }
    .search-user-buddy-button{
        margin-right: 2% !important;  
    }
    .btn-already-buddy{
        padding: 10px 15px;
        font-size: 13px !important;
    }
    .search-user-cancel-button{
        margin-top: -45px;
        margin-right: 3% !important;
    }
    .btn-cancel-search-buddies{
        font-size: 10px !important;
        padding: 10px 20px;
        /* margin-left: 3%; */
    }
    .search-user-buttons{
        margin-right: -13.5% !important; 
    }
    .text-blue-username-search-users{
        color: #575578;
        font-size: 13px !important;
        font-weight: bold;
        margin-left: 0px;

    }
    .search-user-user-username-alone{
        margin-left: 15%;
        margin-top: 18px; 
    }
    .search-user-user-fullname-alone{
        margin-left: 15%;
        margin-top: 10px; 
    }
    .user-search-btn-approve{
        padding: 5px 10px;
        font-size: 12px !important;
        margin-top: -18%;
        margin-bottom: 5%;
        margin-left: 20%;
    }
    .user-search-btn-decline{
        padding: 5px 10px;
        font-size: 12px !important;
        margin-left: 20%;
    }
}
@media only screen and (max-width: 480px){
    .search-buddies-btn-approve{
        font-weight: lighter;
        padding: 5px 16px;
        font-size: 12px;
        left: 70%;
        margin-top: -8px;
    }
    
    .search-buddies-btn-decline{
        font-weight: lighter;
        padding: 5px 15px;
        font-size: 12px;
        left: 70%;
        margin-top: 28px;
    }

    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 60px;
    }
    .m15{
        font-size: 14px;
    }
    .search-user-user-username{
        margin-left: 14%;
        margin-top: 10px;
    }
    .search-buddy-request-btn{
        padding: 5px 14px;
        font-size: 12px;
        right: 40px;
        margin-top: 13px;
    }
    .search-buddy-requested-btn{
        padding: 3px 6px;
        font-size: 12px;
        right: 40px;
        margin-top: 13px;
    }
}

@media only screen and (max-width: 422px){
    .show-profile-buddies-back-button{
        top:35px;
        left:2%;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 55px;
    }
    p.text-blue-search-users {
        font-size: 13px;
    }
    .m15{
        font-size: 12px;
    }
    span.text-blue-search-users {
        font-size: 13px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-10.text-center img {
        width: 40px;
    }
    .right-icon-top-10 img {
        width: 25px;
        margin-top: 10px;
    }
    .footer-icon-10 img {
        width: 33%;
    }

    .sm-header-text{
        padding: 0px 10px;
        margin-top: 0px;
        margin-bottom: -40px;

    }

    .btn-green-10{
        background-color: #06E00F !important;
        margin-top: -20%;
        width: 22%!important;
        font-size: 13px!important;
    }
    .btn-red-10{
        background-color: #CC0919 !important;
        margin-top: -20%;
        margin-left: 10%;
        width: 22%!important;
        font-size: 13px!important;
    }
    .single-item-buddy-field{
        width: 90%!important;
        height: 80px !important;
        margin-top: 2% !important;
    }
    .search-user-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: 10px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    .search-user-user-username{
        margin-left: 10%;
        margin-top: -2px;
    }
    .search-user-username-size{
        font-size: 11px !important;
    }
    .btn-info-search-buddies{
        font-size: 15px !important;
    }
    .search-user-buddy-button{
        margin-right: 2% !important;
        margin-top: -40px;  
    }
    .btn-already-buddy{
        padding: 5px 15px;
        font-size: 13px !important;
    }
    .search-user-cancel-button{
        margin-top: -40px;
        margin-right: 1.5% !important;
    }
    .search-user-buttons-up{
        margin-top: -120px;
    }
    .search-user-buttons-down{
        margin-top: -90px;
    }
    .user-search-btn-approve{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-top: -40%;
        margin-bottom: 25%;
        margin-left: 30%;
    }
    .user-search-btn-decline{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-left: 30%;
    }
}
@media only screen and (max-width: 396px){
    .search-buddies-question-left{
        top:15%;
    }
    .search-buddies-question-right{
        top: 15%;
    }
    .search-buddies-question-btn{
        height: 245px;
    }
    strong.text-blue-search-users {
        font-size: 14px;
    }
    .m15 strong{
        font-size: 12px !important;
    }
    .middle-icon-footer-10 img {
        position: absolute;
        top: -30px;
        width: 65px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .footer-icon-10 img {
        width: 36%;
    }
    .buddies-10{
        width: 10%;
        margin-left: -15%;
        margin-top: 40px;
    }
    .buddies-10-position{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 420px)and (min-height:750px){
    .search-swipe-Whole-box-layout{
        position: absolute;
        top: 3% !important;
        left: 0%;
        width: 100%;
        height:78%;
    } 
}

@media only screen and (max-width: 390px){
    .search-swipe-Whole-box-layout{
        height: 73%; 
    } 
}

@media only screen and (max-width: 390px)and (max-height:700px){
    .search-swipe-Whole-box-layout{
        height: 58%; 
    } 
}

@media only screen and (max-width: 378px){
    .search-buddies-question-box-small{
        position: absolute;
        left: 0%;
        top: 480px;
        width: 100%;
        height: 15%;
        margin: auto;
    }
    .search-carousal13-carouserl-box{
        height: 350px;
    } 
    .search-carousal13-carouserl-layout{
        position: absolute;
        top: 100px !important;
        width: 100%;
    } 
    /* .search-user-user-username{
        margin-left: 30%;
        margin-top: -40px;      
    } */
    .search-user-username-size{
        font-size: 11px !important;
    }
    .btn-info-search-buddies{
        font-size: 13px !important;
    }
    .search-user-buddy-button{
        margin-right: 2% !important;  
    }
    .btn-already-buddy{
        padding: 5px 5px !important;
        font-size: 11px !important;
        margin-left: 5%;
    }
    .search-user-buttons{
        /* margin-right: -16.5% !important;  */
    }
    .search-user-buttons-up{
        margin-top: -120px;
    }
    .search-user-buttons-down{
        margin-top: -90px;
    }
}

@media only screen and (max-width: 365px){
    .search-swipe-Whole-box-layout{
        height: 57%;
    } 
}

@media only screen and (max-width: 360px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 50px;
    }
    .margin-b-10{
        margin-left: 20px;
        margin-top: -5px !important;
        margin-bottom: 20px!important;
    }
    .buddies-10{
        width: 22px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-10.text-center img {
        width: 35px;
    }
    .m15 strong {
        font-size: 11px !important;
    }
    .text-blue-username-search-users{
        color: #575578;
        font-size: 10px !important;
        font-weight: bold;
    }
    .search-user-user-username-alone{
        margin-left: 10%;
        margin-top: 20px; 
    }
    .user-search-btn-approve{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-top: -40%;
        margin-bottom: 25%;
        margin-left: 20%;
    }
    .user-search-btn-decline{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-left: 20%;
    }
}
@media only screen and (max-width: 355px){
    .search-swipe-Whole-box-layout{
        height: 43%;
        top: 50px !important;
    } 
    .search-buddies-small-btn{
        height: 35px ;
    }
    .search-buddies-small-icon-box-left{
        width: 25px;
        height: 25px;
        margin-top: 4px;
    }
    .search-buddies-small-icon-box{
        width: 25px;
        height: 25px;
        margin-top: 4px;
    }
}

@media only screen and (max-width: 341px){
    .search-buddies-all-info{
        width: 130px;
        margin-top: 0px;
        margin-left: 25px;
    }

    .profil-buddies-all-info{
        width: 130px;
        margin-left: 25px;
    }

    .search-buddies-info-username{
        font-size: 12px !important;
        top: 0%;
    }
    
    .search-buddies-info-fullname{
        font-size: 12px !important;
        top: 32%;
    }
    
    .search-buddies-info-extra{
        font-size: 10px !important;
        top: 60%;
    }

    .search-carousal13-vistit-btn{
        padding: 5px 35px;
        font-size: 14px;
    }
    .search-buddies-question-box-small{
        position: absolute;
        left: 0%;
        top: 440px;
        width: 100%;
        height: 15%;
        margin: auto;
    }
    .search-buddies-question-btn{
        height: 200px;
    }
    .search-buddies-question-text{
        width: 90%;
        margin-left: 5%;
    }
    .search-buddies-question-left{
        top:14%;
    }
    .search-buddies-question-right{
        top: 14%;
    }
    .search-buddies-question-icon-box{
        width: 100px;
        height: 100px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 47px;
    }
    .margin-b-10{
        margin-bottom: 5px;
    }
    .buddies-10{
        width: 22px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-10.text-center img {
        width: 35px;
    }
    .m15 strong {
        font-size: 11px !important;
    }
    p.text-blue-search-users {
        font-size: 12px;
    }
    .m15 strong {
        font-size: 10px !important;
    }
    .btn {
        font-size: 13px !important;
        line-height: 22px !important;
    }
    strong.text-blue-search-users {
        font-size: 13px;
    }
    .logo-text-10 img {
        width: 20px;
    }
    img.k-logo-10 {
        width: 35px !important;
        height: 35px !important;
    }
    .footer-icon-10 img {
        width: 43%;
    }
    .search-user-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: -5px !important;
        margin-top: 12% !important;
    }    
    /* .search-user-user-username{
        margin-left: 30%;
        margin-top: -40px;        
    } */
    .search-user-username-size{
        font-size: 10px !important;
    }
    .btn-info-search-buddies{
        font-size: 8px !important;
    }
    .search-user-buttons{
        margin-right: -0% !important; 
    }
    .btn-cancel-search-buddies{
        font-size: 8px !important;
        padding: 3px 10px !important;
        margin-left: 0%; 
    }
}
@media only screen and (max-width: 337px){
    .search-carousal13-carouserl-layout{
        top: 140px !important;
    } 
    .search-carousal13-carouserl-box{
        height: 300px;
    } 

    .search-carousal13-img-box{
        width: 70px;
        height: 70px;
    }
    
    .search-carousal13-sugget-username{
        font-size: 14px !important;
        margin-top: 10px;
    }
    
    .search-carousal13-sugget-full-name{
        font-size: 13px !important;
    }
    .search-carousal13-sugget-full-name-empty{
        font-size: 13px !important;
    }
    
    .search-carousal13-sugget-3-memories{
        height: 70px;
        margin-top: 15px;
    }

    .search-carousal13-sugget-3-memories-empty{
        height: 70px;
        margin-top: 15px;
    }

    .search-carousal13-empty-photos-box{
        margin-top: 15px;
    }
    
    .search-carousal13-no-memories-yet{
        color: #606062;
        font-size: 11px !important;
        font-weight: lighter;
        text-align: center;
        position: absolute;
        width: 100%;
        margin-top: 45px;
    }
    .middle-icon-footer-10 img {
        position: absolute;
        top: -27px;
        width: 57px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .buddies-10-position{
        margin-top: 20px;
    }
    .search-user-buttons{
        margin-right: -25.5% !important; 
    }
    .btn-cancel-search-buddies{
        margin-left: 17%;
    }
    .user-search-btn-approve{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-top: -40%;
        margin-bottom: 25%;
        margin-left: 10%;
    }
    .user-search-btn-decline{
        padding: 3px 5px;
        font-size: 12px !important;
        margin-left: 10%;
    }
}
@media only screen and (max-width: 300px){
    .buddies-10-position{
        margin-top: 10px;
    }
    .search-user-picture-layout{
        border-radius: 50%!important;
        width: 50px!important;
        height: 50px!important;
        margin-left: -10px !important;
        margin-top: 12% !important;
        object-fit: cover;
    }
    /* .search-user-user-username{
        margin-left: 32%;
        margin-top: -40px;        
    } */
    .search-user-username-size{
        font-size: 7px !important;
    }
}
@media only screen and (max-width: 300px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 44px;
    }
}





.search-swipe-buddies34-center-choise-box{
    position: relative;
    width:50%;
    height: 100%;
    /* margin-top: 1%; */
    /* background-color: blue; */
}

.search-swipe-buddies34-center-choise-icon-box{
    position: relative;
    width:80%;
    /* height: 40px; */
    position: absolute;
    bottom: 0%;
    left: 10%;
    font-weight: bold;
    border-radius: 4px;
    color: white !important;
    text-align: center;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    font-size: 12px;
    line-height: 40px;
}

.search-swipe-buddies34-center-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 0%;
  }

  .search-swipe-buddies34-center-recuestr-choise-icon-box{
    position: relative;
    width:80%;
    /* height: 40px; */
    position: absolute;
    bottom: 0%;
    left: 10%;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    font-weight: lighter;
    background: #fff;
    color: rgb(59, 59, 59) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    border: solid 0.5px rgb(204, 204, 204);
  }

  .search-swipe-buddies34-center-recuestr-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 0%;
  }

.search-swipe-buddies34-visit-next-position{
    position: absolute;
    top: 80px;
    right: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.search-swipe-buddies34-visit-add-position{
    position: absolute;
    top: 80px;
    left: 20px;
    width: 50%;
    height: 20%;
    opacity: 0.9;
}
.search-swipe-buddies34-visit-profile-position{
    position: absolute;
    bottom: 140px;
    left: 20%;
    width: 60%;
    height: 20%;  
    opacity: 0.9;
    z-index: 9999;
}
.search-swipe-buddies34-visit-icon-img{
    width: 100%;
    height: 100%;
}
.search-swipe-buddies34-all-choises-btns{
    width: 100% !important;
    height: 10%;
    /* background-color: red; */
    position: absolute;
    top: 87%;
    /* left: 0%; */
}
.search-swipe-buddies34-all-choises-row{
    position: relative;
    width: 100% !important;
    height: 100%;
    top: 0%;
    margin-left: 0%;
}
.search-swipe-buddies34-choise-box{
    position: relative;
    width:25%;
    height: 100%;
    /* background-color: red; */
}

.search-swipe-buddies34-choise-icon-box{
    position: relative;
    width:70%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
}

.search-swipe-buddies34-choise-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.search-swipe-buddies34-choise-icon-box-small{
    position: relative;
    width:50%;
    /* height: 50px; */
    /* background-color: rgb(93, 93, 153); */
    margin: auto;
    margin-top: 12%;
}

.search-swipe-buddies34-choise-icon-box-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }



  .search-swipe-buddies34-choise-icon-img{
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
}

.back-button-search-swipe-buddies34-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.search-swipe-buddies34-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.search-swipe-buddies34-memories-box-empty{
    width: 90%;
    height: 18%;
    background: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(241, 241, 241) 50%);
    position: absolute;
    top: 68%;
    left:5%;
    border-radius: 20px;
}

.search-swipe-buddies34-no-memories-yet{
    color: #606062;
    font-size: 12px !important;
    font-weight: lighter;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0%;
    margin-top: 60px;
    /* background-color: darkred; */
}
.search-swipe-buddies34-empty-photos-icon{
    width: 100%;
    height: 100%;
}

.search-swipe-buddies34-empty-photos-box{
    margin: auto;
    width: 5%;
    /* height: 18px; */
    margin-top: 20px;
}

.search-swipe-buddies34-memory-size{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9999;
}

.search-swipe-buddies34-memory-empty{
    width: 100%;
    height: 100%;
    /* background-color: rgb(27, 19, 19); */
    background: linear-gradient(0deg, rgb(194, 192, 192) 0%, rgb(117, 116, 116) 50%);
}

.search-swipe-buddies34-username{
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 60%;
    left: 5%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
    color: #fff !important;
}

.search-swipe-buddies34-memories-box{
    width: 100%;
    height: 18%;
    /* background-color: rgb(53, 42, 42);; */
    position: absolute;
    top: 68%;
    left: 0%;
}

.search-swipe-buddies34-memory-box-1{
    position: absolute;
    width: 30%;
    left: 3%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.search-swipe-buddies34-memory-box-2{
    position: absolute;
    width: 30%;
    left: 35%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.search-swipe-buddies34-memory-box-3{ 
    position: absolute;
    width: 30%;
    left: 67%;
    height: 100%;
    /* background-color: rgb(112, 92, 185); */
}

.search-swipe-buddies34-swipe-card{
    position: absolute;
    width: 96%;
    height: 100%;
    background-color: rgb(27, 19, 19);
    top: 0%;
    left: 2%;
    border-radius: 20px;
}

.search-swipe-buddies34-swipe-box{
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0%;
    left: 0%;
}

.search-swipe-buddies34-swipe-square{
    /* background-color: #000; */
    width: 100% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -80px 30px 10px rgb(27, 19, 19) inset !important;
}
.search-swipe-buddies34-black-cover-box{
    width: 100%;
    height: 5%;
    background-color: rgb(27, 19, 19);
    position: absolute;
    top: 80%;
    left:0%;
}
  
.search-swipe-buddies34-swipe-square:after {
    content: "";
    display: block;
    padding-bottom: 120%;
  }
.search-swipe-buddies34-swipe-square-small{
    width: 50% !important;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}
  
.search-swipe-buddies34-swipe-square-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.search-swipe-buddies34-swipe-img{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.search-swipe-buddies34-right-10{
    margin-left: 16.67%;    
}

.search-swipe-buddies34-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1200px){
    .search-swipe-buddies34-right-10{
        margin-left: initial;
    }
    .search-swipe-buddies34-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
        }

}

@media only screen and (max-width: 355px){
    .search-swipe-buddies34-username{
        font-size: 20px;
        top: 57%;
    }
    .search-swipe-buddies34-empty-photos-box{
        margin: auto;
        width: 5%;
        /* height: 18px; */
        margin-top: 10px;
    }
    .search-swipe-buddies34-no-memories-yet{
        margin-top: 40px;
    }
}