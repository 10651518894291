@-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }
  
    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }
  
  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }
  
    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
}

.notification-no-notification{
    width: 50%;
    height: 200%;
    position: absolute;
    left: 25%;
}

.notification-no-notification-icon-box{
    width: 20%;
    height: 20%;
    position: absolute;
    top: 10%;
    left: 40%;
    z-index: 99;
}

.notification-no-notification-icon{
    width: 100%;
    height: 100%;
    -webkit-animation: ring 5s 1.5s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 5s 1.5s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 5s 1.5s ease-in-out infinite;
    transform-origin: 50% 4px;
}

.notification-no-notification-text{
    color: #575578;
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 10%;
    width: 80%;
    height: 20%;    
}

.notification-tag-green{
    background-color: #06E00F !important;
    color: white !important;
    cursor: pointer;
    border: none !important;
    font-weight: bold !important;
    font-size: 14px;
    border-radius: 30px !important;
    width: 20% !important;
    color: #ffff !important;
    text-align: center;
    padding: 2px 6px;
    margin-left: 20%;
}
.notification-tag-red{
    background-color: #CC0919 !important;
    color: white !important;
    cursor: pointer;
    font-weight: bold !important;
    font-size: 14px;
    border-radius: 30px !important;
    width: 20% !important;
    color: #ffff !important;
    text-align: center;
    padding: 2px 6px;
    margin-left: 10%;

}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* loading */
.notifications-section-disappear{
    display: none;
}
.notifications-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.notifications-lottie-dissappear{
    display: none;
}
/* end loading */

.text-blue-9{
    color: #575578;
}
.notification-row-position{
    margin: auto !important;
}
.notification-whole-page-layout{
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
}
/* Body left css start */
.body-left-9{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.logo-9{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic-9{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text-9 strong, p{
    color: #fff;
}
.text-white-9{
    color: #fff;
}
.left-text-9{
    font-size: 15px;
}
.left-icon-9 img{
    width: 45%;
}
.left-home-9{
    cursor: pointer;
}
.left-home-9:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.body-right-9{
    margin-left: 16.67%;
    background: #fff;
}


.initial-notifications-welcome{
    width: 100%;
    margin-left: 20%;
    margin-top: 20%;
}
.initial-notifications-welcome-profile{
    width: 120%;
    margin-left: 20%;
    margin-top: 20%;
    border-radius: 50%;  
}
.welcome-notification-text-possition{
    margin-left: 0% !important;
    margin-top: 8% !important;
    text-align: center !important;
}

.notification-text-9{
    margin-top: 10px;
}
.notification-text-9 p{
    margin-bottom: 5px;
}
.right-icon-9 img{
    margin-top: 10px;
}
.logo-text-9{
    display: flex;
    justify-content: space-between;
}
.w-small-9{
    max-width: 80% !important;
}
.notification7-9{
    margin-top: 3.5rem;
}
.cross-text-9{
    display: flex;
    justify-content: space-between;
}
.cross-text-9 img{
    width: 35px;
}
.small-header-container-9{
    display: none;
}

.margin-top-9{
    padding-top: 1.3rem !important;
}
.buddies-9{
    width: 6%;
    margin-left: -70px;
    margin-top: 50px;
}
.notifications-buddies-9-cancelled{
    margin-top: 30px;
}
.k-logo-9{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%!important;
    margin-top: -10px;
}
.text-blue-empty{
    margin-top: 7%;
    margin-left: 30%;
}



.notifications-box-size{
    width: 100%;
}
.single-item-9{
    /* width: 200% !important; */
    /* max-width: 100% !important; */
    background: #fff;
    padding: 15px 10px;
    border-radius: 4px;
    position: initial;
    top: initial; 
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    height: 10rem;
    width: 60% !important;
    margin: auto;
    margin-bottom: 10px;
    border: solid 0.5px rgb(204, 204, 204);
}
.notification-related-photo-position{
    width:60px;
    height: 60px;
}
.related-picture-layout{
    border-radius: 50%!important;
    width: 100%!important;
    height: 100%!important;
    object-fit: cover; 
    /* margin-right: 60px; */
}
.notification-section-icon-layout{
    position: absolute;
    /* background: red; */
    width: 5%;
    height: 10%;
    margin-top: 100px;
    margin-left: 5px;
}
.notification-section-icon{
    width: 20px;
    height: 20px;
    /* margin-top: 30px; */
}

.notification-information-section{
    position: relative;
    width: 60%;
    height: 70%;
    left: 5%;
    top: -10%;
}

.notification-meeting-info{
    position: relative;
    width: 70%;
    height: 20%;
    left: 4%;
    top: -0%;
    z-index: 1;
    margin: auto;
    font-size: 20px !important;
    font-weight: bold;
}
.notification-text-position{
    position: relative;
    z-index: 1;
    margin: auto;
    top: 20%;
    font-size: 20px !important;
    /* font-weight: bold; */
}
.notification-text-layout{
    font-size: 16px !important;
}
.notification-related-icon-layout{
    position: relative;
    width:15%;
    height: 80%;
    z-index: 1;
    left: 10%;
    top: 10%;
}
.notification-related-icon-size{
    width: 80px;
    border-radius: 50%;
}
.notification-buttons-layout{
    width: 60%;
    margin: auto;
}
.btn-green-9{
    background-color: #06E00F !important;
    color: white !important;
    cursor: pointer;
}
.btn-red-9{
    background-color: #CC0919 !important;
    color: white !important;
    cursor: pointer;
    margin-left: 10%;
}
.btn-green-9, .btn-red-9{
    border: none !important;
    font-weight: bold !important;
    font-size: 16px;
    border-radius: 30px !important;
    width: 35% !important;
    color: #ffff !important;
    text-align: center;
    padding: 5px 10px;
    /* margin: auto; */
}
.btn-light-notifications{
    background: #fff !important;
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    width: 60%;
    text-align: center;
    margin: auto;
    margin-top: -5%;
}

.btn-light-notifications-tag{
    background: #fff !important;
    border: none !important;
    padding: 3px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    width: 40%;
    text-align: center;
    margin: auto;
    margin-top: 0%;
}



.notification-text-possition{
    margin-left: 10px !important;
    margin-bottom: 5%;
}
.notification-text-layout-possition strong{
    margin-left: 0% !important;
    /* width: 100% !important; */
    /* margin: auto; */
    text-align: center !important;
}
.buddies-9-position{
    margin-top: 20px;
}

/* Footer icon css start */
.nav-bottom-9{
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon-9 img {
    width: 18%;
}
.middle-icon-footer-9 img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 50%;
    transform: translateX(-50%);
}
.footer-width-9{
    width: 20% !important;
}
.nav-bottom-9{
    display: none !important;
}
.nav-bottom-9{
    z-index: 99999999999 !important;
}
/* Footer icon css end */

.impression-notification-mika-img{
    width: 100%;
    height: 100%;
    /* margin-top: -13px; */
    object-fit: contain;
    margin-top: 0%;
    /* z-index: 99999999999999999 !important; */
}
.impression-notification-mika-img-cover{
    position: absolute;
    width: 100%;
    height:80% !important;
    left: 0%;
    top:10%;
    margin: auto !important;
    border-radius: 0px;
    object-fit: cover;
    /* opacity: 0.8; */
    filter: blur(10px);
    -webkit-filter: blur(10px);
    z-index: -1 !important;
}

@media only screen and (max-width: 1200px){
    .notifications-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }
    body{
        background-color: #fff !important;
    }
    .body-left-9{
        display: none;
    }
    .body-right-9{
        margin-left: initial;
        border-radius: 50px 50px 0px 0px;
        padding-top: 30px !important;
        padding-bottom: 130px !important;
        margin-bottom: 40px !important;
        height: 1000% !important;
    }
    .small-header-container-9{
        display: block;
    }
    .notifications-hide-h3{
        display: none;
    }
    .sm-header-text{
        padding: 30px 10px;
        margin-top: 20px;
    }
    .title-font-layout{
        font-size: 18px;
        text-align:center;
        margin-top: 6px;
        margin-bottom: 6px;
        font-weight: bold;
    }
    .color-9{
        background: rgb(144,36,103);
        background: linear-gradient(300deg, rgba(144,36,103,1) 35%, rgba(28,48,111,1) 100%);
    }
    .nav-bottom-9{
        display: block !important;
        z-index: 99999999999;
    }

    .single-item-9{
        width: 70% !important;
    }
}


@media only screen and (max-width: 800px){
    .body-right-9{
        height: 900px;
    }
    .single-item-9{
        height: 7em !important;
        width: 80%;
    }
    .notification-section-icon-layout{
        margin-top: 60px;
    }
    .notification-section-icon{
        margin-top: 10px;
    }
    .notification-information-section{
        top: -18% !important;
    }
    
    .notification-meeting-info{
        width: 80%;
        font-size: 15px !important;
    }
    .notification-text-layout{
        font-size: 14px !important;
    }
    .notification-related-icon-layout{
        width:14%;
        height: 80%;
        z-index: 1;
        left: 6%;
        top: 10%;
    }
    .notification-related-icon-size{
        width: 55px;
    }
    .notification-buttons-layout{
        margin-top: -0%;
    }
    .btn-green-9, .btn-red-9{
        font-size: 12px;
        padding: 4px 8px;
    }
}


@media only screen and (max-width: 576px){



    .notification-no-notification{
        width: 80%;
        height: 300%;
        position: absolute;
        left: 10%;
    }
    
    .notification-no-notification-icon-box{
        width: 60%;
        height: 30%;
        position: absolute;
        top: 25%;
        left: 20%;
        z-index: 99;
    }

    
    .notification-no-notification-text{
        color: #575578;
        font-size: 18px;
        text-align: center;
        position: absolute;
        top: 60%;
        left: 10%;
        width: 80%;
        height: 20%;    
    }





    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 65px;
    }
    .logo-text-9 img {
        width: 22px;
    }
    img.k-logo-9 {
        width: 40px;
        height: 40px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-9.text-center img {
        width: 45px;
    }
    .right-icon-top-9 img{
        width: 30px;
    }
    .notification-text-9 {
        margin-top: 5px;
    }
    .body-right-9{
        margin-left: initial;
        border-radius: 0px 0px 0px 0px;
        padding-top: 30px;
    }
    .color-9{
        background: #fff;
    }
    .small-header-container-9 {
        background: url(./svg/bg-color.svg) no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 9999;
        border-radius: 0px 0px 0px 0px;
        padding-bottom:0px;
    }
    .col-xl-10.col-lg-12.body-right-9.pb-5 {
        padding-bottom: 0rem !important;
    }
    .footer-icon-9 img {
        width: 25%;
    }
    .middle-icon-footer-9 img {
        position: absolute;
        top: -38px;
        width: 78px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .buddies-9{
        width: 8%;
        margin-left: -20%;
        margin-top: 50px;
    }
    .buddies-9-position{
        margin-top: 20px;
    }
    .all-notifications-layout{
        position: relative;
        margin-top: 50px;
        z-index: 999999 !important;
    }
    .single-item-9{
        height: 9em !important;
        width: 90% !important;
    }
    .notification-section-icon{
        margin-top: 0px;
    }
    .notification-information-section{
        top: -10% !important;
    }
    .notification-meeting-info{
        width: 80%;
        font-size: 15px !important;
    }
    .notification-section-icon-layout{
        margin-top: 90px;
        /* position: relative; */
    }
}
@media only screen and (max-width: 504px){
    .impression-notification-mika-img{
        z-index: 1 !important;
    }
    .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .195rem .75rem !important;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .footer-icon-9 img {
        width: 30%;
    }
    .middle-icon-footer-9 img {
        position: absolute;
        top: -34px;
        width: 75px !important;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media only screen and (max-width: 480px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 60px;
    }
    .m15{
        font-size: 14px;
    }
    .right-icon-top-9 img {
        margin-left: -20px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-9.text-center img {
        width: 40px;
        margin-left: -20px;
    }
    .notification-text-layout{
        font-size: 13px !important;
    }
    .notification-buttons-layout{
        width: 60%;
        margin-top: -0%;
    }
    .btn-green-9, .btn-red-9{
        border: none !important;
        font-weight: bold !important;
        font-size: 10px;
        border-radius: 30px !important;
        width: 35% !important;
        color: #ffff !important;
        text-align: center;
        padding: 3px 6px;
        margin-left: 10%;
        /* margin: auto; */
    }
}
@media only screen and (max-width: 422px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 55px;
    }
    .m15{
        font-size: 12px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-9.text-center img {
        width: 40px;
    }
    .right-icon-top-9 img {
        width: 25px;
        margin-top: 10px;
    }
    .footer-icon-9 img {
        width: 33%;
    }
    .sm-header-text{
        padding: 30px 10px;
        margin-top: 0px;
        margin-bottom: -40px;

    }
    .notification-information-section{
        top: -10% !important;
    }
    
    .notification-meeting-info{
        left: 0%;
        width: 80%;
        font-size: 13px !important;
    }
    .notification-related-icon-size{
        width: 50px;
        margin-top: 35%;
    }
    .notification-related-photo-position{
        width:50px;
        height: 50px;
    }
}
@media only screen and (max-width: 396px){
    .notification-tag-green{
        margin-top: 5px;
        font-size: 12px;
    }
    .notification-tag-red{
        margin-top: 5px;
        font-size: 12px;
    }

    .m15 strong{
        font-size: 12px !important;
    }
    .middle-icon-footer-9 img {
        position: absolute;
        top: -30px;
        width: 65px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .footer-icon-9 img {
        width: 36%;
    }
    .buddies-9{
        width: 10%;
        margin-left: -15%;
        margin-top: 40px;
    }
    .buddies-9-position{
        margin-top: 20px;
    }

    .notifications-buddies-9-cancelled{
        margin-top: 10px;
    }
    .single-item-9{
        height: 7em !important;
        width: 90% !important;
    }
    .notification-related-icon-size{
        width: 40px;
        margin-top: 25%;
    }
    .notification-text-position{
        top: 10%;
    }
    .notification-text-layout{
        font-size: 12px !important;
    }
    .notification-section-icon-layout{
        margin-top: 65px;
    }
}
@media only screen and (max-width: 370px){
    .notification-information-section{
        top: -10% !important;
    }
    
    .notification-meeting-info{
        left: 0%;
        width: 100%;
        font-size: 13px !important;
    }
}
@media only screen and (max-width: 360px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 50px;
    }
    .margin-b-9{
        margin-left: 20px;
        margin-top: -5px !important;
        margin-bottom: 20px!important;
    }
    .buddies-9{
        width: 22px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-9.text-center img {
        width: 35px;
    }
    .m15 strong {
        font-size: 11px !important;
    }
}
@media only screen and (max-width: 341px){
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 47px;
    }
    .margin-b-9{
        margin-bottom: 5px;
    }
    .buddies-9{
        width: 22px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.right-icon-9.text-center img {
        width: 35px;
    }
    .m15 strong {
        font-size: 11px !important;
    }

    .m15 strong {
        font-size: 10px !important;
    }
    .btn {
        font-size: 13px !important;
        line-height: 22px !important;
    }

    .logo-text-9 img {
        width: 20px;
    }
    img.k-logo-9 {
        width: 35px !important;
        height: 35px !important;
    }
    .footer-icon-9 img {
        width: 43%;
    }
}
@media only screen and (max-width: 337px){
    .middle-icon-footer-9 img {
        position: absolute;
        top: -27px;
        width: 57px !important;
        left: 50%;
        transform: translateX(-50%);
    }
    .buddies-9-position{
        margin-top: 20px;
    }
    .notification-text-position{
        top: 5%;
    }
    .notification-text-layout{
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 300px){
    .buddies-9-position{
        margin-top: 10px;
    }
    .col-xl-2.col-lg-2.col-md-2.col-sm-2.col-2.text-right img {
        width: 44px;
    }
    .notification-information-section{
        top: -10% !important;
    }
    
    .notification-meeting-info{
        left: 0%;
        width: 100%;
        font-size: 11px !important;
    }
    .btn-light-notifications{
        width: 80%;
    }
}

