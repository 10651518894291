.memory-tags-remove-tag-text{
    color: #7e7b83;
    position: absolute;
    bottom: 5%;
    left: 15%;
}


.memory-tags-remove-yes{
    background-color: #06E00F !important;
    color: white !important;
    cursor: pointer;
    border: none !important;
    font-weight: bold !important;
    font-size: 16px;
    border-radius: 30px !important;
    width: 15% !important;
    color: #ffff !important;
    text-align: center;
    padding: 5px 10px;
    margin-left: 30px;
    position: absolute;
    bottom: 4%;
    left: 38%;
}

.memory-tags-remove-no{
    background-color: #CC0919 !important;
    color: white !important;
    cursor: pointer;
    margin-left: 4%;
    border: none !important;
    font-weight: bold !important;
    font-size: 16px;
    border-radius: 30px !important;
    width: 15% !important;
    color: #ffff !important;
    text-align: center;
    padding: 5px 10px;
    margin-left: 30px;
    position: absolute;
    bottom: 4%;
    left: 56%;
}

.add-tag-back-icon{
    width: 20px;
    position: absolute;
    bottom: 5%;
    left: 5%;
}
.add-tag-back-img{
    width: 100%;
}
.add-tag-search{
    margin-top: 3%;
    border: none;
    width: 60%;
}
.add-tag-search input{
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}

.memory-tags-add-slider-title{
    position: relative;
    font-size: 18px !important;
    margin-top: 17%;
    color: #3F1E75;
    z-index: 3 !important;
}

.memory-tags-add-slider{
    width:70%;
    height: 15%;
    /* background-color: red; */
    position: absolute;
    top: 55%;
    left: 5%;
}
.memory-tags-add-tag-btn{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: white!important;
    cursor: pointer;
    /* margin: auto; */
    width: 80%;
    text-align: center;
}

.memory-tags-added-tag-btn{
    background: rgb(200, 200, 200);
    background: linear-gradient(0deg, rgb(248, 248, 248) 0%, rgb(241, 241, 241) 100%);
    color:  #575578 !important;    
    border: none !important;
    padding: 8px 20px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    width: 80%;
}

.memory-tags-add-buddy-frame{
    width: 80px;
    height: 80px;
    /* background-color: tomato; */
}

.memory-tags-add-buddy{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: auto;
}
.memory-tags-add-buddy-username{
    color: #3F1E75;
    text-align: center;
}

.memory-tags-action-icon{
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5.7%;
    left: 5%;
}

.memory-tags-action-img{
    width: 100% !important;
}

.memory-tags-action-text{
    color: #7e7b83;
    position: absolute;
    bottom: 5%;
    left: 15%;
}



.memory-tags-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 580px !important;
    margin-top: -245%;
    margin-left: -160%;
    width: 450%;
    z-index: 9999999999999999999 !important;
}

.memory-tags-cancle-btn {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    border:double 1px rgb(179, 171, 209);
}
.memory-tags-close-icon{
    margin-top: 6px;
    width:60%;
}
.memory-tags-title-box{
    width: 50% !important;
    margin-left: 3%;
}
.memory-tags-title{
    position: relative;
    font-size: 20px !important;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
}
.memory-tags-datepicker{
    margin: auto;
    /* width: 65%; */
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
    margin-left: 20px;
}
.memory-tags-slider{
    margin-top: 3%;
    height: 85% !important;
}
.memory-tags-item{
    width: 100%;
}
.memory-tags-single-item{
    width: 80%!important;
    background: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto !important;
}

.memory-tags-user-info{
    width: 20%;
}
.memory-tags-user-picture{
    border-radius: 50%!important;
    width: 60px!important;
    height: 60px!important;
    object-fit: cover;
}

.memory-tags-info{
    width: 60%;
}
.memory-tags-info-layout{
    margin-top: 20px;
}
.memory-tags-text{
    color: #575578;
    font-size: 24px;
    margin-top: -10px;
}
.memory-tags-btn{
    width: 20%;
}
.memory-tags-btn-sellect {
    /* position: absolute; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}
.memory-tags-btn-sellected {
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    background: #fff;
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}
@media only screen and (max-width: 600px){
    .memory-tags-action-icon{
        margin-top: 20px;
        margin-left: 20px;
    }
    .memory-tags-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px 0px 0px 50px;
        background: #fff; 
        height: 520px !important;
        margin-top: -215%;
        margin-left: -20%;
        width: 420%;
        z-index: 99999999999999;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    }
    .memory-tags-title-box{
        width: 70% !important;
        margin-left: 12%;
    }
    .memory-tags-title{
        font-size: 15px !important;
    }
    .memory-tags-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 22px;
        top: 0px;
        left: 3%;
    }
    .memory-tags-close-icon{
        margin-top: 0px;
        width:60%;
    }
    .memory-tags-single-item{
        width: 100%!important;
        padding: 5px 2px;
        border-radius: 0px;
    }
    
    .memory-tags-user-info{
        width: 20%;
    }
    .memory-tags-user-picture{
        width: 40px!important;
        height: 40px!important;
    }
    
    .memory-tags-info-layout{
        margin-top: 10px;
        height: 100%;
        width: 100%;
    }
    .memory-tags-text{
        color: #575578;
        font-size: 16px !important;
        /* margin-top: -15px !important; */
        font-weight: bold;
    }
    .memory-tags-btn{
        width: 20%;
    }
    .memory-tags-btn-sellect {
        /* position: absolute; */
        padding: 4px 9px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        cursor: pointer;
        color: white !important;
        text-align: center;
        width: 100%;
        margin-top: 30%;
    }
    .memory-tags-btn-sellected {
        /* position: absolute; */
        padding: 4px 9px !important;
        font-size: 11px !important;
        width: 100%;
        margin-top: 30%;
    }
}

@media only screen and (max-width: 540px){
    .memory-tags-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        border:double 1px rgb(179, 171, 209);
    }
    .memory-tags-close-icon{
        margin-top: -10px;
        width:60%;
    }
}

@media only screen and (max-width: 500px){
    .memory-tags-modal-box {
        margin-left: -75%;
        width: 420%;
        margin-top: -215%;   
    }
}

@media only screen and (max-width: 400px){
    .memory-tags-modal-box {
        margin-top: -200% !important;  
    }
    .memory-tags-action-icon{
        margin-top: 22px;
        margin-left: 20px;
    }
    .memory-tags-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .memory-tags-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
    .memory-tags-modal-box {
        margin-left: -85%;
        width: 440%;
        margin-top: -245%;
    }
    .memory-tags-title-box{
        width: 60% !important;
        margin-left: 15%;
    }
}

@media only screen and (max-width: 370px){
    .memory-tags-modal-box {
        margin-top: -215% !important;  
    }
}

@media only screen and (max-width: 350px){
    .memory-tags-modal-box {
        margin-top: -180% !important;  
    }
}