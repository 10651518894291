/* loading */
.landing-page-section-disappear{
    display: none;
}
.landing-page-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.landing-page-lottie-dissappear{
    display: none;  
}
/* end loading */
.landing-all-page-display{
    display: none;
}

@media only screen and (max-width: 1200px){
    .landing-page-flag-section{
        width: 100%;
    }
    .landing-page-flag{
        width: 8%;
        margin: auto;
        margin-top: 100px;
    }
    .landing-page-flag-img{
        width: 100% !important;
    } 
    .landing-page-flag-rs{
        width: 8.2%;
        margin: auto;
        margin-top: 98px;
    }


    .landing-page-flag-img-slc{
        width: 100%;
        border-radius: 50%;
        border:double 2px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }


    .landing-page-whole-page-layout{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 519%;
        background-color: #fff;
    }
    .landing-page-whole-page-layout-el{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 519%;
        background-color: #fff;
    }
    .landing-all-page-display{
        display: block;
    }

    .landing-page-header-section{
        position: fixed;
        background-color: #fff;
        border-bottom: solid 1px #434258;

    }
    .landing-page-top-logo{
        width: 8%;
        /* margin: auto; */
        margin-top: 15px;
        margin-left: 30px;
    }
    .landing-page-logo-img{
        width: 100%;
    }
    .landing-page-top-logo-name{
        width: 40%;
        margin-left: -25px;
        margin-top: 17px;
        margin-bottom: 10px;
    }
    .landing-page-logo-name-img{
        width: 100%;
    }

    .landing-page-sigup-btn{
        text-align: center;
        position: absolute;
        right: 4%;
        top: 24%;
        padding: 4px 15px;
        border-radius: 30px;
        background: #fff;
        border: none;
        font-weight: bold;
        color: #553DB1;
        /* -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3); */
        cursor: pointer;
        font-size: 12px;

    }
    .landing-page-title{
        color: #434258;
        margin-top: 40px;
        font-size: 24px;
        font-weight: bolder;
        margin-left: 7%;
        width: 85%;
        /* text-align: justify; */
    }
    .landing-page-subtitle{
        color: #757495;
        margin-top: 20px;
        font-size: 14px;
        margin-left: 7%;
        width: 85%;
        /* text-align: justify; */
    }
    .landing-page-down-title{
        color: #434258;
        margin-top: 40px;
        font-size: 24px;
        font-weight: bolder;
        text-align: center;
        margin-bottom: -20px;
    }
    .landing-page-download-google{
        width: 50%;
        margin: auto;
        margin-top: 40px;
    }
    .landing-page-download-huawei{
        width: 51%;
        margin: auto;
        margin-top: 20px;
    }
    .landing-page-title-visite{
        color: #757495;
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
    }
    .landing-page-site{
        color: #434258;
        margin-top: 10px;
        font-size: 25px;
        text-align: center;
    }
    .landing-page-eplaining-number{
        width: 20%;
        margin: auto;
        margin-top: 70px;
    }
    .landing-page-eplaining-title{
        color: #434258;
        margin-top: 10px;
        font-size: 25px;
        text-align: center;
    }    
    .landing-page-eplaining-image{
        width: 60%;
        margin: auto;
        margin-top: 30px;
    }
    .landing-page-eplaining-image-lg{
        width: 100%;
        margin: auto;
        margin-top: 30px;
    }
}