.conversation-design-done-btn{
    position: absolute;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bolder;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 8px 16px -20px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 40px -20px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 40%;
    text-align: center;
    /* height: 20%; */
    left: 30%;
    font-size: 12px;
    top: 62%;
    font-size: 25px;
    /* border-radius: 20px 20px 0px 0px !important; */
    box-shadow: 0px 0px 12px 1px rgba(45, 6, 104, 0.3);
}

.conversation-design-slider-title{
    width: 90%;
    height: 10%;
    font-size: 14px;
    color: #272635;
    position: absolute;
    left: 5%;
    top: 30px;
}

.conversation-design-slider-layout{
    position: absolute;
    top: 130px;
    left: 0%;
    width: 100%;
}

.conversation-design-slider-frame{
    width: 100%;
    height: 100%;
    min-height: 150px;

}

.conversation-design-color-box{
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.conversation-design-color-box-pressed{
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 7px;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border: 3px solid rgb(204, 204, 204);
}

.conversation-design-times{
    position: absolute;
    width: 5%;
    right: 3%;
    top: 3%;
    z-index: 99999999 !important;
}

.conversation-design-buddy-name{
    position: absolute !important;
    top: 2.8%;
    width: 100%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 0%;
    color: #272635;
    font-size: 18px !important;
    font-weight: bold;
}

.conversation-design-title-hr{
    position: absolute !important;
    width: 100%;
    height: 10px;
    top:35px;
}

.conversation-design-all-choises{
    position: absolute;
    top: 60px;
    width: 100%;
    height: 400px;
    /* background-color: rgb(34, 45, 202); */
}

.conversation-design-choise{
    width: 90%;
    margin-left: 5%;
    height: 24px;

}

.conversation-design-choise-text{
    width: 100%;
    text-align: left;
    color: #272635;
    font-size: 16px !important;
    font-weight: normal;
}

.conversation-design-remove-icon-box{
    position: relative;
    float: right;
    width: 25px;
    height: 25px;
    margin-top: -25px;
    z-index: 99999999 !important;
}

.conversation-design-remove-icon{
    position: absolute;
    width: 100%;
    height: 100%;
}


.conversation-design-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 30px;
    background: #fff; 
    margin-top: -250px;
    margin-left: -100px;
    height: 500px!important;
    width: 500px;
    /* object-fit: cover; */
} 

@media only screen and (max-width: 1200px){
    .conversation-design-box{
        margin-top: -400px;
        margin-left: -270px;
        height: 600px!important;
        width: 600px;
    } 

}


@media only screen and (max-width: 992px){
    .conversation-design-box{
        margin-top: -200px;
        margin-left: -180px;
        height: 400px!important;
        width: 400px;
    } 
}


@media only screen and (max-width: 480px){
    .conversation-design-box{
        margin-top: 0px;
        margin-left: -194px;
        height: 450px!important;
        width: 420px;
        z-index: 9 !important;
    } 
    .conversation-design-times{
        width: 5%;
        right: 4%;
        top: 3.5%;
        z-index: 99999999 !important;
    }
}

@media only screen and (max-width: 410px){
    .conversation-design-box{
        margin-left: -180px;
        height: 750px!important;
        width: 390px;
        z-index: 9 !important;
    } 
    .conversation-design-buddy-name{
        font-size: 17px!important;
        top: 2.5%;
    }
    .conversation-design-times{
        right: 4%;
        top: 1.5%;
    }


    .conversation-design-slider-layout{
        top: 100px;
    }

    .conversation-design-done-btn{
        top: 52%;
        font-size: 20px;
    }
    

}
@media only screen and (max-width: 370px){
    .conversation-design-box{
        margin-left: -162px;
        width: 364px;
    } 
    .conversation-design-buddy-name{
        top: 2%;
    }
    .conversation-design-times{
        right: 4%;
        /* top: 2.5%; */
    }
}
@media only screen and (max-width: 340px){
    .conversation-design-slider-layout{
        top: 100px;
    }
    .conversation-design-box{
        margin-left: -152px;
        width: 334px;
    }
    .conversation-design-buddy-name{
        top: 2%;
        font-size: 16px !important;
    }
    .conversation-design-times{
        right: 5%;
        /* top: 1.5%; */
    }

    .conversation-design-color-box{
        width: 60px;
        height: 60px;
    }
    .conversation-design-color-box-pressed{
        width: 50px;
        height: 50px;
    }
    .conversation-design-done-btn{
        top: 44%;
        font-size: 18px;
        width: 30%;
        left: 35%;
        text-align: center;
        padding: 5px 0px;

    }
}