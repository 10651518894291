.register-login-started-gif{
    position: absolute;
    top: 35%;
    left: 30%;
    width: 40%;
}

.register-top-explain-text{ 
    color: #575578;
    margin-bottom: 0px !important;
    margin-top: 30px !important;
    text-align: center;
    font-size: 14px !important;

}

.register-top-explain-mail-text{
    margin-bottom: 0px !important;
    margin-top: 30px !important;
    text-align: center;
    font-size: 16px !important;
    color: #232231 !important;
}
.register-empty-spaxe-fix{
    width:100%;
    height: 60px;
}

.register-center-top-two-btns{
    width: 100%;
    height: 50px;
    /* background-color: red; */
    border-radius: 60px 60px 0px 0px;
    position: absolute;
    top: 0%;
    left: 0%;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
}

.register-center-top-two-btns-left{
    width: 50%;
    height: 50px;
    /* background-color: rgb(76, 0, 255); */
    border-radius: 30px 0px 0px 0px;
    position: absolute;
    top: 0%;
    left: 0%;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    color: #575578;
    border-bottom: 1px solid #575578;
    /* border-right: 1px solid #575578; */
    background: linear-gradient(180deg, rgb(192, 192, 192) 0%, rgb(235, 235, 235) 65%);
}

.register-center-top-two-btns-left-sellected{
    width: 50%;
    height: 50px;
    /* background-color: rgb(0, 255, 76); */
    border-radius: 30px 0px 0px 0px;
    position: absolute;
    top: 0%;
    left: 0%;
    color: #232231;
    font-weight: bold;
}

.register-center-top-two-btns-right{
    width: 50%;
    height: 50px;
    /* background-color: rgb(76, 0, 255); */
    border-radius: 0px 30px 0px 0px;
    position: absolute;
    top: 0%;
    left: 50%;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    color: #575578;
    border-bottom: 1px solid #575578;
    /* border-right: 1px solid #575578; */
    background: linear-gradient(180deg, rgb(192, 192, 192) 0%, rgb(235, 235, 235) 65%);
}

.register-center-top-two-btns-right-selected{
    width: 50%;
    height: 50px;
    /* background-color: rgb(0, 255, 76); */
    border-radius: 0px 30px 0px 0px;
    position: absolute;
    top: 0%;
    left: 50%;
    color: #232231 !important;
    font-weight: bold;
}

.register-map-box-layout{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: black;
}
.register-map-img-layout{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.register-center-box-layout{
    width: 98%;
    height: 96%;
    border-radius: 60px 60px 60px 60px;
    background-color: rgb(245, 245, 245);
    position:absolute;
    top: 2%;
    left: 1%;
    opacity: 0.9;
}

.register-dowlonlaod-ext{
    color: #3F1E75;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}



/* loading */
.register-page-terms-width{
    /* background-color: tomato !important; */
    width: 95%;
    margin-top: -21px;
    margin-left: 28px;
}

.user-register-section-disappear{
    display: none;
}
.user-register-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;

}
.user-register-lottie-dissappear{
    display: none;
}
.register-lottie-position{
    margin-left:-30% !important
}
/* end loading */

body {
    color: #fff;
    background: #fff !important;
    /* font-family: 'Roboto', sans-serif; */
}
.register-mail-spinner-size{
    width: 10% !important;
}
.register-mail-spinner-layout{
    margin-top: -20px !important;
}
.register-form-control{
    height: 40px;
    box-shadow: none;
    color: #969fa4;
}
.register-back-hand-icon{
    width: 25px !important;
    margin-left: -5%;
    margin-top: -5px;
}
.register-form-control:focus{
    border-color: #5cb85c;
} 
.register-form-control, .btn{        
    border-radius: 30px !important;
}
.register-signup-form {
    width: 550px;
    margin: 0 auto;
    padding: 30px 0;
}
.register-signup-form h2 {
    color: #3F1E75;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
/* .register-signup-form h2:before, .register-signup-form h2:after{
    content: "";
    height: 2px;
    width: 30%;
    background: #d4d4d4;
    position: absolute;
    top: 50%;
    z-index: 2;
}	 */
.register-signup-form h2:before{
    left: 0;
}
.register-signup-form h2:after{
    right: 0;
}
.register-signup-form .hint-text {
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px !important;
}
.register-form {
    color: #999;
    border-radius: 12px;
    margin-bottom: 15px;
    margin-top: 30px;
    /* background: #fff; */
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    padding: 15px 55px;
}
.register-signup-form .form-group{
    margin-bottom: 20px;
}
.register-signup-form input[type="checkbox"]{
    margin-top: 3px;
    background: #000 !important;
}
.register-signup-form .btn{        
    font-size: 16px;
    font-weight: bold;		
    min-width: 140px;
    outline: none !important;
}
.register-signup-form .row div:first-child{
    padding-right: 10px;
}
.register-signup-form .row div:last-child{
    padding-left: 10px;
}    	
.register-signup-form a{
    color: #fff;
    text-decoration: underline;
}
.register-signup-form a:hover{
    text-decoration: none;
}
.register-form a{
    color: #5cb85c;
    text-decoration: none;
}	
.register-form a:hover{
    text-decoration: underline;
}
.register-form-control {
    display: block;
    width: 100%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    text-align:center !important;
}
.register-form-logo img{
    margin-top: 15px;
    margin-bottom: 25px;
}
.register-checkbox-inline {
    color: #575578;
    font-size: 13px;
}
.register-signup-btn-hubst3r {
    font-size: 16px;
    text-align: center !important;
    font-weight: bold;
    width: 60%;
    padding: 5px 10px;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 4px!important;
    color: white!important;
    margin: auto;
}

.register-signup-btn-hubst3r:active {
    transform: scale(0.88);
}

.register-signup-btn-hubst3r:focus {
    transform: scale(0.88);
}

.register-signup-btn-hubst3r:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.register-account-have{
    color: #707070;
}
.register-form a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.register-form-header{
    display: none;
}
.hubster-login-logo-size{
    width: 23%;
}
.hubst3r-login-word-size{
    width: 30%;
}
.register-back-button{
    position: absolute;
    float: left;
    margin-left: 5%;
    margin-top: 1.5%;
}
.register-back-icon-size{
    width: 100%;
}


/* modal */
.authentication-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    /* background: #fff; */
    width: 600% !important;
    margin-top: 0%;
    margin-left: 180%;
}
.authentication-password-box {
    margin-top: -115%;
    margin-left: -420%;
    width: 950%;
} 
.register-modal-top-text{
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.modal-password-field{
    display: block;
    width: 50%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto!important;
    margin-top: 50px!important;
    text-align:center !important;

}
.restart-link-register{
    margin-top:5%;
}
.send-again-a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.send-again-a:hover{
    color: #4e2491;
    text-decoration: underline;
}
.reginter-continue-button-center{
    margin: auto!important;
    margin-top: 5% !important;
    margin-bottom: 0% !important;
}
.register-modal-btn-success{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px!important;
    color: white!important;
    /* margin: auto!important; */
    /* margin-left: 57%!important;    */
}

.register-modal-btn-success:active {
    transform: scale(0.88);
}

.register-modal-btn-success:focus { 
    transform: scale(0.88);
}

.register-modal-btn-success:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

/* end modal */


@media only screen and (max-width: 1200px) {
    .register-download-google{
        width:40%;
        margin:auto;
        margin-bottom: 20px;
    }
    /* loading */
    .register-lottie-position{
        margin-left:0% !important
    }
    /* end loading */
    .register-whole-page-layout{
        position: absolute!important;
        top: 0;
        left: 0;
        width: 99.9%;
        height: -100px;
    }
    .form-color{
        background: rgb(144,36,103) !important;
        background: linear-gradient(342deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 100%) !important;
    }
    .register-form-header{
        display: block;
        padding: 40px 20px 0px 0px;
    }
    .register-form {
        color: #999;
        border-radius: 12px;
        margin-bottom: 0px !important;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        padding: 25px 55px;
        height: 610px !important;
    }
    .register-signup-form {
        width: 100.4%;
        margin: -0.2%;
        padding: 0px 0;
        height: 600px;
    }
    .register-form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
    .register-form-header {
        display: block;
        padding: 50px 0px 30px 0px;
    }
    .register-form-control {
        display: block;
        width: 100%;
        height: 55px !important;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-image: none;
        border: none !important;
        border-radius: 12px !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        /* background-color: #fff !important; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        border-radius: 8px !important;
        border: solid 0.5px rgb(204, 204, 204) !important;
        /* background-color: rgb(243, 243, 243) !important; */
    }
    .register-form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
}


@media only screen and (max-width: 800px) {
    .register-back-button{
        float: left;
        margin-left: 5%;
        margin-top: 0%;
    }
}

@media only screen and (max-width: 506px) {
    .register-signup-btn-hubst3r {
        font-size: 16px;
        text-align: center !important;
        font-weight: bold;
        width: 50%;
        padding: 5px 10px;
        outline: none !important;
        border: none;
        /* background: rgb(144,36,103);
        background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%); */
        background: #1183ca !important;
        font-weight: bold;
        border-radius: 4px!important;
        color: white!important;
        margin: auto;
    }
}

@media only screen and (max-width: 500px) {
    .hubst3r-login-word-size{
        width: 30%!important;
        margin: auto!important;
    }
    .register-form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    
    .register-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .register-form-header img {
        width: 16rem;
    }
    .register-back-button{
        float: left;
        margin-left: 5%;
        margin-top: 0%;
    }
    .register-back-icon-size{
        width: 80% !important;
    }
    /* modal */
    .authentication-box-6 {
        width: 400% !important;
        margin-left: 280%;
    }
    .register-modal-btn-success{
        font-size: 16px;
        margin-top: 8%!important;   
    }
    /* end modal */
}


@media only screen and (max-width: 340px) {
    /* modal */
    .authentication-box-6 {
        width: 350% !important;
        margin-left: 310%;
        height:300% !important;
    }
    .register-modal-top-text{
        font-size: 12px !important;
    }
    .register-modal-btn-success{
        font-size: 13px;
    }
    /* end modal */
}

@media only screen and (max-width: 300px) {
    .register-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        /* background: #fff; */
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 15px;
    }
    .register-modal-top-text{
        font-size: 10px !important;
    }
}