.back-button-hub-mobile-traffic-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.hub-mobile-traffic-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}



.body-right-7{
    margin-left: 16.65%;
    background-color: #fff;
    padding: 20px 0px;
}
.small-header-container-6{
    display: none;
}
.hub-info-h-img-6{
    margin: auto!important;
}
.l-camera1 {
    position: absolute;
    z-index: 9999;
    left: 38%;
    top: 28px; 
    border-radius: 50%;
    width: 12% !important;
}
.top-navbar-logo-update{
    background: transparent !important;
    width: 17% !important;
    height: 15%;
    z-index: 1109999999999 !important;
    top: 0%;
    left: 0%;
    position: absolute;
}
.l-camera1 img{
    border-radius: 50%;
    /* margin: auto; */
    /* margin-top: 10%; */
    width: 20% !important;
}
.top-navbar-hub-logo{
    position: absolute;
    top: -20px !important;
    left: 10%;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    object-fit: cover;
}
.hub-navabar-traffic-big{
    width: 20% !important;
}
.hub-navabar-traffic-small{
    width: 10% !important;
}

@media only screen and (max-width: 1200px){

    .hub-top-navbar-name{
        font-size: 35px;
        text-align: center;
        font-weight: bold;
    }

    .body-right-7{
        margin-left: initial;
        border-radius: 0px 0px 0px 0px;
    }
    .color-7{
        background: rgb(144,36,103);
        background: linear-gradient(300deg, rgba(144,36,103,1) 35%, rgba(28,48,111,1) 100%);
    }
    .small-header-container-6{
        display: block;
    }
    .l-camera1 {
        position: absolute;
        z-index: 9999;
        left: 130px;
        top: 125px;
        border-radius: 50%;
        width: 3.5% !important;
    }
}
@media only screen and (max-width: 992px){
    .l-camera1 {
        left: 110px;
        top: 115px;
        width: 3.5% !important;
    }
    .top-navbar-hub-logo{
        position: absolute;
        top: -20px !important;
        left: 10%;
        height: 120px;
        width: 120px;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 780px){
    .l-camera1 {
        left: 90px;
        top: 100px;
        width: 3.5% !important;
    }
    .top-navbar-hub-logo{
        position: absolute;
        top: -20px !important;
        left: 10%;
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 576px){
    .l-camera1 {
        left: 70px;
        top: 75px;
        width: 3.5% !important;
    }
    .top-navbar-hub-logo{
        position: absolute;
        top: -30px !important;
        left: 2%;
        height: 80px;
        width: 80px;
        border-radius: 50%;
    }

    .container-fluid.small-header-container-6 {
        height: 60px;
    }

    .hub-top-navbar-name{
        font-size: 20px;
        margin-top: -35px;
    }
}

@media only screen and (max-width: 455px){
    .top-navbar-hub-logo{
        position: absolute;
        top: -30px !important;
        left: 2%;
        height: 60px;
        width: 60px;
        border-radius: 50%;
    }
    .l-camera1 {
        left: 50px;
        top: 58px;
        width: 20px !important;
    }
}

@media only screen and (max-width: 412px){
    .container-fluid.small-header-container-6 {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .top-navbar-logo-update{
        width: 21% !important;
        height: 12%;
    }

}

@media only screen and (max-width: 297px){
    .container-fluid.small-header-container-6 {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        height: 135px;
    }
}