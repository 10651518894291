.chat-list-buddies-slider{
    position: absolute;
    width: 96%;
    height: 15%;
    top: 93px;
    left: 4%;
    /* background-color: aqua; */
}

.chat-list-buddies-slider-title{
    position: absolute;
    width: 96%;
    height: 20px;
    top: 63px;
    left: 4%; 
    font-weight: bold;
    color: #232231;
    font-size: 14px;
}

.chat-list-buddies-slider-frame{
    width: 90px !important;
    height:120px !important;
    /* background-color: blue; */
    margin: auto;
}

.chat-list-buddies-slider-profile-pic{
    width: 100% !important;
    height:80% !important;
    /* margin: auto; */
    object-fit: cover;
    border-radius: 4px;
    border: solid 1px rgb(204, 204, 204);
}

.chat-list-buddies-slider-username{
    color: #232231;
    /* background-color: lightslategrey; */
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    /* width: 100%; */
    height: 20%;
    position: relative;
    bottom: 0%;
    line-height: 22px;
}

.chat-list-buddies-slider-username-big{
    color: #232231;
    /* background-color: lightslategrey; */
    text-align: center;
    font-size: 9px;
    font-weight: bold;
    height: 20%;
    position: relative;
    bottom: 0%;
    line-height: 22px;
}


.chat-list-notification-cyrcle{
    width: 15px;
    height: 15px;
    background: #1183ca !important;
    border-radius: 50%;
    position: absolute;
    margin-top: 0%;
    margin-top: -20px;
    right: 15%;
}

.back-button-chat-list-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.chat-list-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.chat-list-total-page-layout{
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0px !important;
    left: 0px !important;
    background: #fff;
}

.all-chat-empty-buddies{
    position: absolute;
    top: 20%;
    left: 10%;
    width:80%;
    height: 60%;
    /* background-color: tomato; */
}

.all-chat-empty-buddies-icon-box{
    position: absolute;
    top: 20%;
    left: 30%;
    width:40%;
    /* height: 60%; */
    /* background-color: rgb(19, 29, 168); */
}

.all-chat-empty-buddies-icon-box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.all-chat-empty-buddies-icon-img{
    width: 100%;
    height: 100%;
}

.all-chat-empty-buddies-text{
    position: absolute;
    top: 60%;
    left: 5%;
    width:90%;
    margin-bottom: 0%;
    color: #575578;
    margin-top: 30px;
    font-size: 16px !important;
    font-weight: normal;
    text-align: center;
}

@media only screen and (max-width: 1200px){
    .chat-lists-page-body-right{
        margin-top: 50px;
    }
    .chat-list-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }
    .chat-list-top-microphone-box{
        width: 10%;
        height: 100%;
        position: absolute;
        top: 0%;
        right: 1%;
    }
    .chat-list-top-microphone-img{
        position: absolute;
        width: 50%;
        top: 20%;
        left: 25%;
    }

    .chat-list-top-profile-box{
        width: 80%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 15%;
    }

    .chat-list-top-profile-pic-box{
        width: 11%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        /* background-color: teal; */
    }
    .chat-list-top-profile-pic{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 13.5px;
        left: 0%;
        object-fit: cover;
        border-radius: 50%;
    }

    .chat-list-top-profile-username-box{
        width: 85%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 15%;
        /* background-color: tomato; */
        text-align: left;
        line-height: 50px;
        color: #232231 !important;
    }
}







.chat-list-sellect-hub-button{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 12px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    margin-top: 43%;
}

.all-chat-lists-itemds{
    margin-top: 6%;
}

.all-chat-lists-itemds-conv{
    margin-top: 6%;
}
.single-item-chat-list{
    width: 50%;
    height: 100px;
    background-color: #ffff;
    border-radius: 20px;
    border:rgb(233, 233, 231) solid 0.5px;
    margin: auto; 
    margin-bottom: 1%;
}
.chat-list-profile-picture-position{
    padding-top: 2.5%;
    margin-left: 5%;
    width: 11%;
}
.chat-list-profile-picture{
    width:70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover!important;
    border: solid 0.5px rgb(204, 204, 204);
}
.chat-list-profile-username{
    width:39%;
    height: 70px;
    text-align: left;
    margin-left: 6%;
    margin-top: 35px;
    font-size: 25px;
    color: #232231 !important;
}

.chat-list-profile-username-font{
    /* font-size: 25px; */
    color: #232231 !important;
}
.chat-list-mika-img-position{
    margin-left: 20px;
    width:15%;
    height: 100%;
}
.chat-list-mika-img{
    margin-top: 30%;
    width: 60%;
}
.chat-list-non-buddy-mika-img-position{
    margin-left: 20%;
    width:15%;
    height: 100%;
}
.chat-list-non-buddy-mika-img{
    margin-top: 10%;
    width: 100%;
}
.chat-list-add-buddy-position{
    width:17%;
    height: 100%;
}

@media only screen and (max-width: 1200px){
    .all-chat-lists-itemds{
        margin-top: 0%;
        padding-top: 5%;
    }
    .all-chat-lists-itemds-conv{
        margin-top: 50%;
        padding-top: 5%;
    }
    .single-item-chat-list{
        width: 80%;
        height: 100px;
        background-color: #ffff;
        border-radius: 20px;
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .chat-list-profile-picture-position{
        padding-top: 1%;
        margin-left: 5%;
        width: 11%;
    }
    .chat-list-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .chat-list-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .chat-list-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .chat-list-mika-img{
        margin-top: 0%;
        width: 60%;
    }
    .chat-list-non-buddy-mika-img-position{
        margin-left: 10%;
        width:10%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-non-buddy-mika-img{
        margin-top: 10%;
        width: 80%;
    }
    .chat-list-add-buddy-position{
        margin-left: -30px;
        width:13%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-add-buddy-button{
        font-size: 15px !important;
        margin-top: 30%;
    }
}

@media only screen and (max-width: 780px){
    .single-item-chat-list{
        width: 80%;
        height: 100px;
        background-color: #ffff;
        border-radius: 20px;
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .chat-list-profile-picture-position{
        padding-top: 2%;
        margin-left: 5%;
        width: 11%;
    }
    .chat-list-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .chat-list-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .chat-list-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .chat-list-mika-img{
        margin-top: 20%;
        width: 60%;
    }
    .chat-list-non-buddy-mika-img-position{
        margin-left: 10%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-non-buddy-mika-img{
        margin-top: 10%;
        width: 80%;
    }
    .chat-list-add-buddy-position{
        margin-left: -20px;
        width:11%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-add-buddy-button{
        font-size: 15px !important;
        margin-top: 50%;
    }
}

@media only screen and (max-width: 550px){
    .single-item-chat-list{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        border-radius: 0px;
        border:none;
        margin: auto; 
        margin-bottom: 1%;
        /* border-bottom: rgb(233, 233, 231) solid 1px; */
    }
    .chat-list-profile-picture-position{
        padding-top: 2%;
        margin-left: 5%;
        width: 11%;
    }
    .chat-list-profile-picture{
        width:70px;
        height: 70px;
        border-radius: 50%;
    }
    .chat-list-profile-username{
        width:50%;
        height: 70px;
        margin-top: 35px;
        font-size: 25px;
    }
    .chat-list-mika-img-position{
        margin-left: 20px;
        width:15%;
        height: 100%;
    }
    .chat-list-mika-img{
        margin-top: 25%;
        width: 70%;
    }
    .chat-list-add-buddy-position{
        margin-left: -20px;
        width:11%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-add-buddy-button{
        font-size: 15px !important;
        margin-top: 60%;
    }
}

@media only screen and (max-width: 430px){
    .single-item-chat-list{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        /* border-radius: 20px; */
        border-bottom:rgb(233, 233, 231) solid 2px;
        margin: auto; 
        margin-bottom: 1%;
    }
    .chat-list-profile-picture-position{
        padding-top: 5%;
        margin-left: 5%;
        width: 11%;
    }
    .chat-list-profile-picture{
        width:60px;
        height: 60px;
    }
    .chat-list-profile-username{
        width:50%;
        height: 70px;
        margin-top: 27px;
        font-size: 18px !important;
        margin-left: 10%;
        font-weight: bold;
        color: #232231;
    }
    .chat-list-explaining-text{
        width:100%;
        /* height: 70px; */
        margin-top: 0px;
        font-size: 11px !important;
        margin-left: 0%;
        font-weight: normal;
        color: #232231;
        /* background-color: teal; */
    }
    .chat-list-mika-img-position{
        margin-left: -30px;
        width:15%;
        height: 100%;
    }
    .chat-list-mika-img{
        margin-top: 40%;
        width: 70%;
    }
    .chat-list-non-buddy-mika-img-position{
        margin-left: 5%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-non-buddy-mika-img{
        margin-top: 20%;
        width: 100%;
    }

    .chat-list-micro{
        position:relative;
        width: 20px;
        height: 20px;
        right:-15%;
        top:40px;
    }

    .chat-list-micro-img{
        width: 100%;
        /* height:100%; */
    }

    .chat-list-add-buddy-position{
        margin-left: -12px;
        width:18%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-add-buddy-button{
        font-size: 15px !important;
        margin-top: 40%;
    }
}

@media only screen and (max-width: 380px){
    .chat-list-buddies-slider-frame{
        width: 80px !important;
        height:110px !important;
    }

    .chat-list-buddies-slider-username{
        font-size: 11px;
    }
    
    .chat-list-buddies-slider-username-big{
        font-size: 9px;
        font-weight: normal;
    }
}

@media only screen and (max-width: 340px){
    .chat-list-buddies-slider-frame{
        width: 70px !important;
        height:90px !important;
    }

    .chat-list-buddies-slider-username{
        font-size: 10px;
    }
    
    .chat-list-buddies-slider-username-big{
        font-size: 8px;
    }

    .single-item-chat-list{
        width: 100%;
        height: 100px;
        background-color: #ffff;
        /* border-radius: 20px; */
        /* border:rgb(233, 233, 231) solid 2px; */
        margin: auto; 
        margin-bottom: 1%;
    }
    .chat-list-profile-picture-position{
        padding-top: 8%;
        margin-left: 5%;
        width: 11%;
    }
    .chat-list-profile-picture{
        width:50px;
        height: 50px;
        border-radius: 50%;
    }
    .chat-list-profile-username{
        width:50%;
        height: 70px;
        margin-top: 30px;
        font-size: 18px;
    }
    .chat-list-mika-img-position{
        margin-left: -40px;
        width:15%;
        height: 100%;
    }
    .chat-list-mika-img{
        margin-top: 70%;
        width: 70%;
    }
    .chat-list-non-buddy-mika-img-position{
        margin-left: 5%;
        width:15%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-non-buddy-mika-img{
        margin-top: 50%;
        width: 100%;
    }
    .chat-list-add-buddy-position{
        margin-left: -0px;
        width:18%;
        height: 100%;
        /* background-color: red; */
    }
    .chat-list-add-buddy-button{
        font-size: 15px !important;
        margin-top: 60%;
    }

    .chat-list-notification-cyrcle{
        margin-top: -25px;
    }
}
















