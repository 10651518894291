.map-search-my-stkerman {
    width: 50px;
    height: 70px;
    margin-left: -25px;
    margin-top: -27.5px;
}

.map-search-my-stkerman-mg {
    width: 100%;
    height: 100%;
}

.map-search-map-memory-unseen {
    width: 40px;
    height: 40px;
    background: rgb(180, 58, 165);
    background: linear-gradient(180deg, rgba(180, 58, 165, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    margin: auto;
    border-radius: 50%;
    margin-left: -40px;
    margin-top: -5px;
    z-index: 9999999999999;
}

.map-search-map-memory-seen {
    width: 40px;
    height: 40px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 223, 223, 1) 50%, rgba(255, 255, 255, 1) 100%);
    margin: auto;
    border-radius: 50%;
    margin-left: -40px;
    margin-top: -5px;
    z-index: 999999999;
}

.map-search-map-memory-profile-img {
    position: absolute;
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 2px;
    margin-top: 2px;
    z-index: 9999;
}



.map-search-map-memory-marker {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 40px;
    height: 40px;
    user-select: none;
    transform: translate(-50%, -50%);
    margin-left: -30px;
    margin-top: 10px;
    z-index: 999999999;
}

.map-search-map-memory-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border: 3px solid rgb(126, 236, 163);
}

.map-search-place-logo-box {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5% !important;
    left: 7%;
}

.map-search-place-name-lign {
    position: absolute;
    width: 70%;
    margin-left: 20%;
    height: 100%;
    color: #232231;
    top: 5% !important;
    left: 7%;
    font-size: 16px;
    line-height: 16px;
}

.map-search-place-more-lign {
    color: blue;
    text-align: center;
    margin-top: 10%;
    margin-left: 7%;
}

.map-search-place-speech-bubble {
    position: relative;
    margin: .5em auto;
    padding: 1em;
    width: 10em;
    height: 4em;
    border-radius: .25em;
    transform: rotate(-4deg) rotateY(15deg);
    background: #629bdd;
    font: 2em/4 Century Gothic, Verdana, sans-serif;
    text-align: center;
    z-index: 9999999;
}

.map-search-place-bubble-margins16 {
    margin-top: -180px;
    margin-left: -120px;
}

.map-search-place-bubble-margins19 {
    margin-top: -300px;
    margin-left: -100px;
    z-index: 99999 !important;
}

.map-search-place-speech-bubble:before,
.map-search-place-speech-bubble:after {
    position: absolute;
    z-index: -1;
    content: '';
}

.map-search-place-speech-bubble:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    transform: rotate(2deg) translate(.35em, -.15em) scale(1.02);
    background: #f4fbfe;
}

.map-search-place-speech-bubble:before {
    border: solid 0 transparent;
    border-right: solid 3.5em #f4fbfe;
    border-bottom: solid .25em #629bdd;
    bottom: .25em;
    left: 1.25em;
    width: 0;
    height: 1em;
    transform: rotate(45deg) skewX(75deg);
}

.map-search-center-marker-box {
    position: absolute;
    top: 47vh;
    left: 45vw;
    width: 10vw;
    height: 10vw;
    user-select: none;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transform: scale(1);
    animation: pulse 2s infinite;
    position: fixed;
    z-index: 9999999999 !important;
}

.map-search-center-marker-icon {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    left: 0%;
    top: 0%;
}



.map-search-place-logo-icon {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.place-marker-icon-back {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    opacity: 0.3;
    position: absolute;
    left: -30px;
    top: -30px;
    z-index: 1;
}

.place-marker-icon-box {
    width: 30px;
    height: 30px;
    opacity: 1;
    z-index: 1;
}

.place-marker-icon-box-blue {
    width: 30px;
    height: 30px;
    opacity: 1;
    z-index: 2 !important;
}

.place-marker-icon-img {
    width: 100%;
    height: 100%;
    z-index: 1 !important;
}

.map-search-buddies-username {
    /* color: #fff; */
    color: #232231;
    font-weight: bold;
    font-size: 7%;
    text-align: center;
    z-index: 9999;
    line-height: 100%;
    width: 80%;
    height: max-content;
    position: relative;
    left: 10%;
    top: 10%;
    border-radius: 50%;
    z-index: 999999;
    padding-top: 5px;
    margin-bottom: 0%;
}

.map-search-hr-box {
    position: relative;
    margin-top: 0px;
    z-index: 9999;
    line-height: 16px;
    width: 90%;
    margin-left: 5%;
}

.map-search-see-more {
    position: relative;

    /* background-color: blue; */

    color: blue;
    margin-top: 20% !important;
    font-weight: bold;
    font-size: 8%;
    text-align: center;
    z-index: 9999;
    width: 90%;
    margin-left: 5%;
    /* margin-bottom: 0%; */
}

/* .pin {
    background-color: #ea5e6a ;
    border-radius: 50% 50% 0px 50% ;
    position:relative ;
    transform: rotate(45deg);
  }
.pin::after {
    content: "" ;
    width: 50% ;
    height: 50% ;
    background: rgb(65, 65, 65) ;
    position: absolute ;
    left: 26% ;
    top: 26% ;
    border-radius: 60px ;
} */


.pin {
    /* height: 250px;
    width: 40%;
    min-width: 200px; */
    background: ivory;
    display: block;
    /* margin: 0 auto; */
    border-radius: 20px 20px 20px 0px;
    font-size: 200px;
    text-align: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    z-index: 99;
}

.pin-triangle {
    /* height: 40px;
    width: 40px; */
    background: ivory;
    /* margin: 0 auto; */
    transform: rotate(45deg);
    border-radius: 0 0 12px 0;
    /* margin-top: -22px; */
    position: relative;
    left: calc(0.5vw - 50px);
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
}



.map-search-place-logo {
    transform: rotate(-45deg);
    width: 50%;
    height: 50%;
    position: absolute;
    left: 26%;
    top: 26%;
    border-radius: 50%;
    z-index: 999999;
}





.map-search-hub-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
}

.map-search-my-marker {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 40px;
    height: 40px;
    user-select: none;
    transform: translate(-50%, -50%);
}

.map-search-hub-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    user-select: none;
    transform: translate(-50%, -50%);
}

.map-search-my-profile-pic {
    width: 80%;
    margin-left: 10%;
    height: 80%;
    margin-top: 10%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border: 3px solid #fff;
}

.map-search-hub-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}


@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(144, 36, 103, 0.05);
    }

    50% {
        transform: scale(1);
        box-shadow: 0 0 0 250px rgba(28, 48, 111, 0.02);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 250px transparent;
    }
}


.map-search-buddies-card {
    width: 100px;
    height: 120px;
}

.map-search-buddies-card-square {
    /* background-color: #000; */
    width: 100% !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 99999999;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px -20px 30px 10px rgb(27, 19, 19) inset !important;
}

.map-search-buddies-card-square:after {
    content: "";
    display: block;
    padding-bottom: 130%;
}

.map-search-buddies-card-square-small {
    width: 50% !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}

.map-search-buddies-card-square-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}