.enjoy-here-like-layout{
    margin: auto;
    width: 50%;
    /* height: 100%; */
    z-index: 999999999999!important;
    position: absolute;
    top: 20%;
    left:25%;
}

.enjoy-here-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -220%;
    margin-left: -100%;
    height: 500px!important;
    width: 500px;
    /* object-fit: cover; */
} 
.enjoy-here-photo-layout{
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: scale-down!important;
    z-index: 9999999;
    position: relative;
    top: -100%;
}
.enjoy-here-photo-layout-cover{
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
    /* opacity: 0.8; */
    filter: blur(10px);
    -webkit-filter: blur(10px);

}
.enjoy-here-times{
    position: absolute;
    width: 5%;
    right: 3%;
    top: 3%;
    z-index: 99999999 !important;
}
@media only screen and (max-width: 1200px){
    .enjoy-here-box{
        margin-top: -420%;
        margin-left: -380%;
        height: 900px!important;
        width: 900px;
    } 

}


@media only screen and (max-width: 992px){
    .enjoy-here-box{
        margin-top: -330%;
        margin-left: -283%;
        height: 700px!important;
        width: 700px;
    } 
}
@media only screen and (max-width: 700px) {  
    .enjoy-here-box{
        margin-top: -280%;
        margin-left: -233%;
        height: 600px!important;
        width: 600px;
    } 
}

@media only screen and (max-width: 576px){
    .enjoy-here-box{
        margin-top: -230%;
        margin-left: -183%;
        height: 500px!important;
        width: 500px;
    } 
}


@media only screen and (max-width: 480px){
    .enjoy-here-box{
        margin-top: -200%;
        margin-left: -133%;
        height: 400px!important;
        width: 400px;
    } 
    .enjoy-here-times{
        width: 7%;
        right: 3%;
        top: 3%;
        z-index: 99999999 !important;
    }
}

@media only screen and (max-width: 410px){
    .enjoy-here-box{
        margin-top: -170%;
        margin-left: -108%;
        height: 350px!important;
        width: 350px;
    }
    .enjoy-here-times{
        width: 7%;
        right: 4%;
        top: 4%;
        z-index: 99999999 !important;
    }
}
@media only screen and (max-width: 340px){
    .enjoy-here-box{
        margin-top: -140%;
        margin-left: -88%;
        height: 310px!important;
        width: 310px;
    }
}