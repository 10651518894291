.find-plcae-select-map-center {
    position: absolute;
    z-index: 99999 !important;
    width: 80%;
    background: #1183ca !important;
    height: 40px;
    line-height: 40px;
    left: 10%;
    top: initial;
    bottom: 30px !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px !important;
    border: none;
    position: fixed !important;
    text-align: center;
    box-shadow: rgb(27, 19, 19, 0.1) 0px 10px 50px;

    background-color: #fff;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}

.find-place-add-place-explaining-txr {
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0px;
    background-color: #ffff;
    z-index: 9999;
    left: 0%;
    color: #232231;
    text-align: center;
    line-height: 70px;
}

.find-place-marker-map-name {
    width: max-content/2;
    max-width: 100px;
    height: 30px;
    /* background-color: red; */
    text-align: center;
    line-height: 14px;
    margin-top: 30px;
    /* background-color: #f7f7f7; */
    /* opacity: 0.8 !important; */

}

.find-place-marker-map-name p {
    color: #fff !important;
    font-weight: bold;
    font-weight: bold;
    font-size: 12px !important;
}

.find-place-buddies-question {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-top: 6%;
    color: #232231;
    padding-left: 3%;
}

.find-place-snapped-photo-layout {
    background-color: black;
    height: 22%;
    width: 22%;
    margin-left: 39%;
    margin-top: 70px;
    border-radius: 4px;
}

.find-place-snapped-photo-size {
    object-fit: scale-down !important;
    width: 100%;
    height: 100% !important;

    margin: auto !important;
    border-radius: 0px;
    /* border: 0px solid rgb(187, 183, 183); */
    z-index: 1;

}

.find-plcae-select-place-map-btn {
    position: absolute;
    z-index: 99999 !important;
    width: 100%;
    background: #1183ca !important;
    height: 50px;
    line-height: 50px;
    left: 0%;
    top: initial;
    bottom: 0% !important;
    font-size: 25px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
    text-align: center;
}

.mapboxgl-ctrl-geocoder {
    font-size: 18px;
    line-height: 24px;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: relative;
    background-color: #fff;
    width: 70vw !important;
    /* min-width: 100vw!important; */
    z-index: 1;
    border-radius: 30px !important;
    transition: width .25s, min-width .25s;
    margin-right: 15vw !important;
    margin-top: 80px !important;
}

.back-button-find-place-high-box {
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 15px;
}

.back-button-find-place-box {
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 20px;
}

.find-place-back-button {
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.find-place-presellected-hub {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 53px;
    line-height: 53px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #232231;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    position: fixed !important;
    z-index: 9999 !important;
}

.find-place-whole-page-layout {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.find-place-post-memory {
    position: absolute;
    z-index: 99999;
    width: 100%;
    background: #1183ca !important;
    height: 50px;
    line-height: 50px;
    left: 0%;
    top: initial;
    bottom: 0% !important;
    font-size: 25px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
    text-align: center;
}

.find-place-buddies-search-input {
    position: absolute !important;
    top: 42%;
    width: 96%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 2%;
    border-radius: 8px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #e4e5e7 !important;
    padding-left: 40px;
}

.find-place-buddies-input-icon-search {
    width: 20px !important;
    position: absolute;
    left: 4%;
    top: 43.5%;
}

.find-place-select-budies-tags {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    height: 40%;
}

.find-place-select-budies-frame {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 295px;
    border-radius: 20px 20px 0px 0px;
}

.find-place-buddies-card {
    position: absolute;
    width: 96%;
    height: 88%;
    background-color: rgb(27, 19, 19);
    top: 1.5%;
    left: 2%;
    border-radius: 20px;
    box-shadow: rgb(27, 19, 19, 0.1) 0px 10px 50px;
    /* rgba(22, 93, 137, 0.4)  */
}


.find-place-buddies-card-square {
    /* background-color: #000; */
    width: 100% !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 99999999;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -70px 30px 10px rgb(27, 19, 19) inset !important;
}

.find-place-buddies-card-square:after {
    content: "";
    display: block;
    padding-bottom: 120%;
}

.find-place-buddies-card-square-small {
    width: 50% !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 99999999;
    position: absolute;
    left: 25%;
    top: 35%;
    /* height: 100%; */
    /* background-color: red; */
}

.find-place-buddies-card-square-small:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.find-place-buddies-username {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    bottom: 18%;
    left: 5%;
    width: 90%;
    text-shadow: -1px 0 rgb(117, 116, 116), 0 1px rgb(117, 116, 116), 1px 0 rgb(117, 116, 116), 0 -1px rgb(117, 116, 116);
}

.find-place-buddies-tag {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    bottom: 4%;
    left: 15%;
    width: 70%;
    padding: 2px;
    background: #1183ca !important;
}

.find-place-buddies-tagged {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    bottom: 4%;
    left: 15%;
    width: 70%;
    padding: 2px;
    color: #1183ca !important;
    background: #fff !important;
}



.find-place-map-layout {
    position: absolute;
    top: 35%;
    left: 0%;
    width: 100%;
    height: 65%;
}

.find-place-map-layout-small {
    position: absolute;
    top: 63px;
    left: 0%;
    width: 100%;
    height: 30%;
}


.find-place-search-input {
    position: absolute !important;
    top: 1%;
    width: 90%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 8%;
    border-radius: 8px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #e4e5e7 !important;
    padding-left: 40px;
}

.find-place-input-icon-search {
    position: absolute;
    width: 30px !important;
    margin-left: 8%;
    margin-top: 8px;
    z-index: 9999999;
}

.find-place-slider-layout {
    position: absolute;
    top: 70px;
    left: 0%;
    width: 100%;
    height: 130px;
    /* background-color: lime; */
}

.find-place-hub-frame {
    width: 120px !important;
    height: 130px !important;
    /* background-color: blue; */
    margin: auto;
}

.find-place-hub-logo-box {
    position: relative;
    width: 50%;
    margin-left: 25%;
}

.find-place-hub-logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.find-place-hub-name {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: #232231;
}

.find-place-hub-extra-info {
    margin-top: 4px;
    /* background-color: blue; */
    font-size: 11px;
    text-align: center;
    color: #575578;
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .find-place-input-icon-search {
        width: 20px !important;
        position: absolute;
        left: 2.5%;
        top: 8px;
    }
}

@media only screen and (max-width: 400px) {
    .find-place-select-budies-frame {
        min-width: 160px;
        min-height: 265px;
    }
}

@media only screen and (max-width: 380px) {
    .find-place-slider-layout {
        top: 65px;
        height: 110px;
    }

    .find-place-hub-name {
        margin-top: 10px;
        font-size: 12px;
    }

    .find-place-hub-extra-info {
        margin-top: 4px;
        font-size: 10px;
        width: 80%;
        margin-left: 10%;
    }

    .find-place-hub-logo-box {
        width: 40%;
        margin-left: 30%;
    }
}


@media only screen and (max-width: 340px) {
    .find-place-slider-layout {
        height: 90px;
    }

    .find-place-select-budies-frame {
        min-width: 150px;
        min-height: 245px;
    }

    .find-place-hub-logo-box {
        width: 30%;
        margin-left: 35%;
    }

    .find-place-hub-extra-info {
        margin-top: 4px;
        font-size: 9px;
    }
}