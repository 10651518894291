.profile-data-done{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    /* padding: 6px 0px; */
    border-radius: 30px;
    font-size: 22px;
    font-weight: bold;
    width: 30%;
    left: 40%;
    color: white;
    cursor: pointer;
    position: absolute;
    bottom: 5%;
    z-index: 999;
    margin: auto;
    text-align: center;
}

.profile-data-fullname{
    margin-top: 3%;
    border: none;
    width: 50%;
    position: absolute;
    top: 65%;
    left: 20%;
    text-align: center;
}
.profile-data-fullname input{
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}

.profile-data-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
    width: 800% !important;
    height: 600%;
    margin-top: -250%;
    margin-left: -330%;
    z-index: 99999999 !important;
}
.profile-data-times-icon{
    float: right;
}
.profile-data-title{
    position: relative;
    font-size: 25px;
    width: 85%;
    margin-bottom: -8%; 
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%; 
}
.profile-data-picture-text{
    position: absolute;
    top: 17%;
    font-size: 18px;
    width: 100%;
    color: #3F1E75;
    z-index: 3 !important;
    text-align: center;
}
.profile-data-fullname-text{
    position: absolute;
    top: 63%;
    font-size: 18px;
    width: 100%;
    color: #3F1E75;
    z-index: 3 !important;
    text-align: center;
}
.profile-data-picture-photo-box{
    position: absolute;
    top: 15%;
    left: 43%;
    height: 160px;
    width: 160px; 
}
.profile-data-picture-photo{
    margin-top: 30%;
    height: 100%;
    width: 100%; 
    border-radius: 50%!important;
    object-fit: cover;
    border: #555555 1px solid;   
}
.mika-approve-button{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left:10%;
    border-radius: 50%;
}
.profile-data-sellected-yes{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 10% !important;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.mika-decline-button{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 15%;
    border-radius: 50%;
}
.profile-data-sellected-no{
    width: 30%;
    height: 30%;
    margin-top: 12% !important;
    margin-left: 15%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.profile-data-choises-no{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 20%;
    margin-top: 6%;
}
.profile-data-choises-yes{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 15%;
    margin-top: 6%;
}


@media only screen and (max-width: 780px) {  
    .profile-data-box-6 {
        width: 600% !important;
        height: 600%;
        margin-top: -350%;
        margin-left: -230%;
    }

    .profile-data-picture-text{
        top: 17%;
        font-size: 18px;
    }
    .profile-data-fullname-text{
        top: 63%;
        font-size: 18px;
    }

    .profile-data-picture-photo-box{
        position: absolute;
        top: 20%;
        left: 43%;
        height: 120px;
        width: 120px; 
    }
    .profile-data-picture-photo{
        margin-top: 30%;
        height: 100%;
        width: 100%; 
        border-radius: 50%!important;
        object-fit: cover;
        border: #555555 1px solid;   
    }
    .profile-data-title{
        position: relative;
        font-size: 18px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%; 
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20%;
        margin-top: 10% !important;
        margin-left:10%;
        border-radius: 50%;
    }
    .profile-data-sellected-yes{
        width: 25%;
        height: 25%;
        margin-top: 10% !important;
        margin-left: 10% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20%;
        margin-top: 10% !important;
        margin-left: 22%;
        border-radius: 50%;
    }
    .profile-data-sellected-no{
        width: 25%;
        height: 25%;
        margin-top: 12% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .profile-data-choises-no{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .profile-data-choises-yes{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 10%;
        margin-top: 6%;
    }
}

@media only screen and (max-width: 570px) {
    .profile-data-box-6 {
        width: 400% !important;
        height: 500%;
        margin-top: -200%;
        margin-left: -135%;
    }

    .profile-data-done{
        padding: 6px 10px;
        border-radius: 30px;
        font-size: 18px;
        width: 40%;
        left: 30%;
    }

    .profile-data-fullname{
        margin-top: 3%;
        border: none;
        width: 70%;
        position: absolute;
        top: 65%;
        left: 9%;
        text-align: center;
    }
    .profile-data-picture-photo-box{
        position: absolute;
        top: 20%;
        left: 34%!important;
        height: 120px;
        width: 120px; 
    }
    .profile-data-picture-photo{
        margin-top: 30%;
        height: 100%;
        width: 100%; 
        border-radius: 50%!important;
        object-fit: cover;
        border: #555555 1px solid;   
    }

    .profile-data-picture-text{
        top: 17%;
        font-size: 18px;
        width: 90%;
    }
    .profile-data-fullname-text{
        top: 60%;
        font-size: 18px;
        width: 90%;
    }

    .profile-data-picture-photo-box{
        position: absolute;
        top: 20%;
        left: 43%;
        height: 120px;
        width: 120px; 
    }
    .profile-data-picture-photo{
        margin-top: 30%;
        height: 100%;
        width: 100%; 
        border-radius: 50%!important;
        object-fit: cover;
        border: #555555 1px solid;   
    }




    .profile-data-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 3%; 
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:10%;
        border-radius: 50%;
    }
    .profile-data-sellected-yes{
        width: 25%;
        margin-top: 10% !important;
        margin-left: 10% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left: 22%;
        border-radius: 50%;
    }
    .profile-data-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-top: 12% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .profile-data-choises-no{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .profile-data-choises-yes{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 10%;
        margin-top: 6%;
    }
}
@media only screen and (max-width: 430px) {
    .profile-data-box-6 {
        width: 400% !important;
        height: 500%;
        margin-top: -227%;
        margin-left: -135%;
    }
}

@media only screen and (max-width: 400px) {
    .profile-data-box-6 {
        width: 350% !important;
        /* height: 320%; */
        /* margin-top: -148%; */
        margin-left: -110%;
    }
    .profile-data-sellected-yes{
        width: 25%;
        margin-top: 10% !important;
        margin-left: 15% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:13%;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 350px) {
    .profile-data-box-6 {
        width: 300% !important;
        height: 420%;
        margin-top: -200%;
        margin-left: -85%;
    }
    .profile-data-picture-photo-box{
        position: absolute;
        top: 20%;
        left: 35%!important;
        height: 100px;
        width: 100px; 
    }
    .profile-data-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        /* margin-left: 8%;  */
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:18%;
        border-radius: 50%;
    }
    .profile-data-sellected-yes{
        width: 25%;
        height: 25% !important;
        margin-top: 10% !important;
        margin-left: 18% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left: 18%;
        border-radius: 50%;
    }
    .profile-data-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-top: 12% !important;
        margin-left: 16%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .profile-data-choises-no{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 14%;
        margin-top: 6%;
    }
    .profile-data-choises-yes{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 15%;
        margin-top: 6%;
    }
}