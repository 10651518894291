.pending-appointments-edit-modal-date{
    position: absolute;
    top: 46%;
    height: 60px;
    width: 40%;
    left: 3%;
    background: #fff;
    line-height: 25px;
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 5px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
    z-index: 999999999999 !important;
    font-size: 40px !important;
}

.pending-appointments-edit-modal-time{
    position: absolute;
    top: 46%;
    height: 60px;
    width: 40%;
    left: 48%;
    background: #fff;
    line-height: 45px;
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 5px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
    /* z-index: 999999999999 !important; */
    font-size: 24px;
}

.approved-appointments-pending-modal-accept{
    font-size: 16px;
    font-weight: bold;
    border: none;
    background: #1183ca !important;
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    width: 60%;
    height: 40px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 15%;    
}

.approved-appointments-timewheel-change-btn{
    font-size: 16px;
    font-weight: bold;
    border: none;
    background: #1183ca !important;
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    width: 50%;
    height: 40px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 25%;      
}

.approved-appointments-timewheel-back-arrow{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
}

.approved-appointments-timewheel-title-text{
    width: 100%;
    height: 30%;
    font-size: 22px;
    color: #232231;
    text-align: center;
    position: absolute;
    top: 5px;
}

.approved-appointments-timewheel-box{
    position: relative;
    width: max-content;
    height: max-content;
    font-size: 20px !important;
    margin: auto;
    margin-top: 80px !important;
}
.approved-appointments-timewheel-text{
    width: 100%;
    height: 30%;
    font-size: 18px;
    color: #232231;
    text-align: center;
    position: absolute;
    top: 300px;
    /* background-color: brown; */
    margin-top: 0%;

}

.approved-appointments-timewheel-arrow-left-box{
    width: 50px;
    height: 50px;
    position: absolute;
    left: 15%;
    margin-top: 155px;
}

.approved-appointments-timewheel-arrow-right-box{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 15%;
    margin-top: 155px;
}

.approved-appointments-timewheel-arrow-img{
    width: 100%;
    height: 100%;
}

.approved-appointments-timewheel-box{
    width: 100%;
    height: 100%;
    /* position: absolute;
    top: 50% !important;
    left: 0%; */
    /* background-color: lightcoral; */
    font-size: 50px !important;
    text-align: center !important;
    margin-top: 30px;
}

.approved-appointments-timewheel-item{
    width: 50%;
    height: 30%;
    /* position: absolute;
    top: 30%;
    left: 25%; */
    text-align: center !important;
    font-size: 50px !important;

}

.approved-appointments-edit-modal-change{
    font-size: 16px;
    font-weight: bold;
    border: none;
    background: #1183ca !important;
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 40px;
    line-height: 30px;
    position: absolute;
    bottom: 10px;
    left: 30%;    
}

.approved-edit-modal-name-title{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    font-size: 16px;
    color: #232231;
    line-height: 40px;
    font-weight: bold;
    top: 30%;
    margin-left: 0%;
    padding-left: 5%;
}

.approved-edit-modal-phone-title{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    font-size: 16px;
    color: #232231;
    line-height: 40px;
    font-weight: bold;
    top: 50%;
    margin-left: 0%;
    padding-left: 5%;
}

.approved-appointments-edit-modal-date{
    position: absolute;
    bottom: 20%;
    height: 40px;
    width: 30%;
    left: 10%;
    background: #fff;
    line-height: 25px;
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 5px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
    z-index: 999999999999 !important;
    font-size: 20px;
}

.approved-appointments-edit-modal-time{
    position: absolute;
    bottom: 20%;
    height: 40px;
    width: 30%;
    left: 50%;
    background: #fff;
    line-height: 25px;
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 5px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
    z-index: 999999999999 !important;
    font-size: 20px;
}

.approved-appointments-show-more-edit{
    position: absolute;
    top: 5px;
    right: 10px;
    color:blue;
    height: max-content;
    line-height: 20px;
}

.approved-appointments-cancel-modal-times{
    position: absolute;
    top: 2%;
    right: 10%;
    width: 20px;
    height: 20px;
}

.approved-appointments-cancel-modal-title{
    width: 90%;
    height: 100%;
    /* background-color: red; */
    font-size: 18px;
    color: #232231;
    line-height: 28px;
    font-weight: normal;
    margin-top: 15px;
    margin-left: 0%;
    padding-left: 5%;
    padding-right: 5%;
}

.approved-appointments-cancel-modal-no{
    width: 20%;
    height: 40px;
    font-size: 18px;
    color: #232231;
    line-height: 40px;
    font-weight: bold;
    background-color: red;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 20%;
}

.approved-appointments-cancel-modal-yes{
    width: 20%;
    height: 40px;
    font-size: 18px;
    background-color: green;
    color: #fff;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 57%;
}

.approved-appointments-cancel-appointment-button{
    font-size: 16px;
    font-weight: bold;
    padding:3px 3px;
    border: none;
    /* background: #1183ca !important; */
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 40px;
    line-height: 30px;
    margin: auto;
    margin-top: 20px;
    background-color: red;
    color: #fff;
}

.approved-appointments-save-button{
    font-size: 16px;
    font-weight: bold;
    padding:5px 5px;
    border: none;
    /* background: #1183ca !important; */
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    width: max-content;
    height: 40px;
    line-height: 30px;
    position: absolute;
    color:#1183ca;
    right: 7%;
    z-index: 999999;

}

.approved-appointments-dad-button{
    font-size: 16px;
    font-weight: bold;
    border: none;
    background: #1183ca !important;
    padding: 5px 5px !important;
    font-weight: bold;
    border-radius: 4px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 40px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 30%;
}

.approved-appointments-modal-input{
    width: 60%;
    left: 23%;
    position: absolute;
    height: 60px;
}

.approved-appointments-modal-all-title{
    margin-top: 80px;
    width: 100%;
    height: 40px;
}

.approved-appointments-modal-phone-title{
    width: 100%;
    height: 100%;
    /* background-color: red; */
    font-size: 16px;
    color: #232231;
    line-height: 40px;
    font-weight: bold;
    margin-top: 15px;
    margin-left: 0%;
    padding-left: 5%;
}

.approved-appointments-modal-title{
    position: absolute;
    top: 3%;
    width: 90%;
    left: 5%;
    font-size: 18px;
    color: #232231;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
}

.approved-appointments-modal-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 0px !important;
    background: #fff; 
    margin-top: -10;
    /* margin-left: -5%; */
    height: 120%!important;
    width: 110%;
    z-index: 9 !important;
} 

.approved-appointments-show-more-note-box{
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
}

.approved-appointments-show-more-note{
    width: 100%;
    height: 30px;
    color: #232231;
    margin: auto;
    margin-top: 10px;
    line-height: 30px;
    padding-left: 20px;
}

.approved-appointments-show-more-phone{
    width: 100%;
    height: 60px;
    color: #232231;
    margin: auto;
    margin-top: 10px;
    line-height: 60px;
    padding-left: 20px;
}

.approved-appointments-show-more-box{
    position: absolute;
    left: 0%;
    top: 60px;
    /* margin-top: 60px; */
    width: 100%;
    height: 300px;
    z-index: 99;
    background-color: rgb(241, 240, 240);
}

.approved-appointments-top-down-arrow{
    width: 6%;
    height: 62%;
    position: absolute;
    right: 3%;
    top: 0%;
    /* background-color: blue; */
}
.approved-appointments-top-up-arrow{
    width: 6%;
    height: 30%;
    position: absolute;
    right: 3%;
    bottom: 10px;
    /* background-color: blue; */
}

.approved-appointments-top-down-arrow-icon{
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 0%;
}

.approved-appointments-mobile-navbar{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 53px;
    line-height: 53px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #232231;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    position: fixed !important;
    z-index: 99999!important;
    }

.approved-appointments-day-box{
    position: absolute;
    top: 73px;
    width: 40%;
    left: 30%;
    height: 50px;
    background: #fff;
    margin: auto;
    margin-top: 20px;
    line-height: 40px;
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
}

.approved-appointments-all-appointment-hours{
    position: absolute;
    width: 100%;
    left: 0%;
    top: 180px;
    height: 100%;
    /* background-color: blue; */
}

.approved-appointments-appointment-hour-box{
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-left: 0%;
    /* background-color: brown; */
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    border: solid 0.5px rgb(204, 204, 204);
}

.approved-appointments-appointment-hour-box-selected{
    position: relative;
    width: 100%;
    height: 360px;
    line-height: 60px;
    margin-left: 0%;
    /* background-color: brown; */
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    border: solid 0.5px rgb(204, 204, 204);
}

.approved-appointments-appointment-hour-value{
    width: 50px;
    margin-left: 0px;
    /* background-color:burlywood; */
    text-align: center;
    color: #232231;
    border-right: solid 0.5px rgb(204, 204, 204);
}

.approved-appointments-appointment-hour-appointment{
    margin-left: 0px;
    height: 100%;
    /* background-color: burlywood; */
    width: 80%;
    padding-left: 30px;
    font-size: 16px;
    color: #232231;
}

.approved-appointments-appointment-hour-appointment-empty{
    margin-left: 0px;
    height: 100%;
    /* background-color: burlywood; */
    padding-left: 30px;
    font-size: 16px;
    color: blue;
    text-align: center;
    background-color: white;
    width: calc(100% - 52px);
}