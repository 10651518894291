/* The switch - the box around the slider */
.hub-homepage-pharmacy-switch {
    position: relative;
    display: inline-block;
    width: 240px;
    height: 45px;
    margin: auto;
    margin-top: 0px;
  }
  
  /* Hide default HTML checkbox */
  .hub-homepage-pharmacy-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .hub-homepage-pharmacy-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .hub-homepage-pharmacy-slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .hub-homepage-pharmacy-slider {
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);

  }
  
  input:focus + .hub-homepage-pharmacy-slider {
    box-shadow: 0 0 1px linear-gradient(315deg, #00b712 0%, #5aff15 74%);
  }
  
  input:checked + .hub-homepage-pharmacy-slider:before {
    -webkit-transform: translateX(202px);
    -ms-transform: translateX(202px);
    transform: translateX(202px);
  }


  
  /* Rounded sliders */
  .hub-homepage-pharmacy-slider.hub-homepage-pharmacy-round {
    border-radius: 34px;
  }
  
  .hub-homepage-pharmacy-slider.hub-homepage-pharmacy-round:before {
    border-radius: 50%;
  }


  .hub-homepage-pharmacy-overnight-text{
      font-size: 14px;
      color: #fff !important;
      text-align: center;
      /* background-color: #232231;
      opacity: 0.5; */
      height: 40px;
      line-height: 40px;
  }







.hub-homepage-no-hubst3r-box{
    width: 94%;
    height: 160px;
    background: linear-gradient(0deg, rgb(209, 208, 208),rgb(155, 155, 155));
    position: absolute;
    top: 0%;
    left: 3%;
}

.hub-homepage-no-hubst3r-text{
    width: 90%;
    margin-left:5%;
    margin-top: 10px;
    height: 160px;
    color: #575578;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
}

.hub-homepage-no-hubst3r-text2{
    width: 90%;
    margin-left:5%;
    margin-top: 30px;
    height: 160px;
    color: #575578;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    word-break: break-all;
}

.hub-homepage-terms-icon-box{
    width: 70%;
    position: absolute;
    left: 15%; 
    top: 32%;
}

.hub-homepage-terms-icon-img{
    width: 100%;
    height: 100%;
}

.hub-homepage-terms-top-text{
    color: #575578;
    text-align: center;
    position: absolute;
    width: 80%;
    left: 10%;
    font-size: 14px;
    top: 60%;
}

.hub-homepage-terms-accept{
    background: #1183ca !important;
    padding: 5px 5px !important;
    color: #fff !important;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 80px;
    line-height: 80px;
    left: 0%;
    bottom: 0% !important;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0px !important;
    border: none;
    position: fixed !important;
}

.hub-homepage-section-disappear{
    display: none;
}
.hub-homepage-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.hub-homepage-lottie-dissappear{
    display: none;
}

.hub-homepage-welcome-title{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:10px;
}

.hub-homepage-explaing-text{
    color: #575578;
    font-size: 12px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:40px;   
    line-height: 12px;
}

.hub-homepage-logo-box{
    position: absolute;
    right: 1%;
    top: 2%;
    width: 100px;
    height: 100px;
}

.hub-homepage-logo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204);
}

.hub-homepage-create-reservation-btn{
    background: #1183ca !important;
    border: none !important;
    padding: 5px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center;
    width: 30%;
    position: absolute;
    left: 45%;
    top: 70px;
}

.hub-homepage-create-reservation-btn:active {
    transform: scale(0.88);
}

.hub-homepage-create-reservation-btn:focus {
    transform: scale(0.88);
}

.hub-homepage-create-reservation-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.hub-homepage-question-box{
    position: absolute;
    left: 30%;
    top: 20% !important;
    width: 60%;
    height: 100%;
}

.hub-homepage-question-left{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.hub-homepage-question-bottom-left{
    position: absolute;
    left: 0%;
    top: 45%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.hub-homepage-question-right{
    position: absolute;
    left: 50%;
    top: 0%;
    width: 50%;
    height: 100%;
    display: block;
}

.hub-homepage-question-bottom-right{
    position: absolute;
    left: 50%;
    top: 45%;
    width: 50%;
    height: 100%;
    margin: auto;
    display: block;
}

.hub-homepage-question-btn{
    display: block;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    color: #eee;
    letter-spacing: 4px;
    overflow: hidden;    
    font-family: verdana;
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
    transition: 0.2s;
    position: absolute;
    width: 90%;
    margin-left: 5%;
    height: 255px;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}


.hub-homepage-question-btn:active {
    transform: scale(0.94);
}

.hub-homepage-question-btn:focus {
    transform: scale(0.94);
}

.hub-homepage-question-btn::after {
    transform: scale(0.94);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}




.hub-homepage-question-icon-box{
    margin: auto;
    width: 150px;
    height: 150px;
    margin-top: 10%;
}

.hub-homepage-question-icon{
    width: 100%;
    height: 100%;
}

.hub-homepage-question-text{
    color: #575578;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    width: 85%;
    margin-left: 7.5%;
}

.hub-homepage-notification-number{
    font-weight: bold;
    margin: auto;
    position: absolute;
    color: #ffff;
    font-size: 12px !important;
    line-height: 32px;
    border-radius: 50%;
    background-color: #dd3535;
    width: 40px;
    height: 40px;
    top: 26%;
    right: 36%;
    text-align: center !important; 
    z-index: 99999999 !important;
    /* padding-left: 4px; */
    border: 5px solid rgb(236, 236, 236);
    letter-spacing: 0.5px;
}


@media only screen and (max-width: 1200px){
    .hub-homepage-notification-number{
        top: 29%;
        right: 39%;
    }


    .whole-hub-homepage-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100% !important;
    }
    .hub-homepage-welcome-title{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        z-index: 99999999;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 15px !important;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    }

    .hub-homepage-back-box{
        position: absolute;
        left: 0%;
        top: 0%;
        width: 15%;
        height: 100%;
        /* background-color: red; */
    }

    .hub-homepage-back-icon-box{
        position: absolute;
        left: 0%;
        top: 0%;
        width: 20px;
        height: 20px;
        /* background-color: blue; */
    }
    .hub-homepage-back-icon{
        width: 100%;
        height: 100%;
    }

    .hub-homepage-profile-pic-box{
        position: absolute;
        left: 25px;
        top: 0%;
        width: 25px;
        height: 25px;
    }

    .hub-homepage-profile-pic{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: solid 0.5px rgb(204, 204, 204);
    }

    .hub-homepage-logo-box{
        position: relative;
        margin: auto;
        margin-top: 16%;
        width: 120px;
        height: 120px;
    }

    .hub-homepage-question-box{
        left: 0%;
        top: 35% !important;
        height: 60%;
        width: 100%;
    }

    .hub-homepage-create-reservation-btn{
        width: 100%;
        height: 80px;
        line-height: 80px;
        left: 0%;
        top: initial;
        bottom: 0% !important;
        font-size: 30px;
        font-weight: bold;
        border-radius: 0px !important;
        border: none;
        position: fixed !important;

    }

    .hub-homepage-explaing-text{
        font-size: 20px !important;
        width: 100%;
        top:7%;   
        line-height: 30px;
    }
    
}

@media only screen and (max-width: 800px){
    .hub-homepage-notification-number{
        top: 27%;
        right: 36%;
    }
}


@media only screen and (max-width: 650px){
    .hub-homepage-notification-number{
        font-size: 11px !important;
        line-height: 26px;
        width: 32px;
        height: 32px;
        top: 25%;
        right: 37%;
        border: 3px solid rgb(236, 236, 236);
    }

    .hub-homepage-question-box{
        top: 30% !important;
        /* height: 70%; */
        position: absolute;
        left: 0%;
        /* top: 22%; */
        width: 100%;
        height: 470px;
    }
    
    .hub-homepage-question-icon-box{
        width: 100px;
        height: 100px;
    }
    .hub-homepage-question-btn{
        height: 190px;
    }
    .hub-homepage-explaing-text{
        font-size: 18px !important;
        top:8%;   
        line-height: 25px;
        font-weight: normal;
    }

    .hub-homepage-logo-box{
        margin: auto;
        margin-top: 18%;
        width: 90px;
        height: 90px;
    }
    
}

@media only screen and (max-width: 450px){
    .hub-homepage-notification-number{
        font-size: 10px !important;
        line-height: 23.5px;
        width: 30px;
        height: 30px;
        top: 20%;
        right: 32%;
        border: 3px solid rgb(236, 236, 236);
    }
    .hub-homepage-question-box{
        position: absolute;
        left: 0%;
        top: 34% !important;
        width: 100%;
        height: 45%;
    }

    .hub-homepage-logo-box{
        margin-top: 23%;
        width: 90px;
        height: 90px;
    }

    .hub-homepage-create-reservation-btn{
        height: 50px;
        line-height: 40px;
        font-size: 25px;
    }
    
    .hub-homepage-question-bottom-left{
        top: 210px;

    }
    
    .hub-homepage-question-bottom-right{
        top: 210px;
    }

}

@media only screen and (max-width: 400px){
    .hub-homepage-question-box{
        /* top: 22%; */
        height: 470px;
    }
    .hub-homepage-explaing-text{
        font-size: 14px !important;
        top:65px;   
        line-height: 18px;
        font-weight: normal;
    }

    .hub-homepage-logo-box{
        margin-top: 95px;
        width: 80px;
        height: 80px;
    }
    .hub-homepage-notification-number{
        font-size: 10px !important;
        line-height: 25px;
        width: 30px;
        height: 30px;
        top: 20%;
        right: 30%;
        border: 3px solid rgb(236, 236, 236);
    }
}

@media only screen and (max-width: 365px){
    .hub-homepage-question-text{
        font-size: 12px;
    }
}

@media only screen and (max-width: 340px){
    .hub-homepage-question-box{
        height: 420px;
        }

    .hub-homepage-question-btn{
        height: 170px;
    }
    .hub-homepage-logo-box{
        margin-top: 95px;
        width: 70px;
        height: 70px;
    }
    .hub-homepage-notification-number{
        right: 27%;
    }
    .hub-homepage-no-hubst3r-text{
        width: 90%;
        margin-left:5%;
        margin-top: 20px;
        font-size: 25px;
        line-height: 50px;
    }
    .hub-homepage-no-hubst3r-text2{
        width: 90%;
        margin-left:5%;
        margin-top: 20px;
        font-size: 16px;
        line-height: 50px;
    }
}