.create-table-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 580px !important;
    margin-top: -245%;
    margin-left: -260%;
    width: 650%;
}

.create-table-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    border:double 1px rgb(179, 171, 209);
}
.create-table-close-icon{
    margin-top: 6px;
    width:60%;
}
.create-table-title-box{
    width: 20% !important;
    margin: auto;
}
.create-table-title-icon{
    margin: auto;
    width: 60%;
    margin-left: 20%;
}
.create-table-title-text{
    color: #3F1E75;
    text-align: center;
    font-size: 18px !important;
}
.create-table-all-fields{
    margin-top: 7%;
}
.create-table-search-user-box{
    width: 60%;
    margin: auto !important;
    margin-top: 0% !important;
}
.create-table-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 8px 30px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    color: white!important;
    cursor: pointer;
    margin: auto;
    width: 25%;
    margin-top: 12%;
    text-align: center;
}
.create-table-modal-frame {
    width: 75px;
    height: 57px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.create-table-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 5px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.create-table-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 10px;
}

.create-table-table-number{
    margin-top: 10px;
    color:#3F1E75;
    font-weight: bold;
    line-height: 13px;
    font-size: 13px;
    /* margin-top: -35% !important; */
    /* margin-bottom: -20% !important; */
}

@media only screen and (max-width: 600px){
    .create-table-modal-frame {
        width: 50px;
        height: 50px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .create-table-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 480px !important;
        margin-top: -195%;
        margin-left: -90%;
        width: 310%;
    }
    .create-table-title-box{
        width: 38% !important;
        margin-left: 3%;
        margin: auto;
    }
    .create-table-title{
        font-size: 15px !important;
    }
    .create-table-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 22px;
    }
    .create-table-close-icon{
        margin-top: 0px;
        width:60%;
    }
    .create-table-title-icon{
        margin: auto;
        width: 30%;
        margin-left: 35%;
    }
    .create-table-title-text{
        color: #3F1E75;
        text-align: center;
        font-size: 14px !important;
    }
    .create-table-all-fields{
        margin-top: 5%;
    }
    .create-table-btn-danger {
        padding: 8px 30px !important;
        font-size: 14px !important;
        width: 50%;
        margin-top: 16%;
        text-align: center;
    }
}
