.notification-settings-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
    width: 800% !important;
    height: 600%;
    margin-top: -250%;
    margin-left: -330%;
    z-index: 99999999 !important;
}
.notification-settings-times-icon{
    float: right;
}
.notification-settings-title{
    position: relative;
    font-size: 25px;
    width: 85%;
    margin-bottom: -8%; 
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%; 
}
.mika-approve-button{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left:10%;
    border-radius: 50%;
}
.notification-settings-sellected-yes{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 10% !important;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.mika-decline-button{
    width: 30%;
    height: 30%;
    margin-top: 10% !important;
    margin-left: 15%;
    border-radius: 50%;
}
.notification-settings-sellected-no{
    width: 30%;
    height: 30%;
    margin-top: 12% !important;
    margin-left: 15%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}
.notification-settings-choises-no{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 20%;
    margin-top: 6%;
}
.notification-settings-choises-yes{
    font-size: 22px;
    color: #3F1E75;
    margin-left: 15%;
    margin-top: 6%;
}


@media only screen and (max-width: 780px) {  
    .notification-settings-box-6 {
        width: 600% !important;
        height: 400%;
        margin-top: -150%;
        margin-left: -230%;
    }
    .notification-settings-title{
        position: relative;
        font-size: 18px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%; 
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20%;
        margin-top: 10% !important;
        margin-left:10%;
        border-radius: 50%;
    }
    .notification-settings-sellected-yes{
        width: 25%;
        height: 25%;
        margin-top: 10% !important;
        margin-left: 10% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20%;
        margin-top: 10% !important;
        margin-left: 22%;
        border-radius: 50%;
    }
    .notification-settings-sellected-no{
        width: 25%;
        height: 25%;
        margin-top: 12% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .notification-settings-choises-no{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .notification-settings-choises-yes{
        font-size: 16px;
        color: #3F1E75;
        margin-left: 10%;
        margin-top: 6%;
    }
}

@media only screen and (max-width: 570px) {
    .notification-settings-box-6 {
        width: 400% !important;
        height: 340%;
        margin-top: -200%;
        margin-left: -135%;
    }
    .notification-settings-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%; 
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:10%;
        border-radius: 50%;
    }
    .notification-settings-sellected-yes{
        width: 25%;
        margin-top: 10% !important;
        margin-left: 10% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left: 22%;
        border-radius: 50%;
    }
    .notification-settings-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-top: 12% !important;
        margin-left: 20%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .notification-settings-choises-no{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 20%;
        margin-top: 6%;
    }
    .notification-settings-choises-yes{
        font-size: 10px;
        color: #3F1E75;
        margin-left: 10%;
        margin-top: 6%;
    }
}
@media only screen and (max-width: 430px) {
    .notification-settings-box-6 {
        width: 400% !important;
        height: 340%;
        margin-top: -167%;
        margin-left: -135%;
    }
}

@media only screen and (max-width: 400px) {
    .notification-settings-box-6 {
        width: 350% !important;
        height: 320%;
        margin-top: -148%;
        margin-left: -110%;
    }
    .notification-settings-sellected-yes{
        width: 25%;
        margin-top: 10% !important;
        margin-left: 15% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:13%;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 350px) {
    .notification-settings-box-6 {
        width: 300% !important;
        height: 330%;
        margin-top: -150%;
        margin-left: -85%;
    }
    .notification-settings-title{
        position: relative;
        font-size: 16px;
        width: 85%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%; 
    }
    .mika-approve-button{
        width: 20% !important;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left:18%;
        border-radius: 50%;
    }
    .notification-settings-sellected-yes{
        width: 25%;
        height: 25% !important;
        margin-top: 10% !important;
        margin-left: 18% !important;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .mika-decline-button{
        width: 20%;
        height: 20% !important;
        margin-top: 10% !important;
        margin-left: 18%;
        border-radius: 50%;
    }
    .notification-settings-sellected-no{
        width: 25%;
        height: 25% !important;
        margin-top: 12% !important;
        margin-left: 16%;
        border-radius: 50%;
        border:double 15px rgb(77,51,168);
        background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
        -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
        box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
    }
    .notification-settings-choises-no{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 14%;
        margin-top: 6%;
    }
    .notification-settings-choises-yes{
        font-size: 9px;
        color: #3F1E75;
        margin-left: 15%;
        margin-top: 6%;
    }
}