.send-message-search-icon-box{
    position: absolute;
    left: 3.5%;
    width: 16px;
    height: 16px;
    z-index: 10;
    top: 10px;
}

.send-message-search-icon-img{
    width: 100%;
    height: 100%;
}

.send-message-search-box{
    position: absolute;
    top: 50px;
    left: 0%;
    width: 100%;
    height: 50px;
}

.send-message-search-field{
    position: absolute;
    height: 35px !important;
    width: 94%;
    left: 2%;
    top: 12%;
    border: none;
    border-radius: 12px;
    background-color: #e4e5e7 !important;
    padding-left: 30px;
    z-index: 9;
}

.send-message-slider-layout{
    position: absolute;
    top: 60px;
    left: 0%;
    width: 100%;
    height: 20%;
}

.send-message-slider-frame{
    width: 100%;
    height: 100%;
}
.send-message-sent-box{
    margin-top: -6px;
}

.send-message-profile-pic-box{
    width: 60px;
    height: 60px;
    margin: auto;
}
.send-message-sent-profile-pic-box{
    width: 30px;
    height: 30px;
    margin: auto; 
    margin-top: 5px;
    margin-bottom: 5px;
}

.send-message-profile-pic-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.send-message-buddy-username{
    color: #3F1E75;
    text-align: center;
}


.send-message-social-finish{
    position: absolute;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bolder;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 8px 16px -20px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 40px -20px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    width: 50%;
    text-align: center;
    /* height: 20%; */
    left: 25%;
    font-size: 12px;
    top: 67%;
    font-size: 25px;
    /* border-radius: 20px 20px 0px 0px !important; */
    box-shadow: 0px 0px 12px 1px rgba(45, 6, 104, 0.3);
}

.send-message-username{
    position: absolute !important;
    top: 2.5%;
    width: 90%;
    height: 30px;
    text-align: left;
    /* top: 0%; */
    left: 5%;
    color: #272635;
    font-size: 16px !important;
    font-weight: bold;
    /* background-color: blue; */
}

.send-message-url{
    position: absolute !important;
    top:30.5%;
    width: 90%;
    height: 30px;
    text-align: left;
    /* top: 0%; */
    left: 5%;
    color: #272635;
    font-size: 16px !important;
    font-weight: bold;
    /* background-color: blue; */
}

.send-message-username-input{
    position: absolute !important;
    top: 11.5%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none; 
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}


.send-message-url-input{
    position: absolute !important;
    top: 39.5%;
    width: 92%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    /* top: 0%; */
    left: 4%;
    border-radius: 4px !important; 
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.send-message-times{
    position: absolute;
    width: 5%;
    right: 3%;
    top: 3%;
    z-index: 99999999 !important;
}

.send-message-buddy-name{
    position: absolute !important;
    top: 2.5%;
    width: 100%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 0%;
    color: #272635;
    font-size: 20px !important;
    font-weight: bold;
}

.send-message-title-hr{
    position: absolute !important;
    width: 100%;
    height: 10px;
    top:35px;
}

.send-message-all-choises{
    position: absolute;
    top: 60px;
    width: 100%;
    height: 400px;
    /* background-color: rgb(34, 45, 202); */
}

.send-message-choise{
    width: 90%;
    margin-left: 5%;
    height: 24px;

}

.send-message-choise-text{
    width: 100%;
    text-align: left;
    color: #272635;
    font-size: 16px !important;
    font-weight: normal;
}

.send-message-remove-icon-box{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 3%;
    top: 30px;
    z-index: 99999999 !important;
}

.send-message-remove-icon{
    position: absolute;
    width: 100%;
    height: 100%;
}


.send-message-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 30px;
    background: #fff; 
    margin-top: -250px;
    margin-left: -220px;
    height: 500px!important;
    width: 500px;
    /* object-fit: cover; */
} 

@media only screen and (max-width: 1200px){
    .send-message-box{
        margin-top: -400px;
        margin-left: -270px;
        height: 600px!important;
        width: 600px;
    } 

}


@media only screen and (max-width: 992px){
    .send-message-box{
        margin-top: -200px;
        margin-left: -180px;
        height: 400px!important;
        width: 400px;
    } 
    .send-message-social-finish{
        top: 77%;
        font-size: 25px;
    }
    
}


@media only screen and (max-width: 480px){
    .send-message-box{
        margin-top: 100px;
        margin-left: -190px;
        height: 300px!important;
        width: 420px;
        z-index: 9 !important;
    } 
    .send-message-times{
        width: 5%;
        right: 4%;
        top: 3.5%;
        z-index: 99999999 !important;
    }
    .send-message-social-finish{
        top: 65%;
        font-size: 25px;
    }
}

@media only screen and (max-width: 410px){
    .send-message-box{
        margin-left: -180px;
        height: 750px!important;
        width: 400px;
        z-index: 9 !important;
    } 
    .send-message-social-finish{
        top: 38%;
        font-size: 20px;
    }
}
@media only screen and (max-width: 370px){
    .send-message-box{
        margin-left: -162px;
        margin-top: -50px;
        width: 364px;
    } 
    .send-message-social-finish{
        top: 40%;
    }
    .send-message-buddy-name{
        top: 1.5%;
    }
    .send-message-times{
        right: 4%;
        top: 2.5%;
    }
}
@media only screen and (max-width: 340px){
    .send-message-box{
        margin-left: -152px;
        width: 344px;
        margin-top: 30px;
    }
    .send-message-buddy-name{
        top: 1.5%;
        font-size: 18px !important;
    }
    .send-message-times{
        right: 5%;
        top: 2%;
    }
    .send-message-social-finish{
        top: 38%;
    }
}