.reservation-list-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 580px !important;
    margin-top: -245%;
    margin-left: -260%;
    width: 650%;
}

.reservation-list-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    border:double 1px rgb(179, 171, 209);
}
.reservation-list-close-icon{
    margin-top: 6px;
    width:60%;
}
.reservation-list-title-box{
    width: 50% !important;
    margin-left: 3%;
}
.reservation-list-title{
    position: relative;
    font-size: 20px !important;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
}
.reservation-list-datepicker{
    margin: auto;
    /* width: 65%; */
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
    margin-left: 20px;
}
.reservation-list-slider{
    margin-top: 3%;
    height: 85% !important;
}
.reservation-list-item{
    width: 100%;
}
.reservation-list-single-item{
    width: 80%!important;
    background: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto !important;
}

.reservation-list-user-info{
    width: 20%;
}
.reservation-list-user-picture{
    border-radius: 50%!important;
    width: 60px!important;
    height: 60px!important;
    object-fit: cover;
}

.reservation-list-info{
    width: 60%;
}
.reservation-list-info-layout{
    margin-top: 20px;
}
.reservation-list-text{
    color: #575578;
    font-size: 14px;
    margin-top: -10px;
}
.reservation-list-btn{
    width: 20%;
}
.reservation-list-btn-sellect {
    /* position: absolute; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}
.reservation-list-btn-sellected {
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    background: #fff;
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}
@media only screen and (max-width: 600px){
    .reservation-list-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 480px !important;
        margin-top: -195%;
        margin-left: -90%;
        width: 310%;
    }
    .reservation-list-title-box{
        width: 80% !important;
        margin-left: 3%;
    }
    .reservation-list-title{
        font-size: 15px !important;
    }
    .reservation-list-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 22px;
    }
    .reservation-list-close-icon{
        margin-top: 0px;
        width:60%;
    }
    .reservation-list-single-item{
        width: 100%!important;
        padding: 5px 2px;
        border-radius: 0px;
    }
    
    .reservation-list-user-info{
        width: 20%;
    }
    .reservation-list-user-picture{
        width: 40px!important;
        height: 40px!important;
    }
    
    .reservation-list-info-layout{
        margin-top: 10px;
        height: 100%;
        width: 100%;
    }
    .reservation-list-text{
        color: #575578;
        font-size: 12px;
        margin-top: -15px !important;
    }
    .reservation-list-btn{
        width: 20%;
    }
    .reservation-list-btn-sellect {
        /* position: absolute; */
        padding: 4px 9px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        cursor: pointer;
        color: white !important;
        text-align: center;
        width: 100%;
        margin-top: 30%;
    }
    .reservation-list-btn-sellected {
        /* position: absolute; */
        padding: 4px 9px !important;
        font-size: 11px !important;
        width: 100%;
        margin-top: 30%;
    }
}

@media only screen and (max-width: 540px){
    .reservation-list-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        border:double 1px rgb(179, 171, 209);
    }
    .reservation-list-close-icon{
        margin-top: -10px;
        width:60%;
    }
}

@media only screen and (max-width: 400px){
    .reservation-list-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .reservation-list-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
}