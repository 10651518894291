.homepage-map-hubs-dissapear {
    display: none;
}

.map-search-add-place-explaining-txr {
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0px;
    background-color: #ffff;
    z-index: 9999;
    left: 0%;
    color: #232231;
    text-align: center;
    line-height: 70px;
}

.map-add-a-place-pop-up-box {
    width: 100%;
    height: max-content;
    z-index: 9999999999999999 !important;
}

.map-add-a-place-pop-up-title {
    width: 90%;
    margin: auto;
    color: #232231;
    text-align: center;
}

.map-add-a-place-pop-up-x {
    position: absolute;
    right: 1%;
    top: 0px;
    width: 20px;
    height: 20px;
    line-height: 0px;
    text-align: left;
    font-weight: bolder;
    color: #000;
    font-size: 22px;
    z-index: 999 !important;
}

.map-add-a-place-pop-up {
    width: 150px;
    height: 40px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    background-color: #1183ca;
    text-align: center;
}

.map-search-map-layout-modal-title {
    width: 80%;
    height: max-content;
    color: #232231 !important;
    /* margin: auto; */
    text-align: center;
    padding-left: 3%;
    margin-top: 1%;
}

.map-search-map-layout-modal-slider {
    width: 100%;
    /* height: 100vh; */
    position: absolute;
    top: 20%;
}


.map-search-map-layout-modal-frame {
    width: 40vw;
    height: 60vw;
    padding: 10px;
    /* background-color: blue; */
    margin: auto;
    border-radius: 30px;
}

.map-search-map-layout-modal-map-img {
    width: 100%;
    height: 100%;
    z-index: 1 !important;
    object-fit: cover;
    border-radius: 30px;

}

.map-search-category-name {
    width: 100%;
    height: 60px;
    text-align: center;
    font-weight: bold;
    /* background-color: crimson; */
    color: #232231;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5px;
}





.map-search-marker-map-name {
    width: max-content/2;
    max-width: 100px;
    height: 30px;
    /* background-color: red; */
    text-align: center;
    line-height: 14px;
    margin-top: 30px;
    /* background-color: #f7f7f7; */
    /* opacity: 0.8 !important; */

}

.map-search-marker-map-name p {
    color: #fff !important;
    font-weight: bold;
    font-weight: bold;
    font-size: 9px !important;
}

.map-search-marker-map-name-blabk {
    width: max-content/2;
    max-width: 100px;
    height: 30px;
    /* background-color: red; */
    text-align: center;
    line-height: 14px;
    margin-top: 30px;
    /* background-color: #f7f7f7; */
    /* opacity: 0.8 !important; */
}

.map-search-marker-map-name-blabk p {
    color: #000 !important;
    font-weight: bold;
    font-weight: bold;
    font-size: 9px !important;
}


.map-search-top-filter-down {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 155px;
    bottom: 9.5%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}


.map-search-top-filter {
    /* width: 5%;
    height: 40px;
    position: absolute;
    top: 80px;
    right: 17%;
    z-index: 9; */
    position: absolute;
    /* top: 80px; */
    /* right: 2%; */
    right: 5px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 1;
    border-radius: 50%;
}

.map-search-top-filter-icon {
    width: 60%;
    height: 60%;
    position: absolute;
    bottom: 20%;
    left: 20%;
    /* background-color: crimson; */
}

.marker-map-mi-marker-box {
    position: relative;
    width: 46px;
    height: 80px;
    z-index: 1;
}

.marker-map-mi-marker-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
    bottom: 0%;
    left: 2px;
}

.marker-map-mi-marker-imade {
    position: absolute;
    width: 46px;
    height: 46px;
    z-index: 2;
    bottom: 29px;
    border-radius: 50%;
    border: 3px solid #fff;
    animation: breathing 7s ease-out infinite;
    -webkit-animation: breathing 7s ease-out infinite;
}

.marker-map-mi-marker-icon-location-btn {
    position: absolute;
    width: 47%;
    height: 47%;
    z-index: 1;
    bottom: 8%;
    left: 26.5%;
}

.marker-map-mi-marker-imade-location-btn {
    position: absolute;
    width: 53%;
    height: 53%;
    z-index: 2;
    bottom: 38%;
    border-radius: 50%;
    border: 3px solid #fff;
    animation: breathing 7s ease-out infinite;
    -webkit-animation: breathing 7s ease-out infinite;
    left: 23.5%;
}

@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    50% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.map-search-upload-image-box {
    position: absolute;
    top: 80px;
    left: 2%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 1;
    border-radius: 50%;

}

.map-search-upload-image-img {
    width: 60%;
    height: 60%;
    margin-left: 22%;
    margin-top: 20%;
}

.map-search-uchat-image-box {
    position: absolute;
    top: 80px;
    right: 2%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 1;
    border-radius: 50%;
}

.homepage-new-message-bbubble2 {
    width: 18px;
    height: 18px;
    background-color: #dd3535;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    text-align: center;
}

.mapboxgl-ctrl-geocoder {
    font-size: 18px;
    line-height: 24px;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: relative;
    background-color: #fff;
    width: 70vw !important;
    /* min-width: 100vw!important; */
    z-index: 1;
    border-radius: 30px !important;
    transition: width .25s, min-width .25s;
    margin-right: 15vw !important;
    margin-top: 80px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);

}

.mapboxgl-ctrl-geocoder--input {
    font: inherit;
    width: 100%;
    border: 0;
    background-color: transparent;
    margin: 0;
    height: 40px !important;
    color: #404040;
    /* fallback */
    color: rgba(0, 0, 0, 0.75);
    padding: 6px 35px 6px 45px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mapboxgl-ctrl-geocoder--icon-search {
    position: absolute;
    top: 9px !important;
    left: 12px;
    width: 23px;
    height: 23px;
}






.marker-map-memory-box {
    /* position: relative; */
    /* background-color: #00b712;
    border-radius: 50%; */
    /* margin-left: -115px;  */
    border-radius: 50% 50% 50% 0;
    /* border: 5px solid #fff; */
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 223, 223, 1) 50%, rgba(255, 255, 255, 1) 100%);
    /* width: 20px;
    height: 20px; */
    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
    margin-left: 30px;
    margin-top: -30px;
    z-index: 1;
}


.marker-map-memory-box-unseen {
    /* position: relative; */
    /* background-color: #00b712;
    border-radius: 50%; */
    /* margin-left: -115px;  */
    border-radius: 50% 50% 50% 0;
    /* border: 5px solid #fff; */
    /* width: 20px;
    height: 20px; */
    background: rgb(180, 58, 165);
    background: linear-gradient(180deg, rgba(180, 58, 165, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);

    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
    margin-left: 30px;
    margin-top: -30px;
    z-index: 1;
}

.marker-map-memory-profile-img {
    width: 80%;
    height: 80%;
    margin-left: 10%;
    margin-top: 10%;
    border-radius: 50%;
    object-fit: cover;
}



/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
    padding: 0;
    width: 220px;
    /* margin-bottom: 25px; */
    border-radius: 0px !important;
    z-index: 9 !important;
}

.mapboxgl-popup-content h3 {
    /* background: #91c949; */
    color: #fff;
    margin: 0;
    padding: 10px;
    /* border-radius: 3px 3px 0 0; */
    font-weight: bold;
    /* margin-top: -15px; */
    color: #232231 !important;
    font-size: 14px;
    text-align: center;
}

.mapboxgl-popup-content h12 {
    position: absolute;
    /* z-index:99999999999999999999!important; */

}

.mapboxgl-popup-content h4 {
    margin: 0;
    padding: 10px;
    font-weight: 400;
    color: #232231 !important;
    font-size: 12px;
    text-align: center;
}

.mapboxgl-popup-content .map-btn-popup-see-more {
    margin: 0;
    padding: 10px;
    font-weight: bold;
    color: blue;
    z-index: 99999999999999999999 !important;
    font-size: 14px !important;
    text-align: center;
    background-color: #fff;
    margin: auto;
    width: 100%;
    border: none;
    outline: none;
}

.mapboxgl-popup-content div {
    padding: 10px;
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-content {
    /* margin-top: 15px; */
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-tip {
    border-bottom-color: #91c949;
    border-radius: 30px !important;

}

.marker-restaurant-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/restaurant-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;
    /* margin-top: 30px; */
}

.marker-restaurant-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/restaurant-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-restaurant-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/restaurant-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cafe-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cafe-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cafe-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/cafe-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
    /* animation:shake 2s ease-out infinite;
    -webkit-animation:shake 2s ease-out infinite; */
    /* animation: breathing 7s ease-out infinite;
    -webkit-animation: breathing 7s ease-out infinite; */
}

.marker-cafe-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cafe-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bar-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bar-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bar-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bar-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bar-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bar-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-club-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/club-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-club-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/club-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-club-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/club-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-food-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/food-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-food-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/food-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-food-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/food-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-casino-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/casino-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-casino-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/casino-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-casino-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/casino-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-takeaway-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/takeaway-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-takeaway-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/takeaway-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-takeaway-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/takeaway-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-clothestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/clothestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-clothestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/clothestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-clothestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/clothestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-electronicsstore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/electronicsstore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-electronicsstore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/electronicsstore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-electronicsstore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/electronicsstore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-shoestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/shoestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-shoestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/shoestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-shoestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/shoestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-supermarket-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/supermarket-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-supermarket-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/supermarket-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-supermarket-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/supermarket-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-jewelry-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/jewelry-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-jewelry-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/jewelry-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-jewelry-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/jewelry-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-liquorstore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/liquorstore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-liquorstore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/liquorstore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-liquorstore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/liquorstore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-petstore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/petstore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-petstore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/petstore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-petstore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/petstore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-mall-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/mall-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-mall-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/mall-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-mall-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/mall-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-conviniencestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/conviniencestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-conviniencestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/conviniencestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-conviniencestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/conviniencestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-gasstation-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/gasstation-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-gasstation-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/gasstation-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-gasstation-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/gasstation-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-homegoodsstore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/homegoodsstore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-homegoodsstore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/homegoodsstore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-homegoodsstore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/homegoodsstore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-hardwarestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/hardwarestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-hardwarestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/hardwarestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-hardwarestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/hardwarestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-pharmacy-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/pharmacy-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-pharmacy-overnight-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/pharmacy-overnight-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-pharmacy-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/pharmacy-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-pharmacy-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/pharmacy-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-florist-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/florist-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-florist-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/florist-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-florist-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/florist-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-furniturestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/furniturestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-furniturestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/furniturestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-furniturestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/furniturestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bicyclestore-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bicyclestore-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bicyclestore-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bicyclestore-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bicyclestore-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bicyclestore-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bookshop-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bookshop-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bookshop-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bookshop-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bookshop-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bookshop-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cardealer-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cardealer-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cardealer-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/cardealer-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-cardealer-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cardealer-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bakery-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bakery-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bakery-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bakery-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bakery-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bakery-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-beautysalon-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/beautysalon-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-beautysalon-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/beautysalon-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-beautysalon-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/beautysalon-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-hairsalon-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/hairsalon-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-hairsalon-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/hairsalon-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-hairsalon-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/hairsalon-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-spa-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/spa-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-spa-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/spa-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-spa-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/spa-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-doctor-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/doctor-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-doctor-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/doctor-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-doctor-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/doctor-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-dentist-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/dentist-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-dentist-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/dentist-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-dentist-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/dentist-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-lawer-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/lawyer-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-lawer-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/lawyer-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-lawer-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/lawyer-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-electrician-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/electrician-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-electrician-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/electrician-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-electrician-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/electrician-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-accountant-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/accountant-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-accountant-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/accountant-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-accountant-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/accountant-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-laundry-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/laundry-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-laundry-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/laundry-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-laundry-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/laundry-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bank-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bank-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bank-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bank-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bank-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bank-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-gym-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/gym-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-gym-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/gym-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-gym-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/gym-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-veterany-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/veterany-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-veterany-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/veterany-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-veterany-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/veterany-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-insurance-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/insurance-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-insurance-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/insurance-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-insurance-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/insurance-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-travel-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/travel-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-travel-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/travel-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-travel-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/travel-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-parking-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/parking-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-parking-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/parking-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-parking-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/parking-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-painter-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/painter-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-painter-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/painter-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-painter-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/painter-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-rentcar-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/rentcar-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-rentcar-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/rentcar-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-rentcar-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/rentcar-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-physiotherapist-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/physiotherapist-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-physiotherapist-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/physiotherapist-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-physiotherapist-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/physiotherapist-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-realestateagent-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/realestateagent-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-realestateagent-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/realestateagent-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-realestateagent-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/realestateagent-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-plumber-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/plumber-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-plumber-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/plumber-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-plumber-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/plumber-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-carrepair-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/carrepair-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-carrepair-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/carrepair-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-carrepair-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/carrepair-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-carwash-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/carwash-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-carwash-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/carwash-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-carwash-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/carwash-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-locksmith-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/locksmith-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-locksmith-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/locksmith-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-locksmith-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/locksmith-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cinema-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cinema-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-cinema-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/cinema-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-cinema-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/cinema-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-lodging-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/lodging-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-lodging-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/lodging-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-lodging-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/lodging-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-minifootball-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/minifootball-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-minifootball-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/minifootball-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-minifootball-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/minifootball-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-sports-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/sports-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-sports-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/sports-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-sports-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/sports-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bowling-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bowling-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-bowling-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/bowling-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-bowling-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/bowling-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-artgallery-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/artgallery-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-artgallery-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/artgallery-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-artgallery-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/artgallery-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-museum-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/museum-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-museum-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/museum-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-museum-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/museum-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}


.marker-aquarium-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/aquarium-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-aquarium-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/aquarium-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-aquarium-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/aquarium-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-amusementpark-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/amusementpark-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-amusementpark-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/amusementpark-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-amusementpark-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/amusementpark-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-zoo-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/zoo-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-zoo-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/zoo-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-zoo-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/zoo-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-campground-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/campground-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-campground-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/campground-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-campground-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/campground-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-library-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/library-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-library-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/library-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-library-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/library-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-rvpark-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/rvpark-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-rvpark-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/rvpark-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-rvpark-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/rvpark-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-university-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/university-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-university-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/university-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

.marker-university-active {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/university-active.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-place-red {
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-image: url("../../assets/map/svg/place-red.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 20px 20px;

}

.marker-place-blue {
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-image: url("../../assets/map/svg/place-blue.svg");
    background-repeat: no-repeat;
    z-index: 0 !important;
    background-size: 40px 40px;
}

@keyframes shake {
    0% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    10% {
        transform: translate(-0.5px, -0.5px) rotate(-1deg);
    }

    20% {
        transform: translate(-0.5px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    40% {
        transform: translate(0.5px, -0.5px) rotate(1deg);
    }

    50% {
        transform: translate(-0.5px, 0.5px) rotate(-1deg);
    }

    60% {
        transform: translate(-0.5px, 0.5px) rotate(0deg);
    }

    70% {
        transform: translate(0.5px, 0.5px) rotate(-1deg);
    }

    80% {
        transform: translate(-0.5px, -0.5px) rotate(1deg);
    }

    90% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    100% {
        transform: translate(0.5px, -0.5px) rotate(-1deg);
    }
}

@-webkit-keyframes shake {
    0% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    10% {
        transform: translate(-0.5px, -0.5px) rotate(-1deg);
    }

    20% {
        transform: translate(-0.5px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    40% {
        transform: translate(0.5px, -0.5px) rotate(1deg);
    }

    50% {
        transform: translate(-0.5px, 0.5px) rotate(-1deg);
    }

    60% {
        transform: translate(-0.5px, 0.5px) rotate(0deg);
    }

    70% {
        transform: translate(0.5px, 0.5px) rotate(-1deg);
    }

    80% {
        transform: translate(-0.5px, -0.5px) rotate(1deg);
    }

    90% {
        transform: translate(0.5px, 0.5px) rotate(0deg);
    }

    100% {
        transform: translate(0.5px, -0.5px) rotate(-1deg);
    }
}


.map-container {
    /* width: 100vw !important; */
    height: 100vh !important;
}

.mapMarkerStyle {
    /* background-color: blue; */
    width: 40px;
    height: 40px;
    display: block;
    left: -0.5rem;
    top: -0.5rem;
    position: relative;
    /* border-radius: 1.5rem 1.5rem 0;
    transform: rotate(45deg); */
    /* border: 1px solid #FFFFFF; */
    color: #000;
    font-size: 8px;
}

/* .mapMarkerStyle::after {
    position: 'absolute';
    content: '';
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    background-color: #fff;
  } */

.map-box-search-map-map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.map-box-search-map-box {
    position: absolute;
    width: 100vw !important;
    height: 1100vh !important;
    top: 0%;
    left: 0%;
    background-color: #575578;
    object-fit: fill;
    /* width: 100px;
    height: 100px; */
}

.map-search-show-more-tags-box {
    width: 100%;
    height: 80px;
    margin-left: 0%;
    /* background-color: rgb(131, 42, 42); */
    /* border-radius: 30px; */
    margin-bottom: 10px;
    /* box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4); */
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
    /* background: linear-gradient(0deg, transparent 0%, #fff 0%); */

}

.map-search-show-more-tag-buddy-box {
    /* width: 80px; */
    height: 80px;
    /* background-color: #00b712; */
    margin: auto;
}

.map-search-show-more-tag-profile-box {
    width: 60px !important;
    height: 60px !important;
    /* background-color: #0003b7; */
    margin: auto;
}

.map-search-show-more-tag-profile-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #fff;
}

.map-search-show-more-tag-username {
    width: 100%;
    height: 20px;
    /* background-color: rgb(180,58,165); */
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #232231 !important;
}


.map-search--promo-photo-blur {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    z-index: -1 !important;
}

.map-search-contact-success-box {
    width: 120px;
    height: 120px;
    margin-top: 20% !important;
    margin: auto;
}

.map-search-contact-success-img {
    width: 100%;
    height: 100%;
}

.map-search-contact-success-subtitle {
    width: 70%;
    margin-left: 15%;
    margin-top: 10% !important;
    color: #232231;
    font-size: 16px;
    text-align: center;
}

.map-search-hub-contact-success-done-btn {
    width: 40%;
    z-index: 9999999999 !important;
    height: max-content;
    background: #1183ca !important;
    padding: 5px;
    line-height: 25px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    position: relative;
    margin-top: 20% !important;
    margin-left: 30%;
}

.map-search-acces-modal-times-box {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0%;
    right: 0%;
    /* background-color: brown; */
}

.map-search-acces-modal-times-box2 {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0%;
    right: 6%;
    /* background-color: brown; */
}

.map-search-place-modal-times-box {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0.5%;
    right: 6%;
    z-index: 9999999999999999999 !important;
}

.map-search-place-modal-times-igg {
    width: 100%;
    height: 100%;
}

.map-search-acces-modal-times-img {
    width: 50%;
    height: 50%;
    margin-top: 2%;
    margin-left: 45%;
}

.map-search-hub-contact-claim-btn {
    width: 60%;
    z-index: 9999999999 !important;
    height: 40px;
    background: #1183ca !important;
    padding: 5px;
    line-height: 30px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: 5%;
    left: 20%;
}

.map-search-hub-contact-name-text {
    z-index: 1 !important;
    position: absolute !important;
    top: 23%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 14px !important;
    z-index: 1 !important;
}

.map-search-hub-contact-name-input {
    position: absolute;
    left: 15%;
    width: 70%;
    top: 32%;
    height: 40px;
    border-radius: 4px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.map-search-hub-contact-phone-text {
    z-index: 1 !important;
    position: absolute !important;
    top: 50%;
    width: 90%;
    height: 50px;
    text-align: center;
    /* top: 0%; */
    left: 5%;
    color: #575578;
    font-size: 14px !important;
    z-index: 1 !important;
}

.map-search-hub-contact-phone-input {
    position: absolute;
    left: 15%;
    width: 70%;
    top: 59%;
    height: 40px;
    border-radius: 4px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #f1f2f7 !important;
    padding-left: 20px;
}

.map-search-hub-contact-titel {
    width: 80%;
    height: max-content;
    color: #232231 !important;
    margin: auto;
    text-align: center;
}

.map-search-hub-contact-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.map-search-bubble-unclaimed-acconut {
    width: max-content;
    height: max-content;
    position: absolute;
    text-align: right;
    right: 0%;
    bottom: 0%;
    font-size: 10px;
    line-height: 11px;
    color: blue;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.4);
    padding: 3px;
    padding-bottom: 0px;
}

.map-search-bubble-unclaimed-acconut2 {
    width: max-content;
    height: max-content;
    position: absolute;
    text-align: right;
    right: 0%;
    bottom: 0%;
    font-size: 9px;
    line-height: 11px;
    color: #232231 !important;
    font-weight: bold;
    padding: 2px;
    padding-bottom: 0px;
}

.map-search-user-change-cover-photo-box {
    width: max-content;
    height: max-content;
    position: absolute;
    text-align: left;
    left: 0%;
    bottom: 0%;
    font-size: 9px;
    line-height: 11px;
    color: blue;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.2);
}

.map-search-user-cover-props {
    width: 70%;
    height: max-content;
    position: absolute;
    text-align: center;
    left: 15%;
    bottom: 0%;
    font-size: 8px;
    line-height: 11px;
    color: #232231 !important;
}

.map-search-user-set-cover-photo-box {
    width: 80%;
    height: max-content;
    left: 10%;
    position: absolute;
    bottom: 40%;
    font-size: 12px;
    line-height: 14px;
    color: #232231 !important;
    font-weight: bold;
}

.map-search-user-set-cover-photo-btn {
    width: 40%;
    height: 30px;
    left: 30%;
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    z-index: 9999999999 !important;
    background: #1183ca !important;
    border-radius: 4px;
    color: #fff;
}

.map-search-bubble-claim-tetx {
    /* width: 60%;
    z-index: 99 !important;
    height: max-content;
    background: #1183ca !important;
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 30px !important;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    font-size:12px;
    font-weight: bold;
    color:#fff; */
    /* position: absolute;
    bottom: 5%;
    left: 20%; */
}

.all-page-touches-invisible-box {
    width: 100%;
    height: 83%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 9999990999909909999;
}

.map-search-close-street-view-btn {
    position: absolute;
    bottom: -50px;
    left: 35%;
    width: 30%;
    height: 30px;
    background: #d60c27;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    z-index: 999999999999;
}

.suggestion-item {
    z-index: 999;
}

.map-search-show-more-modal {
    width: 100%;
    height: max-content;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 9999999999999999 !important;
}



.map-search-show-more-single-box {
    width: 90%;
    height: 50px;
    margin-left: 5%;
    background-color: #fff;
    border-radius: 30px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
}

.map-search-show-more-single-row {
    width: max-content;
    color: #232231 !important;
    text-align: center;
    font-size: 17px;
    line-height: 50px;
    margin: auto;

}

.map-search-show-more-image {
    width: 40px;
    height: 30px;
    /* margin-top:10px */
}

.map-search-show-more-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: -0px;
    margin-left: 0%;
}

.map-search-show-more-x-box {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.4);
    color: #232231;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
}

.map-search-story-doubleclick {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 999999999999;
}

.map-sarch-top-chat-position {
    width: 10%;
    height: 100%;
    position: absolute;
    right: 0%;
    top: 0%;
}

.map-sarch-top-chat-img {
    width: 65%;
    height: 65%;
    position: absolute;
    left: 10%;
    top: 18%;
    /* background-color: rgb(255, 0, 255); */
}

.map-search-story-all-black {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: black;
    z-index: 999 !important;
}

.map-search-story-index {
    z-index: 9999999999999999999999 !important;
}

.story {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.story img {
    width: 70px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.story svg {
    fill: none;
    stroke: #8a3ab8;
    stroke-width: 3px;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: loading 4500ms ease-in-out infinite alternate;
}

@keyframes loading {
    100% {
        stroke: #cd476b;
        stroke-dasharray: 10;
        transform: rotate(200deg);
    }
}


.map-search-all-story-section {
    width: 100%;
    height: 75px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99 !important;
    margin-left: 0px;
    /* background: linear-gradient(0deg, transparent 30%, #fff 90%); */
    background: black;
    padding-top: 50px;
    opacity: 1;
}

.map-search-my-story-profile-plus-img {
    position: absolute;
    bottom: 22px;
    left: 57px;
    width: 26px;
    height: 26px;
    z-index: 99999999999 !important;
}

.map-search-story-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5%;
    left: 0%;
    /* background-color:red; */
}

.map-search-my-story-box {
    width: 80%;
    height: 80px;
    margin: auto;
}

.map-search-my-story-profile-unseen {
    width: 55px;
    height: 55px;
    background: rgb(180, 58, 165);
    background: linear-gradient(180deg, rgba(180, 58, 165, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    margin: auto;
    border-radius: 50%;
}

.map-search-my-story-profile {
    width: 55px;
    height: 55px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 223, 223, 1) 50%, rgba(255, 255, 255, 1) 100%);
    margin: auto;
    border-radius: 50%;
}

.map-search-my-story-profile-img {
    position: absolute;
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 3.5px;
    margin-top: 3.5px;
    z-index: 9999;
}

.map-search-my-story-spinner {
    width: 55px;
    height: 55px;
    border-top: 5.5px solid #6da9f5;
    border-right: 5.5px solid transparent;
    border-radius: 50%;
    margin: auto;
    animation: spinner 0.6s linear infinite;
    font-size: 18px;
    z-index: 99999999999999;
}

.map-search-my-story-head {
    width: 5.5px;
    height: 5.5px;
    background-color: #6da9f5;
    border-radius: 50%;
    margin-left: 46.75px;
    margin-top: 2.75px;
}

@keyframes spinner {
    100% {
        transform: rotate(360deg)
    }
}

.map-search-story-username-text {
    font-size: 0.55rem;
    color: #fff;
    /* color: rgb(32, 32, 32); */
    text-align: center;
    width: 100%;
    margin: auto;
    margin-top: 2px;
}

.map-search-story-times-box {
    width: 40px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 0px;
    z-index: 99999999999999999 !important;
    /* background-color: crimson; */
}

.map-search-story-times-img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 12px;
}

.map-search-story-reactions-xo {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 7px;
    bottom: 10px;
    max-width: 12%;
    position: fixed !important;
    z-index: 99999999;
}

.map-search-story-reactions-img {
    width: 100%;
    height: 100%;
    z-index: 99999999999999999999 !important;
}

.map-search-story-swipe-up {
    position: absolute;
    width: 70%;
    height: 70px;
    left: 15%;
    bottom: 0%;
    z-index: 9999999999999999999;
    color: white;
    text-align: center;
    letter-spacing: 0.1em;
    /* margin-bottom: 1.2vh; */
    text-transform: capitalize;
    opacity: 1;
    font-size: 0.8em;
    transition: opacity 300ms ease-in-out;
}

.map-search-story-bottom-row {
    position: absolute;
    width: 20%;
    height: 10%;
    right: 0%;
    bottom: 0%;
    z-index: 99999999999999999999 !important;
}

.map-search-story-top-text {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 30px;
    left: 90px;
    z-index: 99;
}

.map-search-story-top-heading {
    font-size: 0.7rem !important;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    margin-bottom: 2;
}

.map-search-story-top-heading-tags {
    font-size: 0.6rem !important;
    color: rgba(255, 255, 255, 0.9);
}

.map-search-story-top-subheading1 {
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 2;
}

.map-search-story-top-subheading {
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}

.map-search-story-profile-pic-box {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 29px;
    left: 20px;
    z-index: 999999999999999 !important;
}

.map-search-story-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    z-index: 999999999999999 !important;
    border: 2px solid rgba(255, 255, 255, 0.8)
        /* background: rgb(93,93,93);
background: radial-gradient(circle, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.map-search-story-photo {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    /* background: rgb(93,93,93);
    background: linear-gradient(0deg, rgba(93,93,93,1) 0%, rgba(175,177,177,1) 48%, rgba(51,52,52,1) 100%); */
}

.map-search-story-photo-size {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: contain;
}

.map-search-memories-full-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 99099999999 !important;
}

.map-search-category-sho-all {
    width: 50%;
    height: 40px;
    position: absolute;
    bottom: 3%;
    left: 25%;
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    border-radius: 30px;
    color: #232231;
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
}

.map-search-category-all-sellected {
    margin-left: 0%;
    margin-top: 20%;
    width: 100% !important;
    position: absolute;
}

.map-search-category-sellected {
    height: max-content;
    background: #fff !important;
    color: rgb(59, 59, 59) !important;
    line-height: 30px;
    border-radius: 30px;
    border: solid 0.5px rgb(204, 204, 204);
    -webkit-box-shadow: 0px 2px 4px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(45, 6, 104, 0.3);
    padding: 0px 12px 0px 12px !important;
    margin: 5px !important;
    /* margin-left: 10px; */
    width: max-content !important;
}

.map-search-category-title {
    color: #232231;
    font-weight: bold;
    font-size: 16px;
    width: 90%;
    height: 30px;
    /* background-color: blue; */
    margin-top: 15px;
    margin-left: 10px;
}

.map-search-category-search-input {
    position: absolute !important;
    top: 13%;
    width: 96%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    left: 2%;
    border-radius: 8px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #e4e5e7 !important;
    padding-left: 15px;
}

.map-search-category-slider {
    width: 100%;
    /* height: 100px;
    padding: 10px; */
    /* background-color: rgb(177, 133, 142); */
    position: absolute;
    bottom: 10%;
}

.map-search-category-frame {
    width: 100px;
    height: 100px;
    padding: 10px;
    /* background-color: blue; */
    margin: auto;
}

.map-search-category-name {
    width: 100%;
    height: 60px;
    text-align: center;
    font-weight: bold;
    /* background-color: crimson; */
    color: #232231;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5px;
}

.map-search-street-view-btn {
    width: 50%;
    position: absolute;
    top: 135px;
    left: 25%;
    height: 28px;
    /* line-height: 53px; */
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #232231;
    /* border: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%); */
    position: fixed !important;
    /* border-radius: 30px; */
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.4);
}

.map-search-map-map {
    opacity: 1 !important;
    width: 100%;
    height: 100%;
}

.map-search-bubble-see-more-disc {
    position: relative;
    /* background-color: blue; */
    color: blue;
    font-weight: bold;
    font-size: 12px !important;
    text-align: center;
    z-index: 99;
    width: 60%;
    margin: auto;
    height: 60%;
    height: 100%;
    padding-top: 1%;
}

.map-search-bubble-see-more {
    position: relative;
    /* background-color: blue; */
    color: blue;
    font-weight: bold;
    font-size: 16px !important;
    text-align: center;
    z-index: 99;
    width: 60%;
    margin: auto;
    height: 60%;
    height: 100%;
    padding-top: 1%;
}


.map-search-place-bubble-silder {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
}

.map-search-place-bubble-discount {
    width: 100%;
    background-color: #222;
    position: absolute;
    margin-left: 0%;
    background: linear-gradient(0deg, rgb(53, 53, 53), #222);
    box-shadow:
        0px 0px 2px 0px #aaa inset,
        0px -1px 2px 0px #aaa inset,
        2px -5px 5px 0px #111 inset,
        0px -5px 5px 0px #111 inset,
        2px 5px 5px 0px #111;
    border-radius: 0px;
    z-index: 999;
    top: 16%;
}

.map-search-place-bubble-sales-text {
    width: 350px;
    height: 20px;
    color: rgb(201, 63, 63);
    z-index: 99;
    text-align: center;
}


.map-search-bubble-closer-name {
    color: #232231;
    z-index: 99;
    position: absolute;
    top: 0%;
    font-size: 11px;
    font-weight: bold;
    left: 0%;
    padding-left: 3px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.map-search-bubble-closer-category {
    color: #232231;
    z-index: 99;
    position: absolute;
    top: 16px;
    font-size: 11px;
    font-weight: bold;
    left: 0%;
    padding-left: 3px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.map-search-bubble-closer-is-open {
    color: #1cc016;
    z-index: 99;
    position: absolute;
    top: 32px;
    font-size: 11px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.map-search-bubble-closer-is-closed {
    color: #c01616;
    z-index: 99;
    position: absolute;
    top: 32px;
    font-size: 11px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.4);
    /* text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; */
}

.map-search-bubble-closer-has-label {
    color: #232231;
    z-index: 99;
    position: absolute;
    top: 32px;
    font-size: 11px;
    font-weight: bold;
    left: 0%;
    padding-left: 18px;
    padding-right: 3px;
    background: rgba(255, 255, 255, 0.3);
}

.map-search-bubble-closer-red-bubble {
    width: 13px;
    height: 13px;
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.map-search-bubble-closer-green-bubble {
    width: 13px;
    height: 13px;
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.map-search-bubble-closer-yellow-bubble {
    width: 13px;
    height: 13px;
    background-color: #f5d020;
    background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
    border-radius: 50%;
    position: absolute;
    left: 1px;
    top: 1.5px;
}

.map-search--promo-photo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map-search-top-down-arrow-disc {
    width: 6%;
    height: 80%;
    position: absolute;
    right: 3%;
    top: 0%;
    /* background-color: blue; */
}

.map-search-top-down-arrow {
    width: 6%;
    height: 62%;
    position: absolute;
    right: 3%;
    top: 0%;
    /* background-color: blue; */
}

.map-search-top-down-arrow-icon {
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 0%;
}

.map-search-new-down-arrow-vox {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    bottom: 9.5%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}

.map-search-new-down-location-vox {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 55px;
    bottom: 9.5%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}

.map-search-new-down-style-vox {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 105px;
    bottom: 9.5%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}

.map-search-new-down-add-vox {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 205px;
    bottom: 9.5%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}

.map-search-new-up-location-vox {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    /* bottom: 38.5%; */
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    z-index: 999;
}


.map-search-new-down-arrow-icn {
    width: 26px;
    height: 26px;
    position: absolute;
    left: 7px;
    bottom: 7px;
}


.map-search-place-show-bubble {
    position: absolute;
    /* height: 100px;
    width: 100%; */
    bottom: 8%;
    left: 0%;
    z-index: 99;
}

.map-search-promo-photo-box {
    position: absolute;
    background-color: #fff;
    top: 30%;
    left: 0%;
    z-index: 999;
}

.map-search-bubble-bottom-text-disc {
    position: absolute;
    border-bottom: 1px solid rgb(241, 241, 241);
    border-top: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, rgb(245, 245, 245) 0%, rgb(235, 235, 235) 65%);
    top: 0%;
    left: 0%;
    z-index: 99;
}

.map-search-bubble-bottom-text {
    position: absolute;
    border-bottom: 1px solid rgb(241, 241, 241);
    border-top: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, rgb(245, 245, 245) 0%, rgb(235, 235, 235) 65%);
    /* bottom: 9%; */
    top: 10%;
    left: 0%;
    z-index: 99;
}

.back-button-map-search-box {
    position: absolute;
    z-index: 99;
    width: 27px;
    height: 27px;
    line-height: 0px;
    top: 14px;
    left: 2%;
}

.map-search-back-button {
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.map-search-mobile-navbar {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 53px;
    line-height: 53px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #232231;
    border-bottom: 1px solid rgb(241, 241, 241);
    background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
    position: fixed !important;
    z-index: 99 !important;
}

.map-search-search-input {
    position: absolute !important;
    top: 5px;
    width: 78%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    left: 10%;
    border-radius: 8px !important;
    border: none;
    color: #969fa4 !important;
    background-color: #e4e5e7 !important;
    padding-left: 40px;
    padding-right: 40px;
    z-index: 99;
}

.map-search-input-icon-search {
    position: absolute;
    width: 25px !important;
    left: 11.5%;
    top: 12px;
    z-index: 99;
}

.map-search-whole-page-layout {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.map-search-map-layout {
    position: absolute;
    top: 0% !important;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map-search-map-layout-sml {
    position: absolute;
    bottom: 0% !important;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.map-search-map-info-window {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    z-index: 99 !important;
}


@media only screen and (max-width: 340px) {
    .map-search-category-all-sellected {
        margin-top: 17%;
    }
}