.create-reservation-modal-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 250px !important;
    margin-top: -100%;
    margin-left: -400%;
    width: 950%;
}
.btn-danger-create-reservation {
    position: absolute;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 38px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    width: 20%;
    left: 40%;
    top:90%
}

.create-reservation-btn-has-user{
    padding: 10px 10px !important;
    border-radius: 30px !important;
    width: 30%;
    text-align: center;
    margin-top: 10%;
    margin-left: 3%; 
    color: #fff;
    background: rgb(134,122,239);
    background: linear-gradient(0deg, rgba(134,122,239,1) 12%, rgba(92,69,185,1) 73%);
    border-color: #5c45b9 !important;
    cursor: pointer;
}
.search-profile-round-button{
    margin-top: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 28px;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
}
.search-profile-icon{
    margin-left: -9px !important;
}
.create-reservation-time-number {
    margin: 8px auto;
    width: 80%;
    height: 28px;
    color: rgb(114, 111, 111);
    background: #fff;
    line-height: 28px;
    border-radius: 6px;
}
.create-reservation-modal-title{
    position: relative;
    font-size: 30px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    margin-left: 0%;
}
.create-reservation-section-title{
    position: relative;
    font-size: 17px;
    width: 10%;
    margin-bottom: -8%;
    color: #3F1E75;
    margin-left: 0%; 
}
.create-reservation-search-section-title{
    position: relative;
    font-size: 17px;
    width: 10%;
    color: #3F1E75;
}
.create-reservation-note{
    position: absolute;
    background: transparent;
    width: 50% !important;
    height: 15%;
    left: 25%;
    top: 80px;
}
.create-reservation-search-position{
    position: absolute;
    width: 100%;
    left: 55%;
    top: 25%;
}
.create-reservation-textbox{
    width: 100%;
    height: 300% !important;
}
.add-reservations-input-icon-search{
    margin-left: 2px !important;
    /* position: absolute;
    width: 2%;
    margin-left: 10px;
    margin-top: 8px; */
    /* z-index: 9999999; */
    /* margin-top: 10px; */
}
.create-reservation-search-box{
    width: 40%;
    margin: auto !important;
    margin-top: 4% !important;
}
.create-reservation-hubst3r-user-search{
    margin-top: 3%;
    text-align: center;
}
.create-reservation-search-user-box{
    width: 100%;
    margin-left: 10%;
    /* margin: auto !important; */
    /* margin-top: 0% !important; */
}
.create-reservation-search-top input{
    border-radius: 9px; 
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    text-align: center;

    /* z-index: 999999 !important; */
}
.add-reservations-search-form-text{
    text-align: left;
}

.create-reservation-modal-table-number{
    color:#3F1E75;
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
    /* margin-top: -35% !important; */
    /* margin-bottom: -20% !important; */
}
.create-reservation-modal-table-reservation{
    color:#3F1E75;
    /* font-weight: bold; */
    font-size: 16px;
    margin-top: 5% !important;
    /* margin-bottom: -20% !important; */
    line-height: 20px;
}
.create-reservation-modal-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.create-reservation-modal-close-icon{
    margin-top: 6px;
    width:60%;
}
.create-reservation-datebox{
    text-align: center;
    margin-top: 60px;
}
.create-reservation-datepicker{
    margin: auto;
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
}
.create-reservation-timebox-position{
    text-align: center;
    margin-top: 60px;
    height: 60px !important;
    width: 120px;
    margin-left: 50px;
}
.create-reservation-time-box{
    margin: auto;
    border-radius: 30px;
    padding: 12px 5px;
    background: #fff;
    border: none;
    font-size: 13px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    height: 40px !important;
}

.create-reservation-name-choises{
    margin: auto;
    width: 100%;
    margin-top: 5%;
}
.create-reservation-btn-no-user{
    background: #fff !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    text-align: center;
    margin: auto;
    margin-top: 0%;
    width: 30%;
    /* margin-left: 3%;  */
}
.create-reservation-btn-no-hubst3r-user{
    background: #fff !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    text-align: center;
    margin: auto;
    margin-top: 0%;
    width: 30%;
}
.create-reservation-btn-hubst3r-user{
    background: #fff !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    text-align: center;
    margin: auto;
    margin-top: 0%;
    width: 30%;
    font-size: -12px;
}

.create-reservation-modal-slider-layout{
    margin-top: -2% !important;
    width:70%;
    margin:auto
}
.create-reservation-hubst3r-user-serarch{
    width: 50%;
    margin: auto;
}
.create-reservation-search-profile-button{
    position: absolute;
    top: 100px;
    right: 300px;
}
.create-reservation-top-tiltle{
    margin-top: 8px;
}
.create-reservation-modal-search{
    margin-top: 1%;
    border: none;
    width: 40%;
}
.create-reservation-modal-search input{
    border-radius: 9px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
}
.create-reservation-modal-modal-frame {
    width: 75px;
    height: 57px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.create-reservation-modal-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 10px;
}
.create-reservation-user-profile{
    border-radius: 50%!important;
    height: 60px!important;
    width: 60px!important;
}
.create-reservation-user-profile{
    position: absolute;
    left: 43%;
    top: 7%;
}
.create-reservation-user-not-found{
    position: absolute;
    top: 70px;
    left: 44%;
}
.create-reservation-user-not-found-text{
    color: #3F1E75;
}
.create-reservation-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 5px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}

.create-reservation-modal-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}

.create-reservation-modal-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 4%;
    color: white!important;
    cursor: pointer;
    float: right;
}

.create-reservation-back-icon-1{
    position: absolute;
    top: 190px;
    left: 2%;
}

.create-reservation-next-icon-1{
    position: absolute;
    top: 190px;
    right: 2%;
}

@media only screen and (max-width: 992px){
    .create-reservation-modal-modal-box {
        height: 250px !important;
        /* margin-top: -285%; */
        margin-left: -270%;
        width: 680%;
    }
    .create-reservation-datebox{
        text-align: center;
        margin-top: 60px;
        margin-left: -120px;
    }
    .create-reservation-timebox-position{
        /* margin-left: -120px; */
    }
    .create-reservation-search-profile-button{
        top: 90px;
        right: 100px;
    }
}


@media only screen and (max-width: 650px){
    .create-reservation-search-profile-button{
        top: 115px;
    }
    .create-reservation-hubst3r-user-search{
        margin-top: 10%;
        text-align: center;
    }
    .create-reservation-hubst3r-user-serarch{
        width: 90%;
        margin: auto;
    }
    .create-reservation-user-profile{
        height: 60px!important;
        width: 60px!important;
    }
    .create-reservation-user-profile{
        left: 38%;
        top: 16%;
    }

    .create-reservation-modal-slider-layout{
        margin-top: -2% !important;
        width:80%;
    }
    
    .create-reservation-modal-modal-box {
        /* margin-top: -355%; */
        margin-left: -172%;
        width: 480%;
    }
    .create-reservation-datebox{
        text-align: center;
        margin-top: 60px;
        margin-left: -220px;
    }
    .create-reservation-timebox-position{
        /* margin-left: 20px;
        margin-top: 15px; */
    }
    .create-reservation-btn-no-user{
        width: 30%;
        font-size: 12px;
        /* margin-left: 3%;  */
    }
    .create-reservation-note{
        position: absolute;
        background: transparent;
        width: 70% !important;
        height: 13%;
        left: 15%;
        /* top: 70%; */
    }
    .btn-danger-create-reservation {
        position: absolute;
        font-size: 18px !important;
        width: 40%;
        margin: auto;
        left: 30%;
    }
    .create-reservation-search-user-box{
        width: 70%;
    }
    .create-reservation-search-box{
        width: 70%;
    }
    .create-reservation-btn-no-hubst3r-user{
        position: absolute;
        margin-top: 70px;
        margin-left: 51%;
        width: 30%;
        font-size: 12px;
    }
    .create-reservation-btn-hubst3r-user{
        position: absolute;
        margin-top: 70px;
        width: 30%;
        font-size: 12px;
        margin-left: 11%;

    }
}

@media only screen and (max-width: 450px){
    .create-reservation-modal-modal-box {
        margin-left: -102%;
        width: 330%;
    }    
    .create-reservation-modal-title{
        font-size: 20px;
    }
    .create-reservation-datebox{
        text-align: center;
        margin-top: 60px;
        margin-left: -190px;
    }
    .create-reservation-timebox-position{
        /* text-align: center;
        margin-top: 60px;
        height: 60px !important;
        width: 120px; */
        margin-left: 30px;
    }
    .create-reservation-modal-slider-layout{
        margin-top: -2% !important;
        width:92%;
        margin-left: 4%;
    }
    .create-reservation-modal-modal-frame {
        width: 50px;
        height: 50px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    }
    .create-reservation-modal-table-reservation{
        color:#3F1E75;
        /* font-weight: bold; */
        font-size: 12px;
        margin-top: 5% !important;
        /* margin-bottom: -20% !important; */
    }
    .create-reservation-btn-no-user{
        width: 50%;
        margin-top: -10px;
    }
    .create-reservation-hubst3r-user-serarch{
        width: 100%;
        margin: auto;
    }
    .create-reservation-search-user-box{
        width: 100%;
    }
    .create-reservation-search-profile-button{
        top: 135px;
        right: 20px;
    }
    /* .create-reservation-note{
        position: absolute;
        background: transparent;
        width: 70% !important;
        height: 8%;
        right: 15%;
        top: 77%;
    }
    .create-reservation-user-profile{
        height: 60px!important;
        width: 60px!important;
    }
    .create-reservation-user-profile{
        left: 5%;
        top: 68%;
    } */
}












/* working hours modal */
.create-reservation-time-modal {
    margin-top: -230%; 
    margin-left: -200%;
    width: 550% !important;
    height: 550%!important;
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
}

.create-reservation-time-title{
    position: relative;
    font-size: 25px;
    width: 70%;
    color: #3F1E75;
    text-align: center;
    margin: auto;  
    margin-bottom: -8%;
}
.create-reservation-modal-back-arrow{
    position: relative;
    width: 2.5%;
    margin-bottom: -60px!important;
    float: left;
    z-index: 1 !important;
    margin-top: 12px;
    margin-right: 0px!important;
}
 

@media only screen and (max-width: 768px){
    .create-reservation-time-modal {
        margin-left: -225%;
    }
}
@media only screen and (max-width: 612px){
    /* working hours modal */
    .create-reservation-time-modal {
        margin-left: -225%;
    }
}

@media only screen and (max-width: 477px){
    .create-reservation-time-modal {
        margin-top: -230%; 
        margin-left: -150%;
        width: 400% !important;
        height: 400%!important;
    }
    .create-reservation-time-title{
        position: relative;
        font-size: 25px;
        width: 70%;
        color: #3F1E75;
        z-index: 3 !important;
        text-align: center;
        margin: auto;  
        margin-bottom: -8%;
    }
    .create-reservation-modal-back-arrow{
        position: relative;
        width: 2.5%;
        margin-bottom: -60px!important;
        float: left;
        z-index: 1 !important;
        margin-top: 12px;
        margin-right: 0px!important;
    }
} 

@media only screen and (max-width: 365px){ 
    .create-reservation-time-modal {
        margin-top: -190%; 
    }
}
@media only screen and (max-width: 330px){
    .create-reservation-time-modal {
        margin-top: -170%; 
    }
}
 


.create-reservation-edit-box{
    position: absolute;
    background-color: #fff;
    width: 20%;
    height: 10% !important;
    top: 55%;
    left:40%;
    box-sizing: content-box;
    border-radius: 12px;
    -webkit-box-shadow: 20px 24px 28px -25px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px 5px rgba(28, 2, 68, 0.3);
    text-align: center;
}
.create-reservation-edit-number {
    margin: 10px auto;
    width: 80%;
    height: 38px;
    color: rgb(114, 111, 111);
    /* background: #fff; */
    line-height: 38px;
    border-radius: 6px;
    margin-top: 1px;
}
.create-reservation-timepicker-clock-layout{
    width: 90% !important;
    height: 90%;
}
.create-reservation-timepicker-size{
    margin-left: 8%;
    margin-top: 24%;
}
.create-reservation-hour-invisible-1{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 53%;
    top: 40%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.create-reservation-hour-invisible-2{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 60%;
    top: 46%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(60deg);
}

.create-reservation-hour-invisible-3{
    background-color: transparent !important;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 62%;
    top: 55%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.create-reservation-hour-invisible-4{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 40px;
    height: 60px;
    left: 60%;
    top: 63.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-65deg);
}

.create-reservation-hour-invisible-5{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 54%;
    top: 71.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-6{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 45%;
    top: 73.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-7{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 36.5%;
    top: 70%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-8{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 30.5%;
    top: 63%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-9{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 28.5%;
    top: 54.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-10{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 29.5%;
    top: 45%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-11{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 36%;
    top: 38.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.create-reservation-hour-invisible-00{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 45%;
    top: 36%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}


.create-reservation-hour-invisible-12{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 42%;
    top: 20%;
    z-index: 1;
    border-radius: 30px;
    /* transform: rotate(-40deg); */
}

.create-reservation-hour-invisible-13{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 58%;
    top: 25.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.create-reservation-hour-invisible-14{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 69.5%;
    top: 40.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(70deg);
}

.create-reservation-hour-invisible-15{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 71.5%;
    top: 54.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.create-reservation-hour-invisible-16{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 68.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(120deg);
}

.create-reservation-hour-invisible-17{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 57.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(152deg);
}

.create-reservation-hour-invisible-18{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 42.5%;
    top: 80.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(180deg);
}

.create-reservation-hour-invisible-19{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 27.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(220deg);
}

.create-reservation-hour-invisible-20{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 17.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(235deg);
}

.create-reservation-hour-invisible-21{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 13%;
    top: 53.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.create-reservation-hour-invisible-22{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 16%;
    top: 38.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(110deg);
}

.create-reservation-hour-invisible-23{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 26%;
    top: 25.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(140deg);
}



@media only screen and (max-width: 477px){
    .create-reservation-all-hiden-hour{
        margin-top:25px;
    }
    .create-reservation-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 55%;
        top: 38%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .create-reservation-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 61.5%;
        top: 44.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .create-reservation-hour-invisible-3{
        background-color: transparent !important;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 63%;
        top: 53%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .create-reservation-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 61%;
        top: 61.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .create-reservation-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 54.5%;
        top: 68%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 46%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 37.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 31%;
        top: 61.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 29%;
        top: 52.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 30.5%;
        top: 43.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 38%;
        top: 37.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 46.5%;
        top: 35.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    .create-reservation-hour-invisible-12{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 22.5%;
        z-index: 1;
        border-radius: 30px;
        /* transform: rotate(-40deg); */
    }
    
    .create-reservation-hour-invisible-13{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 59.5%;
        top: 27%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .create-reservation-hour-invisible-14{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 71%;
        top: 37.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(70deg);
    }
    
    .create-reservation-hour-invisible-15{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 74.5%;
        top: 51.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .create-reservation-hour-invisible-16{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 68.5%;
        top: 64%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(120deg);
    }
    
    .create-reservation-hour-invisible-17{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 57.5%;
        top: 74.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(132deg);
    }
    
    .create-reservation-hour-invisible-18{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 42.5%;
        top: 78.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(180deg);
    }
    
    .create-reservation-hour-invisible-19{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 70px;
        left: 27%;
        top: 73.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(220deg);
    }
    
    .create-reservation-hour-invisible-20{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 70px;
        left: 16%;
        top: 64.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(235deg);
    }
    
    .create-reservation-hour-invisible-21{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 11%;
        top: 49%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
   .create-reservation-hour-invisible-22{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 60px;
        left: 16%;
        top: 34.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(110deg);
    }
    
    .create-reservation-hour-invisible-23{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 60px;
        left: 28%;
        top: 24.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(140deg);
    }
}
@media only screen and (max-width: 477px){
    .create-reservation-timepicker-size{
        margin-left: 10%;
        margin-top: 10%;
        /* width:10% !important */
    }
}

@media only screen and (max-width: 376px){
    .create-reservation-all-hiden-hour{
        margin-top:50px;
        margin-left: 30% !important;
    }
    .create-reservation-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 54%;
        top: 41%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .create-reservation-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 60%;
        top: 47%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .create-reservation-hour-invisible-3{
        background-color: transparent !important;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 62%;
        top: 54%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .create-reservation-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 40px;
        height: 60px;
        left: 60%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .create-reservation-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 55%;
        top: 67.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 34.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 24%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 45%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 35%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .create-reservation-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 38.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
}