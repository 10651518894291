.speech-to-text-bubble-triangle{
    position: absolute;
    top: 44%;
    left: 40.5%;
    width: 50px;
    height: 50px;
    transform: rotate(112deg);
    z-index: 999;
    /* background-color: brown; */
}
.speech-to-text-bubble-triangle-img{
    width: 100%;
    height: 100%;
}

.speech-to-text-box{
    position: absolute;
    top: 19%;
    left: 5%;
    width: 90%;
    height: 28%;
    border-radius: 40px;
    background-color: #ffff;
    z-index: 999;
}

.speech-to-text-profile-pic-box-box{
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 100%;
    height: 40px;
    z-index: 9999; 

}

.speech-to-text-profile-pic-box{
    position: relative;
    width: 40px;
    height: 40px;
    margin: auto;
}

.speech-to-text-profile-pic-img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.speech-to-text-title{
    position: absolute;
    top: 7%;
    left: 10%;
    width: 80%;
    height: 10%;
    /* background-color: brown; */
    color: #232231;
    font-weight: bold;
    /* border-bottom: 2px solid rgb(241, 241, 241); */
}



.speech-to-text-input-box{
    position: absolute;
    top: 22%;
    left: 5%;
    width: 90%;
    height: 60%;
    border-radius: 8px;
    z-index: 9999999999999999;
    padding: 15px;
    padding-bottom: 30px;
    text-align: justify;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    background-color: rgb(240, 239, 239);
}

.speech-to-text-show-text{
    position: absolute;
    top: 22%;
    left: 5%;
    width: 90%;
    height: 65%;
    border-radius: 8px;
    z-index: 9999999999999999;
    padding: 15px;
    text-align: justify;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    color: #232231;
    /* background-color: rgb(240, 239, 239); */
}

.speech-to-text-restart-btn{
    position: absolute;
    width: 50%;
    text-align: center;
    background-color: #c42e2e;
    bottom: 0%;
    left: 0%;
    /* border-radius: 4px; */
    height: 40px;
    line-height: 40px;
    z-index: 999999999;
    font-weight: bold;
    font-size: 18px;
}
.speech-to-text-send-btn{
    position: absolute;
    /* padding: 10px 20px; */
    width: 50%;
    text-align: center;
    bottom: 0%;
    left: 50%;
    /* border-radius: 4px;      */
    height: 40px;
    line-height: 40px;
    z-index: 999999999;
    background: #1183ca !important;
    border: none !important;
    color: #fff !important;
    font-weight: bold;
    font-size: 18px;
}

.chat-room-sending-profile-pic-box{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    left: 78px;
}

.chat-room-sending-profile-pic-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.chat-room-sending-username-align{
    position: absolute;
    left: 48px;
    text-align: left !important;
    /* margin-left: 47px; */
    color: #232231 !important;
    /* background-color: #3225e2; */
}

.back-button-chat-room-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
    
}
.chat-room-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.chat-room-total-page-layout{
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0px !important;
    left: 0px !important;
    background: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px -100px 120px -50px inset; */
    box-shadow:0px 5px 30px 10px rgba(0,0,0,0.5) inset;
}
.chat-room-sound-proof-box{
    display: none;
}



@media only screen and (max-width: 1200px){
    .chat-room-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }
    .chat-room-sound-proof-box{
        display: block;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }
    .chat-room-sound-proof-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width: 360px){
    .speech-to-text-bubble-triangle{
        top: 43%;
        left: 39%;
    }
}

@media only screen and (max-width: 340px){
    .speech-to-text-bubble-triangle{
        top: 42%;
        left: 38%;
    }
}