.hub-info-all-boxes{
    position: relative;
    margin-top: 70px;
    margin-left: 0%;
    width: 100%;
    height: max-content;
}

.hub-info-set-boxe{
    position: relative;
    margin:auto;
    margin-top: 20px;
    width: 150px;
    height: 150px;
    display: block;
    border-radius: 20px;
    position: relative;
    /* display: inline-block; */
    /* overflow: hidden;     */
    /* font-family: verdana; */
    text-decoration: none;
    background: linear-gradient(0deg, rgb(245, 245, 245),rgb(236, 236, 236));
    /* text-shadow: 0px 0px 2px rgba(0, 0, 0, .5); */
    transition: 0.2s;
    box-shadow: 0 0 10px rgb(199, 195, 195);
    border: solid 0.5px rgb(204, 204, 204);
}


.hub-info-set-boxe:active {
    transform: scale(0.94);
}

.hub-info-set-boxe:focus {
    transform: scale(0.94);
}

.hub-info-set-boxe::after {
    transform: scale(0.94);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}


.hub-info-set-img-box{
    width: 75px;
    height: 75px;
    margin: auto;
    margin-top: 10px;
}

.hub-info-set-text{
    width: 90%;
    height: 30px;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    color: #232231;
    line-height: 20px;
}

.hub-info-set-img-img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}


.hub-info-logo-img-box{
    width: 120px;
    height: 120px;
    margin: auto;
}

.hub-info-logo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: solid 0.5px rgb(204, 204, 204);
}

.back-button-hub-mobile-info-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.hub-mobile-info-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
}

.hub-info-top-bar-width{
    width: 100%;
    margin: auto;
}
.hub-info-top-bar-item{
    width: 30%;
    margin: auto;
}
.hub-info-top-img{
    width: 50%;
    margin: auto;
}
.hub-info-top-bar-text{
    color: #575578;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
}

.hub-info-top-round-button{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 90px;
    height: 90px;
    /* width: 50%; */
    text-align: center;
    /* line-height: 144px; */
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto !important;
}
.hub-info-round-img{
    width: 60%;
    margin-top:15%
}


.hub-info-splash-btn{
    position: absolute;
    right: 3%;
    top: 2.5%;
    width: 60px;
    height: 60px;
    z-index: 1;
}
.hub-info-splash-img{
    width: 100%;
    border-radius: 50%;
    margin: auto;
}

/* loading */
.hub-info-section-disappear{
    display: none;
}
.hub-info-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.hub-info-lottie-dissappear{
    display: none;
}
/* end loading */

/* Global CSS start */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.text-blue-6{
    color: #575578;
}
/* Global CSS end */
.hub-info-change-promo{
    margin-left: 30%;
}
.add-promo-image-icon{
    position: absolute;
    margin-top: 65%;
    margin-left:15%;
    width: 40%;
}
.post-promo-photo-btn{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    color: white!important;
    cursor: pointer;
    float: right;
    margin-top: 3%;
}
/* Body left css start */
.body-left-6{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.logo-6{
    padding: 30px 40px;
    margin-top: 30px;
}
.text-white-6{
    color: #fff;
}
.left-text-6{
    font-size: 15px;
}
.left-icon-6 img{
    width: 45%;
    margin-top: 5px;
}
.left-home-6{
    cursor: pointer;
}
.left-home-6:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body right logo section css start */
.col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
    margin-top: -30px;
    /* max-width: 100% !important; */
    width: 100%;
    max-height: 360px;
    margin-left: 0%!important;
    border-radius: 20px 20px 0px 0px;
    object-fit: cover;
}
.body-right-6{
    margin-left: 16.65%;
    background-color: #fff;
    padding: 20px 0px;
}
.congestion-text-6{
    font-size: 24px;
}
.grp-6{
    width: 10%;
    margin-top: -2rem;
}
.all-6{
    width: 80% !important;
    margin: auto;
}
.item-6 strong{
    color: #575578;
    word-spacing: 1px;
}
.item-1st-6{
    /* margin-top: 10px !important; */
    margin-bottom: 15px !important;
}
.all-item-6{
    background-color: #fff;
}
.row.all-item-6 {
    border-radius: 0px 0px 20px 20px !important;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}
.hub-info-time-picker-layout{
    margin-top: -2%;
    width: 60%;
    padding-left: 60px !important;
    font-size: 16px;
    text-align: center;
}
.timepicker-layout{
    border-radius: 50% !important;
}

.bg-white-6{
    background: #fff;
    width: 96.99%;
    margin: auto;
    border-radius: 22px;
}
.box-item-6{
    margin-top: -70px;
}

.hub-page-round-buttons{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 44px;
    height: 44px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
}
.hub-info-cover-subtitle{
    margin: auto;
    margin-top: -1%;
    margin-bottom: 2%;
    text-decoration: underline;
}
.all-items-hub-page{
    margin-left: 0%;
}
.hub-page-information-item{
    margin-left: 15%;
    cursor: pointer;   
}
.hub-page-all-titles{
    /* margin-left: 12%; */
    margin-bottom: 15px;
    color: #575578;
}
.hub-page-information-title-1{
    margin-left: 12.5%;
}
.hub-page-information-title-2{
    margin-left: 10%;
}
.hub-page-information-title-3{
    margin-left: 10%;
}
.hub-page-information-title-4{
    margin-left: 13.5%;
}
.hub-page-information-img{
    width: 18px !important;
    height: 18px !important;
    position: absolute;
    top:13px;
    left: 13.5px;
    /* margin-top: -105px; */
}

/* working hours modal */
.hub-info-workingdays-modal {
    margin-top: -230%; 
    margin-left: -200%;
    width: 550% !important;
    height: 550%!important;
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff;
}
.hub-times-modal-size{
    position: absolute;
    width: 9%;
    right: 6%;
    top: 4%;
    z-index: 3 !important;
    cursor: pointer
}
.working-hours-timepicker{
    margin-top: 20% !important;
    margin: auto;
    width: 43% !important;
}
.hub-page-modal-content {
    border-radius: 40px;
    width: 100%;
}
.hub-page-days-mon{
    margin-top: -72.5%;
    margin-left: 25%;
}
.b-hours{
    margin-top: -74%;
}
.working-day-open-btn{
    background: #fff !important;
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    color: #575578 !important;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
    /* margin-top: -8%; */
    text-align: center; 
    width: 100%;
} 
.working-day-back-icon{
    width: 8%;
    /* float: center; */
    margin-left: 3% !important;
    margin-top: -4% !important;    
}
.working-day-door{
    margin-top: -1%;
    width: 5%;
}
.working-day-edit-title{
    position: relative;
    font-size: 25px;
    width: 70%;
    color: #3F1E75;
    z-index: 3 !important;
    text-align: center;
    margin: auto;  
    margin-bottom: -8%;
}
.working-day-update-btn{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 3px 0px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    width: 20%;
    color: white;
    float: right;
    margin-top: -7%;
    text-align: center;
}
.modal-back-arrow{
    position: relative;
    width: 2.5%;
    margin-bottom: -60px!important;
    float: left;
    z-index: 1 !important;
    margin-top: 12px;
    margin-right: 0px!important;
}
.open-close-splitter{
    font-size: 25px;
    margin-left: -5px;
    margin-right: 5px;
}
.hub-page-days-edit {
    margin-top: 1%;
    margin-left: 38%;  
}
.hub-page-days-edit span{
    font-size: 21px;
    font-weight: bold;
}
.edit-working-hours-pencil{
    width: 8%;
    float: center;
    margin-left: 3% !important;
    margin-top: -4% !important;
}
.timepicker-working-day-edit{
    width: 40% !important;
    margin-left: -11%;
    margin-right: 2%;
    /* height: 140px !important; */
    padding-left: 50px !important;
    padding-top: 1px !important;
    font-size: 12px !important;
}
/* working hours modal */

.hub-info-shop-logo img {
    position: relative;
    top: 92px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 6.8rem;
    height: 6.8rem;
    border-radius: 50%;
    object-fit: cover;
}
.l-camera1 {
    position: absolute;
    z-index: 9999;
    left: 38%;
    top: 28px;
    border-radius: 50%;
    width: 12% !important;
}
.l-camera1 img{
    border-radius: 50%;
    /* margin: auto; */
    /* margin-top: 10%; */
    width: 20% !important;
}
.l-camera {
    position: relative;
    left: 2%;
    top: 20px;
    width: 65px;
    height: 65px;
    z-index: 9999 !important;
}
.l-camera img{
    margin: auto;
    margin-top: 25%;
    width: 60%;
}

.white-box{
    max-width: 85%;
    margin: auto;
}
.w-box1{
    color: #575578;
    margin-top: 1.5rem;
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 10px;
}
.w-box1-s{
    font-size: 18px;
}
.add-box {
    background-color: #6232B2;
    padding: 10px 20px;
    width: 55%;
    border-radius: 13px;
    margin: 20px auto 20px auto;
}
.add-box strong{
    color: #fff;
}
.remove-btn-6 {
    background-color: #ca0c0c !important;
    border: none !important;
    padding: 3px 16px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 16px !important;
    float: right;
    color: #fff !important;
}
.sub-box-6{
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4); 
    box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4);
    padding: 13px 20px;
    border-radius: 13px;
    height: 55px;
}
.hub-info-input{
    position: relative;
    margin-top: -0px;
    /* margin-left: 100%; */
}
.hub-info-input{
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4); 
        box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4);
        padding: 13px 20px;
        border-radius: 13px;
        height: 35px;
        width: 150px;
        text-align: center!important;
}
.text-box-6-input{
    border: 1px solid #b09bd3;
    border-radius: 10px;
    /* margin-top: 8px; */
    width: 165%;
    /* height: 100px; */
    margin-left: 10px;
}

.w-box2, .w-box3{
    color: #575578;
    margin-top: 1.5rem;
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 10px;
}
/* .text-line-6 img{
    margin-right: 1rem;
}
.text-line-6 span{
    margin-left: 2.7rem;
} */
.edit-hour{
    color: #6232B2;
    cursor: pointer;
}
.text-box-6 {
    border: 1px solid #b09bd3;
    border-radius: 10px;
    margin-top: 8px;
    width: 95%;
    margin: 15px auto 0px auto;
}
.text-box-6 p{
    font-size: 13px;
    padding: 5px 5px;
    margin-bottom: 0px;
    color: #6232B2;
}
.small-header-container-6{
    display: none;
}
.show-small-6{
    display: none !important;
}
.hub-info-small-header-6{
    display: none !important;
}
.ss-text-line{
    display: none !important;
}
.hide-box{
    display: block !important;
}
.nav-bottom-6{
    display: none !important;
}
.hub-info-icon {
    width: 20px;
    height: 20px!important;
    margin-right: 8px;
}
.hub-info-icon-activity{
    width: 18px;
    height: 18px!important;
    margin-left: -5px;
    margin-right: 10px;
    margin-top: -3px;
}
.hub-info-info{
    width: 50%;
    margin-left: 75px;
}
.hub-info-pencil-icon {
    width: 20px;
    height: 20px!important;
    margin-left: 200px;
    margin-top: -60px !important;
}
.hub-info-pencil-icon-description {
    width: 20px;
    height: 20px!important;
    margin-right: -28px!important;
}
.hub-user-logout-button{
    background-color: #ce0404 !important;
    color: white !important;
    border: none !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    padding: 10px 35px !important;
    width: 20%;
    margin: auto;
    margin-top: 80px;
    font-size: 25px !important;
    text-align: center;
}
.hub-activate-hub-button{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    color: white !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    padding: 10px 35px !important;
    width: 20%;
    margin: auto;
    margin-top: 80px;
    font-size: 25px !important;
    text-align: center;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1200px){
    .hub-info-logo-img-box{
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .hub-mobile-info-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }
    .hub-info-change-promo{
        margin-top: -2%;
        margin-bottom: 4%;
    }
    .whole-hub-info-page-layout{
        position: absolute;
        top: 0;
        left:0;
        width: 100% !important;
        height: 100% !important;
    }
    .input-box-mobile-style{
        width: 100%;
    }
    .description-mobile-input{
        width: 100%;
    }
    .text-box-6-2 {
        border: 1px solid #b09bd3;
        border-radius: 10px;
        margin-top: 8px;
        width: 120%;
        margin: 15px auto 0px auto;
    }
    .text-box-6-2 p{
        font-size: 13px;
        padding: 5px 5px;
        margin-bottom: 0px;
        color: #6232B2;
    }
    .hub-info-timepicker-small{
        margin-top: -2%;
        width: 30% !important;
        padding-left: 10px !important;
        font-size: 12px;
        text-align: center;
    }
    .body-left-6{
        display: none;
    }
    .body-right-6{
        margin-left: initial;
        border-radius: 50px 50px 0px 0px;
    }
    .color-6{
        /* background: rgb(28,48,111);
        background: linear-gradient(143deg, rgba(28,48,111,1) 3%, rgba(144,36,103,1) 23%); */
        width: 100%;
    }
    .small-header-container-6{
        display: block;
    }
    .top-logo-section-6{
        display: none;
    }
    .congestion-text-6{
        display: none;
    }
    .b-logo{
        width: 17%;
        margin-bottom: 1.3rem;
    }
    .show-small-6{
        display: block !important;
    }
    /* .all-item-6{
        display: none !important;
    } */
    .row.hub-info-small-header-6 {
        border-radius: 0px 0px 20px 20px !important;
        width: 100%;
        margin: auto;
        padding-bottom: 20px;
        padding-top: 20px;
        color: #575578;
        font-size: 14px;
    }
    .bg-white-6 {
        background: #fff;
        width: 100%;
        margin: auto;
        border-radius: 22px;
    }
    .hub-info-small-header-6{
        display: block !important;
        color: #575578;
        padding: 20px 25px;
    }
    .ss-text-line{
        display: block !important;
        width: 100%;
    }
    .hide-box{
        display: none !important;
    }
    .all-6 {
        width: 75% !important;
        margin: auto;
    }
    .nav-bottom-6{
        display: block !important;
    }
    .home.footer-icon-6.middle-icon-footer-5 img {
        width: 62%;
        position: absolute;
        top: -35px;
        margin-left: 0%;
        transform: translateX(-50%);
    }
    .l-camera {
        left: 1%;
        top: 20px;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        margin-top: -30px;
        width: 100%;
        /* max-width: 900px; */
        max-height: 300px;
        margin-left: 0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera1 {
        position: absolute;
        z-index: 9999;
        left: 130px;
        top: 125px;
        border-radius: 50%;
        width: 3.5% !important;
    }
}

@media only screen and (max-width: 992px){
    .hub-page-all-informations{
        margin-top: 20px !important;
        margin-bottom: 20px;
    }
    .hub-page-information-title-1{
        margin-left: 13%;
    }
    .hub-page-information-title-2{
        margin-left: 10%;
    }
    .hub-page-information-title-3{
        margin-left: 9%;
    }
    .hub-page-information-title-4{
        margin-left: 13.5%;
    }
    .hub-info-shop-logo img {
        position: relative;
        top: 92px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 6rem;
        height: 6rem;

    }
    .hub-info-h-img-6{
        margin: auto!important;
    }
    .side-icon-6 img {
        width: 2.3rem;
    }
    .b-logo {
        width: 19%;
        margin-bottom: 1.3rem;
    }

    .hub-info-info{
        width: 50%;
        margin-left: 30%;
    }
    .hub-info-pencil-icon {
        margin-left: 300px;
        margin-top: -60px !important;
    }
    .hub-info-pencil-icon-description {
        margin-right: -5px!important;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        margin-top: -30px;
        /* max-width: 744px; */
        width: 100%;
        max-height: 320px;
        margin-left: 0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera1 {
        left: 110px;
        top: 115px;
        width: 3.5% !important;
    }
}

@media only screen and (max-width: 852px){
    .hub-page-information-item{
        margin-left: 14%;
    }
    .hub-page-information-title-1{
        margin-left: 9%;
    }
    .hub-page-information-title-2{
        margin-left: 9%;
    }
    .hub-page-information-title-3{
        margin-left: 8%;
    }
    .hub-page-information-title-4{
        margin-left: 13%;
    }
}

@media only screen and (max-width: 780px){
    .l-camera1 {
        left: 90px;
        top: 100px;
        width: 3.5% !important;
    }
}

@media only screen and (max-width: 768px){
    .b-logo {
        width: 27%;
        margin-bottom: 1.3rem;
    }
    .hub-info-shop-logo img {
        position: relative;
        top: 92px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 5.5rem;
        height: 5.5rem;
    }
    .all-6 {
        width: 85% !important;
        margin: auto;
    }
    .hub-info-shop-logo img {
        position: relative;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 5.5rem;
        height: 5.5rem;
    }
    .hub-info-info{
        width: 100%;
        margin-left: 20%;
    }
    .hub-info-pencil-icon {
        margin-left: 200px;
        margin-top: -60px !important;
    }
    .hub-info-pencil-icon-description {
        margin-right: 3px!important;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 932px ;
        max-height: 320px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera {
        left: 1%;
        top: 25px;
    }
    .hub-user-logout-button{
        width: 30%;
    }
    .hub-activate-hub-button{
        width: 30%;
    }
    
    /* working hours modal */
    .hub-info-workingdays-modal {
        margin-left: -225%;
    }
    .hub-page-information-item{
        margin-left: 14.5%;
    }
    .hub-page-all-titles strong{
        font-size: 13px !important;
    }
    .hub-page-information-title-1{
        margin-left: 12%;
    }
    .hub-page-information-title-2{
        margin-left: 11%;
    }
    .hub-page-information-title-3{
        margin-left: 11%;
    }
    .hub-page-information-title-4{
        margin-left: 15.5%;
    }
}
@media only screen and (max-width: 612px){
    /* working hours modal */
    .hub-info-workingdays-modal {
        margin-left: -225%;
    }
    /* working hours modal */
    .hub-page-information-item{
        margin-left: 12%;
    }
    .hub-page-all-titles strong{
        font-size: 12px !important;
    }
    .hub-page-information-title-1{
        margin-left: 12%;
    }
    .hub-page-information-title-2{
        margin-left: 7%;
    }
    .hub-page-information-title-3{
        margin-left: 8%;
    }
    .hub-page-information-title-4{
        margin-left: 12.5%;
    }
}
@media only screen and (max-width: 576px){
    .hub-info-change-promo{
        margin-left: 70%;
        margin-top: -7%;
        margin-bottom: 4%;
    }
    .hub-info-timepicker-small{
        margin-top: -2%;
        width: 37% !important;
        padding-left: -0px !important;
        font-size: 12px;
        text-align: center;
        margin-left: 2%;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 490px ;
        max-height: 230px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .side-icon-6 img {
        width: 1.7rem;
    }
    .h-img-6 img{
        width: 25%;
    }
    .b-logo {
        width: 32% !important;
        margin-bottom: 1.3rem;
    }
    .item-6 img {
        width: 18px;
        margin-right: 5px;
    }
    .item-img-6 img {
        width: 15px;
    }
    .item-6 strong {
        font-size: 13px;
    }
    .add-box {
        background-color: #6232B2;
        padding: 10px 20px;
        width: 62%;
        border-radius: 13px;
        margin: 20px auto 20px auto;
    }
    .hub-info-shop-logo img {
        position: relative;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 4.8rem;
        height: 4.8rem;
    }

    .middle-icon-footer-6 img {
        width: 60%;
        margin-left: 50%;
        transform: translate(-140%);
        position: absolute;
        top: -25px !important;
    }
    .home.footer-icon-6.middle-icon-footer-5 img {
        width: 65% !important;
        position: absolute;
        top: -28px;
        margin-left: 0%;
        transform: translateX(-50%);
    }
    .hub-info-info{
        width: 100%;
        margin-left: 30%;
    }
    .hub-info-pencil-icon {
        margin-left: 220px;
        margin-top: -60px !important;
    }
    .hub-info-pencil-icon-description {
        margin-left: 120px;
    }
    .l-camera {
        left: 1%;
        top: 20px;
    }
    .l-camera1 {
        left: 70px;
        top: 75px;
        width: 3.5% !important;
    }
    .hub-page-all-titles{
        display: none;
    }
    .all-items-hub-page{
        margin-left: -5%;
    }
    .hub-page-information-item{
        margin-left: 11%;
    }  
}
@media only screen and (max-width: 530px){
    .hub-info-info{
        width: 100%;
        margin-left: 30%;
    }
    .hub-info-pencil-icon {
        margin-left: 180px;
        margin-top: -60px !important;
    }
    /* .hub-info-pencil-icon-description {
        margin-right: 100px!important;
    } */
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 444px ;
        max-height: 222px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera {
        left: 1%;
        top: 20px;
    }
    .hub-user-logout-button{
        padding: 10px 25px !important;
        width: 35%;
        font-size: 20px !important;
    }
    .hub-activate-hub-button{
        padding: 10px 25px !important;
        width: 35%;
        font-size: 20px !important;
    }
    
}

@media only screen and (max-width: 477px){
    .w-col-6{
        max-width: 20% !important;
    }
    .w-col-who {
        max-width: 37.666667% !important;
    }
    .hub-info-shop-logo img {
        position: relative;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 4rem;
        height: 4rem;
    }
    .hub-info-info{
        width: 100%;
        margin-left: 20%;
    }
    .hub-info-pencil-icon {
        margin-left: 150px;
        margin-top: -60px !important;
    }
    .hub-info-pencil-icon-description {
        margin-left: 75px!important;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 404px ;
        max-height: 202px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera {
        left: 1%;
        top: 20px;
    }
    /* working hours modal */
    .hub-info-workingdays-modal {
        margin-top: -230%; 
        margin-left: -150%;
        width: 400% !important;
        height: 400%!important;
    }
    .hub-times-modal-size{
        position: absolute;
        width: 9%;
        right: 9%;
        top: 4%;
        z-index: 3 !important;
        cursor: pointer
    }
    .working-day-update-btn{

        font-size: 16px;
        width: 23%;
        color: white;
        float: right;
        margin-top: -5%;
        padding: 5px 0px;

        /* margin-left: 10px; */
    }
    .working-hours-timepicker{
        margin-top: 20% !important;
        margin: auto;
        width: 43% !important;
    }
    .hub-page-modal-content {
        border-radius: 40px;
        width: 100%;
    }
    .hub-page-days-mon{
        margin-top: -72.5%;
        margin-left: 25%;
    }
    .b-hours{
        margin-top: -74%;
    }
    .working-day-open-btn{
        background: #fff !important;
        border: none !important;
        padding: 1px 5px !important;
        border-radius: 30px !important;
        color: #575578 !important;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4); 
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.4);
        /* margin-top: -8%; */
        text-align: center; 
        width: 100%;
    } 
    .working-day-back-icon{
        width: 8%;
        /* float: center; */
        margin-left: 3% !important;
        margin-top: -4% !important;    
    }
    .working-day-door{
        margin-top: -1%;
        width: 5%;
    }
    .working-day-edit-title{
        position: relative;
        font-size: 25px;
        width: 70%;
        color: #3F1E75;
        z-index: 3 !important;
        text-align: center;
        margin: auto;  
        margin-bottom: -8%;
    }
    .modal-back-arrow{
        position: relative;
        width: 2.5%;
        margin-bottom: -60px!important;
        float: left;
        z-index: 1 !important;
        margin-top: 12px;
        margin-right: 0px!important;
    }
    .open-close-splitter{
        font-size: 25px;
        margin-left: -5px;
        margin-right: 5px;
    }
    .hub-page-days-edit {
        margin-top: 0.5%;
        margin-left: 38%;  
    }
    .hub-page-days-edit span{
        font-size: 15px;
        font-weight: bold;
    }
    .edit-working-hours-pencil{
        width: 8%;
        float: center;
        margin-left: 3% !important;
        margin-top: -4% !important;
    }
    .timepicker-working-day-edit{
        width: 40% !important;
        margin-left: -11%;
        margin-right: 2%;
        /* height: 140px !important; */
        padding-left: 50px !important;
        padding-top: 1px !important;
        font-size: 12px !important;
    }
    /* working hours modal */
}
@media only screen and (max-width: 457px){
    .hub-info-timepicker-small{
        width: 45% !important;
        padding-left: 10px !important;
        margin-left: 2%;
    }
    .footer-icon-6 img {
        width: 28% !important;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 400px ;
        max-height: 190px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
    .l-camera {
        left: 1%;
        top: 20px;
    }
    .l-camera1 {
        left: 50px;
        top: 60px;
        width: 3.5% !important;
    }
    .hub-info-change-promo{
        margin-left: 70%;
        margin-top: -9%;
        margin-bottom: 4%;
    }
}
@media only screen and (max-width: 450px){
    .hub-info-logo-img-box{
        width: 90px;
        height: 90px;
        margin-top: 60px;
        margin-bottom: 0%;
    }
    .all-items-hub-page{
        margin-left: -9%;
    }
    .hub-page-information-item{
        margin-left: 11%;
    }  
    .hub-info-change-promo{
        margin-left: 70%;
        margin-top: -9%;
        margin-bottom: 4%;
    }
}
@media only screen and (max-width: 442px){
  
    .all-items-hub-page{
        margin-left: -9%;
    }
    .hub-page-information-item{
        margin-left: 11%;
    }  
    .hub-info-change-promo{
        margin-left: 70%;
        margin-top: -13%;
        margin-bottom: 7%;
    }
}
@media only screen and (max-width: 412px){
    .item-6 strong {
        font-size: 11px;
    }
    .item-6 img {
        width: 15px;
        margin-right: 5px;
    }
    .item-6 img {
        width: 13px;
        margin-right: 5px;
    }
    .add-box {
        background-color: #6232B2;
        padding: 10px 20px;
        width: 95%;
        border-radius: 13px;
        margin: 20px auto 20px auto;
    }

    .w-box1-s {
        font-size: 15px;
    }
    .all-6 {
        width: 88% !important;
        margin: auto;
    }

    .white-box {
        max-width: 88%;
        margin: auto;
    }
    .w-box2 {
        width: 91%;
        margin: 20px 20px auto;
    }
    .item-6 strong {
        font-size: 13px;
    }

    .body-right-6 {
        margin-left: initial;
        border-radius: 35px 35px 0px 0px;
    }

    .container-fluid.small-header-container-6 {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        height: 160px;
    }
    .home.footer-icon-6.middle-icon-footer-5 img {
        width: 85% !important;
        position: absolute;
        top: -28px;
        margin-left: 0%;
        transform: translateX(-50%);
    }
    .footer-icon-6 img {
        width: 60% !important;
    }
    .l-camera {
        left: 1%;
        top: 25px;
    }
}

@media only screen and (max-width: 376px){
    .hub-info-timepicker-small{
        width: 50% !important;
        font-size: 11px;
        padding-left: 10px !important;
        margin-left: 2%;
    }
    .col-xl-4.col-md-4.col-sm-4.col-4.text-line-6 strong {
        font-size: 13px;
    }
    .col-xl-1.col-md-1.col-sm-1.col-1.s-icon-6 img {
        width: 16px;
    }
    .s-text-6 strong{
        font-size: 13px;
    }
    .small-text-6 strong{
        font-size: 13px;
    }
    .last-box-6 strong{
        font-size: 13px;
    }
    .col-xl-4.col-md-4.col-sm-4.col-5.last-box-6 img {
        width: 16px;
    }
    .location-img-6 img{
        width: 13px !important;
    }
    .col-xl-4.col-md-4.col-sm-4.col-2.last-box-6.lock-img.text-right img {
        width: 15px;
    }
    .add-box {
        background-color: #6232B2;
        padding: 10px 20px;
        width: 85%;
        border-radius: 13px;
        margin: 20px auto 20px auto;
    }
    .sub-box-6 strong {
        font-size: 14px;
    }
    .remove-btn-6 {
        background-color: #ca0c0c !important;
        border: none !important;
        padding: 3px 12px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 13px !important;
        float: right;
    }
    .w-box1-s {
        font-size: 14px;
    }
    .sub-box-6 {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4);
        box-shadow: 0px 0px 15px -5px rgba(45, 6, 104, 0.4);
        padding: 8px 15px;
        border-radius: 11px;
        height: 43px;
    }
    .hub-info-info{
        width: 100%;
        margin-left: 20%;
    }
    .hub-info-pencil-icon {
        margin-left: 120px;
        margin-top: -60px !important;
    }
    .hub-info-pencil-icon-description {
        margin-left: 55px!important;
    }
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 328px ;
        max-height: 164px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
}
@media only screen and (max-width: 365px){
    .all-items-hub-page{
        margin-left: -5%;
    }
    .hub-page-information-item{
        margin-left: 9%;
    }  
    .hub-info-workingdays-modal {
        margin-top: -190%; 
    }
}
@media only screen and (max-width: 330px){
    .hub-info-timepicker-small{
        width: 50% !important;
        font-size: 11px;
        padding-left: 0px !important;
        margin-left: 2%;
    }
    .hub-user-logout-button{
        padding: 10px 25px !important;
        width: 40%;
        font-size: 20px !important;
    }
    .hub-activate-hub-button{
        padding: 10px 25px !important;
        width: 40%;
        font-size: 20px !important;
    }
    .all-items-hub-page{
        margin-left: -5%;
    }
    .hub-page-information-item{
        margin-left: 7%;
    } 
    .hub-info-workingdays-modal {
        margin-top: -170%; 
    }
}
@media only screen and (max-width: 321px){
    .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12.cover-photo-item img {
        width: 100% !important;
        margin-top: -30px;
        max-width: 282px ;
        max-height: 146px;
        margin-left: -0%!important;
        border-radius: 20px 20px 0px 0px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 319px){
    .w-col-6 {
        max-width: 15% !important;
    }
    .w-box2, .w-box3 {
        color: #575578;
        margin-top: 1.5rem;
        background-color: #fff;
        padding: 20px 11px;
        border-radius: 10px;
    }
    .w-col-who {
        max-width: 36.666667% !important;
    }
    .home.footer-icon-6.middle-icon-footer-5 img {
        width: 100% !important;
        position: absolute;
        top: -28px;
        margin-left: 0%;
        transform: translateX(-50%);
    }
    .all-items-hub-page{
        margin-left: -7%;
    }
    .hub-page-information-item{
        margin-left: 5%;
    } 
}
 
@media only screen and (max-width: 297px){
    .add-box {
        background-color: #6232B2;
        padding: 10px 20px;
        width: 95%;
        border-radius: 13px;
        margin: 20px auto 20px auto;
    }
    .item-6 strong {
        font-size: 9px;
    }
    .container-fluid.small-header-container-6 {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        height: 135px;
    }
    .side-icon-6 img {
        width: 1.4rem;
    }
    .w-box1-s {
        font-size: 12px;
    }
    .hub-info-timepicker-small{
        width: 45% !important;
        font-size: 9px;
        padding-left: 0px !important;
        margin-left: 5%;
    }
}

/* Footer icon css start */
.nav-bottom-6{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon-6 img {
    width: 22%;
}
/* .middle-icon-footer img {
    position: absolute;
    top: -30px;
    width: 92px !important;
    left: 30px;
} */
.middle-icon-footer-6 img {
    width: 60%;
    margin-left: 50%;
    transform: translate(-140%);
    position: absolute;
    top: -46px;
}
.footer-width-6{
    width: 20% !important;
}
/* Footer icon css end */








/* timepicker css */
/* .all-hiden-hour{
    margin-top:-120px;
} */
.hub-hours-edit-box{
    position: absolute;
    background-color: #fff;
    width: 20%;
    height: 10% !important;
    top: 55%;
    left:40%;
    /* margin-left:30%;
    margin-top: 4%; */
    /* padding: 20px; */
    box-sizing: content-box;
    border-radius: 12px;
    -webkit-box-shadow: 20px 24px 28px -25px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px 5px rgba(28, 2, 68, 0.3);
    text-align: center;
}
.hub-hours-edit-number {
    margin: 10px auto;
    width: 80%;
    height: 38px;
    color: rgb(114, 111, 111);
    background: #fff;
    line-height: 38px;
    border-radius: 6px;
}
.timepicker-clock-layout{
    width: 90% !important;
    height: 90%;
}
.arrange-timepicker-component-size{
    margin-left: 8%;
    margin-top: 24%;
}
.select-hour-invisible-1{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 53%;
    top: 40%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.select-hour-invisible-2{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 60%;
    top: 46%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(60deg);
}

.select-hour-invisible-3{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 62%;
    top: 55%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-4{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 40px;
    height: 60px;
    left: 60%;
    top: 63.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-65deg);
}

.select-hour-invisible-5{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 54%;
    top: 71.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-6{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 45%;
    top: 73.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-7{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 36.5%;
    top: 70%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-8{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 30.5%;
    top: 63%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-9{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 28.5%;
    top: 54.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-10{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 29.5%;
    top: 45%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-11{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 36%;
    top: 38.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-00{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 45%;
    top: 36%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}


.select-hour-invisible-12{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 42%;
    top: 20%;
    z-index: 1;
    border-radius: 30px;
    /* transform: rotate(-40deg); */
}

.select-hour-invisible-13{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 58%;
    top: 25.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.select-hour-invisible-14{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 69.5%;
    top: 40.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(70deg);
}

.select-hour-invisible-15{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 71.5%;
    top: 54.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-16{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 68.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(120deg);
}

.select-hour-invisible-17{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 57.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(152deg);
}

.select-hour-invisible-18{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 42.5%;
    top: 80.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(180deg);
}

.select-hour-invisible-19{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 27.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(220deg);
}

.select-hour-invisible-20{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 17.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(235deg);
}

.select-hour-invisible-21{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 13%;
    top: 53.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-22{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 16%;
    top: 38.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(110deg);
}

.select-hour-invisible-23{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 26%;
    top: 25.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(140deg);
}



@media only screen and (max-width: 477px){
    .all-hiden-hour{
        margin-top:25px;
    }
    .select-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 55%;
        top: 38%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 61.5%;
        top: 44.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .select-hour-invisible-3{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 63%;
        top: 53%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 61%;
        top: 61.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .select-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 54.5%;
        top: 68%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 46%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 37.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 31%;
        top: 61.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 29%;
        top: 52.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 30.5%;
        top: 43.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 38%;
        top: 37.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 35px;
        height: 35px;
        left: 46.5%;
        top: 35.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    .select-hour-invisible-12{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 22.5%;
        z-index: 1;
        border-radius: 30px;
        /* transform: rotate(-40deg); */
    }
    
    .select-hour-invisible-13{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 59.5%;
        top: 27%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-14{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 71%;
        top: 37.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(70deg);
    }
    
    .select-hour-invisible-15{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 74.5%;
        top: 51.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-16{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 68.5%;
        top: 64%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(120deg);
    }
    
    .select-hour-invisible-17{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 57.5%;
        top: 74.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(132deg);
    }
    
    .select-hour-invisible-18{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 42.5%;
        top: 78.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(180deg);
    }
    
    .select-hour-invisible-19{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 70px;
        left: 27%;
        top: 73.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(220deg);
    }
    
    .select-hour-invisible-20{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 70px;
        left: 16%;
        top: 64.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(235deg);
    }
    
    .select-hour-invisible-21{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 11%;
        top: 49%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-22{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 60px;
        left: 16%;
        top: 34.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(110deg);
    }
    
    .select-hour-invisible-23{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 60px;
        height: 60px;
        left: 28%;
        top: 24.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(140deg);
    }
}
@media only screen and (max-width: 477px){
    .arrange-timepicker-component-size{
        margin-left: 10%;
        margin-top: 10%;
        /* width:10% !important */
    }
}

@media only screen and (max-width: 376px){
    .all-hiden-hour{
        margin-top:50px;
        margin-left: 30% !important;
    }
    .select-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 54%;
        top: 41%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 60%;
        top: 47%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .select-hour-invisible-3{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 62%;
        top: 54%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 40px;
        height: 60px;
        left: 60%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .select-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 55%;
        top: 67.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 34.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 24%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 45%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 35%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 38.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
}