.select-hour-invisible-1{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 54%;
    top: 42%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.select-hour-invisible-2{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 60%;
    top: 48%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(60deg);
}

.select-hour-invisible-3{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 60px;
    left: 62%;
    top: 56%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-4{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 40px;
    height: 60px;
    left: 60%;
    top: 63.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-65deg);
}

.select-hour-invisible-5{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 55%;
    top: 70.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-6{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 46%;
    top: 72.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-7{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 36.5%;
    top: 70%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-8{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 30.5%;
    top: 63.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-9{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 28.5%;
    top: 55.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-10{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 30.5%;
    top: 47%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-11{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 37%;
    top: 41.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}

.select-hour-invisible-00{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 50px;
    height: 50px;
    left: 46%;
    top: 39.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(-40deg);
}


.select-hour-invisible-12{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 42%;
    top: 25%;
    z-index: 1;
    border-radius: 30px;
    /* transform: rotate(-40deg); */
}

.select-hour-invisible-13{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 90px;
    height: 90px;
    left: 58%;
    top: 29.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(30deg);
}

.select-hour-invisible-14{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 69.5%;
    top: 40.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(70deg);
}

.select-hour-invisible-15{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 71.5%;
    top: 54.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-16{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 68.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(120deg);
}

.select-hour-invisible-17{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 90px;
    left: 57.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(152deg);
}

.select-hour-invisible-18{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 42.5%;
    top: 80.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(180deg);
}

.select-hour-invisible-19{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 27.5%;
    top: 77.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(220deg);
}

.select-hour-invisible-20{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 17.5%;
    top: 67.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(235deg);
}

.select-hour-invisible-21{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 13%;
    top: 53.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(90deg);
}

.select-hour-invisible-22{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 16%;
    top: 38.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(110deg);
}

.select-hour-invisible-23{
    background-color: transparent;
    position: absolute;
    /* background-color: transparent; */
    width: 80px;
    height: 85px;
    left: 27%;
    top: 29.5%;
    z-index: 1;
    border-radius: 30px;
    transform: rotate(140deg);
}

@media only screen and (max-width: 576px){
    .arrange-all-hiden-hour{
        margin-top:20px;
    }
    .select-hour-invisible-12{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 80px;
        left: 42%;
        top: 27%;
        z-index: 1;
        border-radius: 30px;
        /* transform: rotate(-40deg); */
    }
    
    .select-hour-invisible-13{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 80px;
        left: 59%;
        top: 30.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-14{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 90px;
        left: 70.5%;
        top: 40.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(70deg);
    }
    
    .select-hour-invisible-15{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 90px;
        left: 75.5%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-16{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 90px;
        left: 68.5%;
        top: 66%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(120deg);
    }
    
    .select-hour-invisible-17{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 90px;
        left: 57.5%;
        top: 76.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(152deg);
    }
    
    .select-hour-invisible-18{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 42.5%;
        top: 80.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(180deg);
    }
    
    .select-hour-invisible-19{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 27.5%;
        top: 77.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(220deg);
    }
    
    .select-hour-invisible-20{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 15.5%;
        top: 67.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(235deg);
    }
    
    .select-hour-invisible-21{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 11%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-22{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 14%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(110deg);
    }
    
    .select-hour-invisible-23{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 80px;
        height: 85px;
        left: 25%;
        top: 29.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(140deg);
    }
}

@media only screen and (max-width: 490px){
    .arrange-all-hiden-hour{
        margin-top:25px;
    }
    .select-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 54%;
        top: 41%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 60%;
        top: 47%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .select-hour-invisible-3{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 62%;
        top: 54%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 40px;
        height: 60px;
        left: 60%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .select-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 55%;
        top: 67.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 34.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 24%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 45%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 35%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 38.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    .select-hour-invisible-12{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 42%;
        top: 26%;
        z-index: 1;
        border-radius: 30px;
        /* transform: rotate(-40deg); */
    }
    
    .select-hour-invisible-13{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 59%;
        top: 29.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-14{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 71.5%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(70deg);
    }
    
    .select-hour-invisible-15{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 75.5%;
        top: 52.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-16{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 70.5%;
        top: 65%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(120deg);
    }
    
    .select-hour-invisible-17{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 59.5%;
        top: 74.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(132deg);
    }
    
    .select-hour-invisible-18{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 42.5%;
        top: 78.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(180deg);
    }
    
    .select-hour-invisible-19{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 24.5%;
        top: 74.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(220deg);
    }
    
    .select-hour-invisible-20{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 12%;
        top: 65.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(235deg);
    }
    
    .select-hour-invisible-21{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 6%;
        top: 52%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-22{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 11%;
        top: 38.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(110deg);
    }
    
    .select-hour-invisible-23{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 70px;
        height: 70px;
        left: 24%;
        top: 28.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(140deg);
    }
}

@media only screen and (max-width: 376px){
    .arrange-all-hiden-hour{
        margin-top:50px;
        margin-left: 30% !important;
    }
    .select-hour-invisible-1{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 54%;
        top: 41%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(30deg);
    }
    
    .select-hour-invisible-2{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 60%;
        top: 47%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(60deg);
    }
    
    .select-hour-invisible-3{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 60px;
        left: 62%;
        top: 54%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(90deg);
    }
    
    .select-hour-invisible-4{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 40px;
        height: 60px;
        left: 60%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-75deg);
    }
    
    .select-hour-invisible-5{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 55%;
        top: 67.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-6{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 69.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-7{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 34.5%;
        top: 67%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-8{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 60.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-9{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 24%;
        top: 53.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-10{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 27.5%;
        top: 45%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-11{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 35%;
        top: 39.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
    
    .select-hour-invisible-00{
        background-color: transparent;
        position: absolute;
        /* background-color: transparent; */
        width: 50px;
        height: 50px;
        left: 44%;
        top: 38.5%;
        z-index: 1;
        border-radius: 30px;
        transform: rotate(-40deg);
    }
}