.reservation-times-box{
    width: 10%;
    height: 30px;
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
}
.reservation-times-modal-size{
    z-index: 1 !important;
    width: 30px;
}
.reservation-qr-box{
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -155%;
    margin-left: -50%;
    height: 400px!important;
    width: 450%;
}
.reservation-qr-code-title{
    position: relative;
    font-size: 20px;
    width: 100%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 3%;
}

.reservation-qr-image-box{
    width: 80%;
    margin: auto;
    margin-top: -10px;
}
.reservation-qr-image-size{
    width: 100%;
}
.reservation-qr-code-subtitle{
    position: relative;
    font-size: 14px;
    width: 100%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 3%;
    text-align: center;
}

@media only screen and (max-width: 1200px){
    .reservation-qr-box{
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        margin-top: -155%;
        margin-left: -161%;
        height: 400px!important;
        width: 450%;
    }
}

@media only screen and (max-width: 500px){
    .reservation-qr-box{
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        margin-top: -155%;
        margin-left: -101%;
        height: 330px!important;
        width: 330%;
    }
    .reservation-qr-code-title{
        position: relative;
        font-size: 15px;
        width: 100%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 1%;
    }

    .reservation-qr-image-box{
        width: 90%;
        margin: auto;
        margin-top: 0px;
    }
    .reservation-qr-code-subtitle{
        font-size: 12px;
    }
    .reservation-times-box{
        right: 3%;
        z-index: 1;
    }
    .reservation-times-modal-size{
        width: 25px;
    }
}