.post-memory-volume_range{
    position: absolute;
    z-index: 99999999999999 !important;
    background-color: #3F1E75;
    top: 0%;
    left: 0%;
    width: 100%;
}

.memory-full-username-top-layout{
    position: absolute;
    top: 4%;
    left:40%;
    color: #575578; 
}

.full-memory-tag-icon{
    width: 80%;
    margin: auto;
}

.full-memory-tags-btn{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 30px;
    height: 30px;
    position: absolute;
    text-align: center;
    line-height: 27px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    left: 5.5%;
    top: 10%;
    z-index: 9;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.memory-buddies-top-index{
    z-index: 99999!important;
}

/* memory loading */
.memory-full-section-disappear{
    display: none;
}
.memory-full-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.memory-full-lottie-dissappear{
    display: none;
}
.memory-full-lottie-position{
    margin-left:-30% !important
}
/* end memory loading */

/* loading */
.memory-section-disappear{
    display: none; 
}
.memory-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.memory-lottie-dissappear{
    display: none;
}
/* end loading */

.text-blue-10{
    color: #575578; 
}
.memory-full-username-text{
    color: #575578; 
}
.post-memory-hub-name{
    color: #575578; 
    margin: auto;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 2%;
}
.empty-camera-image{
    margin: auto !important;
}
.empty-camera-blocked{
    width: 50% !important;
    margin-left: -5%;
    margin-top:-5%;
}
.text-blue-empty-camera{
    color: #575578;
    font-size: 170% !important; 
}
/* Body left css start */
.body-left-10{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.logo-10{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic-10{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text-10 strong, p{
    color: #fff;
}
.text-white-10{
    color: #fff;
}
.left-text-10{
    font-size: 15px;
}
.left-icon-10 img{
    position: absolute;
    width: 100%!important;
}
.left-home-10{
    cursor: pointer;
}
.left-home-10:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.body-right-17{
    /* margin-left: 16.67%;
    background: #fff;
    padding-top: 20px; */
}
.big-img img{
    width: 63%;
    height: 680px;
}
.camera-section-10 {
    position: absolute;
    background-color: red;
    /* url(./svg/Blur\ BG-3.svg) no-repeat; */
    /* background-position: center;
    background-size: cover; */
    /* filter: blur(8px); */
    /* -webkit-filter: blur(2px); */
    top: 10%;
    left: 10%;
    /* width: 100%;
    height: 70px;
    margin: auto;
    border-radius: 22px;
    opacity: 0.7;
    backdrop-filter: grayscale(0.5) opacity(0.8);
    display: block; */
    z-index: 1111100000111;
}
.camera-section{
    opacity: 0.7;
    backdrop-filter: grayscale(0.5) opacity(0.8);
}
.cancle-btn-camera {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 0%;
    cursor: pointer !important;
    z-index: 99999999999;
}
.times-close-camera{
    margin-top: 10px;
    width:60%;
}
.gallery-icon{
    margin-left: -310%;
}

.change-camera-icon{
    margin-left: 300%;
    /* margin-top: -100px; */
}
.capture-icon img{
    width: 80%;
}
.capture-icon {
    margin-top: -45px !important;
    margin-left: 50% !important;
    background-color: #000;
    width: 80px;
    height: 80px;
    margin: auto;
    line-height: 74px;
    border-radius: 100px;
    transform: translateX(-69%);
}
.cancle-btn-photo-snapped {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 30px;
    left: 0%;
    cursor: pointer !important;
    z-index: 99999999999;
}


.choose-shop-section{
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 12px;
    position: relative;
    top: -170px;
}
.all-logo-10 img{
    cursor: pointer;
    width: 122%;
}
.next-10 img {
    width: 90%;
    margin-top: 20px;
}
.logo-icon-10 {
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    border-radius: 50%;
    margin: auto;
    text-align: center;
}
.change-bg{
    background: rgb(77,51,168);
    background: linear-gradient(0deg, rgba(77,51,168,1) 19%, rgba(141,132,246,1) 100%);
}
.all-logo-10 img {
    cursor: pointer;
    width: 98%;
}
.col-xl-2.logo-icon-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 14.666667% !important;
}
.starbucks-logo img {
    width: 90% !important;
    padding-top: 4px;
    padding-bottom: 4px;
}
.choose-shop-section{
    display: none;
}
.choose-section-10 {
    position: relative;
    top: -270px;
    width: 60%;
    margin: auto;
    border-radius: 22px;
    backdrop-filter: grayscale(0.5) opacity(0.8);
    /* display: block; */
    background-color: #fff;
}
.last-img-show {
    width: 50%;
    margin: auto;
    background-color: #fff;
    padding-top: 20px;
    border-radius: 12px;
}
/* buddy's image */
.memory-page-background{
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: rgb(117,116,163);
    background: linear-gradient(90deg, rgba(117,116,163,1) 0%, rgba(235,195,203,1) 100%);
}
.memory-buddys-memory-box{
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 730px !important;
    width: 500px;
    margin-top: 1%;
    margin-left:42%;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}
.memory-buddys-memory-layout img{
    margin-top: 3%;
    margin-left: -0.5%; 
    width: 450px;
    height: 600px;
    border-radius: 30px 30px 0px 0px;
    /* border: solid 1px rgb(170, 168, 168); */
    object-fit: cover!important;
}
.memory-buddys-cancel-around{
    /* border: solid 1px rgb(170, 168, 168); */
    width: 40px !important; 
    height: 40px !important;
    line-height: 35px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer !important;
    z-index: 99999999999;
}
.memory-buddys-times-icon {
    width:40% !important;
}
.all-memory-footer{
    width: 100%;
    height: 10%;
    margin-left: 0px;
    z-index: 9999999 !important;
}


.memory-reaction-mika-animation{
    position: absolute;
    top: 30%;
    z-index: 9999999 !important;
    left:25%;
    width:50%
}
.memory-mika-animation-size{
    width: 100%;
}
.elementToFadeInAndOut {
    -webkit-animation: fadeinout 1s linear forwards;
    animation: fadeinout 1s linear forwards;
    opacity: 0;
}

@-webkit-keyframes fadeinout {
  50% { opacity: 1; }
}

@keyframes fadeinout {
  50% { opacity: 1; }
}
.memory-buddys-profile-picture{
    /* position: relative; */
    float: left;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover!important;
    z-index: 9999999 !important;
}




.memory-full-username-layout{
    position: relative;
    top: 18px !important;
    margin-left: -40px;
}
.memory-buddys-hub-logo{
    float: left;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 1%;
    margin-left: 2% !important;
    object-fit: cover!important;
    z-index: 2 !important;
}
.memory-all-reactions{
    position: absolute;
    width: 70%;
    /* height: 20%; */
    margin-left: 30%;
    top: 91%;
}
.memory-mika-reaction{
    border-radius: 20%;
    width: 40px;
    height: 40px;
    margin-right: 20px;
}
.my-memory-reactions{
    position: absolute;
    width: 50%;
    bottom: 1%;
    z-index: 999999;
    left: 25%;
    margin: auto;
    /* margin: auto; */
    /* margin-top: 2%; */
}
.my-memory-reactions-count{
    color: #ffffff;
    font-size: 20px!important;
    text-align: center;
    width: 60%;
    margin-left: 20%;
    z-index: 999999;
}
.memory-mika-all-impression-img{
    width: 50% !important;
    margin-left: 13%;
    margin-top: -2%;
}
.memory-mika-impression-img{
    width: 30px !important;
    margin: auto !important;
}
.memory-mika-sellected-reaction{
    border-radius: 20%;
    width: 30px;
    height: 30px;
    /* float: right;
    margin-top: 7px;
    margin-right: 10px; */
    position: relative;
    bottom:-10px;
    left:-80px;
    z-index: 99999999;
    /* margin-left: 25% !important; */
}
.memory-buddys-hour-icon{
    width: auto;
    margin-left: -10px;
}
.memory-buddys-username-hour-layout{
    margin-top: -15px;
    margin-left: 25%;
}
.memory-buddys-hour-text{
    margin-left: 15%;
}
/* end  buddy's image*/

.click-capture-10 img{
    width: 50%;
}
.click-capture-logo-10 img{
    width: 60%;
}
.click-text-10 p{
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 15px;
}
.last-img-show{
    display: none;
}
.body-right-17{
    padding-bottom: 20px;
}

.big-img-hide{
    display: block !important;
}
.big-img-1{
    display: none !important;
}
.memories-photo-size{
    margin-left: 11%;
}

.camera-layout{
    position:absolute;
    left: 0%;
    top: 0%;
    /* width: 120px !important; */
    /* height: 120px !important; */
}
.post-memories-buttons-layout{
    position: absolute;
    left: 48.5%;
    top: 98%;
    z-index: 9999999;
}
.camera-bottom-blur-left{
    /* position: absolute; */
    width: 140%!important;
    margin-top: -28%;
    margin-left: -140%;
    opacity: 70%;
}
.camera-bottom-blur-right{
    /* position: absolute; */
    width: 140%!important;
    margin-top: -47%;
    margin-left: 25%;
    opacity: 70%;
}
.icon-10{
    /* margin-left: 28%; */
    position: absolute;
    width: 60% !important;
    cursor: pointer;
    margin-top: -100% !important;
}
/* post memory-newwwwww */
.post-whole-page-backround{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgb(235, 219, 219);
}
.snapped-photo-size{
    width: 380px;
    height: 500px !important;
    /* margin: auto !important; */
    margin-left: 20px !important;
    margin-top:-10px !important;
    border-radius: 10px;
    /* border: 1px solid rgb(187, 183, 183); */
    object-fit: scale-down!important;
    /* -moz-box-shadow: 20 20 25px #fff;
    -webkit-box-shadow: 20 20 25px #fff; */
    /* box-shadow: inset 0px 0px 50px rgba(0,0,0,0.9);
    -webkit-filter: drop-shadow(-1px -1px 20px rgb(218, 214, 214)); */
}
.snapped-photo-size-cover{   
    display: none; 
}


.post-memory-out-layout{
    margin: auto;
    background: #fbfbf8;
    width:420px;
    height: 600px;
    margin-top: 10px;
    -webkit-box-shadow: 20px 24px 28px -25px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 20px -25px rgba(45, 6, 104, 0.3);
    /* border: 1px solid rgb(187, 183, 183); */
    
}
.times-close-snapped-photo{
    position: absolute;
    margin-top: 35px;
    margin-left: 10px;
    width:10px;
    z-index: 999999 !important;
}
.post-hubs-next-btn{
    font-weight: bold !important;
    font-size: 20px !important;
    color: rgb(74, 74, 75)!important;
    cursor: pointer;
    float: right;
    z-index: 9;
    width: 12%;
    margin-top: 28px;
    /* background: red; */
}
/* modal */
.post-hubs-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 600px !important;
    margin-top: -255%;
    margin-left: -420%;
    width: 950%;
}
.post-hubs-title{
    position: relative;
    font-size: 30px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 0%;
}
.modal-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.modal-times-close-icon{
    margin-top: 6px;
    width:60%;
}
.post-modal-close-icon{
    margin-top: 6px;
    width:60%;
}
.post-hubs-slider-layout{
    margin-top: 7%;
    width: 100%;
    margin-left: -0%;
}
.post-hubs-search{
    margin-top: 1%;
    border: none;
    width: 40%;
}
.post-hubs-search input{
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}
.post-memory-hub-modal-frame {
    width: 250px;
    height: 250px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.post-memory-hub-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}
.post-memory-hub-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}

.post-hubs-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 8%;
    color: white!important;
    cursor: pointer;
    float: right;
}
/* end modal */



@media only screen and (max-width: 1430px){
    .camera-section-10 {
        position: relative;
        background: url(./svg/Blur\ BG-3.svg) no-repeat;
        background-position: center;
        background-size: cover;
        /* filter: blur(8px); */
        /* -webkit-filter: blur(2px); */
        top: -120px;
        width: 60%;
        height: 70px;
        margin: auto;
        border-radius: 22px;
        opacity: 0.7;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        display: block;
    }
    .cancle-btn {
        width: 40px;
        height: 40px;
        line-height: 35px;
        border-radius: 50%;
        background-color: #fff;
        text-align: center;
        position: absolute;
        top: 40px;
        left: 75%;
        cursor: pointer !important;
    }
    .choose-section-10 {
        position: relative;
        top: -283px;
        width: 60%;
        margin: auto;
        border-radius: 22px;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        background-color: #fff;
    }

    .camera-bottom-blur-left{
        width: 140%!important;
        margin-top: -32%;
        margin-left: -160%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 140%!important;
        margin-top: -44%;
        margin-left: 35%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -360%;
    }
    
    .change-camera-icon{
        margin-left: 330%;
    }
}
@media only screen and (max-width: 1382px){
    .camera-section-10 {
        position: relative;
        background: url(./svg/Blur\ BG-3.svg) no-repeat;
        background-position: center;
        background-size: cover;
        /* filter: blur(8px); */
        /* -webkit-filter: blur(2px); */
        top: -138px;
        width: 60%;
        height: 70px;
        margin: auto;
        border-radius: 22px;
        opacity: 0.7;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        display: block;
    }
}
@media only screen and (max-width: 1282px){
    .camera-section-10 {
        position: relative;
        background: url(./svg/Blur\ BG-3.svg) no-repeat;
        background-position: center;
        background-size: cover;
        top: -160px;
        width: 60%;
        height: 70px;
        margin: auto;
        border-radius: 22px;
        opacity: 0.7;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        display: block;
    }

    .choose-section-10 {
        position: relative;
        top: -310px;
        width: 60%;
        margin: auto;
        border-radius: 22px;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        background-color: #fff;
    }
    .body-right-17{
        padding-bottom: 140px;
    }
}
@media only screen and (max-width: 1200px){
    /* memory loading */
    .memory-full-lottie-position{
        margin-left:0% !important
    }
    /* end memory loading */
    .body-left-10{
        display: none;
    }
    .body-right-17{
        margin-left: initial;
    }
    .big-img img {
        width: 100%;
        height: 100%;
    }
    .col-xl-10.body-right-17.text-center {
        padding: 0 !important;
        height: 100vh !important;
    }
    /* .hide-section-10 {
        height: 100vh;
    } */
    .camera-section-10 {
        position: relative;
        background: url(./svg/Blur\ BG-3.svg) no-repeat;
        background-position: center;
        background-size: cover;
        top: -100px;
        width: 95%;
        height: 70px;
        margin: auto;
        border-radius: 22px;
        opacity: 0.7;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        display: block;
    }
    .starbucks-logo1{
        display: none;
    }
    .choose-section-10 {
        position: relative;
        top: -310px;
        width: 95%;
        margin: auto;
        border-radius: 22px;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        background-color: #fff;
    }
    .last-img-show {
        width: 100%;
    }
    .click-capture-10 img {
        width: 35%;
    }
    .click-capture-logo-10 img {
        width: 41%;
    }
    .click-text-10 p {
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 30px;
    }
    .camera-bottom-blur-left{
        width: 120%!important;
        margin-top: -28%;
        margin-left: -140%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 120%!important;
        margin-top: -38%;
        margin-left: 35%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -360%;
    }
    .change-camera-icon{
        margin-left: 330%;
    }
    .icon-10{
        width: 60% !important;
        margin-top: -90% !important;
    }
}

@media only screen and (max-width: 1025px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 800px;
        height: 1320px !important;
        margin-top: 2.5%;
        margin-left:11%;
    }
    .memory-buddys-memory-layout img{
        margin-top: 4%;
        margin-left: -0%;
        width: 732px;
        height: 1100px;
    }
    .memory-buddys-cancel-around{
        top: 10px;
        right: 10px;
    }
    .memory-buddys-username-hour-layout{
        margin-top: -30px;
        margin-left: 30%;
    }
    .memory-buddys-memory-username{
        font-size: 40px!important;
    }
    .memory-buddys-hour-icon{
        width: 25%;
        margin-left: -20px;
        margin-top: -15px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 30px;
    }
    /* end  buddy's image*/
    .post-memory-out-layout{
        /* margin: initial; */
        position: absolute;
        top:3%;
        left:5%;
        width:90%;
        height: 94% !important;
        margin-top: 0px;
        /* border: 2px solid rgb(187, 183, 183); */
    }
    
    .snapped-photo-size{
        position: relative;
        width: 85%;
        height: 900px !important;
        left: 7.5%;
        top:2.5%;
        margin: auto !important;
        border-radius: 10px;
        /* border: 2px solid rgb(187, 183, 183); */
    }
    .times-close-snapped-photo{
        position: absolute;
        bottom:1%;
        margin-left: 10px;
        width:25px;
    }
    .post-hubs-next-btn{
        position: absolute;
        bottom:1%;
        right: 0%;
        font-weight: bold !important;
        font-size: 30px !important;
        color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 12%;
        margin-top: 28px;
        /* background: red; */
    }

    /* .all-memory-footer{
        position: relative;
        width: 100%;
        height: 10%;
        margin-left: 0px;
        top: 90%;
    } */
    .memory-buddys-profile-picture{
        /* position: relative; */
        float: left;
        margin-left: 2%;
        margin-top: 4%;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        object-fit: cover!important;
        z-index: 9999999 !important;
    }
    .memory-full-username-layout{
        font-size: 30px;
        position: relative;
        top: 35% !important;
        /* margin: auto; */
    }
    .memory-buddys-hub-logo{
        width: 90px;
        height: 90px;
        margin-top: 4%;
        /* margin-right: 2% !important; */
        object-fit: cover!important;
    }
}

@media only screen and (max-width: 992px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        margin-left:7%;
    }
    /* end buddy's image */
    .click-text-10 p {
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 18px;
    }
    .choose-section-10 {
        position: relative;
        top: -290px;
        width: 95%;
        margin: auto;
        border-radius: 22px;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        background-color: #fff;
    }
    .camera-bottom-blur-left{
        width: 100%!important;
        margin-top: -24%;
        margin-left: -108%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 90%!important;
        margin-top: -35%;
        margin-left: 23%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -250%!important;
    }
    .change-camera-icon{
        margin-left: 200% !important;
    }
    .icon-10{
        width: 50% !important;
        margin-top: -78% !important;
    }

    /* modal */
    .post-hubs-modal-box {
        height: 500px !important;
        margin-top: -245%;
        margin-left: -260%;
        width: 650%;
    }

    .post-memory-hub-modal-frame {
        width: 200px;
        height: 200px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
}

@media only screen and (max-width: 900px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 660px;
        height: 1100px !important;
        margin-top: 10%;
        margin-left:11%;
    }
    .memory-buddys-memory-layout img{
        margin-top: 4%;
        margin-left: -0%;
        width: 600px;
        height: 900px;
    }

    .memory-buddys-username-hour-layout{
        margin-top: -20px;
        margin-left: 20%;
    }
    /* end  buddy's image*/
}

@media only screen and (max-width: 768px){
    .snapped-photo-size{
        width: 85%;
        height: 730px !important;
    }
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 640px !important;
        height: 1000px !important;
        margin-top: 1.5%;
        margin-left:8%;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -0%;
        width: 580px !important;
        height: 870px !important;
    }
    .memory-buddys-cancel-around{
        /* top: 30px;
        left: 87%; */
    }
    .memory-buddys-username-hour-layout{
        margin-top: -10px;
        margin-left: 25%;
    }
    .memory-buddys-memory-username{
        font-size: 30px!important;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: -20px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 20px;
    }
    /* end buddy's image */
    .click-text-10 p {
        font-size: 17px;
        margin-bottom: 5px;
        margin-top: 8px;
    }
    .choose-section-10 {
        position: relative;
        top: -265px;
        width: 95%;
        margin: auto;
        border-radius: 22px;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        background-color: #fff;
    }
    .big-img{
        /* display: none !important; */
        height: 100%;
    }
    .big-img-hide{
        display: none !important;
    }
    .big-img-1{
        display: block !important;
        height: 100%;
    }

    .last-img-show {
        width: 100%;
        margin: auto;
        background-color: #fff;
        padding-top: 20px;
        border-radius: 0px;
    }
    .memory-buddys-memory-layout img {
        width: 75%;
    }
    .last-img-show {
        width: 100%;
        margin: auto;
        background-color: rgb(255, 255, 255);
        padding-top: 20px;
        border-radius: 0px;
        height: 100% !important;
    }
}
@media only screen and (max-width: 720px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 540px !important;
        height: 900px !important;
        margin-top: 1.5%;
        margin-left:9%;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -1%;
        width: 500px !important;
        height: 750px !important;
    }
    /* end buddy's image */
    .camera-bottom-blur-left{
        width: 75%!important;
        margin-top: -20%;
        margin-left: -88%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 75%!important;
        margin-top: -33%;
        margin-left: 23%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -210%!important;
    }
    .change-camera-icon{
        margin-left: 180% !important;
    }
    .icon-10{
        width: 40% !important;
        margin-top: -75% !important;
    }
    .times-close-snapped-photo{
        position: absolute;
        bottom:1%;
        margin-left: 10px;
        width:22px;
    }
    .post-hubs-next-btn{
        position: absolute;
        bottom:1%;
        right: 0%;
        font-weight: bold !important;
        font-size: 30px !important;
        color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 14%;
        margin-top: 28px;
        /* background: red; */
    }
}
@media only screen and (max-width:610px){
    .snapped-photo-size{
        width: 85%;
        height: 530px !important;
    }
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 540px !important;
        height: 900px !important;
        margin-top: 5%;
        margin-left:5%;
    }
    /* end buddy's image */

    .cancle-btn-photo-snapped {
        top: 25px;
    }
    .cancle-btn-camera {
        top: 25px;
    }
    /* modal */
    .post-hubs-modal-box {
        height: 420px !important;
        margin-top: -155%;
        margin-left: -210%;
        width: 550%;
    }
    .post-memory-hub-modal-frame {
        width: 140px;
        height: 140px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    } 
    /* end modal */
    .post-memory-hub-name{
        font-size: 16px;
        margin-top: 2%;
    }
}

@media only screen and (max-width: 576px){
    .memory-trash-can{
        position: absolute !important;
        top: 90% !important;
        left: 2% !important;
        z-index: 99999999;
    }
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 420px !important;
        height: 700px !important;
        margin-top: 1.5%;
        margin-left:11%;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -1%;
        width: 380px !important;
        height: 570px !important;
    }
    .memory-buddys-cancel-around{
        width: 30px !important; 
        height: 30px !important;
        top: 2px;
        right: 2px;
    }
    .memory-buddys-times-icon {
        width:60% !important;
        margin-top: -10px;
    }

    .memory-buddys-username-hour-layout{
        margin-top: -10px;
        margin-left: 25%;
    }
    .memory-buddys-memory-username{
        font-size: 30px!important;
        margin-left: -23%;
    }
    /* end buddy's image */
    
    .big-img{
        height: 100%;
    }
    .big-img-hide{
        display: none !important;
    }
    .big-img-1{
        display: block !important;
        height: 100%;
    }
    .camera-section-10 {
        position: relative;
        background: url(./svg/Blur\ BG-3.svg) no-repeat;
        background-position: center;
        background-size: cover;
        top: -80px;
        width: 95%;
        height: 65px;
        margin: auto;
        border-radius: 22px;
        opacity: 0.7;
        backdrop-filter: grayscale(0.5) opacity(0.8);
        display: block;
    }
    .capture-icon {
        margin-top: -32px !important;
        margin-left: 49% !important;
        background-color: #000;
        width: 65px;
        height: 65px;
        margin: auto;
        line-height: 59px;
        border-radius: 100px;
        transform: translateX(-69%);
    }
    .click-capture-10 img {
        width: 50%;
    }
    .click-capture-logo-10 img {
        width: 57%;
    }
    .click-text-10 p {
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 8px;
    }
    .hour img {
        width: 15px;
    }
    .hour span {
        font-size: 14px;
    }
    .click-text-10 p {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    .camera-bottom-blur-left{
        width: 65%!important;
        margin-top: -15%;
        margin-left: -75%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 65%!important;
        margin-top: -30%;
        margin-left: 18%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -185%!important;
    }
    .change-camera-icon{
        margin-left: 150% !important;
    }
    .icon-10{
        width: 30% !important;
        margin-top: -75% !important;
    }
    .cancle-btn-camera {
        right: 5%;
    }
    .memory-username-position{
        font-size: 14px;
    }
    .times-close-snapped-photo{
        position: absolute;
        bottom:1%;
        margin-left: 10px;
        width:22px;
    }
    .post-hubs-next-btn{
        position: absolute;
        bottom:1%;
        right: 0%;
        font-weight: bold !important;
        font-size: 30px !important;
        color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 20%;
        margin-top: 28px;
        /* background: red; */
    }
    /* .all-memory-footer{
        position: relative;
        width: 100%;
        height: 10%;
        margin-left: 0px;
        top: 90%;
    } */
    .memory-buddys-profile-picture{
        /* position: relative; */
        float: left;
        margin-left: 2%;
        margin-top: 3%;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover!important;
        z-index: 9999999 !important;
    }
    .memory-full-username-layout{
        font-size: 20px;
        position: relative;
        top: 30% !important;
        margin-left: -200px;

        /* margin: auto; */
    }
    .memory-buddys-hub-logo{
        width: 40px;
        height: 40px;
    }
}
@media only screen and (max-width: 540px){
    /* modal */
    .post-memory-hub-modal-frame {
        width: 110px;
        height: 110px;
        line-height: 105px;
        margin-left: 0%;
    }
    .post-memory-hub-name{
        font-size: 14px;
        margin-top: 4%;
    }
    .cancle-btn-photo-snapped {
        width: 50px;
        height: 50px;
        top: 20px;
        left: 3%;
    }
    /* modal */
    .post-hubs-modal-box {
        height: 380px !important;
        margin-top: -115%;
        margin-left: -160%;
        width: 450%;
    }
    .post-hubs-title{
        font-size: 20px;
    }
    .post-hubs-search{
        margin-top: 1%;
        border: none;
        width: 40%;
    }
    .post-hubs-search input{
        border-radius: 9px;
        border-radius: 20px 20px 20px 20px !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    }
    .post-hubs-btn-danger {
        margin-top: 14%;
    }
    /* end modal */
}

@media only screen and (max-width: 490px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 420px !important;
        height: 700px !important;
        margin-top: 0%;
        margin-left:7%;
    }
    .icon-10 img{
        width: 100% !important;
    }
}

@media only screen and (max-width: 460px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 420px !important;
        height: 700px !important;
        margin-top: 0%;
        margin-left:4%;
    }
    /* modal */
    .post-hubs-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 330px !important;
        margin-top: -115%;
        margin-left: -140%;
        width: 410%;
    }
    .post-hubs-search{
        margin-top: 1%;
        border: none;
        width: 60%;
    }
    .post-memory-hub-modal-frame {
        width: 110px;
        height: 110px;
    }  
    .post-hubs-slider-layout{
        margin-top: 5%;
        margin-bottom: 5%;
        width: 110%;
        margin-left: -5%;
    }
    .post-hubs-btn-danger {
        padding: 8px 30px !important;
        font-size: 15px !important;
        margin-top: 2% !important;
    }
    /* end modal   */
    .camera-bottom-blur-left{
        width: 65%!important;
        margin-top: -18%;
        margin-left: -75%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 65%!important;
        margin-top: -35%;
        margin-left: 18%;
        opacity: 70%;
    }
}


@media only screen and (max-width: 450px){
    .memory-full-username-top-layout{
        position: absolute;
        top: 18px;
        left:75px;
        font-size:20px;
        color: #ffffff;
        z-index: 9999999999 !important; 
    }
    .memory-full-username-text{
        color: #ffffff; 
        /* background: linear-gradient(353deg, rgb(247, 245, 246) 31%, rgb(248, 249, 250) 91%); */
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 3px;
    }
    .times-close-snapped-photo{
        position: absolute;

        background: linear-gradient(353deg, rgb(247, 245, 246) 31%, rgb(248, 249, 250) 91%);
        padding-left: 2%;
        padding-right: 4%;
        padding-bottom: 1.5%;
        padding-top: 1.5%;
        bottom:0% !important;
        left: -3% !important;
        width:10%;
        border-radius: 0px 15px 0px 0px;
    }
    .post-hubs-next-btn{
        position: absolute;
        bottom:0%;
        right: 0%;
        font-weight: bold !important;
        font-size: 22px !important;
        color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 20%;
        margin-top: 28px;
        background: linear-gradient(353deg, rgb(247, 245, 246) 31%, rgb(248, 249, 250) 91%);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 15px 0px 0px 0px;
    }
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 390px !important;
        height: 680px !important;
        margin-top: 5%;
        margin-left:2.5%;
    }
    .memory-username-position{
        font-size: 13px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -3%;
        width: 360px !important;
        height: 540px !important;
    }
    .memory-buddys-cancel-around{
        width: 30px !important; 
        height: 30px !important;
        top: 6px;
        right: 8px;
    }
    .memory-buddys-times-icon {
        width:60% !important;
        margin-top: -10px;
    }
    /* end buddy's image */
    .memory-buddies-top-index{
        z-index: 99999!important;
    }
    .camera-bottom-blur-left{
        width: 65%!important;
        margin-top: -18%;
        margin-left: -75%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 65%!important;
        margin-top: -40%;
        margin-left: 18%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -185%!important;
    }
    .change-camera-icon{
        margin-left: 150% !important;
    }
    .icon-10{
        width: 30% !important;
        margin-top: -85% !important;
    }
    .cancle-btn-photo-snapped {
        top: 15px;
        left: 3%;

    }
    /* .times-close-snapped-photo{
        margin-top: 10px;
        width:60%;
    } */
    .cancle-btn-camera {
        top: 15px;
        right: 5%;
    }

    .post-memory-out-layout{
        /* margin: initial; */
        position: absolute;
        top:0%;
        left:0%;
        width:100%;
        height: 100% !important;
        margin-top: 0px;
        /* border: 2px solid rgb(187, 183, 183); */
    }
    .snapped-photo-size{
        position: absolute;
        width: 100%;
        height:100% !important;
        left: 0%;
        top:0%;
        margin: auto !important;
        border-radius: 0px;
        /* border: 0px solid rgb(187, 183, 183); */
        z-index: 1;
    }
    .snapped-photo-size-cover{   
        display: block; 
        position: absolute;
        width: 100%;
        height:100% !important;
        left: 0%;
        top:0%;
        background-color: black;
    }
    .times-close-snapped-photo{
        position: absolute;
        bottom:1%;
        margin-left: 10px;
        /* width:25px; */
    }
    .post-hubs-next-btn{
        /* position: absolute;
        bottom:1%;
        right: 0%;
        font-weight: bold !important; */
        font-size: 25px !important;
        /* color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 20%;
        margin-top: 38px; */
        /* background: red; */
    }
    /* .all-memory-footer{
        position: relative;
        width: 100%;
        height: 13%;
        margin-left: 0px;
        top: 88%;
    } */
    .memory-buddys-profile-picture{
        position: absolute;
        float: left;
        top: 3px;
        left: 2%;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover!important;
        /* border: 1px solid rgb(255, 255, 255); */
        z-index: 9999999 !important;
    }
    .memory-full-username-layout{
        font-size: 18px;
        position: relative;
        /* top: 25% !important; */
        /* margin: auto; */
    }
    .memory-buddys-hub-logo{
        position: absolute;
        /* float: right; */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        bottom:3.5%;
        right: 1%;
        margin-right: 2% !important;
        object-fit: cover!important;
        /* border: 1px solid rgb(255, 255, 255); */
    }
    .memory-trash-can{
        position: absolute;
        top: 90% !important;
        left: 2% !important;
        z-index: 99999999;
    }
}

@media only screen and (max-width: 400px){
    .memory-full-username-layout{
        margin-left: -170px;

        /* margin: auto; */
    }

    /* buddy's image */
    .memory-username-position{
        font-size: 11px;
        margin-left: -35%;
    }
    .memory-buddys-memory-box{ 
        width: 360px !important;
        height: 610px !important;
        margin-top: 8%;
        margin-left:3%;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -1.5%;
        width: 320px !important;
        height: 480px !important;
    }
    .memory-buddys-username-hour-layout{
        margin-top: -10px;
        margin-left: 25%;
    }
    .memory-buddys-memory-username{
        font-size: 30px!important;
        margin-left: -23%;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: 0px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 20px;
    }
    /* end buddy's image */

    /* modal */
    .post-hubs-btn-danger {
        margin-top: 3% !important;
    }
    .modal-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .modal-times-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
    .post-modal-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
}

@media only screen and (max-width: 376px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 364px !important;
        height: 620px !important;
        margin-top: 5%;
        margin-left:1%;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -1.5%;
        width: 330px !important;
        height: 495px !important;
    }
    .memory-buddys-times-icon {
        width:40% !important;
        margin-top: -9px;
    }
    .memory-buddys-username-hour-layout{
        margin-top: 0px;
        margin-left: 25%;
    }
    .memory-buddys-memory-username{
        font-size: 20px!important;
        margin-left: 0%;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: 0px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end buddy's image */

    .click-text-10 p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    .click-text-10 p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 3px;
    }
    .click-capture-10 img {
        width: 60%;
    }
    .click-capture-logo-10 img {
        width: 70%;
    }
    .camera-bottom-blur-left{
        width: 70%!important;
        margin-top: -24%;
        margin-left: -80%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 70%!important;
        margin-top: -48%;
        margin-left: 18%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -195%!important;
    }
    .change-camera-icon{
        margin-left: 150% !important;
    }
    .icon-10{
        width: 30% !important;
        margin-top: -105% !important;
    }
}

@media only screen and (max-width: 374px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 340px !important;
        height: 620px !important;
        margin-top: 2.5%;
        margin-left:2.5%;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -5%;
        width: 320px !important;
        height: 480px !important;
    }
    .memory-buddys-times-icon {
        width:40% !important;
        margin-top: -9px;
    }
    .memory-buddys-username-hour-layout{
        margin-top: 0px;
        margin-left: 10%;
    }
    .memory-buddys-memory-username{
        font-size: 20px!important;
        margin-left: 0%;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: 0px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end buddy's image */
    .memory-mika-reaction{
        border-radius: 20%;
        width: 35px;
        height: 35px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 355px){
    .memory-full-username-layout{
        margin-left: -140px;

        /* margin: auto; */
    }
    /* modal */
    .post-hubs-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 320px !important;
        margin-top: -115%;
        margin-left: -95%;
        width: 320%;
    }
    .post-hubs-title{
        position: relative;
        font-size: 15px;
        width: 50%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 0%;
    }
    .post-memory-hub-modal-frame {
        width: 70px;
        height: 70px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 0%;
    }
    .post-memory-hub-name{
        font-size: 10px;
        margin-top: 4%;
    }
    /* end modal */
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 320px !important;
        height: 580px !important;
        margin-top: 2.5%;
        margin-left:3%;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -5%;
        width: 300px !important;
        height: 450px !important;
    }
    .memory-buddys-times-icon {
        width:40% !important;
        margin-top: -9px;
    }

    .memory-buddys-username-hour-layout{
        margin-top: 0px;
        margin-left: 10%;
    }
    .memory-buddys-memory-username{
        font-size: 20px!important;
        margin-left: 0%;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: 0px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end buddy's image */
}

@media only screen and (max-width: 336px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 300px !important;
        height: 550px !important;
        margin-top: 3%;
        margin-left:3%;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -6%;
        width: 280px !important;
        height: 420px !important;
    }
    .memory-buddys-times-icon {
        width:40% !important;
        margin-top: -9px;
    }
    .memory-buddys-username-hour-layout{
        margin-top: 0px;
        margin-left: 10%;
    }
    .memory-buddys-memory-username{
        font-size: 20px!important;
        margin-left: 0%;
    }
    .memory-buddys-hour-icon{
        width: 20%;
        margin-left: 0px;
        margin-top: -5px;
    }
    .memory-buddys-hour-text{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end buddy's image */
    .times-close-snapped-photo{
        position: absolute;
        bottom:1%;
        margin-left: 10px;
        width:15px;
    }
    .post-hubs-next-btn{
        position: absolute;
        bottom:0%;
        right: 0%;
        font-weight: bold !important;
        font-size: 20px !important;
        color: rgb(74, 74, 75)!important;
        cursor: pointer;
        float: right;
        z-index: 9;
        width: 20%;
        margin-top: 28px;
        /* background: red; */
    }
    .post-hubs-btn-danger {
        margin-top: 13% !important;
    }
}

@media only screen and (max-width: 325px){
    .camera-bottom-blur-left{
        width: 70%!important;
        margin-top: -24%;
        margin-left: -85%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 70%!important;
        margin-top: -51%;
        margin-left: 23%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -215%!important;
    }
    .change-camera-icon{
        margin-left: 160% !important;
    }
    .icon-10{
        width: 30% !important;
        margin-top: -120% !important;
    }
    .cancle-btn-photo-snapped {
        width: 40px;
        height: 40px;
        top: 20px;
        left: 3%;
    }
    .times-close-snapped-photo{
        margin-top: 0px;
        /* width:60%; */
    }
    .cancle-btn-camera {
        width: 40px;
        height: 40px;
        top: 20px;
        right: 5%;
    }
    .times-close-camera{
        margin-top: 0px;
        width:60%;
    }
    .memory-username-position{
        font-size: 10px;
        margin-left: -35%;
    }
}
 

@media only screen and (max-width: 315px){
    /* buddy's image */
    .memory-buddys-memory-box{ 
        width: 274px !important;
        height: 550px !important;
        margin-top: 10%;
        margin-left:3px;
        border-radius: 30px;
    }
    .memory-buddys-memory-layout img{
        margin-top: 1%;
        margin-left: -8%;
        width: 260px !important;
        height: 420px !important;
    }
    /* end buddy's image */
}

@media only screen and (max-width: 300px){
    .camera-bottom-blur-left{
        width: 70%!important;
        margin-top: -27%;
        margin-left: -80%;
        opacity: 70%;
    }
    .camera-bottom-blur-right{
        width: 70%!important;
        margin-top: -60%;
        margin-left: 18%;
        opacity: 70%;
    }
    .gallery-icon{
        margin-left: -205%!important;
    }
    .change-camera-icon{
        margin-left: 150% !important;
    }
    .icon-10{
        width: 30% !important;
        margin-top: -140% !important;
    }
}
