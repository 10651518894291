.wifi-modal-box {
    position: absolute;
    padding: 10px 15px !important;
    border-radius: 50px;
    background: #fff; 
    height: 150px !important;
    margin-top: -30%;
    margin-left: -135%;
    width: 400%;
}
.wifi-modal-hub-name{
    font-size: 20px;
    color: #3F1E75;
}

.wifi-modal-hub-wifi{
    font-size: 18px;
    color: #3F1E75;
}
.wifi-modal-hub-wifi-position{
    margin-top: 10%;
}
.wifi-modal-times-frame {
    width: 30px;
    height: 30px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 6%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.wifi-modal-close-icon{
    margin-top: -12px;
    width:60%;
}

@media only screen and (max-width: 422px){
    .wifi-modal-box {
        height: 120px !important;
        margin-top: -0%;
        margin-left: -85%;
        width: 300%;
    }
    .wifi-modal-hub-name{
        font-size: 14px;
    }
    
    .wifi-modal-hub-wifi{
        font-size: 12px;
    }
    .wifi-modal-hub-wifi-position{
        margin-top: 5%;
    }
    .wifi-modal-times-frame {
        width: 25px;
        height: 25px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 6%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .wifi-modal-close-icon{
        margin-top: -18px;
        width:60%;
    }
}