.memory-share-reservation-remove-tag-text{
    /* color: #7e7b83; */
    position: absolute;
    bottom: 5%;
    left: 15%;
}


.memory-share-reservation-remove-yes{
    background-color: #06E00F !important;
    color: white !important;
    cursor: pointer;
    border: none !important;
    font-weight: bold !important;
    font-size: 16px;
    border-radius: 30px !important;
    width: 15% !important;
    color: #ffff !important;
    text-align: center;
    padding: 5px 10px;
    margin-left: 30px;
    position: absolute;
    bottom: 4%;
    left: 38%;
}

.memory-share-reservation-remove-no{
    background-color: #CC0919 !important;
    color: white !important;
    cursor: pointer;
    margin-left: 4%;
    border: none !important;
    font-weight: bold !important;
    font-size: 16px;
    border-radius: 30px !important;
    width: 15% !important;
    color: #ffff !important;
    text-align: center;
    padding: 5px 10px;
    margin-left: 30px;
    position: absolute;
    bottom: 4%;
    left: 56%;
}

.add-tag-back-icon{
    width: 20px;
    position: absolute;
    bottom: 5%;
    left: 5%;
}
.add-tag-back-img{
    width: 100%;
}
.share-search-reservation-search{
    margin-top: 3%;
    border: none;
    width: 60%;
    /* margin-left: -20%; */
}
.share-search-reservation-search input{
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}

.memory-share-reservation-add-slider-title{
    position: relative;
    font-size: 18px !important;
    margin-top: 17%;
    color: #3F1E75;
    z-index: 3 !important;
    text-align: left;
}

.memory-share-reservation-add-slider{
    width:70%;
    height: 15%;
    /* background-color: red; */
    position: absolute;
    top: 55%;
    left: 5%;
}
.memory-share-reservation-add-tag-btn{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: white!important;
    cursor: pointer;
    /* margin: auto; */
    width: 80%;
    text-align: center;
}

.memory-share-reservation-add-tag-btn:active {
    transform: scale(0.88);
}

.memory-share-reservation-add-tag-btn:focus {
    transform: scale(0.88);
}

.memory-share-reservation-add-tag-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.memory-share-reservation-added-tag-btn{
    /* background: rgb(200, 200, 200);
    background: linear-gradient(0deg, rgb(248, 248, 248) 0%, rgb(241, 241, 241) 100%); */
    color:  #575578 !important;    
    border: none !important;
    padding: 8px 20px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    width: 80%;
}

.memory-share-reservation-add-buddy-frame{
    width: 80px;
    height: 80px;
    /* background-color: tomato; */
}

.memory-share-reservation-add-buddy{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: auto;
}
.memory-share-reservation-add-buddy-username{
    color: #3F1E75;
    text-align: center;
}

.memory-share-reservation-action-icon{
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5.7%;
    left: 5%;
}

.memory-share-reservation-action-img{
    width: 100% !important;
}

.memory-share-reservation-action-text{
    color: #7e7b83;
    position: absolute;
    bottom: 5%;
    left: 15%;
}



.memory-share-reservation-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 580px !important;
    margin-top: -245%;
    margin-left: -160%;
    width: 450%;
    z-index: 9999999999999999999 !important;
}

.memory-share-reservation-cancle-btn {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    border:double 1px rgb(179, 171, 209);
}
.memory-share-reservation-close-icon{
    margin-top: 6px;
    width:60%;
}
.memory-share-reservation-title-box{
    width: 50% !important;
    margin-left: 3%;
}
.memory-share-reservation-title{
    position: relative;
    font-size: 20px !important;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
}
.memory-share-reservation-datepicker{
    margin: auto;
    /* width: 65%; */
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
    margin-left: 20px;
}
.memory-share-reservation-slider{
    margin-top: 3%;
    height: 85% !important;
}
.memory-share-reservation-item{
    width: 100%;
}
.memory-share-reservation-single-item{
    width: 80%!important;
    background: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto !important;
}

.memory-share-reservation-user-info{
    width: 20%;
}
.memory-share-reservation-user-picture{
    border-radius: 50%!important;
    width: 60px!important;
    height: 60px!important;
    object-fit: cover;
}

.memory-share-reservation-info{
    width: 60%;
}
.memory-share-reservation-info-layout{
    margin-top: 20px;
}
.memory-share-reservation-text{
    color: #575578;
    font-size: 24px !important;
    margin-top: -20px;
}
.memory-share-reservation-small-text{
    color: #575578;
    font-size: 16px !important;
    margin-top: -20px;
}

.memory-share-reservation-btn{
    width: 20%;
}
.memory-share-reservation-btn-sellect {
    /* position: absolute; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}

.memory-share-reservation-btn-sellect:active {
    transform: scale(0.88);
}

.memory-share-reservation-btn-sellect:focus {
    transform: scale(0.88);
}

.memory-share-reservation-btn-sellect:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.memory-share-reservation-btn-sellected {
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    background: #fff;
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-top: 20%;
}
@media only screen and (max-width: 600px){
    .memory-share-reservation-action-icon{
        margin-top: 20px;
        margin-left: 20px;
    }
    .memory-share-reservation-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px 0px 0px 50px;
        background: #fff; 
        height: 520px !important;
        margin-top: -215%;
        margin-left: -20%;
        width: 420%;
        z-index: 99999999999999;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    }
    .memory-share-reservation-title-box{
        width: 70% !important;
        margin-left: 12%;
    }
    .memory-share-reservation-title{
        font-size: 15px !important;
    }
    .memory-share-reservation-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 22px;
        top: 0px;
        left: 3%;
    }
    .memory-share-reservation-close-icon{
        margin-top: 0px;
        width:60%;
    }
    .memory-share-reservation-single-item{
        width: 100%!important;
        padding: 5px 2px;
        border-radius: 0px;
    }
    
    .memory-share-reservation-user-info{
        width: 20%;
    }
    .memory-share-reservation-user-picture{
        width: 40px!important;
        height: 40px!important;
    }
    
    .memory-share-reservation-info-layout{
        margin-top: 10px;
        height: 100%;
        width: 100%;
    }
    .memory-share-reservation-text{
        color: #575578;
        font-size: 16px !important;
        margin-top: -15px !important;
        font-weight: bold;
        margin-left: -10px;
    }
    .memory-share-reservation-small-text{
        color: #575578;
        font-size: 12px !important;
        margin-top: -15px !important;
        margin-left: -10px;
        margin-bottom: -10px;
    }

    .memory-share-reservation-btn{
        width: 20%;
    }
    .memory-share-reservation-btn-sellect {
        /* position: absolute; */
        padding: 4px 9px !important;
        border-radius: 30px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        cursor: pointer;
        color: white !important;
        text-align: center;
        width: 100%;
        margin-top: 30%;
    }
    .memory-share-reservation-btn-sellected {
        /* position: absolute; */
        padding: 4px 9px !important;
        font-size: 11px !important;
        width: 100%;
        margin-top: 30%;
    }
}

@media only screen and (max-width: 540px){
    .memory-share-reservation-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        border:double 1px rgb(179, 171, 209);
    }
    .memory-share-reservation-close-icon{
        margin-top: -10px;
        width:60%;
    }
}

@media only screen and (max-width: 500px){
    .memory-share-reservation-modal-box {
        margin-left: -75%;
        width: 420%;
        margin-top: -255%;   
    }
}

@media only screen and (max-width: 400px){
    .memory-share-reservation-action-icon{
        margin-top: 22px;
        margin-left: 20px;
    }
    .memory-share-reservation-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .memory-share-reservation-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
    .memory-share-reservation-modal-box {
        margin-left: -85%;
        width: 440%;
        margin-top: -210%;
    }
    .memory-share-reservation-title-box{
        width: 60% !important;
        margin-left: 15%;
    }
}


@media only screen and (max-width: 350px){
    .memory-share-reservation-modal-box {
        margin-top: -240% !important;  
    }
}




.share-reservation-modal-box {
    position: absolute;
    padding: 10px 15px !important;
    border-radius: 50px;
    background: #fff; 
    height: 600px !important;
    margin-top: -255%;
    margin-left: -420%;
    width: 950%;
}
.share-reservation-title-size{
    width: 92% !important;
}
.share-reservation-title{
    position: relative;
    font-size: 25px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 0%;
}
.share-reservation-username{
    position: relative;
    font-size: 20px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 0%;
}
.share-reservation-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.share-reservation-close-icon{
    margin-top: 6px;
    width:60%;
}
.share-reservation-slider-layout{
    margin: auto !important;
    margin-top: 8% !important;
    width: 100% !important; 
    /* margin-left: 0%; */
}
.share-reservation-search{
    margin-top: 1%;
    border: none;
    width: 40%;
}
.share-reservation-search input{
    border-radius: 9px;
    border-radius: 20px 20px 20px 20px !important;
    box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
}
.share-reservation-modal-frame {
    width: 250px;
    height: 250px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.share-forever-alone-text{
    position: relative;
    font-size: 35px !important;
    width: 100%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 0%;
}
.share-forever-alone-img{
    width: 70% !important;
}
.share-reservation-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}
.share-reservation-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}

.share-reservation-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 0px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin: auto;
    /* margin-top: 8%; */
    color: white!important;
    cursor: pointer;
    line-height: 40px;
    width: 40%;
    z-index: 99999999999 !important;
    margin-top: 20%;
    margin-left: 66%;
}

.share-reservation-btn-light {
    background: #fff;
    color: rgb(99, 98, 98) !important;
    border: none !important;
    padding: 0px 10px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin: auto;
    line-height: 40px;
    width: 40%;
    z-index: 99999999999 !important;
    margin-top: 20%;
    margin-left: 66%;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}

.share-reservation-times-frame {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}



@media only screen and (max-width: 992px){
    .share-reservation-modal-box {
        height: 550px !important;
        margin-top: -245%;
        margin-left: -260%;
        width: 650%;
    }

    .share-reservation-modal-frame {
        width: 200px;
        height: 200px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    .share-reservation-title{
        position: relative;
        font-size: 22px;
        margin-bottom: -8%;
    }
    .share-reservation-username{
        font-size: 15px;
    }

    .share-reservation-btn-danger {
        padding: 0px 10px !important;
        font-size: 14px !important;
        width: 60%;
        margin-top: 30%;
        margin-left: 65%;
    }
    .share-reservation-btn-light {
        padding: 0px 10px !important;
        font-size: 14px !important;
        width: 60%;
        margin-top: 30%;
        margin-left: 65%;
    }
}


@media only screen and (max-width:610px){
    .share-reservation-modal-box {
        height: 470px !important;
        margin-top: -155%;
        margin-left: -210%;
        width: 550%;
    }
    .share-reservation-modal-frame {
        width: 160px;
        height: 160px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    } 
    .share-reservation-title-size{
        width: 85% !important;
    }
    .share-reservation-username{
        font-size: 13px;
    }
}

@media only screen and (max-width: 540px){
    .share-reservation-modal-frame {
        width: 120px;
        height: 120px;
        line-height: 105px;
        margin-left: 10%;
    }
    .cancle-btn-photo-snapped {
        width: 50px;
        height: 50px;
        top: 20px;
        left: 3%;
    }

    .share-reservation-modal-box {
        height: 410px !important;
        margin-top: -115%;
        margin-left: -160%;
        width: 450%;
    }
    .share-reservation-title{
        font-size: 18px;
    }
    .share-reservation-search{
        margin-top: 1%;
        border: none;
        width: 40%;
    }
    .share-reservation-search input{
        border-radius: 9px;
        border-radius: 20px 20px 20px 20px !important;
        box-shadow: 0px 0px 24px 10px rgba(45, 6, 104, 0.3);
    }
    .share-reservation-btn-danger {
        padding: 0px 10px !important;
        font-size: 14px !important;
        width: 60%;
        margin-top: 30%;
        margin-left: 58%;
    }
    .share-reservation-btn-light {
        padding: 0px 10px !important;
        font-size: 14px !important;
        width: 60%;
        margin-top: 30%;
        margin-left: 58%;
    }
    .share-reservation-username{
        font-size: 13px;
    }
}


@media only screen and (max-width: 460px){
    .share-reservation-modal-box {
        position: absolute;
        padding: 10px 15px !important;
        border-radius: 50px;
        background: #fff; 
        height: 390px !important;
        margin-top: -175%;
        margin-left: -140%;
        width: 410%;
    }
    .share-reservation-search{
        margin-top: 1%;
        border: none;
        width: 60%;
    }
    .share-reservation-modal-frame {
        width: 110px;
        height: 110px;
    }  
    .share-reservation-username{
        font-size: 11px;
    }
    
}



@media only screen and (max-width: 400px){
    .share-reservation-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .share-reservation-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
    .share-reservation-times-frame {
        width: 30px;
        height: 30px;
    }
}


@media only screen and (max-width: 355px){
    .share-reservation-modal-box {
        position: absolute;
        border-radius: 50px;
        background: #fff; 
        height: 370px !important;
        margin-top: -175%;
        margin-left: -95%;
        width: 320%;
    }
    
    .share-reservation-title{
        position: relative;
        font-size: 12px;
        width: 50%;
        line-height: 12px !important;
        margin-bottom: -8%;
        margin-left: 0%;
    }
    .share-reservation-modal-frame {
        width: 100px;
        height: 100px;
        background: #fff;
        margin: auto;
        line-height: 125px;
        border-radius: 12px;
        margin-left: 14%;
    } 
    .share-reservation-username{
        font-size: 10px;
    }
}