.pending-appointments-all-appointments-layout{
    margin-top: 180px;
}

.hub-reservation-reservation-name-top{
    position:absolute;
    top: 10px;
    left:0%;
    width: 100%;
    height: 30px;
    /* background-color: red; */
    text-align: left;
    font-weight: bold;
    color: #232231;
    border-bottom: 1px solid black;
    font-size: 20px;
}

.hub-booked-page-position{
    position: relative;
    margin-top: 130px;
    /* top: 50%; */
}

.pending-hub_reservation-section-title{
    color: #575578;
    font-size: 20px !important;
    font-weight: bold;
    text-align: center; 
    position: absolute;
    width: 100%;
    top:-55px !important;
}



.pending-hub_reservation_gdpr-links{
    position: relative;
    margin-top: 100%;
    width: 100%;
    height: 10%;
}

.back-button-hub-mobile-traffic-box{
    position: absolute;
    z-index: 99999999;
    width: 23px;
    height: 23px;
    line-height: 0px;
    top: 14px;
}
.hub-mobile-traffic-back-button{
    width: 100%;
    height: 100%;
    margin-top: 0%;
    z-index: 99999999;
}

.pending-hub_reservation_empty-image{
    width: 100%;
    margin: auto;
    margin-top: 0%;
}

.bokked_reservation_empty-image{
    width: 70%;
    margin: auto;
    margin-top: 0%;
}

.pending-hub_reservation_empty-icon{
    width: 100px;
    height: 100px;
    margin: auto;
}

.pending-hub_reservationempty-created{
    width: 50%;
}

.text-blue-empty-pending-hub{
    color: #575578;
    font-weight: bold;
    font-size: 20px !important;
}

.text-small-empty-pending-hub{
    color: #575578;
    font-weight: normal;
    font-size: 16px !important;
    width: 60%;
    margin-left: 20%;
}

.pending-hub_reservation-note-box{
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
    /* background-color: red; */
}

.pending-hub_reservation-message-title{
    color: #57558E !important;
    font-weight: bold;
}
.pending-hub_reservation-message{
    color: #57558E !important;
}

.pending-hub_reservation-buttons{
    width:20%;
    height: 50px;
    position: absolute;
    right: 0%;
    bottom: 65%!important;
}

.pending-hub_reservation-btn-not-attended{
    border-radius: 30px;
    padding: 3px 20px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10%;
}

.pending-hub_reservation-btn-cancelled{
    padding: 3px 20px;
    font-size: 16px;
    margin-top: 10%;
    background: #fff;
    color: #d60c27!important;
    font-weight: bold;
    /* border: solid 0.5px rgb(204, 204, 204); */
    border-radius: 4px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 10px -5px rgba(45, 6, 104, 0.3);
}

.pending-hub_reservation-btn-accept{
    border-radius: 30px;
    padding: 3px 20px;
    background: #0cd638;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    /* width: 100% !important; */
}

.pending-hub_reservation-btn-decline{
    border-radius: 30px;
    padding: 3px 20px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10%;
    /* width: 100% !important; */
}

.pending-hub_reservation-btn-answerd{
    border-radius: 30px;
    padding: 3px 20px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10%;
    background: #fff;
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}

.pending-hub_reservation-meeting-status{
    width: 20%;
    height: 20%;
    position: absolute;
    top: 5%;
    right: 0%;
}

.pending-hub_reservation-meeting-requested{
    color: #8fe98f;
    font-size: 18px;
    font-weight: bold;
}

.pending-hub_reservation-meeting-approved{
    color: #228B22;
    font-size: 18px;
    font-weight: bold;
}

.pending-hub_reservation-meeting-cancelled{
    color: #DC143C;
    font-size: 18px;
    font-weight: bold;
}


.pending-hub_reservation-meeting-info{
    width: 140%;
    height: 100%;
    margin-top: 0px;
    color: #575578;
    text-align: left;
    font-size: 18px;
    margin-bottom:30px;
}

.pending-hub_reservation-meeting-data{
    width: 100%;
    height: 100%;
    color: #575578;
    text-align: left;
    font-size: 18px;
    position: absolute;
    top: 0%;
    left: 40%;
}

.pending-hub-reservation-creator-name-color {
    color: #57558E !important;
}

.pending-hub-reservation-m-t {
    margin-top: 10px !important;
    width: 80px;
    height: 80px;
    margin-top: 80px;
    border-radius: 50%;
}

.pending-hub-reservation-item-of-table{
    background-color: #fff !important;
    padding: 25px 18px 0px;
    padding-right: 20px;
    border-radius: 20px;
    width: 120%;
    margin-left: -10% !important;
    border: solid 0.5px rgb(161, 160, 160);
    /* margin-right: 20px !important; */
}

.pending-hub-reservation-item-col{
    max-width: 80% !important;

}

.booked-reservations-qr-scanner{
    display: none;
}

.booked-reservation-all-top-buttons{
    width:100%;
}
.booked-reservation-top-button{
    width: 30%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
.new-booked-text-blue-empty{
    color: #575578;
    font-size: 170% !important; 
}
.new-booked-empty-mika{
    width: 50% !important;
    margin-left: -5%;
    margin-top:-5%;
}
.new-booked-empty-image{
    margin: auto !important;
}
.new-booked-btn1-7 {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(0deg,  rgb(68, 169, 223) 51%, rgba(50,135,187,1) 89%);
    border-color: rgba(50,135,187,1) !important;
}
.new-booked-btn2-7 {
    color: #fff;
    background: rgb(134,122,239);
    background: linear-gradient(0deg, rgba(134,122,239,1) 12%, rgba(92,69,185,1) 73%);
    border-color: #5c45b9 !important;
}
.new-booked-btn3-7 {
    color: #fff;
    background: rgb(221,58,151);
    background: linear-gradient(0deg, rgba(221,58,151,1) 0%, rgba(205,9,92,1) 100%);
    border-color: #AA2E7F !important;
}
.new-booked-btn-light{
    color:#3F1E75;
    background: #fff;
}

.new-booked-button-7 {
    padding: 10px 25px !important;
    width: 100%;
    font-weight: bold !important;
    border-radius: 30px !important;
    cursor: pointer;
}

.new-booked-reservations-all-tables{
    width: 90%;
    margin: auto;
}
.new-booked-reservations-tables-size{
    width: 33%;
}
.new-booked-reservations-tables-border{
    background-color: #FFFfFf;
    width: 90%;
    height: 85%;
    border-radius: 50px;
    /* padding-top: 10px; */
}
.new-booked-reservations-table{
    margin: auto;
}
.new-booked-reservations-table-number{
    margin-top: 30%;
    text-align: center;
    color:#3F1E75;
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
    padding-top: 30px;
}
.new-booked-reservations-table-reservation{
    color:#3F1E75;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    text-align: center;
}
.new-booked-reservations-persons-for{
    color:#3F1E75;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

}
.new-booked-reservations-creator-image{
    border-radius: 50%!important;
    height: 30px!important;
    width: 30px!important;
}
.new-booked-round-button-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}

.new-booked-round-buttons{
    /* margin: 36px; */
    width: 85px;
    height: 85px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    margin-bottom: 23px;
}

.new-booked-tables-icon{
    width: 50px;
    height:50px;
    margin:auto;
    margin-top: -60px;
}
.new-booked-reservations-table-counter{
    position: absolute;
    margin-top: -50px !important;
}
.new-booked-total-persons-position{
    margin-top: 50px;
} 

@media only screen and (max-width: 1200px){
    .pending-hub_reservation_empty-image{
        margin-top: 40%;
    }
    .pending-hub-reservation-d-b {
        display: block;
    }
    .pending-hub-reservation-item-col{
        max-width: 80% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .booked-reservations-qr-scanner{
        display: block;
    }
    .booked-reservations-qr-scanner{
        position: absolute;
        right: 5%;
        top: 50px;
    }
    .booked-reservations-qr-scanner-img{
        width:80px;
    }
    .new-booked-reservations-all-tables{
        width: 85%;
        margin: auto;
        margin-left: 10%;
    }
}
@media only screen and (max-width: 992px){
    .new-booked-total-persons-position{
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .pending-hub_reservation-meeting-info{
        font-size: 16px;
        margin-bottom:30px;
    }
    
    .pending-hub_reservation-meeting-data{
        font-size: 16px;
        top: 0%;
        left: 50%;
    }
}
@media only screen and (max-width: 992px){
    .new-booked-reservations-table-counter{
        margin-left: -15px;
    }
    .booked-reservations-qr-scanner-img{
        width:60px;
    }
}

@media only screen and (max-width: 769px){
    .pending-hub-reservation-m-t {
        margin-top: 10px !important;
        width: 60px;
        height: 60px;
    }
}


@media only screen and (max-width: 576px){
    .pending-hub_reservation-meeting-info{
        font-size: 14px;
        margin-bottom:30px;
    }
    
    .pending-hub_reservation-meeting-data{
        font-size: 14px;
        top: 0%;
        left: 60%;
    }
    .pending-hub-reservation-item-col{
        max-width: 90% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .hub-pending-section-title-box{
        position: relative;
        margin-top: -20%;
    }
    
    .new-booked-reservations-tables-size{
        width: 50%;
    }
    .booked-reservations-qr-scanner{
        position: absolute;
        right: 5%;
        top: 30px;
    }
    .booked-reservations-qr-scanner-img{
        width:30px;
    }
}

@media only screen and (max-width: 492px){
    .pending-hub_reservationempty-created{
        width: 100%;
    }
    .pending-hub_reservation-note-box{
        width: 100%;
        height: 100%;
        margin-top: -20px;
    }
    .pending-hub_reservation-meeting-status{
        width: 80%;
        height: 20%;
        top: 1%;
    }
    .pending-hub_reservation-buttons{
        height: 30px;
        width: 100%;
        position: absolute;
        bottom: 7% !important;
    }

    .pending-hub_reservation-btn-not-attended{
        margin-top: 0%;
        width: 80% !important;
        position: absolute;
        left: 10%;
    }

    .pending-hub_reservation-btn-cancelled{
        margin-top: -10%;
        width: 80% !important;
        position: absolute;
        left: 10%; 
    }
    
    .pending-hub_reservation-btn-accept{
        width: 40% !important;
        position: absolute;
        left: 5%;
    }

    
    .pending-hub_reservation-btn-decline{
        margin-top: 0%;
        width: 40% !important;
        position: absolute;
        left: 55%;
    }

    .pending-hub_reservation-btn-answerd{
        margin-top: 0%;
        width: 60% !important;
        position: absolute;
        left: 20%;
    }
}

@media only screen and (max-width: 435px){
    .pending-hub-reservation-item-of-table{
        padding: 25px 18px 0px;
        padding-right: 20px;
        border-radius: 5px;
        width: 120%;
        margin-left: -10% !important;
        border: solid 0.5px rgb(161, 160, 160);
        /* margin-right: 20px !important; */
    }

    .pending-hub_reservation-meeting-info{
        width: 100%;
        font-size: 12px;
        margin-bottom:30px;
    }

    .pending-hub_reservation-meeting-data{
        width: 70%;
        font-size: 12px;
        top: 0%;
        left: 75%;
    }
    .pending-hub-reservation-m-t {
        margin-top: -5px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px;
    }
    .pending-hub-reservation-d-b {
        display: block;
        font-size: 13px;
        margin-left: -18px;
    }
}

@media only screen and (max-width: 420px){
    .hub-pending-section-title-box{
        margin-top: -25%;
    }
    .booked-attending-arrow-circles{
        width: 40px !important;
        height: 40px !important;
    }
    .attending-sellect-box{
        margin-left: 0px !important;
    }
    .new-booked-round-buttons{
        width: 65px;
        height: 65px;
        line-height: 64px;
    }
    .new-booked-tables-icon{
        width: 40px;
        height:40px;
        margin:auto;
    }
}


@media only screen and (max-width: 400px){
    .pending-hub-reservation-m-t {
        margin-top: -5px !important;
        margin-left: -20px;
        width: 60px;
        height: 60px;
    }
    .pending-hub-reservation-d-b {
        display: block;
        font-size: 11px;
    }
    .pending-hub_reservation-meeting-info{
        width: 100%;
        font-size: 11px;
        margin-bottom:30px;
    }

    .pending-hub_reservation-meeting-data{
        width: 70%;
        font-size: 11px;
        top: 0%;
        left: 78%;
    }
}

@media only screen and (max-width: 365px){
    .pending-hub_reservation-buttons{
        /* height: 50px; */
        position: absolute;
        width: 100%;
        bottom: 8.5% !important;
        /* top: 65% !important; */
    }
    .pending-hub-reservation-m-t {
        width: 36px !important;
        height: 36px !important;
        margin-top: 20px !important;
        margin-left:0px;
    }
    .pending-hub-reservation-d-b {
        margin-top: 3px;
    }
    .pending-hub_reservation-meeting-info{
        width: 100%;
        font-size: 10px;
        margin-bottom:30px;
    }
    
    .pending-hub_reservation-meeting-data{
        width: 80%;
        font-size: 10px;
        top: 0%;
        left: 72%;
    }
    .pending-hub_reservation-meeting-status{
        width: 90%;
    }
}

@media only screen and (max-width: 330px){
    .pending-hub_reservation-btn-not-attended{
        font-size: 14px !important;
    }
    .pending-hub_reservation-btn-cancelled{
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 316px){
    .pending-hub-reservation-m-t {
        margin-top: 0px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px !important;
    }
}






/* loading */
.pending-reservations-section-disappear{
    display: none;
}
.pending-reservations-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.pending-reservations-lottie-dissappear{
    display: none;
}
/* end loading */

.text-blue{ 
    color: rgb(87, 85, 120);
}
.hub-container-fluid{
    max-width: 100% !important;
}
.body-right{
    max-width: 100% !important;
}
/* Global CSS end */

/* Body left css start */

.hub-body-left{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.hub-logo{
    padding: 30px 40px;
    margin-top: 30px;
}
.hub-text-white{
    color: #fff;
}
.hub-left-text{
    font-size: 15px;
}
.hub-left-icon img{
    width: 45%;
    margin-top: 5px;
}
.hub-left-home{
    cursor: pointer;
}
.hub-left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body right logo section css start */
.booked-attending-persons-box{
    /* background: red; */
    width: 100%!important;
    height: 100%!important;
    margin-top: 9% !important;
}
.attending-sellect-box{
    margin: auto;
    margin-bottom: 10%;
    width: 20% !important;
    height: 10px !important;
    background-color: #fff;
    padding: 20px;
    box-sizing: content-box;
    border-radius: 12px;
    border: 0px solid #aaa8a8;
    -webkit-box-shadow: 0px 4px 8px 1px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 5px 14px 1px rgba(45, 6, 104, 0.3);
    margin-left: 5px;
    margin-top: 60px;
}
.attending-sellect-persons-number{
    font-size: 30px !important;
    color: #575578 !important;
    text-align: center;
    margin-top: -20px !important;
    margin-left: -10px;
}
.booked-attending-arrow-circles{
    width: 50px;
    height: 50px;
    position: relative;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto;
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    margin-bottom: 10px !important;
    margin-top: 10px;
}
.booked-attending-arrows{
    margin-top: 35%;
}
.booked-reservations-search-box{
    /* position: absolute; */
    /* z-index: 999999999999999999999999999999 !important; */
    margin: auto !important;
    width: 40%;
}

.reservations-search-top{
    border: none;
    /* z-index: 999999 !important; */

}
.reservations-search-top input{
    margin-top: 6px;
    border-radius: 9px; 
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    /* z-index: 999999 !important; */
}
.reservations-input-icon-search{
    position: absolute;
    width: 2%;
    margin-left: 10px;
    margin-top: 8px;
    /* z-index: 9999999; */
}
.reservations-search-forpending-hub-reservation-m-text{
    text-align: center;
}

.reservation-icon-cancel{
    width: 40px;
    height:40px;
    margin:auto;
    margin-top: -65px;
}

.hub-pending-back-icon{
    position: absolute;
    left:5%;
}

.hub-pending-round-buttons-pressed{
    display: block;
    background-color: #cfcece;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #c9c7c7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #adaaaa, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #b9b8b8, #e7e7e7); 
    background-image: -o-linear-gradient(top, #b9b5b5, #e7e7e7); 
    color: #a7a7a7;
}

.hub-pending-round-buttons-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}

.hub-pending-round-buttons{
    /* margin: 36px; */
    width: 85px;
    height: 85px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto;
    margin-bottom: 23px;
}
.hub-body-right{
    margin-left: 16.6%;
    background-color: #fff;
    padding: 20px 0px;
}
.hub-starbucks-logo img{
    width: 37%;
}
.hub-congestion-text{
    margin-top: 1%!important;
    font-size: 24px;
}

/* Body right logo section css end */

/* Body right box css start */
.hub-small-header-container{
    display: none;
}
.approved-reservations-save-button{
    font-size: 16px;
    font-weight: bold;
    width: 10%;
    padding:5px 10px;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 14px;
    margin: auto;
    text-align: center;
    margin-top: -30px;
    cursor: pointer;
    /* margin-bottom: 0px; */
}
.pending-hub-box-item{
    background-color: #fff;
    padding: 20px;
    box-sizing: content-box;
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
}

.hub-number {
    margin: 10px auto;
    width: 75px;
    height: 38px;
    color: rgb(114, 111, 111);
    background: #fff;
    line-height: 38px;
    border-radius: 6px;
}
.hub-reservation-activity-text{
    color: rgb(114, 111, 111);
    /* background: #fff; */
}

.hub-booked-day-box{
    position: relative;
    width: 50%;
    height: 10%;
    background: #fff;
    margin: auto;
    margin-top: 20px;
    /* line-height: 125px; */
    color: rgb(59, 59, 59) !important;
    text-align: center;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 5px 35px;
    cursor: pointer;
    border: solid 0.5px rgb(204, 204, 204);
}

.hub-today-box-icon{
    width: 55%;
}
.hub-reservations-table-number{
    color: grey;
}
.hub-small-text{
    display: none;
}
.hub-metrics-text{
    color: #575578 !important
}
.booked-reservation-name-position{
    margin-top:40px!important
}
.booked-reservation-name{
    color: #575578 !important;
}
.hub-congestion-text.text-center.text-blue {
    margin-top: -20px;
}
.hub-today-box {
    /* background-color: rgb(188, 175, 245); */
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    padding: 10px 120px 10px 10px !important;
    width: 15%;
    margin: auto;
    margin-top: 5%;
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
}
.hub-today-box strong{
    font-size: 15px !important;
    margin-top: -20px;
    margin-left: 100%;
}
.hub-first-row {
    width: 85%;
    padding: 10px 15px;
    background: #AA2E7F;
    color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
}
.reservations-date-day-position{
    /* margin-left: -80%; */
    float: left;
}
.span{
    float: right;
    font-weight: bold;
    border-radius: 10px;
}
.hub-third-row, .hub-second-row {
    width: 85%;
    padding: 10px 15px;
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 2px 18px -5px rgba(45, 6, 104, 0.3);
}
.table-persons-icons-size {
    width: 8%!important;
    margin-right: 10px;
    float: left;
}
.reservation-icons-size{
    width: 45%!important;
}
.hub-arrow {
    float: right;
    width: 13px;
    margin-top: 10px;
    margin-left: 5px;
}
.hub-item-of-table {
    background: #fff;
    padding: 20px 10px;
    width: 90%;
    border-radius: 15px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    margin: auto;
}
.hub-item-of-table p{
    display: inline-block;
    font-weight: bold;
    border-radius: 10px;
    float: right;
}
.hub-four {
    background-color: #fff;
    color: #000000;
    width: 40px;
    height: 28px;
    text-align: center;
    border-radius: 5px !important;
}



.hub-drinks{
    background-color: #fff;
    color: #000000;
    width: 40px;
    height: 28px;
    text-align: center;
    border-radius: 5px !important;
}

/* .hub-pending-round-buttons-pressed{
    display: block;
    background-color: #cfcece;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #c9c7c7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #adaaaa, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #b9b8b8, #e7e7e7); 
    background-image: -o-linear-gradient(top, #b9b5b5, #e7e7e7); 
    color: #a7a7a7;
}

.hub-pending-round-buttons-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}

.hub-pending-round-buttons{
    width: 85px;
    height: 85px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto;
    margin-bottom: 23px;
} */

.hub-group-unsellected{
    float: right; 
    color: #a7a7a7;
    margin-top: -5px;
}

.hub-group-sellected {
    float: right;
    color: rgb(87, 85, 120);
    margin-top: -5px;
}
.reservations-table-number-round-button{
    margin-left: -20%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 28px;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
}
.hub-left-img img{
    width: 100%;
}
.hub-left-img {
    margin-left: 40px;
    padding: 10px;
}
.hub-reservation-note{
    width: 95%;
    margin: auto;
}
.text-blue-note{
    margin: auto;
    color: rgb(87, 85, 120);
    text-align: center;
}
.hub-first-row-blue{
    background-color: #433BA4;
    height: 30%;
}
.hub-first-row-grey{
    background-color: #8c8a8f;
    height: 30%;
}
.hub-first-row-sky{
    background-color: #27789F;
    height: 30%;
}
.hub-first-row-red{
    height: 30%;
}
.hub-drinks-row{
    width: 100%;
    padding: 15px;
}
.hub-drinks-row p {
    padding: 5px 15px;
    box-sizing: content-box;
    margin-top: -6px;
}
.hub-arrow-down img{
    cursor: pointer;
}
.hub-drinks-box, .hub-drinks-box1, .hub-drinks-box2{
    display: none;
}
.total-arrived-persons-position{
    width: 120px;
    height: 110px;
    margin: auto;
    margin-top: -20px;
    margin-bottom: -50px;
}
.total-arrived-box-item{
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    text-align: center;
    height: 100%;
}


.all-tables-datepicker{
    margin: auto;
    /* width: 65%; */
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: rgb(87, 85, 120);
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
    margin-left: 20px;
}
.booked-tables-icon-position{
    margin-left: 10%;
    margin-top: -8%;
}

.new-reservation-icon-position{
    margin-left: 10%;
    margin-top: -80px;
}
.new-reservation-text-box{
    width: 85px !important;
    margin-top: -10px;    
}
.new-reservation-text{
    color: rgb(87, 85, 120);
    font-weight: bolder;
    font-size: 15px;
}
.booked-tables-icon{
    width: 10%;
    margin-top: -10%;
    /* float: right; */
    /* margin-left: -60% !important; */

}
.all-tables-text{
    color: rgb(87, 85, 120);
    font-weight: bolder;
    /* margin-left: 5px; */
    /* text-align: center; */
}
.hub-tables-round-button-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}

.hub-table-round-buttons{
    /* margin: 36px; */
    width: 85px;
    height: 85px;
    position: relative;
    text-align: center;
    line-height: 144px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    margin-bottom: 23px;
}
.all-reservation-tables-icon{
    width: 50px;
    height:50px;
    margin:auto;
    margin-top: -60px;
}



.hub-today-box img {
    margin-top: -10px;
}
.hub-today-box {
    padding-top: 18px !important;
}
.hub-grp {
    width: 125px;
    margin-top: -25px;
}
.hub-grp-small {
    width: 80px;
    margin-top: -25px;
}
.hub-nav-bottom{
    display: none !important;
    max-width: 100% !important;
}




/* Footer icon css start */
.hub-nav-bottom{
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.hub-footer-icon img {
    width: 22%;
}
.hub-middle-icon-footer img {
    position: absolute;
    top: -50px;
    width: 92px !important;
    left: 30px;
}
.hub-footer-width{
    width: 20% !important;
}
.hub-logo-size-big{
    border-radius: 50%;
    margin-left: 5%;
    margin-top: -20%;
    width: 120px!important;
    height: 120px!important;
    border: 10px solid #FFFAFA;
}
.creator-image-layout{
    border-radius: 50%!important;
    height: 180px!important;
    width: 180px!important;
}
.creator-image-layout-attending{
    border-radius: 50%!important;
    height: 160px!important;
    width: 160px!important;
}
.hub-logo-size-small{
    border-radius: 50%;
    width: 80px!important;
    height: 80px!important;
    border: 3px solid #FFFAFA;
}
.reservation-name-position{
    margin-left: 12% !important;
    font-size: 20px;
}
.btn-danger-booked-reservations{
    border-radius: 30px;
    padding: 12px 50px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 20px;
    width: 100% !important;
}
.booked-cancel-hub-reservation-layout{
    margin: auto;
    margin-top: 2%;
}
.booked-cancel-hub-reservation{
    border-radius: 30px;
    padding: 3px 20px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100% !important;
    margin-top: 15%;
}
.booked-done-hub-reservation{
    border-radius: 30px;
    padding: 3px 20px;
    background: #0cd638;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100% !important;
    margin-top: 15%;
}
.booked-page-time-position{
    float: right;
    display: inline;
}
.btn-success-pending-reservations{
    border-radius: 30px;
    background: #64ad6a;
    color: white;
    font-size: 20px;
    font-weight: bold;
    width: 100% !important;
    height: 95%;
}
.btn-danger-pending-reservations{
    border-radius: 30px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 20px;
    width: 100% !important;
}
.hub-box-container-pending-page {
    position: relative;
    margin: auto;
}
.pending-numbers-postition{
    margin: auto;
    margin-top: 5%;
}
/* .desktop-traffic-layout{
    margin-left: 10%;
} */


/* Footer icon css end */
/* Body right box css end */


/* media quaries start */
@media only screen and (max-width: 1353px){
    .hub-today-box {
        width: 15%;
    }

    .hub-arrow-down img {
        margin-top: 20px;
    }

}
@media only screen and (max-width: 1200px){

    .hub-mobile-traffic-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }

    .whole-hub-reservations-page-layout{
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100% !important;
    }
    .booked-reservation-name-position{
        margin-top:45px!important;
        margin-left: -28%;
        width: 160px;
    }
    .reservation-icons-size{
        width: 30%!important;
    }
    .hub-body-right{
        margin-left: 25%;
    }
    .hub-starbucks-logo img {
        width: 60%;
    }
    .hub-left-img {
        margin-left: 0px;
        margin-top: 28px;
    }
    .hub-item-of-table {
        width: 100%;
    }
    .hub-first-row {
        width: 95%;
    }
    .hub-third-row, .hub-second-row {
        width: 95%;
    }
    .pending-hub-box-item {
        padding: 20px 20px;
    }
    .hub-today-box {
        width: 20%;
    }
    .reservation-name-position{
        margin-left: 14% !important;
    }
    .hub-today-box strong{
        margin-left: 70%;
    }
    .creator-image-layout-attending{
        height: 110px!important;
        width: 110px!important;
        /* margin-left: ; */
    }
}
@media only screen and (max-width: 1050px){
    /* .new-reservation-icon-position{
        margin-left: 82%;
    } */
}

@media only screen and (max-width: 992px){
    .total-arrived-persons-position{
        margin-top: -40px;
        margin-bottom: -70px;
    }
    .new-reservation-icon-position{
        /* margin-top: -162px; */
    }
    .new-reservation-text-box{
        margin-top: -5px;
    }
    .booked-reservations-search-box{
        width: 60%;
    }
    .creator-image-layout{
        height: 110px!important;
        width: 110px!important;
        margin-left: 15%; 
    }
    .hub-container-fluid {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .hub-body-left{
        display: none;
    }
    .hub-body-right{
        margin-left: 0%;
        margin-top: 0px;
    }
    .hub-congestion-text{
        display: none;
    }
    .hub-top-logo-section{
        display: none;
    }
    .hub-starbucks-logo1 img {
        width: 14%;
    }
    .hub-small-header-container{
        background: #fff;
    }
    .hub-small-header{
        /* position: absolute;
        top: 0%;
        left: 0%; */
        border-radius: 0px 0px 130px 130px;
        background: rgb(144,36,103);
        background: linear-gradient(318deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        z-index: 9999999;

    }
    .hub-number{
        display: inline-block;
    }
    .pending-hub-box-item h6{
        display: none;
    }
    .hub-small-text{
        display: block;
        margin-top: -12%!important;
    }
    .pending-hub-box-item {
        width: 75% !important;
        margin: auto;
    }
    .hub-box-container {
        position: relative;
        top: -122px;
    }


    .hub-box-container-pending-page {
        position: relative;
        top: -122px;
    }
    .hub-box {
        height: 0;
    }
    .hub-small-header-container{
        display: block;
    }
    .hub-table-item.mt-5 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .hub-left-img {
        margin: auto;
        margin-top: 28px;
        width: 85%;
    }
    .hub-arrow-down img {
        margin-top: 0px;
    }
    .col-xl-7.col-lg-8.col-md-8.hub-all-row {
        margin-top: 40px;
    }
    .hub-third-row, .hub-second-row {
        width: 100%;
    }
    .hub-first-row {
        width: 100%;
    }
    img.hub-grp-1 {
        width: 125px !important;
        margin-top: -20px;
    }
    .hub-grp-1-small {
        width: 80px;
        margin-top: -20px;
    }
    .hub-nav-bottom{
        display: block !important;
    }
    .hub-today-box {
        position: relative;
        padding: 10px 80px 10px 10px;
        width: 20%;
        margin-top: 70px!important;
        border-radius: 12px;
    }
    .hub-today-box strong{
        position: absolute;
        font-size: 20px;
        margin-left: 10px;
    }

    .reservation-name-position{
        margin-left: 22% !important;
        font-size: 30px;
    }
    .pending-numbers-postition{
        margin: auto;
        margin-top: 8%;
    }
    .creator-image-layout-attending{
        height: 100px!important;
        width: 100px!important;
        margin-left: -15px;
    }
}

@media only screen and (max-width: 850px){
    .reservation-icon-cancel{
        width: 35px;
        height: 35px;
        margin-top: -70px;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 80px;
        height: 80px;
        margin-bottom: 22px;
    }
}
@media only screen and (max-width: 800px){
    .reservations-input-icon-search{
        width: 3%;
        margin-left: 12px;
        margin-top: 8px;
    }
}
@media only screen and (max-width: 768px){
    .approved-reservations-save-button{
        width: 20%;
    }
    .pending-hub-box-item img {
        margin-top: -7px;
    }
    .hub-small-text{
        display: block;
        margin-top: -13%!important;
    }

    .hub-left-img {
        margin: auto;
        margin-top: 45px;
        width: 80%;
    }
    .hub-today-box {
        position: relative;
        padding: 10px 80px 10px 10px;
        width: 20%;
        margin-top: 70px!important;
        border-radius: 12px;
    }
    .hub-today-box strong{
        position: absolute;
        font-size: 20px;
        margin-left: 10px;
    }
  
    /* .creator-image-layout{
        height: 100px!important;
        width: 100px!important;
        margin-left: 15%;
    } */
    .pending-numbers-postition{
        margin: auto;
        margin-top: 10%;
    }
    .reservation-icon-cancel{
        width: 35px;
        height: 35px;
        margin-top: -80px !important;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 70px;
        height: 70px;
    }
 
    .booked-tables-icon{
        width: 15%;
        margin-top: -10%;
        /* float: right; */
        /* margin-left: -60% !important; */
    
    }
    .all-tables-text{
        color: rgb(87, 85, 120);
        font-weight: bolder;
        margin-left: 15px;
        font-size: 16px;
    }
    .new-reservation-text-box{
        width: 92px !important;
    }
    .new-reservation-text{
        font-size: 16px;
    }
    .creator-image-layout-attending{
        height: 90px!important;
        width: 90px!important;
        margin-left: -20px;
    }
    .booked-reservation-name-position{
        margin-top:50px!important;
        margin-bottom: -20px;
        margin-left: -120%;
        width: 160px;
        font-size: 14px;
    }
    .new-reservation-icon-position{
        /* margin-left: 77%;
        margin-top: -159px; */
    }
}
@media only screen and (max-width: 670px){
    .new-booked-button-7 {
        padding: 10px 10px !important;
    }
    .pending-numbers-postition{
        margin: auto;
        margin-top: 12%;
    }
    .reservation-icon-cancel{
        width: 30px;
        height: 30px;
        margin-top: -80px !important;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 70px;
        height: 70px;
        margin-bottom: 17px;
    }
    .creator-image-layout-attending{
        height: 80px!important;
        width: 80px!important;
        margin-left: -30px;
    }
    .booked-reservation-name{
        font-size: 13px;
        margin-left: -15px;
    }
    /* .attending-sellect-persons-number{
        font-size: 23px !important;
        margin-top: -5px !important;
        margin-left:-1px;
    } */
}
@media only screen and (max-width: 576px){
    .total-arrived-persons-position{
        margin-top: -40px;
        margin-bottom: -30px;
    }
    .reservations-input-icon-search{
        width: 7%;
    }
    .booked-reservations-search-box{
        margin-top: -60px !important;
        position: absolute;
        margin-left: 18% !important;
    }
    .hub-box-container{
        margin: auto;
    }
    .hub-small-text{
        display: block;
        margin-left: 15%!important;
        margin-top: -16%!important;
    }
    .hub-small-text h6 {
        font-size: 13px;
    }
    .pending-hub-box-item {
        width: 80% !important;
        margin-left: -10%!important;
        padding: 10px 20px;
    }
    .hub-number {
        margin: 10px auto;
        width: 52px;
        height: 33px;
        background: #fff;
        line-height: 33px;
        border-radius: 6px;
    }
  
    .hub-today-box {
        width: 25%;
        margin-top: 40px!important;
    }
    .hub-today-box strong{
        position: absolute;
        font-size: 18px;
        margin-left: 10px;
    }
    .hub-item-of-table {
        padding: 0px 10px 15px 10px;
    }
    .hub-arrow-down img {
        margin-top: 15px !important;
    } 
    .hub-small-header {
        border-radius: 0px 0px 65px 65px;
    }
    .hub-row.padding {
        padding-right: 80px !important;
        margin-left: -30px;
    }
    .hub-middle-icon-footer img {
        position: absolute;
        top: -45px;
        width: 85px !important;
        left: 18px;
    }
    .reservation-name-position{
        margin-left: 5% !important;
        font-size: 30px;
    }
    .hub-first-row {
        margin-top: 10px!important;
    }
    .pending-numbers-postition{
        margin: auto;
        margin-top: 14%;
    }
    .reservation-icon-cancel{
        width: 27px;
        height: 27px;
        margin-top: -90px !important;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
    }

    .booked-tables-icon-position{
        margin-left: 10%;
        margin-top: -13%;
    }
    .new-reservation-icon-position{
        margin-top: -60px;
        margin-bottom: -0px;
        margin-left: 3%;
    }

    .hub-table-round-buttons{
        width: 65px;
        height: 65px;
        margin-left: 3%;
    }
    .all-reservation-tables-icon{
        width: 40px;
        height:40px;
        margin-top: -80px;
    }
    .booked-attending-persons-box{
        margin-top: 80% !important;
    }
    /* .attending-sellect-persons-number{
        margin-top: -2px !important;
    } */
    .booked-reservation-name-position{
        margin-top:100px!important;
        margin-bottom: -20px;
        margin-left: -120%;
        width: 160px;
        font-size: 13px;
    }
}
@media only screen and (max-width: 493px){
    .hub-today-box {
        width: 28%;
        margin-top: 30px!important;
    }
    .hub-today-box strong{
        position: absolute;
        font-size: 18px;
        margin-left: 15px;
    }
    .hub-left-img {
        margin-left: 10px;
        width: 95%;
    }
    .hub-small-text{
        display: block;
        margin-left: 25%!important;
        margin-top: -18%!important;
    }
    .hub-small-text h6 {
        font-size: 12px;
    }
    .pending-numbers-postition{
        margin: auto;
        margin-top: 16%;
    }
    .creator-image-layout-attending{
        height: 70px!important;
        width: 70px!important;
        margin-left: -35px;
    }
    .booked-reservation-name{
        font-size: 12px;
        margin-left: -25px;
    }
    .booked-attending-persons-box{
        width: 140% !important;
    }
    .new-booked-button-7 {
        font-size: 13px !important;
    }
}
@media only screen and (max-width: 450px){
    .approved-reservations-save-button{
        width: 30%;
    }
    .hub-today-box {
        width: 33%;
    }
    .pending-hub-box-item {
        margin-left: -15%!important;
       
    }
    .hub-small-text h6 {
        font-size: 12px;
        margin-left: -30%;
    }
    .creator-image-layout{
        height: 70px!important;
        width: 70px!important;
        margin-left: 0%;
    }
    .reservation-name-position{
        margin-left: 7% !important;
        font-size: 20px;
    }
    .pending-numbers-postition{
        margin: auto;
        margin-top: 18%;
    }
    .hub-first-row-sky{
        height: 35%;
    }
    .hub-first-row-blue{
        height: 35%;
    }
    .hub-first-row-grey{
        height: 35%;
    }
    .hub-first-row-red{
        height: 35%;
    }
    .booked-tables-icon-position{
        margin-left: 5%;
        margin-top: -16%;
    }
    .creator-image-layout-attending{
        height: 60px!important;
        width: 60px!important;
        margin-left: -30px;
    }
    .hub-item-of-table {
        width: 130%;
        margin-left: -15%;
    }
}
@media only screen and (max-width: 412px){
    .hub-today-box {
        width: 33%;
    }
    .hub-number {
        width: 38px;
        height: 30px;
        line-height: 30px;
    }
    .pending-hub-box-item {
        margin-left: -19%!important;   
    }
    .hub-small-text h6 {
        margin-top: -17px;
        font-size: 12px;
    }
    .btn-danger-booked-reservations {
        font-size: 16px!important;
        width: 100% !important;
    }
    .btn-success-pending-reservations{
        font-size: 15px;
    }
    .btn-danger-pending-reservations{
        font-size: 15px;
    }
    .reservation-icon-cancel{
        width: 27px;
        height: 27px;
        margin-top: -95px !important;
        /* margin-left: 3%; */
    }
    .hub-pending-round-buttons{
        width: 55px;
        height: 55px;
        margin-bottom: 15px;
    }
    .booked-tables-icon{
        width: 15%;
        margin-top: -15%;
        /* float: right; */
        /* margin-left: -60% !important; */
    
    }
    .all-tables-text{
        color: rgb(87, 85, 120);
        font-weight: bolder;
        /* margin-left: -3px; */
        /* margin-top: 10px; */
        font-size: 14px;
    }
    .new-reservation-text-box{
        width: 77px !important;
    }
    .new-reservation-text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 377px){
    .booked-reservation-name{
        font-size: 11px;
    }
    img.grp-1 {
        width: 115px !important;
        margin-top: -34px;
    }
    .hub-grp-1-small {
        width: 70px;
        margin-top: -34px;
    }
    .hub-first-row span, .hub-second-row span, .hub-first-row span {
        margin-top: -24px;
    }
    .hub-today-box {
        width: 37%;
    }
    .hub-small-text{
        display: block;
        margin-left: 25%!important;
        margin-top: -24%!important;
    }
    .hub-small-text h6 {
        font-size: 12px;
    }

    .hub-group {
        box-sizing: content-box;
        padding: 0px 10px;
        margin-top: -7px;
    }
    .btn-danger-booked-reservations {
        font-size: 15px!important;
        width: 100% !important;
    }
    .booked-page-time-position{
        margin-top: 3px!important;
    }
}
@media only screen and (max-width: 372px){
    .creator-image-layout-attending{
        height: 50px!important;
        width: 50px!important;
        margin-left: -15px;
    }
    .booked-reservation-name{
        font-size: 10px;
        margin-left: -10px;
    }
    .hub-first-row {
        padding: 7px 10px;
        font-size: 13px;
    }
    .hub-left-img {
        margin-left: 0px;
        width: 115%;
    }
    .hub-left-img strong {
        font-size: 14px;
    }
    .hub-group {
        box-sizing: content-box;
        padding: 0px 5px;
        width: 34px;
        margin-right: -19px!important;
        margin-top: -6px;
    }
    .hub-first-row strong, .hub-second-row strong ,.hub-third-row strong {
        font-size: 12px;
    }
    .hub-first-row img, .hub-second-row img, .hub-third-row img {
        width: 20px;
    }
    .hub-four {
        margin-right: -8px!important;
        margin-top: -2%!important;
        width: 20%;
        padding-top: 5%;
        font-size: 10px!important;
    }
    .hub-drinks{
        margin-right: -8px!important;
        margin-top: -2%!important;
        width: 17%;
        padding-top: 5%;
        padding-left: 5px!important;
        padding-right: 20px!important;
        padding-bottom: 0%!important;
        font-size: 13px!important;
    }
    .hub-today-box {
        width: 40%;
    }
    .pending-hub-box-item {
        width: 60% !important;
        margin-left: -20%!important;
        padding: 15px 25px;
    }
    .hub-small-text h6 {
        font-size: 12px!important;
        margin-left: -20px;
        margin-top: -20px;
    }
    .reservation-name-position{
        /* margin-top: 60px !important; */
        margin-left: 8% !important;
        font-size: 20px!important;
    }
    .btn-danger-booked-reservations {
        font-size: 12px!important;
        width: 100% !important;
    }
    .hub-first-row-sky{
        height: 30%;
    }
    .hub-first-row-blue{
        height: 30%;
    }
    .hub-first-row-grey{
        height: 30%;
    }
    .hub-first-row-red{
        height: 30%;
    }
    .reservation-icons-size{
        width: 25px !important;
        height: 25px !important;
    }
    .reservation-icon-cancel{
        width: 25px;
        height: 25px;
        margin-top: -100px !important;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        margin-left: -15%;
    }
    .new-booked-button-7 {
        font-size: 12px !important;
        padding: 5px 5px !important; 
    }
}

@media only screen and (max-width: 342px){
    .approved-reservations-save-button{
        width: 45%;
    }
    .hub-group {
        margin-top: -7px;
        padding: 0px 7px;
    }
    .col-md-12.col-sm-12.text-center img {
        width: 70px;
    }
    .hub-logo-size-small{
        width: 70px!important;
        height: 70px!important;
        border: 3px solid #FFFAFA;
    }
    .creator-image-layout{
        height: 60px!important;
        width: 60px!important;
        margin-left: 10%!important;
        margin-bottom: 25%!important;
    }
    .hub-drinks{
        margin-right: -8px!important;
        margin-top: -2%!important;
        width: 17%;
        padding-top: 5%;
        padding-left: 5px!important;
        padding-right: 20px!important;
        padding-bottom: 0%!important;
        font-size: 13px!important;
    }
    .btn-danger-booked-reservations {
        font-size: 10px!important;
        width: 100% !important;
    }
    .reservation-icon-cancel{
        width: 25px;
        height: 25px;
        margin-top: -100px !important;
        /* margin-left: 7%; */
    }
    .hub-pending-round-buttons{
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        margin-left: -29%;
    }
}
@media only screen and (max-width: 332px){
    .hub-first-row {
        width: 100% !important;
    }
    .creator-image-layout-attending{
        height: 45px!important;
        width: 45px!important;
        margin-left: -20px;
    }
    .col-md-12.col-sm-12.text-center img {
        width: 70px;
        margin-bottom: 15px;
    }
    .hub-four {
        margin-right: -8px!important;
        margin-top: 0%!important;
        width: 20%;
        padding-top: 5%;
        font-size: 10px!important;
    }
    .hub-logo-size-small{
        margin-top: -15px;
        margin-left: -0%!important;
        margin-bottom: -2px!important;
        width: 65px!important;
        height: 65px!important;
        border: 3px solid #FFFAFA;
    }
    .creator-image-layout{
        height: 80px!important;
        width: 80px!important;
        margin-left: -20%!important;
        margin-bottom: 25%!important;
    }
    .reservation-name-position{
        margin-top: 30px !important;
        font-size: 30px!important;
    }
    .hub-first-row strong, .hub-second-row strong ,.hub-third-row strong {
        font-size: 9px;
    }
    .btn-danger-booked-reservations {
        font-size: 9px!important;
        width: 100% !important;
    }
    .btn-success-pending-reservations{
        font-size: 13px;
    }
    .btn-danger-pending-reservations{
        font-size: 13px;
    }
    .booked-tables-icon{
        width: 15%;
        margin-top: -10%;

    }
    .booked-tables-icon-position{
        margin-left: 0%;
        margin-top: -20%;
    }
    .all-tables-text{
        color: rgb(87, 85, 120);
        font-weight: bolder;
        font-size: 12px;
    }
    .booked-reservation-name{
        font-size: 10px !important;
        margin-left: -20px;
    }
    .new-reservation-text-box{
        width: 66px !important;
        margin-left: 2px;
    }
    .new-reservation-text{
        font-size: 12px;
    }
    /* .new-reservation-icon-position{
        margin-left: 73%;
        margin-top: -123px;
    } */
}
@media only screen and (max-width: 321px){
    .hub-first-row span, .hub-second-row span, .hub-first-row span {
        margin-top: 0px !important;
    }
    .hub-today-box {
        width: 45%;
    }
    img.hub-grp-1 {
        width: 110px !important;
        margin-top: -20px;
    }
    .reservation-name-position{
        font-size: 20px!important;
    }
    .hub-drinks{
        margin-right: -8px!important;
        margin-top: -2%!important;
        width: 15%;
        padding-top: 5%;
        padding-left: 5px!important;
        padding-right: 18px!important;
        padding-bottom: 0%!important;
        font-size: 10px!important;
    }
    .hub-first-row strong, .hub-second-row strong ,.hub-third-row strong {
        font-size: 8px;
    }
    .btn-danger-booked-reservations {
        font-size: 9px!important;
        padding-left: 10%!important;
        padding-right: 10%!important;
        width: 100% !important;
    }
    .btn-success-pending-reservations{
        font-size: 9px;
    }
    .btn-danger-pending-reservations{
        font-size: 9px;
    }
    /* .new-reservation-icon-position{
        margin-left: 75%;
        margin-top: -125px;
    } */
}
@media only screen and (max-width: 280px){
    .hub-left-img {
        margin-left: 14px;
        width: 160%;
        margin-top: 10px;
    }
    .pending-hub-box-item {
        padding: 6px 12px;
    }
    .hub-small-text h6 {
        font-size: 12px;
    }
    .hub-row.padding {
        padding-right: 30px !important;
    }
    .hub-today-box {
        width: 50%;
    }
    .hub-logo-size-small{
        margin-top: -15px;
        margin-left: -0%!important;
        margin-bottom: -2px!important;
        width: 50px!important;
        height: 50px!important;
        border: 3px solid #FFFAFA;
    }
    .hub-drinks{
        margin-right: -8px!important;
        margin-top: -2%!important;
        width: 15%;
        padding-top: 15%!important;
        padding-left: 5px!important;
        padding-right: 18px!important;
        padding-bottom: 0%!important;
        font-size: 8px!important;
    }
    .hub-first-row strong, .hub-second-row strong ,.hub-third-row strong {
        font-size: 7px;
    }
    .btn-danger-booked-reservations {
        font-size: 15px!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        width: 200% !important;
    }
    .btn-success-pending-reservations{
        font-size: 7px;
        width: 60px!important;
        margin-left: -20px!important;
    }
    .btn-danger-pending-reservations{
        font-size: 7px;
        width: 60px!important;
        margin-left:15px!important;
    }
}
/* media quaries end */
