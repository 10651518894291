/* gag modal */
.text-blue-10{
    color: #575578;
}
.profile-gag-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 360px !important;
    margin-top: -150%;
    margin-left: -220%;
    width: 750%;
}
.post-hub-modal-frame {
    width: 135px;
    height: 130px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.hub-modal-member-photo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);   
}
.post-hub-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}
.gag-modal-times-close-icon{
    margin-top: 6px;
    width:4%;
}
.profile-gag-slider-layout{
    margin-top: 2%;
}
.profile-gag-btn-danger {
    background: #ce0404 !important;
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /* margin-top: 3%; */
    color: white!important;
    cursor: pointer;
    float: right;
}
/* end gag modal */


@media only screen and (max-width: 1200px){
    /* modal */
    .profile-gag-modal-box {
        height: 360px !important;
        margin-top: -150%;
        margin-left: -300%;
        width: 750%;
    }
    /* end mofal */

}


@media only screen and (max-width: 992px){
    /* gag modal */
    .profile-gag-modal-box {
        height: 330px !important;
    }
    .post-hub-modal-frame {
        width: 100px;
        height: 100px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    /* end gag modal */
}


@media only screen and (max-width: 768px){
    /* gag modal */
    .profile-gag-modal-box {
        height: 320px !important;
        margin-top: -150%;
        margin-left: -210%;
        width: 550%;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -50px;
    }
    .profile-gag-btn-danger {
        padding: 8px 40px !important;
        font-size: 20px !important;
    }
    /* end gag modal */
}




@media only screen and (max-width: 610px){
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -180%;
        width: 500%;
    }
    .post-hub-modal-frame {
        width: 80px;
        height: 80px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    
}


@media only screen and (max-width: 576px){
    /* gag modal */
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
    }
    .profile-gag-btn-danger {
        padding: 8px 30px !important;
        font-size: 18px !important;
    }
    /* end gag modal */
}


@media only screen and (max-width: 540px){
    /* gag modal */
    .post-hub-modal-frame {
        width: 80px;
        height: 80px;
        line-height: 105px;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 30px !important;
        font-size: 18px !important;
    }
    /* end gag modal */
}

@media only screen and (max-width: 500px){
    /* modal */
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -150%;
        width: 430%;
    }
    /* end modal */
}

@media only screen and (max-width: 460px){
    /* gag modal */
    .profile-gag-modal-box {
        height: 290px !important;
        margin-top: -150%;
    }
    .post-hub-modal-frame {
        width: 65px;
        height: 65px;
    }
    .profile-gag-btn-danger {
        padding: 8px 20px !important;
        font-size: 15px !important;
    }
    .gag-modal-times-close-icon{
        margin-top: 6px;
        width:8%;
    }
}

@media only screen and (max-width: 432px){
    /* modal */
    .profile-gag-modal-box {
        height: 310px !important;
        margin-top: -150%;
        margin-left: -135%;
        width: 400%;
    }
    /* end modal */
}


@media only screen and (max-width: 400px){
    /* modal */
    .post-hub-modal-frame {
        line-height: 73px;
        width: 55px;
        height: 55px;
    }  
    /* end modal */
}

@media only screen and (max-width: 396px){
    /* gag modal */
    .profile-gag-modal-box {
        height: 260px !important;
        margin-top: -150%;
        margin-left: -110%;
        width: 350%;
    }
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 15px !important;
        font-size: 12px !important;
    }
    /* end gag modal */
}


@media only screen and (max-width: 366px){
    /* gag modal */
    .profile-gag-slider-layout{
        margin-top: 2%;
        margin-left: -0px;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 8px 15px !important;
        font-size: 10px !important;
    }
    /* end gag modal */
}

@media only screen and (max-width: 342px){
    /* gag modal */
    .profile-gag-modal-box {
        height: 240px !important;
        margin-top: -120%;
        margin-left: -95%;
        width: 320%;
    }
    .profile-gag-slider-layout{
        margin-top: 2% !important;
        margin-left: -0px !important;
        width: 115%!important;
    }
    .profile-gag-btn-danger {
        padding: 2px 15px !important;
        font-size: 10px !important;
        margin-top: -7px;
    }
    /* end gag modal */
}


@media only screen and (max-width: 300px){
    /* gag modal */

    .profile-gag-slider-layout{
        width: 100%;
    }
    .profile-gag-btn-danger {
        margin-top: -20px !important;
        padding: 2px 10px !important;
        font-size: 10px !important;
    }
    /* end gag modal */
}