/* modal */
.tables-modal-modal-box {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    height: 380px !important;
    margin-top: -155%;
    margin-left: -420%;
    width: 950%;
}
.tables-modal-title{
    position: relative;
    font-size: 30px;
    width: 50%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 0%;
}
.all-tables-modal-table-number{
    color:#3F1E75;
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
    /* margin-top: -35% !important; */
    /* margin-bottom: -20% !important; */
}
.tables-modal-table-reservation{
    color:#3F1E75;
    /* font-weight: bold; */
    font-size: 16px;
    margin-top: 5% !important;
    /* margin-bottom: -20% !important; */
    line-height: 20px;
}
.tables-modal-cancle-btn {
    width: 50px;
    height: 50px;
    line-height: 35px;
    border-radius: 50%;
    background-color:  rgb(255, 255, 255);
    text-align: center;
    position: absolute;
    top: 0px;
    right: 3%;
    cursor: pointer !important;
    z-index: 99999999999;
    border:double 1px rgb(179, 171, 209);
}
.tables-modal-close-icon{
    margin-top: 6px;
    width:60%;
}
.tables-modal-slider-layout{
    margin-top: 2%;
}
.tables-modal-search{
    margin-top: 1%;
    border: none;
    width: 40%;
}
.tables-modal-search input{
    border-radius: 9px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
    box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
}
.tables-modal-modal-frame {
    width: 120px;
    height: 120px;
    background: #fff;
    margin: auto;
    line-height: 125px;
    border-radius: 12px;
    margin-left: 14%;
}
.tables-modal-modal-logo{
    width: 100% !important;
    height: 100%;
    border:solid 10px ;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 10px;
}
.tables-modal-sellected-logo{
    width: 100% !important;
    height: 100%;
    border-radius: 50%;
    border:double 15px rgb(77,51,168);
    background: linear-gradient(to bottom,rgb(117, 96, 194) 19%, rgba(141,132,246,1) 100%);
    -webkit-box-shadow: 20px 40px 80px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 20px 20px 40px -5px rgba(45, 6, 104, 0.3);
}

.tables-modal-btn-danger {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 8px 60px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 4%;
    color: white!important;
    cursor: pointer;
    float: right;
}
.btn-danger-free-tables {
    /* position: absolute; */
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 10px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    cursor: pointer;
    color: white !important;
    text-align: center;
    width: 25%;
    margin-left: 10%;
}
/* end modal */

@media only screen and (max-width: 992px){
    /* modal */
    .tables-modal-modal-box {
        height: 350px !important;
        margin-top: -155%;
        margin-left: -260%;
        width: 650%;
    }
    .tables-modal-modal-frame {
        width: 100px;
        height: 100px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    .tables-modal-btn-danger {
        margin-top: 6%;
    }
    .btn-danger-free-tables {
        padding: 5px 15px !important;
        font-size: 13px !important;
        width: 20%;
        height: 32px;
        margin-left: 10%;
    }
}

@media only screen and (max-width:610px){
    /* modal */
    .tables-modal-modal-box {
        /* height: 280px !important; */
        margin-top: -155%;
        margin-left: -210%;
        width: 550%;
    }
    .tables-modal-modal-frame {
        width: 80px;
        height: 80px;
        background: #fff;
        margin: auto;
        line-height: 120px;
    }
    .tables-modal-table-reservation{
        font-size: 14px;
        margin-top: 5% !important;
        /* margin-bottom: -20% !important; */
        line-height: 20px;
    }
    .btn-danger-free-tables {
        padding: 5px 15px !important;
        font-size: 12px !important;
        width: 25%;
        height: 32px;
        margin-left: 5%;
    }
    /* end modal */
}

@media only screen and (max-width: 540px){
    /* modal */
    .tables-modal-modal-frame {
        width: 80px;
        height: 80px;
        line-height: 105px;
    }
    .tables-modal-next-btn{
        font-size: 20px !important;
        top: 20px;
        right: 5%;
        width: 40%;
    }
    .cancle-btn-photo-snapped {
        width: 50px;
        height: 50px;
        top: 20px;
        left: 3%;
    }
    .tables-modal-modal-box {
        height: 330px !important;
        margin-top: -115%;
        margin-left: -160%;
        width: 450%;
    }
    .tables-modal-title{
        font-size: 20px;
    }
    .tables-modal-search{
        margin-top: 1%;
        border: none;
        width: 40%;
    }
    .tables-modal-search input{
        border-radius: 9px;
        -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3); 
        box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    }
    .tables-modal-slider-layout{
        margin-top: 5%;
        margin-bottom: 3%;
        width: 100%;
    }
    .tables-modal-cancle-btn {
        width: 30px;
        height: 30px;
        line-height: 35px;
        border-radius: 50%;
        background-color:  rgb(255, 255, 255);
        text-align: center;
        position: absolute;
        top: 0px;
        right: 3%;
        cursor: pointer !important;
        z-index: 99999999999;
        border:double 1px rgb(179, 171, 209);
    }
    .tables-modal-close-icon{
        margin-top: -10px;
        width:60%;
    }
    .btn-danger-free-tables {
        padding: 5px 10px !important;
        font-size: 12px !important;
        width: 20%;
        height: 45px;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 460px){
    /* modal */
    .tables-modal-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 315px !important;
        margin-top: -115%;
        margin-left: -125%;
        width: 380%;
    }
    .tables-modal-search{
        margin-top: 1%;
        border: none;
        width: 60%;
    }
    .tables-modal-modal-frame {
        width: 75px;
        height: 75px;
    }  
    .tables-modal-slider-layout{
        margin-top: 5%;
        margin-bottom: 5%;
        width: 100%;
    }
    .tables-modal-btn-danger {
        padding: 8px 30px !important;
        font-size: 15px !important;
        margin-top: 4%;
    }
    .all-tables-modal-table-number{
        color:#3F1E75;
        font-weight: bold;
        line-height: 30px;
        font-size: 16px;
        margin-top: 5% !important;
        /* margin-bottom: -20% !important; */
    }
    .tables-modal-table-reservation{
        color:#3F1E75;
        /* font-weight: bold; */
        font-size: 12px;
        margin-top: 5% !important;
        /* margin-bottom: -20% !important; */
        line-height: 20px;
    }
    .btn-danger-free-tables {
        padding: 5px 10px !important;
        font-size: 12px !important;
        width: 20%;
        height: 45px;
        margin-left: 2%;
    }
}


@media only screen and (max-width: 400px){
    /* modal */
    .tables-modal-next-btn{
        font-size: 20px !important;
        width: 40%;
        padding: 8px 10px !important;
    }
    /* modal */
    .tables-modal-modal-box {
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        height: 320px !important;
        margin-top: -115%;
        margin-left: -115%;
        width: 360%;
    }
    .all-tables-modal-table-number{
        font-size: 13px;
        margin-top: -40%;
    }
    .tables-modal-cancle-btn {
        width: 30px;
        height: 30px;
    }
    .tables-modal-modal-frame {
        line-height: 73px;
        width: 55px;
        height: 55px;
    }    
    .tables-modal-btn-danger {
        margin-top: -3% !important;
        padding: 8px 20px !important;
        font-size: 15px !important;
        margin-top: 3%;
    }
    .tables-modal-close-icon{
        margin-top: -10px;
        width:60% !important;
    }
    .btn-danger-free-tables {
        padding: 5px 10px !important;
        font-size: 12px !important;
        width: 20%;
        height: 45px;
        margin-left: 0%;
    }
}