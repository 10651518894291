.link2{
    margin-left: 15px;
}
/* loading */
.hub-login-section-disappear{
    display: none;
}
.hub-login-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.hub-login-lottie-dissappear{
    display: none;
}
.hub-login-lottie-position{
    margin-left:-30% !important
}
.hub-contact-form-submit{
    margin-top: -10%;
}
/* end loading */
body {
    color: #fff;
    background: #fff !important;
    /* font-family: 'Roboto', sans-serif; */
}
.hub-login-form-control{
    height: 40px;
    box-shadow: none;
    color: #969fa4;
}
.hub-login-form-control:focus{
    border-color: #5cb85c;
}
.hub-login-form-control, .btn{        
    border-radius: 3px;
}
.hub-login-signup-form {
    width: 550px;
    margin: 0 auto;
    padding: 30px 0;
}
.hub-login-signup-form h2 {
    color: #3F1E75;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
/* .hub-login-signup-form h2:before, .hub-login-signup-form h2:after{
    content: "";
    height: 2px;
    width: 30%;
    background: #d4d4d4;
    position: absolute;
    top: 50%;
    z-index: 2;
}	 */
.hub-login-signup-form h2:before{
    left: 0;
}
.hub-login-signup-form h2:after{
    right: 0;
}
.hub-login-signup-form .hint-text {
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.hub-login-signup-form form {
    color: #999;
    border-radius: 12px;
    margin-bottom: 15px;
    margin-top: 30px;
    background: rgb(255, 227, 227);
    -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    padding: 15px 55px;
}
.hub-login-signup-form .form-group{
    margin-bottom: 20px;
}
.hub-login-signup-form input[type="checkbox"]{
    margin-top: 3px;
    background: #000 !important;
}
.hub-login-signup-form .btn{        
    font-size: 16px;
    font-weight: bold;		
    min-width: 140px;
    outline: none !important;
}
.hub-login-signup-form .row div:first-child{
    padding-right: 10px;
}
.hub-login-signup-form .row div:last-child{
    padding-left: 10px;
}    	
.hub-login-signup-form a{
    color: #fff;
    text-decoration: underline;
}
.hub-login-signup-form a:hover{
    text-decoration: none;
}
.hub-login-signup-form form a{
    color: #5cb85c;
    text-decoration: none;
}	
.hub-login-signup-form form a:hover{
    text-decoration: underline;
}
.hub-login-top-text{
    margin-top: 5%!important;
}
.hub-login-form-control {
    display: block;
    width: 80%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto;
    margin-top: 10%;
    text-align:center;
}
.hub-login-form-logo img{
    margin-top: 15px;
    margin-bottom: 25px;
}
.hub-login-checkbox-inline {
    color: #575578;
    font-size: 13px;
}
.hub-login-signup-form .btn {
    font-size: 16px;
    font-weight: bold;
    width: 60%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 14px;
    margin: auto;
    margin-top: 20%!important;
}

.hub-login-account-have{
    color: #707070;
}
.hub-login-signup-form form a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.hub-login-form-header{
    display: none;
}
.hubster-login-logo-size{
    width: 30%;
}
.hubst3r-login-word-size{
    width: 30%;
}
.hub-login-back-button{
    position: absolute;
    float: left;
    margin-left: 5%;
    margin-top: 1.5%;
}
.hub-login-back-icon-size{
    width: 100%;
}


/* modal */
.authentication-password-box {
    margin-top: -115%;
    margin-left: -420%;
    width: 950%;
}
.hub-login-modal-top-text{
    color: #575578;
    margin-bottom: 30px;
    text-align: center;
}
.modal-password-field{
    display: block;
    width: 50%;
    height: 45px !important;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: #f1f2f7 !important;
    margin: auto!important;
    margin-top: 50px!important;
    text-align:center !important;
}
.send-again-a {
    color: #4e2491;
    text-decoration: none;
    font-weight: bold;
}
.send-again-a:hover{
    color: #4e2491;
    text-decoration: underline;
}
.hub-login-modal-btn-success{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    outline: none !important;
    border: none;
    background: rgb(144,36,103);
    background: linear-gradient(0deg, rgba(144,36,103,1) 21%, rgba(28,48,111,1) 83%);
    font-weight: bold;
    border-radius: 30px!important;
    color: white!important;
    /* margin: auto!important; */
    margin-left: 57%!important;   
}
/* success modal */
.form-success-box{
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -255%;
    margin-left: -250%;
    height: 600px!important;
    width: 550%;
}
.form-success-title{
    position: relative;
    font-size: 30px;
    width: 80%;
    margin-bottom: -8%;
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 10%;
}
.form-success-subtitle{
    /* position: relative; */
    font-size: 20px;
    width: 85%;
    margin-top: 10px !important;
    /* margin-bottom: -8%; */
    color: #3F1E75;
    z-index: 3 !important;
    margin-left: 8%;  
}
.form-success-times-modal{
    position: relative;
    width: 5%;
    margin-bottom: -60px!important;
    float: right;
    z-index: 1 !important;
    margin-top: 1%;
    cursor: pointer;
}
.form-success-button{
    width: 50%;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none;
    padding: 10px 45px;
    border-radius: 30px!important;
    color: white !important;
    margin-top: 10%;
}
.hub-form-sumbit-success{
    width: 50% !important;
    height: 50% !important;
    margin-top: 15%;
    margin-left: 25%; 
    margin-bottom: 7.5%;
}
/* end modal */

@media only screen and (max-width: 1200px) {
    /* loading */
    .register-lottie-position{
        margin-left:0% !important
    }
    /* end loading */
    .hub-login-form-color{
        /* background: rgb(144,36,103) !important;
        background: linear-gradient(342deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 100%) !important; */
        background: rgb(144,36,103);
        background: linear-gradient(336deg, rgba(144,36,103,1) 85%, rgba(28,48,111,1) 99%);
        position: absolute!important;
        top: 0;
        left: 0;
        width: 100%;
        height: -100px;
    }
    .hub-login-form-header{
        display: block;
        padding: 40px 20px 0px 0px;
    }
    .hub-login-signup-form form {
        color: #999;
        border-radius: 12px;
        margin-bottom: 0px !important;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 0px 0px rgba(45, 6, 104, 0.2) !important;
        padding: 25px 55px;
        height: 610px !important;
    }
    .hub-login-signup-form {
        width: 100.4%;
        margin: -0.2%;
        padding: 0px 0;
        height: 600px;
    }
    .hub-login-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }
    .hub-login-form-header {
        display: block;
        padding: 50px 0px 30px 0px;
    }
    .hub-login-form-control {
        display: block;
        width: 100%;
        height: 55px !important;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-image: none;
        border: none !important;
        border-radius: 12px !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: 0 1px 1px rgba(0,0,0,.075) !important;
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        background-color: #fff !important;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2) !important;
    }
    .hub-login-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 55px;
    }   
    /* success modal */
    .form-success-box{
        position: absolute;
        padding: 10px 25px !important;
        border-radius: 50px;
        background: #fff; 
        margin-top: -255%;
        margin-left: -225%;
        height: 600px!important;
        width: 550%;
    }
}



@media only screen and (max-width: 500px) {
    .hubst3r-login-word-size{
        width: 30%!important;
        margin: auto!important;
    }
    .hub-login-back-button{
        float: left;
        margin-left: 5%;
        margin-top: 0%;
    }
    .hub-login-back-icon-size{
        width: 80% !important;
    }
    .hub-login-signup-form form {
        color: #999;
        border-radius: 55px 55px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .hub-login-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 30px;
    }
    .hub-login-form-header img {
        width: 16rem;
    }
    /* success modal */
    .form-success-box{
        margin-top: -255%;
        margin-left: -150%;
        height: 600px!important;
        width: 400%;
    }
    .form-success-title{
        position: relative;
        font-size: 20px;
        width: 80%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 10%;
    }
    .form-success-subtitle{
        /* position: relative; */
        font-size: 20px;
        width: 85%;
        /* margin-top: 10px !important; */
        /* margin-bottom: -8%; */
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    .form-success-times-modal{
        position: relative;
        width: 5%;
        margin-bottom: -60px!important;
        float: right;
        z-index: 1 !important;
        margin-top: 1%;
        cursor: pointer;
    }
    .form-success-button{
        width: 70%;
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 10px 45px;
        border-radius: 30px!important;
        color: white !important;
        /* margin-top: 10%; */
    }
    .hub-form-sumbit-success{
        width: 50% !important;
        height: 50% !important;
        /* margin-top: -5%; */
        margin-left: 25%; 
        /* margin-bottom: 7.5%; */
    }
    /* end modal */
}



@media only screen and (max-width: 385px) {
    .hub-login-modal-btn-success{
        font-size: 16px;
        width: 60%;
        margin-left: 80%!important;   
    }
    
}
@media only screen and (max-width: 370px) {
    /* success modal */
    .form-success-box{
        margin-top: -155%;
        margin-left: -100%;
        height: 400px!important;
        width: 300%;
    }
    .form-success-title{
        position: relative;
        font-size: 17px;
        width: 80%;
        margin-bottom: -8%;
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 10%;
    }
    .form-success-subtitle{
        /* position: relative; */
        font-size: 13px;
        width: 85%;
        /* margin-top: 10px !important; */
        /* margin-bottom: -8%; */
        color: #3F1E75;
        z-index: 3 !important;
        margin-left: 8%;  
    }
    .form-success-times-modal{
        position: relative;
        width: 5%;
        margin-bottom: -60px!important;
        float: right;
        z-index: 1 !important;
        margin-top: 1%;
        cursor: pointer; 
    }
    .form-success-button{
        width: 80%;
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
        padding: 10px 45px;
        border-radius: 30px!important;
        color: white !important;
        margin-top: 5%;
    }
    .hub-form-sumbit-success{
        width: 50% !important;
        height: 50% !important;
        /* margin-top: -5%; */
        margin-left: 25%; 
        /* margin-bottom: 7.5%; */
    }
    /* end modal */
}
@media only screen and (max-width: 300px) {
    .hub-login-signup-form form {
        color: #999;
        border-radius: 35px 35px 0px 0px;
        margin-bottom: 15px;
        margin-top: 0px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        padding: 15px 15px;
    }
}