.approved-appointments-modal-title{
    position: absolute;
    top: 3%;
    width: 90%;
    left: 5%;
    font-size: 18px;
    color: #232231;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
}

.approved-appointments-modal-box{
    position: absolute;
    padding: 0px 0px !important;
    border-radius: 0px !important;
    background: #fff; 
    margin-top: -10;
    /* margin-left: -5%; */
    height: 120%!important;
    width: 110%;
    z-index: 9 !important;
} 

.new-meeting-request-reservtion-btn{
    background: #1183ca !important;
    border: none !important;
    padding: 5px 5px !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-align: center;
    width: 60%;
    margin-top: 70px!important;
    margin-bottom: 30px;
}

.new-meeting-request-reservtion-btn:active {
    transform: scale(0.88);
}

.new-meeting-request-reservtion-btn:focus {
    transform: scale(0.88);
}

.new-meeting-request-reservtion-btn:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.reservation-pc-display{
    display: block;
}
.reservation-mobile-display{
    display: none;
}

.hub-reservation-no-image{
    width: 50%;
    margin-top: 30%;
    border-radius: 50%;
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -18px 20px 0px inset, rgba(0, 0, 0, 0.15) 0px -26px 20px 0px inset, rgba(0, 0, 0, 0.1) 0px -59px 30px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px; */
    padding: 30px;
    /* border-top: solid 0.5px rgb(204, 204, 204); */
    /* animation: pulse 2s infinite; */
}

.hub-reservation-no-created{
    width: 100%;
    height: 100%;
}

@keyframes pulse {
	0% {
		transform: scale(0.97);
		/* box-shadow: 0 0 0 0 rgba(144,36,103,0.1); */
        
	}

	50% {
		transform: scale(1);
		/* box-shadow: 0 0 0 250px rgba(28,48,111,0.05);  */
	}

	100% {
		transform: scale(1);
		/* box-shadow: 0 0 0 250px transparent; */
	}
}


.hub-reservation-no-text-title{
    margin-bottom: 0%;
    color: #575578;
    margin-top: 30px;
    font-size: 20px !important;
    font-weight: bold;
}
.hub-reservation-no-text{
    margin-bottom: 50%;
    color: #575578;
    margin-top: 10px;
    font-size: 18px !important;
    font-weight: lighter;
    width: 90%;
    /* background-color: navy; */

}

.hub_reservation-meeting-share-box{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    /* margin: 36px; */
    width: 30px;
    height: 30px;
    position: relative;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    /* margin: auto;
    margin-bottom: 23px;
    margin-top: -30px !important; */
    position: absolute;
    right: 0%;
    top: 10%;
}

.hub_reservation-meeting-share-icon{
    width: 60%;
    height: 60%;
}

.hub_reservation-cancel-reservation{
    border-radius: 30px;
    padding: 8px 30px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 20px;
    /* width: 60% !important; */
    margin: auto !important;
    margin-top: 2%!important;
    margin-bottom: 2% !important;  
    z-index: 9; 
}

.hub_reservation-cancel-reservation:active {
    transform: scale(0.88);
}

.hub_reservation-cancel-reservation:focus {
    transform: scale(0.88);
}

.hub_reservation-cancel-reservation:after {
    transform: scale(0.88);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.hub_reservation-meeting-share-img-position{
    position: relative;
    top: 5px;
    margin: 5px;
}
.hub_reservation-meeting-share-img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}
.hub_reservation-meeting-shared{
    width: 20%;
    height: 40%;
    position: absolute;
    top: 30%;
    right: 0%;
    /* background-color: darkturquoise; */
}

.hub_reservation-meeting-text{
    color: #575578;
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;
}

.hub_reservation-meeting-text-persons{
    color: #575578;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.hub_reservation-meeting-status{
    width: 20%;
    height: 20%;
    position: absolute;
    top: 5%;
    right: 0%;
}

.hub_reservation-meeting-requested{
    color: #e6be04;
    font-size: 18px;
    font-weight: bold;
}

.hub_reservation-meeting-approved{
    color: #228B22;
    font-size: 18px;
    font-weight: bold;
}

.hub_reservation-meeting-cancelled{
    color: #DC143C;
    font-size: 18px;
    font-weight: bold;
}

.hub_reservation-meeting-waiting{
    color: #9d9d9d;
    font-size: 18px;
    font-weight: bold;
}

.hub_reservation-meeting-info{
    width: 140%;
    height: 100%;
    margin-top: 0px;
    color: #575578;
    text-align: left;
    font-size: 18px;
    margin-bottom:30px;
}

.hub_reservation-meeting-data{
    width: 100%;
    height: 100%;
    color: #575578;
    text-align: left;
    font-size: 18px;
    position: absolute;
    top: 0%;
    left: 40%;
}

.hide-h3{
    display: block;
}
.reservation-meetings-qr-position{
    margin: auto;
}
.reservation-meetings-qr-button{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    line-height: 55px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    margin: auto;
    margin-top: 10px;
}
.reservation-meetings-qr-img{
    width: 60%;
}
.reservation-meetings-qr-text{
    color: #39383d !important;
    margin-top: -20px;
}
.new-meeting-icon-position{
    margin: auto;
    margin-bottom: 20px;
    margin-top: -5%;
}
.new-meeting-round-buttons{
    display: block;
    width: 60px;
    height: 60px;
    position: relative;
    margin: auto;
    margin-bottom: 23px;
    margin-top: -30px !important;
}
.new-meeting-add-icon{
    width: 100%;
    height: 100%;
}
.new-meeting-text{
    color: #57558E !important;
    margin-top: -10px;
    font-size: 16px;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
} 
.meeteing-wifi-round-button-unpressed{
    display: block;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    color: #a7a7a7;
}
.meeteing-wifi-position{
    position: absolute;
    top: 2%;
    left: 3%;
}
.meeteing-wifi-round-button{
    /* margin: 36px; */
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    /* line-height: 95px; */
    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    /* margin: auto; */
    margin-bottom: 23px;
}
.meeteing-wifi-icon{
    width: 20px;
    height:20px;
    margin:auto;
    margin-top: 10px;
}
.meeteing-wifi-text{
    color: rgb(87, 85, 120);
    font-weight: bolder;
    font-size: 12px;
    margin-top: -15px;
}
/* loading */
.meetings-section-disappear{ 
    display: none;
}
.meetings-lottie-appear{
    z-index: 9999999999999999999999999999999990999999999999 !important;
}
.meetings-lottie-dissappear{
    display: none;
}
/* end loading */
.empty-meetings-image{
    margin: auto !important;
}
.empty-meetings-created{
    width: 50% !important;
    margin-left: -5%;
    margin-top:-5%;
}
.meetings-invitations-status-color{
    color: #575578;
}

.note-for-buddies-box{
    font-size: 20px !important;
    margin-top: 16px !important;
    /* margin-bottom: -20px; */
}

.note-for-buddies-text-blue{
    color: #39383d !important;
}
/* .meetings-change-meeting-all{
    width: 30%;
    margin: auto;
}
.meetings-change-meeting-icon-position{
margin-top: -10%;
}
.meetings-change-meeting-icon-size{
    width: 30% !important;
    border-radius: 20%;
} */
.meetings-change-meeting-btn{
    margin: auto;
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 60%);
    border: none !important;
    padding: 5px 18px !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    cursor: pointer;
    color: #fff;
    margin-top: 0px;
    /* width: 40%; */
}
.invitation-message-red-bubble{
    position: relative;
    background-color: red;
    width:14px;
    height: 14px;
    border-radius: 50%;
    z-index: 1;
    margin-top: -89%;
    margin-left: 75%;
    border: solid 2px #fff;
}
.text-blue{
    color: #575578;
    font-size: 70%;
}
.text-blue-empty-meetings{
    color: #575578;
    font-size: 170% !important; 
}
/* Body left css start */
.body-left{
    background: rgb(144,36,103);
    background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%);
    height: 175vh !important;
    position: fixed !important;
}
.feedback-btn {    
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    width: 80% !important;
    border: none;
    /* padding: 10px 45px; */
    border-radius: 30px !important;
    bottom:0px!important;
    color: white !important;
}
.logo{
    padding: 30px 40px;
    margin-top: 30px;
}
.profile-pic{
    padding-right: 0px;
    text-align: right;
    margin-top: 6px;
}
.profile-text strong, p{
    color: #fff;
}
.text-white{
    color: #fff;
}
.left-text{
    font-size: 15px;
}
.left-icon img{
    width: 45%;
}
.left-home{
    cursor: pointer;
}
.left-home:hover{
    background-color: rgba(81, 99, 160, 0.5);
}
/* Body left css end */

/* Body Right css start */
.up-toggle, .up-toggle1, .up-toggle2{
    width: 25px;
    margin-top: 20px;
    cursor: pointer;
}
.meetings-page-body-right{
    margin-left: 16.67%;
    background: #fff;
    width: 1200px !important;
}
/* .button {
    padding: 10px 25px !important;
    width: 106%;
    font-weight: bold !important;
    border-radius: 30px !important;
    cursor: pointer;
} */
.btn-font-size{
    font-size: 12px !important;
    text-align: center;
}

.btn1:hover{
    background-color: #26789F;
}
.all-btn{
    margin-left: 10% !important;
}
.all-btn img{
    cursor: pointer;
}
.text{
    background: #26789F;
    color: #fff;
    border-radius: 12px;
    height: 80px;
    transform: translate(0px, 10px);
}
.text p{
    margin-bottom: 0px;
}
.text span{
    float: right;
    margin-top: 8%;
}
.img-text{
    margin-top: -10px;
}
.item-of-table{
    background-color: rgb(243, 243, 243);
    padding: 25px 18px 0px;
    padding-right: 20px;
    border-radius: 4px;
    width: 120%;
    margin-left: -10% !important;
    border: solid 0.5px rgb(204, 204, 204);
    /* margin-right: 20px !important; */
}
.top-logo {
    position: absolute;
    top: -48px;
    left: 50%;
    /* margin-left: 53%; */
    transform: translateX(-50%);
    /* margin: auto !important; */

}
.meetings-hub-logo-size {
    width: 88px !important;
    height: 88px !important;
    border-radius: 50% !important;
    border: 9px solid #fff !important;    
    object-fit: cover;
}
.second-row {
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    padding: 8px 12px;
    border-radius: 15px;
}
.second-row img{
    width: 35px;
}
.third-row img {
    width: 28px;
}
.next{
    width: 14px !important;
}
.third-row.second-row.no-answer-row.my-3 {
    padding: 13px 5px;
}
.row-4 {
    padding: 14px 8px;
}
.day-layout {
    font-size: 18px;
}
.date-layout {
    margin-top: 7% !important;
    margin-left: -0.5%;
    font-size: 15px !important;
}
.time-layout {
    margin-top: 1.5% !important;
    /* font-size: 20px !important; */
    font-weight: bold;
}
button.btn-meetings.btn-info {
    /* margin-left: -25%; */
    border-radius: 30px;
    background: #553DB1;
    border: none;
    font-weight: bold;
    font-size:17px!important;
    width: 70%!important;
    color: white !important;
    padding: 12px 20px !important;
    /* height: 80% !important; */
}
button.btn-meetings.btn-danger-not-going-meetings {
    border-radius: 30px;
    padding: 8px 30px;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 20px;
    /* width: 60% !important; */
    margin: auto !important;
    margin-top: 2%!important;
    margin-bottom: 2% !important;
}
/* top buttons */
.reservations-pre-share-icon{
    margin-top: 10px;
    width: 34px;
}

.reservations-pre-share-button{
    display: block;
    background-color: #e9e9e9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7); 
    position: relative;
    text-align: center;
    border-radius: 50%;
    width: 74px;
    height: 74px;
    border: 9px solid #fff !important;    
}
.pre-share-button-layout{
    position: absolute;
    top: -25px;
    right: -1.5%;
}
.reservations-share-button{
    display: block;
    width: 74px;
    height: 74px;
    text-align: center;
    border-radius: 50%;
    border: 9px solid #fff !important;    

}
.reservations-share-icon{
    /* margin-top: 10px; */
    width: 74px;
    margin-left: -9px;
    margin-top: -10px;
    /* border: 9px solid #fff !important;  */
    /* border-radius: 50%; */
}
.share-button-layout{
    position: absolute;
    top: -25px;
    right: -1.5%;
}
.invite-more-buddies-icon{
    margin-bottom: 20px;
    width:40px !important;
}
.button-7 {
    padding: 10px 25px !important;
    width: 100%;
    font-weight: bold !important;
    border-radius: 30px !important;
    cursor: pointer;
}
.btn1-7 {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(0deg,  rgb(68, 169, 223) 51%, rgba(50,135,187,1) 89%);
    border-color: rgba(50,135,187,1) !important;
}
.btn2-7 {
    color: #fff;
    background: rgb(134,122,239);
    background: linear-gradient(0deg, rgba(134,122,239,1) 12%, rgba(92,69,185,1) 73%);
    border-color: #5c45b9 !important;
}
.btn3-7 {
    color: #fff;
    background: rgb(221,58,151);
    background: linear-gradient(0deg, rgba(221,58,151,1) 0%, rgba(205,9,92,1) 100%);
    border-color: #AA2E7F !important;
}
.btn1-7:hover{
    background-color: #26789F;
}
.all-btn-7{
    margin-left: 10% !important;
}
.all-btn-7 img{
    cursor: pointer;
}
/* end top buttons */
.activities-reservation-for-text{
    margin-top: -2%;
    margin-bottom: -2%;
}
.meetings-hub-name-position{
    margin: auto;
}
.meetings-hub-name{
    color: #575578;
    font-weight:bold;
    margin-top: 25px;
}
.reservation-shares-photo-layout{
    width: 20%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}
.reservation-shares-profile-pic{
    border-radius: 50%;
    width: 60% !important;
}
.pending-meetings-btn-light{
    margin: auto;
    width: 65%;
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    color: rgb(168, 168, 168) !important;
    border: none;
    font-weight: bold;
    font-size: 10px;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
}
.pending-meetings-btn-sellected{
    margin: auto;
    width: 65%;
    border-radius: 30px;
    padding: 12px 35px;
    background: #553DB1;
    border: none;
    font-weight: bold;
    font-size: 10px;
    color: white !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 1%;
}
.down-arrow img{
    width: 30px;
    cursor: pointer;
    margin: auto !important;
    /* margin-left: 9%; */
}
.drinks-box, .drinks-box1, .drinks-box2{
    display: none;
}
.pre-shared-hub-reservation-box{
    background-color: #8c8a8f;
}
.shared-hub-reservation-box{
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
}
.text1{
    background-color: #553DB1;
}
.text2{
    background-color: #AA2E7F;
}
.item-all{
    padding-bottom: 320px !important;
}
.item-col{
    max-width: 80% !important;
}

.btn-success-meetings {
    background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    border: none !important;
    padding: 12px 75px !important;
    border-radius: 30px !important;
}
.btn-meetings.btn-light {
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    color: rgb(168, 168, 168) !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
}

.number-img {
    margin-top: 10px;
    width: 100px;
    height: 100px;
}
.numbers {
    position: absolute;
    top: 45px;
    left: 0px;
    right: 0;
    font-weight: bold;
    color: #57558E;
    letter-spacing: -1px;
}
.attending-position {
    position: relative;
    margin-left:10px
}
.all-meetings-center-layout{
    width: 100%;
    margin-top: 55px;
}
.numbers-0 {
    position: absolute;
    left: 50px;
    top: 25px;
    font-size: 18px;
}
.link2-meetings{
    margin-left: 15px !important;
}
.meeting-buddies-text-layout{
    color: #57558E;
    text-align: justify;
    margin: auto;
    word-break: break-all;
}
.meeting-row{
    display: none !important;
}
.all-btn img {
    width: 135% !important;
}
.footer {
    padding-bottom: 60px !important;
}

/* Footer icon css start */
.nav-bottom{
    /* background: url(./svg/Nav\ bar\ BG.svg) no-repeat; */
    background: #fff;
    background-size: cover;
    border-radius: 20px 20px 0px 0px;
}
.footer-icon img {
    width: 18%;
}
.middle-icon-footer img {
    position: absolute;
    top: -45px;
    width: 92px !important;
    left: 30px;
}
.footer-width{
    width: 20% !important;
}
.nav-bottom{
    display: none !important;
}
.m-t {
    margin-top: 10px !important;
    width: 80px;
    height: 80px;
    margin-top: 80px;
    border-radius: 50%;
}
.invitations-img {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
    margin-top: -7px;
}
.meetings-form-control-text{
    margin-top: -10px !important;
}
/* Footer icon css end */
/* Body Right css end */

/* extra click modal css start */
.click-blue-box6 {
    background: #553DB1;
    padding: 12px 15px;
    border-radius: 15px;
    width: 50%;
    margin-left: 25%;
    margin-top: 10%;
    margin-bottom: 5%;
}
.click-blue-box6 p {
    margin-bottom: 0px;
}
.meetings-modal-logo{
    border-radius: 50%;
}
.mb{
    margin-top: -.6rem;
}
.red-circle {
    margin-left: -50%;
    /* margin-top: 15px; */
}
.red-circle img{
    width: 40%;
}

.meetings-reservation-activities{
    /* margin-left: 40%; */
    font-weight: bold;
    font-size: 20px;
}
.text-red-6 p{
    color: red;
    font-size: 12px !important;
}
.btn-meetings.btn-primary.fd-btn-6.my-3 {
    background: #553DB1;
    border: none;
    width: 10rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 11px;
    font-weight: bold;
    cursor: pointer;
}
.btn-meetings.btn-light.d-btn-6{
    background: #fff;
    border: none;
    width: 10rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 11px;
    font-weight: bold;
    color: #553DB1;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    cursor: pointer;
    margin-top: 20px;
}
.cancle-btn-6{
    background-color: #c00808 !important;
    border: none !important;
    margin-left: 10%;
}
.send-btn-6{
    background-color: #553DB1 !important;
    border: none !important;
    margin-left: 30%;
}
.btn-meetings.btn-danger.cs-btn6, .btn-meetings.btn-primary.cs-btn6 {
    width: 12rem;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
}
.il-6 {
    position: absolute;
    z-index: 999;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
}
.il-6 img{
    width: 120px;
    height: 120px;
}
.row-6 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -115px!important;
    margin-left: -125px !important;
}

button.btn-meetings.btn-danger-meetings {
    border-radius: 30px;
    padding: 12px 35px !important;
    background: #d60c27;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 12px; 
    margin-top: 5px!important;
    /* width: 100% !important; */
}
button.btn-meetings.btn-success-meetings-green {
    border-radius: 30px;
    padding: 12px 35px;
    background: #64ad6a;
    border: none;
    color: white; 
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px!important;
    /* width: 100% !important;
    height: 95%; */
}
.btn-meetings.btn-light-accepted-declined {
    border-radius: 30px;
    padding: 12px 35px;
    background: #fff;
    border: none;
    font-weight: bold;
    font-size: 12px !important;
    -webkit-box-shadow: 0px 4px 8px -5px rgba(45, 6, 104, 0.3);
    box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.3);
    margin-top: 5px!important;
}

.creator-name-color {
    color: #57558E !important;
}

/* extra click modal css end */
.c-box-6 {
    position: absolute;
    padding: 10px 25px !important;
    border-radius: 50px;
    background: #fff; 
    margin-top: -205%;
    margin-left: -50%;
    width: 500%;
}
.modal-size{
    /* width: 100px;; */
    margin-top: -200px;
    margin-bottom: -100px;
    height: 10%!important;
}
.modal-attending-icon{
    margin-left: 15%;
}
.numbers-6 {
    position: relative;
    margin-top: -28%;
    font-weight: bold;
    color: #57558E;
    margin-left: 15%;
}
.attending-6{
    margin-left: 15%;
}
.numbers-6 span{
    font-weight: bold;
    font-size: 20px;
}
.meetings-modal-logo-hub {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
    border: 5px solid #FFFAFA !important;
    object-fit: cover;
}

.modal.fade.bd-example-modal-lg {
    background: rgba(8, 8, 8, 0.9);
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgba(8, 8, 8, 0.1) !important;
    background-clip: padding-box;
    border: 1px solid  rgba(8, 8, 8, 0.1) !important;
    border-radius: .3rem;
    outline: 0;
}
/* end modal */



 


/* Responsive css start */
@media only screen and (max-width: 1200px){
    .meetings-page-mobile-navbar{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #232231;
        border-bottom: 1px solid rgb(241, 241, 241);
        background: linear-gradient(180deg, #fff 0%, rgb(235, 235, 235) 65%);
        position: fixed !important;
        z-index: 99999999999 !important;
    }


    .meetings-reservation-hub-box{
        width: 30%;
    }


    .meeting-hide-h3{
        display: none !important;
    }
    .meetings-hub-name{
        margin-top: 35px;
        margin-bottom: -10px;
        font-size: 14px;
    }
    .text{
        border-radius: 15px;
        margin-top: 10px;
        height: 90px;
    }
    .meetings-page-body-right{
        width: 100% !important;
        height: 1024px!important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 7rem!important;
    }
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 23.33% !important;
    }
}
@media only screen and (max-width: 1200px){
    .reservation-meetings-qr-button{

        width: 80px;
        height: 80px;
        line-height: 75px;

        margin-top: 10px;
    }
    .reservation-meetings-qr-text{
        margin-top: 0px;
        font-size: 20px;
    }



    .body-left{
        display: none;
    }
    .meetings-page-body-right{
        margin-left: initial;
    }
    .item-col{
        max-width: 80% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .d-b {
        display: block;
    }
    .text span {
        float: right;
        margin-top: -3%;
        position: relative;
    }
    .top-logo {
        position: absolute;
        top: -34px;
        left: 0;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    button.btn-meetings.btn-info {
        padding: 12px 20px !important;
        width: 70%!important;
    }
    /* .blue-btn{
        text-align: right !important;
    } */
    /* .red-btn{
        text-align: left !important;
    } */
    .item-all{
        padding-bottom: 0px !important;
    }
    .meeting-row{
        display: block !important;
        /* background: rgb(144,36,103);
        background: linear-gradient(353deg, rgba(144,36,103,1) 31%, rgba(28,48,111,1) 91%); */
        /* padding: 25px 0px 20px 0px; */
    }
    .meeting{
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
    }
    .col-xl-10.col-lg-12.meetings-page-body-right.pb-5 {
        border-radius: 0px 0px 0px 0px;
    }
    .bg-linear{
        /* background: rgb(144,36,103);
        background: linear-gradient(353deg, rgba(144,36,103,1) 61%, rgba(28,48,111,1) 51%); */
    }
    .meeting-box-size {
        /* width: 115% !important; */
        }
    .hub-color{
        /* background: rgb(144,36,103);
        background: linear-gradient(336deg, rgba(144,36,103,1) 65%, rgba(28,48,111,1) 99%); */
        width: 100%;
        background: linear-gradient(258deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
    }
    .nav-bottom{
        display: block !important;
    }
    .time-layout {
        margin-top: -0% !important;
        font-size: 20px !important;
    }
    .day-layout {
        font-size: 20px;
        margin-top: -1%;
    }
    .number-img {
        margin-left: 7px;
    }
    .numbers {
        /* left: 88px; */
    }
    .attending-position {

    }
    .meetings-total-page-layout{
        position: absolute !important;
        width: 100%;
        top: 0px !important;
        left: 0px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -190%;
    }

}
@media only screen and (max-width: 992px){

    .hub_reservation-meeting-info{
        font-size: 16px;
        margin-bottom:30px;
    }
    
    .hub_reservation-meeting-data{
        font-size: 16px;
        top: 0%;
        left: 50%;
    }

    .click-blue-box6 {  
        width: 40%;
        margin-left: 25%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .red-circle {
        margin-left: -80%;
        margin-top: 10px;
    }
    .red-circle img{
        width: 40%;
    }
    .all-btn {
        margin-left: 3% !important;
    }
    .all-btn img {
        width: 120% !important;
    }
    .text{
        border-radius: 15px;
        margin-top: 10px;
        height: 70px;
    }
    button.btn-meetings.btn-info {
        width: 80%!important;
        height: 100%;
        font-size: 13px!important;
    }
    .time-layout {
        margin-top: -0% !important;
        font-size: 20px !important;
    }
    .date-layout {
        margin-top: 2% !important;
        font-size: 15px !important;
    }
    .number-img {
        /* margin-left: 10%; */
    }
    .numbers {
        /* left: 38%; */
    }
    .btn1 {
        width: 110%;
        margin-left: -50px;
    }
    .btn-meetings.btn-light.bt1-position {
        padding: 12px 35px;
        width: 110%;
    }
    
    .btn2 {
        width: 110%;
    }
    .btn-meetings.btn-light.btn2-position {
        padding: 12px 35px;
        width: 110%;
    }
    
    .btn3 {
        width: 110%;
        margin-left: 25px;
    }
    .btn-meetings.btn-light.btn3-position {
        padding: 12px 35px;
        width: 110%;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 800px){
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 30.33% !important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 3rem!important;
    }
}
@media only screen and (max-width: 790px){
    .numbers {
        /* left: 45%; */
    }
    .hub_reservation-meeting-shared{
        width: 20%;
        height: 40%;
        position: absolute;
        top: 30%;
        right: 0%;
    }
    .hub_reservation-meeting-text-persons{
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
    }
    .hub_reservation-meeting-text{
        color: #575578;
        font-size: 10px;
        font-weight: bold;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 769px){
    .btn-meetings.btn-danger.cs-btn6, .btn-meetings.btn-primary.cs-btn6 {
        width: 10rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 30px;
    }
    .meetings-reservation-activities{
        /* margin-left: 38%; */
        font-weight: bold;
        font-size: 20px;
    }
    .text{
        margin-top: 10px;
        height: 50px;
    }
    .m-t {
        margin-top: 10px !important;
        width: 60px;
        height: 60px;
    }
    .all-btn {
        margin-left: 10% !important;
    }
    .all-btn img {
        width: 96% !important;
    }
    .btn1 {
        width: 110%;
        margin-left: -10px;
    }
    .date-layout{
        margin-top: 2% !important;
    }
    .time-layout {
        margin-top: 1.5% !important;
        font-size: 14px !important;
    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 15px !important;
    }
    .number-img {
        margin-top: -1%;
        margin-left: -15%;
    }
    .numbers {
        margin-top:-10px;
        margin-left: -15px;
    }
    .attending-position{
        margin-left: -15px;
    }
    button.btn-meetings.btn-info {
        width: 100% !important;
        height: 100%;
        font-size: 10px !important;
    }
    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 18px;
        /* width: 65% !important; */
    }
    .cancle-btn-6{
        background-color: #c00808 !important;
        border: none !important;
        margin-left: 20%;
    }
}
@media only screen and (max-width: 767px){
    .meeteing-wifi-position{
        top: 3%; 
        left: 5%;
    }
    .meeteing-wifi-round-button{
        /* margin: 36px; */
        width: 30px;
        height: 30px;
        margin-bottom: 23px;
    }
    .meeteing-wifi-icon{
        width: 15px;
        height:15px;
        margin-top: 3px;
    }
    .all-btn {
        margin-left: 0% !important;
    }
    .il-6 {
        top: -60px;
        left: 51%;
    }
    .il-6 img{
        width: 100px;
        height: 100px;
    }
    .btn1 {
        width: 80%;
        margin-left: -30px;
    }
    .btn-meetings.btn-light.bt1-position {
        padding: 12px 0px;
        width: 80%;
    }
    
    .btn2 {
        width: 80%;
    }
    .btn-meetings.btn-light.btn2-position {
        padding: 12px 0px;
        width: 80%;
    }
    
    .btn3 {
        width: 80%;
        margin-left: 25px;
    }
    .btn-meetings.btn-light.btn3-position {
        padding: 12px 0px;
        width: 80%;
        margin-left: 25px;
    }
}
@media only screen and (max-width: 669px){
    .attending-position{
        margin-left: 0px;
    }
    .numbers {
        margin-top:-10px;
        margin-left: -10px;
    }
    .reservation-meetings-qr-button{
        width: 50px;
        height: 50px;
        line-height: 45px;
        margin-top: 10px;
    }
    .reservation-meetings-qr-text{
        margin-top: -15px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 576px){
    .hub_reservation-meeting-requested{
        font-size: 14px;
    }
    
    .hub_reservation-meeting-approved{
        font-size: 14px;
    }
    
    .hub_reservation-meeting-cancelled{
        font-size: 14px;
    }

    .hub_reservation-meeting-info{
        font-size: 14px;
        margin-bottom:30px;
    }
    
    .hub_reservation-meeting-data{
        font-size: 14px;
        top: 0%;
        left: 60%;
    }
    /* top buttons */
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 31.33% !important;
    }
    .button-7 {
        padding: 10px 10px!important;
        width: 110%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: 1rem!important;
    }
    /* end top buttons */
    button.btn-meetings.btn-info {
        border-radius: 40px;
        background: #553DB1;
        border: none;
        font-weight: bold;
        padding: 2px 15px !important;
        text-align: center !important;
    }
    
    .item-col{
        max-width: 90% !important;
        margin: auto;
        margin-bottom: 60px;
    }
    .footer-icon img {
        width: 25%;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -45px;
        width: 83px !important;
        left: 16px;
    }
    .all-btn img {
        width: 101% !important;
    }
    .attending-position {
        margin-left: 5px
    }
    .feedback-btn {    
        width: 50% !important;
        margin-left:25%;
    }
    .meetings-reservation-activities{
        /* margin-left: 36%; */
        font-weight: bold;
        font-size: 20px;
    }
    .btn1 {
        padding:0px 0px !important;
        width: 80% !important;
        margin-left: -30px;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.bt1-position {
        padding: 0px 0px !important; 
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn2 {
        padding:0px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.btn2-position {
        padding: 0px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn3 {
        padding:0px 0px !important;
        width: 80% !important;
        margin-left: 25px !important;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.btn3-position {
        padding: 0px 0px;
        width: 80%;
        margin-left: 25px;
        font-size: 12px !important
    }
    .numbers {
        margin-top:-10px;
        margin-left: 3px;
    }
}

@media only screen and (max-width: 504px){
    .new-meeting-icon-position{
        margin-top: -20%;
    }
    button.btn-meetings.btn-danger-meetings {
        padding: 12px 30px !important;
        font-size: 10px;
        margin-top: 5px!important;
    }
    button.btn-meetings.btn-success-meetings-green {
        padding: 12px 30px !important;
        font-size: 10px;
        margin-top: 5px!important;
    }
    .btn-meetings.btn-light-accepted-declined {
        padding: 12px 25px !important;
        font-size: 10px !important;
        margin-top: 5px!important;
    }
}

@media only screen and (max-width: 492px){
    .reservation-pc-display{
        display: none;
    }
    .reservation-mobile-display{
        display: block;
    }

    .mobile-reservations-box{
        position: relative;
        width:340px;
        height:260px;
        background-color: #fff;
        margin-bottom: 30px;
        border-radius: 20px;
        border: solid 0.5px rgb(204, 204, 204);
        -webkit-box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(45, 6, 104, 0.2);
    }

    .mobile-reservations-hub-name-box{
        position:absolute;
        top: 10px;
        left:8%;
        width: 84%;
        height: 30px;
        /* background-color: red; */
        text-align: left;
        font-weight: bold;
        color: #232231;
        border-bottom: 1px solid black;
        font-size: 14px;
    }

    .mobile-reservations-hub-logo-box{
        position:absolute;
        top: 85px;
        left:5%;
        width: 80px;
        height: 80px;
        /* background-color: red; */
    }
    .mobile-reservations-hub-logo{
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* border-radius: 50%; */
    }

    .mobile-reservations-status{
        position:absolute;
        top: 50px;
        left:8%;
        width: 84%;
        height: 30px;
        text-align: left;
        font-weight: bold;
        /* background-color: red; */
    }

    .mobile-reservations-data-box{
        position:absolute;
        width:200px;
        height:100px;
        /* background-color: red; */
        left: 130px;
        top: 80px;
    }

    .mobile-reservations-data-info-box{
        position:absolute;
        width:100px;
        height:100px;
        /* background-color: rgb(221, 92, 92); */
        left: 220px;
        top: 80px;
    }

    .hub_reservation-meeting-data{
        width: 100%;
        font-size: 12px;
        top: 0%;
        left: 0%;
    }


    .mobile-reservations-shared-with-box{
        position:absolute;
        top: 180px;
        left:8%;
        width: 84%;
        height: 30px;
        text-align: left;
        font-weight: bold;
        /* background-color: rgb(19, 155, 48); */
        line-height: 30px;
        color: #575578;
        font-size: 11px !important;
        border-top: solid 1px rgb(204, 204, 204);
        border-bottom: solid 1px rgb(204, 204, 204);
    }

    .mobile-reservations-shared-persons{
        position:absolute;
        top: 0px;
        left:95px;
        width: 200px;
        height: 30px;
        text-align: left;
        font-weight: normal;
        color: blue;
        z-index: 99;
    }
    .mobile-reservations-shared-profile-pic{
        position: absolute;
        width: 18px;
        height: 18px;
        left: 73px;
        top: 6px;
    }

    .mobile-reservations-shared-profile-pic-img{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    .mobile-reservations-btns{
        position: absolute;
        bottom: 5px;
        left: 0%;
        width: 100%;
        height: 35px;
        /* background-color: #57558E; */
    }

    .mobile-reservations-cancel-reservation{
        position: absolute;
        left: 8%;
        border-radius: 4px;
        padding: 6px 0px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: normal;
        font-size: 12px;
        width: 40% !important; 
        z-index: 9; 
    }

    .mobile-reservations-cancel-reservation:active {
        transform: scale(0.88);
    }
    
    .mobile-reservations-cancel-reservation:focus {
        transform: scale(0.88);
    }
    
    .mobile-reservations-cancel-reservation:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .mobile-reservations-share-reservation{
        position: absolute;
        left: 52%;
        border-radius: 4px;
        padding: 6px 0px;
        /* background: #1183ca !important; */
        color: white;
        border: none;
        font-weight: normal;
        font-size: 12px;
        width: 40% !important; 
        z-index: 9; 
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
    }

    .mobile-reservations-share-reservation:active {
        transform: scale(0.88);
    }
    
    .mobile-reservations-share-reservation:focus {
        transform: scale(0.88);
    }
    
    .mobile-reservations-share-reservation:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .mobile-reservations-share-reservation-center{
        position: absolute;
        left: 30%;
        border-radius: 4px;
        padding: 6px 0px;
        /* background: #1183ca !important; */
        color: white;
        border: none;
        font-weight: normal;
        font-size: 12px;
        width: 40% !important; 
        z-index: 9; 
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none;
    }

    .mobile-reservations-share-reservation-center:active {
        transform: scale(0.88);
    }
    
    .mobile-reservations-share-reservation-center:focus {
        transform: scale(0.88);
    }
    
    .mobile-reservations-share-reservation-center:after {
        transform: scale(0.88);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }


    .hub_reservation-meeting-share-box{
        right: 2%;
        top: 65%;
    }
 
 
    .hub_reservation-meeting-status{
        width: 100%;
        height: 20%;
        top: 1%;
    }


    .btn-meetings.btn-danger.cs-btn6, .btn-meetings.btn-primary.cs-btn6 {
        width: 8rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 30px;
    }
    .click-blue-box6 {
        background: #553DB1;
        padding: 12px 15px;
        border-radius: 15px;
        width: 50%;
        margin-left: 28%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .date-layout{
        margin-top: 2% !important;
        font-size: 10px !important;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 4% !important;

    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 10px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -130%;
        width: 400%;
        margin-top: -250%;
    }
    /* end modal */
    .pending-meetings-btn-light{
        font-size: 9px;
    }
    .pending-meetings-btn-sellected{
        font-size: 9px;
    }
}
@media only screen and (max-width: 490px){
    .hub_reservation-cancel-reservation{
        position: absolute;
        font-size: 16px; 
        bottom: 7%;
        left: 25%;
        margin-top: 10px;
        }

    .hub_reservation-meeting-shared{
        width: 100%;
        height: 0%;
        position: relative;
        margin-top: -20px;
        right: 0%;
    }
    .hub_reservation-meeting-text-persons{
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 40px;
    }
    .hub_reservation-meeting-text{
        color: #575578;
        font-size: 12px;
        font-weight: bold;
        margin-top: 15px;
    }


    .meetings-reservation-hub-logo{
        height: 80px;
        width: 80px;
        margin-top: -10%;
    }
    
    .meetings-reservation-hub-name{
        font-size: 14px;
    }

    .hub_reservation-meeting-info{
        width: 100%;
        font-size: 12px;
        margin-bottom:30px;
    }



    .date-layout{
        margin-top: 2% !important;
        font-size: 10px !important;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 5% !important;

    }
    .day-layout {
        margin-top: -0% !important;
        font-size: 10px !important;
    }
    .number-img {
        height: 80px;
        margin-top: 10px;
        margin-left: -15px;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 15px;
    }
    /* .numbers {
        top: 36px;
        left: 45%;
    } */
    .attending-position {
        font-size: 12px;
    }
    .feedback-btn {    
        width: 60% !important;
        margin-left:20%;
        font-size: 12px;
    }
    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
        /* width: 70% !important; */
    }
}

@media only screen and (max-width: 455px){
    .col-xl-2.col-lg-4.col-md-3.col-sm-4.col-4.text-right {
        max-width: 33.33% !important;
    }
    .row.text-right.all-btn-7.py-5.mb-4 {
        margin-left: initial !important;
        margin: auto;
        margin-left: -1rem!important;
    }
    .meeteing-wifi-text{
        font-size: 11px;
    }
}

@media only screen and (max-width: 435px){
    .cancle-btn-6{
        background-color: #c00808 !important;
        border: none !important;
        margin-left: 5%;
    }
    .send-btn-6{
        background-color: #553DB1 !important;
        border: none !important;
        margin-left: 50%;
    }
    .d-b {
        display: block;
        font-size: 13px;
        margin-left: -18px;
    }
    .all-btn img {
        width: 116% !important;
    }
    /* .button {
        padding: 8px 0px !important;
        width: 110%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
    } */
    .m-t {
        margin-top: -5px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px;
    }
    .time-layout {
        font-size: 10px !important;
        margin-top: 7% !important;

    }
    /* .numbers {
        top: 35px;
        left: 40px;
    } */
    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
        /* width: 80% !important; */
    }
    .btn1 {
        padding:10px 0px !important;
        width: 80% !important;
        margin-left: -30px;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.bt1-position {
        padding: 10px 0px !important; 
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn2 {
        padding:10px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.btn2-position {
        padding: 10px 0px !important;
        width: 80% !important;
        font-size: 12px !important
    }
    
    .btn3 {
        padding:10px 0px !important;
        width: 80% !important;
        margin-left: 25px !important;
        font-size: 12px !important
    }
    .btn-meetings.btn-light.btn3-position {
        padding: 10px 0px;
        width: 80%;
        margin-left: 25px;
        font-size: 12px !important
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -125%;
        width: 380%;
    }
    .attending-6{
        margin-left: 15%;
        font-size: 10px !important;
    }
    /* end modal */
    .numbers {
        margin-top:-8px;
        margin-left: 22px;
    }
    .attending-position {
        margin-top: -20% !important;
        margin-left: 10% !important;
    }
    .meeteing-wifi-position{
        top: 3%; 
        left: 8%;
    }
}


@media only screen and (max-width: 405px){
    .button-7 {
        padding: 8px 0px!important;
        width: 115%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        /* font-size: 15px !important; */
    }
}

@media only screen and (max-width: 400px){
    .hub_reservation-cancel-reservation{
        bottom: 7%;
        left: 20%;
        }
    .btn-meetings.btn-danger.cs-btn6, .btn-meetings.btn-primary.cs-btn6 {
        width: 7rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 30px;
    }
    .m-t {
        margin-top: -5px !important;
        margin-left: -20px;
        width: 60px;
        height: 60px;
    }
    .full-w{
        width: 100% !important;
        display: block;
    }
    .img-text img {
        width: 57px;
    }
    .d-b {
        display: block;
        font-size: 11px;
    }
    .text {
        /* margin-left: 8px; */
    }
    p, span{
        font-size: 14px;
    }
    .numbers.numbers-0 {
        position: absolute;
        top: 24px;
        left: 40px;
        right: 0;
        font-weight: bold;
        color: #57558E;
        letter-spacing: -2px;
        /* font-size: 14px; */
    }
    .date-layout{
        font-size: 10px;
        margin-top: 7% !important;
    }
    .number-img {
        height: 80px;
        width: 80px;
        margin-top: 20%;
        margin-left: 10%;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 20px;
    }

    .attending-position {
        font-size: 10px;
    }

    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 15px;
        /* width: 80% !important; */
    }
    .btn-meetings.btn-light.bt1-position {
        font-size: 14px !important;
        padding-top: 16%!important;
    }
    .btn-meetings.btn-light.btn2-position {
        width: 125%!important;
        font-size: 14px !important;
        padding-left: 15%!important;
        margin-left: -18px!important;
        height: 100%!important;
    }
    .btn-meetings.btn-light.btn3-position {
        width: 120%!important;
        font-size: 14px!important;
        margin-left: -13px!important;
        height:100%!important;
        padding-left: 20%!important;
    }
    .red-circle {
        margin-left: -100%;
        margin-top: 18px;
    }
    .red-circle img{
        width: 40%;
    }
    .click-blue-box6 {

        padding: 12px 15px;
        border-radius: 15px;
        width: 50%;
        margin-left: 33%;
        margin-top: 13%;
        margin-bottom: 5%;
    }
    .click-blue-box6 p {
        margin-bottom: 0px;
    }
    .meetings-reservation-activities{
        /* margin-left: 34%; */
        font-weight: bold;
        font-size: 20px;
    }
        /* extra click modal css end */
    .c-box-6 {
        margin-left: -115%;
        width: 360%;
    }
    /* end modal */
}
@media only screen and (max-width: 385px){
    .time-layout {
        font-size: 10px !important;
        margin-top: 9% !important;
    }
    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 13px;
        /* width: 80% !important; */
    }
    button.btn-meetings.btn-danger-meetings {
        padding: 12px 30px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    button.btn-meetings.btn-success-meetings-green {
        padding: 12px 30px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    .btn-meetings.btn-light-accepted-declined {
        padding: 12px 20px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    .numbers {
        margin-top:-10px;
        margin-left: 25px;
    }
}
@media only screen and (max-width: 380px){
    .btn-success-meetings {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none !important;
        padding: 10px 75px !important;
        border-radius: 30px !important;
    }
    .button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 12px;
        /* width: 90% !important; */
    }
    .btn1{
        font-size: 13px !important;  
    }
    .btn2{
        font-size: 13px !important;  
    }
    .btn3{
        font-size: 13px !important;  
    }    
    .btn-meetings.btn-light.bt1-position {
        font-size: 13px !important;
    }
    .btn-meetings.btn-light.btn2-position {
        width: 125%!important;
        font-size: 13px !important;
        padding-left: 15%!important;
        margin-left: -18px!important;
        height: 100%!important;
    }
    .btn-meetings.btn-light.btn3-position {
        width: 120%!important;
        font-size: 13px!important;
    }
    .c-box-6 {
        margin-left: -115%;
        width: 360%;
        height: 500px;
        margin-top: -210%;
    }
}
@media only screen and (max-width: 365px){
    .mobile-reservations-box{
        width:290px;
        /* height: 190px; */
    }

    .mobile-reservations-hub-logo-box{
        top: 85px;
        width: 70px;
        height: 70px;
    }

    .mobile-reservations-data-box{
        width:100px;
        height:80px;
        left: 100px;
        top: 80px;
    }

    .mobile-reservations-data-info-box{
        width:100px;
        height:80px;
        left: 170px;
        top: 80px;
    }

    .hub_reservation-cancel-reservation{
        left: 15%;
        }
    p, span {
        font-size: 11px;
        margin-top: 6px !important;
    }
    .d-b {
        margin-top: 3px;
    }
    img.number-img {
        margin-top: -10px;
    }
    .numbers {
        margin-top:-30px;
        margin-left: 25px;
    }
    /* .numbers{
        margin-top: -19px;
        margin-left: 3px;
    } */
    /* .button {
        padding: 8px 0px !important;
        width: 116%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 13px !important;
        margin-left: -15px;
    } */
    .m-t {
        /* margin-top: 10px !important; */
        margin-left: -20px;
        width: 50px !important;
        height: 50px !important;
    }
    .attending-position{
        margin-left: 10px !important;
    }
}
@media only screen and (max-width: 365px){
    .hub_reservation-meeting-info{
        width: 100%;
        font-size: 10px;
        margin-bottom:30px;
    }
    
    .hub_reservation-meeting-data{
        /* width: 70%; */
        font-size: 10px;
        /* top: 0%; */
        /* left: 75%; */
    }
    .m-t {
        width: 36px;
        height: 36px;
        margin-top: 0px;
    }
    .col-xl-3.col-lg-3.col-md-3.col-sm-3.col-3.full-w.img-text {
        margin-left: -10px;
        margin-top: 3px;
    }
    .btn-success-meetings {
        background: linear-gradient(0deg, rgba(144,36,103,1) 0%, rgba(28,48,111,1) 65%);
        border: none !important;
        padding: 10px 20px !important;
        border-radius: 30px !important;
        font-size: 14px !important;
    }
    .d-b {
        margin-left: -12px;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 7px !important;
    }
    .time-layout {
        font-size: 7px !important;
        margin-top: 16% !important;

    }
    .day-layout {
        font-size: 7px !important;
    }
    .attending-position {
        font-size: 9px;
    }
    button.btn-meetings.btn-info {
        width: 120% !important;
        height: 100%;
        font-size: 10px !important;
    }
    /* extra click modal css end */
    .c-box-6 {
        margin-left: -85%;
        width: 300%;
        height: 550px;
    }
    .pending-meetings-btn-light{
        width: 60%;
        font-size: 10px;
        margin-top: 1%;
    }
    .pending-meetings-btn-sellected{
        width: 60%;
        font-size: 10px;
        margin-top: 1%;
    }
    .meetings-reservation-activities{
        font-size: 13px;
    }
    /* end modal */
}
@media only screen and (max-width: 350px){
    
    .meetings-reservation-hub-logo{
        height: 60px;
        width: 60px;
        margin-top: -10%;
    }
    
    .meetings-reservation-hub-name{
        font-size: 12px;
    }


    .button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 12px;
        /* width: 100% !important; */
    }   
    .btn2{
        width: 135%!important;
    } 
    .btn-meetings.btn-light.bt1-position {
        font-size: 12px !important;
    }

    .btn-meetings.btn-light.btn2-position {
        width: 135%!important;
        font-size: 12px !important;
    }
    .btn-meetings.btn-light.btn3-position {
        width: 120%!important;
        font-size: 12px!important;
    }
    button.btn-meetings.btn-danger-meetings {
        padding: 8px 20px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    button.btn-meetings.btn-success-meetings-green {
        padding: 8px 20px !important;
        font-size: 9px;
        margin-top: 5px!important;
    }
    .btn-meetings.btn-light-accepted-declined {
        padding: 8px 15px !important;
        font-size: 8px !important;
        margin-top: 5px!important;
    }
}
@media only screen and (max-width: 340px){
    .button-7 {
        padding: 8px 0px!important;
        width: 128%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 13px !important;
    }

    /* modal */
    .il-6 {
        top: -40px;
        left: 51%;
    }
    .modal-attending-icon{
        margin-left: 5%;
        margin-top: -3%;
    }

    .attending-6{
        margin-left: 5%;
    }

    .meetings-modal-logo-hub {
        width: 80px !important;
        height: 80px !important;
        border-radius: 50% !important;
        border: 5px solid #FFFAFA !important;
        object-fit: cover;
    }

    .modal.fade.bd-example-modal-lg {
        background: rgba(8, 8, 8, 0.9);
    }
    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: rgba(8, 8, 8, 0.1) !important;
        background-clip: padding-box;
        border: 1px solid  rgba(8, 8, 8, 0.1) !important;
        border-radius: .3rem;
        outline: 0;
    }
    .text-red-6 p{
        margin-top: -12px !important;
        margin-bottom: 5px;
    }
    .meetings-reservation-activities{
        font-size: 11px;
    }
    .c-box-6 {
        margin-left: -80%;
        height: 400px;
        width: 300%;
        margin-top: -180% !important;
    }
    .pending-meetings-btn-light{
        font-size: 9px;
        padding: 6px 10px;
    }
    .pending-meetings-btn-sellected{
        font-size: 9px;
        padding: 5px 10px;
    }
    .send-btn-6{
        margin-top: -10px;
    }
    .cancle-btn-6{
        margin-top: -10px;
    }
    /* end modal */
}
@media only screen and (max-width: 325px){
    .hub_reservation-cancel-reservation{
        left: 12%;
        }
    .btn-meetings.btn-danger.cs-btn6, .btn-meetings.btn-primary.cs-btn6 {
        width: 6rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 30px;
    }
    .red-circle {
        margin-left: -100%;
        margin-top: 22px;
    }
    .red-circle img{
        width: 40%;
    }
    .numbers {
        margin-top:-30px;
        margin-left: 36px;
    }
}

@media only screen and (max-width: 316px){
    button.btn-meetings.btn-info {
        width: 140% !important;
        height: 100%;
        font-size: 9px !important;
    }
    .middle-icon-footer img {
        position: absolute;
        top: -33px;
        width: 65px !important;
        left: 0px;
    }
    .footer-icon img {
        width: 50%;
    }
    /* .button {
        padding: 7px 0px !important;
        width: 132%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 9px !important;
        margin-left: -20px;
    } */
    .m-t {
        margin-top: 0px !important;
        margin-left: -20px;
        width: 50px !important;
        height: 50px !important;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 6px !important;
    }
    .time-layout {
        font-size: 6px !important;
        margin-top: 20% !important;

    }
    .day-layout {
        font-size: 6px !important;
    }

    .attending-position {
        font-size: 8px;
    }
    button.btn-meetings.btn-danger-not-going-meetings {
        border-radius: 30px;
        padding: 12px 50px;
        background: #d60c27;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 9px;
        /* width: 105% !important; */
    }
    .btn-meetings.btn-light.bt1-position {
        font-size: 10px !important;
        padding-top: 21%!important;
    }

    .btn-meetings.btn-light.btn2-position {
        width: 135%!important;
        font-size: 10px !important;
    }
    .btn-meetings.btn-light.btn3-position {
        width: 120%!important;
        font-size: 10px!important;
    } 
    button.btn-meetings.btn-danger-meetings {
        padding: 3px 15px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    button.btn-meetings.btn-success-meetings-green {
        padding: 3px 15px !important;
        font-size: 9px !important;
        margin-top: 5px!important;
    }
    .btn-meetings.btn-light-accepted-declined {
        padding: 4px 10px !important;
        font-size: 6px !important;
        margin-top: 5px!important;
    }
}
@media only screen and (max-width: 300px){
    .button-7 {
        padding: 8px 10px!important;
        width: 135%;
        font-weight: bold !important;
        border-radius: 30px !important;
        cursor: pointer;
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 290px){
    .m-t {
        margin-top: 10px !important;
        margin-left: -20px;
        width: 40px !important;
        height: 40px !important;
    }
    .d-b {
        margin-left: -18px;
    }
    .date-layout{
        /* margin-top: 2% !important; */
        font-size: 6px !important;
        margin-left: -12% !important;
    }
    .time-layout {
        font-size: 6px !important;
        margin-top: 25% !important;
        margin-right: -17%;
    }
    .day-layout {
        font-size: 6px !important;
        margin-left: -12% !important;
    }

    .attending-position {
        font-size: 9px;
    }
    .feedback-btn {    
        width: 70% !important;
        margin-left:15%;
        font-size: 11px;
    }
    button.btn-meetings.btn-info {
        margin-left: -20px;
        width: 170% !important;
        height: 100%;
        font-size: 10px !important;
    }
    .btn-meetings.btn-light.bt1-position {
        font-size: 9px !important;
        padding-top: 25%!important;
    }

    .btn-meetings.btn-light.btn2-position {
        width: 135%!important;
        font-size: 9px !important;
    }
    .btn-meetings.btn-light.btn3-position {
        width: 120%!important;
        font-size: 9px!important;
    }
}

/* Responsive css end */